import React, { useEffect, useRef, useState, useCallback } from "react";
import { Form } from "react-bootstrap";
import { throttle } from 'lodash';

import SurveyPreview from "../../../../common/components/SurveyPreviewV2";


const handleSaveSurvey = async (test, answers, submitTests, updateTest) => {
  if (!test.test_survey_save_allowed) return;
  updateTest({ ...test, test_survey_save_allowed: false });

  const response = await submitTests([
    {
      ...test,
      test_survey: {
        survey: answers,
        test_configuration_id: test.test_configuration_id,
      },
    },
  ]);

  if (response.data.success) {
    console.log("Answers saved");
  } else {
    toatr.error("Something went wrong while saving the survey answers");
  }
};

const ServiceSurveySection = ({ submitTests, test, updateTest }) => {
  if (!Object.keys(test?.test_configuration?.survey || {}).length) return <span></span>;

  const surveyRef = useRef(null);
  const [answers, setAnswers] = useState(test.test_survey?.survey || {});

  const saveSurveyIfOutside = useCallback(
    (event) => {
      if (surveyRef.current && !surveyRef.current.contains(event.target)) {
        handleSaveSurvey(test, answers, submitTests, updateTest);
      }
    },
    [test, answers, submitTests, updateTest]
  );

  const throttledMouseMove = useCallback(
    throttle(saveSurveyIfOutside, 500),
    [saveSurveyIfOutside]
  );

  useEffect(() => {
    if (!test.test_survey_save_allowed) return;

    const handleSaveEvent = () => handleSaveSurvey(test, answers, submitTests, updateTest);

    document.addEventListener("mousedown", saveSurveyIfOutside);
    document.addEventListener("keydown", handleSaveEvent);
    window.addEventListener("scroll", handleSaveEvent);
    document.addEventListener("mousemove", throttledMouseMove);

    return () => {
      document.removeEventListener("mousedown", saveSurveyIfOutside);
      document.removeEventListener("keydown", handleSaveEvent);
      window.removeEventListener("scroll", handleSaveEvent);
      document.removeEventListener("mousemove", throttledMouseMove);
      throttledMouseMove.cancel(); // clean up throttling
    };
  }, [saveSurveyIfOutside, throttledMouseMove, test, answers, submitTests, updateTest]);

  return (
    <div>
      <Form.Group ref={surveyRef}>
        <Form.Label>Survey</Form.Label>
        <SurveyPreview
          data={answers}
          json={test.test_configuration.survey}
          onValueChanged={(e) => {
            setAnswers(e.data);
            updateTest({ ...test, test_survey_save_allowed: true });
          }}
        />
      </Form.Group>
    </div>
  );
};

export default ServiceSurveySection;