import React, { useState, useEffect, useRef } from 'react';
import FloatingLabelInput from '../../common/components/FloatingLabelInput';
import FormSelect from '../../common/components/FormSelect';
import AsyncMultiSelect from '../../common/components/AsyncMultiSelect';

const SearchFilters = ({
  testGroup,
  params,
  locationOptions,
  consentedToFilterOptions,
  consentTypeFilterOptions,
  consentFlipperEnabled,
  selectedTags,
  tagOptionsUrl,
  populationOptions,
}) => {
  const [form, setForm] = useState({
    include_archived: "false",
    q: params.q,
    location: params.location,
    archived: params.archived,
    consented_to: params.consented_to,
    sort_by: params.sort_by,
    tags: params.tags,
    populations: params.populations,
  });
  const initialForm = useRef(form);
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchInput, setSearchInput] = useState(params.q);
  const [consentType, setConsentType] = useState(params.consent_type);

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  }

  const updateTags = (tags) => {
    let formTags = tags.map(tag => tag.value).join(",");
    setForm({...form, tags: formTags});
  }

  const filterTestGroupUsers = () => {
    const query = {};
    Object.keys(form).forEach(key => {
      if (form[key]) {
        query[key] = form[key];
      }
    });
    const url = `/test_groups/${testGroup.slug}/test_group_users?` + new URLSearchParams(query).toString();
    window.location.href = url
  }

  useEffect(() => {
    if (initialForm.current !== form) filterTestGroupUsers();
  }, [form]);

  return (
    <div>
      <div className="d-sm-block d-md-flex align-items-center flex-wrap">
        <FloatingLabelInput
          label="Search by name, email, or phone"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && setForm({...form, q: e.target.value})}
          ariaLabel="Search by name, email, or phone"
          className="d-sm-block flex-md-fill mb-3 mb-md-0"
          style={{minWidth: "305px"}}
          prependIcon="search"
        />
        { testGroup.on_demand_only && (
          <div className="d-sm-block d-md-inline-block mb-3 mb-md-0 ms-md-3">
            <FormSelect
              fieldName="location"
              prompt="Location"
              options={locationOptions}
              selected={form.location}
              onChange={(id) => setForm({...form, location: id})}
            />
          </div>
        )}
        <div className="d-sm-block d-md-inline-block mb-3 mb-md-0 ms-md-3">
          <FormSelect
            fieldName="archived"
            prompt="Status"
            options={[
              {label: "Active", value: "false"},
              {label: "Archived", value: "true"}
            ]}
            selected={form.archived}
            defaultValue="false"
            onChange={(value) => setForm({...form, archived: value})}
          />
        </div>
        { !consentFlipperEnabled && (
          <div className="d-sm-block d-md-inline-block mb-3 mb-md-0 ms-md-3">
            <FormSelect
              fieldName="consented_to"
              prompt="Consent"
              options={consentedToFilterOptions}
              selected={form.consented_to}
              onChange={(value) => setForm({...form, consented_to: value})}
            />
          </div>
        )}
        <div className="d-sm-block d-md-inline-block mb-3 mb-md-0 ms-md-3">
          <FormSelect
            fieldName="sort_by"
            prompt="Sort name"
            options={[
              {label: "Last name, ASC", value: "last_name_asc"},
              {label: "Last name, DESC", value: "last_name_desc"}
            ]}
            selected={form.sort_by}
            onChange={(value) => setForm({...form, sort_by: value})}
          />
        </div>
        <a className="text-primary inter semibold mx-1 mx-md-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAdvanced" aria-expanded="false" aria-controls="collapseAdvanced" onClick={toggleIsExpanded}>
          <i className="fa-regular fa-bars-filter"></i>
        </a>
      </div>
      <div className="d-sm-block d-md-flex align-items-center flex-wrap">
        <div className="mt-3 collapse" id="collapseAdvanced">
          <div className="d-sm-block d-md-inline-block mb-3 mb-md-0">
            <AsyncMultiSelect
              name= "Tags"
              selected={selectedTags || []}
              optionsUrl={tagOptionsUrl}
              onSelect={(value) => updateTags(value)}
            />
          </div>
          <div className="d-sm-block d-md-inline-block mb-3 mb-md-0 ms-md-3">
            <FormSelect
              fieldName="populations"
              prompt="Population"
              options={populationOptions}
              selected={form.populations}
              onChange={(value) => setForm({...form, populations: value})}
            />
          </div>
          {consentFlipperEnabled && (
            <>
              <div className="d-sm-block d-md-inline-block mb-3 mb-md-0 ms-md-3">
                <FormSelect
                  fieldName="consent_type"
                  prompt="Consent type"
                  options={consentTypeFilterOptions}
                  selected={consentType}
                  onChange={(value) => setConsentType(value)}
                />
              </div>
              <div className="d-sm-block d-md-inline-block mb-3 mb-md-0 ms-md-3">
                <FormSelect
                  fieldName="consented_to"
                  prompt="Consent"
                  isDisabled={!consentType}
                  options={consentedToFilterOptions}
                  selected={form.consented_to}
                  onChange={(value) => setForm({...form, consented_to: value, consent_type: consentType})}
                  containerClassName={consentType ? "" : "disabled-pointer"}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
 };
export default SearchFilters;
