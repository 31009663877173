export default {
  "date": {
    "order": [
      "year",
      "month",
      "day"
    ],
    "month": "Hli",
    "day": "Hnub",
    "year": "Xyoo",
    "january": "Ib Hlis Ntuj",
    "february": "Ob Hlis Ntuj",
    "march": "Peb Hlis Ntuj",
    "april": "Plaub Hlis Ntuj",
    "may": "Tsib Hlis Ntuj",
    "june": "Rau Hli Ntuj",
    "july": "Xya Hli Ntuj",
    "august": "Yim Hli Ntuj",
    "september": "Cuaj Hli Ntuj",
    "october": "Kaum Hli Ntuj",
    "november": "Kaum Ib Hlis Ntuj",
    "december": "Kaum Ob Hlis Ntuj"
  },
  "user": {
    "send_appointment_confirmation_message": "Koj qhov teem caij mus ntsib raug lees rau {{name}}.",
    "landline_appointment_reminder_message": "Nyob zoo, {{full_name}}.Qhov no yog ib qho ceeb toom rau koj qhov teem caij mus ntsib rau ntawm {{name}}.Koj qhov teem caij mus ntsib rau {{date}}rau thaum {{time}} rau ntawm {{address}}.",
    "send_at_home_visit_confirmation_message": "Koj qhov teem caij mus ntsib raug lees rau {{date}} tom qab {{time}}.",
    "send_mail_order_confirmation_message": "Koj daim ntawv txib yuav tshuaj raug lees lawm",
    "send_waitlist_message": "Koj twb muaj npe nyob tos rau {{name}}.Thov txhob tuaj tshwm ntsej muag kom mus txog rau thaum peb lees lub sij hawm teem caij tuaj ntsib lawm.",
    "verify_contact": {
      "phone": "Thov lees koj tus nab npawb xov tooj los ntawm nias rau qhov no: {{confirmation_link}}",
      "email": "{{confirmation_link}}"
    },
    "duplicate_user_continue_registering": "Tso npe txuas ntxiv rau ib qho teem caij mus ntsib rau ntawm no: {{url}}",
    "on_demand_confirmation_message": "Koj tso npe rau {{name}} tiav lawm.",
    "appointment_reminder": "Ua ntej koj qhov teem caij rau thaum {{start_time}}, thov nias lub link no thiaj li mus ua kom tiav tej yam uas yuav tau ua thiab mus saib koj qhov kev lees.Thov txhob tuaj kom txob tshaj 15 feeb ua ntej koj qhov teem caij.",
    "appointment_reminder_base": "{{text}} NIAS NTAWM NO: {{link}}",
    "test_result_notifier": {
      "text_message": "Tus neeg mob cov ntaub ntawv hauv oos lai (portal) rau {first_name_with_last_initial} raug kho dua tshiab lawm. Mus rau ntawm {{url}} thiab siv tus qhauj: {{access_code}}",
      "email_subject": "Tej uas kho dua tshiab rau hauv koj tus neeg mob cov ntaub ntawv hauv oos lai (portal)",
      "email_intro": "Nyob zoo {{first_name_with_last_initial}}",
      "email_p1": "Muaj ib kab ntawv sau tshiab los sis tus neeg mob tej ntaub ntawv kho dua tshiab rau koj lawn.",
      "email_p2": "Nias qhov nias hauv qab no kawg no los sis siv lub link no los mus saib koj tus neeg mob cov ntaub ntawv thiab siv tus qhauj",
      "email_p3": "Saib tus neeg mob cov ntaub ntawv rau ntawm:'",
      "email_button": "Koj tus neeg mob cov ntaub ntawv raug kho dua tshiab lawm."
    },
    "landline_appointment_reminder_message_without_time": "Nyob zoo, {{full_name}}.Qhov no yog ib qho ceeb toom rau koj qhov teem caij mus ntsib rau ntawm {{name}}.Koj qhov teem caij mus ntsib rau {{date}}rau ntawm {{address}}.",
    "appointment_reminder_on_demand": "Ua ntej koj qhov teem caij rau thaum {{date}} thov nias lub link no thiaj li mus ua kom tiav tej yam uas yuav tau ua thiab mus saib koj qhov kev lees.",
    "new_test_result_notifier": {
      "email_intro": "Mus saib koj cov ntaub ntawv Kho Mob Teev Tseg Yav Dhau Los nyob hauv qab no los mus saib ib tug neeg mob tshiab tej ntaub ntawv teev tseg los sis cov ntawv teev tseg txog kev kuaj mob.",
      "email_text_1": "Mus saib koj li Keeb Kwm Kho Mob Yav Dhau Los hauv qab no thiaj li pom koj qhov ua tau los thiab daim ntawv teev tseg txog qhov kuaj mob tau los.",
      "email_button": "Keeb Kwm Kho Mob Yav Dhau Los",
      "email_text_2": "Koj tuaj yeem theej thiab muab qhov URL no tso rau hauv koj lub browser:"
    },
    "mailers": {
      "email_contact_us": "Puas muaj lus nug? Hu rau peb ntawm"
    }
  },
  "errors": {
    "generic": {
      "message": "Qee yam tsis raug lawm, thov sim dua ib zaug ntxiv"
    },
    "messages": {
      "blank": "txhob cia khoob lug tsis sau rau"
    },
    "incorrect_credentials": "Koj cov ntaub ntawv lees paub tsis raug lawm, thov rov qab sim dua.",
    "error_activating_test_kit": "Muaj ib qho yuam kev rau thaum qhib siv qhov cuab yeej ntsuas no."
  },
  "type": "Hom",
  "payment": {
    "continue_label": "Kav tsij Them mus ntxiv",
    "policy_text": "Yog koj thim los sis tsis mus tshwm ntsej muag rau thaum koj qhov teem caij mus ntsib, peb yuav thim koj cov nyiaj them li 80%, 7 hnub tom qab koj hnub teem caij mus ntsib.Koj yuav saib tag nrho [Terms of Service]({{link}})",
    "card_number": "Nab Npawb Npav",
    "expiry_date": "Hnub tag sij hawm",
    "pay_button": "Them",
    "no_credit_card": "Kuv yuav them nyob rau tom chaw",
    "cash_payment_helper": "Nco qab ntsoov nqa **{{payment}}** thaum tuaj mus raws koj qhov teem caij",
    "invoice": "Daim ntawv xa nqi",
    "pay_by_card": "Them raws daim npav",
    "cost": "Tus Nqi",
    "total": "Tag Nrho",
    "pay_cash_at_appointment": "Kuv yuav siv nyiaj ntsuab them thaum kuv tuaj mus ntsib rau ntawm {{appointment}}",
    "view_invoice": "Saib/Luam Tawm Daim Ntawv Xa Nqi",
    "refund_policy": "Yog tias koj tso tseg koj lub sijhawm teem tseg, peb yuav them nyiaj rov qab {{amount}} ntawm koj cov nyiaj them, 7 hnub tom qab hnub koj teem sijhawm. Koj tuaj yeem saib tag nrho [Cov Ntsiab Lus Cai ntawm Kev Pabcuam]({{link}})",
    "refund_window_passed": "Koj lub sijhawm teem tseg tsis tsim nyog tau txais cov nyiaj them rov qab vim lub sijhawm teem tseg tau dhau mus lawm. Thov hu ncaj qha rau tus kws saib xyuas hauv lub chaw rau txhua qhov kev daws teeb meem.",
    "amount_paid": "Tag Nrho Cov Nqi Uas Yuav Tau Them",
    "balance": "Cov Nyiaj Seem"
  },
  "consent_waiver": {
    "upload_waiver_or_sign": "Rub ib daim ntawv foos zam los sis kos npe rau kem plaub fab hauv qab no",
    "stop": "Yuav Tsum Pom Zoo rau Qhov Kev Tso Npe Tiav",
    "parent_or_guardian_required_html": "Txhawm rau txheeb xyuas koj qhov kev tso npe, koj niam thiab txiv, tus neeg saib xyuas, los sis tus neeg muaj cai txiav txim siab yuav tsum muab kev pom zoo rau ntawm daim ntawv foos hauv qab no thiab kos lawv lub npe rau.Koj <b>CANNOT</b> pom zoo cia muab kev saib xyuas rau koj tus kheej.",
    "send_parent_or_guardian": "Koj tuaj yeem xa lub link no rau koj niam thiab txiv los sis tus neeg saib xyuas los sis hais kom lawv kos npe rau hauv qab ntawm nplooj ntawv no.",
    "consent_guardian_name_signature": "Tus Neeg Lub Npe",
    "consent_for_minor": "Kos npe pom zoo tam rau tus me nyuam yaus",
    "guardian_signature": "Kos npe ntawm niam thiab txiv, tus neeg saib xyuas, los sis tus neeg txiav txim txog kev kho mob uas muaj cai",
    "after_you_schedule": "Cov neeg koom uas muaj hnub nyoog {{age}} yuav tsum pom zoo cia ib tug niam los yog txiv los sis tus neeg saib xyuas kos npe tam rau lawv tus kheej.Kav tsij tso npe kom tiav thiaj li tau xa mus rau koj tus neeg saib xyuas los sis kos npe tam rau koj tus me nyuam.",
    "consent_guardian_last_name_signature": "Lub Xeem",
    "consent_first_and_last_name": "Lub Npe thiab Lub Xeem",
    "phone_number": "Tus neeg saib xyuas tus xov tooj",
    "email": "Tus neeg saib xyuas tus email",
    "consent_registrar_with_guardian_name_signature": "Tus neeg saib xyuas lub npe",
    "consent_registrar_with_guardian_last_name_signature": "Tus neeg saib xyuas lub xeem",
    "consent_registrar_first_name_signature": "Tus neeg tuav ntaub ntawv tso npe lub npe",
    "consent_registrar_last_name_signature": "Tus neeg tuav ntaub ntawv tso npe lub xeem",
    "consent_on_behalf": "Kuv tab tom kos npe tam rau ntawm {{first_name}} {{last_name}}.",
    "consent_on_behalf_registrar": "Kuv raug muab lus tso cai los ntawm {{first_name}} {{last_name}} kom los kos npe tam rau ntawm lawv tus kheej.",
    "consent_on_behalf_registrar_with_guardian": "Kuv raug muab lus tso cai los ntawm tus neeg saib xyuas ntawm {{first_name}} {{last_name}} kom los kos npe tam rau ntawm lawv tus kheej.",
    "registrar_phone": "Tus nab npawb xov tooj ntawm Feem Tso Npe",
    "registrar_email": "Feem Tso Npe tus email",
    "consented_by_parent_guardian": "Niam thiab Txiv/Tus Neeg Saib Xyuas",
    "consented_by_decision_maker": "Tus Neeg Txiav Txim Siab Tso Cai Kho Mob",
    "consented_by_guardian_verbal": "Feem Tso Npe nrog rau Tus Neeg Saib Xyuas (tau txais lus pom zoo)",
    "consented_by_registrar": "Feem Tso Npe (tau txais lus pom zoo)",
    "full_name": "Lub npe thiab lub xeem",
    "print": "Luam tawm"
  },
  "registration": {
    "contact_information": "Lub Npe thiab Chaw Nyob",
    "contact_information_additional": "Niam Txiv los sis Tus Neeg Saib Xyuas Tej Ntaub Ntawv Siv Sib Txuas Lus rau Kev Teem Caij Mus Ntsib Kev Sib Txuas Lus thiab Tej Kuaj Tau Los",
    "personal_information": "Tej ntaub ntawv ntsig txog tus kheej ntiag tug",
    "address_placeholder": "Thov ntaus koj qhov chaw nyob tim tsev rau",
    "first_name_label": "Tus Neeg Lub Npe",
    "last_name_label": "Lub Xeem",
    "errors": {
      "state": "Lub xeev yuav tsum muaj 2 tus niam ntawv thiaj li siv tau ua tus qhauj ntawm lub xeev",
      "phone_number_invalid": "Tus nab npawb xov tooj tsis raug",
      "required": "Yuav tsum muaj tus nqi no",
      "email_domain": "Koj qhov ntaus nkag mus tsis raug lawm; thov kuaj xyuas ob lwm ntxiv.Kev tso npe muaj tsawg rau cov neeg tuaj koom uas raug xaiv los ntawm lub koom haum pab dawb.Thov sim siv koj tus email tim chaw hauj lwm los sis tim tsev.Yog koj xav tias koj tsim nyog thiab muaj ib qho yuam kev lawm, thov hu rau feem pab txhawb.",
      "age_requirement": "Hnub yug tsis raug raws li txoj cai ntawm lub hnub nyoog",
      "signature_required": "Yuav tsum tau kos npe",
      "regex_invalid": "Tus nqi no zoo li siv tsis tau lawm.",
      "date_invalid": "Tsis pom muaj hnub no",
      "invalid_entry": "Thov txhob tso npe mus ntxiv lawm.Ua raws li cov lus qhia ntawm daim phiaj saib los sis hu rau koj tus neeg sib tiv toj.",
      "city": "Yuav tsum yog ib lub nroog uas siv tau",
      "survey_unanswered": "Thov kuaj xyuas kom txhua nqe lus nug uas cim tus cim * raug teb kom tag.",
      "postal_code": "Tus zauv qhauj xa ntawv mas yuav tsum muaj 5 tug uas siv tau",
      "field_required": "{{field}} yuav tsum muaj tus nqi rau",
      "option_required": "Koj yuav tsum xaiv ib ntawm cov kev xaiv no",
      "year_length_invalid": "Ntaus hnub yug nrog plaub tus zauv ntawm lub xyoos rau (yyyy)",
      "invalid_day": "Cov hnub yuav tsum nyob rau ncua 1 - 31",
      "reached_max_chars": "Koj twb sau puv cov niam ntawv uas pub sau ntau kawg nkaus lawm.",
      "chars_max": "Cov niam ntawv uas pub muaj ntau kawg nkaus",
      "minimum_length": "Cov niam ntawv ntaus nkag rau tsawg kawg {{length}} yuav tsum ntev li."
    },
    "insurance_status": {
      "question": "Koj puas muaj issalas kho mob?",
      "have_health_insurance": "Muaj, kuv muaj issalas kho mob kawg",
      "do_not_have_health_insurance": "Tsis muaj, kuv tsis muaj issalas kho mob"
    },
    "insurance_policy_holder": {
      "question": "Leej twg yog tus muaj issalas no?",
      "i_am": "Kuv ntag",
      "my_spouse": "Kuv tus txij nkawm los sis tus khub",
      "my_parents": "Kuv niam thiab txiv",
      "someone_else": "Lwm tus neeg",
      "policy_first_name": "Tus Neeg Muaj Issalas Lub Npe",
      "policy_last_name": "Tus Neeg Muaj Issalas Lub Xeem",
      "policy_dob": "Tus Neeg Muaj Issalas Hnub Yug",
      "name": "Txoj Cai Tswj Fwm ntawm Tus Neeg Muaj Issalas:"
    },
    "insurance_information": {
      "title": "Cov Ntaub Ntawv Pab Kas Phais",
      "company_name": "Tuam Txhab Issalas Lub Npe",
      "id_number": "Tus Nab Npawb Npav Tswv Cuab",
      "group_number": "Tus Nab Npawb Pawg Tswv Cuab",
      "secondary_insurance_label": "Kuv muaj issalas thib ob thiab",
      "take_photo": "Thov yees ib daim duab ntawm koj daim npav issaslas. Qhov yees ib daim duab no mas yuav sau tau qee yam ntaub ntawv sau nkag rau.",
      "front_of_card": "Sab xub ntiag daim npav",
      "card_information": "Cov ntaub ntawv hauv daim npav issaslas",
      "back_of_card": "Sab tom qab daim npav"
    },
    "employment_information": {
      "label": "Kev Ua Hauj Lwm",
      "address_1": "Chaw Nyob Tuam Txhab Hauj Lwm",
      "address_2": "Piv txwv: Suite 200",
      "phone_number": "Nab Npawb Xov Tooj Tuam Txhab Hauj Lwm",
      "company": "Lub Npe Ntawm Tuam Txhab Hauj Lwm",
      "postal_code": "Tus Tswv Num Hauj Lwm Tus Postal Code",
      "employed": "Raug Ntiav Ua Hauj Lwm",
      "sole_prorietor": "Yog Tus Tswv Ib Leeg Xwb",
      "not_employed": "Tsis Raug Ntiav Ua Hauj Lwm",
      "reporting_supervisor": "Tus saib xyuas kev tshaj tawm",
      "reporting_department": "Chav hauj lwm kev tshaj tawm",
      "supervisor_name": "Tus thawj saib xyuas lub npe",
      "supervisor_email": "Tus thawj saib xyuas tus email"
    },
    "location_availability": "Kev kuaj ntsuas tam sim no npaj rau cov tswv cuab hauv zej zos uas nyob rau hauv cov chaw no",
    "custom_survey": "Cov nqe lus nug",
    "confirmation": "Muab Lus Xam Pom",
    "waitlisted": "Muaj npe nyob tos",
    "schedule_your_appointment": "Teem Caij Mus Ntsib",
    "information": "Cov Ntaub Ntawv",
    "consent": "Kev pom zoo",
    "location": "Lub Chaw",
    "symptoms": "Cov tsos mob",
    "address_required": "Thov ntaus koj qhov chaw nyob rau",
    "consent_required": "Thov muab kev pom zoo txuas mus ntxiv",
    "required_field": "qhia txog yam yuav tsum muaj",
    "phone_number": "Nab Npawb Xov Tooj Ntawm Tes",
    "email": "Tus email",
    "date_of_birth": "Hnub Yug",
    "minimum_age": "Lub hnub nyoog uas qis tshaj plaws rau qhov mus koom yog {{year}} xyoo.",
    "no_minimum_age": "Tsis muaj lub hnub nyoog qis tshaj plaws rau qhov kev kuaj ntsuas",
    "continue_button": "Txuas ntxiv",
    "email_required": "Tus nqi no yuav tsum ib tug email uas siv tau.",
    "done": "Ua Tiav",
    "signature": "Kos npe",
    "clear_button": "Lwv pov tseg",
    "back_button": "Rov Qab",
    "choose_location": "Xaiv ib Lub Chaw",
    "no_slots": "Tsis muaj kem ntxiv rau ntawm {{location}}",
    "choose_appointment": "Xaiv ib lub sij hawm teem caij mus ntsib rau ntawm {{location}}",
    "appointment_required": "Thov xaiv ib lub sij hawm teem caij mus ntsib",
    "phone_number_required": "Tus nab npawb xov tooj tsis raug lawm",
    "phone_number_label": "Thov ntaus ib tug nab npawb uas tuaj yeem txais cov kab ntawv tau thiaj li tau txais qhov kuaj ntsuas tau los sai dua",
    "symptoms_experiencing": "Thov nias rau cov tsos mob uas tam sim no koj tab tom ntsib.Yog koj tsis muaj cov tsos mob, thov ua txuas mus ntxiv.",
    "household": "Cov Neeg Hauv Tsev Neeg",
    "household_p1": "Xaiv tau, npaj ib qho teem caij mus ntsib rau cov neeg hauv tsev neeg uas kuj tseem xav kom tau txais kev kuaj ntsuas.",
    "add_dependent": "Ntxiv Cov Neeg Hauv Tsev Neeg",
    "remove_dependent": "Tshem tus neeg nyob nrog tawm",
    "dependent_consents": "Tej kev pom zoo",
    "submit": "Ua tiav",
    "add_waitlist": "Ntxiv rau qhov tso npe tos",
    "address": "Chaw Nyob Tom Tsev",
    "address_1": "Kab Chaw Nyob 1",
    "address_2": "Apt/Suite #",
    "address_city": "Nroog",
    "address_state": "Xeev",
    "postal_code": "Tus Qhauj Xa Ntawv (Postal Code)",
    "race_ethnicity": "Haiv Neeg",
    "gender": "Coj Li Txivneej los Pojniam:",
    "self_described_gender": "Kev Piav Txog Yus Tus Kheej Tias Yog Txiv Neej los yog Poj Niam",
    "interpreter": "Puas xav tau kws txhais lus? Yog xav tau, hom lus dab tsi?",
    "consent_to_terms": "Kuv pom zoo rau cov sij hawm thiab cov tshooj lus no.",
    "reg_not_open": "Tsis tau qhib kev tso npe",
    "no_more_avail_spots": "Lub sij hawm uas koj tau xaiv ntawd tsis muaj lawm.Thov sim dua ntxiv.",
    "consent_helper": "Nias thiab rub koj lub mauj los sis siv koj tus ntiv tes kos rau kem plaub fab hauv qab no los mus kos npe",
    "phone_number_unreachable_label": "Xov tooj hauv tsev?",
    "select": "Xaiv",
    "test_surveys": "Cov Nqe Lus Thaum Teem Caij Mus Ntsib",
    "edit": "kho dua",
    "continue_to_registration": "Kav Tsij Kos Npe Txuas Ntxiv",
    "accounts": {
      "already_have_an_account": "Puas tau muaj ib tug as qhauj?",
      "log_in": "Nkag mus",
      "sign_up": "Tso npe",
      "sign_up_description": "Thov ntaus koj tej ntaub ntawv mus tsim koj tus as qhauj thiab txuag lub sij hawm rau kev tso npe rau yav tom ntej.",
      "log_in_description": "Thov ntaus koj tus e-mail thiab cov phav-xab-vawj nkag rau, los sis tso npe rau qhov Google los sis Outlook hauv qab no",
      "sign_in_with_label": "Tso npe nrog {{provider}}",
      "password_must_match": "Cov phav-xab-vawj yuav tsum sib koos",
      "password_uppercase": "Koj cov phav-xab-vawj yuav tsum muaj tsawg kawg (%s) tus niam ntawv loj.",
      "password_lowercase": "Koj cov phav-xab-vawj yuav tsum muaj tsawg kawg (%s) tus niam ntawv me.",
      "password_number": "Koj cov phav-xab-vawj yuav tsum muaj tsawg kawg (%s) tus zauv.",
      "password_special": "Koj cov phav-xab-vawj yuav tsum muaj tsawg kawg (%s) cov niam ntawv tshwj xeeb."
    },
    "password": "Tus Phav-Xab-Vawj",
    "password_confirmation": "Kev Lees Tus Phav-Xab-Vawj",
    "consent_for": "Pom zoo rau {{name}}",
    "book_one_time_appointment": "Tshwj Tseg Kev Teem Caij Mus Ntsib Tib Zaug Xwb",
    "duplicate_users": {
      "exists": "Koj twb tso npe lawm",
      "overlapping_email_and_phone_p1": "Peb xa ib kab ntawv tuaj rau koj lub xov tooj ntawm tes thiab tus email lawm.",
      "p2": "Kev siv koj kab ntawv, koj tuaj yeem hloov koj qhov teem caij mus ntsib los sis ntaus koj kuaj ntsuas tau los rau.",
      "overlapping_phone_and_email_p3": "Thov kuaj xyuas koj tus email los sis xov tooj yog paub kom ntxaws.",
      "overlapping_email_p3": "Thov kuaj xyuas koj tus email yog paub kom ntxaws.",
      "overlapping_phone_p3": "Thov kuaj xyuas koj tus xov tooj yog paub kom ntxaws.",
      "overlapping_phone_p1": "Peb xa ib kab ntawv tuaj rau koj lub xov tooj ntawm tes.",
      "overlapping_email_p1": "Peb xa ib kab ntawv tuaj rau koj tus email lawm.",
      "p4": "Yog koj xav tias qhov no tsis raug lawm, thov tiv toj rau peb ntawm support@primary.health",
      "overlapping_email_and_phone_p3": "Thov kuaj xyuas koj tus email los sis xov tooj yog paub kom ntxaws."
    },
    "duplicate_waitlist": {
      "exists": "Koj twb muaj npe nyob tos lawm",
      "overlapping_email_and_phone_p1": "Peb xa ib lwm kab ntawv lees paub tuaj rau koj lub xov tooj ntawm tes thiab tus email lawm",
      "p2": "Kev siv koj qhov lees paub, koj tuaj yeem sau lwm cov ntaub ntawv ntxiv rau los sis muab koj tus kheej tshem tawm ntawm cov npe tos.",
      "overlapping_phone_and_email_p3": "Thov kuaj xyuas koj tus email los sis xov tooj yog paub kom ntxaws",
      "overlapping_email_p3": "Thov kuaj xyuas koj tus email yog paub kom ntxaws",
      "overlapping_phone_p3": "Thov kuaj xyuas koj tus xov tooj yog paub kom ntxaws",
      "overlapping_email_p1": "Peb xa lwm kab ntawv lees paub tuaj rau koj tus email lawm."
    },
    "insist_guardian_consent": "Kuv tab tom muab kev pom zoo tam rau ntawm",
    "confirmation_section": {
      "title": "Tshuaj xyuas thiab lees",
      "p1": "Thov saib xyuas koj tej ntaub ntawv ua ntej qhov tso npe tiav.",
      "edit_information": "Rov qab mus saib thiab kho"
    },
    "iemodal": {
      "title": "Lub browser tsis txhawb",
      "body": "Tam sim no tuaj yeem tso npe tau rau ntawm cov browsers no"
    },
    "show_other_locations": "Nias mus qhia tawm lwm cov chaw",
    "non_us_address": "Qhov no yog ib qho chaw nyob txawv teb chaws",
    "test_group_user_survey": "Kev Kuaj Tus Neeg Koom",
    "self_consent": "Kuv muab kev pom zoo rau kuv tus kheej",
    "address_country": "Teb Chaws",
    "appointment": "Kev Teem Caij Sib Ntsib",
    "employee_id": "Neeg Ua Hauj Lwm Daim ID",
    "appointment_time": "Sij hawm teem caij sib ntsib",
    "appointment_location": "Thaj chaw",
    "phone_or_email": "Nab npawb xov tooj los sis email",
    "no_self_consent": "Kuv tsis kam tso cai rau kuv tus kheej",
    "no_insist_guardian_consent": "Kuv tsis kam tso cai sawv cev ntawm {{full_name}}",
    "additional_consents_helpertext": "Tej zaum yuav tsum tau kev tso cai ntxiv tom qab ntawd",
    "minimum_age_with_months": "Lub hnub nyoog tsawg kawg rau kev koom tes yog {{year}} xyoos thiab {{month}} lub hlis.",
    "assistive_technology": "Qhib Siv Thev Naus Laus Zis",
    "covid_vaccine_y_n": "Koj puas xav tau txhaj tshuaj tiv thaiv kab mob COVID-19 nrog rau qhov kev hom tseg no?",
    "received_covid_vaccine_y_n": "Koj puas tau txais koob tshuaj tiv thaiv COVID-19 ua ntej dhau los?",
    "covid_vaccinations": "Cov tshuaj tiv thaiv kab mob Covid-19",
    "select_vaccine_dose": "Thov xaiv qhov tshuaj tiv thaiv kab mob uas koj xav tau",
    "demographic_info": "Cov ntaub ntawv fab pej xeem",
    "additional_info": "Cov ntaub ntawv ntau ntxiv",
    "self_described_race": "Piav Qhia Txog Tus Kheej Haiv Neeg",
    "verify_contact_information": "Kev ua pov thawj",
    "verify_contact": {
      "we_sent_you_a_code": "Thov ntaus tus zauv cim uas peb tau xa tuaj {{contact}}",
      "confirmation_code": "Tus zauv cim rau kev lees paub",
      "code_resent": {
        "email": "Lwm tus zauv cim uas tau xa tuaj rau koj tus email",
        "phone_number": "Lwm tus zauv cim uas tau xa tuaj rau koj lub xov tooj"
      },
      "did_not_receive_code": "Tsis tau txais tus zauv cim rau kev lees paub puas yog?",
      "verify_by": {
        "email": "Ua pov thawj lees paub raws hauv email los hloov rau",
        "phone_number": "Ua pov thawj lees paub raws hauv tus naj npawb xov tooj los hloov rau"
      }
    },
    "preferred_method_of_communication": {
      "question": "Hom kev sib txuas lus uas nyiam",
      "helper_text": "Peb yuav tiv toj rau koj txog xov xwm teem caij mus ntsib",
      "phone_number": "Xov tooj (ntaus ntawv SMS xwb thiab tej zaum yuav tau them tus nqi me ntsis)",
      "landline": "Peb yuav pab tsis tau rau tus xov tooj teeb hauv tsev rau lub sij hawm no. Thov qhia tus email rau tej kev sib txuas lus tseem ceeb.",
      "verification_method": "Koj puas xav tau koj tus qhauj lees paub yus tus kheej?",
      "verification_helper_text": "Yuav xa ib tus qhaj tuaj rau koj kom muab koj li kev lees paub tus kheej ua ntej yuav nkag mus."
    },
    "skip_for_now": "Hla rau tam sim no",
    "default_information": {
      "title": "Tau mas, peb mam li siv qhov chaw nyob no",
      "title_v2": "Tau mas, peb yuav siv cov ntaub ntawv tiv tauj no"
    },
    "middle_name_label": "Lub npe nruab nrab",
    "confirm_appointment": "Lees qhov teem caij mus ntsib",
    "dob": "HNUB YUG",
    "different_appointment": "Qhov teem caij mus ntsib sib txawv",
    "select_appointment_time": "Xaiv sij hawm teem caij mus ntsib",
    "decline_address": "Kuv tsis kam muab qhov chaw nyob qhia.Thov hloov siv qhov khoos kas li chaw nyob.",
    "patient_information": "Tus neeg mob cov ntaub ntawv",
    "scan_license": "Luam tus neeg tsav tsheb daim ntawv tso cai tsav tsheb",
    "how_to_contact": "Peb yuav tiv toj rau koj li cas?",
    "decline_email_or_phone": "Kuv tsis kam muab tus email los sis xov tooj qhia.Kuv tso cai rau cov tswj xyuas qhov khoos kas los txais kuv qhov kuaj tau los.",
    "medical_screening": "Kev kuaj mob",
    "show_service_at_this_location": "Qhia txog qhov saib xyuas rau ntawm lub chaw no",
    "verbal_consent_provided": "Raws li Txoj Cai Neeg Meskas Xiam Oob Qhab, tus neeg tuaj koom los sis lawv tus neeg muaj cai txiav txim siab yuav tsum muab kev pom zoo. Yuav tsum tau txais daim ntawv pom zoo los ntawm tus neeg tuaj koom los sis lawv tus neeg muaj cai txiav txim siab kom tsis dhau 24 teev.",
    "unexpired_written_consent_present": "Lub chaw kuaj ntsuas yeej muaj daim ntawv pom zoo uas tseem tsis tau tag sij hawm nyob rau hauv cov ntaub ntawv.",
    "select_to_proceed": "Thov xaiv ib qho kev xaiv saum toj saud los mus ua.",
    "guardians_information": "Cov ntaub ntawv ntawm cov neeg saib xyuas",
    "registrars_information": "Cov ntaub ntawv ntawm tus kws tso npe",
    "optional": "xaiv tau",
    "vfc_eligibility": "Kev tsim nyog rau Tshuaj Txhaj Tiv Thaiv Kab Mob rau Me Nyuam Yaus (Vaccine for Children, VFC)",
    "vfc_eligibility_subtext": "Tej zaum yuav txhaj tshuaj tiv thaiv kab mob dawb rau koj tus me nyuam los ntawm lub chaw CDC Qhov Kev Pab Txhaj Tshuaj Tiv Thaiv Kab Mob rau Me Nyuam Yaus (VFC) rau ntawm cov chaw saib xyuas kev noj qab haus huv, cov chaw muag tshuaj thiab cov chaw kuaj mob uas tau tso npe rau hauv Qhov Kev Pab VFC."
  },
  "gender_key": {
    "male": "Txiv neej",
    "female": "Poj niam",
    "cisgender": "Yeej yog txiv neeg tiag los yog poj niam tiag",
    "non_binary": "Yeej yog txiv neeg tiag los yog poj niam tiag los sis Tsis yog ob hom",
    "other": "Lwm Yam",
    "prefer_to_self_describe": "Tsis muaj qhov hais qhia tias yog txivneej los yog pojniam",
    "prefer_not_to_disclose": "Tsis xav teb",
    "transgender_male": "Hloov Mus Ua Txivneej/Hloov Mua Ua Txivneej",
    "transgender_female": "Hloov Mus Ua Pojniam/Hloov Mus Ua Pojniam",
    "unknown": "Tsis Paub",
    "non_binary_only": "Tsis yog ob hom li",
    "intersex": "Ua ob hom",
    "transgender": "Kev hloov mus ua txiv neej los yog ua poj niam",
    "gender_fluid": "Haiv neeg hloov pauv",
    "not_applicable": "Tsis siv tau"
  },
  "ethnicity": {
    "american_indian_alaska_native": "Neeg Mes Kas Qhab los sis Neeg Xeeb Txawm Alaska",
    "american_indian_central_america": "Neeg Mes Kas Qhab tuaj Sab Qab Teb los sis Nruab Nrab Mes Kas",
    "asian": "Neeg Es Xias",
    "black": "Neeg Dub los sis As Fab Li Kas Mes Kas",
    "latinx": "Neeg Latino, Latinx los sis Hispanic",
    "middle_eastern_north_african": "Neeg Nruab Nrab Sab Hnub Tuaj los sis Neeg Qaum Teb As Fab Li Kas",
    "native_hawaiian_pacific_islander": "Neeg Hawaii los sis Neeg Nyob Pov Txwv Pacific",
    "white_european": "Neeg Dawb lossis Neeg Tawv Dawb",
    "unknown": "Tsis Paub",
    "prefer_not_to_disclose": "Tsis Xav Tshaj Tawm",
    "asian_indian": "Neeg Es Xias Isdias",
    "filipino": "Ntawv Filipino",
    "japanese": "Lus Nyij Pooj",
    "korean": "Lus Kaus Lim",
    "vietnamese": "Ntawv Nyab Laj",
    "other_asian": "Lwm Hom Neeg Asxis",
    "native_hawaiian": "Neeg Keeb Kwm Has Vais",
    "guamanian_or_chamorro": "Neeg Guamanian lossis Neeg Chamorro",
    "samoan": "Neeg Samoan",
    "other_pacific_islander": "Lwm Haiv Neeg Hauv Pas Dej Hia Txwv",
    "chinese": "Neeg Suav",
    "help": "Haiv neeg yog hais txog tej yeeb yam sab nrauv lub cev uas raug siv los faib raws li qhov sib txawv ntawm pab pawg neeg.Haiv neeg tej zaum yuav yog los sit sis yog raws li xam xaj, los sis lub teb chaws uas koj yog neeg xam xaj.",
    "subtitle": "Tej kev cai uas yuav tsum kom peb suav sau tag nrho tej ntaub ntawv xws li nram qab no.",
    "laotian": "Lus Nplog",
    "cambodian": "Lus Kas Pus Cias",
    "other": "Lwm Yam",
    "bangladeshi": "Lus Bangladesh",
    "hmong": "Hmoob",
    "indonesian": "Lus Is Daus Nes Xias",
    "malaysian": "Lus Maslexias",
    "pakistani": "Lus Pakistan",
    "sri_lankan": "Lus Sri Lankas",
    "thai": "Lus Thaib",
    "taiwanese": "Lus Suav Taib Vam",
    "fijian": "Lus Fijian",
    "guamanian": "Lus Guamanian",
    "tongan": "Lus Tongan",
    "bahamian": "Bahamian"
  },
  "languages": {
    "en": "Lus As Kiv",
    "fr": "Lus Fab Kis",
    "es": "Lus Español",
    "so": "Lus Soomaali",
    "hmn": "Lus Hmoob",
    "asl": "Lus Meskas Piav Tes (ASL)",
    "kar": "Lus Karen",
    "am": "Lus Amharic",
    "ru": "Lus русский",
    "om": "Lus Oromo"
  },
  "symptoms": {
    "fever": "Ua npaws los sis no tshee",
    "cough": "Hnoos",
    "muscle_aches": "Mob leeg nqaij",
    "severe_fatigue": "Nkees sab (heev dua qhov ib txwm muaj)",
    "trouble_breathing": "Ua pa nyuaj",
    "diarrhea": "Raws plab",
    "loss_of_smell": "Tsis hnov tsw dab tsi",
    "loss_of_taste": "Tsis hnov qab los",
    "shortness_of_breath": "Ua pa txog siav los sis ua pa nyuaj",
    "headache": "Mob tob hau",
    "sore_throat": "Mob qa",
    "congestion": "Txhaws ntswg los sis los kua ntswg",
    "nausea": "Xeev siab thiab ntuav",
    "close_contact": "Tau nyob ze nrog ib tug neeg uas muaj mob*",
    "helper_text": {
      "close_contact": "*Koj puas tau nyob ze nrog (ze dua 6 fij ntev tsawg kawg 15 feeb) nrog ib tug neeg uas raug lees tias muaj tus kab mob COVID-19 lawm?"
    },
    "suspected_exposure": "Tau poob siab tias tau nphav",
    "none": "Tsis Muaj Cov Tsos Mob"
  },
  "instructions": {
    "title": "Koj twb tso npe lawm tab sis ua ntej koj mus...",
    "p1": "Nplooj ntawv no muaj cov ntaub ntawv hais txog koj qhov teem caij mus ntsib nrog ru koj tus barcode teem caij mus ntsib.",
    "appointment_scheduled": "Koj qhov teem caij mus ntsib raug teem sij hawm lawm",
    "verify_info": {
      "title": "Kev Txheeb Xyuas Qhov Tseeb Ntawm Koj Tej Ntaub Ntawv",
      "p1": "Qhov tseem ceeb ces kho koj tej ntaub ntawv siv sib txuas lus kom siv tau yog li koj thiaj li nkag mus rau koj tus neeg mob tej ntaub ntawv tau sai.",
      "p2": "Ib qho kev lees paub twb raug xa tuaj rau koj tus email lawm.Thov nias ntawm lub link uas tau muab qhia no.",
      "p3": "Yog koj xav tau hloov koj tej ntaub ntawv sib txuas lus, thov nias qhov nyem thov kev pab."
    },
    "verified": "Tau txheeb xyuas qhov tseeb lawm",
    "not_verified": "Tsis Tau Txheeb Xyuas Qhov Tseeb",
    "resend_text": "Puas tau txais lub link no?",
    "resend": "Rov qab xa dua",
    "skip": "Hla Qhov Kev Tshuaj Xyuas thiab Qhia Qhov Kev Lees Paub",
    "name": "Lub Npe",
    "appointment_details": "Kev Teem Caij Sib Ntsib",
    "date_and_time": "Hnub thiab Lub Sij Hawm",
    "add_to_calendar": "Ntxiv Lub Caij Nyoog Rau",
    "instructions": "Cov Lus Qhia Dav Dav",
    "successfully_flash": "Npaj qhov kev teem caij mus ntsib tau lawm!",
    "success_flash_email": "Tau txheeb xyuas qhov tseeb ntawm tus email tau lawm",
    "success_flash_phone": "Tau txheeb xyuas qhov tseeb ntawm tus nab npawb xov tooj tau lawm",
    "mail_order": "Koj yuav txais koj cov ntaub ntawv qhia kuaj ntsuas rau hauv kev xa ntawv tuaj hauv ib lub vij.",
    "at_home": "Ib tug kws kho mob yuav tuaj rau tim koj tsev rau lub sij hawm no",
    "at_home_instructions": "Cov Lus Qhia Rau Tim Tsev",
    "mail_order_instructions": "Cov Lus Qhia Yuav Raws Kev Xa Tuaj",
    "request_additional_appointment": "Tshwj tseg rau qhov teem caij mus kuaj xyuas",
    "book_additional_appointment": "Tshwj Tseg Rau Lwm Qhov Teem Caij Mus Ntsib",
    "confirmation_code": "Tus Qhauj Lees Paub",
    "completed": "Ua tiav",
    "appointment_barcode": "Tus Npas Qhauj Kev Teem Caij Mus Ntsib",
    "dependent_links": "Yim Neeg Qhov Kev Teem Caij Mus Ntsib Lub Links",
    "on_demand_title": "Koj twb tso npe lawm tab sis ua ntej koj mus...",
    "save_link": "Khaws lub link no",
    "verify_phone": "Tshuaj xyuas koj tus nab npawb xov tooj kom raug",
    "verify_phone_description": "Qhov tseem ceeb ces kho koj tej ntaub ntawv siv sib txuas lus kom siv tau yog li koj thiaj li nkag mus rau koj tus neeg mob tej ntaub ntawv teev tseg tau sai.Ib kab ntawv tshuaj xyuas qhov tseeb tau xa tuaj rau koj tus nab npawb lawm.",
    "verify_email_description": "Qhov tseem ceeb ces kho koj tej ntaub ntawv siv sib txuas lus kom siv tau yog li koj thiaj li nkag mus rau koj tus neeg mob tej ntaub ntawv teev tseg tau sai.Ib tsab email ua kev tshuaj xyuas qhov tseeb tau xa tuaj rau koj tus email lawm.",
    "information": "Tej Ntaub Ntawv Kev Tso Npe",
    "follow_up_appointment": "Kev Teem Caij Mus Soj Qab Xyuas",
    "get_directions": "Tau Txais Tej Kev Qhia Kev",
    "cancel": "Them Kev Teem Caij Mus Ntsib",
    "reschedule_appointment": "Rov Teem Caij Mus Ntsib Dua",
    "reschedule_linked_appointments": "Rov Teem Caij Mus Ntsib Dua",
    "no_slots": "Tsis muaj sij hawm ntxiv lawm",
    "check_results": "Kuaj Xyuas Tus Neeg Mob Qhov Portal",
    "follow_up_modal_header": "Thov Teem Caij Rau Koj Koob Tshuaj Thib 2",
    "are_you_sure_you_want_to_cancel": "Puas yog koj xav thim qhov teem caij mus ntsib no?",
    "please_choose_cancellation_reason": "Thov xaiv lub laj thawj uas thim rau hauv qab no",
    "additional_details": "Thov sau ntxiv kom ntxaws rau hauv qab no",
    "errors": {
      "missing_cancellation_reason": "Thov xaiv lub laj thawj uas thim"
    },
    "verify_email": "Tshuaj xyuas koj tus email kom raug",
    "redcap_url": "Nias mus ua koj qhov kev tshawb fawb kom tiav",
    "verify_contact_information": "Tshuaj Xyuas Koj Tej Ntaub Ntawv Siv Sib Txuas Lus Kom Raug",
    "please_also": "Kuj thov kom",
    "new_title": "Kev lees rau {{name}}",
    "contact_method": "Hom kev sib txuas lus",
    "next_steps": {
      "title": "Cov kauj ruam tom ntej",
      "p1": "Koj muaj ib qho twj kuaj ntsuas qhib rau siv. Thaum koj npaj txhij los ua koj qhov kuaj ntsuas, ua raws li cov lus qhia ntawm lub link uas xa tuaj rau koj tus email rov qab mus rau ntawm phab ntawv no thiab qhib kom koj qhov twj ntsuas hauv qab no rov qab ua hauj lwm tau.",
      "administered": "Peb mam li tiv toj rau koj thaum koj qhov twj ntsuas tuaj txog tim lub chaw kuaj mob thiab thaum koj qhov kuaj tau los kuaj tau lawm.Tom qab lawv xa tuaj rau tim lub chaw kuaj mob mas ib txwm siv sij hawm li 3-5 hnub thiaj li kuaj ntsuas tau los. Koj tuaj yeem saib qhov kuaj ntsuas tau los los ntawm phab ntawv ntawm koj li [Keeb kwm kev kho mob yav dhau los]({{link}}) thaum lawv npaj txhij lawm.",
      "resulted": "Qhov kuaj ntsuas tau los muaj nyob rau hauv phab ntawv ntawm koj li [Keeb kwm kho mob yav dhau los]({{link}}).",
      "shipping_information": "Kev xa cov ntaub ntawv"
    },
    "save_this_page": {
      "title": "Kaw phab ntawv no cia",
      "p1": "Siv phab ntawv no los mus hais qhia ib qho kev kuaj ntsuas yus tus kheej los sis saib qhov kuaj ntsuas tau los raws hauv qhov keeb kwm kho mob yav dhau los.",
      "p2": "Yuav nkag mus saib phab ntawv no rau yav tom ntej ces nias rau ntawm lub link nyob hauv koj tsab email lees paub los sis kab ntawv SMS.",
      "p3": "Koj tuaj yeem kaw cia phab ntawv no tseg uas cim rau nws tseg, tso nws ntxiv rau ntawm daim phiaj saib xub thawj los sis theej lub link coj mus rau ib lub chaw uas nyab xeeb."
    },
    "show_my_barcode": "Qhia tawm kuv tus npas-qhauj",
    "my_account": "Kuv tus as qhauj",
    "register_another": "Tso npe rau lwm tus neeg koom",
    "update_vaccine": "Qhia xov xwm tshiab txog tshuaj tiv thaiv kab mob",
    "medical_history": "Keeb kwm kev noj qab haus huv yav dhau los",
    "upcoming_appointments": "Cov Kev Teem Caij Yuav Los Tom Ntej No",
    "reschedule": "Rov teem caij dua",
    "resend_confirmation": "Kev lees qhov rov qab xa dua",
    "appointment_details_v2": "Hais ntxhaws txog kev teem caij",
    "confirm_cancellation": "Lees qhov thim",
    "confirm_cancellation_question": "Puas yog koj xav thim qhov teem caij mus ntsib no?",
    "select_new_appointment_time_below": "Xaiv lub sij hawm teem caij tshiab rau hauv qab no. Yog tias koj xav hloov kho cov kev saib xyuas los sis lub chaw teem caij mus ntsib, ces thov thim qhov teem caij no thiab teem caij rau ib qho tshiab.",
    "no_take_me_back": "Tsis yog xav thim, yuav teem caij li qub",
    "yes_cancel_appointment": "Yog, xav thim qhov teem caij mus ntsib",
    "update_appointment": "Hloov kho qhov teem caij mus ntsib",
    "select_new_appointment_time": "Xaiv lub sij hawm teem caij tshiab",
    "clinic": "Lub Chaw Kuaj Mob",
    "services": "Cov kev saib xyuas",
    "appointment_missed": "Plam tsis tau mus ntsib raws li teem caij",
    "appointment_canceled": "Tau thim qhov teem caij mus ntsib",
    "screening_complete_for": "Screening complete for {{name}}"
  },
  "result_page": {
    "errors": {
      "invalid_access_code": "Tus qhauj nkag mus tsis raug"
    },
    "landing": {
      "p1": "Yuav saib koj qhov kuaj ntsuas tau los, thov ntaus:",
      "access_code_label": "Tus qhauj nkag mus",
      "submit_button": "Xa",
      "recent_results": "Koj qhov kuaj ntsuas tau los tam sim no"
    },
    "view_results": {
      "headline": "Qhov Kuaj Tau Los rau {{name}}",
      "reregister": "Rov tso npe dua",
      "status": {
        "likely_positive": "Qhov Kuaj Ntsuas Tau Los Tsis Raug Tag Tag",
        "test_not_performed": "Yuav Tsum Tau Rov Kuaj Ntsuas Dua",
        "results_ready": "Qhov Kuaj Ntsuas Tau Los Muaj Lawm",
        "processing": "Txheej txheem ua",
        "test_status": "Qhov Kuaj Ntsuas Nyob Li Cas Lawm",
        "test_result": "Qhov Kuaj Ntsuas Tau Los",
        "administered": "Tau txhaj tshuaj lawm",
        "results_pending": "Tseem tos qhov kuaj ntsuas tau los",
        "test_results": "Qhov kuaj ntsuas tau los"
      },
      "at": "nyob ntawm {{address}}"
    },
    "result_label": "Qhov kuaj ntsuas tau los",
    "result": {
      "flu_a": {
        "result_label": "Qhov tau los ntawm kev kuaj tus kab mob Khaub Thaus A"
      },
      "flu_b": {
        "result_label": "Qhov tau los ntawm kev kuaj tus kab mob Khaub Thaus B"
      },
      "covid19": {
        "result_label": "Qhov tau los ntawm kev kuaj tus kab mob COVID-19"
      },
      "covid": {
        "result_label": "Qhov tau los ntawm kev kuaj tus kab mob COVID-19"
      },
      "sars": {
        "result_label": "Cov txiaj ntsig uas tau txais txog SARS"
      },
      "control": {
        "result_label": "Tuav tswj"
      },
      "rsv": {
        "result_label": "Qhov kuaj ntsuas tau los ntawm RSV"
      },
      "result": {
        "result_label": "Qhov ua tau los"
      },
      "hba1c": {
        "normal": "Tab tom haum",
        "warning": "Pib muaj kab mob ntshav qab zib",
        "danger": "Kab mob ntshav qab zib"
      },
      "lead_venous": {
        "danger": "Muaj qhov txawv lawm",
        "normal": "Tab tom haum"
      },
      "tc": {
        "result_label": "Qhov Kuaj Xyuas Tau Los TC"
      },
      "hdl": {
        "result_label": "Qhov Kuaj Xyuas Tau Los HDL"
      },
      "trg": {
        "result_label": "Qhov Kuaj Xyuas Tau Los TRG"
      },
      "ldl": {
        "result_label": "Qhov Kuaj Xyuas Tau Los TDL"
      },
      "non_hdl": {
        "result_label": "Qhov Kuaj Xyuas Tau Los Non-HDL"
      },
      "tc_hdl_ratio": {
        "result_label": "Feem pua ntawm TC/HDL"
      },
      "glu": {
        "result_label": "Qhov Kuaj Xyuas Tau Los GLU"
      },
      "alere_cholestech_ldx": {
        "danger": "Nyob Rau Qib Muaj Mob Tau",
        "warning": "Muaj qhov txawv lawm",
        "normal": "Tab tom haum"
      },
      "lead": {
        "result_label": "Kuab txhuas"
      },
      "zinc": {
        "result_label": "Kuab Zince"
      },
      "lead_standard_profile": {
        "danger": "Muaj qhov txawv lawm",
        "normal": "Tab tom haum"
      },
      "creatinine": {
        "danger": "Muaj qhov txawv lawm",
        "normal": "Tseem zoo"
      },
      "igg": {
        "result_label": "Qhov kuaj tau los ntawm IgG"
      },
      "igm": {
        "result_label": "Qhov kuaj tau los ntawm IgM"
      },
      "blood_glucose_fasted": {
        "normal": "Qab no",
        "warning": "Txiv txhawm",
        "danger": "Txiv txhawm",
        "hypoglycemia": "Kab mob ntshav piam thaj tsawg",
        "prediabetes": "Tsis tau mob ntshav qab zib kiag",
        "diabetes": "Mob ntshav qab zib"
      },
      "total_cholesterol_fasted": {
        "normal": "Qab no",
        "elevated": "Txiv txhawm",
        "high": "Txiv txhawm",
        "low": "Txhawm nab",
        "abnormal": "Tsis zoo"
      },
      "total_cholesterol_unfasted": {
        "normal": "Qab no",
        "elevated": "Txiv txhawm",
        "high": "Txiv txhawm"
      },
      "a1c_now": {
        "normal": "Qab no",
        "warning": "Qab no txog diabetes",
        "danger": "Kab mob ntshav qab zib"
      },
      "blood_glucose": {
        "warning": "Tsis muaj zog",
        "normal": "Ib txwm muaj",
        "prediabetes": "Mob ntshav qab zib",
        "danger": "Mob ntshav qab zib",
        "hypoglycemia": "Kab mob ntshav piam thaj tsawg",
        "medical_emergency": "Kev kub ntxhov mob nkeeg",
        "inconclusive_1": "Tsis txiav txim 1",
        "inconclusive_2": "Tsis txiav txim 2",
        "possible_diabetes": "Muaj peev xwm mob ntshav qab zib"
      },
      "triglycerides": {
        "result_label": "Cov Roj Triglycerides"
      },
      "blood_glucose_ucsf": {
        "low": "Tsis txaus",
        "normal": "Nruab nrab",
        "high": "Siab"
      },
      "syphilis": {
        "result_label": "Qhov txiaj ntsig syphilis"
      },
      "hepatitis_c": {
        "result_label": "Qhov txiaj ntsig hepatitis C"
      },
      "hiv": {
        "result_label": "Qhov txiaj ntsig HIV"
      },
      "rapid_hiv": {
        "positive": "Teb tau (Zoo li qhov zoo ua ntej)",
        "negative": "Tsis teb tau (Tsis zoo)",
        "did_not_result": "Tsis raug cai (Tsis paub meej)"
      },
      "rapid_hcv": {
        "positive": "Teb tau (Zoo li qhov zoo ua ntej)",
        "negative": "Tsis teb tau (Tsis zoo)",
        "did_not_result": "Tsis raug cai (Tsis paub meej)"
      },
      "rapid_syphilis": {
        "positive": "Teb tau (Zoo li qhov zoo ua ntej)",
        "negative": "Tsis teb tau (Tsis zoo)",
        "did_not_result": "Tsis raug cai (Tsis paub meej)"
      }
    },
    "documents": "Cov ntaub ntawv",
    "self_administered": "Qhov kev kuaj uas yog yus kuaj yus tau txais nyob ntawm {{location}}",
    "patient": "Neeg mob",
    "medical_history": "Keeb Kwm Kho Mob Yav Dhau Los",
    "overview_title": "Xaiv ib qho kev kuaj ntsuas los sis kev pab cuam los mus saib kom ntxaws ntxiv thiab lwm yam kev nqis tes ua uas xav tau.Thov nco tias tej zaum yuav tsis muaj qee yam kev kuaj ntsuas tau los los si tsuas muaj rau tom qab sib tham nrog tus kws kho mob xwb.",
    "insurance_information": "Sau cov ntaub ntawv is sas las",
    "contact_support": "Yog tias koj xav tau kev pab, thov tiv toj feem pab txhawb.",
    "show": "Qhia tawm",
    "hide": "Nkaum",
    "lab_report": "Daim ntawv teev tseg txog qhov kuaj tau los",
    "contact_provider": {
      "header": "Tau kuaj pom tias kuv muaj kab mob",
      "description": "Koj puas xav tham nrog ib tug kws kho mob los sab laj txog koj qhov kuaj tau los thiab qhov kev kho?",
      "yes_option_text": "Xav, kuv xav tham nrog ib tug kws kho mob",
      "no_option_text": "Tsis xav, kuv twb nyeem thiab to taub kuv qhov kuaj tau los lawm, thiab tsis xav tham nrog ib tug kws kho mob",
      "confirm_phone_number_header": "Lees koj tus xov tooj",
      "confirm_phone_number_description": "Thov lees tus xov tooj uas yuav hu tuaj sab laj nrog koj tau zoo tshaj plaws.",
      "confirm_button_text": "Lees",
      "consultation_confirmed_header": "Tau lees qhov kev sab laj lawm",
      "consultation_confirmed_description": "Ib tug kws kho mob yuav hu xov tooj tuaj rau koj ntawm {{phone_number}} rau hauv 2-3 hnub ua hauj lwm.",
      "acknowledgement_option_helper_text": "Qee yam uas piav qhia ntawd mas yog feem tseem ceeb ntawm kev kho mob thiab yuav qhia rau tus neeg paub txog qhov yuav hu xov tooj mus teem caij sib ntsib li cas yog tias lawv hloov siab.",
      "acknowledgement_confirmed_header": "Tau lees paub lawm",
      "acknowledgement_confirmed_description": "Tej yam ntsig txog feem tseem ceeb ntawm qhov tau txais kev kho mob nrog rau cov chaw kho mob uas hais txog ntawd. Nco ntsoov tias qhov kev sab laj no yog muab kev sab laj pub dawb xwb thiab tus kws kho mob tuaj yeem sau ntawv yuav tshuaj tau los sis tej yam uas yuav pab daws tau lawv tus mob.",
      "acknowledgement_confirmed_change_mind_text": "Yog tias koj hloov siab, cia li nias qhov \"kuv xav tau kev sab laj\" nyob hauv qab no.",
      "request_consultation_button_text": "Kuv xav tau kev sab laj"
    },
    "phone": "Xov Tooj",
    "source": "Source"
  },
  "required": {
    "errors": {
      "address_not_in_bounding_box": "Koj qhov chaw nyob tsis haum raws li tej qauv cai tsim nyog rau ntawm cov chaw hauv qhov khoos kas no."
    }
  },
  "member": {
    "medical_history": "Keeb kwm yav dhau los",
    "view_instructions": "Saib Kev Lees Paub",
    "next_appointment": "Kev Teem Caij Mus Ntsib Tom Ntej",
    "over_18": "Kuv lees paub tias kuv muaj 18 xyoo los sis siab dua lawm",
    "choose_test_configurations_title": "Thov xaiv tej kev saib xyuas uas koj xav teem caij rau qhov no",
    "member_taken_error": "Tus neeg siv twb muaj ib tug as qhauj lawm",
    "choose_test_configurations_subtitle": "Tej kev saib xyuas no siv tau tag nrho cov neeg hauv yim neeg",
    "service": "Kev pab cuam",
    "group_or_location": "Pab Pawg/Thaj Chaw",
    "details": "Hais ntxaws ntxaws",
    "see_all": "Saib tag nrho",
    "no_history": "Tsis muaj keeb kwv yav dhau los rau lub sij hawm no.",
    "medical_history_title_with_name": "{{name}} qhov keeb kwm yav dhau los",
    "no_dashboard": "Tam sim no tsis muaj dab tsi",
    "product": "Khoom tsim",
    "price": "Tus nqi",
    "quantity": "Qhov ntau tsawg",
    "total_services_selected": "Tag nrho tej kev saib xyuas uas tau xaiv",
    "total_price": "Tag nrho tus nqi"
  },
  "or": "los sis",
  "account": {
    "errors": {
      "must_be_13": "Yuav tsum muaj 13 xyoos thiaj li mus tso npe tau ib tug as qhauj"
    }
  },
  "self_administration": {
    "title": "Kev Tswj Xyuas Yus Tus Kheej",
    "self_administer_action": "Tus Kws Tswj Xyuas Tus Kheej",
    "skip_to_link": "Hla mus rau qhov Kev Tswj Xyuas Yus Tus Kheej",
    "select_person": "Xaiv ib tug neeg yuav tawm mus",
    "adult": "neeg laus",
    "child": "me nyuam yaus",
    "checked_out": "KOS NPE TAWM LAWM",
    "go_back": "Rov Qab",
    "switch_to_scanner": "Hloov mus rau lub tshuab yees duab",
    "enter_barcode": "Ntau tus Barcode",
    "scan_barcode": "Yees duab tus Barcode",
    "cancel": "Thim",
    "barcode_for": "Barcode rau",
    "enter_barcode_manually": "Siv tes ntaus tus barcode rau",
    "instructions": "Yees duab los sis ntaus tus barcode los mus nrhiav koj lub fwj ntsuas hauv qab no.",
    "regex_prefix": "Barcode yuav tsum muaj txog",
    "all_completed": "Tiav lawm: Txhua tus tswv cuab hauv yim neeg twb ua kuaj ruam no tiav lawm",
    "errors": {
      "no_appointment": "Teeb meem kev nrhiav qhov teem caij sib ntsib",
      "barcode_in_use": "Tus barcode no twb raug siv dhau los lawm. Yog yav dhau los koj tsis tau siv nrog rau qhov twj siv no ces tham nrog tus neeg lis hauj lwm ntawm lub chaw uas muab qhov twj siv no rau koj thiab thov ib qho twj siv tshiab. Tsis li ces, nias qhov thov kev pab hauv qab no.",
      "test_error_general": "Teeb meem kev tsim qhov kev ntsuas",
      "different_barcodes": "Tus barcodes uas koj ntaus rau tsis haum."
    },
    "confirm_barcode_input": "Ntaus tus barcode dua los mus lees",
    "click_to_self_test": "Nias Rau Ntawm No Los Mus Ntsuas Yus Tus Kheej",
    "or_enter_barcode_manually": "Or enter manually with all letters and numbers"
  },
  "location_details": {
    "next_appointment": {
      "waitlist": "Muaj npe nyob tos",
      "unavailable": "Tsis muaj tej kev teem caij mus ntsib"
    },
    "labels": {
      "address": "Chaw nyob",
      "next_appointment": "Muaj kev teem caij mus ntsib rau lwm zaus",
      "services": "Muaj tej kev saib xyuas"
    }
  },
  "waiting_room": {
    "header": "Koj nyob rau hauv chav nyob tos; thov tos thaum peb txuas koj mus rau feem teem caij.",
    "p1": "Thov ua siab ntev.Peb yuav sib koom ua kom xaus tus kab mob sib kis no.",
    "signature": "Sau npe",
    "p2": "Peb paub tias tus kab mob COVID-19 mas tuaj yeem ua rau tag kev cia siab.Thov tos rau ntawm no peb tab tom ua hauj lwm kom sai li sai tau kom koj nkag mus tau rau hauv lub chaw los teem caij koj lub sij hawm mus ntsib."
  },
  "users": {
    "send_appointment_confirmation_message": "Nyob zoo {{full_name}}.Qhov no yog ib qho ceeb toom rau koj qhov teem caij mus ntsib rau ntawm {{name}}.Koj qhov teem caij mus ntsib rau {{date}}rau thaum {{time}} rau ntawm {{address}}.",
    "send_registration_link": "Nyob zoo, {{full_name}}.Ua raws li lub link no mus tso npe rau koj {{name}} {{registration_link}}"
  },
  "follow_up": {
    "first_dose": "Thawj Koob Tshuaj",
    "previous_appointment": "Kev Teem Caij Mus Ntsib Zaug Tag Los",
    "booked_appointment": "Koj Qhov Kev Teem Caij Mus Ntsib",
    "second_dose": "Koob Tshuaj Thib Ob",
    "choose_second_location": "Xaiv Lub Chaw Teem Caij Mus Ntsib Zaum Ob"
  },
  "cancellation_types": {
    "cant_get_to_location": "Kuv tsis muaj hauj lwm",
    "timing_conflict": "Kuv muaj mob tus kab mob COVID-19 lawm",
    "became_ill_with_covid19": "Kuv twb tau txais qhov kev saib xyuas no rau lwm qhov chaw lawm",
    "received_service_elsewhere": "Lwm Yam",
    "other": "Ntaus Lub Npe Hloov Rau",
    "duplicate_appointment": "Qhov no yog kev teem caij sib tshooj lawm"
  },
  "translation": {
    "consent": {
      "type_name_instead": "Ntaus Lub Npe Rau",
      "type_name": "Ntaus qhov kos npe hloov rau"
    }
  },
  "email": {
    "confirmation": {
      "your_access_code_is": "Koj tus qhauj nkag mus yog",
      "please_confirm_your_email": "Thov lees koj tus email los ntawm kev nias qhov pob nias hauv qab no",
      "hello": "Nyob zoo",
      "click_to_reschedule": "Nias Mus Rau Qhov Qhia Txog Cov Kev Qhia/Rov Teem Caij Mus Ntsib",
      "click_to_reschedule_on_demand": "Nias Mus Rau Qhov Qhia Txog Cov Kev Qhia"
    },
    "cancellation": {
      "your_appointment_has_been_canceled": "Koj qhov teem caij mus ntsib rau thaum {{time}} rau ntawm {{location}} raug thim lawm.Yog qhov no tsis raug lawm, thov email rau support@primary.health"
    }
  },
  "signature_lines": {
    "name": "Tus Neeg Koom Lub Npe",
    "date": "Hnub Tim",
    "signature": "Kos Npe ntawm Tus Neeg Mus Koom",
    "and_or": "THIAB/LOS SIS",
    "guardian_name": "Niam Txiv/Tus Neeg Saib Xyuas Lub Npe",
    "guardian_signature": "Kos Npe ntawm Niam Txiv/Tus Neeg Saib Xyuas"
  },
  "employer_testing": {
    "hello_name": "Nyob zoo {{name}}",
    "results": {
      "negative": "Tsis Muaj Mob",
      "positive": "Muaj Mob",
      "did_not_result": "Tsis Paub",
      "unknown": "Tsis Paub",
      "invalid": "Tsis Raug",
      "pending": "Tseem tos"
    },
    "code_reader": {
      "scan_your_code": "Luam tus qhauj nyob rau ntawm koj daim npav kuaj ntsuas",
      "find_in_top_right_hand_corner": "Tus tus qhauj luam mas tshwj xeeb rau koj qhov kev kuaj ntsuas thiab tuaj yeem pom nyob saum toj kawg sab ces kaum xis ntawm koj qhov kev kuaj ntsuas",
      "center_code": "Teeb tus qhauj nruab nrab rau ntawm no.",
      "already_used_error": "Tus npas qhauj kuaj ntsuas no twb raug siv lawm.Thov tiv toj rau koj lub chaw muag qhov cuab yeej yog xav tau kev pab txhawb.",
      "click_to_scan": "Nias mus luam tuam qhauj QR",
      "scan_new_test_card": "Nias ntawm no mus luam ib daim npav kuaj ntsuas tshiab.",
      "not_working": "Tsis ua hauj lwm?",
      "try_again": "Nias ntawm no rov sim dua ntxiv."
    },
    "continue": "Txuas ntxiv",
    "verify_date_of_birth": {
      "confirm_date_of_birth": "Thov lees paub tus neeg koom hnub yug thiaj li mus tau txuas ntxiv",
      "contact_administrator": "Yog pom tej ntaub ntawv tshwm sim tsis raug, thov hu thov kev pab rau ntawm tus tswj xyuas",
      "error": "Ntaus hnub yug tsis raug— thov sim dua los sis tiv toj rau koj lub chaw tswj xyuas kom muab qhov no kho dua tshiab."
    },
    "result_entry": {
      "code_is_registered": "Koj tus qhauj raug tso npe lawm.",
      "take_the_test": "Ua qhov kev ntsuas",
      "follow_the_instructions": "Ua raws li cov lus qhia uas los nrog koj qhov kev kuaj ntsuas thiab pib koj lub sij hawm 15 feeb kiag thaum koj tso daim so ntsws nkag mus rau ntawm daim kuaj ntsuas.",
      "cant_find_instructions": "Tsis tuaj yeem nrhiav pom cov lus qhia?",
      "view_instructions": "Saib Binax Tam Sim No rau cov lus qhia",
      "start_timer": "PIB LUB TUAV SIJ HAWM 15 FEEB (XAIV TAU)",
      "submit_within_15": "Lus qhia: Xa koj qhov kuaj ntsuas tau los kom tsis pub dhau 15 feeb",
      "enter_test_results": "Ntaus Qhov Kuaj Ntsuas Tau Los",
      "choose_a_result": "Xaiv hom kev xaiv uas piav qhia txog qhov tau los rau ntawm koj daim npav kuaj ntsuas",
      "submit": "Xa",
      "must_log_result_and_photo": "Koj yuav tsum sau tseg qhov kuaj tau los thiab yees duab koj qhov kuaj ntsuas thiaj li mus txuas ntxiv tau",
      "submit_within_15_v2": "Lus qhia: Xa koj qhov kuaj tau los tom qab kuaj ntsuas dhau 15 feeb lawm",
      "click_to_take_photo": "Nias mus yees duab",
      "results_may_be_invalid": "Tej zaum qhov kuaj ntsuas tau los yuav tsis raug"
    },
    "summary": {
      "title": "Lub Ntsiab Lus Ntawm Qhov Kuaj Tau Los",
      "negative_subtitle": "Koj tsis muaj tus kab mob COVID-19",
      "negative_message": "Peb twb xa ib daim ntawv ceeb toom mus rau koj tus tswv num hauj lwm lawm tias koj tuaj yeem rov qab mus ua hauj lwm tau lawm.",
      "positive_subtitle": "Peb tu siab, vim koj muaj tus kab mob COVID-19 lawm",
      "positive_message": "Peb twb xa ib daim ntawv ceeb toom mus rau tim koj tus tswv num hauj lwm lawm tias koj muaj tus kab mob COVID-19 lawm.",
      "what_should_you_do": "Qhov koj yuav tsum ua:",
      "employer_name_resources": "Tej Neeg Tim Koj Tus Tswv Num Hauj Lwm Cov Npe:",
      "follow_cdc_guidelines": "Ua Raws Li lub chaw CDC Cov Lus Qhia"
    },
    "clarifying_results": {
      "title": "Kev Piav Qhia Txog Koj Qhov Kuaj Tau Los",
      "which_did_your_result_most_look_like": "Koj qhov kuaj tau los zoo li qhov twg tshaj plaws?",
      "no_lines": "Tsis pom tej kab li",
      "blue_control_line_only": "Kab tswj tsos xim xiav xwb",
      "pink_sample_line_only": "Kab ua qauv tso xim paj yeeb ntsha xwb",
      "blue_control_line_and_pink_sample_line": "Kab tswj tsos xim xiav THIAB kab ua qauv tsos xim paj yeeb ntsha/tsos xim dub nyoo",
      "still_not_sure": "Tseem Tsis Paub Meej",
      "submit_results": "Xa Qhov Kuaj Tau Los",
      "thank_you": "Ua koj tsaug!",
      "clarification_received_message": "Twb tau txais kev ceeb toom koj qhov kuaj tau los lawm.",
      "only_pink_line": "Tsuas yog kab tsos xim paj yeeb ntsha xwb",
      "no_lines_v2": "Tsis Muaj Kab Li"
    },
    "return_to_confirmation": "Rov qab mus rau tom phab ntawv kev lees"
  },
  "preferred_language": {
    "title": "Hom Lus Nyiam Hais",
    "subtitle": "Koj nyiam hais hom lus twg?"
  },
  "appointment_type": {
    "initial_vaccine_appointment": "Tshuaj Tiv Thaiv Kab Mob Xub Thawj",
    "one_dose": "Tshwj tseg kev teem caij rau ib koob tshaj tshwj xeeb.Tshwj xeeb mas, qhov no yog kuv",
    "title": "Kev Xaiv Koob Tshuaj",
    "first_dose": "Thawj koob tshuaj",
    "second_dose": "Koob tshuaj thib ob",
    "single_dose_title": "Tib Koob Tshuaj Xwb",
    "additional": "Ntxiv",
    "booster": "Txhawb Ntxiv",
    "supplemental": "Ntsig Txog Kev Txhawb Nqa Ntxiv",
    "third_dose": "Koob thib peb nkaus xwb",
    "no_vaccine": "Tsis muaj ib qho hais los saum toj saud li"
  },
  "activemodel": {
    "errors": {
      "models": {
        "importers/results/row": {
          "attributes": {
            "administered_at": {
              "invalid_datetime": "yuav tsum muaj tus qauv {{datetime_format}}"
            },
            "results_at": {
              "invalid_datetime": "yuav tsum muaj tus qauv {{datetime_format}}"
            },
            "uid": {
              "unknown_test": "tsis pom qhov kev kuaj ntsuas"
            }
          }
        },
        "importers/participants/row": {
          "attributes": {
            "access_code": {
              "not_allow_update_test_group_user": "tus neeg siv tawm mus lawm thiab tsis tso cai kos kho ntxiv lawm"
            },
            "date_of_birth": {
              "invalid_date": "yuav tsum muaj tus qauv {{date_format}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} tsis pom muaj, xaiv los ntawm cov zauv uas lees txais tau: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} tsis pom muaj, xaiv los ntawm cov zauv uas lees txais tau: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "tsis pom muaj txivneej los yog pojniam, xaiv los ntawm cov zauv uas lees txais tau: {{genders}}"
            },
            "phone_number": {
              "invalid": "yog ib tus nab npawb tsis raug"
            },
            "population_name": {
              "unknown_population": "{{unknown_population}} tsis pom muaj, xaiv los ntawm cov zauv uas lees txais tau: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "tsis tuaj yeem sau rau yog tsis muaj qhov txivneej los yog pojniam \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "tsis pom muaj txivneej los yog pojniam, xaiv los ntawm cov zauv uas lees txais tau: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "tsis pom muaj qhov coj li txivneej los yog pojniam, xaiv los ntawm cov zauv uas lees txais tau: {{sexual_orientations}}"
            },
            "status": {
              "unknown_status": "lub meej mom tsis raug, xaiv los ntawm cov zauv uas lees txais tau: {{statuses}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}}tsis pom muaj, xaiv los ntawm cov zauv uas lees txais tau: {{races}}",
                "other": "{{unknown_races}} tsis pom muaj, xaiv los ntawm cov zauv uas lees txais tau: {{races}}"
              }
            },
            "vaccination_status": {
              "unknown_vaccination_status": "vaccination status is invalid, pick from the following acceptable values: {{vaccination_statuses}}"
            }
          }
        },
        "importers/organization_user/row": {
          "attributes": {
            "role": {
              "insufficient_privileges": "tsis muaj cai txaus los teeb tsa lub luag hauj lwm"
            },
            "user_id": {
              "unknown_user": "tsis pom tus neeg siv"
            }
          }
        },
        "importers/additional_information/row": {
          "attributes": {
            "additional_information": {
              "invalid_json": "qhov JSON tsis raug lawm"
            },
            "user_id": {
              "unknown_user": "tsis pom tus neeg siv"
            }
          }
        },
        "importers/emr/row": {
          "attributes": {
            "appointment_location": {
              "unknown_location": "tsis pom lub chaw teem caij mus ntsib"
            },
            "date_of_birth": {
              "invalid_date": "yuav tsum muaj tus qauv {{date_format}}"
            },
            "email": {
              "duplicated": "twb siv lawm"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} tsis pom muaj, xaiv los ntawm cov zauv uas lees txais tau: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} tsis pom muaj, xaiv los ntawm cov zauv uas lees txais tau: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "tsis pom muaj txivneej los yog pojniam, xaiv los ntawm cov zauv uas lees txais tau: {{genders}}"
            },
            "phone_number": {
              "invalid": "yog ib tus nab npawb tsis raug"
            },
            "population": {
              "unknown_population": "{{unknown_population}} tsis pom muaj, xaiv los ntawm cov zauv uas lees txais tau: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "tsis tuaj yeem sau rau yog tsis muaj qhov txivneej los yog pojniam \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "tsis pom muaj txivneej los yog pojniam, xaiv los ntawm cov zauv uas lees txais tau: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "tsis pom muaj qhov coj li txivneej los yog pojniam, xaiv los ntawm cov zauv uas lees txais tau: {{sexual_orientations}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}}tsis pom muaj, xaiv los ntawm cov zauv uas lees txais tau: {{races}}",
                "other": "{{unknown_races}} tsis pom muaj, xaiv los ntawm cov zauv uas lees txais tau: {{races}}"
              }
            }
          }
        },
        "importers/additional_consents/row": {
          "attributes": {
            "consent_form_id": {
              "unknown_consent_form": "consent_form not found"
            },
            "access_code": {
              "unknown_user": "user not found"
            }
          }
        },
        "importers/vaccine/row": {
          "attributes": {
            "date_of_birth": {
              "invalid_date": "should have format {{date_format}}"
            },
            "phone_number": {
              "invalid": "is an invalid number"
            },
            "email": {
              "duplicated": "is already used"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} does not exist, pick from the following acceptable values: {{races}}",
                "other": "{{unknown_races}} do not exist, pick from the following acceptable values: {{races}}"
              }
            },
            "gender": {
              "unknown_gender": "gender does not exist, pick from the following acceptable values: {{genders}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation does not exist, pick from the following acceptable values: {{sexual_orientations}}"
            },
            "self_described_gender": {
              "not_allowed": "cannot fill it if the gender is not \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "sex does not exist, pick from the following acceptable values: {{sexes}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} does not exist, pick from the following acceptable values: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} do not exist, pick from the following acceptable values: {{ethnicities}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} is an invalid value, pick from the following acceptable values: {{tag_actions}}"
            },
            "population": {
              "unknown_population": "{{unknown_population}} does not exist, pick from the following acceptable values: {{population_names}}"
            },
            "appointment_location": {
              "unknown_location": "appointment location not found"
            },
            "appointment_date": {
              "invalid_date": "should have format {{date_format}}"
            },
            "dose_type": {
              "invalid_dose": "{{unknown_dose}} is not a valid dose, pick from the following acceptable values: {{acceptable_doses}}",
              "unavailable_dose": "{{dose_type}} is not available within the vaccine_lot, pick from the following available values: {{available_doses}}"
            },
            "vis_version_date": {
              "invalid_date": "should have format {{date_format}}"
            }
          }
        },
        "importers/base/row": {
          "attributes": {
            "appointment_time": {
              "invalid_time": "should have format {{time_format}}"
            }
          }
        }
      }
    }
  },
  "activerecord": {
    "errors": {
      "models": {
        "test_location": {
          "attributes": {
            "metadata": {
              "wrong_json": "is not a valid JSON"
            },
            "name": {
              "taken": "thiab chaw nyob twb raug siv rau lwm Thaj Chaw lawm"
            }
          }
        }
      }
    },
    "attributes": {
      "organization_user": {
        "organization_user_leads": {
          "one": "User",
          "other": "Users"
        }
      },
      "appointment_slot_group": {
        "organization_user_leads": {
          "one": "Location",
          "other": "Locations"
        }
      }
    }
  },
  "hispanic_questions": {
    "hispanic": "Ib Tug Neeg Hispanic los sis Neeg Latino/",
    "not_hispanic": "Tsis Yog Ib Tug Neeg Hispanic los sis Neeg Latino/",
    "hispanic_expanded": {
      "other": "Lwm Tus Neeg Hispanic, Latino/ los sis neeg xeeb txawm Xabpeesniv",
      "mexican": "Neeg Mev, Neeg Mev Meskas, Neeg Chicano/",
      "puerto_rican": "Neeg Pueto Rican",
      "cuban": "Neeg Cuban"
    },
    "subtitle": "Tej kev cai uas yuav tsum kom peb suav sau tej ntaub ntawv xws li nram qab no.",
    "help": "Haiv neeg tsawg yog hais txog ib qho yeeb yam xws li hom lus hais, tej kab lis kev cais, kev ntseeg, thiab lwm tus yeeb yam uas raug siv los faib cais tau qhov sib txawv ntawm pab pawg neeg.Nws yuav yoog los sis tsis yoog raws li ib haiv neeg.",
    "unknown": "Tsis Paub",
    "title": "Haiv Neeg Tsawg"
  },
  "sex_at_birth": {
    "question": "Txiv Neej Los Yog Poj Niam",
    "female": "Poj niam",
    "male": "Txiv neej",
    "help": "Txiv neej los yog poj niam yog hais txog qhov muaj nyob rau ntawm koj daim ntawv pov thawj yug.",
    "non_binary": "Tsis yog ob hom li",
    "subtitle": "Tej kev cai uas yuav tsum kom peb suav sau tag nrho tej ntaub ntawv xws li nram qab no.",
    "decline": "Tsis xav teb",
    "unknown": "Tsis Paub"
  },
  "gender": {
    "help": "Kev Coj Li Txiv Neej Los Yog Poj Niam yog hais txog qhov koj tus kheej yog.Nws yuav yoog los sis tsis yoog raws li hom txiv neej los yog poj niam uas tau hais rau thaum yug.",
    "subtitle": "Yog koj xav sau ntxiv txog koj qhov yog txiv neej los yog poj niam ces thov sau tau."
  },
  "sexual_orientation": {
    "title": "Kev Nyiam Coj Li Txiv Neeg los sis Poj Niam",
    "subtitle": "Yog koj xav sau ntxiv txog koj qhov coj li txiv neej los yog poj niam ces thov sau tau.",
    "gay": "Gay, lesbian, los sis kab ntxwv",
    "heterosexual": "Yog hom neeg nyiam lwm hom los sis hom neeg nyiam ncaj qha",
    "bisexual": "Yog txiv neej los yog poj niam",
    "questioning": "Tseem ua xyem xyav/tsis paub meej/tsis paub",
    "prefer_not_to_disclose": "Xaiv qhov tsis tshaj tawm",
    "unknown": "Tsis Paub",
    "orientation_not_listed": "Tsis muaj npe txog kev nthuav qhia",
    "not_applicable": "Tsis siv tau",
    "pansexual": "Hom neeg muaj ntau yam zeej xeeb",
    "queer": "Hom neeg txawv heev"
  },
  "pronouns": {
    "title": "Hom Npe Nyiam Hu",
    "he": "Nws/nws",
    "she": "Nws/nws",
    "they": "Lawv/lawv",
    "choose_not_to_disclose": "Xaiv qhov tsis tshaj tawm",
    "prefer_to_self_describe": "Xav hais txog yus tus kheej"
  },
  "no_insurance_information": {
    "driver_license_or_state_id": "Daim Ntawv Tso Cai Tsav Tsheb los sis Tus Nab Npawb Npav ID Hauv Xeev",
    "driver_license": "Daim Ntawv Tso Cai Tsav Tsheb",
    "use_ssn_instead": "Siv SSN Hloov Rau",
    "social_security_number": "Tus Nab Npawb Social Security",
    "state": "Xeev",
    "upload_front_of_driver_license": "Rub koj daim ntawv tso cai tsav tsheb los sis tus nab npav ID hauv xeev sab xub ntiag tso rau",
    "choose_file": "Xaiv plhaub ntaub ntawv",
    "no_file_chosen": "Tsis pom muaj qhov xaiv plhaub ntaub ntawv li",
    "no_identification": "Kuv tsis muaj npav",
    "insurance_card_has_a_back": "Kuv daim npav is saws las tshuav sab tom qab lawm xwb",
    "upload_front_of_insurance_card": "Rub sab xub ntiag ntawm koj daim npav is saws las nkag rau",
    "front_of_insurance_card_uploaded": "Twb rub sab xub ntiag ntawm koj daim npav is saws las nkag rau lawm.",
    "insurance_card": "Daim npav is saws las",
    "insurance_card_back": "Sab tom qab daim npav is saws las",
    "back_of_insurance_card_uploaded": "Twb rub sab tom qab ntawm koj daim npav is saws las nkag rau lawm."
  },
  "quidel": {
    "certify_text": "Kuv lees tias kuv tsuas sau tseg qhov kuaj tau los no ib zaug xwb.",
    "entering_results": "Kev Ntaus Koj Qhov Kuaj Tau Los Nkag Rau",
    "review_instructions_1": "Ua Tib Zoo Saib Cov Lus Qhia",
    "review_instructions_2": "Koj tuaj yeem saib cov lus qhia us los ntawm koj qhov kev kuaj ntsuas, nyeem qhov coj kev ib kauj ruam zuj zus, los sis saib daim vis dis aus qhia hauv qab no:",
    "read_instructions": "Nyeem Cov Lus Qhia",
    "watch_video": "Saib Daim Vis Dis Aus",
    "timer": "Pib lub tshuab tuav sij hawm tom tso tus pas so ntswg rau hauv lub raj lawm.Lub tshuab tuav sij hawm yuav nres tom qab 1 feeb (rau koj tshem tus pas so ntswg tawm los ntawm lub raj).Thov nco tseg: Koj yuav tsum rov teeb lub tshuab tuav sij hawm los pib qhov 10 feeb rau koj qhov kuaj ntsuas tau los.",
    "start_timer": "Pib Lub Tshuab Tuav Sij Hawm (Xaiv Tau)",
    "restart_timer": "Nres thiab Pib Dua",
    "resume": "Rov Teeb Dua",
    "one_minute_countdown": "1 feeb dhau mus lawm! Tshem koj tus pas so ntswg tawm ntawm lub raj thiab rov teeb dua lub tshuab tuav sij hawm.",
    "take_photo": "Yees Duab ntawm Koj Theem Kuaj Ntsuas",
    "photo_warning": "Yuav tsum yees ib daim duab thiaj li mus txuas ntxiv tau.",
    "enter_results": "Ntaus Qhov Kuaj Ntsuas Tau Los",
    "choose_result": "Xaiv qhov kev xaiv uas phim zoo tshaj plaws rau koj theem kuaj ntsuas.Puas xav tau kev pab? Nias ntawm no mus saib cov ua piv txwv kuaj tau los.",
    "positive": "Muaj Mob",
    "negative": "Tsis Muaj Mob",
    "unknown": "Tsis Paub",
    "clarify": "Kev Piav Qhia Txog Koj Qhov Kuaj Tau Los",
    "strip_question": "Koj theem kuaj tau los zoo li qhov twg tshaj plaws?",
    "no_lines": "Tsis muaj kab li",
    "pink_line": "Tsuas muaj kab tsos xim paj yeeb ntsha xwb",
    "not_sure": "Tseem tsis paub meej"
  },
  "ihealth": {
    "take_a_photo_of_your_test": "Qhov yees duab tau los",
    "retake_photo": "Rov Yees Duab Dua",
    "capture_photo": "Tuav Daim Duab",
    "confirm_information_correct": "Kuv lees tias tej ntaub ntawv saum toj saud raug lawm.",
    "submit": "Xa",
    "title": "Ntaus tej ua tau los rau {{name}}",
    "subtitle": "Koj tsis tas rub los sis siv qhov twj siv iHealth COVID-19 app.",
    "instruction_title": "Saib cov lus qhia ntawm iHealth",
    "instruction_guide": "Nyeem cov lus qhia",
    "video_guide": "Saib daim vis vis aus",
    "add_photo": "Ntxiv duab",
    "confirm_result": "Lees qhov ua tau los",
    "result_warning": "Xaiv qhov ua tau los txhawm rau xa",
    "confirm_warning": "Lees qhov ua tau los txhawm rau xa",
    "administered_at_label": "Koj ua qhov kev ntsuas no thaum twg lawm?",
    "instruction_subtitle": "Saib cov lus qhia uas los nrog koj qhov kev ntsuas los sis rov qab saib cov lus qhia rau ntawm no."
  },
  "public_test_groups": {
    "title": "Tej twj siv kuaj ntsuas COVID-19",
    "appointment_recoveries_title": "Puas xa rov qab?",
    "appointment_recoveries_button_text": "Nrhiav kuv lub link",
    "search_test_group_title": "Puas yog thawj zaug?",
    "search_test_group_button_text": "Tso npe tam sim no",
    "title_2": "Kev tso npe thiab kev hais qhia mas yooj yim xwb",
    "step_one": "Tus as qhauj tso npe",
    "step_two": "Muab cov qaub ncaug ntim xa rov qab mus",
    "step_three": "Saib qhov kuaj tau los rau hauv oos lais",
    "title_3": "So npe koj qhov twj siv thiaj li siv tau",
    "population_title": "Kev tso npe",
    "population_button": "Siv tau",
    "faq_subtitle": "Kawm kom paub ntau ntxiv",
    "faq_title": "Cov nqe lus uas nquag nug",
    "faq_1": "Kuv yuav xa qhov twj kuaj rov qab li cas?",
    "faq_1_footer": "Saib cov lus qhia Xa Cov Aub Ncaug Ncaj Qha Mus",
    "faq_2": "Kuv twb tso npe lawm tab sis kuv tsis nco qab kuv tus as qhauj lawm.",
    "faq_3": "Kuv yuav ua li cas kuv qhov twj kuaj thiaj li siv tau?",
    "faq_2_subtitle": "Yog tus neeg siv uas yuav tau xa rov qab mus, koj tuaj yeem lees koj qhov hais qhia los ntawm kev siv qhov '**Nrhiav kuv lub link**' rau ntawm qhov nyem saum toj saud.",
    "faq_3_subtitle": "Thov nkag mus siv cov kev xaiv hauv qab no los mus saib kom thoob qhov txheej txheem tus as qhauj Kev Noj Qab Haus Huv Xub Thawj:",
    "footer_text_1": "Yam khoom noj tsis raug pom zoo los sis tso cai los ntawm FDA, tab sis FDA yeej tso cai siv rau kis xwm txheej ceev raws li kis EUA;",
    "footer_text_2": "Yam khoom noj tsuas raug tso cai siv rau kis muab cov aub ncaug coj mus ua qauv kuaj tus kab mob SARS-CoV-2 nkaus xwb, tsis yog lwm yam kab mob vais lav los sis lwm cov kab mob;",
    "footer_text_3": "Kev siv yam khoom no rau kis xwm txheej ceev mas tsuas tso cai rau thaum lub sij hawm ceeb toom tias muaj tej xwm txheej tso cai cia siv rau kis muaj mob xwm txheej ceev raws li Tshooj 564(b)(1) Tsoom Fwv Txoj Cai Kev Tswj Hwm Khoom Noj- Tshuaj thiab Khoom Pleev Kom Zoo Nkauj, 21 U.S.C. § 360bbb-3(b)(1), tshwj tsis yog qhov kev ceeb toom no raug tso tseg lawm los sis tau thim qhov kev tso cai tom qab tsis ntev ntawd lawm.",
    "description": "Xav kom koj muaj kev yooj yim, koj tuaj yeem ua kom koj qhov twj kuaj ntsuas siv tau los ntawm kev mus siv qhov nyem hauv qab no:",
    "subtitle": "Puas yog thawj zaug rau ntawm Qhov Kev Saib Xyuas Xub Thawj?",
    "subtitle_1": "Ntsig txog koj tej twj siv kuaj ntsuas COVID-19",
    "faq_1_1": "Tso lub faj ntim cov qaub ncaug rau hauv lub hnab",
    "faq_1_2": "Tso lub hnab rau hau lub thawv rau uas lo ntawv SalivaDirect. Tso lub thawv rau uas lo ntawv SalivaDirect rau hauv FedEx UN 3373 Pak.",
    "faq_1_3": "Tev daim yas nplaum lo tawm ntawm FedEx UN 3373 Pak thiab nias kom ruaj ruaj rau ntawm Pak.",
    "faq_1_4": "Muab koj cov aub ncaug no xa rov qab mus kom txog rau tib hnub ntad ua ntej lub sij hawm tuaj nqa zaum kawg.Txhob xa koj cov aub ncaug mus rau lub tub xa ntawv rau hnub qab as thiv.",
    "faq_1_5": "Mus saib hauv FedEx website rau ntawm **[fedex.com/labreturns](https://www.fedex.com/labreturns)** los mus saib thaj chaw xa thiab cov sij hawm teem caij tuaj nqa.",
    "demo": {
      "title": "COVID-19 kuaj cov ntaub ntawv ntxiv thiab cov lus qhia hauv tsev",
      "online_instructions": "Cov lus qhia online",
      "download_instructions": "rub tawm cov ntawv qhia",
      "begin": "Pib",
      "instructions": {
        "welcome_title": "Zoo siab txais tos rau qhov ntsuas kab mob COVID-19 rau tim tsev.",
        "welcome_text_1": "Ua ntej koj pib ntsuas, ntxuav koj txhais tes los sis siv tshuaj tua kab mob.",
        "welcome_text_2": "Nco ntsoov ua kom koj txhais tes qhuav ua ntej yuav pib ua.",
        "continue": "Txuas ntxiv",
        "back": "Rov Qab",
        "step_one_title": "Kauj ruam 1: Kuaj xyuas koj cov twj ntsuas",
        "step_one_text_1": "Nrhiav tej twj siv:",
        "step_one_text_2": "Yuav tau siv hnab looj tes (yog tsis muaj muab rau) ces kuj yuav tau siv rau thaum ua kev ntsuas.",
        "step_two_title": "Kauj ruam 2: Tso lub fwj rau hauv lub looj lub fwj",
        "step_two_text_1": "Hle lub hau tawm ntawm **LUB FWJ** ces muab nws tso rau hauv **LUB LOOJ LUB FWJ**.",
        "step_two_text_2": "**Faj seeb:** Yuav tau siv hnab looj tes.",
        "step_five_title": "Kauj ruam 5: Tshem tus pas nrws tawm ntawm lub fwj",
        "step_five_text_1": "Tom qab **IB FEEB**, tshem tus pas nrws tawm ntawm **LUB FWJ** es muab tus pas nrws txhuam nrws lub hau mus rau sab hauv lub fwj kom nias cov kua tawm los.",
        "step_five_text_2": "Muab tus pas nrws pom tshem.",
        "step_six_title": "Kauj ruam 6: Qhib daim ntawv ntsuas",
        "step_six_text_1": "Qhib **DAIM NTAWV NTSUAS** ua tib zoo qhib thiab tuav **DAIM NTAWV NTSUAS** raws li qhia.",
        "step_seven_title": "Kauj ruam 7: Tso daim ntawv ntsuas rau hauv lub fwj",
        "step_seven_text_1": "Tso **DAIM NTAWV NTSUAS** rau hauv **LUB FWJ** kom cov xib xub tiag rau hauv. Tso daim ntawv ntsuas rau hauv **LUB FWJ** kom ntev **TXOG KAUM FEEB** - txhob tuav los sis tshem tawm.",
        "step_eight_title": "Kauj ruam 8: Tshem daim ntawv ntsuas tawm hauv lub fwj",
        "step_eight_text_1": "Tsawg kawg **KAUM FEEB**, tshem **DAIM NTAWV NTSUAS** tawm hauv **LUB FWJ**.Txuas ntxiv, tso **DAIM NTAWV NTSUAS** raws li Kuaj Ruam 10-12.Saib kom DAIM NTAWV NTSUAS nyob rau ntawm tus npoo tiaj thiab kaj zoo.",
        "step_eight_text_2": "**FAJ SEEB:** Qhov kev ntsuas no yuav siv sij hawm li 10 feeb mas thiaj li nyeem tau. Yog qhov kev ntsuas no raug nyeem ua ntej lub sij hawm no lawm los sis raug nyeem ua ntej dua 5 feeb tom qab qhia tshwm lub sij hawm nyeem lawm, ces tej zaum qhov kuaj ntsuas tau los yuav tsis raug lawm (ntsuas pom tsis muaj uas tsis raug, ntsuas pom muaj uas tsis raug, los sis ntsuas tau tsis raug) thiab yuav tau rov qab ntsws dua.",
        "step_nine_title": "Kauj ruam 9: Kuaj xyuas koj qhov ntsuas tau los",
        "step_nine_text_1": "Nws muaj peb hom uas yuav ntsuas tau los.",
        "step_nine_text_2": "Kuaj xyuas rau Qhov Ntsuas Tau Los Uas Muaj",
        "step_nine_text_3": "Kuaj xyuas rau Qhov Ntsuas Tau Los Uas Tsis Muaj",
        "step_nine_text_4": "Kuaj xyuas rau Qhov Ntsuas Tau Los Uas Tsis Raug",
        "step_ten_title": "Kauj ruam 10: Kuaj xyuas rau qhov Ntsuas Tau Los Uas Muaj COVID-19",
        "step_ten_text_1": "Tso **DAIM NTAWV NTSUAS** rau ntawm txoj kab daim ntawv ntsuas hauv qab no thiab muab piv rau qhov ua qauv qhov ntsuas tau los.",
        "step_three_title": "Kauj ruam 3: Muab tus pas nrws hauv qhov ntswg",
        "step_three_text_1": "Tshem **TUS PAS NRWS** tawm los ntawm lub phlaub ntim, txhob chwv lub hauv **TUS PAS NRWS**.",
        "step_three_text_2": "Maj mam ntsig **TUS PAS NRWS** kom tob li ½ txog ¾ ya tes rau hauv lub qhov ntswg,saib raws li qhov loj me ntawm tus neeg ntawd lub qhov ntswg.Sib zog siv **TUS PAS NRWS** so mus los rau hauv lub **QHOV NTSWG tsawg kawg yog 4 zaug**.",
        "step_three_text_3": "Nco ntsoov so OB lub qhov ntswg nrog TIB TUG PAS NRWS.",
        "step_three_text_5": "**Faj seeb:** Yog nrws tsis raug ces yuav ua rau qhov ntsuas tau los tsis raug.",
        "step_four_title": "Kauj ruam 4: Tso tus pas nraws rau hauv lub fwj",
        "step_four_text_1": "Tso **TUS PAS NRWS** rau hauv cov kua dej uas nyob hauv **LUB FWJ**, thiab saib kom sib chwv hauv qab kawg. Sib do 3-4 zaug.",
        "step_four_text_2": "Tso tus pas nrws rau hauv cov kua ntev li **IB FEEB.**",
        "step_four_text_3": "**Faj seeb:** Yog tus pas nrws nyob rau hauv cov kua ntev dua 10 fee lawm, ces yuav siv tsis tau lawm.",
        "step_three_text_4": "**Faj seeb:** Yog koj tab tom nrws rau lwm tus neeg, ces rau daim npog ntsej muag.Rau cov me nyuam yaus, ces koj tsis tas yuav ntsig nkag mus tob rau hauv lub qhov ntswg.Rau txhua tus me nyuam mos, tej zaum yuav muaj lwm tus neeg los tuav tus me nyuam lub taub hau thaum nrws."
      },
      "choose_language": "Xaiv koj hom lus"
    }
  },
  "self_resulting": {
    "report_result": "Tshaj tawm qhov kuaj tau los",
    "report_result_subtitle": "Yog koj koj lub chaw muab qhov kev kuaj ntsuas tus kheej, thiab koj twb npaj los tshaj tawm qhov kuaj tau los lawm, ces nias qhov hauv qab no.",
    "need_help": "Puas xav tau kev pab?",
    "assistance": "Yog koj xav tau kev pab, los sis xav hloov koj tej ntaub ntawv sib tiv toj, ces tiv toj rau Lub Chaw Pab Txhawb.",
    "contact_support": "Tiv toj rau feem pab txhawb",
    "save_link": "Khaws lub link no cia mam li hais qhia koj kuaj tau los tom qab.",
    "send_via_email": "Xa lub link mus raws hauv email",
    "send_via_text": "Xa lub link mus raws SMS",
    "copy_link": "Theej lub link",
    "resend_email": "Xa ib tsab email ua kev txheeb xyuas qhov tseeb",
    "phone_verified": "Tau txheeb xyuas qhov tseeb raws hauv xov tooj lawm",
    "add_vaccination_status": "Ntxiv/Qhia Dua Tshiab Txog Kev Txhaj Tshuaj Tiv Thaiv Kab Mob COVID-19",
    "terms_of_service": "Tej ntsiab lus ntawm kev pab cuam",
    "verify_contact_information": "Txheeb xyuas qhov tseeb rau koj tej ntaub ntawv sib tiv toj kom koj thiaj li tau txais qhov kuaj tau los thiab kev sib txuas lus mus los.Yog koj xav hloov koj tej ntaub ntawv sib tiv toj, ces tiv toj rau Lub Chaw Pab Txhawb.",
    "resend_text": "Rov qab xa SMS mus txheeb xyuas qhov tseeb",
    "loading": "Kev rub tso rau",
    "add_to_account": "Ntxiv rau Tus As Qhauj",
    "hi": "Nyob zoo",
    "email_verified": "Tau txheeb xyuas qhov tseeb ntawm tus email lawm",
    "go_to_my_account": "Mus rau kuv tus as qhauj",
    "activate_kit": "Qhib siv cov khoom siv kuaj",
    "report_custom_result": "Qhia tawm qhov tshwm sim: {{test}}",
    "activate_custom_test": "Qhib siv qhov kev xeem: {{test}}",
    "update_vaccination_status": "Hloov kho tshiab txog qhov xwm txheej kev txhaj tshuaj tiv thaiv",
    "order_a_test_kit": "Txib yuav qhov twj siv ntsuas"
  },
  "enter_information": {
    "contact_info": "Ntaus koj cov ntaub ntawv siv sib tiv toj nkag rau",
    "label": "Xov tooj los sis email",
    "invalid": "Tus email los sis tus nab npawb xov tooj no tsis yog lawm.",
    "send": "Peb mam li xa ib tug lab hav tuaj rau koj tus xov tooj los sis email",
    "legal": "Kev ntaus koj tus xov tooj los sis email nkag rau, ces koj pom zoo raws li Primary Health **[Cov Ntsiab Lus ntawm Kev Pab Cuam](https://primary.health/terms-of-service/)** thiab **[Txoj Cai Tswj Hwm Tus Kheej Ntiag](https://primary.health/privacy-policy/)**.",
    "subtitle": "Peb yuav xa ib kab ntawv los sis sau email tuaj pab koj nrhiav koj cov ntaub ntawv teev tseg los sis lub link los mus tshaj qhia qhov kuaj tau los!",
    "find_link": "Puas tau tso npe? Nrhiav koj lub link",
    "not_registered": "Tsis yog tsis tau tso npe lod? Tshawb nrhiav rau koj lub chaw",
    "search_by_keyword": "Tshawb nrhiav raws lub npe, lo lus tseem ceeb, lub nroog, tus zis qhauj, los sis tus lab hav koom haum!",
    "search": "Tshawb Nrhiav",
    "register_here": "Tso npe rau ntawm no"
  },
  "contact_support": {
    "invalid": "Cov ntaub ntawv tsis raug lawm",
    "invalid_explanation": "Tsis ntev los no koj twb thov tso npe rau ntawm lub link rau Primary.Health.Txawm li cas los xij,",
    "never_registered": "Ua ntej dhau los yeej tsis tau tso npe dua li puas yog? Thov tiv toj rau koj lub chaw muab kev ntsuas los nrhiav lub link tso npe, los sis los nrhiav lub chaw hauv qab no.",
    "get_help": "Koj tau txais kev pab sib piv koj cov ntaub ntawv teev tseg rau koj cov ntaub ntawv sib tiv toj nrog",
    "get_help_calling": "los sis hu xov tooj",
    "invalid_explanation_bold": "tej ntaub ntawv uas koj muab qhia mas tsis raug lawm.",
    "get_help_label": "Thov Kev Pab",
    "mobile": {
      "invalid_explanation": "Nrhiav tsis pom cov neeg siv uas yuav tuaj yeem muab coj los ntxiv rau koj tus avkhauj tau.",
      "get_help": "Yog tias koj muaj lus nug ntau ntxiv, ces thov tiv tauj",
      "reason": "Tsis yog tag nrho Primary.Health cov neeg siv txhua tus yuav muaj cai tsim nyog tau txais peb cov Avkhauj tshiab. Yog thaum koj muaj cai tsim nyog tau txais lawm, ces koj yuav tau txais ib tsab ntawv caw los ntawm Primary.Health los sis los ntawm tus neeg saib xyuas koj pab pawg."
    },
    "zen_name": "Lub npe tiv toj",
    "ticket_type": "Qhov no ntsig txog dab tsi",
    "appointment_access_code": "Kev Lees Tus Qhauj (yog paub)",
    "zen_desc": "Ntawv Sau Sib Txuas Lus",
    "contact_us": "Tiv toj rau peb",
    "need_assistance": "Yog koj xav tau kev pab, los sis xav hloov koj cov ntaub ntawv siv sib txuas lus, ces tiv toj rau feem pab txhawb."
  },
  "show_appointments": {
    "welcome": "Nrhiav koj cov ntaub ntawv",
    "welcome_subtitle_1": "Qhia txog cov kuaj tau los hauv qab no rau",
    "added_to_account": "Muab ntxiv rau tus avkhauj lawm",
    "error": "Tej zaum tus neeg koom nrog no twb yeej muaj ib tug avkhauj lawm",
    "welcome_subtitle_2": "Tsis pom lub npe nyob hauv qab no?",
    "welcome_not_logged_in": "Nrhiav ib lub link",
    "welcome_logged_in": "Xaiv koj Pab Pawg",
    "welcome_subtitle_1_logged_in": "Yuav ntxiv ib tswv cuab kom tiav, xaiv tus neeg siv thiab pab pawg.Tau muab ib tug as qhauj rau cov tswv cuab.",
    "welcome_subtitle_2_logged_in": "Yog tias koj tsis pom koj lub npe nyob rau ntawm cov npe teev tswv cuab saum toj saud, ces koj muaj peev xwm",
    "welcome_subtitle_1_not_logged_in": "Xaiv seb leej twg xav nrhiav lawv lub chaw los sis xav tso ib tug tswv cuab tshiab ntxiv. Tsis pom tswv cuab?",
    "welcome_subtitle_3_logged_in": "siv lwm txoj hau kev sib toj toj",
    "welcome_subtitle_4_logged_in": "los sis tiv toj rau peb ntawm",
    "welcome_subtitle_2_not_logged_in": "Qhia tshiab txog qhov tshawb nrhiav.",
    "welcome_subtitle_3": "Qhia tshiab txog koj qhov tshawb nrhiav"
  },
  "enter_code": {
    "code": "Tus lab hav yog dab tsi?",
    "enter_code": "Ntaus tus lab hav xa tuaj mus rau",
    "incorrect_code": "Koj twb ntaus tus lab hav tsis raug lawm.",
    "verify": "Txheeb xyuas tus as qhauj",
    "verified": "Tau txheeb xyuas lawm",
    "verify_account_information": "Lees koj tus as qhauj"
  },
  "general_self_checkout": {
    "choose_test": "Koj tau ua qhov kev ntsuas dab tsi?",
    "photo_required": "Yuav tsum muaj daim duab ntawm qhov ua tau los",
    "find_your_test": "Nrhiav koj qhov kuaj ntsuas"
  },
  "login": {
    "create_account": "Tsim ib tug as qhauj",
    "create_account_help": "Tsim ib tug as qhauj uas tsis zoo ib yam li qhov tso npe rau qhov kev ntsuas los sis kev txhaj tshuaj tiv thaiv kab mob.Thaum koj tso npe lawm",
    "create_account_help_schedule": "Teem caij mus ntsib",
    "create_account_help_record": "Teev tseg tej kev kuaj saib tim tsev",
    "create_account_help_match": "Tswj xyuas tej ntaub ntawv teev tseg txog kev txhaj tshuaj tiv thaiv kab mob thiab kev kuaj ntsuas.",
    "continue": "Ua mus txuas ntxiv raws li tus neeg qhua",
    "already": "Puas tau muaj ib tug as qhauj? Nias",
    "login": "los nkag mus.",
    "or_sign_in": "Los sis tso npe nrog:",
    "no_account": "Tsis tau muaj tus as qhauj puas yog? Nias",
    "signup": "los mus tso npe.",
    "here": "ntawm no",
    "email_address": "Tus email:",
    "password": "Nab Npawb Zais:",
    "complete_registration": "Kev Tso Npe Tiav Lawm",
    "last_covid_test": "Qhov kuaj ntsuas tus kab mob COVID-19 zaum tag los",
    "no_record": "Tsis muaj ntaub ntawv teev tseg li",
    "viewing_information": "Kev tshuaj xyuas {{first_name}}tej ntaub ntawv",
    "download": "Rub tawm los",
    "not_registered": "Nws zoo li koj tsis tau tso npe rau ib pawg twg li",
    "please_follow_link": "Thov soj qab raws lub link caw tuaj rau koj tus email los mus teem caij thiab nkag mus saib koj tej ntaub ntawv nrog Primary.Health.",
    "log_results": "Teev tseg kuv qhov kuaj ntsuas tau los",
    "book_appointment": "Teem caij mus ntsib",
    "no_new_appointments": "Tam sim no tsis kam lees txais kev teem caij mus ntsib rau cov neeg tshiab lawm",
    "upload_vaccinations": "Rub tej ntaub ntawv txhaj tshuaj nkag rau",
    "vaccination": "kev txhaj tshuaj tiv thaiv kab mob",
    "new_appointment": "Kev teem caij mus ntsib tshiab",
    "log_otc_results": "Teev tseg qhov kuaj tau los rau ib qho kev kuaj ntsuas OTC",
    "no_household_members": "Koj tseem tsis tau muaj cov neeg hauv yim neeg li.",
    "no_upcoming": "Tsis muaj kev teem caij mus ntsib li",
    "update_vaccination": "Qhia tshiab txog kev txhaj tshuaj tiv thaiv kab mob",
    "upload_vaccination": "Rub qhov kev txhaj tshuaj tiv thaiv kab mob nkag rau",
    "title": "Zoo siab txais tos tuaj rau Primary.Health",
    "sub_title": "Before registering please create an account.",
    "p1": "Kev tsim ib tug as qhauj thiaj li qhia rau koj paub tias:",
    "p2": "Teem caij mus ntsib rau yav tom ntej",
    "p3": "Teev tseg tej kev kuaj ntsuas tus kab mob COVID-19 rau tim tsev",
    "p4": "Tswj xyuas tej ntaub ntawv teev tseg txog kev txhaj tshuaj tiv thaiv kab mob thiab kev kuaj ntsuas rau cov neeg nyob nrog",
    "p5": "Cia li nkag mus raws txheej txheem los ntawm my.primary.health",
    "no_vaccination_record": "Tseem tsis hais kom txhaj tshuaj tiv thaiv kab mob ntxiv",
    "vaccination_title_card": "Qhov kev txhaj tshuaj tiv thaiv kab mob Covid-19 zoo li cas lawm",
    "account_and_settings": "Tus as qhauj thiab kev teeb tsa",
    "delete_account": "Tshem tus as qhauj tawm",
    "language": "Hom lus",
    "language_current": "Hom lus tam sim no: {{language}}",
    "log_out": "Tawm mus",
    "delete_account_title": "Koj puas xav tias yuav tshem koj tus as qhauj tawm?",
    "new_encounter": "{{first_name}} ntawm tus ntaus nkag tshiab",
    "new_encounter_subtitle": "Tus ntaus nkag tshiab no yog rau pab pawg twg?",
    "no_programs": "Zoo li koj tsis tau tso npe rau ib qho khoos kas twg li",
    "edit_members": "Kho cov tswv cuab",
    "edit_members_subtitle": "Xaiv daim phiaj sau uas koj xav nkag mus rau los sis ntxiv lwm tus tswv cuab nkag rau koj tus as qhauj",
    "add_members": "Ntxiv tswv cuab",
    "delete_member": "Puas tshem {{full_name}} ntawm tus neeg siv tus as qhauj?",
    "delete_member_subtitle": "Kev tshem tus neeg siv tawm mas yuav tshem tag nrho tej ntaub ntawv ntsig txog rau tus as qhauj tawm tib si.",
    "select_member": "Kev xaiv tswv cuab",
    "edit_appointment": "Kho qhov teem caij tuaj ntsib",
    "route": {
      "me": "Daim phiaj sau ntawv",
      "history": "Keeb kwm yav dhau los",
      "programs": "Ntxiv ib qho kev ntsuas",
      "contact_us": "Pab",
      "choose_member": "Tswv cuab",
      "settings": "Kev teeb tsa",
      "help": "Pab"
    },
    "user_dashboard": "{{first_name}} ntawm daim phiaj sau ntawv",
    "view_full_results": "Saib qhov ua tau los",
    "required": "Yuav tsum muaj yog hais tias koj tab tom siv qhov Primary App.",
    "no_email": "Kuv tsis muaj email"
  },
  "vaccination_status": {
    "not_vaccinated": "Tsis tau txhaj tshuaj tiv thaiv kab mob",
    "fully_vaccinated": "Twb txhaj tshuaj tiv thaiv kab mob txhij txhua lawm",
    "partially_vaccinated": "Twb txhaj tshuaj tiv thaiv kab mob ib txhia lawm",
    "vaccination_records": "Cov ntaub ntawv zwm tseg txog kev txhaj tshuaj tiv thaiv",
    "title": "Koj qhov txhaj tshuaj tiv thaiv kab mob COVID-19 nyob rau qib twg lawm?",
    "definition_title": "Cov ntsiab lus ntawm qhov txhaj tshuaj tiv thaiv kab mob",
    "definition_cdc": "CDC cov ntsiab lus ntawm qhov txhaj tshuaj tiv thaiv kab mob",
    "definition_osha": "OSHA cov ntsiab lus ntawm qhov txhaj tshuaj tiv thaiv kab mob",
    "definition": {
      "fully_vaccinated": "2 lub vij tom qab txhaj koob tshuaj kawg hauv hom tshuaj Pfizer-BioNTech los sis Moderna uas txhaj 2 koob, los sis hom tshuaj Johnson & Johnson hom tshuaj Janssen uas txhaj 1 koob.",
      "partially_vaccinated": "Tsuas tau txhaj 1 ntawm 2 koob tshuaj rau thaum xub thawj xwb, los sis tsawg du 2 lub vij tom qab txhaj koob tshuaj kawg hauv hom txhaj 2 koob los sis 1 koob ntawm hom tshuaj Johnson & Johnson's Janssen.",
      "not_vaccinated": "Tseem tsis tau muaj tshuaj tiv thaiv tau kiag tus kab mob COVID-19.",
      "fully_vaccinated_with_one_booster": "Tau txhaj koob tshuaj txhaj txhawb ntxiv ntawm hom tshuaj Pfizer-BioNTech los sis Moderna tom qab txhaj puv raws li qhov txhaj lawm los sis 1 koob tshuaj tiv thaiv kab mob ntawm hom Johnson & Johnson's Janssen.",
      "fully_vaccinated_with_two_boosters": "Tau txhaj koob tshuaj txhaj txhawb ntxiv ntawm hom tshuaj Pfizer-BioNTech los sis Moderna rau koob thib ob lawm tom qab txhaj puv raws li qhov txhaj lawm los sis koob tshuaj tiv thaiv kab mob thiab koob tshuaj txhaj txhawb ntxiv ntawm hom Johnson & Johnson's Janssen."
    },
    "label": {
      "fully_vaccinated": "Twb txhaj tshuaj tiv thaiv kab mob txhij txhua lawm",
      "partially_vaccinated": "Twb txhaj tshuaj tiv thaiv kab mob ib txhia lawm",
      "not_vaccinated": "Tsis tau txhaj tshuaj tiv thaiv kab mob",
      "fully_vaccinated_with_one_booster": "Twb txhaj tshuaj tiv thaiv kab mob txhij txhua lawm + koob txhaj txhawb ntxiv",
      "fully_vaccinated_with_two_boosters": "Twb txhaj tshuaj tiv thaiv kab mob txhij txhua lawm + 2 koob txhaj txhawb ntxiv",
      "prefer_not_to_answer": "Tsis xav teb"
    },
    "progress_bar_title": "Keeb kwm kev txhaj tshuaj tiv thaiv kab mob COVID-19",
    "upload_record_card": "Rub koj daim npav teev tseg txog kev txhaj tshuaj tiv thaiv kab mob COVID-19 nkag rau",
    "show_example": "Qhia tawm qhov ua piv txwv",
    "hide_example": "Zais qhov ua piv txwv",
    "take_photo": "Nias mus yees duab",
    "or": "LOS SIS",
    "upload_image_or_pdf_instead": "Rub daim duab/PDF hloov nkag rau",
    "select_doses_received": "Xaiv tag nrho cov koob tshuaj tiv thaiv kab mob COVID-19 uas koj tau txhaj lawm.",
    "first_dose": "Thawj koob tshuaj",
    "second_dose": "Koob tshuaj thib ob",
    "first_booster_dose": "Thawj koob tshuaj txhaj txhawb ntxiv",
    "second_booster_dose": "Koob tshuaj txhaj txhawb ntxiv koob thib ob",
    "additional_dose": "Lwm koob tshuaj ntxiv",
    "first_dose_manufacturer": "Leej twg yog lub chaw tsim tshuaj tiv thaiv kab mob COVID-19 rau koj thawj koob tshuaj?",
    "first_dose_date": "Hnub uas kom txhaj koj thawj koob tshuaj yog hnub twg?",
    "second_dose_manufacturer": "Leej twg yog lub chaw tsim tshuaj tiv thaiv kab mob COVID-19 rau koj koob tshuaj thib ob?",
    "second_dose_date": "Hnub uas kom txhaj koj koob tshuaj thib ob yog hnub twg?",
    "first_booster_dose_manufacturer": "Leej twg yog lub chaw tsim tshuaj tiv thaiv kab mob COVID-19 rau koj thawj koob tshuaj txhaj txhawb ntxiv?",
    "first_booster_dose_date": "Hnub uas kom txhaj koj thawj koob tshuaj txhawb ntxiv yog hnub twg?",
    "second_booster_dose_manufacturer": "Leej twg yog lub chaw tsim tshuaj tiv thaiv kab mob COVID-19 rau koj koob tshuaj txhaj txhawb ntxiv thib ob?",
    "second_booster_dose_date": "Hnub uas kom txhaj koj koob tshuaj txhaj txhawb ntxiv thib ob yog hnub twg?",
    "additional_dose_manufacturer": "Leej twg yog lub chaw tsim tshuaj tiv thaiv kab mob COVID-19 rau koj lwm koob tshuaj ntxiv?",
    "additional_dose_date": "Hnub uas kom txhaj koj lwm koob tshuaj ntxiv yog hnub twg?",
    "completed_one": "Txhaj tiav tag lawm",
    "completed_two": "Ua koj tsaug uas qhia txog koj qhov txhaj tshuaj tiv thaiv kab mob!",
    "progress_bar_complete": "Txhaj tiav lawm",
    "upload_image": "Rub daim duab nkag rau",
    "retake_photo": "Rov qab yees duab dua",
    "other": "Lwm yam",
    "remove_first_dose": "Muab keeb kwm thawj koob tshuaj tshem tawm",
    "remove_second_dose": "Muab keeb kwm koob tshuaj thib ob tshem tawm",
    "remove_first_booster_dose": "Muab keeb kwm thawj koob tshuaj txhaj txhawb ntxiv tshem tawm",
    "remove_second_booster_dose": "Muab keeb kwm koob tshuaj txhaj txhawb ntxiv thib ob tshem tawm",
    "remove_additional_dose": "Muab keeb kwm lwm koob tshuaj ntxiv tshem tawm",
    "exemption": "Koj puas muaj ib qho kev zam?",
    "exempt": "Kuv muaj kev zam",
    "not_exempt": "Kuv tsis muaj kev zam",
    "enter_exemption": "Ntaus qhov kev zam nkag rau",
    "upload_exemption_documentation": "Rub cov ntaub ntawv nkag rau koj qhov kev zam",
    "remove_dose": "Tshem koob tshuaj tawm",
    "continue": "Txuas ntxiv",
    "enter_credentials": "Thov ntaus koj tej ntaub ntawv ntiag tug nkag rau",
    "incorrect_credentials": "Cov ntaub ntawv ntiag tug tsis raug lawm, thov rov qab sim dua.",
    "add_photo": "Ntxiv duab"
  },
  "mailers": {
    "appointment_mailer": {
      "payment_pending": "Nyob zoo {{name}}, Peb pom tias koj tseem tsis tau ua tiav kev teem sijhawm koj {{group_name}} qhov kev teem caij nrog {{org_name}}. Txhawm rau kom ceev cia tau koj lub sijhawm teem tseg, koj yuav tsum ua kom tiav cov kauj ruam them nyiaj. Thov nyem rau ntawm qhov txuas no rau mus ua kom tiav cov txheej txheem {{complete_url}}. Yog tias koj xav thim koj lub sijhawm teem tseg, thov nyem rau ntawm qhov txuas no {{cancel_url}}. Ua tsaug"
    }
  },
  "otc": {
    "record_results_for_abbott": "KKuaj sim thiab teev tseg koj cov txiaj ntsig tau los rau qhov kev xeem BinaxNOW",
    "record_results": "Kuaj sim thiab teev tseg koj cov txiaj ntsig tau los",
    "activate_a_kit": "Qhib siv cov khoom siv",
    "activate_a_kit_for_test": "Qhib siv cov khoom siv rau {{test}}",
    "todays_test_process": "Cov txheej txheem kev kuaj sim hnub no:",
    "date_of_birth_question": "{{first_name}} hnub yug yog yog hnub twg?",
    "confirm_dob_to_continue": "Thov lees paub tus neeg koom lub hnub yug txhawm rau mus txuas ntxiv:",
    "incorrect_dob": "Hnub yug tsis raug",
    "file_too_large": "Thov xyuas kom meej tias qhov loj ntawm cov ntaub ntawv rub nkag me dua {{size}}",
    "enter_valid_date_in_last_x_days": "Sau hnub tim uas siv tau hauv {{n}} hnub dhau los",
    "barcode_format_not_valid": "Hom ntawv ntawm tus zauv barcode siv tsis tau",
    "complete_button": "Ua tiav",
    "next_button": "Tom Ntej",
    "back_button": "Rov Qab",
    "report_a_test": "Qhia tawm ib qho kev kuaj sim:",
    "x_%_completed": "{{n}}% ua tiav",
    "answer_survey": "Teb cov lus nug ntsuam xyuas",
    "steps": {
      "answer_survey": "Teb daim ntawv ntsuam xyuas",
      "review_instructions": "Tshawb xyuas cov lus qhia",
      "scan_code": "Luam tus zauv",
      "enter_time": "Sau lub sijhawm",
      "enter_result": "Sau cov txiaj ntsig tau txais",
      "take_photo": "Yees duab",
      "confirm_result": "Lees paub qhov tshwm sim",
      "selest_test_kit": "Xaiv hom kev xeem",
      "enter_date": "Sau hnub tim",
      "enter_date_and_time": "Sau hnub tim thiab lub sijhawm"
    },
    "review_instructions": "Saib xyuas cov lus qhia kev kuaj sim",
    "follow_instructions_or_below": "Ua raws li cov lus qhia uas los nrog koj qhov kev kuaj sim lossis tshuaj xyuas cov lus qhia hauv qab no.",
    "watch_video": "Saib daim yeeb yaj kiab",
    "view_instructions": "Saib cov lus qhia",
    "follow_instructions": "Ua raws li cov lus qhia uas uas los nrog koj qhov kev kuaj sim.",
    "scan_code": "Luam tus zauv kuaj sim QR",
    "qr_code_description": "Tus zauv QR yog qhov tshwj xeeb rau koj qhov kev kuaj sim thiab tuaj yeem pom nyob rau sab xis saum kawg nkaus ntawm koj qhov kev kuaj sim",
    "show_example": "Qhia qhov piv txwv",
    "hide_example": "Nkaum qhov piv txwv cia",
    "barcode_scanned": "Tus cim barcode raug luam tiav lawm",
    "enter_barcode": "Sau tus zauv barcode",
    "scan_qr_code": "Luam tus zauv QR",
    "which_test_did_you_take": "Koj ua qhov kev kuaj sim twg?",
    "when_did_you_take_test": "Koj ua qhov kev kuaj sim no thaum twg?",
    "what_day_did_you_take_test": "Koj tau ua qhov kev kuaj sim no nyob rau hnub twg?",
    "what_time_did_you_take_test": "Koj ua qhov kev kuaj sim no lub sijhawm twg?",
    "time": {
      "today": "Hnub no",
      "yesterday": "Nag hmo",
      "two_days_ago": "2 hnub dhau los"
    },
    "enter_current_time": "Sau lub sijhawm tam sim no",
    "enter_result": "Sau qhov txiaj ntsig uas kuaj tau",
    "choose_result_option": "Xaiv qhov kev xaiv uas piav qhia koj qhov txiaj ntsig kev xeem ntawm daim npav:",
    "clarify_results": "Qhia meej koj cov txiaj ntsig tau txais",
    "take_result_photo": "Yees ib daim duab ntawm cov tshwm sim tau los",
    "take_result_photo_description": "Yees ib daim duab ntawm daim npav ua tiav rau teev tseg koj cov txiaj ntsig tau los.",
    "my_result": "Kuv qhov tshwm sim",
    "switch_camera": "Hloov lub koob yees duab",
    "confirm_result": "Lees paub qhov tshwm sim",
    "name": "Lub Npe:",
    "date": "Hnub Tim:",
    "result": "Qhov tshwm sim:",
    "results": "Cov kev kuaj tau los",
    "test_submitted": "Ua tsaug, {{first_name}}! Koj li {{test}} tau xa tiav lawm.",
    "dob": "Hnub yug:",
    "administered": "Kev tswj hwm:",
    "or": "LOS SIS",
    "upload": "Rub Nkag",
    "change_uploaded_file": "Hloov cov ntaub ntawv tau rub nkag",
    "take_photo": "Yees duab",
    "confirm_information": "Lees paub cov ntaub ntawv tias raug lawm",
    "barcode": "Tus cim barcode:",
    "scan_test_barcode": "Luam tus zauv kuaj sim barcode",
    "barcode_description": "Tus zauv barcode yog qhov tshwj xeeb rau koj qhov kev kuaj sim thiab tuaj yeem pom ntawm koj qhov kev kuaj sim",
    "enter_date_in_last_30_days": "Sau hnub tim hauv 30 hnub dhau los thiab lub sijhawm kwv yees uas tau ua qhov kev kuaj sim",
    "add_image": "Ntxiv duab",
    "change_image": "Hloov daim duab",
    "skip_barcode_reader": "Tsis tuaj yeem luam theej duab tus zauv code lod? Nyem qhov Next los ceeb toom qhov kev kuaj sim tau los",
    "enter_date_and_time": "Sau hnub tim thiab lub sijhawm kuaj sim",
    "enter_date": "Sau hnub kuaj sim",
    "did_you_take_test_today": "Koj puas tau laib kuaj nyob rau hnub no?",
    "record_results_for_generic": "Qhov kuaj ntsuas thiab daim ntawv teev tseg ntawm koj qhov kuaj ntsuas tau los rau qhov kuaj ntsuas {{name}}",
    "choose_result_option_custom": "Xaiv feem kev xaiv uas piav qhia txog koj {{test_name}} qhov kuaj tau los:"
  },
  "yes": "Yog",
  "no": "Tsis yog",
  "event_token": {
    "title": "Thov sau tus zauv hauv qab no rau hauv lub tshuab muag khoom txhawm rau muab koj cov khoom siv kuaj sim",
    "loading_text": "Tseem tab tom rub los, thov rov qab los saib dua ib pliag"
  },
  "appointment_recovery": {
    "complete_title": "Koj puav leej raug teeb tsa tau lawm!",
    "subtitle_1": "Yog koj siv ib lub iPhone, ces kov qhov **Ua Tiav** nyob saum toj kawg ces kaum sab lauj ntawm daim phiaj saib no rov qab mus rau ntawm koj daim phiaj sau.",
    "subtitle_2": "Yog koj siv ib lub Android, ces kov **X** nyob saum toj kawg ces kawm sab lauj ntawm daim phiaj saib no.",
    "subtitle_0": "Ces tawm ntawm lub browsser mus thiaj li mus tom ntej tau."
  },
  "primary": {
    "support_email": "support@primary.health"
  },
  "consented_relationship": "Qhov sib raug zoo uas tau pom zoo",
  "finish_registration": {
    "title": "Tso koj lub npe tiav lawm",
    "subtitle": "Thov sau cov ntaub ntawv nram qab no kom tiav ua ntej koj qhov teem caij mus ntsib:",
    "button": "Kev tso npe tiav lawm"
  },
  "arab_ethnicity": {
    "arab": "Neeg Aslav",
    "non_arab": "Tsis Yog Neeg Aslav",
    "prefer_not_to_answer": "Tsis Xav Teb",
    "question": "Haiv Neeg Aslav",
    "subtitle": "Tej kev cai uas yuav tsum kom peb suav sau tej ntaub ntawv xws li nram qab no.",
    "help": "Haiv neeg tsawg yog hais txog ib qho yeeb yam xws li hom lus hais, tej kab lis kev cais, kev ntseeg, thiab lwm tus yeeb yam uas raug siv los faib cais tau qhov sib txawv ntawm pab pawg neeg.Nws yuav yoog los sis tsis yoog raws li ib haiv neeg."
  },
  "custom_comms_quick_add": {
    "mobile_app_links": {
      "email_text": "Nce raws li koj yam khoom siv, nias rau ntawm lub link hauv qab no los mus rub Primary Health Pass."
    }
  },
  "stepper": {
    "next": "Tom Ntej",
    "back": "Rov Qab",
    "complete": "Tiav lawm"
  },
  "registration_direcory": {
    "keyword_search_label": "Lo lus tseem ceeb, nroog, zis qhauj, lub npe koom haum",
    "keyword_search_label_mobile": "Nroog, zis qhauj, lub npe koom haum",
    "zero_results": {
      "title": "Peb nrhiav tsis tau ib tug kws kho mob los ntawm qhov siv lo lus tshawb nrhiav ntawd.",
      "sub_title": "Sim tshawb nrhiav koj tus kws kho mob raws:",
      "p1": "Zis qhauj",
      "p2": "Lub npe nroog los sis npe zos",
      "p3": "Lub npe cheeb tsam nroog",
      "p4": "Lub npe kws kho mob los sis lub koom haum"
    }
  },
  "registration_flyer": {
    "open_camera": "Qhib lub twj siv koob yees  duab nyob rau ntawm koj lub xov tooj",
    "point_to_qr_code": "Tig rau ntawm tus QR qhauj ces taum rau qhov link",
    "complete_registration": "Kev Tso Npe Tiav",
    "need_help": "Puas xav tau kev pab? Tiv toj rau peb ntawm"
  },
  "vaccine_info_sheets": {
    "vaccine_info_attestation": "Kev lees txog cov ntaub ntawv ntawm tshuaj tiv thaiv kab mob",
    "select_checkbox": "Thov saib kem kos hauv qab no thiab xaiv qhov uas tsim nyog hauv qab no ua ntej yuav ua mus ntxiv.",
    "opportunity_to_read": "Kuv muaj los sis yeej muab sij hawm rau kuv los nyeem, los sis muaj neeg nyeem rau kuv mloog lawm, daim ntawv hais qhia txog Cov Ntaub Ntawv Tshaj Tawm Txog Cov Ntaub Ntawv Tshuaj Tiv Thaiv Kab Mob (Vaccine Information Statement(s),“VIS”) los sis Kev Tso Cai Siv Rau Kis Mob Xwm Txheej Ceev (Emergency Use Authorization,“EUA”) uas sau txog cov tshuaj tiv thaiv kab mob uas yuav raug siv:",
    "vaccine_info_sheet": "daim ntawv hais qhia txog cov ntaub ntawv ntawm tshuaj tiv thaiv kab mob",
    "vaccine_fact_sheet": "daim ntawv hais qhia txog qhov tseeb ntawm tshuaj tiv thaiv kab mob",
    "vaccine_info_statement": "lus qhia txog txhaj tshuaj"
  },
  "exemption": {
    "dtap_tdap": "DTaP/TDap",
    "mmr": "MMR",
    "ipv_opv": "Polio",
    "hib": "HIB",
    "hep_b": "HEPB",
    "var": "Varicella",
    "hep_a": "HEPA",
    "pcv": "PCV",
    "mcv": "MCV"
  },
  "test_strip": {
    "A": "daim ntawv siv no muaj xws li cov kev ntsuas rau kab mob sib kis thiab kab mob kas cees.",
    "B": "daim ntawv siv no muaj xws li cov kev ntsuas rau kab mob sib kis thiab kab mob kas cees.",
    "C": "daim ntawv siv no muaj xws li cov kev ntsuas rau kab mob cas cees qau lwj.",
    "D": "qhov cuaj yeej kuaj no muaj qhov kuaj txog kab mob HIV, kab mob kas cees (syphilis), kab mob sib kis los ntawm chaw mos (chlamydia) thiab kab mob kas cees chaw mos ua paug (gonorrhea).",
    "E": "daim ntawv siv no muaj xws li cov kev ntsuas rau kab mob HIV thiab kab mob kas cees qau lwj.",
    "box_type": "Kem plaub fab {{type}}",
    "administered_text": "Peb mam li tiv toj rau koj thaum koj qhov twj ntsuas tuaj txog tim lub chaw kuaj mob thiab thaum koj qhov kuaj tau los kuaj tau lawm.",
    "activate_kit": "Qhib kuv qhov twj ntsuas",
    "register_another_test": "Tso npe rau lwm qhov kev ntsuas",
    "credentials": {
      "title": "Los peb pib tau",
      "label": "Tiv tau rau",
      "p1": "Peb yuav pib nrog koj qee yam ntaub ntawv.",
      "location_step": {
        "p1": "Leej twg muab koj qhov twj ntsuas rau koj?",
        "load": "Rub nkag ntxiv"
      }
    },
    "checklist": {
      "not_urinated": "Sij hawm ib teev dhau los koj twb tsis tau tso zis li.",
      "bathroom": "Koj nkag mus hauv chav dej es khaws cov zis uas koj tso me ntsis.",
      "anal_swab": "Koj nkag mus tso qauv rau ib chav twg tes so ib qho rau hauv lub fwj.",
      "blood_extraction": "Koj nkag mus rau hauv ib chav huv si mus tso ib qho ntshav tawm.",
      "washed_hands": "Ntxuav koj ob sab tes nrog tshuaj xab npum thiab nrog dej sov kom ntev li 30 chib.",
      "title": "Koj puas npaj txhij los pib muab tej qauv yuav coj mus kuaj ntawd?",
      "no_alcohol": "Txhob haus cawv rau hnub yuav muab tej qauv ntawd.",
      "menstruation": "Txhob khaws cov ntshav thaum coj khawb ncaws los sis hauv lub sij hawm sib deev tsis dhau 24 teev."
    },
    "confirm": {
      "title": "Lees paub koj cov ntaub ntawv",
      "p1": "Cov ntaub ntawv no puas raug?"
    },
    "display_name": {
      "A": "Kab Mob Sib Kis thiab Kab Mob Kas Cees Qau Ua Paug (Tib Thaj Chaw)",
      "B": "Kab Mob Sib Kis thiab Kab Mob Kas Cees Qau Ua Paug (3 Thaj Chaw)",
      "C": "Kab Mob Kas Cees Qau Lwj",
      "shortened": {
        "A": "Kab Mob Sib Kis thiab Kab Mob Kas Cees Qau Ua Paug",
        "B": "Kab Mob Sib Kis thiab Kab Mob Kas Cees Qau Ua Paug",
        "C": "Kab Mob Kas Cees Qau Lwj"
      },
      "CC": "COVID-19",
      "CE": "Kev Kuaj Kab Mob Noj Zaub Mov Tsis Txaus",
      "CR": "Kev Kuaj Ntsuas Creatinine",
      "D": "Muaj 4 Hom Kuaj - HIV, Syphilis, Chlamydia thiab Gonorrhea (Ib Thaj Chaw)",
      "DD": "Kev Kuaj Kab Mob Ntshav Qab Zib (HbA1c)",
      "E": "Kab Mob HIV thiab Syphilis",
      "H": "Kab mob HPV",
      "P": "Kab Mob Siab C",
      "HS": "Kab Mob Ua Paug",
      "I": "Kab mob HIV",
      "TT": "Theem Testosterone",
      "VD": "Tshuaj Vais Tas Mees D",
      "VS": "Kev Soj Qab Xyuas Kab Mob Vais Lav",
      "AP": "Chlamydia & Gonorrhea",
      "NN": "COVID-19",
      "HH": "COVID-19, Flu A/B, & RSV",
      "FFFWW": "COVID-19 & Flu A/B",
      "YYYWW": "COVID-19, Flu A/B & RSV",
      "HA": "COVID-19, Flu A/B, & RSV"
    },
    "rescan": "Rov qab kuaj dua",
    "scanned": "Koj twb kuaj lawm Kem Plaub Fab {{type}}",
    "not_yet": "Tseem Tsis Tau",
    "success_registration": "Kev tso npe tiav tawm!",
    "no_box": "Tsis yog tsis muaj kem plaub fab lod?",
    "faq_link": "Saib Cov Nqe Lus Uas Keev Nug",
    "welcome": "Zoo Siab Txais Tos!",
    "scan": {
      "title": "Koj nyob rau ib kauj ruam ze zog rau kev noj qab haus huv zoo tuaj lawm.",
      "p1": "Kuaj luam los sis ntaus **tus npas-qhauj twj ntsuas (kit bar code)** rau ntawm ib sab ntawm koj kem plaub fab."
    },
    "appointments": {
      "title": "Xaiv qhov tus neeg mob thiab kws kho mob",
      "p1": "Peb pom muaj cov ntaub ntawv ntsig txog koj cov ntaub ntawv siv sib tiv toj ntau dua ib daim.Xaiv daim ntawv uas raug:",
      "new": "Tus neeg mob los sis kws kho mob tshiab"
    },
    "verify": {
      "title": "Confirm your identity",
      "p1": "Peb pom koj daim ntawv teev tseg tus neeg mob lawm."
    },
    "section_1": "Lees paub cov ntsiab lus ntawm qhov twj ntsuas",
    "section_2": "Kev suav sau qhov kuaj ntsuas",
    "section_3": "Ntim",
    "section_4": "Xa",
    "box_a": "Lub Thawv A",
    "box_b": "Lub Thawv B",
    "box_c": "Lub Thawv C",
    "kit_flow": {
      "button_1": "Kav tsij ntim mus ntxiv",
      "button_2": "Kuv npaj txhij lawm",
      "button_3": "Kuv qhov twj ntsuas ntim tiav lawm",
      "button_4": "Kuv ua tiav lawm",
      "button_5": "Qhov ntsuas tom ntej",
      "button_6": "Hla cov lus qhia"
    },
    "contents": {
      "header": "Zoo tshaj! Saib xyuas kom ua tiav koj qhov twj ntsuas",
      "text_1": "Does your test kit box contain the following?",
      "blood_collection_card": "Daim npav suav sau ntshav",
      "blood_collection_card_subtitle": "(1 los sis 2 nce raws li qhov twj ntsuas)",
      "alchohol_pads": "2 daim ntaub cawv",
      "lancets": "Cov chais uas siv tib zaug",
      "up_to_other": "(Ntau txog 4 nce raws li qhov twj ntsuas)",
      "adhesive_bandages": "Cov ntaub nplaum qhwv",
      "pipette": "Tus kav nqus kua",
      "urine_tube": "Tus fwj ntim zis",
      "urine_cup": "Lub khob ntim zis",
      "oral_swab": "Tus pa kuaj qhov ncauj",
      "oral_tube": "Lub fwj ntim tus pa kuaj qhov ncauj",
      "anal_swab": "Tus pa kuaj qhov quav",
      "anal_tube": "Lub fwj ntim tus pa kuaj qhov quav",
      "vaginal_tube": "1 lub fwj ntim tus pa kuaj qhov paum",
      "vaginal_swab": "1 tus pa kuaj qhov paum",
      "biodegradable_paper": "Daim ntawv suav sau uas lwj tau",
      "bottle_and_probe": "Lub fwj ntim yam ua qauv kuaj thiab tus pa taum",
      "prepaid_envelope": "Muab ntim lub hnab ntawv uas twb them nqi lawm xa mus",
      "biohazard_subtitle": "(nrog daim ntawv nqus dej)",
      "biohazard_bag": "1 lub hnab ntim yam ua qauv",
      "biohazard_bag_plural": "({{count}}) Cov hnab Biohazard",
      "alcohol_pads_plural": "{{count}} daim ntaub cawv",
      "sterile_gauze_pad": "1 daim ntaub huv si",
      "collection_tube": "1 collection tube",
      "collection_swabs": "2 collection swabs"
    },
    "packaging": {
      "title": "Cov khoom yuav muab ntim",
      "subtitle": "Before you ship or drop your kit at a kiosk, make sure that:"
    },
    "packaging_box_a": {
      "step_1": "Koj ntim txhua yam twj ntsuas kom tag",
      "step_2": "Koj yeej siv txhua yam suav sau yam ua qauv ntsuas uas muaj nyob rau hauv cov twj ntsuas raws li tus qauv muab cov qauv ntsuas",
      "step_3": "Koj **hnub-yug** raug sau rau ntawm txhua lub fwj ntim yam ua qauv uas sau ua HLI/HNUB/XYOO",
      "step_4": "**hnub suav sau** raug sau rau ntawm txhua lub fwj ntim yam ua qauv uas sau ua HLI/HNUB/XYOO",
      "step_5": "Lub fwj ntim cov ua qauv raug muab ntig cia zoo rau hauv lub hnab",
      "step_6": "Muab tag nrho txhua yam ua qauv tso rau hauv lub thawv",
      "step_7": "Tso lub thawv rau hauv lub hnab xa ntawv uas twb them nqi ua ntej lawm",
      "step_8": "Muab lub hnab xa ntawv uas npog zoo ntawd xa rov qab mus"
    },
    "packaging_box_b": {
      "step_1": "Koj ntim txhua yam khoom kuaj ntsuas tag lawm, txawm tias koj twb hla qhov kev ntsuas lawm los xij",
      "step_2": "Koj yeej siv txhua yam suav sau yam ua qauv ntsuas uas muaj nyob rau hauv cov twj ntsuas raws li tus qauv muab cov qauv ntsuas",
      "step_3": "Koj **hnub-yug** raug sau rau ntawm txhua lub fwj ntim yam ua qauv uas sau ua HLI/HNUB/XYOO",
      "step_4": "**hnub suav sau** raug sau rau ntawm txhua lub fwj ntim yam ua qauv uas sau ua HLI/HNUB/XYOO",
      "step_5": "Txhua lub fwj ntim yam ua qauv ntsuas mas nyias raug tso nyias rau hauv nyias lub hnab ntim (tsuas muaj **ib** yam qauv ntsuas toj ib lub hnab xwb)",
      "step_6": "Muab tag nrho txhua yam ua qauv tso rau hauv lub thawv",
      "step_7": "Tso lub thawv rau hauv lub hnab xa ntawv uas twb them nqi ua ntej lawm",
      "step_8": "Muab lub hnab xa ntawv uas npog zoo ntawd xa rov qab mus"
    },
    "packaging_box_c": {
      "step_1": "Koj ntim txhua yam twj ntsuas tag lawm, suav txog cov chais uas siv lawm.",
      "step_2": "Koj **hnub-yug** raug sau rau ntawm daim npav suav sau ntshav uas sau ua HLI/HNUB/XYOO",
      "step_3": "**hnub suav sau** raug sau rau ntawm daim npav suav sau ntshv uas sau ua HLI/HNUB/XYOO",
      "step_4": "Muab daim npav suav sau ntshav thiab tag nrho cov chais uas siv lawm tso rau hauv lub hnab ntim",
      "step_5": "Muab lub hnab ntim tso rau hauv lub thawv",
      "step_6": "Tso lub thawv rau hauv lub hnab xa ntawv uas twb them nqi ua ntej lawm",
      "step_7": "Muab lub hnab xa ntawv uas npog zoo ntawd xa rov qab mus"
    },
    "success": {
      "header_1": "Zoo tshaj!",
      "text_1": "Koj yeej npaj txhij los muab koj thawj yam ua qauv lawm.",
      "header_2": "Koj twb muab tau lawm!",
      "text_2": "**Txuas ntxiv:** muab koj qhov twj ntsuas ntim xa rov qab mus.",
      "header_3": "Txoj hauj lwm zoo heev!",
      "text_3": "Koj nyob rau kauj ruam txav ze rau theem kev noj qab nyob zoo uas zoo dua lawm.",
      "header_4": "Ua tau zoo lawm!",
      "text_4": "Tsuas yog me ntsis xwb koj yeej yuav ua tau lawm!",
      "text_5": "You're ready to collect your sample."
    },
    "instructions": {
      "title": "Xaiv ib qho kev ntsuas los saib cov lus qhia:",
      "subtitle": "Kev Ntsuas",
      "button": "Cov Lus Qhia",
      "pdf": "Cov lus qhia sau ua PDF"
    },
    "instructions_box_a": {
      "title": "Kev suav sau cov zis",
      "subtitle": "Rau qov kuaj ntsuas no koj yuav tsum ua raws li cov ntaub ntawv xws li nram qab no:",
      "step_1": "Txhob tso zis cia ntev tsawg kawg 1 teev ua ntej yuav suav sau. Siv cov zis tso rau yav sawv ntxov mas zoo tshaj.",
      "step_2": "Tso zis ncaj qha rau lub PeeCanter los sis lub khob ntim zis, tso kom puv li 1/3 thiab 1/2.",
      "step_3": "Muab cov zis hauv lub PeeCanter hliv tawm los sis siv tus pas taum kom cov zis ntws mus rau lub fwj ntim zis uas muaj cim tsos xim daj <span style=\"color: #FF5000\">**\"URINE\"**</span>.",
      "step_4": "Hliv cov kua zis rau lub fwj ntim kom puv raws li kab cim tseg.",
      "step_4_sublabel": "Txhob hliv cov kua zis kom tshaj kab uas siab kawg nkaus los sis tsis li ces yuav raug tsis kam lees cov qauv zis no.",
      "step_5": "Muab lub hau npog rau lub fwj thiab saib xyuas kom kaw ceev nrug rau lawm.",
      "step_6": "Sau koj lub hnub yug thiab hnub suav sau qauv zis (hnub no yog hnub) raws li HLI/HNUB/XYOO rau ntawm lub fwj uas nyob hauv thaj chaw npaj tseg.",
      "step_7": "Tso cov kua zis rau hauv ib lub hnab nrog rau ib daim ntaub nqus dej thiab saib xyuas kom raug kaw zoo lawm."
    },
    "instructions_box_b": {
      "test_1": {
        "title": "Kev suav sau cov zis",
        "subtitle": "Rau qov kuaj ntsuas no koj yuav tsum ua raws li cov ntaub ntawv xws li nram qab no:",
        "step_1": "Txhob tso zis cia ntev tsawg kawg 1 teev ua ntej yuav suav sau. Siv cov zis tso rau yav sawv ntxov mas zoo tshaj.",
        "step_2": "Tso zis ncaj qha rau lub PeeCanter los sis lub khob ntim zis, tso kom puv li 1/3 thiab 1/2.",
        "step_3": "Muab cov zis hauv lub PeeCanter hliv tawm los sis siv tus pas kuaj taum kom cov zis ntws mus rau lub fwj ntim zis uas muaj cim tsos xim daj <span style=\"color: #FF5000\">**\"URINE\"**</span>.",
        "step_4": "Hliv cov kua zis rau lub fwj ntim kom puv raws li kab cim tseg.",
        "step_4_subtitle": "Txhob hliv cov kua zis kom tshaj kab uas siab kawg nkaus los sis tsis li ces yuav raug tsis kam lees cov qauv zis no.",
        "step_5": "Muab lub hau npog rau lub fwj thiab saib xyuas kom kaw ceev nrug rau lawm.",
        "step_6": "Sau koj lub hnub yug thiab hnub suav sau qauv zis (hnub no yog hnub) raws li HLI/HNUB/XYOO rau ntawm lub fwj uas nyob hauv thaj chaw npaj tseg.",
        "step_7": "Tso cov kua zis rau hauv ib lub hnab nrog rau ib daim ntaub nqus dej thiab saib xyuas kom raug kaw zoo lawm."
      },
      "test_2": {
        "title": "Tus pa kuaj qhov ncauj",
        "subtitle": "Rau qov kuaj ntsuas no koj yuav tsum ua raws li cov ntaub ntawv xws li nram qab no:",
        "step_1": "Tuav tus pas kuaj rau sab muaj txoj kab plooj (yog muaj) thiab sab muaj paj rwb.",
        "step_2": "Ntxig tus pa so nkag mus rau hauv koj lub qhov ncauj thiab nrws tus pa rau tom koj lub qa ntev li 10 chib.",
        "step_3": "Maj mam ua tib zoo thau tus pa so thiab muab tso rau hauv lub fwj ntim uas cim tsos xim xiav <span style=\"color: #001F70\">**\"ORAL\"**</span>. Muab tus pa dam rau ntawm txoj kab uas yog muab nws ncab tso rau hauv lub fwj ntim.",
        "step_4": "Muab lub hau npog rau lub fwj thiab saib xyuas kom kaw ceev nrug rau lawm.",
        "step_5": "Sau koj lub hnub yug thiab hnub suav sau qauv zis (hnub no yog hnub) raws li HLI/HNUB/XYOO rau ntawm lub fwj uas nyob hauv thaj chaw npaj tseg.",
        "step_6": "Tso cov kua zis rau hauv ib lub hnab nrog rau ib daim ntaub nqus dej thiab saib xyuas kom raug kaw zoo lawm."
      },
      "test_3": {
        "title": "Tus pa kuaj qhov quav",
        "subtitle": "Rau qov kuaj ntsuas no koj yuav tsum ua raws li cov ntaub ntawv xws li nram qab no:",
        "step_1": "Tuav tus pas kuaj rau sab muaj txoj kab plooj (yog muaj) thiab sab muaj paj rwb.",
        "step_2": "Ntxig tus pa so mus tob li 3 - 5 cm (1 - 2”) rau hauv lub qhov quav. Maj mam tig tus pa so ntev li 5 - 10 chib es thiaj li tuav tau tej yam muaj sia me me.",
        "step_3": "Maj mam ua tib zoo thau tus pa so thiab muab tso rau hauv lub fwj ntim uas cim tsos xim ntsuab <span style=\"color: #00C08C\">**“ANAL”**</span>.Muab tus pa dam rau ntawm txoj kab uas yog muab nws ncab tso rau hauv lub fwj ntim.",
        "step_4": "Muab lub hau npog rau lub fwj thiab saib xyuas kom kaw ceev nrug rau lawm.",
        "step_5": "Sau koj lub hnub yug thiab hnub suav sau qauv zis (hnub no yog hnub) raws li HLI/HNUB/XYOO rau ntawm lub fwj uas nyob hauv thaj chaw npaj tseg.",
        "step_6": "Tso cov kua zis rau hauv ib lub hnab nrog rau ib daim ntaub nqus dej thiab saib xyuas kom raug kaw zoo lawm."
      }
    },
    "instructions_box_c": {
      "title": "Kev suav sau ntshav",
      "subtitle": "Rau qov kuaj ntsuas no koj yuav tsum ua raws li cov ntaub ntawv xws li nram qab no:",
      "step_1": "Sau koj lub npe, hnub yug, thiab hnub suav sau rau kem uas hais npaj tseg. Siv hom HLI/HNUB/XYOO.",
      "step_2": "Qhib daim ntawv ntawv ntshav kom pom cov duab kheej kheej nyob rau saum daim ntawv suav sau ntshav. Txhob chwv daim ntawv suav sau ntshav.",
      "step_3": "Ntxuas koj ob sab tes nrog dej sov kom ntev tsawg kawg yog 30 chib, tom ntawd sib sib zog yoj tes ntev li 15 chib kom ntshav nce los pem koj cov ntim tes.",
      "step_4": "Siv daim ntaub cawv so pem ntsis ntim tes kom huv si.Qhov zoo tshaj ces siv tus ntim tes nruab nrab los sis tus ntim tes puab ntawd rau sab uas koj tsis xis.",
      "step_5": "Tuav rab chais thiab tig lub hau fwj tawm.Nias lub ntsis ntim tes rau ntawm koj tus ntim tes, kom txog thaum rab koob hno kiag. Thaum sij hawm suav sau ntshav mas tso koj sab tes kom nyob qis dua koj lub plawv, zuaj tus ntim tses txij pem hauv paus mus rau pem ntsis kom ntshav khiav zoo.",
      "step_5_subtitle": "Tsuas siv cov chais tib zaug xwb. Yuav tsum muab cov chais xa rov qab nrog koj cov qauv ntshav coj mus rau tom chav kuaj vim yuav tau muab pov tseg.",
      "step_6": "Pib rau ntawm nruab nrab, siv cov ntshav li 3-6 tees nrog rau lub fwj kheej kheej uas muaj daim ntaub nqus ntshav. Txhob chwv koj tus ntim tes rau daim ntaubvim tias qhov no yuav ua rau ntshav tsis ntws. Thaum lub fwj kheej kheej puv lawm, txav mus rau lwm lub. Tsis ua cas txawm tias ntshav yuav tshaj ntawm cov kab los tau, tab sis txhob cia cov ntshav tsheej mus rau lwm lub.",
      "step_6_subtitle": "Txhob ntxiv cov ntshav ntxiv rau ib lub fwj kheej kheej rau thaum ua tiav lawm los sis qhuav lawm. Qhov cov ntshav“khov ua tshooj” ces yuav ua rau qhov muab cov ntshav no siv tsis tau.",
      "step_7": "Txhob npog daim ntawv nqus ntshav, muab tso rau ntawm tus npoo tiaj tiaj es ua kom daim ntawv suav sau ntshav raug cua qhuav rau hauv chav nyob, ntev tsawg kawg yog 30 feeb. Txhob coj mus rhaub kom kub, tso cua kom qhuav, los sis qhib daim ntawv suav sau ntshav rau duab tshav ntuj. Qhov ua rau kub yuav ua rau cov qauv ntshav puas.",
      "step_7_subtitle": "Kuaj xyuas sab tom qab ntawm daim ntawv suav sau ntshav.Cov ntshav yuav tsum nrog mus kom puv txhua lub fwj kheej kheej rau ntawm daim ntaub suav sau ntshav.",
      "step_8": "Thaum daim ntawv suav sau ntshav qhuav,ces kaw daim ntawv ntshav uas muab nws tais cia. Tso daim npav ntshav thiab cov chais tso rau hauv lub hnab nrog rau pob khoom nqus yam noo. Saib xyuas kom lub hnab zawm hau raug zoo lawm."
    },
    "test_tips": {
      "title": "Cov Lus Qhia rau Kev Suav Sau Ntshav Kom Raug Zoo",
      "subtitle": "Rau qhov ua kom kuaj tau los zoo tshaj plaws:",
      "step_1": "Saib xyuas kom koj lub cev txhob qhuav dej rau thaum suav sau ntshav. Lub cev muaj dej mas yeej ua rau ntshav khiav tau zoo dua.",
      "step_2": "Txhob suav sau cov ntshav kiag tom qab haus luam yeeb tag.",
      "step_3": "Ntxuav koj ob sab tes nrog dej sov yuav pab ua rau ntshav khiav mus rau pem koj cov ntim tes tau zoo.",
      "step_4": "Sib sib zog yoj koj txhais tes kom cov ntshav nce mus rau pem koj cov ntim tes.",
      "step_5": "Saib xyuas yam khoom siv suav sau ntshav thiab ob sab tes kom nyob qis dua koj lub plawv rau thaum suav sau ntshav vim yuav ua rau ntshav khiav tau zoo tshaj.",
      "step_6": "Tej zaum yuav tau tho koj tus ntiv tes ntau dua ib qho.Rov qab ua raws li cov lus qhia no rau qhov tho ntim tes txhua zaus."
    },
    "shipping": {
      "header": "Koj yam twj ntsuas yeej npaj xa mus lawm!",
      "text": "**Congrats!** You finished your health screening."
    },
    "pick_up": "Txais cov twj kuaj ntsuas raws li qhia los ntawm tus neeg lis hauj lwm rau ntawm lub chaw thiab tso npe rau hauv qab no",
    "short_display_name": {
      "A": "Kab Mob Sib Kis thiab Kab Mob Kas Cees Qau Ua Paug",
      "B": "Kab Mob Sib Kis thiab Kab Mob Kas Cees Qau Ua Paug",
      "C": "Kab Mob Kas Cees",
      "CC": "Kab Mob Khees Xaws Txoj Hnyuv Qhov Quav",
      "CE": "Kab Mob Noj Zaub Mov Tsis Txaus",
      "CR": "Creatinine",
      "D": "Kab mob HIV, Syphilis, Chlamydia thiab Gonorrhea",
      "DD": "Kab mob ntshav qab zib",
      "E": "Kab Mob HIV thiab Syphilis",
      "H": "Kab mob HPV",
      "P": "Kab Mob Siab C",
      "HS": "Kab Mob Ua Paug",
      "I": "Kab mob HIV",
      "TT": "Testosterone",
      "VD": "Tshuaj Vais Tas Mees D",
      "VS": "Kev Soj Qab Xyuas Kab Mob Vais Lav"
    },
    "test_kit": "Qhov Twj Kuaj Ntsuas",
    "view_results": "Saib qhov kuaj ntsuas tau los",
    "recommended_kit_title": "Cov Twj Kuaj Ntsuas Raws Li Qhia",
    "instructions_box_h": {
      "title": "Tus Pa Kuaj Qhov Paum",
      "subtitle": "Tej zaum cov fwj ntim yuav muaj cov kua tshuaj tiv thaiv lwj.<span class=\"text-danger\">**Txhob hliv cov kua tshuaj tiv thaiv lwj tawm ntawm lub fwj yog tias muaj kua.**</span>",
      "step_1": "Tuav tus pa kuaj rau ntawm tog muaj txoj kab plooj (yog muaj) thiab tog muaj paj rwb rau ntawm ib sab te thiab faib cais ob sab di paum (cov tawv nqaij uas tais npog rau ob sab ncig ntawm lub paum kom nws rua).",
      "step_2": "Ntxig tus pas kuaj kom nkag mus tob li 5cm (2in)rau hauv lub paum.Maj mam tig tus pa kuaj mus los kom ntev li 30 chib nrog rau siv tus pa kuaj kuav ob sab ntug hauv lub qhov paum.",
      "step_3": "Maj mam thau tus pa kuaj tawm los thiab muab tso rau hauv lub fwj ntim uas cim tseg tias <span class=\"text-danger\">**RED “VAGINAL” LABEL**</div>.Muab tus pa kuaj dam uas yog muab nws ncab tso rau hauv lub fwj ntim.",
      "step_4": "Muab lub hau npog rau lub fwj thiab saib xyuas kom kaw ceev nrug rau lawm.",
      "step_5": "Sau koj hnub yug xws li HLI/HNUB/XYOO rau ntawm lub fwj rau hauv thaj chaw uas npaj tseg (DOB).",
      "step_6": "Sau hnub koj suav sau cov qauv kuaj (hnub no yog hnub) raws li HLI/HNUB/XYOO rau ntawm lub fwj uas nyob hauv thaj chaw npaj tseg (HnubSuav Sau Cov Qauv Kuaj).",
      "step_7": "Tso cov qauv kuaj rau hauv ib lub hnab nrog rau ib daim ntaub nqus dej thiab saib xyuas kom raug kaw zoo lawm."
    },
    "packaging_box_h": {
      "step_3": "Koj **hnub-yug** raug sau rau ntawm lub fwj ntim yam ua qauv uas sau ua HLI/HNUB/XYOO",
      "step_4": "Koj **hnub suav sau** raug sau rau ntawm lub fwj ntim yam ua qauv uas sau ua HLI/HNUB/XYOO",
      "step_6": "Muab lub hnab ntim tso rau hauv lub thawv"
    },
    "instructions_box_cc": {
      "title": "Kev suav sau cov quav",
      "step_1": "Sau koj lub hnub yug thiab hnub suav sau qauv zis (hnub no yog hnub) raws li HLI/HNUB/XYOO rau ntawm lub fwj uas nyob hauv thaj chaw npaj tseg.Tig thiab nqa qhib lub hau ntsuab.",
      "step_2": "Tso daim ntaub suav suav quav tso rau hauv lub qhov plob kom nyob siab dua cov dej.",
      "step_3": "Tso cov quav ua qauv rau saum daim ntaub suav sau quav.",
      "step_4": "Khaws cov quav ua qauv cia ua ntej cov ntawv yuav tog thiab cov quav ua qauv yuav chwv dej.",
      "step_5": "Siv tus pa taum txhuam tus ntug ntawm cov quav ua qauv.",
      "step_6": "Npog sab tus pa taum cov quav ua qauv kom zoo.",
      "step_7": "Kaw lub fwj ntim cov quav ua qauv uas muab tus pa taum thiab ntxig rau ces kaw lub hau ntsuab kom khov rau.Txhob qhib dua lawm.",
      "step_8": "Tso dej yaug tawm mus.Daim ntawv suav sau mas yog yam lwj tau thiab yuav tsis cuam tshuam rau lub qhov dej qias.",
      "step_9": "Muab lub fwj ntim yam ua qauv tso rau hauv daim ntaub nqus dej thiab ntxig rau hauv lub hnab."
    },
    "contact_support": {
      "title": "Los peb kho qhov no kom haum",
      "text_1": "Peb tu siab uas tau hnov tias muaj qee yam tsis raug lawm!",
      "text_2": "Thov tiv toj rau peb kom peb paub txog yam uas tsis raug ntawd seb yog dab tsi ces peb thiaj li yuav pab hloov tau koj tej twj siv kuaj ntsuas."
    },
    "contact_support_success": {
      "title": "Peb twb txais tau koj kab ntawv lawm",
      "text_1": "Ua koj tsaug uas tiv toj rau peb.",
      "text_2": "Ib tug tswv cuab ntawm peb pab pwg pab txhawb mam li tiv toj rov qab sai sai.",
      "button_text": "Rov qab mus tsev"
    },
    "kit_in_transit_to_patient": "Koj daim ntawv txib yuav tshuaj tseem txog tog kev! Cov ntawv txib yuav tshuaj feem ntau mas yuav muab tshuaj xa tuaj tsis pub dhau 2-5 hnub. Kiag thaum koj txais koj qhov cuab yeej kuaj lawm, peb mam li xa cov ntaub ntawv hais txog yuav qhib siv li cas tuaj rau koj kom ntau ntxiv thiab hais txog qhov yuav xa rov qab li cas mus.\n\nSoj Qab Xyuas Hauv Lub Link: https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{tracking_number}}",
    "kit_received_at_lab": "Lub chaw kuaj mob tau txais koj qhov cuab yeej kuaj mob lawm! Koj yuav tau txais ib daim ntawv ceeb toom txog kev kuaj xyuas koj tus mob thaum kuaj tau los lawm",
    "registration_confirmation": "tam sim no koj qhov tso npe tiav lawm! Mus saib koj nplooj ntawv lees kev tso npe los mus qhib koj qhov cuab yeej kuaj:",
    "kit_ordered_online": "Peb twb txais koj daim ntawv txib yuam cuab yeej kuaj mob lawm thiab peb mam li ceeb toom tshiab tuaj rau koj thaum xa nws tuaj! Daim ntawv txib yuav #: {{order_number}} Hnub txib yuav: {{order_date}}",
    "kit_ordered_online_email": {
      "header": "Peb tau txais koj daim ntawv txib yuav lawm!",
      "p1": "Peb twb txais koj daim ntawv txib yuav lawm! Kiag thaum xa koj qhov cuab yeej txib yuav, peb mam li ceeb toom tshiab tuaj rau koj.",
      "order_number": "Daim ntawv txib yuav #",
      "order_date": "Hnub txib yuav"
    },
    "completed": "Tiav lawm",
    "I": "qhov cuab yeej no muaj kuaj rau kab mob HIV.",
    "CC": "this kit includes tests for COVID-19.",
    "CE": "qhov cuab yeej no muaj kuaj rau kab mob noj zaub mov tsis txaus.",
    "CR": "qhov cuab yeej no muaj kuaj rau kab mob creatinine.",
    "DD": "qhov cuab yeej no muaj kuaj rau kab mob ntshav qab zib.",
    "H": "qhov cuab yeej no muaj kuaj rau kab mob HPV.",
    "P": "qhov cuab yeej no muaj kuaj rau kab mob siab C.",
    "HH": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "HS": "qhov cuab yeej no muaj kuaj rau kab mob HSV-2.",
    "TT": "qhov cuab yeej no muaj kuaj rau kab mob testosterone.",
    "VD": "qhov cuab yeej no muaj kuaj rau tshuaj vais tas mees D.",
    "nasal_collection": {
      "title": "Nasal collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Write your Date of Birth and the Collection Date on your collection tube. Use MM/DD/YYYY format for the dates (i.e., 08/29/2023).",
      "step_2": "Wash your hands with soap and water thoroughly before proceeding and after completeing all steps.",
      "step_3": "Remove the swab from the wrapper with your clean hands. Hold the swab with the white tip up. Do not touch the tip of the swab to anything.",
      "step_4": "Holding the swab with one hand, gently insert the white tip of the swab about 1/2 to 3/4 inces (1 to 2 cm) into the opening of your nose. Rotate the swab for 15 to 30 seconds. Make sure the swab touches the insides of your nose. Remove the swab from your nose being careful not to touch your skin. Do not set the swab down. Repeat in the other nostril.",
      "step_5": "While still holding the swab, unscrew and remove the cap from the transport tube without setting the cap down. Place the swab into the tube with the white tip down, taking great care not to spill or splash the liquid in the collection tube.",
      "step_6": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_7": "Place specimen into the empty biohazard bag with the absobent pad and ensure the seal is closed completely. Return the biohazard bag to the kiosk drop box."
    },
    "NN": "this kit includes tests for COVID-19.",
    "YYYWW": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "FFFWW": "this kit includes tests for COVID-19 and Flu A/B.",
    "HA": "this kit includes tests for COVID-19, Flu A/B, and RSV."
  },
  "copy_link": "Theej phab ntawv ntawm lub link",
  "copied_link": "Theej lub link lawm!",
  "vaccines": {
    "descriptions": {
      "vaccine_flu": "Kev tiv thaiv cov kab mob vais lav khaub thuas hnoos uas ib txwm muaj",
      "vaccine_covid": "Kev tiv thaiv cov kab mob vais lav COVID-19",
      "tdap": "Tshuaj txhaj tiv thaiv kab mob Tdap tuaj yeem tiv thaiv tau kab mob xeb",
      "polio": "Kev tiv thaiv kab mob vais lav polio.Yuav tsum txhaj cov me nyuam yaus uas ua ntej yuav pib mus kawm ntawv.",
      "varicella": "Tshuaj txhaj tiv thaiv kab mob uas tiv thaiv kab mob ua pob xoo liab",
      "shingles": "Tshuaj txhaj tiv thaiv kab mob uas tiv thaiv kab mob ua qoob.Hnub nyoog tsawg kawg yog 18 xyoo.",
      "human_papillomavirus": "Tshuaj txhaj tiv thaiv kab mob uas tiv thaiv kab mob HPV.Cov me nyuam yaus hnub nyoog 11 xyoos yuav tau txhaj tshuaj tiv thaiv kab mob.",
      "meningococcal": "Tshuaj txhaj tiv thaiv kab mob yuav tiv thaiv tau plaub hom kab mob npev-this-lias."
    }
  },
  "viral_surveillance": {
    "shipping": {
      "title": "Prepare to ship your sample:",
      "step_1": {
        "label": "Put the biohazard bag into box.",
        "substep_1": "Remove the return shipping mailer from your test kit.",
        "substep_2": "Place the biohazard bag into the original collection kit box and close the box."
      },
      "step_2": {
        "label": "Put box into return shipping mailer.",
        "substep_1": "Place the box into the return shipping mailer and seal the mailer.",
        "substep_2": "Do not fold or cover the UN3373 diamond symbol."
      },
      "step_3": {
        "label": "Return to Fedex location.",
        "substep_1": "Return kit on the same day of collection (before the last pickup) to a Fedex location.",
        "substep_2": "Call for a FedEx pickup at 1.800.GoFedEx."
      }
    },
    "overview": {
      "title": "Welcome to the Viral Sequencing Program!",
      "subtitle": "Your participation helps provide valuable information on which respiratory pathogen variants are circulating in your area. Please follow the instructions that came with your kit to get started."
    },
    "instructions": {
      "title": "Collect your sample:",
      "step_1": "Wash your hands.",
      "step_2": "Remove the cap of the collection tube and open the swab package.",
      "step_2a": "Remove the swab from the package. Do not touch the soft end with your hands or anything else.",
      "step_3": "Collect a nasal swab.",
      "step_3a": "Insert the entire soft end of the swab into your nostril no more than 3/4 of an inch (1/5cm) into your nose.",
      "step_3b": "Slowly rotate the swab",
      "step_3c": "Gently remove the swab.",
      "step_3d": "Using the same swab, repeat in your other nostril with the same end of the swab.",
      "step_4": "Put swab into the collection tube and cap the tube securely.",
      "step_5": "Put the collection tube into the biohazard bag and seal.",
      "step_6": "Apply even pressure to the ziplock to seal the bag shut."
    },
    "click_to_get_started_button": "Click to get started",
    "get_started_button": "Get started",
    "thank_you": {
      "title": "Thank you for using Primary.Health!",
      "p_3": "You may now close the browser.",
      "p_2": "Please make sure to drop off the viral surveillance kit at your closest FedEx location.",
      "p_1": "Your participation actively helps Public Health officials fight the spread of disease. If you would like to see the impact you are making on Public Health, please take a moment to explore more via [this link]({{link}}). You will not be contacted about your results, as they are anonymous."
    },
    "verify_dob_title": "First, let's confirm your details",
    "verify_dob_subtitle": "This helps us ensure the correct person is using the test kit.",
    "activate_kit": "Activate a kit:",
    "steps_complete": "Steps complete",
    "opt_in": {
      "section_1_title": "How it works",
      "section_1_text_1": "Sign up below and receive a viral sequencing test kit in 1-3 business days.",
      "section_1_text_2": "Upon receiving the test kit",
      "section_1_text_3": "Send the sample back per the instructions in the kit using our prepaid label.",
      "sign_up": "Sign me up!",
      "no_to_participate": "No",
      "section_2_title": "What is the impact of your participation?",
      "section_2": {
        "card_1_title": "Rapid response to new variants",
        "card_1_text": "Genetic sequencing helps track the spread and emergence of new respiratory pathogen variants, allowing public health systems to quickly ramp up preparedness efforts.",
        "card_2_title": "Track how Respiratory Pathogens are changing",
        "card_2_text": "Respiratory pathogen sequencing analyzes the “genetic blueprint” of the virus. This helps scientists trace how the virus changes, enabling it to spread more easily, make people more sick",
        "card_3_title": "You have complete privacy",
        "card_3_text": "The sequencing will be processed anonymously. The lab will never contact you about your results"
      },
      "title": "You’re eligible for California’s Free Respiratory Pathogen Viral Sequencing Project!",
      "subtitle": "You can help public health officials protect communities against respiratory pathogens! If you opt in to participate you will receive a free viral sequencing kit that will test what respiratory pathogen strain you are infected with. Results will help officials track the spread of respiratory pathogen variants. See below for more information and to sign up.",
      "continue_to_result": "Continue to my result",
      "create_page_title": "Thank you!",
      "create_page_text_1": "Your kit should arrive in the next 1-3 business days.",
      "create_page_text_2": "Your participation actively helps Public Health officials fight the spread of diseases. You will be notified when your test kit order has been approved and when it has shipped."
    }
  },
  "family_registrations": {
    "find_clinic": "Nrhiav ib lub chaw kuaj mob",
    "select_pin": "Xaiv rab koob los mus saib kom ntxaws",
    "miles_shorten": "{{miles}} mais kev",
    "next_available": "Puas muaj lwm zaus",
    "appointment_type": "Hom teem caij mus ntsib",
    "individual_appointment": "Kev teem caij mus ntsib rau tus kheej",
    "family_appointment": "Kev teem caij mus ntsib rau tsev neeg",
    "vaccines": "Cov tshuaj txhaj tiv thaiv kab mob",
    "what_kind_appointment": "Hom kev teem caij mus ntsib uas koj xav hais tseg ua ntej yog hom dab tsi?",
    "select_family_or_individual": "Xaiv qhov teem caij mus ntsib rau tsev neeg yog tias koj tab tom teem caij mus ntsib rau ntau leej hauv tsev neeg rau tib zaug.",
    "vaccines_selected": "Cov tshuaj txhaj tiv thaiv kab mob uas xaiv lawm",
    "where_care": "Koj xav kom tau txais kev saib xyuas rau ntawm lub chaw twg?",
    "select_vaccines_for_individual": "Xaiv cov tshuaj txhaj tiv thaiv kab mob rau koj qhov teem caij mus ntsib (xaiv tau).",
    "select_vaccines_for_family": "Xaiv cov tshuaj txhaj tiv thaiv kab mob rau koj tsev neeg qhov teem caij mus ntsib (xaiv tau).",
    "schedule_services": "Sij hawm muab kev saib xyuas",
    "add_family": "Ntxiv cov tswv cuab hauv tsev neeg thiab xaiv cov kev saib xyuas hauv qab no rau.",
    "family_member": "Tus neeg hauv tsev neeg {{number}}",
    "morning": "Sawv ntxov",
    "afternoon": "Tav su",
    "slot_available": "{{number}} khoom",
    "within_x_miles": "Hauv {{miles}} mais kev ntawm",
    "any_distance": "Ib ncua deb tuaj ntawm",
    "partial_results": "Qhov kuaj tau los ib txhia",
    "partial_matches": "Cov chaw kuaj mob nram qab no muaj qee yam tshuaj tab sis tsis muaj tag nrho cov tshuaj txhaj tiv thaiv kab mob uas koj tab tom nrhiav",
    "no_matches": "Qhov tshawb nrhiav ntawd nrhiav tsis pom ib qho uas haum li. Sim hloov pauv koj lub lim kom nrhiav tau qhov kuaj nrhiav tau los zoo dua.",
    "no_clinics_found": "Tsis muaj ib lub chaw kuaj mob twg uas yuav haum raws li koj qhov tshawb nrhiav li.",
    "broaden_filters": "Sim tshawb nrhiav kom dav ntxiv rau koj lub lim kom nrhiav tau qhov kuaj nrhiav tau los zoo dua.",
    "unavailable_vaccines": "Tsis muaj cov tshuaj txhaj tiv thaiv kab mob:",
    "available_vaccines": "Muaj cov tshuaj txhaj tiv thaiv kab mob:",
    "select_date": "Xaiv ib hnub",
    "available_appointments": "MUAJ QHOV TEEM CAIJ MUS NTSIB",
    "appointment_scheduling_info": "Koj tuaj yeem teem lub sij hawm mus ntsib rau cov tswv cuab tshwj xeeb hauv tsev neeg rau kauj ruam tom ntej. Koj tuaj yeem teem caij mus ntsib rau cov hnub uas sib txawv tau yog tias koj xaiv.",
    "hold_selected_appointments": "Peb mam li saib raws li koj lub sij hawm teem caij uas tau xaiv tseg ntawd tau ntev li 15 feeb.",
    "appointments_selected": "COV KEV TEEM CAIJ MUS NTSIB UAS XAIV LAWM",
    "no_appointments_selected": "Tsis muaj kev teem caij mus ntsib uas xaiv lawm",
    "vaccines_needed": "Cov tshuaj txhaj tiv thaiv kab mob uas xav tau",
    "select_x_appointments": "Xaiv {{number}} cov kev teem caij mus ntsib.",
    "more": "Ntau dua",
    "less": "Tsawg dua",
    "register_next_family_member": "Tso npe rau tus tswv cuab hauv tsev neeg txuas ntxiv",
    "successfully_registered_x_of_y_family_members": "Koj tso npe tiav {{x}} ntawm {{y}} cov tswv cuab hauv tsev neeg tau lawm",
    "next_family_member": "Tus tswv cuab hauv tsev neeg txuas ntxiv",
    "appointments_abbreviated": "appts",
    "register_for_clinic": "Tso npe rau lub chaw kuaj mob",
    "select_services": "Xaiv cov kev saib xyuas",
    "person": "Tus neeg {{number}}",
    "add_person": "Ntxiv lwm tus neeg rau",
    "registration_confirmation": "Kev lees qhov tso npe"
  },
  "user_mailer": {
    "verify_email": "Los lees paub koj tus email!",
    "hi_full_name": "Nyob zoo {{full_name}},",
    "verify_email_button": "Lees paub tus email",
    "please_verify_email": "Thov siv kem nias hauv qab no los mus lees paub koj tus email."
  },
  "services": {
    "dptap_dt": "DTaP/Tdap/Td",
    "hepatitis_a": "Kab Mob Siab A",
    "hepatitis_b": "Kab Mob Siab B",
    "hepatitis_a_b": "Kab Mob Siab A thiab B",
    "hib": "Kab Mob Hib",
    "mmr": "Kab mob ua qoob, mob mumps, mob rubella",
    "meningococcal": "Kab mob Meningococcal",
    "mpox": "Kab mob Mpox",
    "pneumococcal": "Kab mob ntsws",
    "polio": "Kab mob Polio",
    "rsv": "Kab mob vais lav rau feem hlab ua pa",
    "rotovirus": "Kab mob hnyuv rau me nyuam mos",
    "zoster": "Kab mob tawv nqaij (Kab mob tawv nqaij liab)",
    "tetanus_diptheria": "Kab Mob Xeb thiab Kab Mob Diphtheria",
    "tdap": "Kab mob Tdap",
    "typhoid": "Kab mob Typhoid",
    "varicella": "Kab mob Varicella (Ua pob xoo liab)",
    "covid-19": "Kab mob COVID-19",
    "covid-19_adult": "Kab mob COVID-19 (Neeg loj)",
    "covid-19_adult_description": "Rau cov neeg muaj 12 xyoos los sis siab dua.",
    "covid-19_children": "Kab mob COVID-19 (Me nyuam yaus)",
    "covid-19_children_description": "Rau cov me nyuam yaus hnub nyoog 4 - 11 xyoos.",
    "covid-19_infants": "Kab mob COVID-19 (Me nyuam mos liab)",
    "covid-19_infants_description": "Rau cov me nyuam yaus hnub nyoog 6 hli- 3 xyoos.",
    "influenza": "Kab Mob Hnoos Heev (Influenza)",
    "mmrv": "Kab mob ua qoob, Kab mob ua qog su, Kab mob Rubella thiab Varicella"
  },
  "deep_archived": {
    "admin": "Xav kom muaj kev nyab xeeb rau cov ntaub ntawv, cov ntaub ntawv hauv pab pawg {{test_group_name}} ({{slug}}) raug khaws cia mus ib txhis lawm thiab yuav saib tsis tau lawm los sis yuav hloov tsis tau suav txij phab ntawv no mus lawm.Thov tiv toj rau tus thawj tswj xyuas koj tus as qhauj los sis upport@primary.health yog tias koj xav tau kev pab.",
    "participant": "Tsis muaj phab ntawv no lawm. Thov tiv toj rau koj cov neeg tiv toj los ntawm lub rooj nthuav qhia txog kev noj qab haus huv: {{test_group_name}}. Yog tias koj muaj teeb meem los saib cov ntaub ntawv teev tseg qub, ces tiv toj rau support@primary.health yog xav tau kev pab."
  }
}