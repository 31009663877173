import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

import ActionsDropdown from './sections/ActionsDropdown';
import FabrxCheckbox from "../../../Registration/primary/FabrxCheckbox";
import { printPdfLabelOnClick } from '../PdfLabelPrinter';
import { SaveRemoveServiceButtons } from '../ServiceCard';
import ServiceSurveySection from './sections/ServiceSurveySection';
import { zplCodeOnClick } from '../ZplCodePrinter';


const GenericTestCardBody = ({
  notesRef,
  test,
  submitTests,
  updateTest,
  ...props
}) => {

  const [notes, setNotes] = useState(test.notes);

  const handleTestUpdate = async (updatedTest, errorMessage) => {
    const response = await submitTests([updatedTest]);
    if (response.data.success) {
      return true;
    } else {
      toatr.error(errorMessage || "Something went wrong");
      return false;
    }
  };

  const handleAdministerUnadminister = async () => {
    const updatedTest = { ...test, administered: !test.administered, saved: true };
    const isSuccess = await handleTestUpdate(
      updatedTest,
      "Something went wrong while saving the administered status"
    );
    if (isSuccess) updateTest(updatedTest);
  };

  const handleNotesBlur = async (e) => {
    const updatedTest = { ...test, notes: e.target.value };
    await handleTestUpdate(
      updatedTest,
      "Something went wrong while saving the notes"
    );
  };

  const handleClickOnPrintLabel = () => {
    if (test.label_code == "zebra_label_code") {
      zplCodeOnClick(test, updateTest);
    } else {
      printPdfLabelOnClick(test, updateTest, submitTests);
    }
  };

  return (
    <div>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Barcode</Form.Label>
            <Form.Control
              disabled={true}
              value={test.uid}
              data-test-hook={`barcode-${test.uid}`}
            />
          </Form.Group>
        </Col>
        <Col>
          <div className="d-flex justify-content-end">
            <Button
              onClick={handleClickOnPrintLabel}
            >
              Print label
            </Button>
            <ActionsDropdown
              submitTests={submitTests}
              test={test}
              {...props}
            />
          </div>
        </Col>
      </Row>
      <ServiceSurveySection
        submitTests={submitTests}
        test={test}
        updateTest={updateTest}
      />
      <Row>
        <FabrxCheckbox
          checked={test.administered}
          className="ms-3 mb-3"
          onChange={handleAdministerUnadminister}
          label={test.administered ? "Administered" : "Not administered"}
        />
      </Row>
      <Row>
        <Form.Group>
          <Form.Label>Notes</Form.Label>
          <Form.Control
            as="textarea"
            onBlur={handleNotesBlur}
            onChange={(e) => setNotes(e.target.value)}
            ref={notesRef}
            value={notes}
          />
        </Form.Group>
      </Row>
      <SaveRemoveServiceButtons
        disabled={false}
        displayRemove={!test.result && test.state != "processing"}
        label={"save"}
        slug={test.test_group.slug}
        testId={test.id}
      />
    </div>
  );
};

export default GenericTestCardBody;
