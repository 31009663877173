import React, { useState } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Accordion,
  Collapse,
  Badge,
  Image,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import printJS from 'print-js';
import axios from 'axios';
import SurveyPreview from '../../../common/components/SurveyPreview';
import '../../../common/locales/i18n';
import { ChevronDropDown } from '../../../common/components/Chevron';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faEllipsis, faCircleCheck, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { today, dataURItoBlob } from '../../../common/utils';
import AsyncMultiSelect from '../../../common/components/AsyncMultiSelect';
import FabrxCheckbox from '../../../Registration/primary/FabrxCheckbox';
import { Camera, Digital, Manage, Shield, Upload } from '../../../common/components/Icons';
import { useRef } from 'react';
import { buildGuardianConsent, ConsentContainer } from '../../../Consents/NewConsentFormsUsers';
import { validateSurvey } from '../../../Registration/components/registration/CustomSurvey';
import { useEffect } from 'react';
import AdditionalConsentsModal from './AdditionalConsentModal';
import { faEye, faRotate } from '@fortawesome/pro-solid-svg-icons';
import Insurance from '../insurance/Insurance';
import { isValidInteger } from '../../../CheckoutPage/components/utils';

export const pushReminderToSign = ({ id }) => {
  axios
    .post(`/api/send_consent_reminder_appointment/${id}`)
    .then(() => {
      toastr.success(`Reminder sent!`);
    })
    .catch((error) => {
      const {
        response: { status },
      } = error;
      if (status === 401) {
        toastr.error('Not authorized to send reminder.');
      } else {
        toastr.error('Failed to send reminder.');
      }
    });
};

const TestLaneSection = ({ appointment }) => {
  return (
    <React.Fragment>
      {appointment.available_test_lanes?.map((testLane) => (
        <div
          style={{ float: 'left' }}
          key={testLane.name}
          data-test-hook="test_lane"
        >
          <input
            type="radio"
            className="btn-check"
            name="appointment[test_lane_id]"
            value={testLane.id}
            id={`test-lane-${testLane.id}`}
            autoComplete="off"
            required
            defaultChecked={appointment.test_lane_id === testLane.id}
          />
          <label
            className="btn btn-outline-primary me-2"
            htmlFor={`test-lane-${testLane.id}`}
          >
            {testLane.name}
          </label>
        </div>
      ))}
      <div className="clearfix"></div>
      <hr/>
    </React.Fragment>
  );
};

const SurveySection = ({ value, survey, title, inputName, testGroup, setSurveyMissingAnswer, newSurvey=false }) => {
  const [answers, setAnswers] = useState(value);
  const [show, setShow] = useState(true);
  const surveyErrors = validateSurvey.bind(null, null, survey, testGroup)()(answers);
  const surveyHasErrors = Object.keys(surveyErrors).length > 0;

  useEffect(() => {
    const surveyErrorHeader = document.getElementsByClassName("missingSurveyAnswer-error-header");
    const missingSurveyAnswerErrors = document.getElementsByClassName("missing-survey-answer");
    if (!surveyHasErrors) {
      if (missingSurveyAnswerErrors.length < 1) {
        Array.from(surveyErrorHeader).forEach(h => h.classList.add("d-none"));
        setSurveyMissingAnswer(false);
      }
    } else {
      Array.from(surveyErrorHeader).forEach(h => h.classList.remove("d-none"));
      // only disable checkout button if checkout questionnaire fails validation
      if (title === "Checkout Questionnaire") setSurveyMissingAnswer(true);
    }
  }, [answers]);

  return (
    <React.Fragment>
      <div onClick={()=> setShow(!show)}>
        <hr/>
        <ChevronDropDown open={show} setOpen={setShow} btnClass="p-0" />
        {title &&
          <h5>
            {title} {' '}
            { surveyHasErrors &&
              <FontAwesomeIcon
                icon={faCircleExclamation}
                className="text-danger missing-survey-answer"
              />
            }
          </h5>
      }
      </div>
      <Collapse in={show}>
        <div>
          <input type="hidden" name={inputName} value={JSON.stringify(answers)} />
          <SurveyPreview json={survey} data={answers} newSurvey={newSurvey} onValueChanged={(e) => setAnswers(e.data)} />
        </div>
      </Collapse>
    </React.Fragment>
  );
};

export const additionalConsents = (testConfigurations) => (
  testConfigurations.map(tc => tc.test_group_test_configuration.active_consent_form.found &&
    ({id: tc.test_group_test_configuration.active_consent_form.id, testConfiguration: tc })
  ).filter(x => x)
)

const ConsentSection = ({
  appointment: { consent_capture_method, ...appointment },
  user,
  tests,
  testGroup,
  testConfigurations=[],
}) => {
  const consented = appointment['consents_signed?'];
  const [consentImage, setConsentImage] = useState(null);
  const [fileText, setFileText] = useState(null);
  const [showWebcam, setShowWebcam] = useState(false);
  const [show, setShow] = useState(true);
  const fileRef = useRef(null)
  const testConfigurationDisplayList = testConfigurations.map(tc => tc.display_name).join(", ");
  const isMobileView = () => screen.width < 400;

  const updateWebcamImage = (img) => {
    setConsentImage(img)
    let container = new DataTransfer();
    container.items.add(dataURItoBlob(img, true))
    document.getElementById("consentFile").files = container.files;
    setShowWebcam(false);
  }

  const additionalUserConsents = additionalConsents(testConfigurations).
    filter(x => {
      return user.consent_forms_users.map(cfu => cfu.consent_form_id).includes(x.id) ||
        tests.map(t => t.test_configuration_id).includes(x.testConfiguration.id)
    });

  const additionalConsentState = (consentFormUser) => {
    const consent = consentFormUser || { consent: null, consented: false }
    const declinedConsent = consent.consent == "no";

    const renderTooltip = (props) => (
      <Tooltip {...props}>
        { consent.consented
          ? "Consented"
          : declinedConsent
            ? "Declined consent"
            : "Consent for this service has not been provided by the participant"
        }
      </Tooltip>
    );

    if (consent.consented) return;

    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        {!consent.consented && (
          <span>
            <FontAwesomeIcon
              icon={declinedConsent ? ["far", "times-circle"] : faCircleExclamation}
              className="text-danger"
              size="lg"
            />
          </span>
        )}
      </OverlayTrigger>
    )
  }

  return (
    <React.Fragment>
      <div onClick={() => setShow(!show)}>
        <ChevronDropDown open={show} setOpen={setShow} btnClass="p-0" />
        <h5>
          Consent{' '}
          { !consented &&
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className="text-danger"
            />
          }
          {!!user.requestable_consents_forms && (
            <span className="ms-4">
              <AdditionalConsentsModal
                requestableConsentForms={user.requestable_consents_forms}
                user={user}
                testGroup={testGroup}
              />
            </span>
          )}
        </h5>
      </div>
      <Collapse in={show}>
        <div className="mt-4">
          <h6>Required consent</h6>
          <Row>
            <Col lg={6} md={9}>
              <ConsentContainer
                consented={consented}
                consentOption={consented ? (
                  <a
                    className="btn btn-link"
                    href={`/test_groups/${testGroup.slug}/participants/${user.id}/consent_forms_users`}
                    target="_blank"
                  >
                    View
                  </a>
                ) : (
                  <Dropdown>
                    <Dropdown.Toggle variant="link" className="chevron" id="dropdown-consent">
                      Sign
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href={`${user.confirmation_link}?confirmation_bypass=show_consent`} target="_blank">
                        <Digital className='me-2' style={{ width: 14 }} /> Capture consent digitally
                      </Dropdown.Item>
                      <Dropdown.Item  href="javascript:;" onClick={() => pushReminderToSign(appointment)}>
                        <FontAwesomeIcon className='me-2' icon={faEnvelope} /> Send consent to participant
                      </Dropdown.Item>
                      <Dropdown.Item
                        href={`/test_groups/${testGroup.slug}/participants/${user.id}/consent_forms_users`}
                        target="_blank"
                      >
                        <Manage className='me-2' style={{ width: 14 }} /> Manage consents
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setShowWebcam(true)} >
                        <Camera className='me-2' color='inherit' style={{ width: 14 }} />Take photo
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => fileRef.current.click()}>
                          <Upload className="me-1" color='inherit' style={{ width: 14 }} /> Upload waiver
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                showWebcam={showWebcam}
                webcamProps={{
                  setImageData: (data) => updateWebcamImage(data),
                  setShow: setShowWebcam,
                }}
                fileText={fileText}
                fileProps={{
                  ref: fileRef,
                  id: "consentFile",
                  name: "appointment[consent_waiver_image]",
                  onChange: (e) => {
                    setFileText(e.target.files[0].name);
                    setConsentImage(null);
                  },
                }}
                consentImage={consentImage}
                badge={!consented && <span><FontAwesomeIcon icon={faCircleExclamation} size="lg" className="text-danger" /></span>}
                title={testGroup.name}
                isMobileView={isMobileView()}
              />
            </Col>
          </Row>
          {consentImage && <div><Image src={consentImage} className="my-3 rounded" height="150" /></div>}
          {buildGuardianConsent(user, appointment, testGroup)}

          {additionalConsents(testConfigurations).length > 0 && (
            <div className="my-4">
              <h6>Additional consents</h6>
              {additionalUserConsents.length > 0 &&
                <p>These consents are required for specific services to be administered</p>
              }
            </div>
          )}

          {additionalConsents(testConfigurations).map(additionalConsent => {
            const consentFormUser = user.consent_forms_users.find(consentFormUser => consentFormUser.consent_form_id == additionalConsent.id);
            const requiredConsent = additionalUserConsents.find(a => a.id == additionalConsent.id)
            return (
              <Row className='my-3'>
                <Col lg={6} md={9}>
                  <Row>
                    <Col>
                      <div className={`p-4 border rounded ${requiredConsent && !consentFormUser?.consented && "border-danger-500"}`}>
                        <div className='d-flex'>
                          <div>
                            <b>{additionalConsent.testConfiguration.checkout_name || additionalConsent.testConfiguration.display_name} consent</b>
                            {isMobileView() && !!consentFormUser && <span className="mx-1">{additionalConsentState(consentFormUser)}</span>}
                          </div>
                          <div className='ms-auto'>
                            {consentFormUser
                              ? <a
                                  href={`/test_groups/${testGroup.slug}/participants/${user.id}/consent_forms_users?consent_type=${additionalConsent.testConfiguration.test_group_test_configuration.id}`}
                                  target="_blank"
                                >
                                  <b>View</b>
                                </a>
                              : <a
                                  href={`/test_groups/${testGroup.slug}/participants/${user.id}/consent_forms_users/${appointment.id}/bulk_edit?redirect_to=${location.href}`}
                                  target="_blank"
                                >
                                  <b>Sign</b>
                                </a>
                            }
                          </div>
                        </div>
                      </div>
                    </Col>
                    {!isMobileView() && (
                      <Col xs="1" className="my-auto">
                        {additionalConsentState(consentFormUser)}
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
          )})}
        </div>
      </Collapse>
    </React.Fragment>
  );
};

const DependentsSection = ({ appointment, user, testGroup, symptoms }) => {
  return user.dependent_appointments?.map((dep_appointment, idx) => (
    <React.Fragment key={idx + 'consent'}>
      <div className="h4 my-2">
        {dep_appointment.first_name} {dep_appointment.last_name}
      </div>
      <input
        type="hidden"
        name={`dependent_appointments[${idx}][user_id]`}
        value={dep_appointment.id}
      />
      <div className="btn-group-toggle" data-bs-toggle="buttons">
        {appointment?.available_test_lanes?.map((testLane) => (
           <div
            key={testLane.name}
            data-test-hook="test_lane"
          >
            <input
              type="radio"
              className="btn-check"
              name={`dependent_appointments[${idx}][test_lane_id]`}
              value={testLane.id}
              id={`test-lane-${testLane.id}-${idx}`}
              autoComplete="off"
              required
            />
            <label
              className="btn btn-outline-primary me-2"
              htmlFor={`test-lane-${testLane.id}-${idx}`}
            >
              {testLane.name}
            </label>
          </div>
        ))}
      </div>
      <div className="mt-3 mb-3">
        <div className="form-check">
          <input
            type="checkbox"
            name={`dependent_appointments[${idx}][consent]`}
            value="1"
            checked="checked"
            className="form-check-input"
          />
          <label className="form-check-label">Signed Consent?</label>
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <SymptomsSection
          symptoms={symptoms}
          appointment={dep_appointment}
          name={`dependent_appointments[${idx}]`}
          showHeader={false}
        />
      </div>
    </React.Fragment>
  ));
};

const SymptomsSection = ({ user={}, symptoms, appointment, name="appointment", showHeader=true }) => {
  const [symptomValues, setSymptomValues] = useState(appointment.symptoms);
  const [show, setShow] = useState(true);

  const { t, i18n } = useTranslation();

  return (
    <React.Fragment>
      {showHeader &&
        <div onClick={()=> setShow(!show)}>
          <hr/>
          <ChevronDropDown open={show} setOpen={setShow} btnClass="p-0" />
          <h5 className="my-4">
            Symptoms
            {Object.values(symptomValues).some(v => v === "1" || v === "true") && (
              <FontAwesomeIcon icon={faCircleCheck} className="mx-2" color="var(--bs-success)"/>
            )}
          </h5>
        </div>
      }
      <Collapse in={show} >
        <div>
          {user.dependent_appointments &&
            <h5 className="mb-2">
              {user.first_name} {user.last_name}
            </h5>
          }
          <div className="row">
            {symptoms.map((symptom, idx) => {
              const active =
                symptomValues[symptom.key] === '1' ||
                symptomValues[symptom.key] === 'true';
              return (
                <div className="col-md-6" key={`symptom-${idx}`}>
                  <FabrxCheckbox
                    ariaLabel={t(`symptoms.${symptom.key}`)}
                    value="1"
                    name={`${name}[symptoms_blob][${symptom.key}]`}
                    defaultChecked={active}
                    label={t(`symptoms.${symptom.key}`)}
                  />
                </div>
            )})}
          </div>
        </div>
      </Collapse>
    </React.Fragment>
  );
};

const TagSection = ({user, testGroup}) => {
  const [edit, setEdit] = useState(false);
  const [show, setShow] = useState(true);
  const tags = user.user_tags?.map(userTag => (
    {id: userTag.tag_id, friendly_name: userTag.friendly_name}
  )) || []
  const [activeTags, setActiveTags] = useState(tags);
  const originalTagIds = tags.map(t => t.id);
  const activeTagIds = activeTags.map(t => isValidInteger(t) ? t : t?.id);
  const [inActiveTagIds, setInActiveTagIds] = useState(originalTagIds.filter(t => !activeTagIds.includes(t)));
  const allTagIds = activeTagIds.concat(inActiveTagIds);

  const isOriginalTag = (tagId) => originalTagIds.find(id => id == tagId);

  return (
    <div className="my-3">
      <hr/>
      <div className="mt-4">
        <ChevronDropDown open={show} setOpen={setShow} btnClass="p-0" />
        <h5 className="mb-4">
          Tags{' '}
          {activeTags.length > 0 && (
            <FontAwesomeIcon icon={faCircleCheck} className="mx-2" color="var(--bs-success)"/>
          )}
        </h5>
      </div>
      <Collapse in={show} className="my-4">
        {edit ? (
          <div className="w-50">
            <AsyncMultiSelect
              name="Tags"
              selected={activeTags}
              optionsUrl={`/test_groups/${testGroup.slug}/tags`}
              onSelect={(selected) => {
                const newValues = selected ? selected.map(t => t.value) : [];
                setActiveTags(newValues);
                setInActiveTagIds(originalTagIds.filter(t => !newValues.includes(t)));
              }}
            />
          </div>
        ) : (
          <div>
            <FontAwesomeIcon
              icon={faEllipsis}
              className="text-muted mx-4 mt-2 pointer"
              onClick={() => setEdit(!edit)}
            />
            {activeTags.map((tag, idx) => (
              <Badge variant="primary" className={idx == 0 ? "me-1" : "mx-1"}>{tag.friendly_name}</Badge>
            ))}
          </div>
        )}
      </Collapse>
      {allTagIds.map((tagId, idx) => (
          inActiveTagIds.includes(tagId) ? (
            isOriginalTag(tagId) &&
              <>
                <input
                  type="hidden"
                  value="1"
                  name={`user[user_tags_attributes][${idx}][_destroy]`}
                />
                <input
                  type="hidden"
                  value={user.user_tags.find(x => x.tag_id == tagId).id}
                  name={`user[user_tags_attributes][${idx}][id]`}
                />
              </>
          ) : !isOriginalTag(tagId) &&
            <input type="hidden" value={tagId} name={`user[user_tags_attributes][${idx}][tag_id]`} />
        ))}
    </div>
)};

const InsuranceSection = ({ user, appointment, testGroup }) => {
  let missingState;

  if(testGroup.insurance_information_required) {
    missingState = !user.insurance_policy_holder;
  } else {
    missingState = !(user.insurance_policy_holder || user.drivers_license_number || user.social_security_number);
  }

  const [show, setShow] = useState(true);
  const [missing, setMissing] = useState(missingState)


  return (
    <div className="my-3" id="insurance-section">
      <hr/>
      <div className="mt-4">
        <ChevronDropDown open={show} setOpen={setShow} btnClass="p-0" />
        <h5 className="mb-4">
          Insurance{' '}
          { missing &&
              <FontAwesomeIcon
                icon={faCircleExclamation}
                className="text-danger"
              />
            }
          {!missing && (
            <FontAwesomeIcon icon={faCircleCheck} className="mx-2" color="var(--bs-success)"/>
          )}
        </h5>
      </div>
      <Collapse in={show}>
        <div>
          <Insurance test_group={testGroup} user={user} appointment={appointment} />
        </div>
      </Collapse>
    </div>
  );
}

export const CheckInInputs = ({
  appointment,
  user,
  symptoms,
  tests,
  testGroup,
  activeSections = [],
  testConfigurations = [],
  disableAdvanced=false,
  setSurveyMissingAnswer,
  checkOut=false,
}) => {
  const [checkIn, setCheckIn] = useState(true);

  const guardianInformation = appointment['has_guardian_information?'] && (
    <div>
      Guardian Info: {appointment.consented_guardian_name}
      {appointment.consented_guardian_phone_number &&
        `/ ${appointment.consented_guardian_phone_number}`}
      {appointment.consented_guardian_email &&
        `/ ${appointment.consented_guardian_email}`}
    </div>
  );

  const showInsuranceSection = testGroup.insurance_fields_requested.includes('insurance') || testGroup.insurance_fields_required.includes('insurance');

  const sections = {
    guardian_information: guardianInformation,
    test_lane: appointment.available_test_lanes?.length > 0 && <TestLaneSection appointment={appointment} />,
    consent: <ConsentSection tests={tests || appointment.tests} appointment={appointment} user={user} testGroup={testGroup} testConfigurations={testConfigurations} />,
    medical_screening_survey: testGroup.medical_screening_survey && (
      <SurveySection
        value={() => {
          try {
            return JSON.parse(appointment.medical_screening_survey_answers.answers)
          } catch {
            return {}
          }
        }}
        survey={testGroup.medical_screening_survey}
        title="Medical Screening"
        inputName="appointment[medical_screening][answers]"
        testGroup={testGroup}
        setSurveyMissingAnswer={setSurveyMissingAnswer}
      />
    ),
    appointment_survey: testGroup.show_custom_survey && (
      <SurveySection
        value={appointment.questionnaire}
        survey={testGroup.custom_survey}
        title="Appointment Questionnaire"
        inputName="appointment[questionnaire]"
        testGroup={testGroup}
        setSurveyMissingAnswer={setSurveyMissingAnswer}
      />
    ),
    test_group_user_survey: Object.keys(testGroup.test_group_user_survey).length >
      0 && (
      <SurveySection
        value={user.test_group_user.survey}
        survey={testGroup.test_group_user_survey}
        title="Participant Questionnaire"
        inputName="test_group_user[survey]"
        testGroup={testGroup}
        setSurveyMissingAnswer={setSurveyMissingAnswer}
      />
    ),
    dependents: testGroup.allow_dependents && (
      <DependentsSection
        appointment={appointment}
        user={user}
        testGroup={testGroup}
        symptoms={symptoms}
      />
    ),
    symptoms: (
      <SymptomsSection
        user={user}
        symptoms={symptoms}
        appointment={appointment}
      />
    ),
    tags: <TagSection user={user} testGroup={testGroup} />,
    checkout_survey: testGroup.checkout_survey && Object.keys(testGroup.checkout_survey).length > 0 && (
      <SurveySection
        value={appointment.checkout_survey || {}}
        survey={testGroup.checkout_survey}
        title="Checkout Questionnaire"
        inputName="appointment[checkout_survey]"
        testGroup={testGroup}
        setSurveyMissingAnswer={setSurveyMissingAnswer}
      />
    ),
    insurance: showInsuranceSection && (
      <InsuranceSection user={user} appointment={appointment} testGroup={testGroup} />
    ),
    insurance_survey: testGroup.insurance_survey && (
      <SurveySection
        value={typeof(appointment.insurance_answers) == 'string' ? JSON.parse(appointment.insurance_answers) : appointment.insurance_answers}
        survey={testGroup.insurance_survey}
        title="Insurance Questionnaire"
        inputName="appointment[insurance_answers]"
        newSurvey={testGroup.flipper_flags.upgraded_surveyjs}
        testGroup={testGroup}
        setSurveyMissingAnswer={setSurveyMissingAnswer}
      />
    ),
  };

  return (
    <div className="h5-vivid-blue">
      <input type="hidden" name="_method" value="put" />
      <input
        type="hidden"
        name="authenticity_token"
        value={appointment.form_authenticity_token}
      />
      <input type="hidden" value={checkIn} name="appointment[check_in]" />
      <input type="hidden" value={checkOut} name="appointment[check_out]" />
      {activeSections.map((section, idx) =>
        <React.Fragment key={idx}>
          {sections[section]}
        </React.Fragment>
      )}
      {!disableAdvanced &&
        <Accordion>
          <Accordion.Toggle
            as={Button}
            variant="link"
            className="mb-2 p-0"
            eventKey="0"
          >
            Advanced
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <div
            className="h6 custom-control custom-switch custom-switch-lg"
              onClick={() => setCheckIn(!checkIn)}
            >
              <input
                type="radio"
                defaultChecked={checkIn}
                checked={checkIn}
                className="custom-control-input"
              />
              <label className="custom-control-label">Confirm Check In</label>
            </div>
          </Accordion.Collapse>
        </Accordion>
      }
    </div>
  );
};

const CheckInModal = ({
  setShow,
  show,
  appointment,
  user,
  symptoms,
  testGroup,
  testConfigurations=[],
  redirect = true,
  overrideCheckin = () => {},
}) => {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const appointmentIsToday = appointment.on_demand
    ? true
    : today(appointment.appointment_slot.localized_day);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = document.querySelector('#checkInModal')
    const formData = new FormData(form)

    axios({
      url: `/test_groups/${testGroup.id}/appointments/${appointment.id}`,
      method: "post",
      data: formData,
      headers: { 'content-type': 'multipart/form-data' },
    })
      .then(() => {
        checkIn && toastr.success('Checked In');
        overrideCheckin(checkIn);
        handleClose();
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      data-test-hook="check_in_modal"
    >
      <Modal.Header>
        <Modal.Title as="h2">
          Check In for {user.first_name} {user.last_name}{' '}
          {!appointmentIsToday && (
            <div className="alert-danger">
              This appointment is not scheduled for today.
            </div>
          )}
        </Modal.Title>
        <button
          type="button"
          className="btn-close"
          onClick={handleClose}
          aria-label="Close"
        ></button>
      </Modal.Header>
      <form
        onSubmit={handleSubmit}
        encType="multipart/form-data"
        action={`/test_groups/${testGroup.id}/appointments/${appointment.id}`}
        acceptCharset="UTF-8"
        method="post"
        id="checkInModal"
        className="h5-vivid-blue"
      >
        <Modal.Body>
          <CheckInInputs
            appointment={appointment}
            user={user}
            symptoms={symptoms}
            testGroup={testGroup}
            testConfigurations={testConfigurations}
            activeSections={[
              "guardian_information",
              "test_lane",
              "consent",
              "medical_screening_survey",
              "appointment_survey",
              "test_group_user_survey",
              "dependents",
              "symptoms",
              "tags",
              "checkout_survey",
              "insurance",
              "insurance_survey"
            ].filter(section => !testGroup.disabled_checkin_options.includes(section))}
          />
        </Modal.Body>

        <Modal.Footer>
          {appointment.links?.vaccine_consent_form && (
            <a
              onClick={() => printJS(appointment.links.vaccine_consent_form)}
              className="btn btn-outline-primary me-auto"
            >
              Print Vaccine Consent Form
            </a>
          )}
          <Button variant="outline-secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            type="submit"
            data-test-hook="save"
            onClick={redirect ? null : handleSubmit}
          >
            Save changes
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default CheckInModal;
