import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TestConfigurationForm from './instructions/TestConfigurationForm';
import NewShow from './instructions/NewShow';
import { Container } from 'react-bootstrap';
import Header from '../../Registration/components/Header';
import SwitchUsers from '../../MembersPortal/components/partials/SwitchUsers';
import Consent from './instructions/Consent';

const LOGGED_IN_NOT_READY = 0;
const LOGGED_IN_READY = 1;
const LOGGED_IN_NO_HOUSEHOLD_MEMBERS = 2;

const HeaderWrapper = ({testGroup, user, householdMembers, loadingMembers, children}) => (
  <div>
    <Header
      testGroup={testGroup}
      loggedInComponent={householdMembers?.length > 0 &&
        <SwitchUsers
          currentUser={user.id ? householdMembers.find(hm => hm.user.id === user.id) || {} : {}}
          householdMembers={householdMembers}
          ready={loadingMembers}
          disableOtherMembers
        />
      }
    />
    <div className="flex mb-2" role="main">
      {children}
    </div>
  </div>
)

const Instruction = ({ render_page, user, test_group, logged_in=false }) => {
  const { t, i18n } = useTranslation();
  const [householdMembers, setHouseholdMembers] = useState([]);
  const [loadingMembers, setLoadingMembers] = useState(LOGGED_IN_NOT_READY);

  useEffect(() => {
    if (logged_in) {
      fetch(`/members/household_members.json`)
        .then(response => response.json())
        .then((data) => {
          setHouseholdMembers(data.household_members);
          setLoadingMembers(data.household_members.length > 0 ? LOGGED_IN_READY : LOGGED_IN_NO_HOUSEHOLD_MEMBERS);
        })
        .catch((error) => console.log("Error"));
    }
  }, []);

  if (render_page.show_consent) {
    return <Consent loggedIn={logged_in} user={user} testGroup={test_group} />
  } else {
    return (
      <HeaderWrapper
        testGroup={test_group}
        user={user}
        householdMembers={householdMembers}
        loadingMembers={loadingMembers}
      >
        {render_page.show_test_configurations ? (
          <Container className="pb-4">
            <TestConfigurationForm
              initialValues={user.appointment}
              testGroup={test_group}
              user={user}
            />
          </Container>
        ) : (
          <NewShow
            user={user}
            testGroup={test_group}
            newConfirmation
            loggedIn={logged_in}
            isAppointmentCanceled={render_page.canceled}
            modalOpen={render_page.modal_open}
            renderPage={render_page}
          />
        )}
      </HeaderWrapper>
    );
  }
};

export default Instruction;
