export default {
  "date": {
    "order": [
      "year",
      "month",
      "day"
    ],
    "month": "مہینہ",
    "day": "دن",
    "year": "سال۔",
    "january": "جنوری۔",
    "february": "فروری۔",
    "march": "مارچ۔",
    "april": "اپریل۔",
    "may": "مئی",
    "june": "جون",
    "july": "جولائی۔",
    "august": "اگست۔",
    "september": "ستمبر",
    "october": "اکتوبر۔",
    "november": "نومبر۔",
    "december": "دسمبر۔"
  },
  "user": {
    "send_appointment_confirmation_message": "{{name}}کے لیے آپ کی اپائنٹمنٹ کی توثیق ہو گئی ہے ۔",
    "landline_appointment_reminder_message": "ہیلو ،٪ {full_name}۔ یہ٪ {name} پر آپ کی ملاقات کے لیے ایک یاد دہانی ہے۔ آپ کی ملاقات٪ {date} کو٪ {time} پر٪ {address} پر ہے۔",
    "send_at_home_visit_confirmation_message": "آپ کی اپائنٹمنٹ کی توثیق {{date}}کو {{time}}کے بعد کی گئی ہے ۔",
    "send_mail_order_confirmation_message": "آپ کے میل آرڈر کی تصدیق ہو گئی ہے",
    "send_waitlist_message": "آپ کو٪ {name} کے لیے ویٹ لسٹ کیا گیا ہے۔ براہ کرم جب تک ہم ملاقات کے وقت کی تصدیق نہ کریں اس وقت تک ظاہر نہ کریں۔",
    "verify_contact": {
      "phone": "براہ کرم کلک کرکے اپنے فون نمبر کی تصدیق کریں:{{confirmation_link}}",
      "email": "{{confirmation_link}}"
    },
    "duplicate_user_continue_registering": "یہاں ملاقات کے لیے اندراج جاری رکھیں:٪ {url}",
    "on_demand_confirmation_message": "آپ نے{{name}}کے لیے کامیابی کے ساتھ اندراج کر لیا ہے ۔",
    "appointment_reminder": "٪ {start_time} پر اپنی ملاقات سے پہلے ، براہ کرم کسی بھی بقایا ایکشن آئٹم کو مکمل کرنے اور اپنی تصدیق دیکھنے کے لیے لنک پر کلک کریں۔ براہ کرم اپنی ملاقات سے 15 منٹ پہلے نہ پہنچیں۔",
    "appointment_reminder_base": "٪ {text} یہاں کلک کریں:٪ {link}",
    "test_result_notifier": {
      "text_message": "{{first_name_with_last_initial}} کے لیے مریض کا پورٹل اپ ڈیٹ کر دیا گیا ہے۔ {{url}} پر جائیں اور کوڈ استعمال کریں: {{access_code}}",
      "email_subject": "آپ کے مریض پورٹل میں نئی ​​اپ ڈیٹس",
      "email_intro": "ہیلو {{first_name_with_last_initial}}",
      "email_p1": "آپ کے لیے ایک نیا پیغام یا مریض کی تازہ کاری دستیاب ہے۔",
      "email_p2": "نیچے دیئے گئے بٹن پر کلک کریں یا اپنا مریض پورٹل دیکھنے اور کوڈ استعمال کرنے کے لیے لنک کا استعمال کریں۔",
      "email_p3": "مریض کا پورٹل یہاں دیکھیں:'",
      "email_button": "پورٹل دیکھیں"
    },
    "landline_appointment_reminder_message_without_time": "ہیلو ،٪ {full_name}۔ یہ٪ {name} پر آپ کی ملاقات کے لیے ایک یاد دہانی ہے۔ آپ کی ملاقات٪ {date} کو٪ {address} پر ہے۔",
    "appointment_reminder_on_demand": "٪ {date} پر اپنی ملاقات سے پہلے براہ کرم کسی بھی بقایا ایکشن آئٹم کو مکمل کرنے کے لیے لنک پر کلک کریں اور اپنی تصدیق دیکھیں۔",
    "new_test_result_notifier": {
      "email_intro": "آپ کے مریض کے پورٹل کو اپ ڈیٹ کر دیا گیا ہے۔",
      "email_text_1": "کسی بھی نئے مریض کے ریکارڈ یا لیب کی رپورٹس دیکھنے کے لیے نیچے اپنا میڈیکل ہسٹری پورٹل دیکھیں۔",
      "email_button": "طبی تاریخ",
      "email_text_2": "آپ اس URL کو اپنے براؤزر میں کاپی اور پیسٹ بھی کر سکتے ہیں:"
    },
    "mailers": {
      "email_contact_us": "کوئی سوال ہے؟ ہم سے رابطہ کریں"
    }
  },
  "errors": {
    "generic": {
      "message": "کچھ غلط ہو گیا ، براہ کرم دوبارہ کوشش کریں۔"
    },
    "messages": {
      "blank": "خالی نہیں ہو سکتا"
    },
    "incorrect_credentials": "آپ کی اسناد غلط تھیں، براہ کرم دوبارہ کوشش کریں ۔",
    "error_activating_test_kit": "ٹیسٹ کٹ کو فعال کرنے میں ایک خرابی پیش آگئی ۔"
  },
  "type": "ٹائپ کریں۔",
  "payment": {
    "continue_label": "ادائیگی جاری رکھیں۔",
    "policy_text": "اگر آپ اپنی ملاقات کے لیے منسوخ کرتے ہیں یا ظاہر نہیں کرتے ہیں تو ، ہم آپ کی تقرری کی تاریخ کے 7 دن بعد آپ کی 80 فیصد ادائیگی واپس کر دیں گے۔ آپ مکمل [سروس کی شرائط] دیکھ سکتے ہیں (٪ {link})",
    "card_number": "کارڈ نمبر",
    "expiry_date": "خاتمے کی تاریخ",
    "pay_button": "ادا کریں۔",
    "no_credit_card": "میں سائٹ پر ادائیگی کروں گا",
    "cash_payment_helper": "براہ کرم اپنی ملاقات میں **٪ {payment} ** لانا یقینی بنائیں۔",
    "invoice": "رسید",
    "pay_by_card": "کارڈ کے ذریعے ادائیگی کریں۔",
    "cost": "لاگت",
    "total": "کل۔",
    "pay_cash_at_appointment": "میں اپنی ملاقات کے دوران نقد رقم ادا کروں گا {{appointment}}",
    "view_invoice": "انوائس دیکھیں/پرنٹ کریں۔",
    "refund_policy": "اگر آپ اپنی ملاقات منسوخ کروانا چاہتے ہیں تو ہم آپ کو آپ کی ادائیگی کا {{amount}}ملاقات کی تاریخ کے 7 دن بعد ادا کردیں گے۔آپ مکمل تفصیلات [Terms of Service]({{link}}) پر دیکھ سکتے ہیں۔",
    "refund_window_passed": "آپ کی اپوائنٹمنٹ ریفنڈ کے لیے اہل نہیں ہے کیونکہ اپوائنٹمنٹ کا وقت گزر چکا ہے. براہ کرم کسی بھی ریزولوشن کے لیے براہِ راست سائٹ کوآرڈینیٹرز سے رابطہ کریں",
    "amount_paid": "ادا کی گئی رقم",
    "balance": "بقایا رقم"
  },
  "consent_waiver": {
    "upload_waiver_or_sign": "رضامندی کی چھوٹ اپ لوڈ کریں یا نیچے دستخط والے باکس پر دستخط کریں۔",
    "stop": "رجسٹریشن مکمل کرنے کے لیے رضامندی درکار ہے۔",
    "parent_or_guardian_required_html": "آپ کی رجسٹریشن کو حتمی شکل دینے کے لیے ، آپ کے والدین ، ​​سرپرست ، یا مجاز فیصلہ ساز کو نیچے دیے گئے فارم پر رضامندی فراہم کرنی چاہیے اور ان کے نام پر دستخط کرنا چاہیے۔ آپ <b> اس سروس کو خود نہیں مان سکتے </b>۔",
    "send_parent_or_guardian": "آپ اپنے والدین یا سرپرست کو یہ لنک بھیج سکتے ہیں یا انہیں اس صفحے پر نیچے دستخط کروا سکتے ہیں۔",
    "consent_guardian_name_signature": "پہلا نام",
    "consent_for_minor": "نابالغ کی جانب سے رضامندی پر دستخط کریں۔",
    "guardian_signature": "والدین ، ​​سرپرست ، یا مجاز طبی فیصلہ ساز کے دستخط۔",
    "after_you_schedule": "٪ {age} سے کم عمر کے شرکاء کو والدین یا سرپرست کی جانب سے ان کی جانب سے دستخط شدہ رضامندی ہونی چاہیے۔ براہ کرم اپنے سرپرست کو بھیجنے کے لیے رجسٹریشن کے اختتام تک جاری رکھیں یا اپنے انحصار کے لیے دستخط کریں۔",
    "consent_guardian_last_name_signature": "آخری نام",
    "consent_first_and_last_name": "پہلا اور آخری نام۔",
    "phone_number": "گارڈین کا فون نمبر",
    "email": "گارڈین ای میل",
    "consent_registrar_with_guardian_name_signature": "سرپرست کے نام کا پہلا حصہ",
    "consent_registrar_with_guardian_last_name_signature": "سرپرست کے نام کا دوسرا حصہ حصہ",
    "consent_registrar_first_name_signature": "رجسٹرار کے نام کا پہلا حصہ",
    "consent_registrar_last_name_signature": "رجسٹرار کے نام کا دوسرا حصہ",
    "consent_on_behalf": "میں یہ دستخط {{first_name}} {{last_name}} کی جانب سے کررہا ہوں۔",
    "consent_on_behalf_registrar": "مجھے {{first_name}} {{last_name}} کی جانب سے دست خط کرنے کا زبانی اختیار دیا گیا ہے۔",
    "consent_on_behalf_registrar_with_guardian": "مجھے {{first_name}} {{last_name}} کے سرپرست کی جانب سے دست خط کرنے کا زبانی اختیار دیا گیا ہے۔",
    "registrar_phone": "رجسٹرار کا فون نمبر",
    "registrar_email": "رجسٹرار کی ای میل",
    "consented_by_parent_guardian": "والد یا والدہ/ سرپرست",
    "consented_by_decision_maker": "مجاز طبی فیصلہ ساز",
    "consented_by_guardian_verbal": "سرپرست کے ساتھ رجسٹرار (زبانی رضامندی حاصل کی گئی)",
    "consented_by_registrar": "رجسٹرار (زبانی رضامندی حاصل کی گئی)",
    "full_name": "پورا نام",
    "print": "پرنٹ"
  },
  "registration": {
    "contact_information": "نام اور پتہ",
    "contact_information_additional": "تقرری مواصلات اور نتائج کے لیے والدین یا سرپرست سے رابطہ کی معلومات۔",
    "personal_information": "آبادیاتی معلومات",
    "address_placeholder": "براہ کرم اپنے گھر کا پتہ درج کریں۔",
    "first_name_label": "پہلا نام",
    "last_name_label": "آخری نام",
    "errors": {
      "state": "ریاست کا ایک درست 2 حروف والا ریاستی کوڈ ہونا چاہیے۔",
      "phone_number_invalid": "فون نمبر غلط ہے۔",
      "required": "یہ قدر درکار ہے۔",
      "email_domain": "آپ کا اندراج غلط ہے براہ کرم ڈبل چیک کریں رجسٹریشن اسپانسر کرنے والی تنظیم کے منتخب کردہ شرکاء تک محدود ہے۔ براہ کرم اپنا کام یا کمیونٹی ای میل استعمال کرنے کی کوشش کریں۔ اگر آپ کو لگتا ہے کہ آپ اہل ہیں اور کوئی خرابی ہے تو ، براہ کرم سپورٹ سے رابطہ کریں۔",
      "age_requirement": "تاریخ پیدائش عمر کے تقاضوں کو پورا نہیں کرتی۔",
      "signature_required": "دستخط درکار ہیں۔",
      "regex_invalid": "یہ قدر غلط معلوم ہوتی ہے۔",
      "date_invalid": "یہ تاریخ موجود نہیں ہے۔",
      "invalid_entry": "براہ کرم رجسٹریشن جاری نہ رکھیں۔ اسکرین پر دی گئی ہدایات پر عمل کریں یا اپنے رابطہ کے مقام تک پہنچیں۔",
      "city": "ایک درست شہر ہونا ضروری ہے۔",
      "survey_unanswered": "براہ کرم چیک کریں کہ * کے ساتھ نشان زد تمام ضروری سوالات کے جوابات دے دیے گئے ہیں۔",
      "postal_code": "ایک درست 5 ہندسوں کا پوسٹل کوڈ ہونا چاہیے۔",
      "field_required": "{{field}} ایک قدر ہونا ضروری ہے",
      "option_required": "آپ کو اختیارات میں سے ایک کا انتخاب کرنا ہوگا۔",
      "year_length_invalid": "تاریخ پیدائش چار ہندسوں والے سال کے ساتھ درج کریں(س س س س)",
      "invalid_day": "دن 1 سے 31 کے درمیان ہونا چاہیے۔",
      "reached_max_chars": "آپ اپنے کیریکٹر ز کی زیادہسے زیادہ حد تک پہنچ گئے ہیں",
      "chars_max": "زیادہ سے زیادہ کیرکٹرز",
      "minimum_length": "ان پٹ کم از کم {{length}} حروف طویل ہونی چاہیے ۔"
    },
    "insurance_status": {
      "question": "کیا آپ کے پاس ہیلتھ انشورنس ہے؟",
      "have_health_insurance": "ہاں ، میرے پاس ہیلتھ انشورنس ہے۔",
      "do_not_have_health_insurance": "نہیں ، میرے پاس ہیلتھ انشورنس نہیں ہے۔"
    },
    "insurance_policy_holder": {
      "question": "پالیسی ہولڈر کون ہے؟",
      "i_am": "میں ہوں",
      "my_spouse": "میرا شریک حیات یا شریک حیات۔",
      "my_parents": "میرے والدین",
      "someone_else": "کوئی اور",
      "policy_first_name": "پالیسی ہولڈر کا پہلا نام۔",
      "policy_last_name": "پالیسی ہولڈر کا آخری نام۔",
      "policy_dob": "پالیسی ہولڈر کی تاریخ پیدائش۔",
      "name": "پالیسی ہولڈر کا نام"
    },
    "insurance_information": {
      "title": "انشورنس کی معلومات۔",
      "company_name": "انشورنس کمپنی کا نام۔",
      "id_number": "ممبر شناختی نمبر",
      "group_number": "ممبر گروپ نمبر۔",
      "secondary_insurance_label": "میرے پاس ثانوی بیمہ ہے۔",
      "take_photo": "براہ کرم اپنے انشورنس کارڈ کی تصویر لیں ۔ تصویر لینے سے کچھ معلومات خود بخود پُر ہو جائیں گی ۔",
      "front_of_card": "کارڈ کے سامنے",
      "card_information": "انشورنس کارڈ کی معلومات",
      "back_of_card": "کارڈ کا پچھلا حصہ"
    },
    "employment_information": {
      "label": "روزگار",
      "address_1": "کمپنی ایڈریس",
      "address_2": "مثال کے طور پر سویٹ 200۔",
      "phone_number": "کمپنی کا فون نمبر۔",
      "company": "کمپنی کا نام۔",
      "postal_code": "آجر کا پوسٹل کوڈ۔",
      "employed": "ملازم",
      "sole_prorietor": "واحد مالک",
      "not_employed": "بے روزگار",
      "reporting_supervisor": "رپورٹنگ سپروائزر",
      "reporting_department": "رپورٹنگ ڈیپارٹمنٹ",
      "supervisor_name": "سپروائزر کا نام",
      "supervisor_email": "سپروائزر کا ای میل"
    },
    "location_availability": "فی الحال ان علاقوں میں رہنے والے کمیونٹی ممبروں کے لیے جانچ کو ترجیح دی جاتی ہے۔",
    "custom_survey": "سوالنامہ۔",
    "confirmation": "جائزہ لیں۔",
    "waitlisted": "انتظار کی فہرست",
    "schedule_your_appointment": "تقرری کا شیڈول۔",
    "information": "معلومات",
    "consent": "رضامندی",
    "location": "مقام",
    "symptoms": "علامات۔",
    "address_required": "براہ کرم اپنا پتہ درج کریں۔",
    "consent_required": "براہ کرم جاری رکھنے پر رضامندی دیں۔",
    "required_field": "مطلوبہ فیلڈ کی طرف اشارہ کرتا",
    "phone_number": "موبائل فون نمبر",
    "email": "ای میل۔",
    "date_of_birth": "پیدائش کی تاریخ",
    "minimum_age": "شرکت کے لیے کم از کم عمر {{year}}  سال ہے۔",
    "no_minimum_age": "جانچ کے لیے کوئی کم از کم عمر نہیں ہے۔",
    "continue_button": "جاری رہے",
    "email_required": "یہ قدر ایک درست ای میل ہونی چاہیے۔",
    "done": "ہو گیا",
    "signature": "دستخط",
    "clear_button": "صاف",
    "back_button": "پیچھے",
    "choose_location": "ایک مقام منتخب کریں۔",
    "no_slots": "٪ {location} پر مزید سلاٹس دستیاب نہیں ہیں",
    "choose_appointment": "٪ {location} پر ملاقات کا وقت منتخب کریں",
    "appointment_required": "براہ کرم ملاقات کا وقت منتخب کریں۔",
    "phone_number_required": "فون نمبر غلط ہے۔",
    "phone_number_label": "براہ کرم ایک نمبر درج کریں جو ٹیسٹ کے تیز نتائج کے لیے ٹیکسٹ پیغامات وصول کر سکے۔",
    "symptoms_experiencing": "براہ کرم ان علامات پر کلک کریں جن کا آپ فی الحال سامنا کر رہے ہیں۔ اگر آپ میں کوئی علامات نہیں ہیں تو ، براہ کرم جاری رکھیں۔",
    "household": "گھریلو ممبران۔",
    "household_p1": "اختیاری طور پر ، گھریلو ممبروں کے لیے ملاقات کا وقت بنائیں جنہیں ٹیسٹ کروانے کی ضرورت ہے۔",
    "add_dependent": "گھریلو ممبر شامل کریں۔",
    "remove_dependent": "انحصار کو ہٹا دیں۔",
    "dependent_consents": "رضامندی",
    "submit": "مکمل",
    "add_waitlist": "انتظار کی فہرست میں شامل کریں۔",
    "address": "گھر کا پتہ",
    "address_1": "پتہ لائن 1",
    "address_2": "اپٹ/سویٹ #",
    "address_city": "شہر۔",
    "address_state": "حالت",
    "postal_code": "ڈاک کامخصوص نمبر",
    "race_ethnicity": "دوڑ",
    "gender": "صنفی شناخت",
    "self_described_gender": "خود بیان کردہ صنف۔",
    "interpreter": "ترجمان کی ضرورت ہے؟ اگر ہاں تو کون سی زبان؟",
    "consent_to_terms": "میں ان شرائط سے اتفاق کرتا ہوں۔",
    "reg_not_open": "رجسٹریشن نہیں کھولی گئی۔",
    "no_more_avail_spots": "آپ کا منتخب کردہ ٹائم سلاٹ اب دستیاب نہیں ہے۔ دوبارہ کوشش کریں.",
    "consent_helper": "دستخط کرنے کے لیے نیچے والے باکس میں اپنے ماؤس یا انگلی پر کلک کریں اور گھسیٹیں۔",
    "phone_number_unreachable_label": "لینڈ لائن؟",
    "select": "منتخب کریں۔",
    "test_surveys": "تقرری کے سوالات۔",
    "edit": "ترمیم",
    "continue_to_registration": "رجسٹریشن جاری رکھیں۔",
    "accounts": {
      "already_have_an_account": "پہلے سے ہی ایک اکاؤنٹ ہے؟",
      "log_in": "لاگ ان کریں",
      "sign_up": "سائن اپ",
      "sign_up_description": "براہ کرم اپنا اکاؤنٹ بنانے کے لیے اپنی معلومات درج کریں اور مستقبل کی رجسٹریشن پر وقت کی بچت کریں۔",
      "log_in_description": "براہ کرم اپنا ای میل اور پاس ورڈ درج کریں ، یا نیچے گوگل یا آؤٹ لک کے ذریعے سائن ان کریں۔",
      "sign_in_with_label": "٪ {provider} کے ساتھ سائن ان کریں",
      "password_must_match": "پاس ورڈز مماثل ہونا چاہیے",
      "password_uppercase": "آپ کے پاس ورڈ میں کم از کم (٪ s) بڑا حرف ہونا چاہیے۔",
      "password_lowercase": "آپ کے پاس ورڈ میں کم از کم (٪ s) چھوٹے حروف کا ہونا ضروری ہے۔",
      "password_number": "آپ کے پاس ورڈ میں کم از کم (٪ s) نمبر ہونا چاہیے۔",
      "password_special": "آپ کا پاس ورڈ کم از کم (٪ s) خاص حروف پر مشتمل ہونا چاہیے۔"
    },
    "password": "پاس ورڈ",
    "password_confirmation": "پاسورڈ کی تو ثیق",
    "consent_for": "{{name}}  کے لیے رضامندی",
    "book_one_time_appointment": "ایک وقتی تقرری بک کروائیں۔",
    "duplicate_users": {
      "exists": "آپ پہلے ہی رجسٹرڈ ہیں۔",
      "overlapping_email_and_phone_p1": "ہم نے آپ کے فون نمبر اور ای میل ایڈریس پر ایک پیغام بھیجا ہے۔",
      "p2": "اپنے پیغام کا استعمال کرتے ہوئے ، آپ اپنی ملاقات کو تبدیل کر سکتے ہیں یا ٹیسٹ کے نتائج داخل کر سکتے ہیں۔",
      "overlapping_phone_and_email_p3": "براہ کرم تفصیلات کے لیے اپنا ای میل یا فون چیک کریں۔",
      "overlapping_email_p3": "براہ کرم تفصیلات کے لیے اپنا ای میل چیک کریں۔",
      "overlapping_phone_p3": "براہ کرم تفصیلات کے لیے اپنا فون چیک کریں۔",
      "overlapping_phone_p1": "ہم نے آپ کے فون نمبر پر ایک پیغام بھیجا ہے۔",
      "overlapping_email_p1": "ہم نے آپ کے ای میل ایڈریس پر ایک پیغام بھیجا ہے۔",
      "p4": "اگر آپ کو لگتا ہے کہ یہ ایک غلطی ہے تو ، براہ کرم ہم سے support@primary.health پر رابطہ کریں۔",
      "overlapping_email_and_phone_p3": "براہ کرم تفصیلات کے لیے اپنا ای میل یا فون چیک کریں۔"
    },
    "duplicate_waitlist": {
      "exists": "آپ پہلے ہی انتظار کی فہرست میں ہیں۔",
      "overlapping_email_and_phone_p1": "ہم نے آپ کے فون نمبر اور ای میل ایڈریس پر ایک اور تصدیق بھیجی ہے۔",
      "p2": "اپنی تصدیق کا استعمال کرتے ہوئے ، آپ اضافی معلومات فراہم کرسکتے ہیں یا اپنے آپ کو ویٹ لسٹ سے نکال سکتے ہیں۔",
      "overlapping_phone_and_email_p3": "براہ کرم تفصیلات کے لیے اپنا ای میل یا فون چیک کریں۔",
      "overlapping_email_p3": "براہ کرم تفصیلات کے لیے اپنا ای میل چیک کریں۔",
      "overlapping_phone_p3": "براہ کرم تفصیلات کے لیے اپنا فون چیک کریں۔",
      "overlapping_email_p1": "ہم نے آپ کے ای میل پر ایک اور تصدیق بھیجی ہے۔"
    },
    "insist_guardian_consent": "کی جانب سے رضامندی فراہم کر رہا ہوں۔",
    "confirmation_section": {
      "title": "جائزہ لیں اور تصدیق کریں۔",
      "p1": "براہ کرم رجسٹریشن مکمل کرنے سے پہلے اپنی معلومات کا جائزہ لیں۔",
      "edit_information": "واپس جائیں اور ترمیم کریں۔"
    },
    "iemodal": {
      "title": "معاون براؤزر",
      "body": "رجسٹریشن فی الحال ان براؤزرز پر ممکن ہے۔"
    },
    "show_other_locations": "دیگر مقامات دکھانے کے لیے کلک کریں۔",
    "non_us_address": "یہ ایک بین الاقوامی خطاب ہے۔",
    "test_group_user_survey": "شرکاء کی اسکریننگ۔",
    "self_consent": "میں اپنے لیے رضامند ہوں۔",
    "address_country": "ملک",
    "appointment": "تقرری",
    "employee_id": "ملازم کی ID",
    "appointment_time": "ملاقات کا وقت",
    "appointment_location": "مقام",
    "phone_or_email": "فون نمبر یا ای میل",
    "no_self_consent": "میں اپنی رضامندی سے انکار کرتا ہوں",
    "no_insist_guardian_consent": "میں {{full_name}} کی جانب سے رضامندی سے انکار کرتا ہوں",
    "additional_consents_helpertext": "بعد میں اضافی رضامندی کی درخواست کی جا سکتی ہے",
    "minimum_age_with_months": "حصہ لینے کے لیے کم از کم عمر {{year}} سال اور {{month}} ماہ ہے۔",
    "assistive_technology": "معاون ٹکنالوجی",
    "covid_vaccine_y_n": "کیا آپ اس بکنگ کے ساتھ COVID-19 ویکسینیشن چاہتے ہیں؟",
    "received_covid_vaccine_y_n": "کیا آپ نے پہلے COVID-19 ویکسین حاصل کی ہے؟",
    "covid_vaccinations": "COVID-19 ویکسینیشن",
    "select_vaccine_dose": "براہ کرم ویکسین کی وہ خوراک منتخب کریں جو آپ وصول کرنا چاہتے ہیں",
    "demographic_info": "آبادیاتی معلومات",
    "additional_info": "اضافی معلومات",
    "self_described_race": "خود بیان کردہ نسل",
    "verify_contact_information": "تصدیق",
    "verify_contact": {
      "we_sent_you_a_code": "براہ مہربانی وہ کوڈ درج کیجئیے جو ہم نے {{contact}} پر بھیجا ہے",
      "confirmation_code": "تصدیقی کوڈ",
      "code_resent": {
        "email": "آپ کے ای میل ایڈریس پر ایک اور کوڈ بھیجا گیا تھا",
        "phone_number": "آپ کے فون پر ایک اور کوڈ بھیجا گیا تھا"
      },
      "did_not_receive_code": "کیا آپ کو تصدیقی کوڈ نہیں ملا؟",
      "verify_by": {
        "email": "اس کی بجائے ای میل کے ذریعے تصدیق کیجئیے",
        "phone_number": "اس کی بجائے فون نمبر کے ذریعے تصدیق کیجئیے"
      }
    },
    "preferred_method_of_communication": {
      "question": "مواصلات کا ترجیحی طریقہ",
      "helper_text": "ہم اپائنٹمنٹ کی اپ ڈیٹس کے لیے آپ سے رابطہ کریں گے",
      "phone_number": "فون (صرف SMS پیغام رسانی اور ڈیٹا کی شرحیں لاگو ہو سکتی ہیں)",
      "landline": "ہم اس وقت لینڈ لائنز کو سپورٹ نہیں کر سکتے ۔ اہم مواصلات کے لیے براہ کرم ایک ای میل فراہم کریں ۔",
      "verification_method": "آپ اپنا توثیقی کوڈ کیسے موصول کرنا چاہتے ہیں ؟",
      "verification_helper_text": "لاگ ان کرنے سے پہلے آپ کو اپنی شناخت کی توثیق کرنے کے لیے ایک کوڈ بھیجا جائے گا ۔"
    },
    "skip_for_now": "ابھی کے لیے نظر انداز کریں",
    "default_information": {
      "title": "ٹھیک ہے، ہم یہ پتہ استعمال کریں گے",
      "title_v2": "ٹھیک ہے، ہم اس رابطے کی معلومات کا استعمال کریں گے"
    },
    "middle_name_label": "درمیانہ نام",
    "confirm_appointment": "ملاقات کی تصدیق کریں",
    "dob": "تاریخ پیدائش",
    "different_appointment": "مختلف اپائنٹمنٹ",
    "select_appointment_time": "اپائنٹمنٹ کا وقت منتخب کریں",
    "decline_address": "میں پتہ فراہم کرنے سے انکار کرتا/کرتی ہوں ۔ براہ کرم اس کے بجائے پروگرام کا پتہ استعمال کریں ۔",
    "patient_information": "مریض کی معلومات",
    "scan_license": "ڈرائیونگ لائسنس اسکین کریں",
    "how_to_contact": "ہمیں آپ سے کیسے رابطہ کرنا چاہیے ؟",
    "decline_email_or_phone": "میں ای میل یا فون نمبر فراہم کرنے سے انکار کرتا/کرتی ہوں ۔ میں پروگرام کے منتظمین کو اپنے نتائج موصول کرنے کی اجازت دیتا/دیتی ہوں ۔",
    "medical_screening": "طبی جانچ",
    "show_service_at_this_location": "اس مقام پر خدمت دکھائیں",
    "verbal_consent_provided": "معذور امریکیوں کے قانون کے مطابق، شریک یا ان کے قانونی طور پر مجاز فیصلہ ساز نے زبانی رضامندی فراہم کی ۔ شرکاء یا ان کے قانونی طور پر مجاز فیصلہ ساز سے 24 گھنٹوں کے اندر تحریری رضامندی حاصل کی جائے گی ۔",
    "unexpired_written_consent_present": "ٹیسٹنگ سائٹ کے پاس غیر میعاد شدہ تحریری رضامندی موجود ہے۔",
    "select_to_proceed": "براہ کرم آگے بڑھنے کے لیے اوپر ایک آپشن منتخب کریں ۔",
    "guardians_information": "سرپرستوں کی معلومات",
    "registrars_information": "رجسٹرار کی معلومات",
    "optional": "اختیاری",
    "vfc_eligibility": "بچوں کے لیے ویکسین (VFC) کی اہلیت",
    "vfc_eligibility_subtext": "آپ کا بچہ CDC کے ویکسینز فار چلڈرن (VFC) پروگرام کے ذریعے صحت کی دیکھ بھال فراہم کرنے والے دفاتر، فارمیسیوں اور ہیلتھ کلینکس میں مفت ویکسین حاصل کرنے کے قابل ہو سکتا ہے جو VFC پروگرام میں اندراج شدہ ہیں ۔"
  },
  "gender_key": {
    "male": "مرد",
    "female": "عورت",
    "cisgender": "سسجینڈر",
    "non_binary": "صنفی یا غیر ثنائی۔",
    "other": "دیگر",
    "prefer_to_self_describe": "اندراج درج نہیں ہے۔",
    "prefer_not_to_disclose": "جواب دینے سے انکار۔",
    "transgender_male": "ٹرانس مرد/ ٹرانس مین",
    "transgender_female": "ٹرانس خاتون/ ٹرانس وومن۔",
    "unknown": "نامعلوم",
    "non_binary_only": "غیر-بائنری",
    "intersex": "بین صنفی",
    "transgender": "مخنث",
    "gender_fluid": "صنفی سیال",
    "not_applicable": "قابل اطلاق نہیں"
  },
  "ethnicity": {
    "american_indian_alaska_native": "امریکی ہندوستانی یا الاسکا کا باشندہ۔",
    "american_indian_central_america": "جنوبی یا وسطی امریکہ سے امریکی ہندوستانی۔",
    "asian": "ایشیائی",
    "black": "سیاہ فام یا افریقی امریکی۔",
    "latinx": "لاطینی ، لاطینی یا ہسپانوی۔",
    "middle_eastern_north_african": "مشرق وسطی یا شمالی افریقی۔",
    "native_hawaiian_pacific_islander": "مقامی ہوائی یا پیسیفک آئلینڈر۔",
    "white_european": "سفید یا کاکیشین۔",
    "unknown": "نامعلوم",
    "prefer_not_to_disclose": "ظاہر نہ کرنے کو ترجیح دیں۔",
    "asian_indian": "ایشین انڈین۔",
    "filipino": "فلپائنی",
    "japanese": "جاپانی",
    "korean": "کورین",
    "vietnamese": "ویتنامی",
    "other_asian": "دیگر ایشیائی۔",
    "native_hawaiian": "مقامی ہوائی۔",
    "guamanian_or_chamorro": "گامانی یا چمورو۔",
    "samoan": "ساموانی",
    "other_pacific_islander": "دیگر بحر الکاہل کا جزیرہ۔",
    "chinese": "چینی",
    "help": "ریس سے مراد جسمانی خصوصیات کا ایک مجموعہ ہے جو لوگوں کے گروہوں میں فرق کرنے کے لیے استعمال ہوتا ہے۔ ریس قومیت ، یا جس ملک میں آپ کی شہریت ہے اس کے ساتھ ہم آہنگی ہو سکتی ہے یا نہیں۔",
    "subtitle": "ضابطوں کا تقاضا ہے کہ ہم مندرجہ ذیل تمام معلومات اکٹھا کریں۔",
    "laotian": "لاؤشین",
    "cambodian": "کمبوڈین",
    "other": "دیگر",
    "bangladeshi": "بنگلہ دیشی",
    "hmong": "ہمونگ",
    "indonesian": "انڈونیشیائی",
    "malaysian": "ملیشیائی",
    "pakistani": "پاکستانی",
    "sri_lankan": "سری لنکن",
    "thai": "تھائی",
    "taiwanese": "تائیوانی",
    "fijian": "فجی",
    "guamanian": "گوامی",
    "tongan": "ٹونگن",
    "bahamian": "بہامیائی"
  },
  "languages": {
    "en": "انگریزی",
    "fr": "فرانسیسی۔",
    "es": "اسپینول۔",
    "so": "سومالی۔",
    "hmn": "ہمونگ۔",
    "asl": "امریکی سائن لینگویج (ASL)",
    "kar": "کیرن۔",
    "am": "امہاری",
    "ru": "۔",
    "om": "اورومو۔"
  },
  "symptoms": {
    "fever": "بخار یا سردی لگ رہی ہے۔",
    "cough": "کھانسی",
    "muscle_aches": "پٹھوں میں درد",
    "severe_fatigue": "شدید تھکاوٹ (معمول سے زیادہ)",
    "trouble_breathing": "سانس لینے میں دشواری۔",
    "diarrhea": "اسہال۔",
    "loss_of_smell": "بو کی کمی۔",
    "loss_of_taste": "ذائقہ کا نقصان۔",
    "shortness_of_breath": "سانس کی قلت یا سانس لینے میں دشواری۔",
    "headache": "سر درد",
    "sore_throat": "گلے کی سوزش",
    "congestion": "بھیڑ یا ناک بہنا۔",
    "nausea": "متلی یا قے۔",
    "close_contact": "متاثرہ شخص کے ساتھ قریبی رابطہ*",
    "helper_text": {
      "close_contact": "*کیا آپ نے کسی ایسے شخص سے قریبی رابطہ کیا ہے (کم از کم 15 منٹ تک 6 فٹ کے اندر) جس نے COVID-19 کی تصدیق کی ہے؟"
    },
    "suspected_exposure": "مشتبہ نمائش۔",
    "none": "کوئی علامات نہیں۔"
  },
  "instructions": {
    "title": "آپ اندراج شدہ ہیں لیکن جانے سے پہلے ...",
    "p1": "اس صفحے میں آپ کی تقرری کے ساتھ ساتھ آپ کی ملاقات کا بار کوڈ بھی شامل ہے۔",
    "appointment_scheduled": "آپ کی ملاقات طے شدہ ہے۔",
    "verify_info": {
      "title": "اپنی معلومات کی تصدیق کریں۔",
      "p1": "اپنی رابطہ کی معلومات کی توثیق کرنا ضروری ہے تاکہ آپ اپنے مریض کے پورٹل تک جلدی رسائی حاصل کر سکیں۔",
      "p2": "ایک تصدیق آپ کے ای میل پر بھیج دی گئی ہے۔ براہ مہربانی فراہم کردہ لنک پر کلک کریں۔",
      "p3": "اگر آپ کو اپنی رابطہ کی معلومات تبدیل کرنے کی ضرورت ہے تو ، براہ کرم مدد کا بٹن دبائیں۔"
    },
    "verified": "تصدیق شدہ",
    "not_verified": "غیر تصدیق شدہ",
    "resend_text": "لنک نہیں ملا؟",
    "resend": "دوبارہ بھیجیں",
    "skip": "توثیق کو چھوڑیں اور تصدیق دکھائیں۔",
    "name": "نام۔",
    "appointment_details": "تقرری",
    "date_and_time": "تاریخ اور وقت",
    "add_to_calendar": "کیلنڈر میں شامل کریں۔",
    "instructions": "عمومی ہدایات۔",
    "successfully_flash": "تقرری کامیابی کے ساتھ بنائی گئی!",
    "success_flash_email": "کامیابی سے ای میل کی تصدیق کی گئی۔",
    "success_flash_phone": "فون نمبر کی کامیابی سے تصدیق ہو گئی۔",
    "mail_order": "آپ کو ہفتے کے اندر میل میں اپنی ٹیسٹ کٹ مل جائے گی۔",
    "at_home": "ایک طبی فراہم کنندہ اس وقت آپ کے گھر آئے گا۔",
    "at_home_instructions": "گھریلو ہدایات پر۔",
    "mail_order_instructions": "میل آرڈر ہدایات۔",
    "request_additional_appointment": "فالو اپ اپائنٹمنٹ بک کروائیں۔",
    "book_additional_appointment": "ایک اور اپائنٹمنٹ بک کروائیں۔",
    "confirmation_code": "تصدیقی کوڈ",
    "completed": "مکمل",
    "appointment_barcode": "تقرری بارکوڈ۔",
    "dependent_links": "گھریلو تقرری کے لنکس۔",
    "on_demand_title": "آپ اندراج شدہ ہیں لیکن جانے سے پہلے ...",
    "save_link": "اس لنک کو محفوظ کریں۔",
    "verify_phone": "اپنے فون نمبر کی تصدیق کریں۔",
    "verify_phone_description": "اپنی رابطہ کی معلومات کی توثیق کرنا ضروری ہے تاکہ آپ اپنے ریکارڈ تک جلدی رسائی حاصل کر سکیں۔ آپ کے نمبر پر ایک توثیقی ٹیکسٹ پیغام بھیجا گیا ہے۔",
    "verify_email_description": "اپنی رابطہ کی معلومات کی توثیق کرنا ضروری ہے تاکہ آپ اپنے ریکارڈ تک جلدی رسائی حاصل کر سکیں۔ ایک تصدیق ای میل آپ کے ای میل پتے پر بھیج دی گئی ہے۔",
    "information": "رجسٹریشن کی معلومات۔",
    "follow_up_appointment": "تقرری کی پیروی کریں۔",
    "get_directions": "ہدایات حاصل کریں۔",
    "cancel": "تقرری منسوخ کریں۔",
    "reschedule_appointment": "تقرری کو دوبارہ شیڈول کریں۔",
    "reschedule_linked_appointments": "تقرریوں کو دوبارہ شیڈول کریں۔",
    "no_slots": "مزید اوقات دستیاب نہیں۔",
    "check_results": "مریض پورٹل چیک کریں۔",
    "follow_up_modal_header": "براہ کرم اپنی دوسری خوراک کا شیڈول بنائیں۔",
    "are_you_sure_you_want_to_cancel": "کیا آپ واقعی اس ملاقات کو منسوخ کرنا چاہتے ہیں؟",
    "please_choose_cancellation_reason": "براہ کرم ذیل میں منسوخی کی وجہ منتخب کریں۔",
    "additional_details": "براہ کرم ذیل میں کوئی اضافی تفصیلات شامل کریں۔",
    "errors": {
      "missing_cancellation_reason": "براہ کرم منسوخی کی وجہ منتخب کریں۔"
    },
    "verify_email": "اپنے ای میل کی تصدیق کریں۔",
    "redcap_url": "اپنا سروے مکمل کرنے کے لیے کلک کریں۔",
    "verify_contact_information": "اپنی رابطہ کی معلومات کی تصدیق کریں۔",
    "please_also": "برائے مہربانی اس کے علاوہ",
    "new_title": "توثیق برائے {{name}}",
    "contact_method": "رابطہ کا طریقہ",
    "next_steps": {
      "title": "اگلے اقدامات",
      "p1": "آپ کے پاس فعال کرنے کے لیے ایک ٹیسٹ کٹ ہے ۔ جب آپ اپنے ٹیسٹ کے لیے تیار ہوں، تو اپنے ای میل پر بھیجی گئی لنک پر کلک کریں تاکہ اس صفحے پر واپس آ سکیں اور نیچے اپنی کٹ کو فعال کریں۔",
      "administered": "ہم آپ سے رابطہ کریں گے جب آپ کی کٹ لیب میں پہنچ جائے گی اور جب آپ کے نتائج تیار ہوں گے۔ نتائج عام طور پر لیب میں پہنچنے کے بعد 3 سے 5 دن لیتے ہیں۔ جب آپ تیار ہوں تو آپ اپنی [طبی تاریخ] ({{link}}) صفحہ سے نتائج دیکھ سکتے ہیں جب یہ تیار ہوں ۔",
      "resulted": "نتائج آپ کے [میڈیکل ہسٹری] ({{link}}) کے صفحے پر دستیاب ہیں ۔",
      "shipping_information": "ترسیل کی معلومات"
    },
    "save_this_page": {
      "title": "یہ صفحہ محفوظ کریں",
      "p1": "خود ٹیسٹ کی رپورٹ کرنے یا طبی سرگزشت کے ذریعے نتائج دیکھنے کے لیے یہ صفحہ استعمال کریں۔",
      "p2": "مستقبل میں اس صفحے تک رسائی حاصل کرنے کے لیے اپنے تصدیقی ای میل یا SMS ٹیکسٹ میں موجود لنک پر کلک کریں ۔",
      "p3": "آپ اس صفحے کو بک مارک کر کے، اسے ہوم اسکرین پر شامل کر کے یا لنک کو کسی محفوظ جگہ پر کاپی کر کے محفوظ کر سکتے ہیں ۔"
    },
    "show_my_barcode": "میرا بار کوڈ دکھائیں",
    "my_account": "میرا اکاؤنٹ",
    "register_another": "کسی دوسرے شریک کو رجسٹر کریں",
    "update_vaccine": "ویکسین کی معلومات اپ ڈیٹ کریں",
    "medical_history": "طبی تاریخ",
    "upcoming_appointments": "آنے والی اپائنٹمنٹس",
    "reschedule": "دوبارہ شیڈول کریں",
    "resend_confirmation": "تصدیق دوبارہ بھیجیں",
    "appointment_details_v2": "اپائنٹمنٹ کی تفصیلات",
    "confirm_cancellation": "منسوخی کی توثیق کریں",
    "confirm_cancellation_question": "کیا آپ واقعی اپنی اپائنٹمنٹ منسوخ کرنا چاہتے ہیں؟",
    "select_new_appointment_time_below": "ذیل میں ایک نیا اپائنٹمنٹ کا وقت منتخب کریں ۔ اگر آپ کو خدمات یا اپائنٹمنٹ کے مقام میں ترمیم کرنے کی ضرورت ہے تو، براہ کرم اس اپائنٹمنٹ کو منسوخ کریں اور ایک نیا شیڈول کریں ۔",
    "no_take_me_back": "نہیں، مجھے واپس لے جاؤ",
    "yes_cancel_appointment": "ہاں، اپائنٹمنٹ منسوخ کریں",
    "update_appointment": "اپائنٹمنٹ اپ ڈیٹ کریں",
    "select_new_appointment_time": "اپائنٹمنٹ کا وقت منتخب کریں",
    "clinic": "کلینک",
    "services": "خدمات",
    "appointment_missed": "اپائنٹمنٹ چھوٹ گئی",
    "appointment_canceled": "اپائنٹمنٹ منسوخ ہو گئی",
    "screening_complete_for": "Screening complete for {{name}}"
  },
  "result_page": {
    "errors": {
      "invalid_access_code": "غلط رسائی کوڈ۔"
    },
    "landing": {
      "p1": "اپنے نتائج دیکھنے کے لیے ، درج کریں:",
      "access_code_label": "رسائی کوڈ",
      "submit_button": "جمع کرائیں",
      "recent_results": "آپ کے حالیہ نتائج۔"
    },
    "view_results": {
      "headline": "{{name}} کے ٹیسٹ کے نتائج",
      "reregister": "دوبارہ رجسٹر کریں۔",
      "status": {
        "likely_positive": "نتائج غیر یقینی",
        "test_not_performed": "دوبارہ جانچ کی ضرورت ہے۔",
        "results_ready": "نتائج تیار ہیں۔",
        "processing": "پروسیسنگ",
        "test_status": "ٹیسٹ کی حیثیت۔",
        "test_result": "ٹیسٹ کا نتیجہ۔",
        "administered": "زیر انتظام",
        "results_pending": "زیر التواء نتائج",
        "test_results": "ٹیسٹ کے نتائج"
      },
      "at": "{{address}}"
    },
    "result_label": "نتیجہ",
    "result": {
      "flu_a": {
        "result_label": "فلو اے کا نتیجہ"
      },
      "flu_b": {
        "result_label": "فلو بی کا نتیجہ"
      },
      "covid19": {
        "result_label": "COVID-19 کا نتیجہ"
      },
      "covid": {
        "result_label": "COVID-19 کا نتیجہ"
      },
      "sars": {
        "result_label": "سارس کا نتیجہ"
      },
      "control": {
        "result_label": "اختیار"
      },
      "rsv": {
        "result_label": "RSV نتیجہ"
      },
      "result": {
        "result_label": "نتیجہ"
      },
      "hba1c": {
        "normal": "حسب معمول",
        "warning": "پری ذیابیطس",
        "danger": "ذیابیطس"
      },
      "lead_venous": {
        "danger": "غیر معمولی",
        "normal": "حسب معمول"
      },
      "tc": {
        "result_label": "ٹی سی نتیجہ"
      },
      "hdl": {
        "result_label": "ایچ ڈی ایل نتیجہ"
      },
      "trg": {
        "result_label": "ٹی آر جی نتیجہ"
      },
      "ldl": {
        "result_label": "ایل ڈی ایل کا نتیجہ"
      },
      "non_hdl": {
        "result_label": "غیر ایچ ڈی ایل نتیجہ"
      },
      "tc_hdl_ratio": {
        "result_label": "ٹی سی/ایچ ڈی ایل تناسب"
      },
      "glu": {
        "result_label": "جی ایل یو نتیجہ"
      },
      "alere_cholestech_ldx": {
        "danger": "خطرے میں",
        "warning": "غیر معمولی",
        "normal": "حسب معمول"
      },
      "lead": {
        "result_label": "ليڈ"
      },
      "zinc": {
        "result_label": "زنک"
      },
      "lead_standard_profile": {
        "danger": "غیر معمولی",
        "normal": "حسب معمول"
      },
      "creatinine": {
        "danger": "غیر معمولی",
        "normal": "حسب معمول"
      },
      "igg": {
        "result_label": "IgG نتیجہ"
      },
      "igm": {
        "result_label": "IgM نتیجہ"
      },
      "blood_glucose_fasted": {
        "normal": "نمائندہ",
        "warning": "उचچ",
        "danger": "उचچ",
        "hypoglycemia": "ہائپوگلیسیمیا",
        "prediabetes": "قبل از ذیابیطس",
        "diabetes": "ذیابیطس"
      },
      "total_cholesterol_fasted": {
        "normal": "نمائندہ",
        "elevated": "उचچ",
        "high": "उचچ",
        "low": "کم",
        "abnormal": "غیر معمولی"
      },
      "total_cholesterol_unfasted": {
        "normal": "نمائندہ",
        "elevated": "उचچ",
        "high": "उचچ"
      },
      "a1c_now": {
        "normal": "نمائندہ",
        "warning": "پریڈائیبیٹیز",
        "danger": "ذیابیطس"
      },
      "blood_glucose": {
        "warning": "کم",
        "normal": "معمول",
        "prediabetes": "پیش ذیابیطس",
        "danger": "ذیابیطس",
        "hypoglycemia": "ہائپوگلیسیمیا",
        "medical_emergency": "طبی ایمرجنسی",
        "inconclusive_1": "غیر یقینی 1",
        "inconclusive_2": "غیر یقینی 2",
        "possible_diabetes": "ممکنہ ذیابیطس"
      },
      "triglycerides": {
        "result_label": "ٹریگلیسرائڈز"
      },
      "blood_glucose_ucsf": {
        "low": "کم",
        "normal": "معمول",
        "high": "زیادہ"
      },
      "syphilis": {
        "result_label": "سفلس کا نتیجہ"
      },
      "hepatitis_c": {
        "result_label": "ہیپاٹائٹس سی کا نتیجہ"
      },
      "hiv": {
        "result_label": "ایچ آئی وی کا نتیجہ"
      },
      "rapid_hiv": {
        "positive": "رد عمل (ابتدائی مثبت)",
        "negative": "غیر رد عمل (منفی)",
        "did_not_result": "غیر موزوں (غیر معینہ)"
      },
      "rapid_hcv": {
        "positive": "رد عمل (ابتدائی مثبت)",
        "negative": "غیر رد عمل (منفی)",
        "did_not_result": "غیر موزوں (غیر معینہ)"
      },
      "rapid_syphilis": {
        "positive": "رد عمل (ابتدائی مثبت)",
        "negative": "غیر رد عمل (منفی)",
        "did_not_result": "غیر موزوں (غیر معینہ)"
      }
    },
    "documents": "دستاویزات",
    "self_administered": "{{location}} سے منتخب کردہ خود انتظام کردہ ٹیسٹ",
    "patient": "مریض",
    "medical_history": "طبی تاریخ",
    "overview_title": "مزید تفصیلات اور درکار کسی بھی اضافی اقدامات کو دیکھنے کے لیے ایک ٹیسٹ یا خدمت منتخب کریں۔ نوٹ کریں کہ کچھ ٹیسٹ کے نتائج ابھی دستیاب نہیں ہوسکتے ہیں یا صرف فراہم کنندہ سے بات کرنے کے بعد ہی دستیاب ہوں گے ۔",
    "insurance_information": "انشورنس کی معلومات پُر کریں",
    "contact_support": "اگر آپ کو مدد کی ضرورت ہو تو براہ کرم سپورٹ سے رابطہ کریں ۔",
    "show": "دکھائیں",
    "hide": "چھپائیں",
    "lab_report": "لیب کی رپورٹ",
    "contact_provider": {
      "header": "آپ کے مثبت نتائج ہیں",
      "description": "کیا آپ اپنے نتائج اور علاج پر تبادلہ خیال کرنے کے لئے کسی فراہم کنندہ سے بات کرنا چاہتے ہیں ؟",
      "yes_option_text": "ہاں، میں کسی فراہم کنندہ سے بات کرنا چاہتا ہوں",
      "no_option_text": "نہیں، میں نے اپنے نتائج پڑھ اور سمجھ لیے ہیں، اور کسی فراہم کنندہ سے بات نہیں کرنا چاہتا",
      "confirm_phone_number_header": "اپنے فون نمبر کی توثیق کریں",
      "confirm_phone_number_description": "براہ کرم اپنی مشاورت کے لیے بہترین فون نمبر کی توثیق کریں ۔",
      "confirm_button_text": "تصدیق کریں",
      "consultation_confirmed_header": "مشاورت کی تصدیق ہوگئی",
      "consultation_confirmed_description": "ایک فراہم کنندہ آپ کو 2-3 کاروباری دنوں {{phone_number}} کے اندر کال کرے گا ۔",
      "acknowledgement_option_helper_text": "کوئی ایسی چیز جو علاج کی اہمیت کی وضاحت کرتی ہے اور صارف کو یہ جاننے دیتی ہے کہ اگر وہ اپنا ارادہ بدل لیتے ہیں تو کال کو شیڈول کیسے کریں ۔",
      "acknowledgement_confirmed_header": "اعتراف کی توثیق ہو گئی",
      "acknowledgement_confirmed_description": "منسلک وسائل کے ساتھ علاج کروانے کی اہمیت کے بارے میں کچھ ۔ یاد دلائیں کہ مشاورت مکمل طور پر مفت ہے اور فراہم کنندہ انفیکشن کو حل کرنے میں ان کی مدد کے لئے کچھ اور تجویز کر سکتا ہے ۔",
      "acknowledgement_confirmed_change_mind_text": "اگر آپ اپنا ارادہ تبدیل کرتے ہیں تو بس ذیل میں \"مجھے مشاورت چاہیے\" پر کلک کریں ۔",
      "request_consultation_button_text": "میں مشاورت کرنا چاہوں گا"
    },
    "phone": "فون",
    "source": "Source"
  },
  "required": {
    "errors": {
      "address_not_in_bounding_box": "آپ کا پتہ اس پروگرام میں موجود سائٹوں کے لیے اہلیت کے تقاضوں سے مماثل نہیں ہے۔"
    }
  },
  "member": {
    "medical_history": "تاریخ",
    "view_instructions": "تصدیق دیکھیں۔",
    "next_appointment": "اگلی ملاقات۔",
    "over_18": "میں تصدیق کرتا ہوں کہ میری عمر 18 سال یا اس سے زیادہ ہے۔",
    "choose_test_configurations_title": "برائے مہربانی اس سروس کو منتخب کریں جو آپ اس ملاقات کے لیے چاہتے ہیں۔",
    "member_taken_error": "صارف کا پہلے ہی ایک اکاؤنٹ ہے۔",
    "choose_test_configurations_subtitle": "گھر کے تمام ممبران پر سروسز لاگو ہوں گی۔",
    "service": "خدمت",
    "group_or_location": "گروپ/مقام",
    "details": "تفصیلات",
    "see_all": "سب دیکھیں",
    "no_history": "اس وقت پچھلا مواد دستیاب نہیں ہے۔",
    "medical_history_title_with_name": "{{name}} کی تاریخ",
    "no_dashboard": "فی الحال کچھ بھی دستیاب نہیں ہے۔",
    "product": "مصنوعہ",
    "price": "قیمت",
    "quantity": "مقدار",
    "total_services_selected": "کُل منتخب کردہ خدمات",
    "total_price": "کل قیمت"
  },
  "or": "یا",
  "account": {
    "errors": {
      "must_be_13": "اکاؤنٹ کے لیے رجسٹر ہونے کے لیے 13 سال کا ہونا ضروری ہے۔"
    }
  },
  "self_administration": {
    "title": "سیلف ایڈمنسٹریشن۔",
    "self_administer_action": "سیلف ایڈمنسٹریٹر۔",
    "skip_to_link": "خود انتظامیہ پر جائیں۔",
    "select_person": "چیک کرنے کے لیے ایک شخص منتخب کریں۔",
    "adult": "بالغ",
    "child": "بچہ",
    "checked_out": "چیک آؤٹ",
    "go_back": "واپس جاو",
    "switch_to_scanner": "اسکینر پر سوئچ کریں۔",
    "enter_barcode": "بار کوڈ درج کریں۔",
    "scan_barcode": "بارکوڈ اسکین کریں۔",
    "cancel": "منسوخ کریں۔",
    "barcode_for": "کے لیے بارکوڈ",
    "enter_barcode_manually": "بار کوڈ دستی طور پر درج کریں۔",
    "instructions": "ذیل میں اپنی ٹیسٹ ٹیوب پر موجود بار کوڈ کو اسکین کریں یا درج کریں۔",
    "regex_prefix": "بارکوڈ پر مشتمل ہونا چاہیے۔",
    "all_completed": "مکمل: گھر کے تمام افراد نے یہ مرحلہ مکمل کر لیا ہے۔",
    "errors": {
      "no_appointment": "ملاقات کا پتہ لگانے میں دشواری",
      "barcode_in_use": "یہ بارکوڈ پہلے ہی استعمال ہو چکا ہے۔ اگر آپ نے پہلے اس کٹ کے ساتھ خود انتظام نہیں کیا تھا تو براہ کرم سائٹ پر موجود عملے سے بات کریں جس نے آپ کو کٹ دی ہے اور ایک نئی کٹ طلب کریں۔ بصورت دیگر، ذیل میں مدد کے بٹن پر کلک کریں۔",
      "test_error_general": "ٹیسٹ بنانے میں دشواری",
      "different_barcodes": "آپ کے درج کردہ بارکوڈز مماثل نہیں ہیں۔"
    },
    "confirm_barcode_input": "تصدیق کے لیے دوبارہ بار کوڈ درج کریں۔",
    "click_to_self_test": "خود ٹیسٹ کرنے کے لیے یہاں کلک کریں۔",
    "or_enter_barcode_manually": "Or enter manually with all letters and numbers"
  },
  "location_details": {
    "next_appointment": {
      "waitlist": "انتظار کی فہرست",
      "unavailable": "کوئی تقرری دستیاب نہیں ہے۔"
    },
    "labels": {
      "address": "پتہ۔",
      "next_appointment": "اگلی دستیاب ملاقات۔",
      "services": "خدمات دستیاب ہیں۔"
    }
  },
  "waiting_room": {
    "header": "آپ انتظار گاہ میں ہیں براہ کرم انتظار کریں جب تک ہم آپ کو شیڈولنگ سسٹم سے جوڑیں۔",
    "p1": "برائے مہربانی صبر کریں۔ ہم مل کر اس وبائی مرض کا خاتمہ کریں گے۔",
    "signature": "مخلص",
    "p2": "ہم جانتے ہیں کہ COVID-19 کا تجربہ مایوس کن ہوسکتا ہے۔ برائے مہربانی یہاں انتظار کریں جب تک کہ ہم آپ سے اپائنٹمنٹ بک کروانے کے لیے جتنی تیزی سے کام کر سکتے ہیں کریں۔"
  },
  "users": {
    "send_appointment_confirmation_message": "ہیلو٪ {full_name}۔ یہ٪ {name} پر آپ کی ملاقات کے لیے ایک یاد دہانی ہے۔ آپ کی ملاقات٪ {date} کو٪ {time} پر٪ {address} پر ہے۔",
    "send_registration_link": "ہیلو ،٪ {full_name}۔ اپنے٪ {name}٪ {registration_link} کے لیے رجسٹر کرنے کے لیے اس لنک پر عمل کریں"
  },
  "follow_up": {
    "first_dose": "پہلی خوراک۔",
    "previous_appointment": "ماضی کی تقرری",
    "booked_appointment": "آپ کی تقرری",
    "second_dose": "دوسری خوراک۔",
    "choose_second_location": "دوسری ملاقات کا مقام منتخب کریں۔"
  },
  "cancellation_types": {
    "cant_get_to_location": "میرے پاس وقت کا تنازعہ تھا۔",
    "timing_conflict": "میں COVID-19 سے بیمار ہوگیا۔",
    "became_ill_with_covid19": "مجھے یہ سروس کہیں اور ملی ہے۔",
    "received_service_elsewhere": "دیگر",
    "other": "اس کے بجائے نام ٹائپ کریں۔",
    "duplicate_appointment": "یہ ایک نقلی ملاقات تھی۔"
  },
  "translation": {
    "consent": {
      "type_name_instead": "نام ٹائپ کریں۔",
      "type_name": "اس کے بجائے دستخط ٹائپ کریں"
    }
  },
  "email": {
    "confirmation": {
      "your_access_code_is": "آپ کا رسائی کوڈ ہے۔",
      "please_confirm_your_email": "براہ کرم نیچے دیئے گئے بٹن پر کلک کرکے اپنے ای میل کی تصدیق کریں۔",
      "hello": "ہیلو",
      "click_to_reschedule": "ہدایات/ری شیڈول دکھانے کے لیے کلک کریں۔",
      "click_to_reschedule_on_demand": "ہدایات دکھانے کے لیے کلک کریں۔"
    },
    "cancellation": {
      "your_appointment_has_been_canceled": "آپ کی اپائنٹمنٹ {{time}} پر{{location}}کومنسوخ کردی گئی ہے ۔ اگر یہ ایک غلطی ہے تو، براہ کرم support@primary.health پر ای میل کریں"
    }
  },
  "signature_lines": {
    "name": "حصہ لینے والے کا نام۔",
    "date": "تاریخ",
    "signature": "شرکاء کے دستخط۔",
    "and_or": "اور/یا",
    "guardian_name": "والدین/سرپرست کا نام۔",
    "guardian_signature": "والدین/سرپرست کے دستخط"
  },
  "employer_testing": {
    "hello_name": "ہیلو٪ {name}",
    "results": {
      "negative": "منفی۔",
      "positive": "مثبت۔",
      "did_not_result": "نامعلوم",
      "unknown": "نامعلوم",
      "invalid": "غلط",
      "pending": "زیر التواء"
    },
    "code_reader": {
      "scan_your_code": "اپنے ٹیسٹ کارڈ پر کوڈ اسکین کریں۔",
      "find_in_top_right_hand_corner": "اسکین کوڈ آپ کے ٹیسٹ کے لیے منفرد ہے اور آپ کے ٹیسٹ کے اوپری دائیں کونے پر پایا جا سکتا ہے۔",
      "center_code": "کوڈ کو یہاں مرکز کریں۔",
      "already_used_error": "یہ ٹیسٹ بار کوڈ پہلے ہی استعمال ہو چکا ہے۔ برائے مہربانی سپورٹ کے لیے اپنے ٹیسٹ کٹ فراہم کنندہ سے رابطہ کریں۔",
      "click_to_scan": "کیو آر کوڈ کو اسکین کرنے کے لیے کلک کریں۔",
      "scan_new_test_card": "نیا ٹیسٹ کارڈ اسکین کرنے کے لیے یہاں کلک کریں۔",
      "not_working": "کام نہیں کررہا؟",
      "try_again": "دوبارہ کوشش کرنے کے لیے یہاں کلک کریں۔"
    },
    "continue": "جاری رہے",
    "verify_date_of_birth": {
      "confirm_date_of_birth": "براہ کرم جاری رکھنے کے لیے شریک کی تاریخ پیدائش کی تصدیق کریں۔",
      "contact_administrator": "اگر دکھائی گئی معلومات غلط ہے تو برائے مہربانی مدد کے لیے کسی منتظم سے رابطہ کریں۔",
      "error": "درج کردہ تاریخ پیدائش غلط ہے - براہ کرم دوبارہ کوشش کریں یا اپنی سائٹ ایڈمنسٹریٹر سے رابطہ کریں تاکہ یہ اپ ڈیٹ ہو۔"
    },
    "result_entry": {
      "code_is_registered": "آپ کا کوڈ رجسٹرڈ ہے۔",
      "take_the_test": "ٹیسٹ لیں۔",
      "follow_the_instructions": "اپنے ٹیسٹ کے ساتھ آنے والی ہدایات پر عمل کریں اور ٹیسٹنگ کارڈ پر شیشی میں ناک کے جھاڑو داخل کرنے کے بعد 15 منٹ کا ٹائمر شروع کریں۔",
      "cant_find_instructions": "ہدایات نہیں مل سکتیں؟",
      "view_instructions": "Binax Now ہدایات دیکھیں۔",
      "start_timer": "15 منٹ ٹائمر شروع کریں (اختیاری)",
      "submit_within_15": "ٹپ: 15 منٹ کے اندر اپنے نتائج جمع کروائیں۔",
      "enter_test_results": "ٹیسٹ کے نتائج درج کریں۔",
      "choose_a_result": "وہ آپشن منتخب کریں جو آپ کے ٹیسٹنگ کارڈ کے نتائج کو بیان کرے۔",
      "submit": "جمع کرائیں",
      "must_log_result_and_photo": "جاری رکھنے کے لیے آپ کو رزلٹ لاگ کرنا ہوگا اور اپنے ٹیسٹ کی تصویر کھینچنی ہوگی۔",
      "submit_within_15_v2": "ٹپ: ٹیسٹ 15 منٹ تک چلنے کے بعد اپنے نتائج جمع کروائیں۔",
      "click_to_take_photo": "تصویر لینے کے لیے کلک کریں۔",
      "results_may_be_invalid": "ٹیسٹ کے نتائج غلط ہو سکتے ہیں۔"
    },
    "summary": {
      "title": "نتائج کا خلاصہ۔",
      "negative_subtitle": "آپ کے پاس COVID-19 نہیں ہے۔",
      "negative_message": "ہم نے آپ کے آجر کو نوٹیفکیشن بھیجا ہے کہ آپ کو آن سائٹ کام پر واپس آنے کی اجازت مل گئی ہے۔",
      "positive_subtitle": "ہمیں افسوس ہے ، لیکن آپ کو COVID-19 ہے۔",
      "positive_message": "ہم نے آپ کے آجر کو نوٹیفکیشن بھیجا ہے کہ آپ کو کووڈ 19 ہے۔",
      "what_should_you_do": "تمہیں کیا کرنا چاہئے:",
      "employer_name_resources": "آجر کے نام کے وسائل:",
      "follow_cdc_guidelines": "سی ڈی سی کی سفارش کردہ ہدایات پر عمل کریں۔"
    },
    "clarifying_results": {
      "title": "اپنے نتائج کو واضح کرنا۔",
      "which_did_your_result_most_look_like": "آپ کا نتیجہ سب سے زیادہ کیسا لگا؟",
      "no_lines": "کوئی لائن نہیں دیکھی۔",
      "blue_control_line_only": "صرف بلیو کنٹرول لائن۔",
      "pink_sample_line_only": "صرف گلابی نمونہ لائن",
      "blue_control_line_and_pink_sample_line": "بلیو کنٹرول لائن اور گلابی/جامنی نمونہ لائن۔",
      "still_not_sure": "پھر بھی یقین نہیں۔",
      "submit_results": "نتائج جمع کروائیں۔",
      "thank_you": "شکریہ!",
      "clarification_received_message": "آپ کے نتائج کی وضاحت موصول ہو گئی ہے۔",
      "only_pink_line": "صرف گلابی لکیر",
      "no_lines_v2": "کوئی لکیریں نہیں۔"
    },
    "return_to_confirmation": "تصدیق صفحہ پر واپس جائیں۔"
  },
  "preferred_language": {
    "title": "پسندیدہ زبان۔",
    "subtitle": "آپ کونسی زبان کا انتخاب کریں گے؟"
  },
  "appointment_type": {
    "initial_vaccine_appointment": "ابتدائی ویکسین سیریز",
    "one_dose": "ایک مخصوص خوراک کے لیے اپائنٹمنٹ بک کروائیں۔ خاص طور پر ، یہ میرا ہے۔",
    "title": "خوراک کا انتخاب",
    "first_dose": "پہلی خوراک۔",
    "second_dose": "دوسری خوراک۔",
    "single_dose_title": "سنگل خوراک",
    "additional": "اضافی",
    "booster": "بوسٹر",
    "supplemental": "ضمنی",
    "third_dose": "صرف تیسری خوراک",
    "no_vaccine": "مندرجہ بالا میں سے کوئی بھی نہیں۔"
  },
  "activemodel": {
    "errors": {
      "models": {
        "importers/results/row": {
          "attributes": {
            "administered_at": {
              "invalid_datetime": "فارمیٹ٪ {datetime_format} ہونا چاہیے"
            },
            "results_at": {
              "invalid_datetime": "فارمیٹ٪ {datetime_format} ہونا چاہیے"
            },
            "uid": {
              "unknown_test": "ٹیسٹ نہیں ملا"
            }
          }
        },
        "importers/participants/row": {
          "attributes": {
            "access_code": {
              "not_allow_update_test_group_user": "صارف موجود ہے اور اسے اپ ڈیٹ کرنے کی اجازت نہیں ہے۔"
            },
            "date_of_birth": {
              "invalid_date": "فارمیٹ٪ {date_format} ہونا چاہیے"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "٪ {unknown_ethnicities} موجود نہیں ہے ، درج ذیل قابل قبول اقدار میں سے منتخب کریں:٪ {نسلیات}",
                "other": "٪ {unknown_ethnicities} موجود نہیں ، درج ذیل قابل قبول اقدار میں سے منتخب کریں:٪ {قومیتیں}"
              }
            },
            "gender": {
              "unknown_gender": "جنس موجود نہیں ہے ، درج ذیل قابل قبول اقدار میں سے منتخب کریں:٪ {genders}"
            },
            "phone_number": {
              "invalid": "ایک غلط نمبر ہے"
            },
            "population_name": {
              "unknown_population": "٪ {unknown_population} موجود نہیں ہے ، درج ذیل قابل قبول اقدار میں سے منتخب کریں:٪ {Population_names}"
            },
            "self_described_gender": {
              "not_allowed": "اگر جنس \"ترجیحی_تو_خود_بدلی\" نہ ہو تو اسے پُر نہیں کیا جا سکتا"
            },
            "sex": {
              "unknown_sex": "جنس موجود نہیں ہے ، درج ذیل قابل قبول اقدار میں سے انتخاب کریں:٪ {sexes}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sex_orientation موجود نہیں ہے ، درج ذیل قابل قبول اقدار میں سے منتخب کریں:٪ {sexual_orientations}"
            },
            "status": {
              "unknown_status": "حیثیت غلط ہے ، درج ذیل قابل قبول اقدار میں سے منتخب کریں:٪ {statuses}"
            },
            "races": {
              "unknown_races": {
                "one": "٪ {unknown_races} موجود نہیں ہے ، درج ذیل قابل قبول اقدار میں سے منتخب کریں:٪ {races}",
                "other": "٪ {unknown_races} موجود نہیں ، درج ذیل قابل قبول اقدار میں سے منتخب کریں:٪ {races}"
              }
            },
            "vaccination_status": {
              "unknown_vaccination_status": "vaccination status is invalid, pick from the following acceptable values: {{vaccination_statuses}}"
            }
          }
        },
        "importers/organization_user/row": {
          "attributes": {
            "role": {
              "insufficient_privileges": "کردار مقرر کرنے کے لیے ناکافی مراعات۔"
            },
            "user_id": {
              "unknown_user": "صارف نہیں ملا"
            }
          }
        },
        "importers/additional_information/row": {
          "attributes": {
            "additional_information": {
              "invalid_json": "غلط JSON"
            },
            "user_id": {
              "unknown_user": "صارف نہیں ملا"
            }
          }
        },
        "importers/emr/row": {
          "attributes": {
            "appointment_location": {
              "unknown_location": "ملاقات کا مقام نہیں ملا"
            },
            "date_of_birth": {
              "invalid_date": "فارمیٹ٪ {date_format} ہونا چاہیے"
            },
            "email": {
              "duplicated": "پہلے ہی استعمال کیا جاتا ہے"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "٪ {unknown_ethnicities} موجود نہیں ہے ، درج ذیل قابل قبول اقدار میں سے منتخب کریں:٪ {نسلیات}",
                "other": "٪ {unknown_ethnicities} موجود نہیں ، درج ذیل قابل قبول اقدار میں سے منتخب کریں:٪ {قومیتیں}"
              }
            },
            "gender": {
              "unknown_gender": "جنس موجود نہیں ہے ، درج ذیل قابل قبول اقدار میں سے منتخب کریں:٪ {genders}"
            },
            "phone_number": {
              "invalid": "ایک غلط نمبر ہے"
            },
            "population": {
              "unknown_population": "٪ {unknown_population} موجود نہیں ہے ، درج ذیل قابل قبول اقدار میں سے منتخب کریں:٪ {Population_names}"
            },
            "self_described_gender": {
              "not_allowed": "اگر جنس \"ترجیحی_تو_خود_بدلی\" نہ ہو تو اسے پُر نہیں کیا جا سکتا"
            },
            "sex": {
              "unknown_sex": "جنس موجود نہیں ہے ، درج ذیل قابل قبول اقدار میں سے انتخاب کریں:٪ {sexes}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sex_orientation موجود نہیں ہے ، درج ذیل قابل قبول اقدار میں سے منتخب کریں:٪ {sexual_orientations}"
            },
            "races": {
              "unknown_races": {
                "one": "٪ {unknown_races} موجود نہیں ہے ، درج ذیل قابل قبول اقدار میں سے منتخب کریں:٪ {races}",
                "other": "٪ {unknown_races} موجود نہیں ، درج ذیل قابل قبول اقدار میں سے منتخب کریں:٪ {races}"
              }
            }
          }
        },
        "importers/additional_consents/row": {
          "attributes": {
            "consent_form_id": {
              "unknown_consent_form": "consent_form not found"
            },
            "access_code": {
              "unknown_user": "user not found"
            }
          }
        },
        "importers/vaccine/row": {
          "attributes": {
            "date_of_birth": {
              "invalid_date": "should have format {{date_format}}"
            },
            "phone_number": {
              "invalid": "is an invalid number"
            },
            "email": {
              "duplicated": "is already used"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} does not exist, pick from the following acceptable values: {{races}}",
                "other": "{{unknown_races}} do not exist, pick from the following acceptable values: {{races}}"
              }
            },
            "gender": {
              "unknown_gender": "gender does not exist, pick from the following acceptable values: {{genders}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation does not exist, pick from the following acceptable values: {{sexual_orientations}}"
            },
            "self_described_gender": {
              "not_allowed": "cannot fill it if the gender is not \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "sex does not exist, pick from the following acceptable values: {{sexes}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} does not exist, pick from the following acceptable values: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} do not exist, pick from the following acceptable values: {{ethnicities}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} is an invalid value, pick from the following acceptable values: {{tag_actions}}"
            },
            "population": {
              "unknown_population": "{{unknown_population}} does not exist, pick from the following acceptable values: {{population_names}}"
            },
            "appointment_location": {
              "unknown_location": "appointment location not found"
            },
            "appointment_date": {
              "invalid_date": "should have format {{date_format}}"
            },
            "dose_type": {
              "invalid_dose": "{{unknown_dose}} is not a valid dose, pick from the following acceptable values: {{acceptable_doses}}",
              "unavailable_dose": "{{dose_type}} is not available within the vaccine_lot, pick from the following available values: {{available_doses}}"
            },
            "vis_version_date": {
              "invalid_date": "should have format {{date_format}}"
            }
          }
        },
        "importers/base/row": {
          "attributes": {
            "appointment_time": {
              "invalid_time": "should have format {{time_format}}"
            }
          }
        }
      }
    }
  },
  "activerecord": {
    "errors": {
      "models": {
        "test_location": {
          "attributes": {
            "metadata": {
              "wrong_json": "is not a valid JSON"
            },
            "name": {
              "taken": "اور پتہ پہلے سے موجود کسی اور مقام پر استعمال ہو رہا ہے۔"
            }
          }
        }
      }
    },
    "attributes": {
      "organization_user": {
        "organization_user_leads": {
          "one": "User",
          "other": "Users"
        }
      },
      "appointment_slot_group": {
        "organization_user_leads": {
          "one": "Location",
          "other": "Locations"
        }
      }
    }
  },
  "hispanic_questions": {
    "hispanic": "ھسپانوی یا لاطینی/a",
    "not_hispanic": "ہسپانوی یا لاطینی نہیں۔",
    "hispanic_expanded": {
      "other": "ایک اور ہسپانوی ، لاطینی/a یا ہسپانوی نژاد۔",
      "mexican": "میکسیکن ، میکسیکن امریکن ، شیکانو/اے۔",
      "puerto_rican": "پورٹو ریکن۔",
      "cuban": "کیوبا"
    },
    "subtitle": "ضابطوں کا تقاضا ہے کہ ہم درج ذیل معلومات جمع کریں۔",
    "help": "نسل سے مراد مشترک خصلتوں کا ایک مجموعہ ہے جیسے کہ زبان ، ثقافتی رسم و رواج ، مذہب اور دیگر خصوصیات جو لوگوں کے گروہوں میں فرق کرنے کے لیے استعمال ہوتی ہیں۔ یہ نسلی شناخت کے ساتھ بھی ہو سکتا ہے یا نہیں۔",
    "unknown": "نامعلوم",
    "title": "نسلی"
  },
  "sex_at_birth": {
    "question": "سیکس۔",
    "female": "عورت",
    "male": "مرد",
    "help": "سیکس سے مراد وہ چیز ہے جو آپ کے پیدائشی سرٹیفکیٹ پر ہے۔",
    "non_binary": "غیر ثنائی۔",
    "subtitle": "ضابطوں کا تقاضا ہے کہ ہم مندرجہ ذیل تمام معلومات اکٹھا کریں۔",
    "decline": "جواب دینے سے انکار۔",
    "unknown": "نامعلوم"
  },
  "gender": {
    "help": "صنفی شناخت سے مراد ہے کہ آپ ذاتی طور پر کس طرح شناخت کرتے ہیں۔ یہ اس جنس سے مطابقت رکھ سکتا ہے یا نہیں جو آپ کو پیدائش کے وقت تفویض کی گئی تھی۔",
    "subtitle": "اگر آپ اپنی صنفی شناخت کے بارے میں معلومات شامل کرنا چاہتے ہیں تو براہ کرم ایسا کریں۔"
  },
  "sexual_orientation": {
    "title": "جنسی واقفیت۔",
    "subtitle": "اگر آپ اپنے جنسی رجحان کے بارے میں معلومات شامل کرنا چاہتے ہیں تو براہ کرم ایسا کریں۔",
    "gay": "ہم جنس پرست ، ہم جنس پرست ، یا ہم جنس پرست۔",
    "heterosexual": "ہم جنس پرست یا سیدھا۔",
    "bisexual": "ابیلنگی۔",
    "questioning": "سوال کرنا/غیر یقینی/پتہ نہیں۔",
    "prefer_not_to_disclose": "ظاہر نہ کرنے کا انتخاب کریں۔",
    "unknown": "نامعلوم",
    "orientation_not_listed": "واقفیت درج نہیں ہے۔",
    "not_applicable": "قابل اطلاق نہیں",
    "pansexual": "پینسیکشول",
    "queer": "کوئیر"
  },
  "pronouns": {
    "title": "ترجیحی ضمیر۔",
    "he": "وہ/وہ۔",
    "she": "وہ/وہ",
    "they": "وہ انہیں",
    "choose_not_to_disclose": "ظاہر نہ کرنے کا انتخاب کریں۔",
    "prefer_to_self_describe": "خود بیان کرنے کو ترجیح دیں۔"
  },
  "no_insurance_information": {
    "driver_license_or_state_id": "ڈرائیور لائسنس یا ریاستی شناختی نمبر۔",
    "driver_license": "ڈرائیور لائسنس",
    "use_ssn_instead": "اس کے بجائے SSN استعمال کریں۔",
    "social_security_number": "سوشل سیکورٹی نمبر",
    "state": "حالت",
    "upload_front_of_driver_license": "اپنے ڈرائیور لائسنس یا ریاستی شناختی نمبر کے سامنے کا حصہ اپ لوڈ کریں۔",
    "choose_file": "فائل کا انتخاب کریں۔",
    "no_file_chosen": "کوئی فائل منتخب نہیں کی گئی۔",
    "no_identification": "میرے پاس شناخت نہیں ہے۔",
    "insurance_card_has_a_back": "میرے بیمہ کارڈ کی پشت ہے۔",
    "upload_front_of_insurance_card": "اپنے بیمه کارڈ کا سامنے والا حصہ اپ لوڈ کریں۔",
    "front_of_insurance_card_uploaded": "آپ کے بیمه کارڈ کا سامنے والا حصہ اپ لوڈ کیا گیا ہے۔",
    "insurance_card": "بیمہ کارڈ/ انشورنس کارڈ",
    "insurance_card_back": "بیمہ کارڈ پیچھے",
    "back_of_insurance_card_uploaded": "آپ کے بیمہ کارڈ کا پچھلا حصہ اپ لوڈ کیا گیا ہے۔"
  },
  "quidel": {
    "certify_text": "میں تصدیق کرتا ہوں کہ میں صرف ایک بار اس ٹیسٹ کے نتائج لاگ کر رہا ہوں۔",
    "entering_results": "اپنے نتائج درج کرنا۔",
    "review_instructions_1": "ہدایات کا بغور جائزہ لیں۔",
    "review_instructions_2": "آپ اپنے ٹیسٹ کے ساتھ آنے والی ہدایات کا جائزہ لے سکتے ہیں ، ایک مرحلہ وار گائیڈ پڑھ سکتے ہیں ، یا ذیل میں ایک ہدایاتی ویڈیو دیکھ سکتے ہیں:",
    "read_instructions": "ہدایات پڑھیں۔",
    "watch_video": "ویڈیو دیکھیں۔",
    "timer": "ٹیوب میں جھاڑو ہلانے کے بعد ٹائمر شروع کریں۔ ٹائمر 1 منٹ کے بعد رک جائے گا (آپ ٹیوب سے جھاڑو نکالنے کے لیے)۔ براہ کرم نوٹ کریں: آپ کو اپنے ٹیسٹ کے نتائج کے لیے 10 منٹ شروع کرنے کے لیے ٹائمر دوبارہ شروع کرنا ہوگا۔",
    "start_timer": "ٹائمر شروع کریں (اختیاری)",
    "restart_timer": "رکیں اور دوبارہ شروع کریں۔",
    "resume": "دوبارہ شروع کریں",
    "one_minute_countdown": "1 منٹ گزر گیا! ٹیوب سے اپنا جھاڑو نکالیں اور ٹائمر دوبارہ شروع کریں۔",
    "take_photo": "اپنی ٹیسٹ پٹی کی تصویر لیں۔",
    "photo_warning": "جاری رکھنے کے لیے تصویر کی فراہمی ضروری ہے۔",
    "enter_results": "ٹیسٹ کے نتائج درج کریں۔",
    "choose_result": "وہ آپشن منتخب کریں جو آپ کی ٹیسٹ پٹی کی بہترین عکاسی کرے۔ مدد چاہیے؟ مثال کے نتائج دیکھنے کے لیے یہاں کلک کریں۔",
    "positive": "مثبت۔",
    "negative": "منفی۔",
    "unknown": "نامعلوم",
    "clarify": "اپنے نتائج کو واضح کرنا۔",
    "strip_question": "آپ کی ٹیسٹ پٹی سب سے زیادہ کس طرح نظر آئی؟",
    "no_lines": "کوئی لائن نہیں۔",
    "pink_line": "صرف گلابی لکیر۔",
    "not_sure": "ابھی تک یقین نہیں ہے۔"
  },
  "ihealth": {
    "take_a_photo_of_your_test": "نتیجے کی تصویر",
    "retake_photo": "تصویر دوبارہ لیں",
    "capture_photo": "تصویر لیں",
    "confirm_information_correct": "میں تصدیق کرتا ہوں کہ اوپر دی گئی معلومات درست ہیں۔",
    "submit": "جمع کرائیں",
    "title": "{{name}} کے نتائج درج کریں",
    "subtitle": "آپ کو کورونا آئی ہیلتھ ایپ ڈاؤن لوڈ یا استعمال کرنے کی ضرورت نہیں ہے۔",
    "instruction_title": "آئی ہیلتھ کی ہدایات دیکھیں",
    "instruction_guide": "ہدایات پڑھیں",
    "video_guide": "ویڈیو دیکھیں",
    "add_photo": "تصویر لگائیں",
    "confirm_result": "نتیجے کی تصدیق کریں۔",
    "result_warning": "جمع کرانے کے لیے نتیجہ منتخب کریں۔",
    "confirm_warning": "جمع کرانے کے لیے نتیجہ کی تصدیق کریں۔",
    "administered_at_label": "آپ نے یہ امتحان/ٹیسٹ کب لیا؟",
    "instruction_subtitle": "وہ ہدایات دیکھیں جو آپ کے ٹیسٹ کے ساتھ آئیں یا یہاں دی گئی ہدایات کا جائزہ لیں۔"
  },
  "public_test_groups": {
    "title": "کورونا ٹیسٹ کٹ",
    "appointment_recoveries_title": "واپسی؟",
    "appointment_recoveries_button_text": "میرا لنک ڈھونڈیں",
    "search_test_group_title": "پہلی بار",
    "search_test_group_button_text": "ابھی رجسٹر کریں",
    "title_2": "سادہ رجسٹریشن اور رپورٹنگ",
    "step_one": "اکاؤنٹ رجسٹریشن",
    "step_two": "واپس بھیجنے کے لیے تھوک کا نمونہ لیں",
    "step_three": "آن لائن نتائج دیکھیں",
    "title_3": "اپنی کٹ کو استعمال کرنے کے لیے رجسٹر کریں",
    "population_title": "رجسٹریشن",
    "population_button": "استعمال کے قابل بنائیں",
    "faq_subtitle": "مزید جانیے",
    "faq_title": "اکثر پوچھے جانے والے سوالات",
    "faq_1": "میں اپنی ٹیسٹ کٹ کیسے واپس کروں؟",
    "faq_1_footer": "تھوک فنل کے استعمال سے متعلق ہدایات دیکھیں",
    "faq_2": "میں پہلے ہی رجسٹرڈ ہوں لیکن میں اپنا اکاؤنٹ بھول گیا ہوں۔",
    "faq_3": "میں اپنی کٹ کو کیسے قابل استعمال بناؤں؟",
    "faq_2_subtitle": "پہلے سے رجسٹرڈ صارفین اوپر '** میرا لنک ڈھونڈیں**' بٹن استعمال کرکے اپنی رپورٹ کی تصدیق کر سکتے ہیں۔",
    "faq_3_subtitle": "براہ کرم ذیل کے اختیارات کا استعمال کرتے ہوئے پرائمری ہیلتھ اکاؤنٹ کے عمل سے گزریں:",
    "footer_text_1": "اس پروڈکٹ کو فیڈرل ڈرگ اتھارٹی کی طرف سے ابھی منظوری نہیں دی گئی ہے، اگرچہ اسے ای-یو-اے کے تحت ہنگامی استعمال کے لیے اختیار حاصل ہے۔",
    "footer_text_2": "اس پراڈکٹ کو تھوک کے نمونوں کے ذریعے نیوکلک ایسڈ سے کورونا وائرس کی تشخیص کے استعمال کے لیے اجازت دی گئی ہے، کسی دوسرے وائرس یا پیتھوجینز کے لیے نہیں۔",
    "footer_text_3": "اس پروڈکٹ کو ہنگامی حالات میں استعمال کی اجازت فیڈرل فوڈ، ڈرگ اینڈ کاسمیٹک ایکٹ 564(b)(1) کے سیکشن 21 U.S.C. § 360bbb-3(b)(1) کے تحت اس وقت تک حاصل ہے جب تک کہ یہ ایکٹ منسوخ نہ کر دیا جائے۔",
    "description": "آپ کی سہولت کے لیے، آپ نیچے دیئے گئے بٹنوں کا استعمال کرکے اپنی ٹیسٹ کٹ کو قابل استعمال بنا سکتے ہیں:",
    "subtitle": "پرائمری میں پہلی بار؟",
    "subtitle_1": "آپ کی کورونا ٹیسٹ کٹ کے بارے میں معلومات",
    "faq_1_1": "نمونے کی بند شدہ ٹیوب کو بائیو اسپیسمین بیگ میں رکھیں",
    "faq_1_2": "بائیو اسپیسمین بیگ کو واپس SalivaDirect کے لیبل والے ڈبے میں رکھ دیں۔ SalivaDirect کے لیبل والے باکس کو FedEx UN 3373 Pak میں رکھ دیں۔",
    "faq_1_3": "FedEx UN 3373 Pak کی چپکنے والی پٹی کو ہٹا دیں اور اس کو مضبوطی سے بند کرنے کے لیے نیچے کی طرف دبائیں۔",
    "faq_1_4": "براہ کرم اپنا نمونہ اسی دن جمع کرائیں جس دن آپ نے اسے لیا ہو اور یقینی بنائیں کے دن کے آخری جمع کرانے کے وقت سے پہلے آپ یہ عمل مکمل کرلیں۔ ہفتے کے اختتام یعنی ویکنڈ پر اپنے نمونے کو ڈراپ باکس میں نہ جمع کرائیں۔",
    "faq_1_5": "ڈراپ باکس کے مقامات اور اوقات دیکھنے کے لیے **[fedex.com/labreturns](https://www.fedex.com/labreturns)** پر فیڈ ایکس کی ویب سائٹ ملاحظہ کریں۔",
    "demo": {
      "title": "COVID-19 at-home test kit additional information & instructions",
      "online_instructions": "Online instructions",
      "download_instructions": "Download instruction sheet",
      "begin": "Begin",
      "instructions": {
        "welcome_title": "Welcome to the at-home COVID-19 testing portal.",
        "welcome_text_1": "Before you start testing, wash you hands or use hand sanitizer.",
        "welcome_text_2": "Make sure your hands are dry before starting.",
        "continue": "Continue",
        "back": "Back",
        "step_one_title": "Step 1: Check your test kit",
        "step_one_text_1": "Locate the kit components:",
        "step_one_text_2": "It is recommended gloves (not provided) also be used during testing.",
        "step_two_title": "Step 2: Place tube in the tube holder",
        "step_two_text_1": "Remove cap from one **TUBE** and place it in the **TUBE HOLDER.**",
        "step_two_text_2": "**NOTE:** Use of gloves is recommended.",
        "step_five_title": "Step 5: Remove swab from the tube",
        "step_five_text_1": "After **ONE MINUTE**, remove the swab from the **TUBE** by rubbing the swab head against the inside wall of the tube to squeeze out as much liquid as possible.",
        "step_five_text_2": "Dispose of swab in the trash.",
        "step_six_title": "Step 6: Open the test strip",
        "step_six_text_1": "Open the **TEST STRIP** pouch carefully at the slit and hold the **TEST STRIP** as indicated.",
        "step_seven_title": "Step 7: Place test strip in the tube",
        "step_seven_text_1": "Place the **TEST STRIP** into the **TUBE** with the arrows pointing down. Leave the strip in the **TUBE** for a **FULL TEN MINUTES**– do not handle or remove.",
        "step_eight_title": "Step 8: Remove test strip from the tube",
        "step_eight_text_1": "At **TEN MINUTES**, remove the **TEST STRIP** from the **TUBE**. Next, place the **TEST STRIP** on the outline in Steps 10-12. Ensure the **TEST STRIP** is on a flat surface in good lighting.",
        "step_eight_text_2": "**Note:** The test is intended to be read at 10 minutes. If the test is read before this or is read more than 5 minutes after the indicated read time, results may be inaccurate (false negative, false positive, or invalid) and the test should be repeated.",
        "step_nine_title": "Step 9: Check your results",
        "step_nine_text_1": "There are three type of results possible.",
        "step_nine_text_2": "Check for a Positive Result",
        "step_nine_text_3": "Check for a Negative Result",
        "step_nine_text_4": "Check for an Invalid Result",
        "step_ten_title": "Step 10: Remove test strip from the tube",
        "step_ten_text_1": "Place the **TEST STRIP** on the test strip outline below and compare with test result examples shown.",
        "step_three_title": "Step 3: Swab the nostrils",
        "step_three_text_1": "Remove the **SWAB** from its wrapper, being careful not to touch the **SWAB** head.",
        "step_three_text_2": "Gently insert the **SWAB** ½ to ¾ of an inch into the nostril, depending on the size of the person’s nose. Firmly rub the **SWAB** in a circular motion around the inside wall of EACH NOSTRIL at least 4 times.",
        "step_three_text_3": "Be sure to rub BOTH nostrils with the SAME SWAB.",
        "step_three_text_5": "**NOTE:** Failure to swab properly may cause false negative results.",
        "step_four_title": "Step 4: Place swab in the tube",
        "step_four_text_1": "Immediately place the **SWAB** into the liquid inside the **TUBE**, and ensure it is touching the bottom. Stir 3-4 times.",
        "step_four_text_2": "Leave the swab in the solution for **ONE MINUTE**.",
        "step_four_text_3": "**NOTE**: If the swab is in the solution for more than 10-minutes it should not be used.",
        "step_three_text_4": "**NOTE:** If you are swabbing others, please wear a face mask. With children, you may not need to insert the swab as far into the nostril. For very young children, you may need another person to steady the child’s head while swabbing."
      },
      "choose_language": "Choose your language"
    }
  },
  "self_resulting": {
    "report_result": "نتیجہ کی اطلاع دیں۔",
    "report_result_subtitle": "اگر آپ کی تنظیم خود جانچ کی پیشکش کرتی ہے، اور آپ کسی نتیجے کی اطلاع دینے کے لیے تیار ہیں، تو براہ کرم نیچے دیئے گئے بٹن پر کلک کریں۔",
    "need_help": "مدد چاہیے؟",
    "assistance": "اگر آپ کو مدد کی ضرورت ہے، یا اپنی رابطے کی معلومات کو تبدیل کرنے کے لیے، براہ کرم سپورٹ سینٹر سے رابطہ کریں۔",
    "contact_support": "رابطہ برای مدد",
    "save_link": "بعد میں نتائج کی اطلاع دینے کے لیے اس لنک کو محفوظ کریں۔",
    "send_via_email": "ای میل کے ذریعے لنک بھیجیں۔",
    "send_via_text": "ایس ایم ایس ٹیکسٹ کے ذریعے لنک بھیجیں۔",
    "copy_link": "لنک کو کاپی کریں۔",
    "resend_email": "ای میل کی تصدیق دوبارہ بھیجیں۔",
    "phone_verified": "فون کی تصدیق ہو گئی۔",
    "add_vaccination_status": "COVID-19 ویکسینیشن کی حیثیت کو شامل/اپ ڈیٹ کریں۔",
    "terms_of_service": "خدمت کرنی کی شرائط",
    "verify_contact_information": "اپنی رابطہ کی معلومات کی تصدیق کریں تاکہ آپ نتائج اور مواصلت حاصل کر سکیں۔ اگر آپ کو اپنی رابطہ کی معلومات کو تبدیل کرنے کی ضرورت ہے تو، براہ کرم ہمارے سپورٹ سینٹر سے رابطہ کریں۔",
    "resend_text": "ایس ایم ایس ٹیکسٹ کی تصدیق کو دوبارہ بھیجیں۔",
    "loading": "لوڈ ہو رہا ہے۔",
    "add_to_account": "اکاؤنٹ میں شامل کریں",
    "hi": "آداب",
    "email_verified": "ای میل کی تصدیق ہو گئی۔",
    "go_to_my_account": "میرے اکاؤنٹ میں جائیں۔",
    "activate_kit": "ٹیسٹ کٹ کو چالو کریں۔",
    "report_custom_result": "نتیجہ کی اطلاع دیں: {{test}}",
    "activate_custom_test": "ٹیسٹ کو چالو کریں: {{test}}",
    "update_vaccination_status": "ویکسینیشن کی صورتحال کو اپ ڈیٹ کریں۔",
    "order_a_test_kit": "کوئی ٹیسٹ کٹ آرڈر کریں"
  },
  "enter_information": {
    "contact_info": "اپنے رابطے کی معلومات درج کریں۔",
    "label": "فون یا ای میل",
    "invalid": "یہ درست ای میل یا فون نمبر نہیں ہے۔",
    "send": "ہم آپ کے فون یا ای میل پر ایک کوڈ بھیجیں گے۔",
    "legal": "اپنا فون نمبر یا ای میل درج کرکے، آپ متفق ہیں پرائمری ہیلتھ کی **[ خدمت کرنی کی شرائط ]      (https://primary.health/terms-of-service)** اور ** [رازداری کی پالیسی]     (https://primary.health/privacy-policy)**۔",
    "subtitle": "ہم آپ کو اپنے ریکارڈز تلاش کرنے میں مدد کے لیے ایک ٹیکسٹ یا ای میل بھیجیں گے یا ٹیسٹ کے نتائج کی اطلاع دینے کے لیے لنک بھیجیں گے!",
    "find_link": "رجسٹر شدہ؟ اپنا لنک تلاش کریں",
    "not_registered": "رجسٹرڈ نہیں؟ اپنی سائٹ تلاش کریں",
    "search_by_keyword": "تلاش بذریعہ نام، کی ورڈ، شہر، زپ کوڈ یا ادارہ جاتی کوڈ",
    "search": "تلاش",
    "register_here": "یہاں رجسٹر کریں"
  },
  "contact_support": {
    "invalid": "غلط معلومات",
    "invalid_explanation": "آپ نے حال ہی میں پرائمری ہیلتھ کے لیے سائن آن لنک کی درخواست کی ہے۔ البتہ،",
    "never_registered": "پہلے کبھی رجسٹرڈ نہیں ہوئے؟ براہ کرم رجسٹریشن کے لیے اپنے ٹیسٹنگ فراہم کنندہ سے رابطہ کریں، یا نیچے اپنی سائٹ تلاش کریں",
    "get_help": "آپ اپنے ریکارڈ کو اپنی رابطہ کی معلومات سے ملانے میں مدد حاصل کر سکتے ہیں کے ساتھ",
    "get_help_calling": "یا کال کرکے",
    "invalid_explanation_bold": "آپ کی فراہم کردہ معلومات غلط تھی۔",
    "get_help_label": "مدد حاصل کریں",
    "mobile": {
      "invalid_explanation": "کوئی صارف نہیں ملا جسے آپ کے اکاؤنٹ میں شامل کیا جا سکے۔",
      "get_help": "اگر آپ کے کوئی اضافی سوالات ہیں، تو براہ کرم رابطہ کریں۔",
      "reason": "تمام بنیادی صحت کے صارفین ہمارے اکاؤنٹس کی نئی خصوصیت کے اہل نہیں ہیں۔ ایک بار جب آپ اہل ہو جاتے ہیں، تو آپ کو پرائمری ہیلتھ یا آپ کے گروپ ایڈمنسٹریٹر کی طرف سے دعوت نامہ موصول ہوگا۔"
    },
    "zen_name": "رابطے کا نام",
    "ticket_type": "یہ کس حوالے سے ہے",
    "appointment_access_code": "تصدیقی کوڈ (اگر معلوم ہو)",
    "zen_desc": "پیغام",
    "contact_us": "ہم سے رابطہ کریں۔",
    "need_assistance": "اگر آپ کو مدد کی ضرورت ہے، یا اپنی رابطے کی معلومات کو تبدیل کرنے کے لیے، براہ کرم سپورٹ سے رابطہ کریں ۔"
  },
  "show_appointments": {
    "welcome": "اپنی پروفائل تلاش کریں",
    "welcome_subtitle_1": "کے لیے ذیل میں نتائج دکھائے جا رہے ہیں",
    "added_to_account": "اکاؤنٹ میں شامل کر دیا گیا۔",
    "error": "اس شریک کے پاس پہلے سے ہی اکاؤنٹ ہو سکتا ہے۔",
    "welcome_subtitle_2": "ذیل میں نام نظر نہیں آ رہا ؟",
    "welcome_not_logged_in": "سائٹ کا لنک تلاش کرنا",
    "welcome_logged_in": "اپنا گروپ منتخب کریں",
    "welcome_subtitle_1_logged_in": "ممبر شامل کرنا مکمل کرنے کے لیے، صارف اور گروپ منتخب کریں ۔ ممبرز کو ایک اکاؤنٹ مختص کیا جاتا ہے۔",
    "welcome_subtitle_2_logged_in": "اگر آپ اوپر درج اپنے رکن کو نہیں دیکھ رہے ہیں، تو آپ",
    "welcome_subtitle_1_not_logged_in": "منتخب کریں کہ کس کو اپنی سائٹ کا پتہ لگانے یا ایک نیا ممبر شامل کرنے کی ضرورت ہے ۔ ممبر نہیں ملا ؟",
    "welcome_subtitle_3_logged_in": "رابطہ کا کوئی دوسرا طریقہ آزمائیں",
    "welcome_subtitle_4_logged_in": "ہم سے رابطہ کریں",
    "welcome_subtitle_2_not_logged_in": "تلاش کو اپ ڈیٹ کریں ۔",
    "welcome_subtitle_3": "اپنی تلاش کو اپ ڈیٹ کریں"
  },
  "enter_code": {
    "code": "کوڈ کیا ہے؟",
    "enter_code": "بھیجے گئے کوڈ کو درج کریں۔",
    "incorrect_code": "آپ نے ایک غلط کوڈ درج کیا ہے۔",
    "verify": "اکاؤنٹ کی تصدیق کریں۔",
    "verified": "تصدیق شدہ",
    "verify_account_information": "اپنے اکاؤنٹ کی توثیق کریں"
  },
  "general_self_checkout": {
    "choose_test": "آپ نے کون سا امتحان لیا؟",
    "photo_required": "نتیجہ کی تصویر درکار ہے۔",
    "find_your_test": "اپنا ٹیسٹ ڈھونڈیں۔"
  },
  "login": {
    "create_account": "ایک اکاؤنٹ بنائیں",
    "create_account_help": "اکاؤنٹ بنانے کا عمل ٹیسٹنگ یا حفاظتی ٹیکہ جات لگوانے کے لیے رجسٹر ہونے جیسا نہیں ہے۔ جب آپ ایک دفعہ رجسٹر ہوجائیں",
    "create_account_help_schedule": "ملاقاتوں کا شیڈول بنائیں",
    "create_account_help_record": "گھر پر ہونے والے ٹیسٹ ریکارڈ کریں۔",
    "create_account_help_match": "ویکسینیشن اور جانچ کے ریکارڈ کا نظم کریں۔",
    "continue": "بطور مہمان جاری رکھیں",
    "already": "پہلے سے ہی اکاؤنٹ ہے؟ کلک کریں۔",
    "login": "لاگ ان کرنے کے لیے",
    "or_sign_in": "یا اس کے ساتھ سائن ان کریں:",
    "no_account": "کیا آپ کا کوئی اکاؤنٹ نہیں ہے؟ کلک کریں۔",
    "signup": "سائن اپ کرنے کے لیے",
    "here": "یہاں",
    "email_address": "ای میل اڈریس:",
    "password": "پاس ورڈ:",
    "complete_registration": "مکمل رجسٹریشن",
    "last_covid_test": "آخری COVID-19 ٹیسٹ کا نتیجہ",
    "no_record": "کوئی ریکارڈ نہیں",
    "viewing_information": "دیکھ رہا ہے {{first_name}} معلومات",
    "download": "ڈاؤن لوڈ کریں",
    "not_registered": "ایسا لگتا ہے کہ آپ کسی گروپ میں رجسٹرڈ نہیں ہیں۔",
    "please_follow_link": "براہ کرم پرائمری ہیلتھ کے ساتھ اپنی معلومات کو شیڈول کرنے اور اس تک رسائی کے لیے اپنے ای میل سے دعوتی لنک پر عمل کریں۔",
    "log_results": "میرے ٹیسٹ کے نتائج لاگ کریں۔",
    "book_appointment": "ملاقات طی کریی",
    "no_new_appointments": "فی الحال نئی ملاقاتیں کو قبول نہیں کیا جا رہا ہے۔",
    "upload_vaccinations": "ویکسینیشن اپ لوڈ کریں۔",
    "vaccination": "ویکسینیشن",
    "new_appointment": "نئی ملاقات",
    "log_otc_results": "او ٹی سی ٹیسٹ کے نتائج کو لاگ کریں۔",
    "no_household_members": "آپ کے پاس ابھی تک کوئی گھریلو افراد نہیں ہے۔",
    "no_upcoming": "کوئی آنے والی ملاقات نہیں۔",
    "update_vaccination": "ویکسینیشن کو اپ ڈیٹ کریں۔",
    "upload_vaccination": "ویکسینیشن اپ لوڈ کریں۔",
    "title": "پرائمری ہیلتھ میں خوش آمدید",
    "sub_title": "Before registering please create an account.",
    "p1": "ایک اکاؤنٹ بنانا آپ کو یہ کرنے دیتا ہے:",
    "p2": "مستقبل کی ملاقاتوں کا شیڈول بنائیں",
    "p3": "گھر پر ہوییCOVID-19 ٹیسٹ ریکارڈ کریں۔",
    "p4": "منحصر  کی ویکسینیشن اور جانچ کے ریکارڈ کی درخواست کریں۔",
    "p5": "میری پرائمری ہیلتھ سے لاگ ان ہونی کا آسان راسته",
    "no_vaccination_record": "ابھی تک کوئی ویکسینیشن شامل نہیں کی گئی۔",
    "vaccination_title_card": "کورونا ویکسینیشن کی صورتحال",
    "account_and_settings": "اکاؤنٹ کی ترتیبات",
    "delete_account": "اکاؤنٹ ہمیشہ کے لئے بند کریں",
    "language": "زبان",
    "language_current": "موجودہ زبان: {{language}}",
    "log_out": "لاگ آوٹ",
    "delete_account_title": "کیا آپ واقعی اپنا اکاؤنٹ ہمیشہ کے لئے بند چاہتے ہیں؟",
    "new_encounter": "{{first_name}} کا پہلا اندراج",
    "new_encounter_subtitle": "یہ نیا اندراج کس گروپ کے لیے ہے؟",
    "no_programs": "ایسا لگتا ہے کہ آپ کا کسی پروگرام میں اندراج نہیں ہوا۔",
    "edit_members": "اراکین کی معلومات میں ترمیم کریں",
    "edit_members_subtitle": "منتخب کریں کہ آپ کس ڈیش بورڈ پر جانا چاہتے ہیں یا اپنے اکاؤنٹ میں کسی اور ممبر کو شامل کریں",
    "add_members": "ممبر کو شامل کریں",
    "delete_member": "{{full_name}} کا اکاؤنٹ ہمیشہ کے لئے بند کریں؟",
    "delete_member_subtitle": "صارف کو ہٹانے سے اکاؤنٹ سے وابستہ تمام معلومات ختم ہو جائیں گی",
    "select_member": "ممبر کا انتخاب",
    "edit_appointment": "اپائنٹمنٹ میں ترمیم کریں",
    "route": {
      "me": "ڈیش بورڈ",
      "history": "پچھلا مواد",
      "programs": "ٹیسٹ شامل کریں",
      "contact_us": "مدد",
      "choose_member": "ممبر",
      "settings": "ترتیبات",
      "help": "مدد"
    },
    "user_dashboard": "{{first_name}} کا ڈیش بورڈ",
    "view_full_results": "نتائج پورٹل میں دیکھیں",
    "required": "اگر آپ پرائمری ایپ استعمال کررہے ہیں تو یہ درکار ہے",
    "no_email": "میرا ای میل ایڈریس نہیں ہے"
  },
  "vaccination_status": {
    "not_vaccinated": "ویکسین نا شده",
    "fully_vaccinated": "مکمل طور پر ویکسین شدہ",
    "partially_vaccinated": "جزوی طور پر ویکسین شده",
    "vaccination_records": "ویکسینیشن ریکارڈ",
    "title": "آپ کا کوویڈ-19 کے حفاظتی ٹیکہ جات لگوانے کا عمل کس درجے پر ہے؟",
    "definition_title": "حفاظتی ٹیکہ جات لگوانے کے عمل کے درجہ کی تعریف",
    "definition_cdc": "سی ڈی سی حفاظتی ٹیکہ جات لگوانے کے عمل کے درجہ کی تعریف",
    "definition_osha": "او ایس ایچ اے حفاظتی ٹیکہ جات لگوانے کے عمل کے درجہ کی تعریف",
    "definition": {
      "fully_vaccinated": "فائزر- بیون ٹیک یا موڈرنا کے 2 حفاظتی ٹیکہ جات کے سلسلے کی آخری خوارک یا جانسن اینڈ جانسن کے جین سن حفاظتی ٹیکے کی ایک خوراک کے دو ہفتے بعد۔",
      "partially_vaccinated": "پرائمری سلسلے کی 2 خوراکوں میں صرف ایک خوراک حاصل کی، یا دو خوراکوں کا سلسلہ مکمل کرنے کے دو ہفتہ سے کم عرصے بعد یا جانسن اینڈ جانسن کے جین سن کی ایک خؤراک کے بعد۔",
      "not_vaccinated": "کویڈ-19 کا کوئی حفاظتی ٹیکہ نہیں لگایا گیا",
      "fully_vaccinated_with_one_booster": "جانسن اینڈ جانسن کے جین سن حفاظتی ٹیکہ جات کے مکمل سلسلے یا ایک خوراک کے بعد فائزر-بئیون ٹیک یا موڈرنا حفاظتی ٹیکہ کی پہلی بوسٹر خوراک حاصل کی۔",
      "fully_vaccinated_with_two_boosters": "جانسن اینڈ جانسن کے جین سن حفاظتی ٹیکہ جات کے مکمل سلسلے یا خوراک کے بعد فائزر-بئیون ٹیک یا موڈرنا حفاظتی ٹیکہ کی دوسری بوسٹر خوراک حاصل کی۔"
    },
    "label": {
      "fully_vaccinated": "مکمل ویکسین شدہ",
      "partially_vaccinated": "جزوی ویکسین شدہ",
      "not_vaccinated": "غیر ویکسین شدہ",
      "fully_vaccinated_with_one_booster": "مکمل ویکسین شدہ + بوسٹر",
      "fully_vaccinated_with_two_boosters": "مکمل ویکسین شدہ +2 بوسٹرز",
      "prefer_not_to_answer": "جواب نہ دینا پسند کرنا"
    },
    "progress_bar_title": "کویڈ+19 کے حفاظتی ٹیکہ جات کی ہسٹری",
    "upload_record_card": "کویڈ-19 کا اپنا حفاظتی ٹیکہ جات کا ریکارڈ اپ لوڈ کریں",
    "show_example": "مثال دکھائیں",
    "hide_example": "مثال پوشیدہ رکھیں",
    "take_photo": "تصویر کھچوانے کے یے کلک کریں",
    "or": "یا",
    "upload_image_or_pdf_instead": "امیج /پی ڈی ایف اپ لوڈ کریں",
    "select_doses_received": "کویڈ-19 کےجو حفاظتی ٹیکہ جات آپ لے چکے ہیں انہیں منتخب کریں",
    "first_dose": "پہلی خوارک",
    "second_dose": "دوسری خوراک",
    "first_booster_dose": "پہلی بوسٹر خوراک",
    "second_booster_dose": "دوسری بوسٹر خوارک",
    "additional_dose": "اضافی خوراک",
    "first_dose_manufacturer": "کویڈ-19 کی آپ کی پہلی خوراک کا تیار کنندہ کون تھا؟",
    "first_dose_date": "آپ کی پہلی خوراک کی تاریخ کون سی تھی؟",
    "second_dose_manufacturer": "کویڈ-19 کی آپ کی دوسری خوراک کا تیار کنندہ کون تھا؟",
    "second_dose_date": "آپ کی دوسری خوراک کی تاریخ کون سی تھی؟",
    "first_booster_dose_manufacturer": "کویڈ-19 کی آپ کی پہلی بوسٹر خوراک کا تیار کنندہ کون تھا؟",
    "first_booster_dose_date": "آپ کی پہلی بوسٹر خوراک کی تاریخ کون سی تھی؟",
    "second_booster_dose_manufacturer": "کویڈ-19 کی آپ کی دوسری بوسٹر خوراک کا تیار کنندہ کون تھا؟",
    "second_booster_dose_date": "آپ کی دوسری دوسری بوسٹرخوراک کی تاریخ کون سی تھی؟",
    "additional_dose_manufacturer": "کویڈ-19 کی آپ کی اضافی خوراک کا تیار کنندہ کون تھا؟",
    "additional_dose_date": "آپ کی اضافی خوراک لینے کی تاریخ کون سی تھی؟",
    "completed_one": "مکمل شدہ",
    "completed_two": "اپنا و یکسینیشن درجہ اپ ڈیٹ کرنے کا شکریہ!",
    "progress_bar_complete": "مکمل",
    "upload_image": "امیج اپ لوڈ کریں",
    "retake_photo": "تصویر دوبارہ لیں",
    "other": "دیگر",
    "remove_first_dose": "پہلی خوراک کی ہسٹری ختم کریں",
    "remove_second_dose": "دوسری خوراک کی ہسٹری ختم کریں",
    "remove_first_booster_dose": "پہلی بوسٹر خوراک کی ہسٹری ختم کریں",
    "remove_second_booster_dose": "دوسری بوسٹر خوراک کی ہسٹری ختم کریں",
    "remove_additional_dose": "اضافی خوراک کی ہسٹری ختم کریں",
    "exemption": "کیا آپ کو کوئی استثنیٰ حاصل ہے؟",
    "exempt": "میں مستثنیٰ ہوں",
    "not_exempt": "میں مستثنیٰ نہیں ہوں",
    "enter_exemption": "استثنیٰ کا اندارج کریں",
    "upload_exemption_documentation": "اپنے استثنیٰ کی دستاویز اپ لوڈ کریں",
    "remove_dose": "ڈوز ہٹائیں",
    "continue": "جاری رکھیں",
    "enter_credentials": "براہ کرم اپنی مصدقہ معلومات درج کریں",
    "incorrect_credentials": "غلط معلومات، براہ کرم دوبارہ کوشش کریں۔",
    "add_photo": "تصویر شامل کریں"
  },
  "mailers": {
    "appointment_mailer": {
      "payment_pending": "ہیلو {{name}}، ہم نے نوٹس کیا ہے کہ آپ نے {{group_name}} کا {{org_name}}کے ساتھ اپنی اپائنٹمنٹ شیڈول کرنا مکمل نہیں کیا ہے ۔ اپنی اپائنٹمنٹ برقرار رکھنے کے لیے، آپ کو ادائیگی کا مرحلہ مکمل کرنا ہوگا ۔ عمل مکمل کرنے کے لیے براہ کرم اس لنک پر کلک کریں  {{complete_url}} ۔ اگر آپ اپنی اپائنٹمنٹ منسوخ کرنا چاہتے ہیں تو براہ کرم اس لنک پر کلک کریں {{cancel_url}}۔ شکریہ"
    }
  },
  "otc": {
    "record_results_for_abbott": "BinaxNOW ٹیسٹ کے لیے اپنے نتائج کی جانچ اور ریکارڈ کریں",
    "record_results": "اپنے نتائج کی جانچ اور ریکارڈ کریں",
    "activate_a_kit": "ایک کٹ کو چالو کریں",
    "activate_a_kit_for_test": "{{test}} کے لیے ایک کٹ کو چالو کریں",
    "todays_test_process": "آج کے ٹیسٹ کا عمل:",
    "date_of_birth_question": "{{first_name}} کی تاریخ پیدائش کیا ہے؟",
    "confirm_dob_to_continue": "جاری رکھنے کے لیے براہ کرم شرکت کنندہ کی تاریخ پیدائش کی تصدیق کریں:",
    "incorrect_dob": "غلط تاریخ پیدائش",
    "file_too_large": "براہ کرم یقینی بنائیں کہ اپ لوڈ کی گئی فائل کا سائز {{size}} سے چھوٹا ہے",
    "enter_valid_date_in_last_x_days": "آخری {{n}} دنوں میں ایک درست تاریخ درج کریں",
    "barcode_format_not_valid": "بارکوڈ فارمیٹ درست نہیں ہے",
    "complete_button": "مکمل",
    "next_button": "اگلا",
    "back_button": "پیچھے",
    "report_a_test": "ٹیسٹ کی اطلاع دیں:",
    "x_%_completed": "{{n}}% مکمل",
    "answer_survey": "سروے کے سوالوں کے جواب دیں",
    "steps": {
      "answer_survey": "سروے کا جواب دیں",
      "review_instructions": "ہدایات پر نظر ثانی کریں",
      "scan_code": "کوڈ اسکین کریں",
      "enter_time": "وقت درج کریں",
      "enter_result": "نتیجہ درج کریں",
      "take_photo": "تصویر کھینچو",
      "confirm_result": "نتیجہ کی تصدیق کریں",
      "selest_test_kit": "ٹیسٹ کی ٹائپ انتخاب کریں",
      "enter_date": "تاریخ درج کریں",
      "enter_date_and_time": "تاریخ اور وقت درج کریں"
    },
    "review_instructions": "ٹیسٹ کی ہدایات پر نظر ثانی کریں",
    "follow_instructions_or_below": "ان ہدایات پر عمل کریں جو آپ کے ٹیسٹ کے ساتھ آئی ہیں یا نیچے دی گئی ہدایات نظرثانی کے",
    "watch_video": "ویڈیو دیکھئیے",
    "view_instructions": "ہدایات ملاحظہ کریں",
    "follow_instructions": "ان ہدایات پر عمل کریں جو آپ کے ٹیسٹ کے ساتھ آئیں",
    "scan_code": "ٹیسٹ کیو آر کوڈ اسکین کریں",
    "qr_code_description": "QR کوڈ آپ کے ٹیسٹ کے لیے منفرد ہے اور آپ کے ٹیسٹ کے اوپری دائیں کونے میں پایا جا سکتا ہے",
    "show_example": "مثال دکھائیں",
    "hide_example": "مثال چھپائیں",
    "barcode_scanned": "بار کوڈ کامیابی کے ساتھ اسکین ہو گیا",
    "enter_barcode": "بار کوڈ درج کریں",
    "scan_qr_code": "QR کوڈ اسکین کریں",
    "which_test_did_you_take": "آپ نے کون سا امتحان دیا؟",
    "when_did_you_take_test": "آپ نے یہ ٹیسٹ کب دیا؟",
    "what_day_did_you_take_test": "آپ نے کس دن یہ امتحان دیا؟",
    "what_time_did_you_take_test": "آپ نے یہ ٹیسٹ کس وقت دیا تا؟",
    "time": {
      "today": "آج",
      "yesterday": "کل",
      "two_days_ago": "2 دن پہلے"
    },
    "enter_current_time": "موجودہ وقت درج کریں",
    "enter_result": "ٹیسٹ کا نتیجہ درج کریں",
    "choose_result_option": "وہ اختیار منتخب کریں جو آپ کے ٹیسٹنگ کارڈ کے نتائج کو بیان کرتا ہے:",
    "clarify_results": "اپنے نتائج کو واضح کریں",
    "take_result_photo": "نتائج کی تصویر کھینچیں",
    "take_result_photo_description": "اپنے نتائج ریکارڈ کرنے کے لیے مکمل رزلٹ کارڈ کی تصویر کھینچیں",
    "my_result": "میرا نتیجہ",
    "switch_camera": "کیمرہ سوئچ کریں",
    "confirm_result": "نتیجہ کی تصدیق کریں",
    "name": "نام:",
    "date": "تاریخ:",
    "result": "نتیجہ:",
    "results": "نتائج",
    "test_submitted": "شکریہ، {{first_name}}! آپ کا {{test}} کامیابی کے ساتھ جمع کر دیا گیا ہے",
    "dob": "پیدائش کی تاریخ:",
    "administered": "زیر انتظام:",
    "or": "یا",
    "upload": "اپ لوڈ کریں",
    "change_uploaded_file": "اپ لوڈ کردہ فائل کو تبدیل کریں",
    "take_photo": "ایک تصویر کھینچو",
    "confirm_information": "معلومات کی تصدیق کریں",
    "barcode": "بارکوڈ:",
    "scan_test_barcode": "ٹیسٹ بار کوڈ اسکین کریں",
    "barcode_description": "بار کوڈ آپ کے ٹیسٹ کے لیے منفرد ہے اور آپ کے ٹیسٹ میں پایا جا سکتا ہے",
    "enter_date_in_last_30_days": "آخری 30 دنوں میں ایک تاریخ درج کریں اور ٹیسٹ لینے کا تخمینی وقت درج کریں",
    "add_image": "تصویر شامل کریں",
    "change_image": "تصویر تبدیل کریں",
    "skip_barcode_reader": "کوڈ کو اسکین کرنے سے قاصر ہے؟ بہر حال ٹیسٹ کے نتائج کی اطلاع دینے کے لیے اگلا پر کلک کریں",
    "enter_date_and_time": "ٹیسٹ کی تاریخ اور وقت درج کریں",
    "enter_date": "ٹیسٹ کی تاریخ درج کریں",
    "did_you_take_test_today": "کیا آپ نے آج امتحان دیا؟",
    "record_results_for_generic": "{{name}}ٹیسٹ کے لیے اپنے نتائج کی جانچ کریں اور ریکارڈ کریں",
    "choose_result_option_custom": "وہ آپشن منتخب کریں جو آپ کے {{test_name}} نتائج کی وضاحت کرتا ہے:"
  },
  "yes": "جی ہاں",
  "no": "نہیں",
  "event_token": {
    "title": "اپنی ٹیسٹ کٹ کی فراہمی کے لیے براہ کرم وینڈنگ مشین میں نیچے کا کوڈ درج کریں",
    "loading_text": "لوڈ ہو رہا ہے، براہ کرم ایک منٹ میں دوبارہ چیک کریں"
  },
  "appointment_recovery": {
    "complete_title": "کام مکمل ہوگیا",
    "subtitle_1": "اگر آپ آئی فون استعمال کر رہے ہیں، تو براہ کرم اپنے ڈیش بورڈ پر واپس جانے کے لیے اس اسکرین کے اوپری بائیں کونے میں **ہو گیا** پر کلک کریں۔",
    "subtitle_2": "اگر آپ اینڈرائیڈ استعمال کر رہے ہیں تو اسکرین کے اوپری بائیں کونے میں **کراس** کے نشان پر کلک کریں۔",
    "subtitle_0": "جاری رکھنے کے لئے براؤزر بند کریں"
  },
  "primary": {
    "support_email": "support@primary.health"
  },
  "consented_relationship": "رضامندی کا تعلق",
  "finish_registration": {
    "title": "اپنی رجسٹریشن مکمل کریں",
    "subtitle": "براہ کرم اپنی ملاقات سے قبل درج ذیل معلومات مکمل کریں:",
    "button": "رجسٹریشن مکمل کریں"
  },
  "arab_ethnicity": {
    "arab": "عرب",
    "non_arab": "غیر عرب",
    "prefer_not_to_answer": "جواب دینا نہیں پسند",
    "question": "عربی النسل",
    "subtitle": "قواعد و ضوابط کا تقاضہ ہے کہ ہم درج ذیل معلومات حاسل کریں۔",
    "help": "نسل سے مراد مشترکہ اوصاف ، جیسے کہ زبان، ثقافتی روایات، مذہب، اور دیگر اوصاف کا وہ مجموعہ ہے جو لوگوں کے ایک گروہ کو دسرے سے ممیز کرے۔ نسل کسی لسانی شناخت سے مطابقت رکھ بھی سکتی ہے اور نہیں بھی رکھ سکتی۔"
  },
  "custom_comms_quick_add": {
    "mobile_app_links": {
      "email_text": "اپنی ڈیوائس کے لحاظ سے، پرائمری ہیلتھ پاس ڈاؤن لوڈ کرنے کے لیے نیچے دیئے گئے لنک پر کلک کریں ۔"
    }
  },
  "stepper": {
    "next": "اگلا",
    "back": "واپس",
    "complete": "مکمل"
  },
  "registration_direcory": {
    "keyword_search_label": "کلیدی لفظ، شہر، زپ کوڈ، تنظیم کا نام",
    "keyword_search_label_mobile": "شہر، زپ کوڈ، تنظیم کا نام",
    "zero_results": {
      "title": "ہمیں اس تلاش کی اصطلاح کے ساتھ کوئی فراہم کنندہ نہیں ملا ۔",
      "sub_title": "اپنے فراہم کنندہ کو تلاش کرنے کی کوشش کریں بذریعہ:",
      "p1": "زپ کوڈ",
      "p2": "شہر یا قصبے کا نام",
      "p3": "کاؤنٹی کا نام",
      "p4": "فراہم کنندہ یا تنظیم کا نام"
    }
  },
  "registration_flyer": {
    "open_camera": "اپنے موبائل کا کیمرہ کھولیں",
    "point_to_qr_code": "کیمرے سے QR کوڈ کو اسکین کریں اور اسکرین پر نمودار ہونے والے لنک پر کلک کریں",
    "complete_registration": "رجسٹریشن مکمل کریں",
    "need_help": "کیا آپ کو مدد چاہیے؟ ہم سے رابطہ کریں۔"
  },
  "vaccine_info_sheets": {
    "vaccine_info_attestation": "ویکسین کی معلومات کی تصدیق",
    "select_checkbox": "براہ کرم مندرجہ ذیل کا جائزہ لیں اور آگے بڑھنے سے پہلے ذیل میں مناسب چیک باکس منتخب کریں ۔",
    "opportunity_to_read": "مجھے ویکسین لگوانے کے لیے فراہم کردہ ویکسین انفارمیشن اسٹیٹمنٹ (\" VIS \") یا ایمرجنسی استعمال کی اجازت (\" EUA \") فیکٹ شیٹ پڑھنے یا پڑھنے کا موقع دیا گیا ہے:",
    "vaccine_info_sheet": "ویکسین کی معلوماتی شیٹ",
    "vaccine_fact_sheet": "ویکسین فیکٹ شیٹ",
    "vaccine_info_statement": "ویکسین سے متعلق معلومات کا بیان"
  },
  "exemption": {
    "dtap_tdap": "DTaP/TDap",
    "mmr": "ایم ایم آر",
    "ipv_opv": "پولیو",
    "hib": "ایچ آئی بی",
    "hep_b": "ایچ ای پی بی",
    "var": "ویریسیلا",
    "hep_a": "ایچ ای پی اے",
    "pcv": "پی سی وی",
    "mcv": "ایم سی وی"
  },
  "test_strip": {
    "A": "this kit includes tests for chlamydia and gonorrhea.",
    "B": "this kit includes tests for chlamydia and gonorrhea.",
    "C": "this kit includes tests for syphilis.",
    "D": "this kit includes tests for HIV, syphilis, chlamydia and gonorrhea.",
    "E": "this kit includes tests for HIV and syphilis.",
    "box_type": "Box {{type}}",
    "administered_text": "We will contact you when your kit reaches the lab and when your results are ready.",
    "activate_kit": "Activate my test kit",
    "register_another_test": "Register another test",
    "credentials": {
      "title": "Let's get started",
      "label": "Contact",
      "p1": "We'll start with some info about you.",
      "location_step": {
        "p1": "Who provided your test kit?",
        "load": "Load more"
      }
    },
    "checklist": {
      "not_urinated": "You have not urinated in the last hour.",
      "bathroom": "You have access to a bathroom for urine collection.",
      "anal_swab": "You have access to a private place to perform an anal swab.",
      "blood_extraction": "You have access to a clean environment for blood extraction.",
      "washed_hands": "You have washed your hands with soap and warm water for 30 seconds.",
      "title": "Are you ready to begin sample collection?",
      "no_alcohol": "Do not drink alcohol on the day of collection.",
      "menstruation": "Do not collect vaginal swab specimen during menstruation or within 24 hours of intercourse."
    },
    "confirm": {
      "title": "Confirm your information",
      "p1": "Is this information correct?"
    },
    "display_name": {
      "A": "Chlamydia & Gonorrhea (Single Site)",
      "B": "Chlamydia & Gonorrhea (3 Site)",
      "C": "Syphilis",
      "shortened": {
        "A": "Chlamydia & Gonorrhea",
        "B": "Chlamydia & Gonorrhea",
        "C": "Syphilis"
      },
      "CC": "COVID-19",
      "CE": "Celiac Disease Screening",
      "CR": "Creatinine Test",
      "D": "4 Panel Test - HIV, Syphilis, Chlamydia & Gonorrhea (Single Site)",
      "DD": "Diabetes Screening (HbA1c)",
      "E": "HIV & Syphilis",
      "H": "HPV",
      "P": "Hepatitis C",
      "HS": "Herpes Simplex",
      "I": "HIV",
      "TT": "Testosterone Level",
      "VD": "Vitamin D",
      "VS": "Viral Surveillance",
      "AP": "Chlamydia & Gonorrhea",
      "NN": "COVID-19",
      "HH": "COVID-19, Flu A/B, & RSV",
      "FFFWW": "COVID-19 & Flu A/B",
      "YYYWW": "COVID-19, Flu A/B & RSV",
      "HA": "COVID-19, Flu A/B, & RSV"
    },
    "rescan": "Rescan",
    "scanned": "You've scanned Box {{type}}",
    "not_yet": "Not Yet",
    "success_registration": "Registration success!",
    "no_box": "Don't have a box?",
    "faq_link": "Visit FAQs",
    "welcome": "Welcome!",
    "scan": {
      "title": "You're one step closer to a healthier you.",
      "p1": "Scan or enter the **kit bar code** on the side of your box."
    },
    "appointments": {
      "title": "Select the patient & provider",
      "p1": "We found more than one profile associated with your contact information. Select the correct profile:",
      "new": "New patient or provider"
    },
    "verify": {
      "title": "Confirm your identity",
      "p1": "We found your patient record."
    },
    "section_1": "Confirm kit contents",
    "section_2": "Test collection",
    "section_3": "Packaging",
    "section_4": "Shipping",
    "box_a": "Box A",
    "box_b": "Box B",
    "box_c": "Box C",
    "kit_flow": {
      "button_1": "Continue to packaging",
      "button_2": "I'm ready",
      "button_3": "My kit is complete and packed",
      "button_4": "I'm finished",
      "button_5": "Next test",
      "button_6": "Skip instructions"
    },
    "contents": {
      "header": "Great! Let's make sure your kit is complete",
      "text_1": "Does your test kit box contain the following?",
      "blood_collection_card": "Blood collection card",
      "blood_collection_card_subtitle": "(1 or 2 depending on test kit)",
      "alchohol_pads": "2 alcohol pads",
      "lancets": "Single use lancets",
      "up_to_other": "(Up to 4 depending on test kit)",
      "adhesive_bandages": "Adhesive bandages",
      "pipette": "Pipette",
      "urine_tube": "Urine collection tube",
      "urine_cup": "Urine collection cup",
      "oral_swab": "Oral swab",
      "oral_tube": "Oral collection tube",
      "anal_swab": "Anal swab",
      "anal_tube": "Anal collection tube",
      "vaginal_tube": "1 vaginal collection tube",
      "vaginal_swab": "1 vaginal swab",
      "biodegradable_paper": "Biodegradable collection paper",
      "bottle_and_probe": "Sampling bottle and probe",
      "prepaid_envelope": "Pre-paid shipping envelope",
      "biohazard_subtitle": "(with absorbent pad)",
      "biohazard_bag": "1 specimen bag",
      "biohazard_bag_plural": "({{count}}) Biohazard bags",
      "alcohol_pads_plural": "{{count}} alcohol pads",
      "sterile_gauze_pad": "1 sterile gauze pad",
      "collection_tube": "1 collection tube",
      "collection_swabs": "2 collection swabs"
    },
    "packaging": {
      "title": "Packaging checklist",
      "subtitle": "Before you ship or drop your kit at a kiosk, make sure that:"
    },
    "packaging_box_a": {
      "step_1": "You have packed all test kit contents",
      "step_2": "You have used all collection materials contained in the kit to collect the required specimens",
      "step_3": "Your **date-of-birth** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_4": "The **collection date** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_5": "The specimen collection tube is sealed in its biohazard bag",
      "step_6": "All specimens are placed into the box",
      "step_7": "The box is placed into the pre-paid shipping envelope",
      "step_8": "The return shipping envelope is completely sealed"
    },
    "packaging_box_b": {
      "step_1": "You have packed all test kit contents, even if you skipped the test(s)",
      "step_2": "You have used all collection materials contained in the kit to collect the required specimens",
      "step_3": "Your **date-of-birth** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_4": "The **collection date** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_5": "Each specimen collection tube is sealed in its own biohazard bag (only **one** specimen per bag)",
      "step_6": "All specimens are placed into the box",
      "step_7": "The box is placed into the pre-paid shipping envelope",
      "step_8": "The return shipping envelope is completely sealed"
    },
    "packaging_box_c": {
      "step_1": "You have packed all test kit contents, including used lancets.",
      "step_2": "Your **date-of-birth** is written on the blood collection card using MM/DD/YYYY format",
      "step_3": "The **collection date** is written on the blood collection card using MM/DD/YYYY format",
      "step_4": "The blood collection card and all used lancets are placed in the biohazard bag",
      "step_5": "The biohazard bag is placed into the box",
      "step_6": "The box is placed into the pre-paid shipping envelope",
      "step_7": "The return shipping envelope is completely sealed"
    },
    "success": {
      "header_1": "Great!",
      "text_1": "You're ready to collect your first sample.",
      "header_2": "You did it!",
      "text_2": "**Next up:** package your kit to send back.",
      "header_3": "Great job!",
      "text_3": "You’re one step closer to better health.",
      "header_4": "Nice job!",
      "text_4": "Just one more and you’re done!",
      "text_5": "You're ready to collect your sample."
    },
    "instructions": {
      "title": "Select a test to view instructions:",
      "subtitle": "Test",
      "button": "Instructions",
      "pdf": "PDF instructions"
    },
    "instructions_box_a": {
      "title": "Urine collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Do not urinate for at least 1 hour prior to collection. Using first morning urine is best.",
      "step_2": "Urinate directly into the PeeCanter or collection cup, filling between 1/3 and 1/2.",
      "step_3": "Pour from the PeeCanter or use the pipette to transfer urine from the collection cup into the collection tube(s) marked with an orange <span style=\"color: #FF5000\">**\"URINE\"**</span> label.",
      "step_4": "Fill the collection tube(s) until the combined liquid is between the designated fill lines.",
      "step_4_sublabel": "Do not fill tube past the maximum fill line or specimen will be rejected.",
      "step_5": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_6": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
      "step_7": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
    },
    "instructions_box_b": {
      "test_1": {
        "title": "Urine collection",
        "subtitle": "For this test you will need the following materials:",
        "step_1": "Do not urinate for at least 1 hour prior to collection. Using first morning urine is best.",
        "step_2": "Urinate directly into the PeeCanter or collection cup, filling between 1/3 and 1/2.",
        "step_3": "Pour from the PeeCanter or use the pipette to transfer urine from the collection cup into the collection tube(s) marked with an orange <span style=\"color: #FF5000\">**\"URINE\"**</span> label.",
        "step_4": "Fill the collection tube(s) until the combined liquid is between the designated fill lines.",
        "step_4_subtitle": "Do not fill tube past the maximum fill line or specimen will be rejected.",
        "step_5": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
        "step_6": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
        "step_7": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
      },
      "test_2": {
        "title": "Oral swab",
        "subtitle": "For this test you will need the following materials:",
        "step_1": "Hold the swab between the faint score line (if present) and the cotton-topped portion of the swab.",
        "step_2": "Insert the swab into your mouth and rub the swab tip against the back of your throat for 10 seconds.",
        "step_3": "Carefully withdraw the swab and place into the collection tube marked with a blue <span style=\"color: #001F70\">**\"ORAL\"**</span> label. Break the swab at the score line by bending against the collection tube.",
        "step_4": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
        "step_5": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
        "step_6": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
      },
      "test_3": {
        "title": "Anal swab",
        "subtitle": "For this test you will need the following materials:",
        "step_1": "Hold the swab between the faint score line (if present) and the cotton-topped portion of the swab.",
        "step_2": "Insert the swab 3 - 5 cm (1 - 2”) into the anal canal. Gently turn the swab for 5 - 10 seconds to collect any potential organisms.",
        "step_3": "Carefully withdraw the swab and place into the collection tube marked with a green <span style=\"color: #00C08C\">**“ANAL”**</span> label. Break the swab at the score line by bending against the collection tube.",
        "step_4": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
        "step_5": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
        "step_6": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
      }
    },
    "instructions_box_c": {
      "title": "Blood collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Write your name, date of birth, and the date of collection in the designated fields. Use MM/DD/YYYY format.",
      "step_2": "Open blood card flap to expose the circles on the blood collection paper. Do not touch the blood collection paper.",
      "step_3": "Wash hands with warm water for at least 30 seconds, then shake hands vigorously for 15 seconds to encourage blood flow to your fingers.",
      "step_4": "Clean fingertip with alcohol pad. It is best to use the middle or ring finger of your non-dominant hand.",
      "step_5": "Take the lancet and twist off the cap. Press the small tip firmly into the tip of your finger, until the needle ejects with a click. Keeping your hand below your heart during collection, massage finger from base to tip to encourage blood flow.",
      "step_5_subtitle": "Lancets are single use. All lancets need to be returned with your sample to the laboratory for disposal.",
      "step_6": "Starting at the center, apply 3-6 drops to fill the circle and soak through the collection paper. Do not touch the paper with your finger as this will restrict blood flow. Once circle is full, move on to next circle. It is okay for blood to extend beyond lines, but do not let blood spots spread into each other.",
      "step_6_subtitle": "Do not add additional blood to a circle once completed or dry. The “layering” of blood will invalidate the collection.",
      "step_7": "Without closing the blood card, lay it on a flat surface and allow the blood collection paper to air dry at room temperature, for at least 30 minutes. Do not heat, blow dry, or expose the blood collection paper to direct sunlight. Heat will damage the specimen.",
      "step_7_subtitle": "Check the back side of blood collection paper. Blood should saturate all the way through and fill each circle of the collection paper.",
      "step_8": "When blood collection paper is dry, close blood card by tucking flap. Place the blood card and lancets into biohazard bag with the desiccant pack. Ensure biohazard bag is properly sealed."
    },
    "test_tips": {
      "title": "Tips for Proper Blood Collection",
      "subtitle": "For best results:",
      "step_1": "Be sure you are not dehydrated while performing collection. Hydration promotes blood flow.",
      "step_2": "Do not perform collection immediately after smoking.",
      "step_3": "Washing and warming your hands under warm water will help promote blood flow in your hands.",
      "step_4": "Shake hands vigorously towards the floor to encourage blood flow to your fingers.",
      "step_5": "Keep collection device and hands below your heart during collection for best blood flow.",
      "step_6": "You may need more than one finger prick. Repeat these tips between each finger prick."
    },
    "shipping": {
      "header": "Your kit is ready to ship!",
      "text": "**Congrats!** You finished your health screening."
    },
    "pick_up": "Pick up your recommended test kit from the site staff and register below",
    "short_display_name": {
      "A": "Chlamydia & Gonorrhea",
      "B": "Chlamydia & Gonorrhea",
      "C": "Syphilis",
      "CC": "Colorectal Cancer",
      "CE": "Celiac",
      "CR": "Creatinine",
      "D": "HIV, Syphilis, Chlamydia & Gonorrhea",
      "DD": "Diabetes",
      "E": "HIV & Syphilis",
      "H": "HPV",
      "P": "Hepatitis C",
      "HS": "Herpes Simplex",
      "I": "HIV",
      "TT": "Testosterone",
      "VD": "Vitamin D",
      "VS": "Viral Surveillance"
    },
    "test_kit": "Test Kit",
    "view_results": "View results",
    "recommended_kit_title": "Recommended Test Kits",
    "instructions_box_h": {
      "title": "Vaginal Swab Collection",
      "subtitle": "Collection tubes may contain a liquid preservative. <span class=\"text-danger\">**Do not empty liquid preservative from the collection tubes if present.**</span>",
      "step_1": "Hold the swab between the faint score line (if present) and the cotton-tipped portion of the swab in one hand and separate the labia (folds of skin around the vaginal opening).",
      "step_2": "Insert the swab 5cm (2in) into the vaginal opening. Gently turn the swab for 30 seconds while rubbing the swab against the walls of the vagina.",
      "step_3": "Carefully withdraw the swab and place into the collection tube marked with a <span class=\"text-danger\">**RED “VAGINAL” LABEL**</div>. Break the swab by bending against the collection tube.",
      "step_4": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_5": "Write your date of birth in MM/DD/YYYY format onto the tube in the designated area (DOB).",
      "step_6": "Write the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area (Coll. Date).",
      "step_7": "Place specimen into the empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
    },
    "packaging_box_h": {
      "step_3": "Your **date-of-birth** is written on the specimen collection tube using MM/DD/YYYY format",
      "step_4": "Your **collection date** is written on the specimen collection tube using MM/DD/YYYY format",
      "step_6": "The biohazard bag is placed into the box"
    },
    "instructions_box_cc": {
      "title": "Stool collection",
      "step_1": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area. Open green cap by twisting and lifting.",
      "step_2": "Place supplied collection paper into toilet bowl on top of water.",
      "step_3": "Deposit stool sample on top of collection paper.",
      "step_4": "Collect sample from stool before paper sinks and stool sample touches water.",
      "step_5": "Scrape the surface of the fecal sample with the sample probe.",
      "step_6": "Cover the grooved portion of the sample probe completely with stool sample.",
      "step_7": "Close sampling bottle by inserting sample probe and snapping green cap on tightly. Do not reopen.",
      "step_8": "Flush. Collection paper is biodegradable and will not harm septic systems.",
      "step_9": "Wrap sampling bottle in absorbent pad and insert in to  the biohazard bag."
    },
    "contact_support": {
      "title": "Let's fix this",
      "text_1": "We are sorry to hear that something is wrong!",
      "text_2": "Please contact us to let us know what’s wrong and we will help to replace your kit."
    },
    "contact_support_success": {
      "title": "We received your message",
      "text_1": "Thanks for contacting us.",
      "text_2": "A member of our support team will be in touch soon.",
      "button_text": "Return home"
    },
    "kit_in_transit_to_patient": "Your order is on its way! Most orders are delivered within 2-5 business days. Once you receive your kit, we will send you more information on how to activate it and send it back.\n\nTracking Link: https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{tracking_number}}",
    "kit_received_at_lab": "The lab received your kit! You'll receive a notification to check your patient portal when results are ready",
    "registration_confirmation": "Your registration is now complete! Visit your registration confirmation page to activate your test kit:",
    "kit_ordered_online": "We've received your kit order and we will send an update once it ships! \n\nOrder #: {{order_number}} \nOrder date: {{order_date}}",
    "kit_ordered_online_email": {
      "header": "We received your order!",
      "p1": "We've received your order! Once your order ships, we'll send you another update.",
      "order_number": "Order #",
      "order_date": "Order date"
    },
    "completed": "Completed",
    "I": "this kit includes tests for HIV.",
    "CC": "this kit includes tests for COVID-19.",
    "CE": "this kit includes tests for celiac disease.",
    "CR": "this kit includes tests for creatinine.",
    "DD": "this kit includes tests for diabetes.",
    "H": "this kit includes tests for HPV.",
    "P": "this kit includes tests for hepatitis C.",
    "HH": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "HS": "this kit includes tests for HSV-2.",
    "TT": "this kit includes tests for testosterone.",
    "VD": "this kit includes tests for vitamin D.",
    "nasal_collection": {
      "title": "Nasal collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Write your Date of Birth and the Collection Date on your collection tube. Use MM/DD/YYYY format for the dates (i.e., 08/29/2023).",
      "step_2": "Wash your hands with soap and water thoroughly before proceeding and after completeing all steps.",
      "step_3": "Remove the swab from the wrapper with your clean hands. Hold the swab with the white tip up. Do not touch the tip of the swab to anything.",
      "step_4": "Holding the swab with one hand, gently insert the white tip of the swab about 1/2 to 3/4 inces (1 to 2 cm) into the opening of your nose. Rotate the swab for 15 to 30 seconds. Make sure the swab touches the insides of your nose. Remove the swab from your nose being careful not to touch your skin. Do not set the swab down. Repeat in the other nostril.",
      "step_5": "While still holding the swab, unscrew and remove the cap from the transport tube without setting the cap down. Place the swab into the tube with the white tip down, taking great care not to spill or splash the liquid in the collection tube.",
      "step_6": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_7": "Place specimen into the empty biohazard bag with the absobent pad and ensure the seal is closed completely. Return the biohazard bag to the kiosk drop box."
    },
    "NN": "this kit includes tests for COVID-19.",
    "YYYWW": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "FFFWW": "this kit includes tests for COVID-19 and Flu A/B.",
    "HA": "this kit includes tests for COVID-19, Flu A/B, and RSV."
  },
  "copy_link": "صفحہ کا لنک کاپی کریں",
  "copied_link": "لنک کاپی ہو گیا!",
  "vaccines": {
    "descriptions": {
      "vaccine_flu": "عام فلو وائرس کے خلاف تحفظ",
      "vaccine_covid": "COVID -19 وائرس کے خلاف تحفظ",
      "tdap": "Tdap ویکسین تشنج کو روک سکتی ہے",
      "polio": "پولیو وائرس کے خلاف تحفظ ۔ اسکول شروع کرنے سے پہلے بچوں کے لئے درکار ہے ۔",
      "varicella": "ویکسین جو چکن پوکس کے خلاف حفاظت کرتی ہے",
      "shingles": "ویکسین جو جلدی بیماری (شنگلز) کے خلاف حفاظت کرتی ہے ۔ کم از کم عمر 18 سال ہے ۔",
      "human_papillomavirus": "ویکسین جو HPV کے خلاف حفاظت کرتی ہے ۔ 11 سال کے بچوں کے لئے تجویز کردہ ۔",
      "meningococcal": "ویکسین چار قسم کے میننگوکوکل بیکٹیریا سے بچاتی ہے ۔"
    }
  },
  "viral_surveillance": {
    "shipping": {
      "title": "Prepare to ship your sample:",
      "step_1": {
        "label": "Put the biohazard bag into box.",
        "substep_1": "Remove the return shipping mailer from your test kit.",
        "substep_2": "Place the biohazard bag into the original collection kit box and close the box."
      },
      "step_2": {
        "label": "Put box into return shipping mailer.",
        "substep_1": "Place the box into the return shipping mailer and seal the mailer.",
        "substep_2": "Do not fold or cover the UN3373 diamond symbol."
      },
      "step_3": {
        "label": "Return to Fedex location.",
        "substep_1": "Return kit on the same day of collection (before the last pickup) to a Fedex location.",
        "substep_2": "Call for a FedEx pickup at 1.800.GoFedEx."
      }
    },
    "overview": {
      "title": "Welcome to the Viral Sequencing Program!",
      "subtitle": "Your participation helps provide valuable information on which respiratory pathogen variants are circulating in your area. Please follow the instructions that came with your kit to get started."
    },
    "instructions": {
      "title": "Collect your sample:",
      "step_1": "Wash your hands.",
      "step_2": "Remove the cap of the collection tube and open the swab package.",
      "step_2a": "Remove the swab from the package. Do not touch the soft end with your hands or anything else.",
      "step_3": "Collect a nasal swab.",
      "step_3a": "Insert the entire soft end of the swab into your nostril no more than 3/4 of an inch (1/5cm) into your nose.",
      "step_3b": "Slowly rotate the swab",
      "step_3c": "Gently remove the swab.",
      "step_3d": "Using the same swab, repeat in your other nostril with the same end of the swab.",
      "step_4": "Put swab into the collection tube and cap the tube securely.",
      "step_5": "Put the collection tube into the biohazard bag and seal.",
      "step_6": "Apply even pressure to the ziplock to seal the bag shut."
    },
    "click_to_get_started_button": "Click to get started",
    "get_started_button": "Get started",
    "thank_you": {
      "title": "Thank you for using Primary.Health!",
      "p_3": "You may now close the browser.",
      "p_2": "Please make sure to drop off the viral surveillance kit at your closest FedEx location.",
      "p_1": "Your participation actively helps Public Health officials fight the spread of disease. If you would like to see the impact you are making on Public Health, please take a moment to explore more via [this link]({{link}}). You will not be contacted about your results, as they are anonymous."
    },
    "verify_dob_title": "First, let's confirm your details",
    "verify_dob_subtitle": "This helps us ensure the correct person is using the test kit.",
    "activate_kit": "Activate a kit:",
    "steps_complete": "Steps complete",
    "opt_in": {
      "section_1_title": "How it works",
      "section_1_text_1": "Sign up below and receive a viral sequencing test kit in 1-3 business days.",
      "section_1_text_2": "Upon receiving the test kit",
      "section_1_text_3": "Send the sample back per the instructions in the kit using our prepaid label.",
      "sign_up": "Sign me up!",
      "no_to_participate": "No",
      "section_2_title": "What is the impact of your participation?",
      "section_2": {
        "card_1_title": "Rapid response to new variants",
        "card_1_text": "Genetic sequencing helps track the spread and emergence of new respiratory pathogen variants, allowing public health systems to quickly ramp up preparedness efforts.",
        "card_2_title": "Track how Respiratory Pathogens are changing",
        "card_2_text": "Respiratory pathogen sequencing analyzes the “genetic blueprint” of the virus. This helps scientists trace how the virus changes, enabling it to spread more easily, make people more sick",
        "card_3_title": "You have complete privacy",
        "card_3_text": "The sequencing will be processed anonymously. The lab will never contact you about your results"
      },
      "title": "You’re eligible for California’s Free Respiratory Pathogen Viral Sequencing Project!",
      "subtitle": "You can help public health officials protect communities against respiratory pathogens! If you opt in to participate you will receive a free viral sequencing kit that will test what respiratory pathogen strain you are infected with. Results will help officials track the spread of respiratory pathogen variants. See below for more information and to sign up.",
      "continue_to_result": "Continue to my result",
      "create_page_title": "Thank you!",
      "create_page_text_1": "Your kit should arrive in the next 1-3 business days.",
      "create_page_text_2": "Your participation actively helps Public Health officials fight the spread of diseases. You will be notified when your test kit order has been approved and when it has shipped."
    }
  },
  "family_registrations": {
    "find_clinic": "ایک کلینک تلاش کریں",
    "select_pin": "تفصیلات دیکھنے کے لیے پن منتخب کریں",
    "miles_shorten": "{{miles}} mi",
    "next_available": "آئندہ دستیاب",
    "appointment_type": "اپائنٹمنٹ کی قسم",
    "individual_appointment": "انفرادی اپائنٹمنٹ",
    "family_appointment": "فیملی اپائنٹمنٹ",
    "vaccines": "ویکسینز",
    "what_kind_appointment": "آپ کس قسم کا اپائنٹمنٹ بک کرنا چاہتے ہیں ؟",
    "select_family_or_individual": "اگر آپ ایک ساتھ متعدد فیملی ممبرز کے لیے شیڈولنگ کر رہے ہیں تو فیملی اپائنٹمنٹ منتخب کریں ۔",
    "vaccines_selected": "منتخب کردہ ویکسینز",
    "where_care": "آپ کہاں نگہداشت حاصل کرنا چاہتے ہیں ؟",
    "select_vaccines_for_individual": "اپنی اپائنٹمنٹ کے لیے ویکسینز منتخب کریں (اختیاری)۔",
    "select_vaccines_for_family": "اپنی فیملی اپائنٹمنٹ کے لیے ویکسینز منتخب کریں (اختیاری)۔",
    "schedule_services": "سروسز شیڈول کریں",
    "add_family": "فیملی ممبرز شامل کریں اور ذیل میں سروسز منتخب کریں ۔",
    "family_member": "فیملی ممبر {{number}}",
    "morning": "صبح",
    "afternoon": "دوپہر",
    "slot_available": "{{number}} دستیاب",
    "within_x_miles": "کے {{miles}} میل کے اندر",
    "any_distance": "سے کوئی فاصلہ",
    "partial_results": "جزوی نتائج",
    "partial_matches": "مندرجہ ذیل کلینک آپ کی مطلوبہ ویکسینز میں سے کچھ پیش کرتے ہیں لیکن تمام نہیں",
    "no_matches": "اس تلاش کو کوئی مماثلت نہیں ملی ۔ بہتر نتائج کے لیے اپنے فلٹرز تبدیل کرنے کی کوشش کریں ۔",
    "no_clinics_found": "کوئی بھی کلینک ایسا نہیں ہے جو آپ کی تلاش سے مماثل ہو ۔",
    "broaden_filters": "بہتر نتائج کے لیے اپنے فلٹرز کو وسیع کرنے کی کوشش کریں ۔",
    "unavailable_vaccines": "غیر دستیاب ویکسینز:",
    "available_vaccines": "دستیاب ویکسینز:",
    "select_date": "تاریخ منتخب کریں",
    "available_appointments": "دستیاب اپائنٹمنٹس",
    "appointment_scheduling_info": "آپ اگلے مرحلے میں مخصوص فیملی ممبرز کو اپائنٹمنٹ کے اوقات تفویض کر سکیں گے ۔ اگر آپ چاہیں تو آپ مختلف دنوں میں اپائنٹمنٹس شیڈول کر سکتے ہیں ۔",
    "hold_selected_appointments": "ہم آپ کی منتخب کردہ اپائنٹمنٹس کو 15 منٹ کے لیے ہولڈ کریں گے ۔",
    "appointments_selected": "منتخب کردہ اپائنٹمنٹس",
    "no_appointments_selected": "کوئی اپائنٹمنٹ منتخب نہیں کی گئی",
    "vaccines_needed": "ویکسینز درکار ہیں",
    "select_x_appointments": "{{number}} اپائنٹمنٹس منتخب کریں ۔",
    "more": "مزید",
    "less": "کم",
    "register_next_family_member": "فیملی کے اگلے ممبر کو رجسٹر کریں",
    "successfully_registered_x_of_y_family_members": "آپ نے {{y}}خاندان کے افراد میں سے{{x}} فیملی ممبرز کو کامیابی سے رجسٹر کر لیا ہے",
    "next_family_member": "فیملی کا اگلا رکن",
    "appointments_abbreviated": "ملاقاتیں",
    "register_for_clinic": "کلینک کے لیے رجسٹر کریں",
    "select_services": "خدمات منتخب کریں",
    "person": "فرد {{number}}",
    "add_person": "کوئی دوسرا شخص شامل کریں",
    "registration_confirmation": "رجسٹریشن کی توثیق"
  },
  "user_mailer": {
    "verify_email": "آئیے آپ کی ای میل کی توثیق کرتے ہیں!",
    "hi_full_name": "ہیلو {{full_name}}،",
    "verify_email_button": "ای میل کی توثیق کریں",
    "please_verify_email": "اپنی ای میل کی توثیق کرنے کے لیے براہ کرم ذیل میں موجود بٹن استعمال کریں ۔"
  },
  "services": {
    "dptap_dt": "DTaP/Tdap/Td",
    "hepatitis_a": "ہیپاٹائٹس A",
    "hepatitis_b": "ہیپاٹائٹس B",
    "hepatitis_a_b": "ہیپاٹائٹس اے اور بی",
    "hib": "ایچ آئی بی",
    "mmr": "خسرہ، ممپس، روبیلا",
    "meningococcal": "میننگوکوکل",
    "mpox": "Mpox",
    "pneumococcal": "نیوموکوکل",
    "polio": "پولیو",
    "rsv": "سانس کا مطابقت پذیری وائرس",
    "rotovirus": "روٹا وائرس",
    "zoster": "زوسٹر (شنگلز)",
    "tetanus_diptheria": "تشنج اور خناق",
    "tdap": "Tdap",
    "typhoid": "ٹائیفائیڈ",
    "varicella": "ویری سیلا (چکن پوکس)",
    "covid-19": "COVID-19",
    "covid-19_adult": "COVID -19 (بالغ)",
    "covid-19_adult_description": "12 سال یا اس سے زیادہ عمر کے لوگوں کے لیے ۔",
    "covid-19_children": "COVID -19 (بچہ)",
    "covid-19_children_description": "4 سے 11 سال کی عمر کے بچوں کے لئے ۔",
    "covid-19_infants": "COVID -19 (شیر خوار)",
    "covid-19_infants_description": "6 ماہ - 3 سال کی عمر کے بچوں کے لئے ۔",
    "influenza": "نزلہ",
    "mmrv": "خسرہ، ممپس، روبیلا اور ویری سیلا"
  },
  "deep_archived": {
    "admin": "ڈیٹا سیکورٹی کے مقاصد کے لیے، گروپ {{test_group_name}} ({{slug}}) میں موجود ڈیٹا محفوظ شدہ ہے اور اب اس صفحے سے دیکھنا یا تبدیل کرنا ممکن نہیں ہے۔ اگر آپ کو مدد کی ضرورت ہو تو براہ کرم اپنے اکاؤنٹ مینیجر یا support@primary.health سے رابطہ کریں ۔",
    "participant": "یہ صفحہ اب دستیاب نہیں ہے ۔ براہ کرم صحت کی مہم سے اپنے رابطوں سے رابطہ کریں: {{test_group_name}}۔ اگر آپ کو پرانا ریکارڈ دیکھنے میں دشواری ہو رہی ہے تو، براہ کرم مدد کے لئے support@primary.health سے رابطہ کریں ۔"
  }
}