import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormCheck, FormControl, Image, Row } from "react-bootstrap";
import { faCheck, faExclamationTriangle, faInfoCircle, faCamera, faUpload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';

import CheckboxCard from "../../../common/components/CheckBoxCard";
import { getBase64 } from "../../../Otc/components/PhotoUploader";
import {
  LabeledField,
  ResultStatus,
  SaveRemoveServiceButtons,
  ServiceCardSubHeader,
} from "../ServiceCard";
import {
  determineBloodPressureStatus,
  idFromGenericTestResult,
  isValidInteger,
  handleGenericTestResultChange,
  resetGenericTestResultAttributes,
  valueFromGenericTestResult
} from "../utils";
import Webcam from "../../../common/components/Webcam";

const SYMPTOMS = {
  chest_pain: "Chest pain",
  shortness_of_breath: "Shortness of breath",
  back_pain: "Back pain",
  numbness_weakness: "Numbness/weakness",
  changes_in_vision: "Changes in vision",
  difficulty_speaking: "Difficulty speaking",
  other_new_or_worsening_symptoms: "Other new or worsening symptoms",
  no_symptoms: "No symptoms",
};

const VALIDATIONS = {
  systolic: {
    min: 50,
    max: 300,
  },
  diastolic: {
    min: 50,
    max: 300,
  },
};

const BLOOD_PRESSURE_STATUSES = {
  normal: {
    color: "#048154",
    icon: faCheck,
    label: "Normal",
  },
  elevated: {
    color: "#D14110",
    icon: faExclamationTriangle,
    label: "Elevated",
  },
  high_stage_1: {
    color: "#CB2531",
    icon: faExclamationTriangle,
    label: "High (stage 1)",
  },
  high_stage_2: {
    color: "#CB2531",
    icon: faExclamationTriangle,
    label: "High (stage 2)",
  },
  hypertensive_crisis: {
    color: "#CB2531",
    icon: faExclamationTriangle,
    label: "Hypertensive crisis",
  },
};

const InfoBox = ({ containerClassName, message, icon, iconColor, backgroundColor }) => {
  return (
    <div className={containerClassName} style={{ borderRadius: "12px", backgroundColor: backgroundColor }}>
    <FontAwesomeIcon className="me-2" icon={icon} style={{ color: iconColor }} />
    {message}
  </div>
  );
};

const HyperextensionScreeningCardBody = ({
  test,
  updateTest,
  submitTests,
  setIsOpen,
}) => {

  // notes:
  // date and time has a separate input field but it's one datetime value on the backend
  // status is being calculated based on systolic and diastolic values, it's is not being saved

  // first reading
  const systolic = valueFromGenericTestResult(test, "systolic");
  const diastolic = valueFromGenericTestResult(test, "diastolic");
  const dateTime = valueFromGenericTestResult(test, "datetime");
  const [firstReadingDate, setFirstReadingDate] = useState(null);
  const [firstReadingTime, setFirstReadingTime] = useState(null);
  const [firstReadingHypertensionStatus, setFirstReadingHypertensionStatus] = useState(determineBloodPressureStatus(systolic, diastolic));

  // repeated reading
  const systolicRepeated = valueFromGenericTestResult(test, "systolic_repeated");
  const diastolicRepeated = valueFromGenericTestResult(test, "diastolic_repeated");
  const dateTimeRepeated = valueFromGenericTestResult(test, "datetime_repeated");
  const [repeatedReadingDate, setRepeatedReadingDate] = useState(null);
  const [repeatedReadingTime, setRepeatedReadingTime] = useState(null);
  const [repeatedReadingHypertensionStatus, setRepeatedReadingHypertensionStatus] = useState(determineBloodPressureStatus(systolicRepeated, diastolicRepeated));

  // emergency care
  const consentedToEmergencyCare = valueFromGenericTestResult(test, "consented_to_emergency_care");
  const _emergencyCareSymptoms = valueFromGenericTestResult(test, "emergency_care_symptoms");
  const emergencyCareSymptoms = typeof _emergencyCareSymptoms === "string" ? _emergencyCareSymptoms.split(",") : _emergencyCareSymptoms;
  const customEmergencyCareSymptom = valueFromGenericTestResult(test, "custom_emergency_care_symptom");

  // other
  const [currentStep, setCurrentStep] = useState(null); // can be "first_reading", "repeated_reading", "emergency_care"
  const [errors, setErrors] = useState({});
  const [nonResetableTestTypes, setNonResetableTestTypes] = useState([]);
  const mustRepeatTest = ["high_stage_2", "hypertensive_crisis"].includes(firstReadingHypertensionStatus);
  const showEmergencyOptions = repeatedReadingHypertensionStatus == "hypertensive_crisis";
  const repeatedReadingSaved = !!idFromGenericTestResult(test, "diastolic_repeated")
  const saveButtonDisabled = ((currentStep == "first_reading" && (!firstReadingHypertensionStatus || !firstReadingTime)) ||
                              (currentStep == "repeated_reading" && (!repeatedReadingHypertensionStatus || !repeatedReadingTime))) ||
                              Object.values(errors).some(error => !!error);
  const saveButtonLabel = (
    (currentStep == "first_reading" && mustRepeatTest) ||
    (currentStep == "repeated_reading" && !repeatedReadingSaved && repeatedReadingHypertensionStatus == "hypertensive_crisis")
  ) ? "Continue" : "Save";
  const [attachWaiverView, setAttachWaiverView] = useState(null);
  const waiver = test.waiver_base_64 || test.waiver?.url;

  const handleSuccess = () => {
    toastr.success("Blood pressure reading saved");
    setIsOpen(false);
  };

  const handleSave = async () => {
    updateTest({...test, administered: true})

    const response = await submitTests([test]);
    if (!response.data.success) {
      return toastr.error("Something went wrong");
    }

    setNonResetableTestTypes(test.generic_test_results.map(({ type }) => type));
    if (currentStep == "first_reading") {
      if (mustRepeatTest) {
        setCurrentStep("repeated_reading");
      } else {
        handleSuccess();
      }
    } else if (currentStep == "repeated_reading") {
      if (repeatedReadingHypertensionStatus == "hypertensive_crisis") {
        setCurrentStep("emergency_care");
      } else {
        handleSuccess();
      }
    } else {
      handleSuccess();
    }
  };

  const handleReset = () => {
    if (currentStep == "first_reading") {
      setFirstReadingDate('');
      setFirstReadingTime('');
      setFirstReadingHypertensionStatus('');
    } else if (currentStep == "repeated_reading") {
      setRepeatedReadingDate('');
      setRepeatedReadingTime('');
      setRepeatedReadingHypertensionStatus('');
    } else {
      // do nothing
    }
    resetGenericTestResultAttributes(test, updateTest, nonResetableTestTypes);
  };

  const handleSymptomChange = (e) => {
    const currentSymptoms = emergencyCareSymptoms || [];
    const updatedSymptoms = currentSymptoms.includes(e.target.name)
      ? currentSymptoms.filter(symptom => symptom !== e.target.name)
      : [...currentSymptoms, e.target.name];
    handleGenericTestResultChange({ target: { name: "emergency_care_symptoms", value: updatedSymptoms.join(",") } }, test, updateTest);
  };

  const setInitialStep = () => {
    const step = (repeatedReadingSaved && showEmergencyOptions)
      ? "emergency_care"
      : mustRepeatTest
        ? "repeated_reading"
        : "first_reading";
    setCurrentStep(step);
  };

  const splitDateTimeIntoDateAndTime = () => {
    if (!!dateTime && (!firstReadingDate || !firstReadingTime)) {
      const [date, time, timeZone] = dateTime.split(" ");
      setFirstReadingDate(date);
      setFirstReadingTime(time);
    }
    if (!!dateTimeRepeated && (!repeatedReadingDate || !repeatedReadingTime)) {
      const [repeatedDate, repeatedTime, repeatedTimeZone] = dateTimeRepeated.split(" ");
      setRepeatedReadingDate(repeatedDate);
      setRepeatedReadingTime(repeatedTime);
    }
  };

  const joinDateAndTimeIntoDateTime = () => {
    if (!!firstReadingDate && !!firstReadingTime) {
      const dateTime = `${firstReadingDate} ${firstReadingTime}`;
      handleGenericTestResultChange({ target: { name: "datetime", value: dateTime } }, test, updateTest);
    };
    if (!!repeatedReadingDate && !!repeatedReadingTime) {
      const dateTimeRepeated = `${repeatedReadingDate} ${repeatedReadingTime}`;
      handleGenericTestResultChange({ target: { name: "datetime_repeated", value: dateTimeRepeated } }, test, updateTest);
    };
  };

  const validateField = (e) => {
    const { name, value } = e.target;
    const _name = name.split("_")[0];
    const minValue = VALIDATIONS[_name].min;
    const maxValue = VALIDATIONS[_name].max;
    const newErrors = { ...errors };
    if (!value) {
      newErrors[name] = `Please enter a value`;
    } else if (!isValidInteger(value) || parseInt(value) < minValue || parseInt(value) > maxValue) {
      newErrors[name] = `Please enter a value between ${minValue} and ${maxValue}`;
    } else {
      newErrors[name] = null;
    }
    setErrors({ ...newErrors });
  };

  const handleFieldChange = (e) => handleGenericTestResultChange(e, test, updateTest);

  // date and time has a separate input field but it's one datetime value on the backend
  // splits the datetime value into date and time and fill the values in the input fields on the first load
  useEffect(() => splitDateTimeIntoDateAndTime(), [dateTime, dateTimeRepeated]);

  // updates datetime value in the props when the date or time input fields are changed
  useEffect(() => joinDateAndTimeIntoDateTime(), [firstReadingDate, firstReadingTime, repeatedReadingDate, repeatedReadingTime]);

  // calculates and set the blood pressure status based on the systolic and diastolic values
  useEffect(() => setFirstReadingHypertensionStatus(determineBloodPressureStatus(systolic, diastolic)), [systolic, diastolic]);

  // calculates and set the blood pressure status based on the repeated systolic and diastolic values
  useEffect(() => setRepeatedReadingHypertensionStatus(determineBloodPressureStatus(systolicRepeated, diastolicRepeated)), [systolicRepeated, diastolicRepeated]);

  // sets the initial step based on the saved values
  useEffect(() => !currentStep && setInitialStep(), []);

  if (!currentStep) return <span></span>;

  return (
    <>
      <div>
        <ServiceCardSubHeader
          containerClassName="my-3"
          handleLock={() => setIsOpen(false)}
          handleReset={handleReset}
          title="Blood pressure reading"
        />
        <Row className="my-3">
          <Col xs={4}>
            <LabeledField label="Date">
              <FormControl
                disabled={currentStep != "first_reading"}
                name="date"
                onChange={(e) => setFirstReadingDate(e.target.value)}
                onClick={() => !firstReadingDate && setFirstReadingDate(format(new Date(), 'yyyy-MM-dd'))}
                type="date"
                value={firstReadingDate}
              />
            </LabeledField>
          </Col>
          <Col xs={4}>
            <LabeledField label="Time">
              <FormControl
                disabled={currentStep != "first_reading"}
                name="time"
                onChange={(e) => setFirstReadingTime(e.target.value)}
                onClick={() => !firstReadingTime && setFirstReadingTime(format(new Date(), 'HH:mm'))}
                type="time"
                value={firstReadingTime}
              />
            </LabeledField>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <LabeledField
              disabled={currentStep != "first_reading"}
              error={errors.systolic}
              label="Systolic"
              name="systolic"
              onBlur={validateField}
              onChange={handleFieldChange}
              type="text"
              value={systolic}
            />
          </Col>
          <Col xs={4}>
            <LabeledField
              disabled={currentStep != "first_reading"}
              error={errors.diastolic}
              label="Diastolic"
              name="diastolic"
              onBlur={validateField}
              onChange={handleFieldChange}
              type="text"
              value={diastolic}
            />
          </Col>
          <Col xs={4} className="d-flex flex-column justify-content-end">
            {firstReadingHypertensionStatus && (
              <ResultStatus
                className="mb-2"
                color={BLOOD_PRESSURE_STATUSES[firstReadingHypertensionStatus]["color"]}
                icon={BLOOD_PRESSURE_STATUSES[firstReadingHypertensionStatus]["icon"]}
                status={BLOOD_PRESSURE_STATUSES[firstReadingHypertensionStatus]["label"]}
              />
            )}
          </Col>
        </Row>
      </div>

      {["repeated_reading", "emergency_care"].includes(currentStep) && (
        <>
          <hr />
          {currentStep == "repeated_reading" && !!firstReadingHypertensionStatus && (
            <InfoBox
              containerClassName="p-3 my-3"
              backgroundColor="#E9F1FB"
              icon={faInfoCircle}
              iconColor="#2862FA"
              message={`Patient blood pressure is ${BLOOD_PRESSURE_STATUSES[firstReadingHypertensionStatus]["label"].toLowerCase()}. Wait 5 minutes and repeat test.`}
            />
          )}
          <div className="mt-3">
            <div>
              <div className="service-card-header">Repeat blood pressure reading</div>
              <Row className="my-3">
                <Col xs={4}>
                  <LabeledField label="Date">
                    <FormControl
                      disabled={currentStep != "repeated_reading"}
                      name="date_repeated"
                      onChange={(e) => setRepeatedReadingDate(e.target.value)}
                      onClick={() => !repeatedReadingDate && setRepeatedReadingDate(format(new Date(), 'yyyy-MM-dd'))}
                      type="date"
                      value={repeatedReadingDate}
                    />
                  </LabeledField>
                </Col>
                <Col xs={4}>
                  <LabeledField label="Time">
                    <FormControl
                      disabled={currentStep != "repeated_reading"}
                      name="time_repeated"
                      onChange={() => setRepeatedReadingTime(e.target.value)}
                      onClick={(e) => !repeatedReadingTime && setRepeatedReadingTime(format(new Date(), 'HH:mm'))}
                      type="time"
                      value={repeatedReadingTime}
                    />
                  </LabeledField>
                </Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <LabeledField
                    disabled={currentStep != "repeated_reading"}
                    error={errors.systolic_repeated}
                    label="Systolic"
                    name="systolic_repeated"
                    onBlur={validateField}
                    onChange={handleFieldChange}
                    type="text"
                    value={systolicRepeated}
                  />
                </Col>
                <Col xs={4}>
                  <LabeledField
                    disabled={currentStep != "repeated_reading"}
                    error={errors.diastolic_repeated}
                    label="Diastolic"
                    name="diastolic_repeated"
                    onBlur={validateField}
                    onChange={handleFieldChange}
                    type="text"
                    value={diastolicRepeated}
                  />
                </Col>
                <Col xs={4} className="d-flex flex-column justify-content-end">
                  {repeatedReadingHypertensionStatus && (
                    <ResultStatus
                      className="mb-2"
                      color={BLOOD_PRESSURE_STATUSES[repeatedReadingHypertensionStatus]["color"]}
                      icon={BLOOD_PRESSURE_STATUSES[repeatedReadingHypertensionStatus]["icon"]}
                      status={BLOOD_PRESSURE_STATUSES[repeatedReadingHypertensionStatus]["label"]}
                    />
                  )}
                </Col>
              </Row>
            </div>

            {currentStep == "emergency_care" && (
              <>
                <hr />
                {!!repeatedReadingHypertensionStatus && (
                  <InfoBox
                    containerClassName="p-3 my-3"
                    backgroundColor="#FFDDE4"
                    icon={faExclamationTriangle}
                    iconColor="#CB2531"
                    message={`Patient blood pressure is in ${BLOOD_PRESSURE_STATUSES[repeatedReadingHypertensionStatus]["label"].toLowerCase()}. Conduct emergency protocol.`}
                  />
                )}
                <div classaName="my-3">
                  <div className="service-card-header">Emergency care</div>
                  <div className="my-3 inter semibold">Is the patient experiencing any of the following:</div>
                  <Form className="my-3">
                    {Object.entries(SYMPTOMS).map(([key, value]) => {
                      return (
                        <>
                          <FormCheck
                            checked={emergencyCareSymptoms?.includes(key)}
                            id={`${test.id}-checkbox-${key}`}
                            key={key}
                            label={value}
                            name={key}
                            onChange={handleSymptomChange}
                            type="checkbox"
                          />
                          {key === "other_new_or_worsening_symptoms" && emergencyCareSymptoms?.includes("other_new_or_worsening_symptoms") && (
                            <div className="mx-3 my-2">
                              <FormControl
                                name="custom_emergency_care_symptom"
                                onChange={handleFieldChange}
                                type="text"
                                value={customEmergencyCareSymptom}
                              />
                            </div>
                          )}
                        </>
                      )
                    })}
                  </Form>
                </div>

                {!!valueFromGenericTestResult(test, "emergency_care_symptoms") && (
                  <>
                    <div className="mt-5">
                      <hr />
                    </div>
                    <div className="my-3">
                      <div className="my-3 inter semibold">Did patient consent to receive emergency medical services?</div>
                      <Row className="my-3">
                        <Col xs={12} md={6}>
                          <CheckboxCard
                            radio
                            label="Yes, patient consented to care"
                            checked={consentedToEmergencyCare == "true"}
                            onChange={() => handleGenericTestResultChange({ target: { name: "consented_to_emergency_care", value: "true" } }, test, updateTest)}
                          />
                        </Col>
                        <Col xs={12} md={6}>
                          <CheckboxCard
                            radio
                            label="No, patient refused care"
                            checked={consentedToEmergencyCare == "false"}
                            onChange={() => handleGenericTestResultChange({ target: { name: "consented_to_emergency_care", value: "false" } }, test, updateTest)}
                          />
                        </Col>
                      </Row>
                    </div>
                    {consentedToEmergencyCare == "false" && (
                      <div className="my-5">
                        <div className="my-3 inter semibold">Attach patient consent to care waiver: </div>
                        {!attachWaiverView && (
                          <>
                            <div className="d-flex my-3">
                              <Button
                                onClick={() => setAttachWaiverView("camera")}
                                variant="outline-primary"
                              >
                                Take photo
                                <FontAwesomeIcon className="ms-2" icon={faCamera} />
                              </Button>
                              <Button
                                className="ms-3"
                                onClick={() => setAttachWaiverView("upload")}
                                variant="outline-primary"
                              >
                                Upload waiver
                                <FontAwesomeIcon className="ms-2" icon={faUpload} />
                              </Button>
                            </div>
                            {!!waiver && (
                              <Image src={waiver} className="my-3 rounded" height="240" />
                            )}
                          </>
                        )}
                        {attachWaiverView == "camera" && (
                          <div className="d-flex">
                            <Webcam
                              setImageData={(data) => {
                                updateTest({
                                  ...test,
                                  waiver_base_64: data
                                });
                                setAttachWaiverView(null);
                              }}
                              setShow={() => setAttachWaiverView(null)}
                              style={{width: 300, height: 300, borderRadius: 10}}
                            />
                          </div>
                        )}
                        {attachWaiverView == "upload" && (
                          <input
                            type="file"
                            onChange={(e) => {
                              getBase64(e.currentTarget.files[0])
                                .then(data => {
                                  updateTest({
                                    ...test,
                                    waiver_base_64: data,
                                  });
                                  setAttachWaiverView(null);
                                }
                              );
                            }}
                            accept="image/*, application/pdf"
                          />
                        )}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}
      <LabeledField
        containerClassName="my-3"
        label="Notes"
      >
        <Form.Control
          as="textarea"
          onChange={(e) => {
            updateTest({
              ...test,
              notes: e.target.value,
            });
          }}
          value={test.notes}
        />
      </LabeledField>
      <SaveRemoveServiceButtons
        disabled={saveButtonDisabled}
        displayRemove={!test.result}
        label={saveButtonLabel}
        onClick={handleSave}
        slug={test.test_group.slug}
        testId={test.id}
      />
    </>
  );
};

export default HyperextensionScreeningCardBody;