import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faHouse } from '@fortawesome/pro-regular-svg-icons';
import startCase from 'lodash/startCase';
import LogAttemptModal from './LogAttemptModal';
import { assignTest } from './Actions';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { formatDistanceToNowStrict } from 'date-fns';

const getLocation = ({ test_location, appointment_slot_group }) => test_location?.name || appointment_slot_group?.title;

const copyPhoneToClipboard = (text) => {
  const el = document.createElement('input');
  document.body.appendChild(el);
  el.value = text;
  el.select();
  el.setSelectionRange(0, 99999); /*For mobile devices*/
  document.execCommand("copy");
  document.body.removeChild(el);
};

const checkSymptomaticOrContact = (symptoms) => {
  let symptomatic = false;
  let close_contact = false;

  if (symptoms && symptoms !== {}) {
    if (symptoms?.close_contact === 1 || symptoms?.close_contact === '1' || symptoms?.close_contact === 'on') {
      close_contact = true;
      delete symptoms.close_contact;
    }
    if (!symptoms.none) {
      for (const symptom in Object.values(symptoms)) {
        if (symptom === '1' || symptom === 1) {
          symptomatic = true;
          break;
        }
      }
    }
  }

  return { symptomatic, close_contact };
};

const parseSymptoms = ({ questionnaire, /*initial_symptoms_json, */symptoms_json }) => {
  // let positive_last_90_self_reported = false;
  // if (questionnaire && questionnaire !== {} && questionnaire?.question1 === 'Yes') {
  //   positive_last_90_self_reported = true;
  // }

  // commenting this because we will not use it on case reports for noW
  // const {
  //   symptomatic: registration_symptomatic,
  //   close_contact: registration_close_contact
  // } = checkSymptomaticOrContact(initial_symptoms_json);

  const {
    symptomatic: checkin_symptomatic,
    close_contact: checkin_close_contact
  } = checkSymptomaticOrContact(symptoms_json);

  let symptoms = [];
  // if (positive_last_90_self_reported) {
  //   symptoms.push("Self-reported Positive Past 90 Days")
  // }
  if (/*registration_symptomatic ||*/ checkin_symptomatic) {
    symptoms.push("Symptomatic");
  }
  // commenting this because we will not use it on case reports for now
  // if (checkin_close_contact) { //|| registration_close_contact
  //   symptoms.push("Close Contact");
  // }

  return symptoms.join(' and ');
};

const formatBarcodeColumn = (name) => {
  if (name.length < 21) {
    return name;
  } else {
    return `${name.slice(0, 20)}…`;
  }
};

const statusTextColor = (status) => {
  if (status) {
    return [
      'contacted_complete',
      'contact_employer_follow_up_required',
      'contacted_follow_up_required',
      'contacted_final',
      'contacted_video',
      'contacted_phone',
      'normal_result',
      'contacted_patient_called_back',
    ].includes(status) ? 'text-success' : 'text-danger';
  }
};

const age = (dob) => dob ? Math.floor((new Date() - new Date(dob).getTime()) / 3.15576e+10) : null;

const CaseReportRow = ({ test, current_user_id, isSelected = false, updateSelection }) => {
  const [assigned, setAssigned] = useState(test.assigned_user?.full_name);
  const [assigning, setAssigning] = useState(false);
  const [attempted, setAttempted] = useState(test.attempts_count );
  const [logOpen, setLogOpen] = useState(false);

  const barcode = test?.test_strip?.barcode || test.uid;

  const location = getLocation(test);
  const groupName = test.test_group.name;
  const { last_positive_at } = test.user;
  const barcodeColumnNameLength = `${test.uid} - ${test.test_configuration.display_name}`.length;
  const num_positive_days = last_positive_at
    ? formatDistanceToNowStrict(new Date(last_positive_at), {unit: 'day'})
    : null;

  return (
    <tr key={test.id}>
      <td>
        <input type='checkbox' className="form-check-input" checked={isSelected} onChange={() => { updateSelection(test.id, !isSelected) }} />
      </td>
      {
         barcodeColumnNameLength < 21 ?
          <td>
            <b>{barcode} - {test.test_configuration.display_name}</b>
            <br />
            <small>{ groupName } - { location }</small>
          </td>
        :
            <OverlayTrigger
              overlay={<Tooltip>{barcode} - {test.test_configuration.display_name}</Tooltip>}
            >
              <td>
                <b>{formatBarcodeColumn(`${barcode} - ${test.test_configuration.display_name}`)}</b>
                <br />
                <small>{ groupName } - { location }</small>
              </td>
            </OverlayTrigger>
      }
      <td className={statusTextColor(test.case_report_status)}>
        { test.case_report_status ? `(${startCase(test.case_report_status)})` : '(new)' }
        <br />
        { attempted !== null ? `${attempted} attempt(s)` : null }
      </td>
      <td>
        { assigned }
        { test.assigned_user?.id === current_user_id
          ? null
          : <Button size='sm' variant="warning" disabled={assigning} onClick={() => assignTest(test, setAssigned, setAssigning)} >
            { assigned ? 'Reassign to me' : 'Assign to me' }
          </Button>
        }
      </td>
      <td>{test.state}</td>
      <td>{ test.result }</td>
      <td>
        { last_positive_at ? `${num_positive_days} days ago` : 'No' }
      </td>
      <td>{ test.localized_results_at && (new Date(test.localized_results_at).toLocaleString()) }</td>
      <td>{ parseSymptoms(test.appointment) }</td>
      <td>
        <a href={ test.case_report_household_url } className="btn btn-outline-secondary btn-has-one btn-sm rounded-circle">
          <FontAwesomeIcon icon={faHouse} />
        </a>
      </td>
      <td>
        { test.user.full_name }<br/>
        <small>{ test.user.date_of_birth }</small>
        <br />
        <small> { age(test.user.date_of_birth) } years</small>
      </td>
      <td><strong>{ test.user.state }</strong></td>
      <td>
        { test.user.phone_number
          ? <span onClick={() => copyPhoneToClipboard(test.user.phone_number)}><span>{ test.user.phone_formatted }</span>&nbsp;<FontAwesomeIcon icon={faClipboard} style={{cursor: 'pointer'}} /></span>
          : null
        }
      </td>
      <td>
        <Button size='sm' disabled={logOpen} onClick={() => setLogOpen(true)} >Log attempt</Button>
        <LogAttemptModal test={test} open={logOpen} setOpen={setLogOpen} attempted={attempted} setAttempted={setAttempted} />
      </td>
      <td>
        <a className="btn btn-sm btn-primary" href={test.edit_test_group_case_report_path} target="_blank">
          Case report
        </a>
      </td>
      <td>
        { test.external_urls.length > 0
          ? test.external_urls.map((url, i) => (
            <span key={i}>
              <a className="btn btn-sm btn-primary mb-2" href={url} target="_blank">
                {url.indexOf('elationemr') !== -1 ? 'Elation EMR' : 'External Report'}
              </a>
              {' '}
            </span>
          ))
          : null
        }
      </td>
    </tr>
  );
};

export default CaseReportRow;
