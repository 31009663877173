export default {
  "date": {
    "order": [
      "year",
      "month",
      "day"
    ],
    "month": "Mois",
    "day": "Jour",
    "year": "Année",
    "january": "janvier",
    "february": "février",
    "march": "mars",
    "april": "avril",
    "may": "mai",
    "june": "juin",
    "july": "juillet",
    "august": "août",
    "september": "septembre",
    "october": "octobre",
    "november": "novembre",
    "december": "décembre"
  },
  "user": {
    "send_appointment_confirmation_message": "Votre rendez-vous est confirmé pour {{name}}.",
    "landline_appointment_reminder_message": "Bonjour, {{full_name}}. Ceci est un rappel de votre rendez-vous à {{name}}, le {{date}} à {{time}} à l'adresse {{address}}.",
    "send_at_home_visit_confirmation_message": "Votre rendez-vous est confirmé le {{date}} à partir de {{time}}.",
    "send_mail_order_confirmation_message": "Votre commande en ligne est confirmée",
    "send_waitlist_message": "Vouz avez bien été mis sur une liste d'attente {{name}}; ne vous présentez pas jusqu'à ce que nous vous ayons confirmé un rendez-vous.",
    "verify_contact": {
      "phone": "Veuillez confirmer votre numéro de téléphone en cliquant sur le lien: {{confirmation_link}}",
      "email": "{{confirmation_link}}"
    },
    "duplicate_user_continue_registering": "Pour continuer l'inscription de votre rendez-vous, cliquez sur le lien suivant {{url}}",
    "on_demand_confirmation_message": "Vous avez finalisé votre inscription pour {{name}}.",
    "appointment_reminder": "Avant votre rendez-vous à {{start_time}}, veuillez cliquer sur le lien pour voir votre confirmation de rendez-vous et compléter l'information manquante si tel est le cas. Nous vous conseillons de vous présenter au minimum 15 minutes avant votre rendez-vous.",
    "appointment_reminder_base": "{{text}} CLIQUEZ ICI: {{link}}",
    "test_result_notifier": {
      "text_message": "Le portail patient de {{first_name_with_last_initial}} a été mis à jour. Allez sur {{url}} et utilisez le code : {{access_code}}",
      "email_subject": "Nouvelles mises à jour de votre portail patient",
      "email_intro": "Salut {{first_name_with_last_initial}}",
      "email_p1": "Un nouveau message ou une mise à jour patient est disponible pour vous.",
      "email_p2": "Cliquez sur le bouton ci-dessous ou utilisez le lien pour afficher votre portail patient et utiliser le code.",
      "email_p3": "Voir le portail patient à :",
      "email_button": "Voir le portail"
    },
    "landline_appointment_reminder_message_without_time": "Bonjour, {{full_name}}. Ceci est un rappel de votre rendez-vous à {{name}}, le {{date}} à {{time}} à l'adresse de {{address}}.",
    "appointment_reminder_on_demand": "Avant votre rendez-vous le {{date}}, veuillez cliquer sur le lien pour voir votre confirmation de rendez-vous, ou pour compléter l'information manquante si tel est le cas.",
    "new_test_result_notifier": {
      "email_intro": "Votre portail patient a été mis à jour.",
      "email_text_1": "Visitez le portail des antécédents médicaux ci-dessous pour consulter les dossiers du nouveau patient ou les rapports de laboratoire.",
      "email_button": "Antécédents médicaux",
      "email_text_2": "Vous pouvez aussi copier-coller cette adresse dans votre navigateur :"
    },
    "mailers": {
      "email_contact_us": "Vous avez une question ? Contactez-nous à"
    }
  },
  "errors": {
    "generic": {
      "message": "Erreur : veuillez réessayer"
    },
    "messages": {
      "blank": "Doit être remplis"
    },
    "incorrect_credentials": "Vous avez saisi des identifiants incorrects, veuillez réessayer.",
    "error_activating_test_kit": "Une erreur s’est produite lors de l’activation du kit de test."
  },
  "type": "Taper",
  "payment": {
    "continue_label": "Continuer et Payer",
    "policy_text": "Si vous annulez votre rendez-vous, ou si vous ne vous y présentez pas, vous recevrez un remboursement de 80% de votre paiement, effectué une semaine après votre rendez-vous. Ici, vous pouvez voir le [Conditions de Service] dans sa totalité ({{link}}).",
    "card_number": "Numéro de carte",
    "expiry_date": "Date d'expiration",
    "pay_button": "Payer",
    "no_credit_card": "Je paierai sur place",
    "cash_payment_helper": "Assurez-vous de venir à votre rendez-vous avec votre **{{payment}}**",
    "invoice": "Facture d'achat",
    "pay_by_card": "Payer par carte",
    "cost": "Coût",
    "total": "Total",
    "pay_cash_at_appointment": "Je paierai comptant à mon rendez-vous à {{appointment}}",
    "view_invoice": "Voir/Imprimer ma facture d'achat",
    "refund_policy": "Si vous annulez votre rendez-vous, nous rembourserons {{amount}} sur votre paiement, 7 jours après la date initialement prévue de votre rendez-vous. Vous pouvez consulter les [Conditions de service]({{link}}) complètes",
    "refund_window_passed": "Votre rendez-vous ne peut faire l'objet d'un remboursement car l'heure de rendez-vous est dépassée. Veuillez contacter les coordinateurs du site directement pour une résolution.",
    "amount_paid": "Le Montant Payé",
    "balance": "Solde"
  },
  "consent_waiver": {
    "upload_waiver_or_sign": "Télécharger une attestation de renonciation de consentement, ou signez la case signature suivante",
    "stop": "Consentement requis pour compléter l'inscription",
    "parent_or_guardian_required_html": "Afin de compléter votre inscription, votre parent, gardien, ou responable légal doit fournir une attestation de consentement sur le formulaire ci-dessous. Il/elle doit aussi signer ce formulaire. Vous, <b>CANNOT</b> ne pouvez pas consentir à ce service vous-même.",
    "send_parent_or_guardian": "Vous pouvez envoyer ce lien à votre parent ou gardien ; vous pouvez aussi les faire signer ci-dessous, sur cette page.",
    "consent_guardian_name_signature": "Prénom",
    "consent_for_minor": "Signature de consentement de la part du mineur.",
    "guardian_signature": "Signature de parent, gardien, ou responsable légal autorisé medical.",
    "after_you_schedule": "Une attestation de consentement signée par un parent ou gardien doit être fournie par tout participant agé de moins de {{age}}. Veuillez terminer votre inscription afin de l'envoyer à votre gardien ou votre personne à charge.",
    "consent_guardian_last_name_signature": "Nom",
    "consent_first_and_last_name": "Prénom et Nom",
    "phone_number": "No de téléphone du tuteur",
    "email": "Courriel/Mail du tuteur",
    "consent_registrar_with_guardian_name_signature": "Prénom du tuteur ou de la tutrice",
    "consent_registrar_with_guardian_last_name_signature": "Nom de famille du tuteur ou de la tutrice",
    "consent_registrar_first_name_signature": "Prénom de l'officier d'état civil",
    "consent_registrar_last_name_signature": "Nom de famille de l'officier d'état civil",
    "consent_on_behalf": "Je signe au nom de {{first_name}} {{last_name}}.",
    "consent_on_behalf_registrar": "J'ai reçu l'autorisation verbale de {{first_name}} {{last_name}} de signer en son nom.",
    "consent_on_behalf_registrar_with_guardian": "Le tuteur ou la tutrice de {{first_name}} {{last_name}} m'a donné l'autorisation verbale de signer en son nom.",
    "registrar_phone": "Numéro de téléphone du clinicien",
    "registrar_email": "E-mail du clinicien",
    "consented_by_parent_guardian": "Parent/Tuteur",
    "consented_by_decision_maker": "Personne de confiance habilitée en matière médicale",
    "consented_by_guardian_verbal": "Clinicien avec le tuteur (consentement verbal obtenu)",
    "consented_by_registrar": "Clinicien (consentement verbal obtenu)",
    "full_name": "Nom complet",
    "print": "En capitales"
  },
  "registration": {
    "contact_information": "Nom et adresse",
    "contact_information_additional": "Coordonnées du parent ou gardien pour la communication en rapport avec le rendez-vous  et les résultats",
    "personal_information": "Informations démographiques",
    "address_placeholder": "Veuillez saisir votre adresse personnelle",
    "first_name_label": "Prénom",
    "last_name_label": "Nom",
    "errors": {
      "state": "Veuillez noter votre État en deux caractères",
      "phone_number_invalid": "Numéro de téléphone non valide",
      "required": "Cette donnée est requise",
      "email_domain": "Cette donnée n'est pas valide ; veuillez réessayer. L'inscription est uniquement disponible aux participants choisis par l'organisation parraine. Veuillez essayer avec votre mail professionel ou communautaire. Si vous pensez qu'une erreur a été commise, et que vous êtes bien éligible, veuillez contacter la Service du Support.",
      "age_requirement": "Votre date de naissance n'est pas conforme à l'âge requis",
      "signature_required": "La signature est requise",
      "regex_invalid": "Cette donnée n'est pas valide.",
      "date_invalid": "Cette date n'existe pas",
      "invalid_entry": "Veuillez arrêter l'inscription. Veuillez suivre les indications à l'écran ou contacter votre personne de contact.",
      "city": "La ville doit être valide",
      "survey_unanswered": "Veuillez confirmer que toutes les questions requise, marqués d'un *, aient été bien remplies.",
      "postal_code": "Code postal valide doit être de 5 chiffres",
      "field_required": "{{field}} doit avoir une valeur",
      "option_required": "Vous devez choisir l’une des options",
      "year_length_invalid": "Entrez la date de naissance avec une année à quatre chiffres (yyyy)",
      "invalid_day": "Le jour doit figurer entre le 1 et 31",
      "reached_max_chars": "Vous avez atteint le nombre maximum de caractères.",
      "chars_max": "Caractères maximum",
      "minimum_length": "La saisie doit faire au moins {{length}} caractères."
    },
    "insurance_status": {
      "question": "Avez-vous une assurance maladie ?",
      "have_health_insurance": "Oui, j'ai une assurance maladie.",
      "do_not_have_health_insurance": "Non, je n'ai pas d'assurance maladie."
    },
    "insurance_policy_holder": {
      "question": "Qui est le titulaire de l'assurance ?",
      "i_am": "Moi",
      "my_spouse": "Mon épouse ou partenaire",
      "my_parents": "Mes parents",
      "someone_else": "Quelqu'un d'autre",
      "policy_first_name": "Prénom du titulaire de l'assurance maladie",
      "policy_last_name": "Nom de titulaire de l'assurance maladie",
      "policy_dob": "Date de naissance du titulaire de l'assurance maladie",
      "name": "Titulaire de la police"
    },
    "insurance_information": {
      "title": "Coordonnées de la Compagnie d'assurance",
      "company_name": "Nom de la Compagnie d'assurance",
      "id_number": "Numéro de membre",
      "group_number": "Numéro de groupe",
      "secondary_insurance_label": "J'ai une assurance maladie secondaire",
      "take_photo": "Merci de prendre une photo de votre carte d’assurance. Avec cette photo, certaines informations seront remplies automatiquement.",
      "front_of_card": "Recto de la carte",
      "card_information": "Informations de la carte d’assurance",
      "back_of_card": "Verso de la carte"
    },
    "employment_information": {
      "label": "Emploi",
      "address_1": "Adresse de l'employeur",
      "address_2": "ex. Bureau 200",
      "phone_number": "Numéro de téléphone de l'employeur",
      "company": "Nom de l'employeur",
      "postal_code": "Code postal de l'employeur",
      "employed": "Employé",
      "sole_prorietor": "Propriétaire unique",
      "not_employed": "Sans emploi",
      "reporting_supervisor": "Superviseur à qui rapporter les résultats",
      "reporting_department": "Service à qui rapporter les résultats",
      "supervisor_name": "Nom du superviseur",
      "supervisor_email": "Courriel du superviseur"
    },
    "location_availability": "Les essais priorisent actuellement les membres de la communauté qui habitent dans ces zones :\r\n",
    "custom_survey": "Questionnaire",
    "confirmation": "Revoir",
    "waitlisted": "Mis(e) en attente",
    "schedule_your_appointment": "Prendre un rendez-vous",
    "information": "Information(s)",
    "consent": "Consentement",
    "location": "Lieu",
    "symptoms": "Symptômes",
    "address_required": "Veuillez saisir votre adresse personnelle",
    "consent_required": "Veuillez consentir afin de continuer",
    "required_field": "Indique des champs obligatoires",
    "phone_number": "Numéro de mobile",
    "email": "Mail",
    "date_of_birth": "Date de naissance",
    "minimum_age": "L'âge minimum pour participer est de {{year}} ans.",
    "no_minimum_age": "Il n'y a pas d'âge minimum pour suivre un essai",
    "continue_button": "Continuer",
    "email_required": "Cette donnée doit être un mail valide.",
    "done": "Terminé",
    "signature": "Signature",
    "clear_button": "Effacer",
    "back_button": "Retour",
    "choose_location": "Choisissez un lieu",
    "no_slots": "Il n'y a plus des places disponibles à {{location}}",
    "choose_appointment": "Choisissez une heure de rendez-vous à {{location}}",
    "appointment_required": "Veuilliez choisir une heure de rendez-vous",
    "phone_number_required": "Le numéro de téléphone n'est pas valide",
    "phone_number_label": "Veuillez saisir un numéro (mobile) qui accepte des SMS afin de recevoir des résultats plus vite",
    "symptoms_experiencing": "Veuillez selectionner les symptômes que vous avez actuellement. Si vous n'avez pas de symptômes, veuillez continuer.",
    "household": "Membres du ménage",
    "household_p1": "En option, générez un rendez-vous pour votre/vos membre(s) du ménage qui doit/-ivent aussi se faire tester.",
    "add_dependent": "Ajouter un membre du ménage",
    "remove_dependent": "Retirer une personne à charge",
    "dependent_consents": "Consentements",
    "submit": "Compléter",
    "add_waitlist": "Ajouter à la liste d'attente",
    "address": "Adresse personnelle",
    "address_1": "Ligne d'adresse 1",
    "address_2": "Numéro de bâtiment/appartement",
    "address_city": "Ville",
    "address_state": "État",
    "postal_code": "Code postal",
    "race_ethnicity": "Race",
    "gender": "Identité de genre",
    "self_described_gender": "Genre autoproclamé",
    "interpreter": "Avez-vous besoin d'un(e) interprète ? Si oui, dans quelle langue ?",
    "consent_to_terms": "Je consens à ces termes.",
    "reg_not_open": "L'inscription n'est pas encore ouverte.",
    "no_more_avail_spots": "Le créneau horaire que vous avez selectionné n'est plus disponible. Veuillez réessayer.",
    "consent_helper": "Veuillez cliquer et faire glisser votre souris ou doigt vers la boîte de signature pour signer",
    "phone_number_unreachable_label": "Ligne fixe ?",
    "select": "Choisir",
    "test_surveys": "Questions sur votre rendez-vous",
    "edit": "Réviser",
    "continue_to_registration": "Continuer pour vous inscrire",
    "accounts": {
      "already_have_an_account": "Avez-vous déjà un compte?",
      "log_in": "Connexion",
      "sign_up": "Créer un compte",
      "sign_up_description": "Veuillez saisir vos coordonnées pour créer votre compte afin de gagner du temps pour les inscriptions futures.",
      "log_in_description": "Veuillez saisir votre Mail et mot de passe, ou vous identifier avec Google ou Outlook ci-dessous",
      "sign_in_with_label": "S'enregistrer avec {{provider}}",
      "password_must_match": "Les mots de passe doivent correspondre",
      "password_uppercase": "Votre mot de passe doit comporter au moins (%s) caractères majuscules.",
      "password_lowercase": "Votre mot de passe doit comporter au moins (%s) caractères minuscules.",
      "password_number": "Votre mot de passe doit comporter au moins (%s) chiffres.",
      "password_special": "Votre mot de passe doit comporter au moins (%s) caractères spéciaux."
    },
    "password": "Mot de passe",
    "password_confirmation": "Confirmation du mot de passe",
    "consent_for": "Consentement pour {{name}}",
    "book_one_time_appointment": "Prendre un rendez-vous unique.",
    "duplicate_users": {
      "exists": "Vous êtes déjà inscrit(e).",
      "overlapping_email_and_phone_p1": "Nous avons envoyé un message à votre numéro de téléphone et votre Mail.",
      "p2": "En utilisant votre message, vous pouvez changer votre rendez-vous ou saisir vos résultats d'examen.",
      "overlapping_phone_and_email_p3": "Veuillez voir votre Mail ou téléphone pour en savoir plus.",
      "overlapping_email_p3": "Veuillez voir votre Mail pour en savoir plus.",
      "overlapping_phone_p3": "Veuillez voir votre téléphone pour en savoir plus",
      "overlapping_phone_p1": "Nous avons envoyé un message à votre numéro de téléphone.",
      "overlapping_email_p1": "Nous avons envoyé un message à votre Mail.",
      "p4": "Si vous pensez qu'une erreur a été commise, veuillez nous contacter à support@primary.health",
      "overlapping_email_and_phone_p3": "Veuillez voir votre Mail ou téléphone pour en savoir plus."
    },
    "duplicate_waitlist": {
      "exists": "Vous avez été déja mis(e) en attente.",
      "overlapping_email_and_phone_p1": "Nous avons envoyé une autre confirmation à votre numéro de téléphpone et votre Mail.",
      "p2": "Avec votre confirmation, vous pouvez ajouter de l'information supplémentaire, ou vous retirer de la liste d'attente.",
      "overlapping_phone_and_email_p3": "Veuillez voir votre Mail ou téléphone pour en savoir plus",
      "overlapping_email_p3": "Veuillez voir votre Mail pour en savoir plus",
      "overlapping_phone_p3": "Veuillez voir votre téléphone pour en savoir plus",
      "overlapping_email_p1": "Nous avons envoyé une autre confirmation à votre Mail"
    },
    "insist_guardian_consent": "Je donne le consentement de la part de :",
    "confirmation_section": {
      "title": "Veuillez revoir et confirmer",
      "p1": "Veuillez bien vérifier vos informations avant de compléter l'inscription.",
      "edit_information": "Retourner et réviser"
    },
    "iemodal": {
      "title": "Navigateur pas reconnu",
      "body": "L'inscription est présentement possible sur ces navigateurs"
    },
    "show_other_locations": "Cliquez pour voir d'autres lieux.",
    "non_us_address": "Ceci est une adresse internationale",
    "test_group_user_survey": "Dépistage des participants",
    "self_consent": "Je donne le consentement pour moi-même",
    "address_country": "Pays",
    "appointment": "Rendez-vous",
    "employee_id": "Employé ID",
    "appointment_time": "Heure de rendez-vous",
    "appointment_location": "Lieu",
    "phone_or_email": "Numéro de téléphone ou e-mail",
    "no_self_consent": "Je refuse mon consentement",
    "no_insist_guardian_consent": "Je refuse le consentement au nom de {{full_name}}",
    "additional_consents_helpertext": "Les autres consentements peuvent être demandés plus tard",
    "minimum_age_with_months": "L'âge minimum pour participer est de {{year}} ans et {{month}} mois.",
    "assistive_technology": "Technologie d'assistance",
    "covid_vaccine_y_n": "Voulez-vous faire une vaccination contre la COVID-19 lors de ce rendez-vous?",
    "received_covid_vaccine_y_n": "Voulez-vous faire une vaccination contre la COVID-19 lors de ce rendez-vous?",
    "covid_vaccinations": "Vaccinations Covid-19",
    "select_vaccine_dose": "Veuillez choisir la dose de vaccin à recevoir",
    "demographic_info": "Information démographique",
    "additional_info": "Informations supplémentaires",
    "self_described_race": "Race auto-déclarée",
    "verify_contact_information": "Vérification",
    "verify_contact": {
      "we_sent_you_a_code": "Veuillez entrer le code que nous avons envoyé à {{contact}}",
      "confirmation_code": "Code confirmation",
      "code_resent": {
        "email": "Un autre code a été envoyé à votre courrier électronique",
        "phone_number": "Un autre code a été envoyé à votre téléphone"
      },
      "did_not_receive_code": "Aviez-vous reçu le code de confirmation ?",
      "verify_by": {
        "email": "Vérifiez plutôt par courrier électronique",
        "phone_number": "Vérifiez plutôt par numéro de téléphone"
      }
    },
    "preferred_method_of_communication": {
      "question": "Moyen de communication privilégié",
      "helper_text": "Nous vous contacterons pour vous tenir à jour sur les rendez-vous",
      "phone_number": "Téléphone (SMS uniquement ; des frais de données seront peut-être facturés)",
      "landline": "À l’heure actuelle, nous ne pouvons pas communiquer vers les téléphones fixes. Merci de fournir une adresse e-mail pour les communications importantes.",
      "verification_method": "Comment souhaitez-vous recevoir votre code d’authentification ?",
      "verification_helper_text": "Un code vous sera envoyé pour vérifier votre identité avant la connexion."
    },
    "skip_for_now": "Ignorer pour l’instant",
    "default_information": {
      "title": "D’accord, nous utiliserons cette adresse",
      "title_v2": "D'accord, nous utiliserons ces informations de contact"
    },
    "middle_name_label": "Deuxième prénom",
    "confirm_appointment": "Confirmer le rendez-vous",
    "dob": "Date de naissance",
    "different_appointment": "Autre rendez-vous",
    "select_appointment_time": "Choisir l’heure du rendez-vous",
    "decline_address": "Je ne fournis pas d’adresse. Merci d’utiliser plutôt l’adresse du programme",
    "patient_information": "Informations du patient",
    "scan_license": "Scanner le permis de conduire",
    "how_to_contact": "Comment devrons-nous vous contacter ?",
    "decline_email_or_phone": "Je ne fournis pas d’adresse e-mail ou de numéro de téléphone. J’autorise les administrateurs du programme à recevoir mes résultats.",
    "medical_screening": "Dépistage médical",
    "show_service_at_this_location": "Montrer les services sur ce site",
    "verbal_consent_provided": "Conformément à la loi sur le handicap, le/la participant(e) ou sa personne de confiance légalement habilitée en matière médicale a fourni un consentement verbal. Un consentement écrit du participant ou de sa personne de confiance légalement habilitée en matière médicale devra être obtenu sous 24 heures.",
    "unexpired_written_consent_present": "Le site de test dispose d’un consentement écrit encore valide.",
    "select_to_proceed": "Veuillez sélectionner une option ci-dessus pour continuer.",
    "guardians_information": "Informations du tuteur",
    "registrars_information": "Informations du clinicien",
    "optional": "optionnel",
    "vfc_eligibility": "Éligibilité aux vaccins pour enfants (VFC)",
    "vfc_eligibility_subtext": "Votre enfant est peut-être en droit de bénéficier de vaccins gratuits, grâce au programme Vaccins pour enfants (VFC) du CDC, au sein de cabinets médicaux, de pharmacies et de cliniques affiliées au programme VFC."
  },
  "gender_key": {
    "male": "Homme",
    "female": "Femme",
    "cisgender": "Cisgenre",
    "non_binary": "Genderqueer ou nonbinaire",
    "other": "Autre",
    "prefer_to_self_describe": "Identité non-listée",
    "prefer_not_to_disclose": "Refuser de répondre",
    "transgender_male": "Homme transexuel",
    "transgender_female": "Femme transexuelle",
    "unknown": "Inconnu(e)",
    "non_binary_only": "Non binaire",
    "intersex": "Intersexué(e)",
    "transgender": "Transgenre",
    "gender_fluid": "Genre fluide",
    "not_applicable": "Non applicable"
  },
  "ethnicity": {
    "american_indian_alaska_native": "Amérindien(ne) ou autochtone d'Alaska",
    "american_indian_central_america": "Amérindien(ne) d'Amérique Centrale ou du Sud",
    "asian": "Asiatique",
    "black": "Noir(e) ou Afro-Américain(e)",
    "latinx": "Latino/-e, Latinx, ou Hispanique",
    "middle_eastern_north_african": "Moyen-oriental(e) ou maghrébin(ne)",
    "native_hawaiian_pacific_islander": "Autochtone d'Hawaï ou Polynésien(ne)",
    "white_european": "Blanc ou caucasien(ne)",
    "unknown": "Inconnu(e)",
    "prefer_not_to_disclose": "Préfère ne pas répondre",
    "asian_indian": "Indien-Asiatique",
    "filipino": "Philippin(e)",
    "japanese": "Japonais(e)",
    "korean": "Coréen(e)",
    "vietnamese": "Vietnamien(ne)",
    "other_asian": "Asiatique (autre)",
    "native_hawaiian": "Autochtone d'Hawaï",
    "guamanian_or_chamorro": "Guamanien(ne) ou Chamorro",
    "samoan": "Samoan(e)",
    "other_pacific_islander": "Polynésien(ne) (autre)",
    "chinese": "Chinois(e)",
    "help": "La race fait référence aux caractéristiques physiques qui sont utilisées pour distinguer les différents groupes de personnes ; il se peut que cette définition corresponde, ou ne corresponde pas, à votre nationalité, ou au pays où vous detenez votre citoyenneté.",
    "subtitle": "Les réglementations nationales nous obligent à collécter toutes les informations suivantes.",
    "laotian": "Laotien(ne)",
    "cambodian": "Cambodgien(ne)",
    "other": "Autre",
    "bangladeshi": "Bangladais",
    "hmong": "Hmong",
    "indonesian": "Indonésien",
    "malaysian": "Malaisien",
    "pakistani": "Pakistanais",
    "sri_lankan": "Sri-lankais",
    "thai": "Thaïlandais",
    "taiwanese": "Taïwanais",
    "fijian": "Fidjien",
    "guamanian": "Guamanien",
    "tongan": "Tongien",
    "bahamian": "Bahaméen(ne)"
  },
  "languages": {
    "en": "Anglais",
    "fr": "Francais",
    "es": "Espagnol",
    "so": "Somalien",
    "hmn": "hmong",
    "asl": "Langue des signes américaine",
    "kar": "Karen/karène",
    "am": "Amharique",
    "ru": "Russe",
    "om": "Oromo"
  },
  "symptoms": {
    "fever": "Fièvre ou frissons",
    "cough": "Toux",
    "muscle_aches": "Douleurs musculaires",
    "severe_fatigue": "Fatigue inhabituelle et sévère",
    "trouble_breathing": "Difficultés respiratoires",
    "diarrhea": "Diarrhée",
    "loss_of_smell": "Perte de l'odorat",
    "loss_of_taste": "Perte du goût",
    "shortness_of_breath": "Essoufflement ou difficultés respiratoires",
    "headache": "Mal de tête",
    "sore_throat": "Mal à la gorge",
    "congestion": "Congestion nasale ou écoulement nasal",
    "nausea": "Nausées ou vomissements",
    "close_contact": "Contact étroit avec quelqu'un d'infecté*",
    "helper_text": {
      "close_contact": "Avez-vous été en contact étroit (à moins d'une distance de six pieds ou une distance d'environ 1 à 2 mètres pendant une durée d'au moins 15 minutes) avec quelqu'un qui a testé positif pour la COVID-19 ?"
    },
    "suspected_exposure": "Exposition présumée",
    "none": "Pas de symptômes"
  },
  "instructions": {
    "title": "Vous êtes bien inscrit(e), mais avant de quitter..",
    "p1": "Cette page contient de l'information sur votre rendez-vous ainsi que le code à barres lié à votre rendez-vous.",
    "appointment_scheduled": "Votre rendez-vous est bien fixé",
    "verify_info": {
      "title": "Verifiez vos coordonnées",
      "p1": "Il est important de valider votre information de contact afin de faciliter votre accès au portail pour patients.",
      "p2": "Une attestation de confirmation a été bien envoyée à votre Mail. Veuilliez cliquer sur le lien fourni.",
      "p3": "Si vous avez besoin de changer votre information de contact, veuillez cliquer sur le bouton Aide."
    },
    "verified": "Vérifié",
    "not_verified": "Non vérifié",
    "resend_text": "Vous n'avez pas encore reçu le lien ?",
    "resend": "Renvoyer",
    "skip": "Ignorer la validation & voir la confirmation",
    "name": "Nom",
    "appointment_details": "Rendez-vous",
    "date_and_time": "Date et heure",
    "add_to_calendar": "Ajouter au calendrier",
    "instructions": "Indications générales",
    "successfully_flash": "Rendez-vous fixé avec succès !",
    "success_flash_email": "Mail validé avec succès.",
    "success_flash_phone": "Numéro de téléphone validé avec succès",
    "mail_order": "Vous recevrez votre test-kit par courrier dans un délai d'une semaine.",
    "at_home": "Un médecin viendra chez vous vers l'heure fixée",
    "at_home_instructions": "Indications pour les tests à domicile",
    "mail_order_instructions": "Indications pour les commandes par courrier",
    "request_additional_appointment": "Fixer un rendez-vous de suivi",
    "book_additional_appointment": "Fixer un autre rendez-vous",
    "confirmation_code": "Code de confirmation",
    "completed": "Terminé",
    "appointment_barcode": "Code à barres du rendez-vous",
    "dependent_links": "Liens pour les rendez-vous à domicile",
    "on_demand_title": "Vous êtes bien inscrit, mais avant de quitter...",
    "save_link": "Sauvegardez ce lien",
    "verify_phone": "Validez votre numéro de téléphone",
    "verify_phone_description": "Il est important de valider votre information de contact afin de faciliter l'accès à vos dossiers médicaux. Un SMS de validation a été envoyé à votre numéro de téléphone.",
    "verify_email_description": "Il est important de valider votre information de contact afin de faciliter l'accès à vos dossiers médicaux. Un SMS de validation a été envoyé à votre Mail.",
    "information": "Information d'inscription.",
    "follow_up_appointment": "Rendez-vous de suivi",
    "get_directions": "Obtenir des directives",
    "cancel": "Annuler votre rendez-vous",
    "reschedule_appointment": "Fixer à nouveau votre rendez-vous",
    "reschedule_linked_appointments": "Fixer à nouveau vos rendez-vous",
    "no_slots": "Aucun crénau horaire disponible",
    "check_results": "Consultez votre portail des patients",
    "follow_up_modal_header": "Veuillez fixer un rendez-vous pour recevoir votre deuxième dose de vaccin",
    "are_you_sure_you_want_to_cancel": "Êtes-vous certain(e) de vouloir annuler ce rendez-vous ?",
    "please_choose_cancellation_reason": "Veuillez choisir une des raisons suivantes pour votre annulation",
    "additional_details": "Veuillez ajouter quelconque information supplémentaire ci-dessous",
    "errors": {
      "missing_cancellation_reason": "Veuillez choisir une raison pour votre annulation"
    },
    "verify_email": "Validez votre Mail",
    "redcap_url": "Cliquer pour terminer votre sondage",
    "verify_contact_information": "Validez votre information de contact",
    "please_also": "Veuillez aussi",
    "new_title": "Confirmation pour {{name}}",
    "contact_method": "Moyen de contact",
    "next_steps": {
      "title": "Prochaines étapes",
      "p1": "Vous avez un kit de test à activer. Lorsque vous êtes sur le point de réaliser votre test, suivez le lien envoyé à votre adresse e-mail pour revenir à cette page et activer votre test ci-dessous.",
      "administered": "Nous vous contacterons lorsque votre kit aura atteint le laboratoire et que vos résultats seront prêts. En général, les résultats sont prêts 3 à 5 jours après l’arrivée du kit au laboratoire. Vous pourrez voir les résultats sur votre page [Medical history]({{link}}) lorsqu’ils seront prêts.",
      "resulted": "Les résultats sont disponibles sur votre page [Medical history]({{link}}).",
      "shipping_information": "Informations d’envoi"
    },
    "save_this_page": {
      "title": "Sauvegarder cette page",
      "p1": "Utilisez cette page pour signaler un autotest ou voir les résultats parmi les antécédents médicaux.",
      "p2": "Pour accéder à cette page à l’avenir, cliquez sur le lien dans votre e-mail ou SMS de confirmation.",
      "p3": "Vous pouvez sauvegarder cette page en l’ajoutant aux marque-pages, à l’écran d’accueil ou en copiant le lien en lieu sûr."
    },
    "show_my_barcode": "Montrer mon code-barres",
    "my_account": "Mon compte",
    "register_another": "Inscrire un autre participant",
    "update_vaccine": "Mettre à jour les informations sur les vaccins",
    "medical_history": "Antécédents médicaux",
    "upcoming_appointments": "Rendez-vous à venir",
    "reschedule": "Modifier la date ou l’heure",
    "resend_confirmation": "Renvoyer la confirmation",
    "appointment_details_v2": "Détails du rendez-vous",
    "confirm_cancellation": "Confirmer l’annulation",
    "confirm_cancellation_question": "Confirmez-vous que vous souhaitez annuler ce rendez-vous ?",
    "select_new_appointment_time_below": "Choisir un nouvel horaire de rendez-vous ci-dessous. Si vous avez besoin de modifier le lieu des services ou du rendez-vous, veuillez annuler ce rendez-vous et en programmer un nouveau.",
    "no_take_me_back": "Non, revenir en arrière",
    "yes_cancel_appointment": "Oui, annuler le rendez-vous",
    "update_appointment": "Mettre à jour le rendez-vous",
    "select_new_appointment_time": "Choisir un nouvel horaire de rendez-vous",
    "clinic": "Clinique",
    "services": "Services",
    "appointment_missed": "Rendez-vous manqué",
    "appointment_canceled": "Rendez-vous annulé",
    "screening_complete_for": "Screening complete for {{name}}"
  },
  "result_page": {
    "errors": {
      "invalid_access_code": "Code d'accès non valide"
    },
    "landing": {
      "p1": "Pour voir vos résultats, veuillez saisir votre :",
      "access_code_label": "Code d'accès",
      "submit_button": "Confirmer",
      "recent_results": "Vos résultats récents"
    },
    "view_results": {
      "headline": "Résultats pour {{name}}",
      "reregister": "S'inscrire à nouveau",
      "status": {
        "likely_positive": "Résultats indéterminés",
        "test_not_performed": "Un test à nouveau réquis",
        "results_ready": "Résultats prêts",
        "processing": "En traitement",
        "test_status": "État du test",
        "test_result": "Résultats du test",
        "administered": "Administré",
        "results_pending": "Résultats en attente",
        "test_results": "Résultats du test"
      },
      "at": "au {{address}}"
    },
    "result_label": "Résultats",
    "result": {
      "flu_a": {
        "result_label": "Résultat grippe A"
      },
      "flu_b": {
        "result_label": "Résultat grippe B"
      },
      "covid19": {
        "result_label": "Résultat COVID-19"
      },
      "covid": {
        "result_label": "Résultat COVID-19"
      },
      "sars": {
        "result_label": "Résultat de SARS"
      },
      "control": {
        "result_label": "Contrôle"
      },
      "rsv": {
        "result_label": "Résultat VRS"
      },
      "result": {
        "result_label": "Résultat"
      },
      "hba1c": {
        "normal": "Normal",
        "warning": "Prédiabète",
        "danger": "Diabète"
      },
      "lead_venous": {
        "danger": "Anormal",
        "normal": "Normal"
      },
      "tc": {
        "result_label": "Résultat CT"
      },
      "hdl": {
        "result_label": "Résultat HDL"
      },
      "trg": {
        "result_label": "Résultat TRG"
      },
      "ldl": {
        "result_label": "Résultat LDL"
      },
      "non_hdl": {
        "result_label": "Résultat hors HDL"
      },
      "tc_hdl_ratio": {
        "result_label": "Rapport CT/HDL"
      },
      "glu": {
        "result_label": "Résultat GLU"
      },
      "alere_cholestech_ldx": {
        "danger": "À risque",
        "warning": "Anormal",
        "normal": "Normal"
      },
      "lead": {
        "result_label": "Plomb"
      },
      "zinc": {
        "result_label": "Zinc"
      },
      "lead_standard_profile": {
        "danger": "Anormal",
        "normal": "Normal"
      },
      "creatinine": {
        "danger": "Anormal",
        "normal": "Normal"
      },
      "igg": {
        "result_label": "Résultat IgG"
      },
      "igm": {
        "result_label": "Résultat IgM"
      },
      "blood_glucose_fasted": {
        "normal": "Normal",
        "warning": "Élevé",
        "danger": "Élevé",
        "hypoglycemia": "Hypoglycémie",
        "prediabetes": "Pré-diabète",
        "diabetes": "Diabète"
      },
      "total_cholesterol_fasted": {
        "normal": "Normal",
        "elevated": "Élevé",
        "high": "Élevé",
        "low": "Faible",
        "abnormal": "Anormal"
      },
      "total_cholesterol_unfasted": {
        "normal": "Normal",
        "elevated": "Élevé",
        "high": "Élevé"
      },
      "a1c_now": {
        "normal": "Normal",
        "warning": "Prédiabète",
        "danger": "Diabète"
      },
      "blood_glucose": {
        "warning": "Bas",
        "normal": "Normal",
        "prediabetes": "Prédiabète",
        "danger": "Diabète",
        "hypoglycemia": "Hypoglycémie",
        "medical_emergency": "Urgence médicale",
        "inconclusive_1": "Non concluant 1",
        "inconclusive_2": "Non concluant 2",
        "possible_diabetes": "Diabète possible"
      },
      "triglycerides": {
        "result_label": "Triglycérides"
      },
      "blood_glucose_ucsf": {
        "low": "Faible",
        "normal": "Normal",
        "high": "Élevé"
      },
      "syphilis": {
        "result_label": "Résultat de la syphilis"
      },
      "hepatitis_c": {
        "result_label": "Résultat de l'hépatite C"
      },
      "hiv": {
        "result_label": "Résultat du VIH"
      },
      "rapid_hiv": {
        "positive": "Réactif (Positif préliminaire)",
        "negative": "Non réactif (Négatif)",
        "did_not_result": "Invalide (Indéterminé)"
      },
      "rapid_hcv": {
        "positive": "Réactif (Positif préliminaire)",
        "negative": "Non réactif (Négatif)",
        "did_not_result": "Invalide (Indéterminé)"
      },
      "rapid_syphilis": {
        "positive": "Réactif (Positif préliminaire)",
        "negative": "Non réactif (Négatif)",
        "did_not_result": "Invalide (Indéterminé)"
      }
    },
    "documents": "Papiers",
    "self_administered": "Test auto-administré récupéré à {{location}}.",
    "patient": "Patient",
    "medical_history": "Antécédents médicaux",
    "overview_title": "Choisir un test ou un service pour voir plus de détails et les éventuelles actions supplémentaires nécessaires. Sachez que certains résultats de test ne sont peut-être pas encore disponibles ou ne le seront qu’une fois que vous aurez parlé à un(e) prestataire de santé.",
    "insurance_information": "Remplir les informations d’assurance",
    "contact_support": "Si vous avez besoin d’assistance, merci de contacter le service d’aide.",
    "show": "Montrer",
    "hide": "Masquer",
    "lab_report": "Rapport du laboratoire",
    "contact_provider": {
      "header": "Vous avez des résultats positifs",
      "description": "Souhaitez-vous parler à un(e) soignant(e) au sujet de vos résultats et de votre traitement ?",
      "yes_option_text": "Oui, je souhaite parler à un(e) soignant(e)",
      "no_option_text": "Non, j’ai lu et compris mes résultats et je ne souhaite pas parler à un(e) soignant(e)",
      "confirm_phone_number_header": "Confirmer votre numéro de téléphone",
      "confirm_phone_number_description": "Merci de confirmer le meilleur numéro de téléphone pour votre consultation.",
      "confirm_button_text": "Confirmer",
      "consultation_confirmed_header": "Consultation confirmée",
      "consultation_confirmed_description": "Un(e) soignant vous appellera au {{phone_number}} d’ici 2 à 3 jours ouvrés.",
      "acknowledgement_option_helper_text": "Quelque chose qui explique l’importance du traitement et informe l’utilisateur de la manière de programmer un appel si elle ou il change d’avis.",
      "acknowledgement_confirmed_header": "Reconnaissance confirmée",
      "acknowledgement_confirmed_description": "Quelque chose sur l’importance de suivre un traitement, avec des liens vers des ressources. Rappel que la consultation est complètement gratuite et que le/la soignant(e) peut fournir une prescription ou toute autre aide pour contribuer à résoudre l’infection.",
      "acknowledgement_confirmed_change_mind_text": "Si vous changez d’avis, vous n’avez qu’à cliquer sur « Je souhaite une consultation » ci-dessous.",
      "request_consultation_button_text": "Je souhaite une consultation"
    },
    "phone": "Téléphone",
    "source": "Source"
  },
  "required": {
    "errors": {
      "address_not_in_bounding_box": "Votre adresse ne correspond pas aux critères d'éligibilité requis par les sites participants de ce programme."
    }
  },
  "member": {
    "medical_history": "Historique",
    "view_instructions": "Voir la confirmation",
    "next_appointment": "Prochain rendez-vous",
    "over_18": "Je certifie que j'ai 18 ans ou plus.",
    "choose_test_configurations_title": "Veuillez choisir les services que vous voudriez pour ce rendez-vous.",
    "member_taken_error": "L'utilisateur possède déjà un compte",
    "choose_test_configurations_subtitle": "Ces services seront maintenus pour tous les membres de la domicile.",
    "service": "Service",
    "group_or_location": "Groupe/Emplacement",
    "details": "Détails",
    "see_all": "Voir tout",
    "no_history": "Il n'y a pas d'historique disponible pour le moment.",
    "medical_history_title_with_name": "{{name}}'s historique",
    "no_dashboard": "Il n'y a rien pour le moment",
    "product": "Produit",
    "price": "Prix",
    "quantity": "Quantité",
    "total_services_selected": "Total des services sélectionnés",
    "total_price": "Prix total"
  },
  "or": "ou",
  "account": {
    "errors": {
      "must_be_13": "Il faut avoir un minimum de 13 ans pour créer un compte."
    }
  },
  "self_administration": {
    "title": "Auto-administration",
    "self_administer_action": "Auto-administrer",
    "skip_to_link": "Passer à l'auto-administration",
    "select_person": "Choississez quelqu'un à valider",
    "adult": "adulte",
    "child": "enfant",
    "checked_out": "VALIDÉ",
    "go_back": "Retourner",
    "switch_to_scanner": "Passer au scanner",
    "enter_barcode": "Entrer code-barres",
    "scan_barcode": "Scanner code-barres",
    "cancel": "Canceller",
    "barcode_for": "Code-barres pour",
    "enter_barcode_manually": "Inscrire code-barres manuellement",
    "instructions": "Scanner ou entrer le code-barres situé sur votre éprouvette ci-dessous",
    "regex_prefix": "Le code à barres doit comprendre",
    "all_completed": "Terminé : Tous les membres du ménage ont terminé cette étape",
    "errors": {
      "no_appointment": "Problème de localisation du rendez-vous",
      "barcode_in_use": "Ce code-barres a déjà été utilisé. Si vous n’avez pas déjà utilisé cette trousse vous-même, veuillez en parler au personnel du site qui vous a donné la trousse et demandez-en une nouvelle. Sinon, cliquez sur le bouton d’aide ci-dessous.",
      "test_error_general": "Problème pendant la création du test",
      "different_barcodes": "Les codes-barres que vous avez entrés ne correspondent pas."
    },
    "confirm_barcode_input": "Entrez de nouveau le code-barres pour confirmer.",
    "click_to_self_test": "\r\n\r\nCliquez ici pour l’auto-test",
    "or_enter_barcode_manually": "Or enter manually with all letters and numbers"
  },
  "location_details": {
    "next_appointment": {
      "waitlist": "Liste en attente",
      "unavailable": "Aucun rendez-vous disponible"
    },
    "labels": {
      "address": "Adresse",
      "next_appointment": "Prochain rendez-vous disponible",
      "services": "Services disponibles"
    }
  },
  "waiting_room": {
    "header": "Vous êtes dans la salle d'attente ; veuillez patienter pendant que nous vous connectons avec le système d'horaires.",
    "p1": "Soyons patients - ensemble, nous pouvons mettre fin àcette pandémie.",
    "signature": "Cordialement",
    "p2": "Nous savons que l'expérience de la COVID-19 peut être frustrante. Veuillez patienter ici pendant que nous travaillons afin de vous connecter au site pour que vous puissiez fixer votre rendez-vous."
  },
  "users": {
    "send_appointment_confirmation_message": "Bonjour {{full_name}}. Ceci est un rappel pour le rendez-vous qui aura lieu à {{name}}, le {{date}} à {{time}}, à l'adresse {{address}}.",
    "send_registration_link": "Bonjour, {{full_name}}. Veuillez suivre ce lien afin de vous inscrire pour votre {{name}} {{registration_link}}"
  },
  "follow_up": {
    "first_dose": "Première dose",
    "previous_appointment": "Rendez-vous précédent",
    "booked_appointment": "Votre rendez-vous",
    "second_dose": "Deuxième dose",
    "choose_second_location": "Choississez un deuxième lieu pour votre rendez-vous"
  },
  "cancellation_types": {
    "cant_get_to_location": "J'avais un conflit d'horaire.",
    "timing_conflict": "J'ai attrapé la COVID-19.",
    "became_ill_with_covid19": "J'ai reçu ce service ailleurs",
    "received_service_elsewhere": "Autre",
    "other": "Saisissez plutôt le Nom",
    "duplicate_appointment": "Rendez-vous en double"
  },
  "translation": {
    "consent": {
      "type_name_instead": "Saisissez le Nom",
      "type_name": "Saisissez plutôt la signature"
    }
  },
  "email": {
    "confirmation": {
      "your_access_code_is": "Votre code d'accès est :",
      "please_confirm_your_email": "Veuillez confirmer votre Mail en cliquant sur le bouton ci-dessous",
      "hello": "Bonjour",
      "click_to_reschedule": "Cliquer ici pour consulter les indications/fixer à nouveau un rendez-vous",
      "click_to_reschedule_on_demand": "Cliquer ici pour consulter les indications"
    },
    "cancellation": {
      "your_appointment_has_been_canceled": "Votre rendez-vous à {{time}} à {{location}} a été annulé. S’il s’agit d’une erreur, merci d’écrire à support@primary.health"
    }
  },
  "signature_lines": {
    "name": "Nom du participant",
    "date": "Date et heure",
    "signature": "Signature du participant",
    "and_or": "ET/OU",
    "guardian_name": "Nom du parent/gardien",
    "guardian_signature": "Signature du parent/gardien"
  },
  "employer_testing": {
    "hello_name": "Bonjour {{name}}",
    "results": {
      "negative": "Négatif",
      "positive": "Positif",
      "did_not_result": "Inconnu",
      "unknown": "Inconnu",
      "invalid": "Invalide",
      "pending": "En attente"
    },
    "code_reader": {
      "scan_your_code": "Veuillez scanner le code trouvé sur votre carte de test",
      "find_in_top_right_hand_corner": "Le code à barres est unique à votre test ; on peut le trouver au coin supérieur droit de votre test.",
      "center_code": "Faites centrer le code ici.",
      "already_used_error": "Le code à barres de ce test a été déjà utilisé. Veuillez contacter l'équipe qui vous a fourni ce kit pour obtenir de l'assistance.",
      "click_to_scan": "Cliquez ici pour scanner votre code QR",
      "scan_new_test_card": "Cliquez ici pour scanner une nouvelle carte de test.",
      "not_working": "Ça ne fonctionne pas ?",
      "try_again": "Cliquez ici pour réessayer"
    },
    "continue": "Continuer",
    "verify_date_of_birth": {
      "confirm_date_of_birth": "Pour continuer, veuillez confirmer la date de naissance du participant.",
      "contact_administrator": "Si l'information affichée n'est pas correcte, veuillez contacter un administrateur pour recevoir de l'assistance",
      "error": "La date de naissance saisie n'est pas correcte - veuillez réessayer ou contacter l'administrateur du site pour la mettre à jour."
    },
    "result_entry": {
      "code_is_registered": "Votre code à été enregistré.",
      "take_the_test": "Passer le test",
      "follow_the_instructions": "Suivez bien les indications qui accompagnent votre test ; après avoir fermé l'écouvillon nasal dans la fiole trouvée sur la carte de test, vous pouvez commencer une minuterie de 15 minutes.",
      "cant_find_instructions": "Vous ne trouvez pas les indications ?",
      "view_instructions": "Voir les indications Binax NOW",
      "start_timer": "COMMENCER VOTRE MINUTERIE DE 15 MINUTES (OPTIONNEL)",
      "submit_within_15": "N.B. Soumettre vos résultats après 15 minutes.",
      "enter_test_results": "Saisissez les résultats du test",
      "choose_a_result": "Choississez l'option qui décrit le mieux le résultat de votre test affiché sur votre carte de test.",
      "submit": "Soumettre",
      "must_log_result_and_photo": "Vous devez choisir un résultat et prendre une photo du résultat de votre test pour pouvoir continuer",
      "submit_within_15_v2": "N.B. Soumettre vos résultats 15 minutes après que le test",
      "click_to_take_photo": "Cliquez pour prendre une photo",
      "results_may_be_invalid": "Il est possible que les résultats du test soient non-valides"
    },
    "summary": {
      "title": "Bilan de résultats.",
      "negative_subtitle": "Vous n'avez pas la COVID-19",
      "negative_message": "Nous avons envoyé une notification à votre employeur qui constate que vous êtes bien autorisé(e) à travailler sur place",
      "positive_subtitle": "Nous sommes désolé, mais vous avez la COVID-19",
      "positive_message": "Nous avons envoyé une notification à votre employeur qui constate que vous avez attrapé la COVID-19.",
      "what_should_you_do": "Que faire ?",
      "employer_name_resources": "Nom de ressources chez votre employeur",
      "follow_cdc_guidelines": "Suivez bien les directives et recommendations faites par le CDC."
    },
    "clarifying_results": {
      "title": "Clarification de vos résultats",
      "which_did_your_result_most_look_like": "À quoi correspondent vos résultats ?",
      "no_lines": "Aucune ligne visible",
      "blue_control_line_only": "Uniquement une ligne de contrôle bleue",
      "pink_sample_line_only": "Uniquement une ligne d'échantillon rose",
      "blue_control_line_and_pink_sample_line": "La ligne de contrôle bleue ET une ligne d'échantillon rose/violette",
      "still_not_sure": "Toujours pas certain(e)",
      "submit_results": "Soumettre vos résultats",
      "thank_you": "Merci !",
      "clarification_received_message": "La clarification de vos résultats a été reçue.",
      "only_pink_line": "Seulement ligne rose",
      "no_lines_v2": "Pas de lignes"
    },
    "return_to_confirmation": "Retourner à la page de Confirmation"
  },
  "preferred_language": {
    "title": "Langue préférée",
    "subtitle": "Quelle langue préférez-vous ?"
  },
  "appointment_type": {
    "initial_vaccine_appointment": "Série initiale de vaccins",
    "one_dose": "Fixer un rendez-vous pour un vaccin à dose spécifique . En particulier, ce rendez-vous correspond à ma :",
    "title": "Séléction de la dose",
    "first_dose": "Première dose",
    "second_dose": "Deuxième dose",
    "single_dose_title": "Une seule dose",
    "additional": "Additionnel",
    "booster": "Booster",
    "supplemental": "Supplémentaire",
    "third_dose": "Troisième dose seulement",
    "no_vaccine": "Aucune de ces réponses"
  },
  "activemodel": {
    "errors": {
      "models": {
        "importers/results/row": {
          "attributes": {
            "administered_at": {
              "invalid_datetime": "Le format de date devrait être : {{datetime_format}}"
            },
            "results_at": {
              "invalid_datetime": "Le format de date devrait être : {{datetime_format}}"
            },
            "uid": {
              "unknown_test": "test non-trouvé"
            }
          }
        },
        "importers/participants/row": {
          "attributes": {
            "access_code": {
              "not_allow_update_test_group_user": "l'usager existe et n'est pas autorisé à faire de mise à jour"
            },
            "date_of_birth": {
              "invalid_date": "Le format de date devrait être : {{datetime_format}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} n'existe(nt) pas ; choisissez parmi les options acceptables suivantes : {{ethnicities}}",
                "other": "{{unknown_ethnicities}} n'existe(nt) pas ; choisissez parmi les options acceptables suivantes : {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "le genre n'existe pas, choisissez parmi les options acceptables suivantes : {{genders}}"
            },
            "phone_number": {
              "invalid": "est un chiffre qui n'est pas valide"
            },
            "population_name": {
              "unknown_population": "{{unknown_population}} n'existe pas, choisissez parmi les options acceptables suivantes {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "ne peut pas être rempli si le genre n'est pas \"préfère_autoproclamer.\""
            },
            "sex": {
              "unknown_sex": "le sexe n'existe pas, choisissez parmi les options acceptables suivantes : {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "l'orientation_sexuelle n'existe pas, choisissez parmi les options acceptables suivantes : {{sexual_orientations}}"
            },
            "status": {
              "unknown_status": "le statut n'est pas valide, choisissez parmi les options acceptables suivantes : {{statuses}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} n'existe(nt) pas, choisissez parmi les options acceptables suivantes: {{races}}",
                "other": "{{unknown_races}} n'exist(nt) pas, choisissez parmi les options acceptables suivantes: {{races}}"
              }
            },
            "vaccination_status": {
              "unknown_vaccination_status": "vaccination status is invalid, pick from the following acceptable values: {{vaccination_statuses}}"
            }
          }
        },
        "importers/organization_user/row": {
          "attributes": {
            "role": {
              "insufficient_privileges": "droits insuffisants pour définir le rôle"
            },
            "user_id": {
              "unknown_user": "usager non-trouvé"
            }
          }
        },
        "importers/additional_information/row": {
          "attributes": {
            "additional_information": {
              "invalid_json": "JSON non valide"
            },
            "user_id": {
              "unknown_user": "usager non-trouvé"
            }
          }
        },
        "importers/emr/row": {
          "attributes": {
            "appointment_location": {
              "unknown_location": "lieu du rendez-vous introuvable"
            },
            "date_of_birth": {
              "invalid_date": "le format de date devrait être : {{datetime_format}}"
            },
            "email": {
              "duplicated": "est déjà utilisé"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} n'existe(nt) pas ; choisissez parmi les options acceptables suivantes : {{ethnicities}}",
                "other": "{{unknown_ethnicities}} n'existe(nt) pas ; choisissez parmi les options acceptables suivantes : {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "le genre n'existe pas, choisissez parmi les options acceptables suivantes : {{genders}}"
            },
            "phone_number": {
              "invalid": "n'est pas un chiffre valide"
            },
            "population": {
              "unknown_population": "{{unknown_population}} n'existe pas, choisissez parmi les options acceptables suivantes: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "ne peut pas être rempli si le genre n'est pas \"préfère_autoproclamer.\""
            },
            "sex": {
              "unknown_sex": "le sexe n'existe pas, choisissez parmi les options acceptables suivantes : {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "l'orientation_sexuelle n'existe pas, choisissez parmi les options acceptables suivantes : {{sexual_orientations}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} n'existe(nt) pas, choisissez parmi les options acceptables suivantes: {{races}}",
                "other": "{{unknown_races}} n'existe(nt) pas, choisissez parmi les options acceptables suivantes: {{races}}"
              }
            }
          }
        },
        "importers/additional_consents/row": {
          "attributes": {
            "consent_form_id": {
              "unknown_consent_form": "consent_form not found"
            },
            "access_code": {
              "unknown_user": "user not found"
            }
          }
        },
        "importers/vaccine/row": {
          "attributes": {
            "date_of_birth": {
              "invalid_date": "should have format {{date_format}}"
            },
            "phone_number": {
              "invalid": "is an invalid number"
            },
            "email": {
              "duplicated": "is already used"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} does not exist, pick from the following acceptable values: {{races}}",
                "other": "{{unknown_races}} do not exist, pick from the following acceptable values: {{races}}"
              }
            },
            "gender": {
              "unknown_gender": "gender does not exist, pick from the following acceptable values: {{genders}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation does not exist, pick from the following acceptable values: {{sexual_orientations}}"
            },
            "self_described_gender": {
              "not_allowed": "cannot fill it if the gender is not \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "sex does not exist, pick from the following acceptable values: {{sexes}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} does not exist, pick from the following acceptable values: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} do not exist, pick from the following acceptable values: {{ethnicities}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} is an invalid value, pick from the following acceptable values: {{tag_actions}}"
            },
            "population": {
              "unknown_population": "{{unknown_population}} does not exist, pick from the following acceptable values: {{population_names}}"
            },
            "appointment_location": {
              "unknown_location": "appointment location not found"
            },
            "appointment_date": {
              "invalid_date": "should have format {{date_format}}"
            },
            "dose_type": {
              "invalid_dose": "{{unknown_dose}} is not a valid dose, pick from the following acceptable values: {{acceptable_doses}}",
              "unavailable_dose": "{{dose_type}} is not available within the vaccine_lot, pick from the following available values: {{available_doses}}"
            },
            "vis_version_date": {
              "invalid_date": "should have format {{date_format}}"
            }
          }
        },
        "importers/base/row": {
          "attributes": {
            "appointment_time": {
              "invalid_time": "should have format {{time_format}}"
            }
          }
        }
      }
    }
  },
  "activerecord": {
    "errors": {
      "models": {
        "test_location": {
          "attributes": {
            "metadata": {
              "wrong_json": "is not a valid JSON"
            },
            "name": {
              "taken": "et l'adresse est déjà utilisé dans un autre lieu existant"
            }
          }
        }
      }
    },
    "attributes": {
      "organization_user": {
        "organization_user_leads": {
          "one": "User",
          "other": "Users"
        }
      },
      "appointment_slot_group": {
        "organization_user_leads": {
          "one": "Location",
          "other": "Locations"
        }
      }
    }
  },
  "hispanic_questions": {
    "hispanic": "Hispanique ou Latino/-a",
    "not_hispanic": "Non-Hispanique ou Latino/-a",
    "hispanic_expanded": {
      "other": "Autre origine Hispanique, Latino/-e ou hispanophone",
      "mexican": "Mexicain(e), Mexicain(e)-Americain(e), Chicano/-a",
      "puerto_rican": "Portoricain(e)",
      "cuban": "Cubain(e)"
    },
    "subtitle": "Les règlementations nous obligent à collécter toutes les informations suivantes",
    "help": "La description de l'ethnicité fait référence aux traits comme la langue, les coutumes culturelles, la religion, et d'autres caractéristiques qui sont utiliseés afin de distinguer différents groupes de personnes ; il se peut que cette définition corresponde, ou ne corresponde pas, à votre identité raciale.",
    "unknown": "Inconnu",
    "title": "Ethnicité"
  },
  "sex_at_birth": {
    "question": "Sexe",
    "female": "Femme",
    "male": "Homme",
    "help": "Le sexe fait référence à celui qui se trouve sur votre acte de naissance",
    "non_binary": "Nonbinaire",
    "subtitle": "Les règlementatios nous obligent à collécter toutes les informations suivantes.",
    "decline": "Refuser de répondre",
    "unknown": "Inconnu"
  },
  "gender": {
    "help": "Votre identité de genre est définie par la façon dont vous vous identifiez de manière personnelle. Il est possible que celle-ci s'aligne ou ne s'aligne pas au sexe qu'on vous a attribué à votre naissance.",
    "subtitle": "Si vous voulez ajouter de l'information supplémentaire par rapport à votre identité de genre, nous vous prions de le faire."
  },
  "sexual_orientation": {
    "title": "Orientation sexuelle",
    "subtitle": "Si vous voulez ajouter d'information supplémentaire par rapport à votre identité sexuelle, nous vous prions de le faire.",
    "gay": "Gay, lesbienne, ou homosexuel",
    "heterosexual": "Hétérosexuel ou \"hétéro\"",
    "bisexual": "Bisexuel",
    "questioning": "En questionnement/incertain(e)/je ne sais pas",
    "prefer_not_to_disclose": "Préfère ne pas divulguer",
    "unknown": "Inconnu",
    "orientation_not_listed": "Orientation non listée",
    "not_applicable": "Non applicable",
    "pansexual": "Pansexuel",
    "queer": "Queer"
  },
  "pronouns": {
    "title": "Pronoms préférés",
    "he": "Il/lui",
    "she": "Elle",
    "they": "Ellui/Elleux",
    "choose_not_to_disclose": "Préfère ne pas divulguer",
    "prefer_to_self_describe": "Préfère s'auto-décrire"
  },
  "no_insurance_information": {
    "driver_license_or_state_id": "Permis de conduire ou Carte d'identité (la carte de votre état, avec votre numero d'identité)",
    "driver_license": "Permis de conduire",
    "use_ssn_instead": "Utiliser votre numéro de sécurité sociale",
    "social_security_number": "Numero de sécurité sociale",
    "state": "État",
    "upload_front_of_driver_license": "Téléchargez le recto de votre permis de conduire ou le numéro d'identification de l'État.",
    "choose_file": "Choisir le fichier",
    "no_file_chosen": "Pas de fichier choisi",
    "no_identification": "Je n'ai pas d'identification",
    "insurance_card_has_a_back": "Ma carte d'assurance a un verso",
    "upload_front_of_insurance_card": "Télécharger le recto de votre carte d'assurance",
    "front_of_insurance_card_uploaded": "Le recto de votre carte d'assurance est téléchargé",
    "insurance_card": "Carte d'assurance",
    "insurance_card_back": "Verso de la carte d'assurance",
    "back_of_insurance_card_uploaded": "Le verso de votre carte d'assurance est téléchargé"
  },
  "quidel": {
    "certify_text": "Je confirme que je me connecte pour voir les résultats de ce test uniquement une seule fois.",
    "entering_results": "Soumettre vos résultats",
    "review_instructions_1": "Réviser attentivement les indications",
    "review_instructions_2": "Vous pouvez réviser les indications qui accompagnent votre test, lire un guide étape-par-étape, ou regarder une vidéo d'instruction ci-dessous :",
    "read_instructions": "Lire les indications",
    "watch_video": "Regarder la vidéo",
    "timer": "Commencer la minuterie après avoir remué l'écouvillon dans son tube. La minuterie s'arrêtra après une minute (pour que vous puissiez retirer l'écouvillon du tube). N.B. Vous devez relancer la minuterie pour commencer la durée de 10 minutes afin de compléter votre test.",
    "start_timer": "Commencer la minuterie (Optionnel)",
    "restart_timer": "Arrêter et Recommencer",
    "resume": "Relancer",
    "one_minute_countdown": "Une minute s'est écoulée ! Retirez bien l'écouvillon du tube et relancer la minuterie.",
    "take_photo": "Prenez une photo de votre bandelette de test",
    "photo_warning": "Il est necessaire de fournir une photo afin de poursuivre.",
    "enter_results": "Soumettre les résultats de test",
    "choose_result": "Choisissez l'option qui correspond le mieux à votre bandelette de test. Avez-vous besoin d'aide ? Cliquez ici pour voir des exemples de résultats de test.",
    "positive": "Positif",
    "negative": "Négatif",
    "unknown": "Inconnu",
    "clarify": "Clarification de vos résultats",
    "strip_question": "À quoi correspondait le plus votre bandelette?",
    "no_lines": "Aucune ligne",
    "pink_line": "Seulement une ligne rose",
    "not_sure": "Toujours incertain(e)"
  },
  "ihealth": {
    "take_a_photo_of_your_test": "Photo de résultat de test",
    "retake_photo": "Reprendre la photo",
    "capture_photo": "Prendre la photo",
    "confirm_information_correct": "Je confirme que les informations ci-dessus sont correctes.",
    "submit": "Envoyer",
    "title": "Saisissez les résultats pour {{name}}",
    "subtitle": "Vous n'avez pas besoin de télécharger ou d'utiliser l'application iHealth COVID-19.",
    "instruction_title": "Voir les instructions iHealth",
    "instruction_guide": "Lire les instructions",
    "video_guide": "Regarder la vidéo",
    "add_photo": "Ajouter photo",
    "confirm_result": "Confirmer le résultat",
    "result_warning": "Sélectionnez le résultat pour envoyer",
    "confirm_warning": "Confirmer le résultat pour envoyer",
    "administered_at_label": "Quand as-vous pris ce test?",
    "instruction_subtitle": "Consultez les instructions fournies avec votre test ou consultez les instructions ici."
  },
  "public_test_groups": {
    "title": "Kits de test pour COVID-19",
    "appointment_recoveries_title": "De retour sur notre site?",
    "appointment_recoveries_button_text": "Trouver mon lien",
    "search_test_group_title": "Est-ce votre première visite?",
    "search_test_group_button_text": "Inscrivez-vous maintenant",
    "title_2": "Inscription simple et communication",
    "step_one": "Enregistrement de compte",
    "step_two": "Collecter un échantillon de salive pour le renvoi",
    "step_three": "Voir les résultats en ligne",
    "title_3": "Inscrivez-vous pour activer votre kit",
    "population_title": "inscription",
    "population_button": "Activer",
    "faq_subtitle": "En savoir plus",
    "faq_title": "Questions fréquentes",
    "faq_1": "Comment dois-je renvoyer mon kit de test?",
    "faq_1_footer": "Afficher les instructions de Saliva Direct Funnel",
    "faq_2": "Je suis déjà inscrite mais j'ai oublié mes identifiants",
    "faq_3": "Comment puis-je activer mon kit?",
    "faq_2_subtitle": "En tant qu'utilisateur effectuant un renvoi, vous pouvez confirmer votre rapport à l''aide du bouton '**Trouver mon lien**' ci-dessus.",
    "faq_3_subtitle": "Veuillez suivre la procédure du compte Primary.Health à l'aide des options suivantes:",
    "footer_text_1": "Ce produit n'a pas reçu d''autorisation de la FDA et n'a pas été approuvé par celle-ci mais a reçu une autorisation d'urgence de la FDA dans le cadre d'une EUA (autorisation d''utilisation d''urgence);",
    "footer_text_2": "Ce produit a été autorisé uniquement pour la collecte et la conservation d''échantillons de salive et dans le but d'aider à la détection de l'acide nucléique du SARS-CoV-2, et non pour d'autres virus ou agents pathogènes ;",
    "footer_text_3": "L'utilisation en urgence de ce produit n'est autorisée que pour la durée de la déclaration stipulant que des circonstances justifiant l'utilisation d''urgence de dispositifs médicaux conformément à l'Article 564(b)(1) de la Federal Food, Drug and Cosmetic Act, 21 U.S.C. § 360bbb-3(b)(1) existent, sauf si la déclaration est résiliée ou si l'autorisation est révoquée avant l'expiration de cette durée.",
    "description": "Pour des raisons pratiques, vous pouvez activer votre kit de test à l'aide des boutons ci-dessous :",
    "subtitle": "Est-ce votre premier contact avec Primary?",
    "subtitle_1": "Au sujet de vos kits de test pour COVID-19",
    "faq_1_1": "Placez le tube d'échantillon fermé dans le sac d'échantillons biologiques",
    "faq_1_2": "Placez le sac d'échantillon biologique dans la boîte portant l'étiquette SalivaDirect dans laquelle vous l'avez reçu. Placez la boîte portant l'étiquette SalivaDirect dans lemballage FedEx UN 3373.",
    "faq_1_3": "Retirez la bande adhésive de l'emballage FedEx UN 3373 et appuyez fermement afin de bien refermer l'emballage.",
    "faq_1_4": "Veuillez déposer votre échantillon le jour même du prélèvement et avant l'heure ultime de collecte. Ne déposez pas votre échantillon dans une boîte de collecte pendant le weekend.",
    "faq_1_5": "Consultez le site internet de FedEx à l'adresse **[fedex.com/labreturns](https://www.fedex.com/labreturns)** pour afficher les emplacements des boîtes de collecte et les horaires de collecte.",
    "demo": {
      "title": "COVID-19 at-home test kit additional information & instructions",
      "online_instructions": "Online instructions",
      "download_instructions": "Download instruction sheet",
      "begin": "Begin",
      "instructions": {
        "welcome_title": "Welcome to the at-home COVID-19 testing portal.",
        "welcome_text_1": "Before you start testing, wash you hands or use hand sanitizer.",
        "welcome_text_2": "Make sure your hands are dry before starting.",
        "continue": "Continue",
        "back": "Back",
        "step_one_title": "Step 1: Check your test kit",
        "step_one_text_1": "Locate the kit components:",
        "step_one_text_2": "It is recommended gloves (not provided) also be used during testing.",
        "step_two_title": "Step 2: Place tube in the tube holder",
        "step_two_text_1": "Remove cap from one **TUBE** and place it in the **TUBE HOLDER.**",
        "step_two_text_2": "**NOTE:** Use of gloves is recommended.",
        "step_five_title": "Step 5: Remove swab from the tube",
        "step_five_text_1": "After **ONE MINUTE**, remove the swab from the **TUBE** by rubbing the swab head against the inside wall of the tube to squeeze out as much liquid as possible.",
        "step_five_text_2": "Dispose of swab in the trash.",
        "step_six_title": "Step 6: Open the test strip",
        "step_six_text_1": "Open the **TEST STRIP** pouch carefully at the slit and hold the **TEST STRIP** as indicated.",
        "step_seven_title": "Step 7: Place test strip in the tube",
        "step_seven_text_1": "Place the **TEST STRIP** into the **TUBE** with the arrows pointing down. Leave the strip in the **TUBE** for a **FULL TEN MINUTES**– do not handle or remove.",
        "step_eight_title": "Step 8: Remove test strip from the tube",
        "step_eight_text_1": "At **TEN MINUTES**, remove the **TEST STRIP** from the **TUBE**. Next, place the **TEST STRIP** on the outline in Steps 10-12. Ensure the **TEST STRIP** is on a flat surface in good lighting.",
        "step_eight_text_2": "**Note:** The test is intended to be read at 10 minutes. If the test is read before this or is read more than 5 minutes after the indicated read time, results may be inaccurate (false negative, false positive, or invalid) and the test should be repeated.",
        "step_nine_title": "Step 9: Check your results",
        "step_nine_text_1": "There are three type of results possible.",
        "step_nine_text_2": "Check for a Positive Result",
        "step_nine_text_3": "Check for a Negative Result",
        "step_nine_text_4": "Check for an Invalid Result",
        "step_ten_title": "Step 10: Remove test strip from the tube",
        "step_ten_text_1": "Place the **TEST STRIP** on the test strip outline below and compare with test result examples shown.",
        "step_three_title": "Step 3: Swab the nostrils",
        "step_three_text_1": "Remove the **SWAB** from its wrapper, being careful not to touch the **SWAB** head.",
        "step_three_text_2": "Gently insert the **SWAB** ½ to ¾ of an inch into the nostril, depending on the size of the person’s nose. Firmly rub the **SWAB** in a circular motion around the inside wall of EACH NOSTRIL at least 4 times.",
        "step_three_text_3": "Be sure to rub BOTH nostrils with the SAME SWAB.",
        "step_three_text_5": "**NOTE:** Failure to swab properly may cause false negative results.",
        "step_four_title": "Step 4: Place swab in the tube",
        "step_four_text_1": "Immediately place the **SWAB** into the liquid inside the **TUBE**, and ensure it is touching the bottom. Stir 3-4 times.",
        "step_four_text_2": "Leave the swab in the solution for **ONE MINUTE**.",
        "step_four_text_3": "**NOTE**: If the swab is in the solution for more than 10-minutes it should not be used.",
        "step_three_text_4": "**NOTE:** If you are swabbing others, please wear a face mask. With children, you may not need to insert the swab as far into the nostril. For very young children, you may need another person to steady the child’s head while swabbing."
      },
      "choose_language": "Choose your language"
    }
  },
  "self_resulting": {
    "report_result": "Signaler un résultat",
    "report_result_subtitle": "Si votre organisme offre des auto-tests et que vous voulez signaler un résultat, cliquez sur le bouton ci-dessous",
    "need_help": "Besoin d'aide?",
    "assistance": "Si vous avez besoin d'aide ou vous voulez modifier vos coordonnées, veuillez contacter le centre d'assistance.",
    "contact_support": "Contacter le centre d'assistance",
    "save_link": "Enregistrer ce lien pour signaler les résultats plus tard.",
    "send_via_email": "Envoyer ce lien par e-mail",
    "send_via_text": "Envoyer ce lien par SMS",
    "copy_link": "Copier le lien",
    "resend_email": "Envoyer une vérification par e-mail",
    "phone_verified": "Téléphone vérifié",
    "add_vaccination_status": "Ajouter/Mettre à jour le statut de vaccination COVID-19",
    "terms_of_service": "Conditions d'utilisation",
    "verify_contact_information": "Vérifiez vos coordonnées afin de pouvoir recevoir les résultats et les communications. Si vous devez modifier vos coordonnées, veuillez contacter notre centre d'assistance.",
    "resend_text": "Renvoyer un SMS de vérification",
    "loading": "Téléchargement de",
    "add_to_account": "Ajouter au compte",
    "hi": "Bonjour",
    "email_verified": "Email vérifié",
    "go_to_my_account": "Accéder à mon compte",
    "activate_kit": "Activer une trousse de test",
    "report_custom_result": "Rapporter un résultat: {{test}}",
    "activate_custom_test": "Activer un test: {{test}}",
    "update_vaccination_status": "Actualiser le statut de vaccination",
    "order_a_test_kit": "Commander un kit de test"
  },
  "enter_information": {
    "contact_info": "Entrez vos coordonnées",
    "label": "Téléphone ou e-mail",
    "invalid": "E-mail ou numéro de téléphone non valide.",
    "send": "Nous vous enverrons un code sur votre téléphone ou votre e-mail.",
    "legal": "En saisissant votre numéro de téléphone ou votre adresse électronique, vous acceptez les **[Conditions d'utilisation](https://primary.health/terms-of-service/)** et la **[Politique de confidentialité](https://primary.health/privacy-policy/)** de Primary Health.",
    "subtitle": "Nous vous enverrons un SMS ou un e-mail pour vous aider à trouver vos dossiers ou un lien pour signaler un résultat de test !",
    "find_link": "Enregistré(e) ? Accéder à votre lien",
    "not_registered": "Pas inscrit(e) ?",
    "search_by_keyword": "Recherchez par nom, mot-clé, ville, code postal ou code de classement !",
    "search": "Rechercher",
    "register_here": "S’inscrire"
  },
  "contact_support": {
    "invalid": "Informations non valides",
    "invalid_explanation": "Vous avez récemment demandé un lien de connexion à Primary.Health. Cependant,",
    "never_registered": "Jamais inscrit(e) auparavant ? Veuillez contacter votre fournisseur de tests pour obtenir un lien d'inscription, ou trouver votre site ci-dessous.",
    "get_help": "Vous pouvez obtenir de l'aide pour faire correspondre votre dossier à vos coordonnées avec",
    "get_help_calling": "ou en appelant",
    "invalid_explanation_bold": "les informations fournies ne sont pas valides.",
    "get_help_label": "Obtenir de l'aide",
    "mobile": {
      "invalid_explanation": "Aucun utilisateur n'a été trouvé qui pourrait être ajouté à votre compte.",
      "get_help": "Si vous avez des questions supplémentaires, veuillez contactez",
      "reason": "Tous les utilisateurs de Primary.Health ne sont pas éligibles pour notre nouvelle fonction Comptes. Une fois que vous êtes éligible, vous recevrez une invitation de Primary.Health ou de l'administrateur de votre groupe."
    },
    "zen_name": "Nom du contact",
    "ticket_type": "À quoi est-ce relatif ?",
    "appointment_access_code": "Code de confirmation (si connu)",
    "zen_desc": "Message",
    "contact_us": "Contactez-nous",
    "need_assistance": "Pour obtenir de l’aide ou modifier vos coordonnées, merci de contacter le service d’aide."
  },
  "show_appointments": {
    "welcome": "Trouver votre profil",
    "welcome_subtitle_1": "Les résultats s’affichent ci-dessous pour",
    "added_to_account": "Ajouté(e) au compte",
    "error": "Ce participant a peut-être déjà un compte",
    "welcome_not_logged_in": "Lien de site trouvé",
    "welcome_logged_in": "Choisir votre groupe",
    "welcome_subtitle_1_logged_in": "Pour finir l’ajout d’un membre, sélectionner l’utilisateur et le groupe. Les membres sont assignés à un seul compte.",
    "welcome_subtitle_2_logged_in": "Si vous ne voyez pas votre numéro ci-dessus, vous pouvez",
    "welcome_subtitle_1_not_logged_in": "Choisir qui a besoin de localiser son site ou d’ajouter un nouveau numéro. Membre non trouvé ?",
    "welcome_subtitle_3_logged_in": "essayer un moyen de contact différent",
    "welcome_subtitle_4_logged_in": "ou nous contacter à",
    "welcome_subtitle_2_not_logged_in": "Mettre à jour la recherche.",
    "welcome_subtitle_2": "Vous ne voyez pas le nom ci-dessous ?",
    "welcome_subtitle_3": "Mettre à jour votre recherche"
  },
  "enter_code": {
    "code": "Quel est le code?",
    "enter_code": "Entrez le code envoyé à",
    "incorrect_code": "Vous avez saisi un code incorrect.",
    "verify": "Vérifir le compte",
    "verified": "Vérifié",
    "verify_account_information": "Vérifier votre compte"
  },
  "general_self_checkout": {
    "choose_test": "Quel test avez-vous fait?",
    "photo_required": "Photo du résultat requise",
    "find_your_test": "Trouver votre test"
  },
  "login": {
    "create_account": "Créer un compte",
    "create_account_help": "La création d'un compte est différente de l'inscription pour le dépistage ou les vaccinations. Une fois que vous vous êtes inscrit(e)",
    "create_account_help_schedule": "Prendre des rendez-vous",
    "create_account_help_record": "Enregistrer des tests faits à domicile",
    "create_account_help_match": "Gérer les carnets de vaccination et de tests.",
    "continue": "Continuer comme invité",
    "already": "Avez-vous déjà un compte ? Cliquez sur",
    "login": "pour ouvrir une session.",
    "or_sign_in": "Ou ouvrir une session avec:",
    "no_account": "Pas de compte ? Cliquer",
    "signup": "pour vous inscrire.",
    "here": "ici",
    "email_address": "Adresse e-mail :",
    "password": "Mot de passe :",
    "complete_registration": "Compléter l'inscription",
    "last_covid_test": "Dernier résultat de test COVID-19",
    "no_record": "Pas d'enregistrement",
    "viewing_information": "Visualisation des informations de {{first_name}}",
    "download": "Télécharger",
    "not_registered": "Il semble que vous ne soyez inscrit.e à aucun groupe",
    "please_follow_link": "Veuillez suivre le lien d'invitation de votre e-mail pour vous inscrire et accéder à vos informations avec Primary.Health.",
    "log_results": "Enregistrer les résultats de mes tests",
    "book_appointment": "Prendre un rendez-vous",
    "no_new_appointments": "Actuellement, nous n'acceptons pas de nouveaux rendez-vous",
    "upload_vaccinations": "Télécharger les vaccins",
    "vaccination": "Vaccination",
    "new_appointment": "Nouveau rendez-vous",
    "log_otc_results": "Enregistrer les résultats d'un test OTC",
    "no_household_members": "Vous n'avez aucun membre du foyer.",
    "no_upcoming": "Pas de rendez-vous à venir",
    "update_vaccination": "Mettre à jour la vaccination",
    "upload_vaccination": "Télécharger la vaccination",
    "title": "Bienvenue à Primary.Health",
    "sub_title": "Before registering please create an account.",
    "p1": "Créer un compte vous permet de :",
    "p2": "Prende de futurs rendez-vous",
    "p3": "Enregistrer les tests COVID-19 faits à la maison",
    "p4": "Gérer les enregistrements de vaccination et de tests des personnes à charge",
    "p5": "Processus de connexion simple à partir de my.primary.health",
    "no_vaccination_record": "Aucun vaccin ajouté pour le moment",
    "vaccination_title_card": "Statut vaccinal Covid-19",
    "account_and_settings": "Compte & paramètres",
    "delete_account": "Supprimer le compte",
    "language": "Langue",
    "language_current": "Langue actuelle : {{language}}",
    "log_out": "Déconnexion",
    "delete_account_title": "Êtes-vous sûr de vouloir supprimer votre compte ?",
    "new_encounter": "La nouvelle rencontre de {{first_name}}",
    "new_encounter_subtitle": "À quel groupe s'adresse cette nouvelle rencontre ?",
    "no_programs": "Il semble que vous n'êtes inscrit à aucun programme",
    "edit_members": "Modifier les membres",
    "edit_members_subtitle": "Sélectionnez le tableau de bord auquel vous souhaitez accéder ou ajoutez un autre membre à votre compte",
    "add_members": "Ajouter un membre",
    "delete_member": "Effacer le compte d'utilisateur de {{full_name}} ?",
    "delete_member_subtitle": "La suppression de l'utilisateur supprimera toutes les informations associées au compte.",
    "select_member": "Sélection des membres",
    "edit_appointment": "Modifier le rendez-vous",
    "route": {
      "me": "Tableau de bord",
      "history": "Histoire",
      "programs": "Ajouter un test",
      "contact_us": "Aider",
      "choose_member": "Membre",
      "settings": "Réglages",
      "help": "Aider"
    },
    "user_dashboard": "Tableau de bord de {{first_name}}",
    "view_full_results": "Afficher dans le portail des résultats",
    "required": "Obligatoire si vous utilisez l'application Primary.",
    "no_email": "Je ne possède pas d'adresse électronique"
  },
  "vaccination_status": {
    "not_vaccinated": "Pas de vaccination",
    "fully_vaccinated": "Vaccination complète",
    "partially_vaccinated": "Vaccination partielle",
    "vaccination_records": "Carnet de vaccination",
    "title": "Quel est votre statut vaccinal contre le COVID-19 ?",
    "definition_title": "Définitions du statut vaccinal",
    "definition_cdc": "Définition du statut vaccinal selon les CDC",
    "definition_osha": "Définition du statut vaccinal selon l'OSHA",
    "definition": {
      "fully_vaccinated": "Deux semaines après la dernière dose d'une série de deux doses des vaccins Pfizer-BioNTech ou Moderna, ou une dose du vaccin Janssen de Johnson & Johnson.",
      "partially_vaccinated": "Une seule des deux doses d'une série primaire a été administrée, ou moins de deux semaines après avoir reçu la dernière dose d'une série de deux doses ou une dose du vaccin Janssen de Johnson & Johnson.",
      "not_vaccinated": "Aucun vaccin contre le COVID-19 n'a été administré.",
      "fully_vaccinated_with_one_booster": "Une dose de rappel des vaccins Pfizer-BioNTech ou Moderna a été administrée après la série complète ou après une dose du vaccin Janssen de Johnson & Johnson.",
      "fully_vaccinated_with_two_boosters": "Une deuxième dose de rappel des vaccins Pfizer-BioNTech ou Moderna a été administrée après la série complète ou une dose du vaccin Janssen de Johnson & Johnson et une dose de rappel."
    },
    "label": {
      "fully_vaccinated": "Entièrement vacciné(e)",
      "partially_vaccinated": "Partiellement vacciné(e)",
      "not_vaccinated": "Non vacciné(e)",
      "fully_vaccinated_with_one_booster": "Entièrement vacciné(e) + rappel",
      "fully_vaccinated_with_two_boosters": "Entièrement vacciné(e) + 2 rappels",
      "prefer_not_to_answer": "Préfère ne pas répondre"
    },
    "progress_bar_title": "Schéma de vaccination contre le COVID-19",
    "upload_record_card": "Téléversez votre carnet de vaccination contre le COVID-19",
    "show_example": "Afficher le modèle",
    "hide_example": "Masquer le modèle",
    "take_photo": "Cliquez pour prendre une photo",
    "or": "OU",
    "upload_image_or_pdf_instead": "Téléversez plutôt une image/PDF",
    "select_doses_received": "Sélectionnez toutes les doses de vaccin anti-Covid-19 que vous avez reçues.",
    "first_dose": "Première dose",
    "second_dose": "Deuxième dose",
    "first_booster_dose": "Première dose de rappel",
    "second_booster_dose": "Deuxième dose de rappel",
    "additional_dose": "Dose supplémentaire",
    "first_dose_manufacturer": "Qui était le fabricant du vaccin anti-Covid-19 de votre première dose ?",
    "first_dose_date": "Quelle était la date d'administration de votre première dose ?",
    "second_dose_manufacturer": "Qui était le fabricant du vaccin anti-Covid-19 de votre deuxième dose ?",
    "second_dose_date": "Quelle était la date d'administration de votre deuxième dose ?",
    "first_booster_dose_manufacturer": "Qui était le fabricant du vaccin anti-Covid-19 de votre première dose de rappel ?",
    "first_booster_dose_date": "Quelle était la date d'administration de votre première dose de rappel ?",
    "second_booster_dose_manufacturer": "Qui était le fabricant du vaccin anti-Covid-19 de votre deuxième dose de rappel ?",
    "second_booster_dose_date": "Quelle était la date d'administration de votre deuxième dose de rappel ?",
    "additional_dose_manufacturer": "Qui était le fabricant du vaccin anti-Covid-19 de votre dose supplémentaire ?",
    "additional_dose_date": "Quelle était la date d'administration de votre dose supplémentaire ?",
    "completed_one": "Complété(e)",
    "completed_two": "Merci de mettre à jour votre statut vaccinal !",
    "progress_bar_complete": "Compléter",
    "upload_image": "Téléverser l'image",
    "retake_photo": "Prendre une nouvelle photo",
    "other": "Autre",
    "remove_first_dose": "Supprimer le shéma vaccinal de la première dose",
    "remove_second_dose": "Supprimer le shéma vaccinal de la deuxième dose",
    "remove_first_booster_dose": "Supprimer le shéma vaccinal de la première dose de rappel",
    "remove_second_booster_dose": "Supprimer le shéma vaccinal de la deuxième dose de rappel",
    "remove_additional_dose": "Supprimer le shéma vaccinal de la dose supplémentaire",
    "exemption": "Disposez-vous d'une exemption ?",
    "exempt": "Je bénéficie d'une exemption",
    "not_exempt": "Je ne dispose pas d'une exemption",
    "enter_exemption": "Saisir le motif de l'exemption",
    "upload_exemption_documentation": "Téléverser les justificatifs de votre exemption",
    "remove_dose": "Supprimer la dose",
    "continue": "Continuer",
    "enter_credentials": "Veuillez saisir vos coordonnées",
    "incorrect_credentials": "Vos coordonnées sont incorrectes, veuillez réessayer.",
    "add_photo": "Ajouter une photo"
  },
  "mailers": {
    "appointment_mailer": {
      "payment_pending": "Bonjour {{name}}, Vous n'avez pas fini de prendre votre  rendez-vous pour {{group_name}} avec {{org_name}}. Pour le conserver, vous devez faire le paiement. Cliquez sur le lien pour terminer le processus {{complete_url}}. Pour annuler, cliquez sur le lien {{cancel_url}}. Merci"
    }
  },
  "otc": {
    "record_results_for_abbott": "Faire le test et enregistrer vos résultats pour le test BinaxNOW",
    "record_results": "Faire le test et enregistrer vos résultats",
    "activate_a_kit": "Activer une trousse",
    "activate_a_kit_for_test": "Activer une trousse pour le  {{test}}",
    "todays_test_process": "Traitement du test d'aujourd'hui:",
    "date_of_birth_question": "Quelle est la date de naissance de  {{first_name}}?",
    "confirm_dob_to_continue": "Veuillez confirmer la date de naissance du participant pour continuer:",
    "incorrect_dob": "Date de naissance incorrecte",
    "file_too_large": "Assurez-vous que la taille du fichier téléchargé est inférieure à {{size}}",
    "enter_valid_date_in_last_x_days": "Saisir une date valide dans les derniers {{n}} jours",
    "barcode_format_not_valid": "Le format du code barre n'est pas valide",
    "complete_button": "Remplir",
    "next_button": "Suivant",
    "back_button": "Retour",
    "report_a_test": "Rapporter un test:",
    "x_%_completed": "{{n}}% complétude",
    "answer_survey": "Répondre aux questions du sondage",
    "steps": {
      "answer_survey": "Répondre au sondage",
      "review_instructions": "Revoir les instructions",
      "scan_code": "Numériser le code",
      "enter_time": "Saisir l'heure",
      "enter_result": "Saisir le résultat",
      "take_photo": "Prendre une photo",
      "confirm_result": "Confirmer le résultat",
      "selest_test_kit": "Choisir le type de test",
      "enter_date": "Saisir la date",
      "enter_date_and_time": "Saisir la date et l'heure"
    },
    "review_instructions": "Revoir les instructions du test",
    "follow_instructions_or_below": "Suivre les instructions qui accompagnent votre test ou revoir les instructions ci-dessous.",
    "watch_video": "Regarder la vidéo",
    "view_instructions": "Regarder les instructions",
    "follow_instructions": "Suivre les instructions qui accompagnent votre test.",
    "scan_code": "Numériser le code QR du test",
    "qr_code_description": "Le code barre est spécifique à votre test et apparaît dans le coin supérieur droit de la boîte",
    "show_example": "Montrer un exemple",
    "hide_example": "Cacher l'exemple",
    "barcode_scanned": "Code barre bien numérisé",
    "enter_barcode": "Saisir un code barre",
    "scan_qr_code": "Numériser le code QR",
    "which_test_did_you_take": "Quel test avez-vous fait?",
    "when_did_you_take_test": "Quand avez-vous fait le test?",
    "what_day_did_you_take_test": "Quel jour avez-vous fait le test?",
    "what_time_did_you_take_test": "A quelle heure avez-vous fait le test?",
    "time": {
      "today": "Aujourd'hui",
      "yesterday": "Hier",
      "two_days_ago": "Il y a deux jours"
    },
    "enter_current_time": "Saisir l'heure actuelle",
    "enter_result": "Saisir le résultat du test",
    "choose_result_option": "Choisissez l' option qui décrit le mieux le résultat de votre fiche de test:",
    "clarify_results": "Clarifiez vos résultats",
    "take_result_photo": "Prendre une photo des résultats",
    "take_result_photo_description": "Faire une photo de la fiche de résultats remplie pour enregistrer les résultats.",
    "my_result": "Mes résultats",
    "switch_camera": "Mettre en route la caméra",
    "confirm_result": "Confirmer le résultat",
    "name": "Nom:",
    "date": "Date :",
    "result": "Résultat:",
    "results": "Résultats",
    "test_submitted": "Merci, {{first_name}}! Votre {{test}} a bien été soumis.",
    "dob": "Date de naissance:",
    "administered": "Administré:",
    "or": "OU",
    "upload": "Télécharger",
    "change_uploaded_file": "Changer le fichier téléchargé",
    "take_photo": "Prendre une photo",
    "confirm_information": "Confirmer les renseignements",
    "barcode": "Code barre:",
    "scan_test_barcode": "Numériser le code QR du test",
    "barcode_description": "Le code barre est spécifique à votre test; il est affiché sur la boîte",
    "enter_date_in_last_30_days": "Saisir une date dans les 30 derniers jours et l'heure approximative de l'administration du test",
    "add_image": "Ajouter une image",
    "change_image": "Changer l'image",
    "skip_barcode_reader": "Impossible de numériser le code? Cliquer sur Suivant pour rapporter quand même les résultats du test",
    "enter_date_and_time": "Saisir la date et l'heure du test",
    "enter_date": "Saisir la date du test",
    "did_you_take_test_today": "Est-ce qui avez fait le test, aujourd'hui?",
    "record_results_for_generic": "Tester et enregistrer vos résultats pour le test {{name}}",
    "choose_result_option_custom": "Choisir l’option qui décrit votre résultat pour le test suivant : {{test_name}}"
  },
  "yes": "Oui",
  "no": "Non",
  "event_token": {
    "title": "Veuillez saisir le code ci-dessous dans le distributeur pour obtenir votre trousse de test",
    "loading_text": "Chargement, veuillez attendre une minute"
  },
  "appointment_recovery": {
    "complete_title": "Vous êtes prêt !",
    "subtitle_1": "Si vous utilisez un iPhone, veuillez appuyer sur **Terminé** dans le coin supérieur gauche de cet écran pour revenir à votre tableau de bord.",
    "subtitle_2": "Si vous utilisez un Android, appuyez sur le **X** dans le coin supérieur gauche de cet écran.",
    "subtitle_0": "Veuillez quitter le navigateur pour continuer."
  },
  "primary": {
    "support_email": "support@primary.health"
  },
  "consented_relationship": "Relation consentie",
  "finish_registration": {
    "title": "Terminer votre inscription",
    "subtitle": "Veuillez renseigner les informations suivantes avant votre rendez-vous :",
    "button": "Finir l’inscription"
  },
  "arab_ethnicity": {
    "arab": "Arabe",
    "non_arab": "Non-arabe",
    "prefer_not_to_answer": "Préfère ne pas répondre",
    "question": "Ethnicité arabe",
    "subtitle": "Nous devons, comme l'exige la réglementation, recueillir les informations suivantes.",
    "help": "L'ethnicité désigne un ensemble de traits communs tels que la langue, les pratiques culturelles, la religion et d'autres caractéristiques utilisées pour différencier des groupes de personnes. Elle peut également s'aligner ou non à une identité raciale."
  },
  "custom_comms_quick_add": {
    "mobile_app_links": {
      "email_text": "En fonction de votre appareil, cliquer sur le lien ci-dessous pour télécharger le Pass de santé primaire Primary Health Pass."
    }
  },
  "stepper": {
    "next": "Suivant",
    "back": "Retour",
    "complete": "Terminer"
  },
  "registration_direcory": {
    "keyword_search_label": "Mot clé, ville, code postal, nom de l’organisation",
    "keyword_search_label_mobile": "Ville, code postal, nom de l’organisation",
    "zero_results": {
      "title": "Nous n’avons trouvé aucun soignant avec ce terme de recherche.",
      "sub_title": "Essayez de chercher votre soignant par :",
      "p1": "Code postal",
      "p2": "Ville ou village",
      "p3": "Comté",
      "p4": "Nom du soignant ou de l’organisation"
    }
  },
  "registration_flyer": {
    "open_camera": "Ouvrez l'application caméra sur votre appareil",
    "point_to_qr_code": "Pointez sur le code QR et cliquez sur le lien",
    "complete_registration": "Terminez l'enregistrement",
    "need_help": "Vous avez besoin d'aide ? Contactez-nous sur"
  },
  "vaccine_info_sheets": {
    "vaccine_info_attestation": "Attestation d’informations vaccinales",
    "select_checkbox": "Merci d’examiner les informations suivantes et de cocher la bonne case ci-dessous avant de continuer.",
    "opportunity_to_read": "J’ai lu, on m’a donné la possibilité de lire ou on m’a lu la fiche de déclaration(s) d’informations sur un vaccin (« VIS ») ou la fiche d’autorisation d’utilisation d’urgence (« EUA ») fournie pour le(s) vaccin(s) à administrer :",
    "vaccine_info_sheet": "Fiche d’informations sur le vaccin",
    "vaccine_fact_sheet": "Fiche descriptive sur le vaccin",
    "vaccine_info_statement": "Déclaration d’informations sur le vaccin"
  },
  "exemption": {
    "dtap_tdap": "DTCa/TdCa",
    "mmr": "ROR",
    "ipv_opv": "Polio",
    "hib": "Hib",
    "hep_b": "Hépatite B",
    "var": "Varicelle",
    "hep_a": "Hépatite A",
    "pcv": "VPC",
    "mcv": "Méningocoque"
  },
  "test_strip": {
    "A": "this kit includes tests for chlamydia and gonorrhea.",
    "B": "this kit includes tests for chlamydia and gonorrhea.",
    "C": "this kit includes tests for syphilis.",
    "D": "this kit includes tests for HIV, syphilis, chlamydia and gonorrhea.",
    "E": "this kit includes tests for HIV and syphilis.",
    "box_type": "Box {{type}}",
    "administered_text": "We will contact you when your kit reaches the lab and when your results are ready.",
    "activate_kit": "Activate my test kit",
    "register_another_test": "Register another test",
    "credentials": {
      "title": "Let's get started",
      "label": "Contact",
      "p1": "We'll start with some info about you.",
      "location_step": {
        "p1": "Who provided your test kit?",
        "load": "Load more"
      }
    },
    "checklist": {
      "not_urinated": "You have not urinated in the last hour.",
      "bathroom": "You have access to a bathroom for urine collection.",
      "anal_swab": "You have access to a private place to perform an anal swab.",
      "blood_extraction": "You have access to a clean environment for blood extraction.",
      "washed_hands": "You have washed your hands with soap and warm water for 30 seconds.",
      "title": "Are you ready to begin sample collection?",
      "no_alcohol": "Do not drink alcohol on the day of collection.",
      "menstruation": "Do not collect vaginal swab specimen during menstruation or within 24 hours of intercourse."
    },
    "confirm": {
      "title": "Confirm your information",
      "p1": "Is this information correct?"
    },
    "display_name": {
      "A": "Chlamydia & Gonorrhea (Single Site)",
      "B": "Chlamydia & Gonorrhea (3 Site)",
      "C": "Syphilis",
      "shortened": {
        "A": "Chlamydia & Gonorrhea",
        "B": "Chlamydia & Gonorrhea",
        "C": "Syphilis"
      },
      "CC": "COVID-19",
      "CE": "Celiac Disease Screening",
      "CR": "Creatinine Test",
      "D": "4 Panel Test - HIV, Syphilis, Chlamydia & Gonorrhea (Single Site)",
      "DD": "Diabetes Screening (HbA1c)",
      "E": "HIV & Syphilis",
      "H": "HPV",
      "P": "Hepatitis C",
      "HS": "Herpes Simplex",
      "I": "HIV",
      "TT": "Testosterone Level",
      "VD": "Vitamin D",
      "VS": "Viral Surveillance",
      "AP": "Chlamydia & Gonorrhea",
      "NN": "COVID-19",
      "HH": "COVID-19, Flu A/B, & RSV",
      "FFFWW": "COVID-19 & Flu A/B",
      "YYYWW": "COVID-19, Flu A/B & RSV",
      "HA": "COVID-19, Flu A/B, & RSV"
    },
    "rescan": "Rescan",
    "scanned": "You've scanned Box {{type}}",
    "not_yet": "Not Yet",
    "success_registration": "Registration success!",
    "no_box": "Don't have a box?",
    "faq_link": "Visit FAQs",
    "welcome": "Welcome!",
    "scan": {
      "title": "You're one step closer to a healthier you.",
      "p1": "Scan or enter the **kit bar code** on the side of your box."
    },
    "appointments": {
      "title": "Select the patient & provider",
      "p1": "We found more than one profile associated with your contact information. Select the correct profile:",
      "new": "New patient or provider"
    },
    "verify": {
      "title": "Confirm your identity",
      "p1": "We found your patient record."
    },
    "section_1": "Confirm kit contents",
    "section_2": "Test collection",
    "section_3": "Packaging",
    "section_4": "Shipping",
    "box_a": "Box A",
    "box_b": "Box B",
    "box_c": "Box C",
    "kit_flow": {
      "button_1": "Continue to packaging",
      "button_2": "I'm ready",
      "button_3": "My kit is complete and packed",
      "button_4": "I'm finished",
      "button_5": "Next test",
      "button_6": "Skip instructions"
    },
    "contents": {
      "header": "Great! Let's make sure your kit is complete",
      "text_1": "Does your test kit box contain the following?",
      "blood_collection_card": "Blood collection card",
      "blood_collection_card_subtitle": "(1 or 2 depending on test kit)",
      "alchohol_pads": "2 alcohol pads",
      "lancets": "Single use lancets",
      "up_to_other": "(Up to 4 depending on test kit)",
      "adhesive_bandages": "Adhesive bandages",
      "pipette": "Pipette",
      "urine_tube": "Urine collection tube",
      "urine_cup": "Urine collection cup",
      "oral_swab": "Oral swab",
      "oral_tube": "Oral collection tube",
      "anal_swab": "Anal swab",
      "anal_tube": "Anal collection tube",
      "vaginal_tube": "1 vaginal collection tube",
      "vaginal_swab": "1 vaginal swab",
      "biodegradable_paper": "Biodegradable collection paper",
      "bottle_and_probe": "Sampling bottle and probe",
      "prepaid_envelope": "Pre-paid shipping envelope",
      "biohazard_subtitle": "(with absorbent pad)",
      "biohazard_bag": "1 specimen bag",
      "biohazard_bag_plural": "({{count}}) Biohazard bags",
      "alcohol_pads_plural": "{{count}} alcohol pads",
      "sterile_gauze_pad": "1 sterile gauze pad",
      "collection_tube": "1 collection tube",
      "collection_swabs": "2 collection swabs"
    },
    "packaging": {
      "title": "Packaging checklist",
      "subtitle": "Before you ship or drop your kit at a kiosk, make sure that:"
    },
    "packaging_box_a": {
      "step_1": "You have packed all test kit contents",
      "step_2": "You have used all collection materials contained in the kit to collect the required specimens",
      "step_3": "Your **date-of-birth** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_4": "The **collection date** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_5": "The specimen collection tube is sealed in its biohazard bag",
      "step_6": "All specimens are placed into the box",
      "step_7": "The box is placed into the pre-paid shipping envelope",
      "step_8": "The return shipping envelope is completely sealed"
    },
    "packaging_box_b": {
      "step_1": "You have packed all test kit contents, even if you skipped the test(s)",
      "step_2": "You have used all collection materials contained in the kit to collect the required specimens",
      "step_3": "Your **date-of-birth** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_4": "The **collection date** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_5": "Each specimen collection tube is sealed in its own biohazard bag (only **one** specimen per bag)",
      "step_6": "All specimens are placed into the box",
      "step_7": "The box is placed into the pre-paid shipping envelope",
      "step_8": "The return shipping envelope is completely sealed"
    },
    "packaging_box_c": {
      "step_1": "You have packed all test kit contents, including used lancets.",
      "step_2": "Your **date-of-birth** is written on the blood collection card using MM/DD/YYYY format",
      "step_3": "The **collection date** is written on the blood collection card using MM/DD/YYYY format",
      "step_4": "The blood collection card and all used lancets are placed in the biohazard bag",
      "step_5": "The biohazard bag is placed into the box",
      "step_6": "The box is placed into the pre-paid shipping envelope",
      "step_7": "The return shipping envelope is completely sealed"
    },
    "success": {
      "header_1": "Great!",
      "text_1": "You're ready to collect your first sample.",
      "header_2": "You did it!",
      "text_2": "**Next up:** package your kit to send back.",
      "header_3": "Great job!",
      "text_3": "You’re one step closer to better health.",
      "header_4": "Nice job!",
      "text_4": "Just one more and you’re done!",
      "text_5": "You're ready to collect your sample."
    },
    "instructions": {
      "title": "Select a test to view instructions:",
      "subtitle": "Test",
      "button": "Instructions",
      "pdf": "PDF instructions"
    },
    "instructions_box_a": {
      "title": "Urine collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Do not urinate for at least 1 hour prior to collection. Using first morning urine is best.",
      "step_2": "Urinate directly into the PeeCanter or collection cup, filling between 1/3 and 1/2.",
      "step_3": "Pour from the PeeCanter or use the pipette to transfer urine from the collection cup into the collection tube(s) marked with an orange <span style=\"color: #FF5000\">**\"URINE\"**</span> label.",
      "step_4": "Fill the collection tube(s) until the combined liquid is between the designated fill lines.",
      "step_4_sublabel": "Do not fill tube past the maximum fill line or specimen will be rejected.",
      "step_5": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_6": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
      "step_7": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
    },
    "instructions_box_b": {
      "test_1": {
        "title": "Urine collection",
        "subtitle": "For this test you will need the following materials:",
        "step_1": "Do not urinate for at least 1 hour prior to collection. Using first morning urine is best.",
        "step_2": "Urinate directly into the PeeCanter or collection cup, filling between 1/3 and 1/2.",
        "step_3": "Pour from the PeeCanter or use the pipette to transfer urine from the collection cup into the collection tube(s) marked with an orange <span style=\"color: #FF5000\">**\"URINE\"**</span> label.",
        "step_4": "Fill the collection tube(s) until the combined liquid is between the designated fill lines.",
        "step_4_subtitle": "Do not fill tube past the maximum fill line or specimen will be rejected.",
        "step_5": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
        "step_6": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
        "step_7": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
      },
      "test_2": {
        "title": "Oral swab",
        "subtitle": "For this test you will need the following materials:",
        "step_1": "Hold the swab between the faint score line (if present) and the cotton-topped portion of the swab.",
        "step_2": "Insert the swab into your mouth and rub the swab tip against the back of your throat for 10 seconds.",
        "step_3": "Carefully withdraw the swab and place into the collection tube marked with a blue <span style=\"color: #001F70\">**\"ORAL\"**</span> label. Break the swab at the score line by bending against the collection tube.",
        "step_4": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
        "step_5": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
        "step_6": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
      },
      "test_3": {
        "title": "Anal swab",
        "subtitle": "For this test you will need the following materials:",
        "step_1": "Hold the swab between the faint score line (if present) and the cotton-topped portion of the swab.",
        "step_2": "Insert the swab 3 - 5 cm (1 - 2”) into the anal canal. Gently turn the swab for 5 - 10 seconds to collect any potential organisms.",
        "step_3": "Carefully withdraw the swab and place into the collection tube marked with a green <span style=\"color: #00C08C\">**“ANAL”**</span> label. Break the swab at the score line by bending against the collection tube.",
        "step_4": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
        "step_5": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
        "step_6": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
      }
    },
    "instructions_box_c": {
      "title": "Blood collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Write your name, date of birth, and the date of collection in the designated fields. Use MM/DD/YYYY format.",
      "step_2": "Open blood card flap to expose the circles on the blood collection paper. Do not touch the blood collection paper.",
      "step_3": "Wash hands with warm water for at least 30 seconds, then shake hands vigorously for 15 seconds to encourage blood flow to your fingers.",
      "step_4": "Clean fingertip with alcohol pad. It is best to use the middle or ring finger of your non-dominant hand.",
      "step_5": "Take the lancet and twist off the cap. Press the small tip firmly into the tip of your finger, until the needle ejects with a click. Keeping your hand below your heart during collection, massage finger from base to tip to encourage blood flow.",
      "step_5_subtitle": "Lancets are single use. All lancets need to be returned with your sample to the laboratory for disposal.",
      "step_6": "Starting at the center, apply 3-6 drops to fill the circle and soak through the collection paper. Do not touch the paper with your finger as this will restrict blood flow. Once circle is full, move on to next circle. It is okay for blood to extend beyond lines, but do not let blood spots spread into each other.",
      "step_6_subtitle": "Do not add additional blood to a circle once completed or dry. The “layering” of blood will invalidate the collection.",
      "step_7": "Without closing the blood card, lay it on a flat surface and allow the blood collection paper to air dry at room temperature, for at least 30 minutes. Do not heat, blow dry, or expose the blood collection paper to direct sunlight. Heat will damage the specimen.",
      "step_7_subtitle": "Check the back side of blood collection paper. Blood should saturate all the way through and fill each circle of the collection paper.",
      "step_8": "When blood collection paper is dry, close blood card by tucking flap. Place the blood card and lancets into biohazard bag with the desiccant pack. Ensure biohazard bag is properly sealed."
    },
    "test_tips": {
      "title": "Tips for Proper Blood Collection",
      "subtitle": "For best results:",
      "step_1": "Be sure you are not dehydrated while performing collection. Hydration promotes blood flow.",
      "step_2": "Do not perform collection immediately after smoking.",
      "step_3": "Washing and warming your hands under warm water will help promote blood flow in your hands.",
      "step_4": "Shake hands vigorously towards the floor to encourage blood flow to your fingers.",
      "step_5": "Keep collection device and hands below your heart during collection for best blood flow.",
      "step_6": "You may need more than one finger prick. Repeat these tips between each finger prick."
    },
    "shipping": {
      "header": "Your kit is ready to ship!",
      "text": "**Congrats!** You finished your health screening."
    },
    "pick_up": "Pick up your recommended test kit from the site staff and register below",
    "short_display_name": {
      "A": "Chlamydia & Gonorrhea",
      "B": "Chlamydia & Gonorrhea",
      "C": "Syphilis",
      "CC": "Colorectal Cancer",
      "CE": "Celiac",
      "CR": "Creatinine",
      "D": "HIV, Syphilis, Chlamydia & Gonorrhea",
      "DD": "Diabetes",
      "E": "HIV & Syphilis",
      "H": "HPV",
      "P": "Hepatitis C",
      "HS": "Herpes Simplex",
      "I": "HIV",
      "TT": "Testosterone",
      "VD": "Vitamin D",
      "VS": "Viral Surveillance"
    },
    "test_kit": "Test Kit",
    "view_results": "View results",
    "recommended_kit_title": "Recommended Test Kits",
    "instructions_box_h": {
      "title": "Vaginal Swab Collection",
      "subtitle": "Collection tubes may contain a liquid preservative. <span class=\"text-danger\">**Do not empty liquid preservative from the collection tubes if present.**</span>",
      "step_1": "Hold the swab between the faint score line (if present) and the cotton-tipped portion of the swab in one hand and separate the labia (folds of skin around the vaginal opening).",
      "step_2": "Insert the swab 5cm (2in) into the vaginal opening. Gently turn the swab for 30 seconds while rubbing the swab against the walls of the vagina.",
      "step_3": "Carefully withdraw the swab and place into the collection tube marked with a <span class=\"text-danger\">**RED “VAGINAL” LABEL**</div>. Break the swab by bending against the collection tube.",
      "step_4": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_5": "Write your date of birth in MM/DD/YYYY format onto the tube in the designated area (DOB).",
      "step_6": "Write the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area (Coll. Date).",
      "step_7": "Place specimen into the empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
    },
    "packaging_box_h": {
      "step_3": "Your **date-of-birth** is written on the specimen collection tube using MM/DD/YYYY format",
      "step_4": "Your **collection date** is written on the specimen collection tube using MM/DD/YYYY format",
      "step_6": "The biohazard bag is placed into the box"
    },
    "instructions_box_cc": {
      "title": "Stool collection",
      "step_1": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area. Open green cap by twisting and lifting.",
      "step_2": "Place supplied collection paper into toilet bowl on top of water.",
      "step_3": "Deposit stool sample on top of collection paper.",
      "step_4": "Collect sample from stool before paper sinks and stool sample touches water.",
      "step_5": "Scrape the surface of the fecal sample with the sample probe.",
      "step_6": "Cover the grooved portion of the sample probe completely with stool sample.",
      "step_7": "Close sampling bottle by inserting sample probe and snapping green cap on tightly. Do not reopen.",
      "step_8": "Flush. Collection paper is biodegradable and will not harm septic systems.",
      "step_9": "Wrap sampling bottle in absorbent pad and insert in to  the biohazard bag."
    },
    "contact_support": {
      "title": "Let's fix this",
      "text_1": "We are sorry to hear that something is wrong!",
      "text_2": "Please contact us to let us know what’s wrong and we will help to replace your kit."
    },
    "contact_support_success": {
      "title": "We received your message",
      "text_1": "Thanks for contacting us.",
      "text_2": "A member of our support team will be in touch soon.",
      "button_text": "Return home"
    },
    "kit_in_transit_to_patient": "Your order is on its way! Most orders are delivered within 2-5 business days. Once you receive your kit, we will send you more information on how to activate it and send it back.\n\nTracking Link: https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{tracking_number}}",
    "kit_received_at_lab": "The lab received your kit! You'll receive a notification to check your patient portal when results are ready",
    "registration_confirmation": "Your registration is now complete! Visit your registration confirmation page to activate your test kit:",
    "kit_ordered_online": "We've received your kit order and we will send an update once it ships! \n\nOrder #: {{order_number}} \nOrder date: {{order_date}}",
    "kit_ordered_online_email": {
      "header": "We received your order!",
      "p1": "We've received your order! Once your order ships, we'll send you another update.",
      "order_number": "Order #",
      "order_date": "Order date"
    },
    "completed": "Completed",
    "I": "this kit includes tests for HIV.",
    "CC": "this kit includes tests for COVID-19.",
    "CE": "this kit includes tests for celiac disease.",
    "CR": "this kit includes tests for creatinine.",
    "DD": "this kit includes tests for diabetes.",
    "H": "this kit includes tests for HPV.",
    "P": "this kit includes tests for hepatitis C.",
    "HH": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "HS": "this kit includes tests for HSV-2.",
    "TT": "this kit includes tests for testosterone.",
    "VD": "this kit includes tests for vitamin D.",
    "nasal_collection": {
      "title": "Nasal collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Write your Date of Birth and the Collection Date on your collection tube. Use MM/DD/YYYY format for the dates (i.e., 08/29/2023).",
      "step_2": "Wash your hands with soap and water thoroughly before proceeding and after completeing all steps.",
      "step_3": "Remove the swab from the wrapper with your clean hands. Hold the swab with the white tip up. Do not touch the tip of the swab to anything.",
      "step_4": "Holding the swab with one hand, gently insert the white tip of the swab about 1/2 to 3/4 inces (1 to 2 cm) into the opening of your nose. Rotate the swab for 15 to 30 seconds. Make sure the swab touches the insides of your nose. Remove the swab from your nose being careful not to touch your skin. Do not set the swab down. Repeat in the other nostril.",
      "step_5": "While still holding the swab, unscrew and remove the cap from the transport tube without setting the cap down. Place the swab into the tube with the white tip down, taking great care not to spill or splash the liquid in the collection tube.",
      "step_6": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_7": "Place specimen into the empty biohazard bag with the absobent pad and ensure the seal is closed completely. Return the biohazard bag to the kiosk drop box."
    },
    "NN": "this kit includes tests for COVID-19.",
    "YYYWW": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "FFFWW": "this kit includes tests for COVID-19 and Flu A/B.",
    "HA": "this kit includes tests for COVID-19, Flu A/B, and RSV."
  },
  "copy_link": "Copier le lien de la page",
  "copied_link": "Lien copié !",
  "vaccines": {
    "descriptions": {
      "vaccine_flu": "Protection contre les virus de la grippe saisonnière",
      "vaccine_covid": "Protection contre les virus COVID-19",
      "tdap": "Le vaccin TdCa peut prévenir le tétanos",
      "polio": "Protection contre le virus de la polio. Obligatoire pour les enfants avant leur entrée à l’école.",
      "varicella": "Vaccin qui protège de la varicelle",
      "shingles": "Vaccin qui protège du zona. L’âge minimum est de 18 ans.",
      "human_papillomavirus": "Vaccin qui protège du papillomavirus. Recommandé pour les enfants de 11 ans.",
      "meningococcal": "Le vaccin protège de quatre types de méningocoque."
    }
  },
  "viral_surveillance": {
    "shipping": {
      "title": "Prepare to ship your sample:",
      "step_1": {
        "label": "Put the biohazard bag into box.",
        "substep_1": "Remove the return shipping mailer from your test kit.",
        "substep_2": "Place the biohazard bag into the original collection kit box and close the box."
      },
      "step_2": {
        "label": "Put box into return shipping mailer.",
        "substep_1": "Place the box into the return shipping mailer and seal the mailer.",
        "substep_2": "Do not fold or cover the UN3373 diamond symbol."
      },
      "step_3": {
        "label": "Return to Fedex location.",
        "substep_1": "Return kit on the same day of collection (before the last pickup) to a Fedex location.",
        "substep_2": "Call for a FedEx pickup at 1.800.GoFedEx."
      }
    },
    "overview": {
      "title": "Welcome to the Viral Sequencing Program!",
      "subtitle": "Your participation helps provide valuable information on which respiratory pathogen variants are circulating in your area. Please follow the instructions that came with your kit to get started."
    },
    "instructions": {
      "title": "Collect your sample:",
      "step_1": "Wash your hands.",
      "step_2": "Remove the cap of the collection tube and open the swab package.",
      "step_2a": "Remove the swab from the package. Do not touch the soft end with your hands or anything else.",
      "step_3": "Collect a nasal swab.",
      "step_3a": "Insert the entire soft end of the swab into your nostril no more than 3/4 of an inch (1/5cm) into your nose.",
      "step_3b": "Slowly rotate the swab",
      "step_3c": "Gently remove the swab.",
      "step_3d": "Using the same swab, repeat in your other nostril with the same end of the swab.",
      "step_4": "Put swab into the collection tube and cap the tube securely.",
      "step_5": "Put the collection tube into the biohazard bag and seal.",
      "step_6": "Apply even pressure to the ziplock to seal the bag shut."
    },
    "click_to_get_started_button": "Click to get started",
    "get_started_button": "Get started",
    "thank_you": {
      "title": "Thank you for using Primary.Health!",
      "p_3": "You may now close the browser.",
      "p_2": "Please make sure to drop off the viral surveillance kit at your closest FedEx location.",
      "p_1": "Your participation actively helps Public Health officials fight the spread of disease. If you would like to see the impact you are making on Public Health, please take a moment to explore more via [this link]({{link}}). You will not be contacted about your results, as they are anonymous."
    },
    "verify_dob_title": "First, let's confirm your details",
    "verify_dob_subtitle": "This helps us ensure the correct person is using the test kit.",
    "activate_kit": "Activate a kit:",
    "steps_complete": "Steps complete",
    "opt_in": {
      "section_1_title": "How it works",
      "section_1_text_1": "Sign up below and receive a viral sequencing test kit in 1-3 business days.",
      "section_1_text_2": "Upon receiving the test kit",
      "section_1_text_3": "Send the sample back per the instructions in the kit using our prepaid label.",
      "sign_up": "Sign me up!",
      "no_to_participate": "No",
      "section_2_title": "What is the impact of your participation?",
      "section_2": {
        "card_1_title": "Rapid response to new variants",
        "card_1_text": "Genetic sequencing helps track the spread and emergence of new respiratory pathogen variants, allowing public health systems to quickly ramp up preparedness efforts.",
        "card_2_title": "Track how Respiratory Pathogens are changing",
        "card_2_text": "Respiratory pathogen sequencing analyzes the “genetic blueprint” of the virus. This helps scientists trace how the virus changes, enabling it to spread more easily, make people more sick",
        "card_3_title": "You have complete privacy",
        "card_3_text": "The sequencing will be processed anonymously. The lab will never contact you about your results"
      },
      "title": "You’re eligible for California’s Free Respiratory Pathogen Viral Sequencing Project!",
      "subtitle": "You can help public health officials protect communities against respiratory pathogens! If you opt in to participate you will receive a free viral sequencing kit that will test what respiratory pathogen strain you are infected with. Results will help officials track the spread of respiratory pathogen variants. See below for more information and to sign up.",
      "continue_to_result": "Continue to my result",
      "create_page_title": "Thank you!",
      "create_page_text_1": "Your kit should arrive in the next 1-3 business days.",
      "create_page_text_2": "Your participation actively helps Public Health officials fight the spread of diseases. You will be notified when your test kit order has been approved and when it has shipped."
    }
  },
  "family_registrations": {
    "find_clinic": "Trouver une clinique",
    "select_pin": "Choisir une épingle pour voir les détails",
    "miles_shorten": "{{miles}} mi",
    "next_available": "Prochaine disponibilité",
    "appointment_type": "Type de rendez-vous",
    "individual_appointment": "Rendez-vous individuel",
    "family_appointment": "Rendez-vous familial",
    "vaccines": "Vaccins",
    "what_kind_appointment": "Quel type de rendez-vous souhaitez-vous réserver ?",
    "select_family_or_individual": "Choisir « Rendez-vous familial » si vous programmez un rendez-vous pour plusieurs membres de la famille.",
    "vaccines_selected": "Vaccins choisis",
    "where_care": "Où souhaitez-vous bénéficier de soins ?",
    "select_vaccines_for_individual": "Choisir les vaccins pour votre rendez-vous (facultatif).",
    "select_vaccines_for_family": "Choisir les vaccins pour votre rendez-vous familial (facultatif).",
    "schedule_services": "Programmer des services",
    "add_family": "Ajouter des membres de la famille et choisir des services ci-dessous.",
    "family_member": "Membre de la famille {{number}}",
    "morning": "Matin",
    "afternoon": "Après-midi",
    "slot_available": "{{number}} disponible(s)",
    "within_x_miles": "À {{miles}} miles de",
    "any_distance": "N’importe quelle distance de",
    "partial_results": "Résultats partiels",
    "partial_matches": "Les cliniques suivantes offrent certains les vaccins que vous cherchez, mais pas tous",
    "no_matches": "Cette recherche n’a rien donné. Essayez de modifier vos filtres en vue d’obtenir de meilleurs résultats.",
    "no_clinics_found": "Aucune clinique ne correspond à votre recherche.",
    "broaden_filters": "Essayez d’élargir vos filtres en vue d’obtenir de meilleurs résultats.",
    "unavailable_vaccines": "Vaccins indisponibles :",
    "available_vaccines": "Vaccins disponibles :",
    "select_date": "Choisir une date",
    "available_appointments": "RENDEZ-VOUS DISPONIBLES",
    "appointment_scheduling_info": "Lors de l’étape suivante, vous pourrez assigner des horaires de rendez-vous à des membres spécifiques de la famille. Vous pouvez programmer des rendez-vous à des jours différents si vous le souhaitez.",
    "hold_selected_appointments": "Vos rendez-vous choisis seront retenus pendant 15 minutes.",
    "appointments_selected": "RENDEZ-VOUS CHOISIS",
    "no_appointments_selected": "Aucun rendez-vous choisi",
    "vaccines_needed": "Vaccins nécessaires",
    "select_x_appointments": "Choisir {{number}} rendez-vous.",
    "more": "Plus",
    "less": "Moins",
    "register_next_family_member": "Inscrire le membre de la famille suivant",
    "successfully_registered_x_of_y_family_members": "Vous avez inscrit {{x}} membres de la famille sur {{y}}",
    "next_family_member": "Membre de la famille suivant",
    "appointments_abbreviated": "RDV",
    "register_for_clinic": "S’inscrire à la clinique",
    "select_services": "Choisir les services",
    "person": "Personne {{number}}",
    "add_person": "Ajouter une autre personne",
    "registration_confirmation": "Confirmation d’inscription"
  },
  "user_mailer": {
    "verify_email": "Vérifions votre adresse e-mail !",
    "hi_full_name": "Bonjour {{full_name}},",
    "verify_email_button": "Vérifier l’adresse e-mail",
    "please_verify_email": "Merci de vérifier votre adresse e-mail avec le bouton ci-dessous."
  },
  "services": {
    "dptap_dt": "DTCa/TdCa/Td",
    "hepatitis_a": "Hépatite A",
    "hepatitis_b": "Hépatite B",
    "hepatitis_a_b": "Hépatite A et B",
    "hib": "Hib",
    "mmr": "Rougeole, oreillons, rubéole",
    "meningococcal": "Méningocoque",
    "mpox": "Mpox",
    "pneumococcal": "Pneumocoque",
    "polio": "Polio",
    "rsv": "Virus respiratoire syncytial",
    "rotovirus": "Rotavirus",
    "zoster": "Zona",
    "tetanus_diptheria": "Tétanos et diphtérie",
    "tdap": "TdCa",
    "typhoid": "Typhoïde",
    "varicella": "Varicelle",
    "covid-19": "COVID-19",
    "covid-19_adult": "COVID-19 (Adulte)",
    "covid-19_adult_description": "Pour les personnes de 12 ans ou plus",
    "covid-19_children": "COVID-19 (Enfant)",
    "covid-19_children_description": "Pour les enfants entre 4 et 11 ans.",
    "covid-19_infants": "COVID-19 (Nourrisson)",
    "covid-19_infants_description": "Pour les enfants entre 6 mois et 3 ans.",
    "influenza": "Grippe",
    "mmrv": "Rougeole, oreillons, rubéole et varicelle"
  },
  "deep_archived": {
    "admin": "Pour des raisons de sécurité des données, les données du groupe {{test_group_name}} ({{slug}}) sont archivées et ne peuvent plus être consultées ou modifiées depuis cette page. Veuillez contacter votre gestionnaire de compte ou support@primary.health si vous avez besoin d’assistance.",
    "participant": "Cette page n’est plus disponible. Merci de joindre vos contacts de la campagne de santé : {{test_group_name}}. Si vous avez du mal à consulter un dossier précédent, veuillez demander de l’aide à support@primary.health"
  }
}