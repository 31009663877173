import React, { useRef, useState } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Accordion,
} from 'react-bootstrap';
import { isEqual } from 'lodash';
import printJS from 'print-js';
import axios from 'axios';
import SurveyPreview from '../../../common/components/SurveyPreviewV2';
import '../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import { arraysAreEqual, today } from '../../../common/utils';
import FabrxCheckbox from '../../../Registration/primary/FabrxCheckbox';
import ConsentFormsUser from '../../../Consents/NewConsentFormsUsersV2';
import { validateSurvey } from '../../../Registration/components/registration/CustomSurvey';
import { useEffect } from 'react';
import Insurance from '../insurance/InsuranceV2';
import Select, {
  CheckboxOption,
} from '../../../common/components/Select';
import Icon from '../../../common/components/AssetIcon';
import CheckoutSection from "../../../CheckoutPage/components/CheckoutSection";
import ImmunizationHistory from '../../../CheckoutPage/components/ImmunizationHistoryV2';

export const SECTIONS_IN_ORDER = [
  "consent",
  "appointment_survey",
  "checkout_survey",
  "insurance_survey",
  "medical_screening_survey",
  "test_group_user_survey",
  "dependents",
  "symptoms",
  "tags",
  "insurance",
  "show_immunization_history",
];

const SURVEY_SECTIONS = [
  "appointment_survey",
  "checkout_survey",
  "insuranve_survey",
  "medical_screening_survey",
  "test_group_user_survey",
];

export const pushReminderToSign = ({ id }) => {
  axios
    .post(`/api/send_consent_reminder_appointment/${id}`)
    .then(() => {
      toastr.success(`Reminder sent!`);
    })
    .catch((error) => {
      const {
        response: { status },
      } = error;
      if (status === 401) {
        toastr.error('Not authorized to send reminder.');
      } else {
        toastr.error('Failed to send reminder.');
      }
    });
};

const SurveySection = ({
  completed,
  inputName,
  name,
  newSurvey=false,
  saveSurvey,
  setSurveyMissingAnswer,
  survey,
  testGroup,
  title,
  value,
  ...props
}) => {
  const [answers, setAnswers] = useState(value);
  const [savedRecently, setSavedRecently] = useState(false);
  const surveyErrors = validateSurvey.bind(null, null, survey, testGroup)()(answers);
  const surveyHasErrors = Object.keys(surveyErrors).length > 0;
  const evaluatedValue = typeof value === 'function' ? value() : value;
  const saveDisabled = answers === evaluatedValue || surveyHasErrors || savedRecently;

  useEffect(() => {
    setSavedRecently(false);
    const surveyErrorHeader = document.getElementsByClassName("missingSurveyAnswer-error-header");
    const missingSurveyAnswerErrors = document.getElementsByClassName("missing-survey-answer");
    if (!surveyHasErrors) {
      if (missingSurveyAnswerErrors.length < 1) {
        Array.from(surveyErrorHeader).forEach(h => h.classList.add("d-none"));
        setSurveyMissingAnswer(false);
      }
    } else {
      Array.from(surveyErrorHeader).forEach(h => h.classList.remove("d-none"));
      // only disable checkout button if checkout questionnaire fails validation
      if (title === "Checkout Questionnaire") setSurveyMissingAnswer(true);
    }
  }, [answers]);

  const handleSave = (data) => {
    saveSurvey(data)
      .then((response) => {
        if (response.data.success) {
          setSavedRecently(true);
        } else {
          toastr.error('Something went wrong');
        }
      }
    ).catch((error) => {
      toastr.error('Something went wrong');
    });
  };

  useEffect(() => {
    if (!saveDisabled) handleSave(answers);
  }, [answers]);

  return (
    <CheckoutSection
      bodyStyle={{ marginTop: '-1.5rem' }}
      completed={completed || savedRecently}
      data={answers}
      sectionKey={name}
      showHide={true}
      savedRecently={savedRecently}
      title={title}
      {...props}
    >
      <input type="hidden" name={inputName} value={JSON.stringify(answers)} />
      <SurveyPreview json={survey} data={answers} newSurvey={newSurvey} onValueChanged={(e) => setAnswers(e.data)} />
    </CheckoutSection>
  );
};

export const additionalConsents = (testConfigurations) => (
  testConfigurations.map(tc => tc.test_group_test_configuration.active_consent_form.found &&
    ({id: tc.test_group_test_configuration.active_consent_form.id, testConfiguration: tc })
  ).filter(x => x)
)

const ConsentSection = ({
  appointment: { consent_capture_method, ...appointment },
  testGroup,
  testConfigurations=[],
  user,
  ...props
}) => {
  const groupConsentSigned = appointment['consents_signed?'];
  const otherConsents = additionalConsents(testConfigurations);
  const signedAdditionalConsentsCount = otherConsents.filter(additionalConsent => {
    return !!user.consent_forms_users.find(consentFormUser => consentFormUser.consent_form_id == additionalConsent.id);
  }).length;
  const totalConsentCount = otherConsents.length + 1;
  const signedConsentCount = signedAdditionalConsentsCount + (groupConsentSigned ? 1 : 0);
  const allConsentsSigned = groupConsentSigned && signedAdditionalConsentsCount == otherConsents.length;

  const subTitle = (
    <div>
      {allConsentsSigned ? (
        <>All consents signed</>
      ) : (
        <>{`${signedConsentCount} of ${totalConsentCount} consents signed`}</>
      )}
    </div>
  );

  return (
    <CheckoutSection
      bodyStyle={{ marginTop: '0.5rem' }}
      completed={allConsentsSigned}
      sectionKey="consent"
      showHide={true}
      subTitle={subTitle}
      title={'Consent'}
      {...props}
    >
      {!allConsentsSigned && (
        <p>{appointment['has_guardian_information?'] ? "Guardian" : "Participant"} has not signed the current active consent.</p>
      )}
      <ConsentFormsUser
        appointment={appointment}
        test_group={testGroup}
        test_configurations={testConfigurations}
      />
    </CheckoutSection>
  );
};

const DependentsSection = ({ appointment, user, testGroup, symptoms }) => {
  return user.dependent_appointments?.map((dep_appointment, idx) => (
    <React.Fragment key={idx + 'consent'}>
      <div className="h4 my-2">
        {dep_appointment.first_name} {dep_appointment.last_name}
      </div>
      <input
        type="hidden"
        name={`dependent_appointments[${idx}][user_id]`}
        value={dep_appointment.id}
      />
      <div className="btn-group-toggle" data-bs-toggle="buttons">
        {appointment?.available_test_lanes?.map((testLane) => (
           <div
            key={testLane.name}
            data-test-hook="test_lane"
          >
            <input
              type="radio"
              className="btn-check"
              name={`dependent_appointments[${idx}][test_lane_id]`}
              value={testLane.id}
              id={`test-lane-${testLane.id}-${idx}`}
              autoComplete="off"
              required
            />
            <label
              className="btn btn-outline-primary me-2"
              htmlFor={`test-lane-${testLane.id}-${idx}`}
            >
              {testLane.name}
            </label>
          </div>
        ))}
      </div>
      <div className="mt-3 mb-3">
        <div className="form-check">
          <input
            type="checkbox"
            name={`dependent_appointments[${idx}][consent]`}
            value="1"
            checked="checked"
            className="form-check-input"
          />
          <label className="form-check-label">Signed Consent?</label>
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <SymptomsSection
          symptoms={symptoms}
          appointment={dep_appointment}
          name={`dependent_appointments[${idx}]`}
          showHeader={false}
          testGroup={testGroup}
        />
      </div>
    </React.Fragment>
  ));
};

const SymptomsSection = ({ symptoms, appointment = {}, name = "appointment", testGroup, ...props }) => {
  const { t } = useTranslation();
  const { symptoms: appointmentSymptoms = {} } = appointment;
  const [selectedSymptoms, setSelectedSymptoms] = useState(appointmentSymptoms);
  const atLeastOneCheckboxSelected = Object.values(selectedSymptoms).some(v => v == '1');
  const [completed, setCompleted] = useState(atLeastOneCheckboxSelected);
  const [savedRecently, setSavedRecently] = useState(false);
  const saveButtonDisabled = !atLeastOneCheckboxSelected || appointmentSymptoms == selectedSymptoms || savedRecently;

  const renderSymptomCheckbox = (symptom) => {
    const { key } = symptom;
    const active = appointmentSymptoms[key] === '1' || appointmentSymptoms[key] === 'true';
    return (
      <Col xs={12} sm={6} key={`symptom-${key}`}>
        <FabrxCheckbox
          ariaLabel={t(`symptoms.${key}`)}
          value="1"
          name={`${name}[symptoms_blob][${key}]`}
          defaultChecked={active}
          label={t(`symptoms.${key}`)}
          onChange={(e) => {
            setSavedRecently(false);
            setSelectedSymptoms((prev) => {
              const newSymptoms = { ...prev };
              newSymptoms[key] = selectedSymptoms[key] === '1' ? '0' : '1';
              return newSymptoms;
            });
          }}
        />
      </Col>
    );
  };

  const handleSave = () => {
    axios.put(`/test_groups/${testGroup.id}/appointments/${appointment.id}/checkout/save_symptoms`, { symptoms_blob: selectedSymptoms })
      .then((response) => {
        if (response.data.success) {
          setCompleted(true);
          setSavedRecently(true);
        } else {
          toastr.error('Something went wrong');
        }
      }
    ).catch((error) => {
      toastr.error('Something went wrong');
    });
  };

  useEffect(() => {
    if (!saveButtonDisabled) handleSave();
  }, [selectedSymptoms]);

  return (
    <CheckoutSection
      bodyStyle={{ marginTop: '0.5rem' }}
      data={selectedSymptoms}
      completed={completed}
      saveButtonDisabled={saveButtonDisabled}
      savedRecently={savedRecently}
      sectionKey="symptoms"
      showHide={true}
      title={'Symptoms'}
      {...props}
    >
      <Row>
        {symptoms.map(renderSymptomCheckbox)}
      </Row>
    </CheckoutSection>
  );
};

const TagSection = ({
  appointment,
  user,
  testGroup,
  ...props
}) => {
  const userTags = user.user_tags?.map(userTag => userTag.tag_id) || []
  const originalTagIds = userTags;
  const [activeTags, setActiveTags] = useState(originalTagIds);
  const inActiveTags = originalTagIds.filter(t => !activeTags.includes(t))
  const totalTags = activeTags.concat(inActiveTags);
  const isOriginalTag = (tagId) => originalTagIds.find(id => id == tagId);
  const [selectTagOptions, setSelectTagOptions] = useState([]);
  const [savedRecently, setSavedRecently] = useState(false);
  const saveButtonDisabled = arraysAreEqual(originalTagIds, activeTags) || savedRecently;

  useEffect(() => {
    axios.get(`/test_groups/${testGroup.slug}/tags`).then((response) => {
      const options = typeof(response.data.options) == "string"
        ? JSON.parse(response.data.options)
        : response.data.options
      setSelectTagOptions(
        options.map((option) => ({
          value: option["id"],
          label: option["friendly_name"],
        })),
      );
    });
  }, []);

  const onRemove = (tagId) => {
    const newActiveTags = activeTags.filter(tag => tag != tagId);
    setActiveTags(newActiveTags);
    handleSave(newActiveTags);
  }

  const handleSave = (tags) => {
    axios.put(`/test_groups/${testGroup.id}/appointments/${appointment.id}/checkout/save_user_tags`, { user_tags: tags })
      .then((response) => {
        if (response.data.success) {
          setSavedRecently(true);
        } else {
          toastr.error('Something went wrong');
        }
      }
    ).catch((error) => {
      toastr.error('Something went wrong');
    });
  }

  return (
    <CheckoutSection
      completed={!!userTags.length || savedRecently}
      hidePending={true}
      saveButtonDisabled={saveButtonDisabled}
      savedRecently={savedRecently}
      sectionKey="tags"
      showHide={true}
      title={'Tags'}
      {...props}
    >
      <p>Tags assign someone into a population.</p>
      <Row>
        <Col xs={12} lg={6}>
          <h6>Available tags</h6>
          <Select
            isMulti
            onBlur={() => handleSave(activeTags)}
            options={selectTagOptions}
            blurInputOnSelect={false}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            isClearable={false}
            controlShouldRenderValue={false}
            verticallyCenterCheckBox={true}
            placeholder={""}
            components={{ Option: CheckboxOption }}
            styles={{
              menuList: (styles) => ({
                ...styles,
                maxHeight: '460px',
              })
            }}
            value={activeTags}
            onChange={(values) => {
              setActiveTags(values);
              setSavedRecently(false);
            }}
          />
        </Col>
      </Row>
      {!!activeTags.length && (
        <div className="d-flex flex-wrap my-3">
          {activeTags.map((tag) => {
            const label = selectTagOptions.find(x => x.value == tag)?.label;
            return (
              <div
                className="px-3 py-2 me-3 my-2"
                style={{ backgroundColor: "#F7F7F7", borderRadius: "20px" }}
              >
                {label}
                <Icon
                  className="ms-2 pointer"
                  onClick={() => onRemove(tag)}
                  path="/images/icons/icon-x.svg"
                  style={{ width: 14 }}
                />
              </div>
            );
          })}
        </div>
      )}
      {totalTags.map((tagId, idx) => (
        inActiveTags.includes(tagId) ? (
          isOriginalTag(tagId) &&
            <>
              <input
                type="hidden"
                value="1"
                name={`user[user_tags_attributes][${idx}][_destroy]`}
              />
              <input
                type="hidden"
                value={user.user_tags.find(x => x.tag_id == tagId).id}
                name={`user[user_tags_attributes][${idx}][id]`}
              />
            </>
        ) : !isOriginalTag(tagId) &&
          <input type="hidden" value={tagId} name={`user[user_tags_attributes][${idx}][tag_id]`} />
      ))}
    </CheckoutSection>
  );
};

const InsuranceSection = ({ user, appointment, testGroup, ...props }) => {
  const showInsuranceField = (testGroup, field) =>
    testGroup.insurance_fields_requested.includes(field) ||
    testGroup.insurance_fields_required.includes(field);
  const requireInsuranceField = (testGroup, field) =>
    testGroup.insurance_fields_required.includes(field);

  const initialFormAction = useRef({
    requestInsurance: showInsuranceField(testGroup, 'insurance'),
    requireInsurance: requireInsuranceField(testGroup, 'insurance'),
    requestDriversLicense: showInsuranceField(testGroup, 'drivers_license'),
    requireDriversLicense: requireInsuranceField(testGroup, 'drivers_license'),
    requestSocialSecurityNumber: showInsuranceField(testGroup, 'ssn'),
    requireSocialSecurityNumber: requireInsuranceField(testGroup, 'ssn'),
    showInsuranceBack: false,
    showInsuranceInformation:
      requireInsuranceField(testGroup, 'insurance') ||
      user.insurance_policy_holder,
    showNonInsuranceInformation:
      !requireInsuranceField(testGroup, 'insurance') &&
      !user.insurance_policy_holder &&
      (user.insurance_status == 1 || user.insurance_status == 'do_not_have_health_insurance'),
    policyHolder: user.insurance_policy_holder,
    secondaryPolicyHolder: user.insurance_secondary_policy_holder,
    showSecondary: !!user.insurance_secondary_policy_holder,
    useSSN:
      !showInsuranceField(testGroup, 'drivers_license') &&
      showInsuranceField(testGroup, 'ssn'),
    participantHasID: true,
    memberBirthMonth: user["insurance_primary_member_date_of_birth(2i)"],
    memberBirthYear: user["insurance_primary_member_date_of_birth(1i)"],
    memberBirthDay: user["insurance_primary_member_date_of_birth(3i)"],
    memberSecondaryBirthMonth: user["insurance_secondary_member_date_of_birth(2i)"],
    memberSecondaryBirthYear: user["insurance_secondary_member_date_of_birth(1i)"],
    memberSecondaryBirthDay: user["insurance_secondary_member_date_of_birth(3i)"],
    driverLicenseState: user.driver_license_state,
    insuranceStatus: user.insurance_status,
    insuranceId: user.insurance_id,
    insuranceCompany: user.insurance_company,
    insuranceGroupNumber: user.insurance_group_number,
    insurancePrimaryMemberFirstName: user.insurance_primary_member_first_name,
    insurancePrimaryMemberLastName: user.insurance_primary_member_last_name,
    insuranceCardFront: user.insurance_card_front,
    insuranceCardBack: user.insurance_card_back,
    insuranceSecondaryCardFront: user.insurance_secondary_card_front,
    insuranceSecondaryCardBack: user.insurance_secondary_card_back,
    driverLicenseNumber: user.driver_license_number,
    socialSecurityNumber: user.social_security_number,
    driverLicense: user.driver_license,
    insuranceSecondaryId: user.insurance_secondary_id,
    insuranceSecondaryCompany: user.insurance_secondary_company,
    insuranceSecondaryGroupNumber: user.insurance_secondary_group_number,
    insuranceSecondaryPolicyHolder: user.insurance_secondary_policy_holder,
    insuranceSecondaryMemberFirstName: user.insurance_secondary_member_first_name,
    insuranceSecondaryMemberLastName: user.insurance_secondary_member_last_name,
  });

  const [formAction, setFormAction] = useState(initialFormAction.current);
  const [savedRecently, setSavedRecently] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(!!user.insurance_status);

  useEffect(() => {
    setSaveButtonDisabled(
      isEqual(formAction, initialFormAction.current)
    );
  }, [formAction]);

  const handleSave = () => {
    event.preventDefault();
    setSaveButtonDisabled(true);

    const form = document.querySelector('#insurance-form')
    const formData = new FormData(form)
    formData.append("saving_from_checkout_page", "true");

    const appendMemberBirthDate = (prefix, birthYear, birthMonth, birthDay) => {
      if (!!birthYear && !!birthMonth && !!birthDay) {
        formData.append(`user[${prefix}_date_of_birth(1i)]`, birthYear?.toString());
        formData.append(`user[${prefix}_date_of_birth(2i)]`, birthMonth?.toString());
        formData.append(`user[${prefix}_date_of_birth(3i)]`, birthDay?.toString());
      }
    };

    appendMemberBirthDate("insurance_primary_member", formAction.memberBirthYear, formAction.memberBirthMonth, formAction.memberBirthDay);
    appendMemberBirthDate("insurance_secondary_member", formAction.memberSecondaryBirthYear, formAction.memberSecondaryBirthMonth, formAction.memberSecondaryBirthDay);

    axios({
      url: `/registrations/${appointment.access_code}/insurance_information`,
      method: "put",
      data: formData,
      headers: { 'content-type': 'multipart/form-data' },
    })
    .then((response) => {
      if (response.data.success) {
        setSavedRecently(true);
      } else {
        toastr.error('Something went wrong');
      }
    }).catch((error) => {
      toastr.error('Something went wrong');
    });
  };

  useEffect(() => {
    if (!isEqual(formAction, initialFormAction.current)) handleSave();
  }, [formAction]);

  return (
    <CheckoutSection
      bodyStyle={{ marginTop: '-0.5rem' }}
      completed={!!user.insurance_status || savedRecently}
      savedRecently={savedRecently}
      sectionKey="insurance"
      showHide={true}
      title="Insurance"
      {...props}
    >
      <Insurance
        appointment={appointment}
        formAction={formAction}
        setFormAction={setFormAction}
        test_group={testGroup}
        user={user}
      />
    </CheckoutSection>
  );
};

export const CheckInInputs = ({
  ...props
}) => {

  const {
    activeSections=[],
    appointment,
    checkOut=false,
    currentUserIsHipaaTrained,
    disableAdvanced=false,
    setSidebarKeys,
    sidebarKeys,
    testConfigurations=[],
    testGroup,
    tests,
    user,
  } = props;

  const [checkIn, setCheckIn] = useState(true);

  const showInsuranceSection = testGroup.insurance_fields_requested.includes('insurance') || testGroup.insurance_fields_required.includes('insurance');

  const sections = {
    consent: (
      <ConsentSection
        tests={tests || appointment.tests}
        testConfigurations={testConfigurations}
        {...props}
      />
    ),
    medical_screening_survey: testGroup.medical_screening_survey && (
      <SurveySection
        completed={!!Object.keys(appointment.medical_screening_survey_answers?.answers || {}).length}
        saveSurvey={(data) => axios.put(`/test_groups/${testGroup.id}/appointments/${appointment.id}/checkout/save_medical_screening_survey`,
          { medical_screening_survey_answers: data }
        )}
        inputName="appointment[medical_screening][answers]"
        name="medical_screening_survey"
        survey={testGroup.medical_screening_survey}
        title="Medical screening"
        value={() => {
          try {
            return JSON.parse(appointment.medical_screening_survey_answers.answers);
          } catch {
            return appointment.medical_screening_survey_answers?.answers || {};
          }
        }}
        {...props}
      />
    ),
    appointment_survey: testGroup.show_custom_survey && (
      <SurveySection
        completed={!!Object.keys(appointment.questionnaire || {}).length}
        saveSurvey={(data) => axios.put(`/test_groups/${testGroup.id}/appointments/${appointment.id}/checkout/save_appointment_survey`,
          { appointment_survey_answers: data }
        )}
        inputName="appointment[questionnaire]"
        name="appointment_survey"
        survey={testGroup.custom_survey}
        title="Appointment questionnaire"
        value={appointment.questionnaire}
        {...props}
      />
    ),
    test_group_user_survey: Object.keys(testGroup.test_group_user_survey).length >
      0 && (
      <SurveySection
        completed={!!Object.keys(user.test_group_user.survey || {}).length}
        saveSurvey={(data) => axios.put(`/test_groups/${testGroup.id}/appointments/${appointment.id}/checkout/save_test_group_user_survey`,
          { test_group_user_survey_answers: data }
        )}
        inputName="test_group_user[survey]"
        name="test_group_user_survey"
        survey={testGroup.test_group_user_survey}
        title="Participant questionnaire"
        value={user.test_group_user.survey}
        {...props}
      />
    ),
    dependents: testGroup.allow_dependents && (
      <DependentsSection {...props} />
    ),
    symptoms: (
      <SymptomsSection {...props} />
    ),
    tags: (
      <TagSection {...props} />
    ),
    checkout_survey: testGroup.checkout_survey && Object.keys(testGroup.checkout_survey).length > 0 && (
      <SurveySection
        completed={!!Object.keys(appointment.checkout_survey || {}).length}
        saveSurvey={(data) => axios.put(`/test_groups/${testGroup.id}/appointments/${appointment.id}/checkout/save_checkout_survey`,
          { checkout_survey_answers: data }
        )}
        inputName="appointment[checkout_survey]"
        name="checkout_survey"
        survey={testGroup.checkout_survey}
        title="Checkout questionnaire"
        value={appointment.checkout_survey || {}}
        {...props}
      />
    ),
    insurance: showInsuranceSection && (
      <InsuranceSection {...props} />
    ),
    insurance_survey: testGroup.insurance_survey && (
      <SurveySection
        value={typeof(appointment.insurance_answers) == 'string' ? JSON.parse(appointment.insurance_answers) : appointment.insurance_answers}
        survey={testGroup.insurance_survey}
        title="Insurance Questionnaire"
        inputName="appointment[insurance_answers]"
        name="insurance_survey"
        newSurvey={testGroup.flipper_flags.upgraded_surveyjs}
        testGroup={testGroup}
        {...props}
      />
    ),
    show_immunization_history: (
      <ImmunizationHistory
        testGroupId={testGroup.slug}
        user={user}
        isHipaaTrained={currentUserIsHipaaTrained}
        tests={appointment.tests}
        histories={user.vaccine_histories}
        iisReferences={user.iis_references}
      />
    )
  };

  const sectionKeys = activeSections.filter(section => !!sections[section]);
  if (sectionKeys.some(k => SURVEY_SECTIONS.includes(k))) sectionKeys.push("survey");
  sectionKeys.push("services");
  if (sidebarKeys.length != sectionKeys.length || !sidebarKeys.every((value, index) => value === sectionKeys[index])) {
    setSidebarKeys(sectionKeys);
  }

  return (
    <div className="h5-vivid-blue">
      <input type="hidden" name="_method" value="put" />
      <input
        type="hidden"
        name="authenticity_token"
        value={appointment.form_authenticity_token}
      />
      <input type="hidden" value={checkIn} name="appointment[check_in]" />
      <input type="hidden" value={checkOut} name="appointment[check_out]" />
      {activeSections.map((section, idx) =>
        <React.Fragment key={idx}>
          {sections[section]}
        </React.Fragment>
      )}
      {!disableAdvanced &&
        <Accordion>
          <Accordion.Toggle
            as={Button}
            variant="link"
            className="mb-2 p-0"
            eventKey="0"
          >
            Advanced
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <div
            className="h6 custom-control custom-switch custom-switch-lg"
              onClick={() => setCheckIn(!checkIn)}
            >
              <input
                type="radio"
                defaultChecked={checkIn}
                checked={checkIn}
                className="custom-control-input"
              />
              <label className="custom-control-label">Confirm Check In</label>
            </div>
          </Accordion.Collapse>
        </Accordion>
      }
    </div>
  );
};

const CheckInModal = ({
  setShow,
  show,
  appointment,
  user,
  symptoms,
  testGroup,
  testConfigurations=[],
  redirect = true,
  overrideCheckin = () => {},
}) => {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const appointmentIsToday = appointment.on_demand
    ? true
    : today(appointment.appointment_slot.localized_day);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = document.querySelector('#checkInModal')
    const formData = new FormData(form)

    axios({
      url: `/test_groups/${testGroup.id}/appointments/${appointment.id}`,
      method: "post",
      data: formData,
      headers: { 'content-type': 'multipart/form-data' },
    })
      .then(() => {
        checkIn && toastr.success('Checked In');
        overrideCheckin(checkIn);
        handleClose();
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      data-test-hook="check_in_modal"
    >
      <Modal.Header>
        <Modal.Title as="h2">
          Check In for {user.first_name} {user.last_name}{' '}
          {!appointmentIsToday && (
            <div className="alert-danger">
              This appointment is not scheduled for today.
            </div>
          )}
          {appointment.follow_up_time && (
            <div className="h5">Follow Up: {appointment.follow_up_time}</div>
          )}
        </Modal.Title>
        <button
          type="button"
          className="btn-close"
          onClick={handleClose}
          aria-label="Close"
        ></button>
      </Modal.Header>
      <form
        onSubmit={handleSubmit}
        encType="multipart/form-data"
        action={`/test_groups/${testGroup.id}/appointments/${appointment.id}`}
        acceptCharset="UTF-8"
        method="post"
        id="checkInModal"
        className="h5-vivid-blue"
      >
        <Modal.Body>
          <CheckInInputs
            appointment={appointment}
            user={user}
            symptoms={symptoms}
            testGroup={testGroup}
            testConfigurations={testConfigurations}
            activeSections={SECTIONS_IN_ORDER.filter(section => !testGroup.disabled_checkin_options.includes(section))}
          />
        </Modal.Body>

        <Modal.Footer>
          {appointment.links?.vaccine_consent_form && (
            <a
              onClick={() => printJS(appointment.links.vaccine_consent_form)}
              className="btn btn-outline-primary me-auto"
            >
              Print Vaccine Consent Form
            </a>
          )}
          <Button variant="outline-secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            type="submit"
            data-test-hook="save"
            onClick={redirect ? null : handleSubmit}
          >
            Save changes
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default CheckInModal;
