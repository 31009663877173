import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import FloatingLabelSelect from './FloatingLabelSelect';

const FormSelect = ({
  fieldName,
  prompt,
  options,
  selected,
  onChange,
  required,
  creatable = false,
  name,
  value,
  className="",
  isDisabled,
  containerClassName,
}) => {
  const [inputValue, setInputValue] = useState(selected);

  const props = {
    className: `basic-single ${className || ''}`,
    classNamePrefix: 'select',
    placeholder: prompt,
    label: prompt,
    defaultValue: options.find(
      (option) =>
        option.value === parseInt(selected) || option.value === selected ||
        option.value === parseInt(value) || option.value === value,
    ),
    onChange: (e) => {
      const value = e ? e.value : null;
      setInputValue(value == 0 ? true : value);
      onChange && onChange(value);
    },
    components: { IndicatorSeparator: () => null },
    options: options,
    isSearchable: true,
    isClearable: true,
    name: fieldName || name,
    filledValue: inputValue,
    ariaRequired: required,
    isDisabled: isDisabled,
    containerClassName: containerClassName,
  };

  return (
    <div>
      {creatable ? (
        <CreatableSelect
          formatCreateLabel={(value) => `Other - click here to add: ${value}`}
          {...props}
        />
      ) : (
        <FloatingLabelSelect {...props} />
      )}
    </div>
  );
};

export default FormSelect;
