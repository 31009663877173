import React from 'react';
import { Tooltip, OverlayTrigger, Button } from 'react-bootstrap';
import { Badge, BadgeWithTooltip } from '../../common/components/Badge';

const TestGroupUserRow = ({
  test_group_user,
  key,
  is_global_search,
  on_demand_only,
  assignAppointmentSlotGroup,
 }) => {
  const { test_group, user, links } = test_group_user;

  let appointment_link = links.participant_url;
  let waitlist_link = links.waitlist_url;
  const hasMissingRequiredInfo = user.missing_required_info.length > 0;
  const readyToCheckout = user.ready_to_checkout;
  const isOnDemand =
    !['draft', 'archived'].includes(test_group.status) &&
    test_group.has_on_demand_location;
  if (is_global_search) {
    appointment_link = links.global_search.global_search_participant_url;
    waitlist_link = links.global_search.global_search_waitlist_url;
  }

  const CheckoutButton = ({
    links: {
      create_on_demand_appointment_url,
    },
    readyToCheckout,
    onClick: propOnClick,
  }) => {
    const buildButtonProps = (href) =>
      propOnClick
        ? { onClick: () => propOnClick(href) }
        : { href: href, 'data-method': 'post' };

    const notReadyTooltip = (props) => (
      !readyToCheckout
      ? <Tooltip {...props}>This participant cannot be checked out until their required information is filled in.</Tooltip>
      : <span></span>
    );

    return (
      <OverlayTrigger overlay={notReadyTooltip}>
        <span>
          <Button
            variant="outline-gray"
            disabled={!readyToCheckout}
            className="gray-if-disabled"
            {...buildButtonProps(create_on_demand_appointment_url)}
          >
            Checkout
          </Button>
        </span>
      </OverlayTrigger>
    )
  };

  return (
    <React.Fragment>
      <td key={key}>
        <div className="fw-bold">
          <a href={links.participant_path}>
            {user.first_name} {user.last_name}
          </a>
        </div>
        <div className="flex">
          {user.date_of_birth} (age {user.age})
          {user.age < 18 && <span className="badge bg-light text-secondary ms-1">Minor</span>}
        </div>
      </td>
      <td>
        {user.phone_number && (
          <div>{user.phone_formatted}</div>
        )}
        {user.email && (
          <div>{user.email}</div>
        )}
      </td>
      <td>
        {hasMissingRequiredInfo && (
          <BadgeWithTooltip
            title={`Missing information: ${user.missing_required_info.join(", ")}`}
            label="Missing information"
            link={links.edit_test_group_user_url}
            color="#FFFFFF"
            backgroundColor="#D44F58"
            icon="fa-circle-exclamation"
          />
        )}
        {!on_demand_only && test_group_user.has_appointment && (
          <Badge
            label="Has appointment"
            link={links.test_group_participant_url}
            color="#2E948E"
            backgroundColor="#EEFBF9"
          />
        )}
        {!test_group_user.is_waitlisted && (
          <Badge
            label="Waitlisted"
            link={waitlist_link}
            color="#586DA0"
            backgroundColor="#F7F7F7"
          />
        )}
        {!test_group_user.archived && (
          <Badge
            label="Archived"
            link={appointment_link}
            color="#C71A00"
            backgroundColor="#FFEFE8"
          />
        )}
        {!is_global_search &&
          test_group.has_active_consent &&
          !test_group_user.consented && (
            <BadgeWithTooltip
              title={test_group_user.consent_gone_status}
              link={links.consent_url}
              color="#F70235"
              backgroundColor="#F3E2E3"
              icon="fa-circle-exclamation"
            />
        )}
      </td>
      {isOnDemand && (
        <td>
          <CheckoutButton
            links={links}
            readyToCheckout={readyToCheckout}
            onClick={
              test_group.assign_appointment_slot_groups_to_users &&
              assignAppointmentSlotGroup
            }
          />
        </td>
      )}
      {!on_demand_only && (
        <td>
          <a
            href={links.create_appointment_url}
            className="btn btn-outline-gray"
          >
              Create appointment
          </a>
        </td>
      )}
      <td>
        <div className="dropdown my-auto">
          <a
            className="text-secondary mx-2"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa-regular fa-chevron-right"></i>
          </a>
          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
            <li>
              <a href={links.medical_history_url} className="dropdown-item fw-normal">Patient Details</a>
            </li>
            <li><div className="dropdown-divider"></div></li>
            <li>
              <a href={links.edit_test_group_user_url} className="dropdown-item fw-normal">Edit patient</a>
            </li>
          </ul>
        </div>
      </td>
    </React.Fragment>
  );
};

export default TestGroupUserRow;
