import { format } from 'date-fns';

export const unixToDateTime = (unixTime) => {
  if (unixTime) {
    const standardDate = new Date(unixTime * 1000);
    const date = format(standardDate, 'yyyy-MM-dd');
    const time = format(standardDate, 'HH:mm');

    const formattedTime = date + 'T' + time;
    return formattedTime;
  } else {
    return null;
  }
};

export const formattedDateTime = (dateTime) => {
  if (!dateTime) return null;
  let date, time;
  try {
    date = dateTime.split('T')[0];
    time = dateTime.split('T')[1].split('.')[0];
  } catch {
    date = format(new Date(dateTime), 'yyyy-MM-dd');
    time = format(new Date(dateTime), 'HH:mm');
  }

  const formattedTime = date + 'T' + time;

  return formattedTime;
};

export const toUnix = (dateTime) => {
  return dateTime ? Math.round(new Date(dateTime).getTime() / 1000) : null;
};

export const formatTextUid = (uid) => {
  if (!uid) return uid;

  if (uid.length < 15) {
    return uid;
  }
  return uid.substr(0, 6) + '...' + uid.substr(uid.length - 6);
};

export const getCookie = (key) => {
  var b = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)');
  return b ? unescape(b.pop()) : '';
};

export const getTestGroups = () =>
  getCookie('testGroups') || localStorage.getItem('testGroups');

export const testRedirect = (test) => {
  let serviceSpecification = test.test_configuration.service_specification;
  let route = genericTestSpecificationRouteMappings[serviceSpecification];
  let url;

  if (route) {
    url = `/${route}_test_results/${test.uid}`;
  } else {
    route = serviceSpecificationRouteMappings[serviceSpecification];
    url = `/${route}/results/${test.uid}`;
  }
  if (url) {
    window.location.href = url;
  }
};

const genericTestSpecificationRouteMappings = {
  rapid_chembio: 'chembio',
  rapid_flowflex: 'flowflex',
  rapid_indicaid: 'indicaid',
  rapid_lucira: 'lucira',
  rapid_lumira: 'lumira',
  rapid_quidel_lyra: 'quidel_lyra',
  rapid_quidel_quickvue: 'quidel_quickvue',
  rapid_quidel_quickvue_rsv: 'quidel_quickvue_rsv',
  rapid_status_strep_a_flip: 'status_strep_a_flip',
  rapid_universal: 'universal',
};
const serviceSpecificationRouteMappings = {
  ...genericTestSpecificationRouteMappings,
  alere_cholestech_ldx: 'alere_cholestech_ldx',
  abbott_id_now_rsv: 'abbott_id_now_rsv',
  cepheid_rapid_pcr: 'cepheid',
  cobas: 'cobas',
  hba1c: 'a1c',
  i_health: 'i_health',
  istat_creatinine: 'istat_creatinine',
  lead_venous: 'lead_venous',
  luminostics_rapid_pcr: 'luminostics',
  naat_abbott_id_now: 'abbottidnow',
  naat_accula: 'accula',
  naat_cue: 'cue',
  naat_lucira_flu_ab_covid19: 'lucira_flu_ab_covid19',
  rapid_antigen_abbott_covid19: 'abbott',
  rapid_antigen_bdveritor_covid19: 'bdveritor',
  rapid_antigen_bdveritor_flu_ab_covid19: 'bdveritor_flu_ab_covid19',
  rapid_antigen_carestart_covid19: 'carestart',
  rapid_antigen_lifesign_status_flu_ab_covid19:
    'lifesign_status_flu_ab_covid19',
  rapid_antigen_quidel_covid19: 'quidel',
  rapid_assure_ecotest_covid19: 'assure_ecotest_covid19',
  rapid_bdveritor_flu_ab: 'bdveritor_flu_ab',
  rapid_hcv: 'rapid_hcv',
  rapid_hiv: 'hiv',
  rapid_syphilis: 'rapid_syphilis',
  rapid_i_health: 'i_health',
  rapid_quidel_quickvue_influenza_ab: 'quidel_quickvue_flu_ab',
  sofia2: 'sofia2',
  spitfire_rapid_pcr: 'spitfire',
  visby_rapid_pcr: 'visby',
  xpert_xpress: 'xpert_xpress',
  abbott_id_now_flu_a_b_2: 'abbott_id_now_flu_a_b_2',
  creatinine: 'creatinine',
  lead_standard_profile: 'lead_standard_profile',
  rapid_pcr_flu_ab_multiplex: 'rapid_pcr_flu_ab_multiplex',
  blood_glucose: 'blood_glucose',
  hemoglobin: 'hemoglobin',
  a1c_now: 'a1c_now',
  total_cholesterol_fasted: 'total_cholesterol_fasted',
  total_cholesterol_unfasted: 'total_cholesterol_unfasted',
  blood_glucose_fasted: 'blood_glucose_fasted'
};

export const getTestResultType = (service_specification) => {
  return serviceSpecificationRouteMappings[service_specification];
};

export const uploadPhoto = (
  testImageData,
  url,
  dispatchTestResults,
  hasUnapprovedResult = false,
  reloadAfterDispatch = true,
) => {
  if (hasUnapprovedResult) {
    return toastr.error(
      'Cannot save positive result photo without final approval.',
    );
  } else {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ test: { test_image_base64: testImageData } }),
      redirect: 'manual',
    };

    fetch(url, requestOptions).then((response) => {
      if (response.ok) {
        dispatchTestResults(reloadAfterDispatch);
      } else {
        return toastr.error('Failed to save photo.');
      }
    });
  }
};

export const getTestErrorMessage = (error) =>
  error.response.status === 401
    ? 'Not authorized to perform this action'
    : error.response.data?.error || 'Problem locating test';

export const parseRange = (rangeString) => {
  let [from, to] = rangeString.split('..');
  if (!from) from = Number.NEGATIVE_INFINITY;
  if (!to) to = Number.POSITIVE_INFINITY;
  return { from: parseFloat(from), to: parseFloat(to) };
};

export const formatRangeValue = (value, rangeProps) => {
  let valueLabel = value.from;
  if (rangeProps?.cut_off) {
    const cutOff = rangeProps.cut_off;
    const beginlessRange = rangeProps.from == Number.NEGATIVE_INFINITY;
    const endlessRange = rangeProps.to == Number.POSITIVE_INFINITY;
    if (beginlessRange && value.from < cutOff) {
      valueLabel = `<${cutOff}`;
    } else if (endlessRange && value.from > cutOff) {
      valueLabel = `>${cutOff}`;
    }
  } else {
    if (value.from && value.to) {
      if (value.from != value.to) {
        valueLabel = `${value.from} - ${value.to}`;
      } else {
        valueLabel = value.from;
      }
    } else if (value.from) {
      valueLabel = `>${value.from}`;
    } else {
      valueLabel = `<${value.to}`;
    }
  }
  return valueLabel;
};

export const hasExistingData = (data) => {
  if (!data) return false;

  const { result, notes, results } = data;

  return result || notes || results.find(resultHasValue);
};

export const resultHasValue = (result) => {
  return result.value || result.value_numeric || result.value_date_time;
};
