import React from "react";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const renderTooltip = (title, props) => (
  <Tooltip className="tooltip-small tooltip-arrow" {...props}>
    {title}
  </Tooltip>
);

const BadgeBase = ({
  label,
  color,
  backgroundColor,
  icon,
}) => {
  return (
    <span
      className="my-auto me-1"
      style={{
        color: color,
        backgroundColor: backgroundColor,
        padding: "4px 8px",
        borderRadius: "4px",
      }}
    >
      {icon && (
        <i className={`fa-regular ${icon} ${label && 'me-1'}`} style={{height: "12px", marginBottom: "2px"}}></i>
      )}
      {label && (
        <span
          className="my-auto"
          style={{
            fontSize: "14px",
            lineHeight: "16px",
            fontWeight: "700",
          }}
        >
          {label}
        </span>
      )}
    </span>
  )
};

export const Badge = ({
  label,
  link,
  color,
  backgroundColor,
  icon,
}) => {
  return (
    link ? (
      <a
        href={link}
        style={{textDecoration: "none"}}
        className="d-inline-block mb-2"
      >
        <BadgeBase
          label={label}
          color={color}
          backgroundColor={backgroundColor}
          icon={icon}
        />
      </a>
    ) : (
      <BadgeBase
        label={label}
        color={color}
        backgroundColor={backgroundColor}
        icon={icon}
      />
    )
  )
};

export const BadgeWithTooltip = ({
  title,
  label,
  link,
  color,
  backgroundColor,
  icon,
}) => {
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip(title)}
    >
      <span>
        <Badge
          label={label}
          link={link}
          color={color}
          backgroundColor={backgroundColor}
          icon={icon}
        />
      </span>
    </OverlayTrigger>
  )
};

export default Badge;
