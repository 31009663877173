import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ExpandableSidebar = ({items, current_path}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const isActive = (url) => {
    return current_path === url && 'active';
  }

  return (
    <div
      className={`sidebar-nav expandable d-lg-block d-none ${isExpanded && 'expanded'}`}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      {
        items.map(item => (
          <a key={item.label} href={item.url} className={`dropdown-item ${isActive(item.url)}`} data={item.data}>
            <FontAwesomeIcon icon={['fa-regular', item.icon]} />
            {isExpanded && <span>{item.label}</span>}
          </a>
        ))
      }
    </div>
  );
};

export default ExpandableSidebar;
