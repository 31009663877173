import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

import {
  AdministrationStep,
  ConfirmationStep,
  ErrorSummary,
  NotesStep,
  PrintStep,
  ScreeningStep,
  VaccineInfoSheetStep,
  VaccineLotStep,
  VaccineProgramStep,
} from '../fields/VaccineStepsV2';
import { SaveRemoveServiceButtons } from '../ServiceCard';
import { vaccineTestShape } from '../shapes';


const VFA_NAMES = {
  mn: {
    title: 'UUAV eligibility',
    subTitle: 'What UUAV (Uninsured and Underinsured Adult Vaccine) criteria applies to this patient? *',
  },
};

export const computeCompleteStatus = (test, vaccineResult, surveyAnswers) => {
  let reasons = {};

  if (vaccineResult.request_vfa) {
    if (!vaccineResult['vfa_eligibility']) reasons['vfa_eligibility'] = 'UUAV eligibility answer';
  }

  if (vaccineResult.request_vfc) {
    if (!vaccineResult['vfc_eligibility']) reasons['vfc_eligibility'] = 'VFC eligibility answer';
  }

  if (vaccineResult.request_funding_for_vfa || vaccineResult.request_funding_for_vfc) {
    if (!vaccineResult['funding_source']) reasons['funding_source'] = 'Funding source answer';
  }

  [
    { key: 'administrator_initials', name: 'Administrator Initials' },
    { key: 'administrator_name', name: 'Administrator Name' },
    { key: 'administrator_degree', name: 'Administrator Degree' },
    { key: 'vaccine_lot_id', name: 'Vaccine Lot' },
  ].forEach((field) => {
    if (!vaccineResult[field.key] || vaccineResult[field.key] == "") {
      reasons[field.key] = field.name;
    }
  });
  const completedAdministratorAndLot = Object.keys(reasons).length === 0;

  [
    { key: 'dose', name: 'Dose' },
    { key: 'dose_name', name: 'Administered dose' },
    { key: 'administration_site', name: 'Administration site' },
    { key: 'statement_provided', name: 'Statement provided' },
    { key: 'administration_type', name: 'Administration type' },
  ].forEach((field) => {
    if (!vaccineResult[field.key] || vaccineResult[field.key] == "") {
      reasons[field.key] = field.name;
    }
  });

  if (surveyAnswers && !surveyAnswers.admin_approved) {
    reasons['admin_approved'] = 'Screening Questions Approved';
  }
  const completedVaccineResults =
    Object.keys(reasons).length === 0 ||
    vaccineResult.refusal_state !== 'no_refusal';

  let complete = completedAdministratorAndLot && completedVaccineResults;

  if (test.administered == false) { // has to be explicitly false
    complete = true;
    reasons = {};
  }

  return {
    complete,
    reasons,
  };
};

const VaccineFormV2 = ({
  hidePrintOptions = false,
  onComplete,
  setIsOpen,
  submitTests,
  test,
  updateTest,
  ...props
}) => {
  const {
    vaccine_result: vaccineResult,
    test_configuration: testConfiguration,
    vaccine_result: { vaccine_route },
  } = test;

  const surveyAnswers = test.use_medical_screening_survey_on_checkout
    ? test.medical_screening_survey_answers
    : test.test_survey;
  const [showError, setShowError] = useState(false);
  const [vaccineRoute, setVaccineRoute] = useState(vaccine_route);
  const vaccineInfoSheetProps = vaccineRoute?.vaccine_info_sheets_props;
  const [administrationLogged, setAdministrationLogged] = useState(test.administered);

  const { complete, reasons } = computeCompleteStatus(
    test,
    vaccineResult,
    surveyAnswers,
  );

  const reasonKeys = Object.keys(reasons);

  const handleSave = () => {
    setShowError(!complete);
    if (complete) {
      updateTest({
        ...test,
        administered: test?.administered == false ? false : true,
        saved: true,
        vaccine_result: {
          ...vaccineResult,
          refusal_state: 'no_refusal',
          refusal_note: null,
        },
      });
      submitTests([
        {
          ...test,
          administered: test?.administered == false ? false : true,
          saved: true,
          vaccine_result: {
            ...vaccineResult,
            refusal_state: 'no_refusal',
            refusal_note: null,
          },
        },
      ]);
      setIsOpen(false);
    }
  };

  return (
    <div className="mx-2 col">
      <ScreeningStep
        completeReasons={reasonKeys}
        showError={showError}
        test={test}
        updateTest={updateTest}
      />
      {testConfiguration.vaccine_lots ? (
        <div className="mb-4">
          <Form className="w-100 mb-2">
            {(vaccineResult.request_vfa || vaccineResult.request_funding_for_vfa) && (
              <VaccineProgramStep
                completeReasons={reasonKeys}
                fundingSourceOptions={vaccineResult.funding_source_options}
                options={vaccineResult.vfa_options}
                requestEligibility={vaccineResult.request_vfa}
                requestFundingSource={vaccineResult.request_funding_for_vfa}
                showError={showError}
                subTitle={VFA_NAMES[vaccineResult.state]?.subTitle || "What vaccine program criteria applies to this patient? *"}
                test={test}
                title={VFA_NAMES[vaccineResult.state]?.title || "Vaccine program eligibility"}
                updateTest={updateTest}
                vaccineProgramField="vfa_eligibility"
              />
            )}
            {(vaccineResult.request_vfc || vaccineResult.request_funding_for_vfc) && (
              <VaccineProgramStep
                completeReasons={reasonKeys}
                fundingSourceOptions={vaccineResult.funding_source_options}
                options={vaccineResult.vfc_options}
                requestEligibility={vaccineResult.request_vfc}
                requestFundingSource={vaccineResult.request_funding_for_vfc}
                showError={showError}
                subTitle="What VFC criteria applies to this patient? *"
                test={test}
                title="VFC eligibility"
                updateTest={updateTest}
                vaccineProgramField="vfc_eligibility"
              />
            )}
            <VaccineLotStep
              complete={complete}
              completeReasons={reasonKeys}
              setVaccineRoute={setVaccineRoute}
              showError={showError}
              test={test}
              updateTest={updateTest}
              vaccineRoute={vaccineRoute}
            />
            <AdministrationStep
              complete={complete}
              completeReasons={reasonKeys}
              showError={showError}
              test={test}
              updateTest={updateTest}
            />
            <ConfirmationStep
              administrationLogged={administrationLogged}
              complete={complete}
              completeReasons={reasons}
              onComplete={onComplete}
              setAdministrationLogged={setAdministrationLogged}
              setShowError={setShowError}
              showError={showError}
              submitTests={submitTests}
              test={test}
              updateTest={updateTest}
              vaccineInfoSheetProps={vaccineInfoSheetProps}
              {...props}
            />
            {!hidePrintOptions && (
              <PrintStep
                onComplete={onComplete}
                submitTests={submitTests}
                test={test}
                updateTest={updateTest}
              />
            )}
            <VaccineInfoSheetStep
              completeReasons={reasons}
              showError={showError}
              test={test}
              updateTest={updateTest}
              vaccineInfoSheetProps={vaccineInfoSheetProps}
              vaccineResult={vaccineResult}
            />
            <NotesStep
              test={test}
              updateTest={updateTest}
              {...props}
            />
            <ErrorSummary
              completeReasons={reasons}
              showError={showError}
            />
          </Form>
          <SaveRemoveServiceButtons
            displayRemove={!vaccineResult.id}
            label="Save"
            onClick={handleSave}
            slug={test.test_group.slug}
            testId={test.id}
          />
        </div>
      ) : (
        'Add Vaccines to Inventory'
      )}
    </div>
  );
};

VaccineFormV2.propTypes = vaccineTestShape;

export default VaccineFormV2;
