export default {
  "date": {
    "order": [
      "year",
      "month",
      "day"
    ],
    "month": "ເດືອນ",
    "day": "ວັນ",
    "year": "ປີ",
    "january": "ມັງກອນ",
    "february": "ກຸມພາ",
    "march": "ມີນາ",
    "april": "ເມສາ",
    "may": "ພຶດສະພາ",
    "june": "ມິຖຸນາ",
    "july": "ກໍລະກົດ",
    "august": "ສິງຫາ",
    "september": "ກັນຍາ",
    "october": "ຕຸລາ",
    "november": "ພະຈິກ",
    "december": "ທັນວາ"
  },
  "user": {
    "send_appointment_confirmation_message": "ການນັດໝາຍຂອງທ່ານໄດ້ຮັບການຢືນຢັນສຳລັບ {{name}}.",
    "landline_appointment_reminder_message": "ສະ​ບາຍ​ດີ, {{full_name}}. ນີ້​ເປັນ​ການ​ເຕືອນ​ສຳ​ລັບ​ການ​ນັດ​ໝາຍ​ຂອງ​ທ່ານ​ຢູ່​ທີ່ {{name}}. ການ​ນັດ​ໝາຍ​ຂອງທ່ານໃນ​ວັນ​ທີ {{date}} ເວ​ລາ {{time}} ຢູ່​ທີ່ {{address}}.",
    "send_at_home_visit_confirmation_message": "ການ​ນັດ​ໝາຍ​ຂອງທ່ານໄດ້​ຮັບ​ການ​ຢືນ​ຢັນໃນ​ວັນ​ທີ {{date}} ຫຼັງ​ຈາກ {{time}}.",
    "send_mail_order_confirmation_message": "ກາ​ນ​ສັ່ງທາງ​ໄປ​ສະ​ນີ​ຂອງທ່ານໄດ້ຮັບການຢືນຢັນແລ້ວ",
    "send_waitlist_message": "ທ່ານ​ໄດ້​ຢູ່​ໃນ​ບັນ​ຊີ​ລໍ​ຖ້າ​ສຳ​ລັບ {{name}}. ກະ​ລຸ​ນາ​ຢ່າ​ມາ​ປະ​ກົດ​ຕົວ​ຈົນ​ກວ່າ​ພວກ​ເຮົາ​ຢືນ​ຢັນ​ເວ​ລາ​ນັດ​ໝາຍ​ຂອງ​ທ່ານ.",
    "verify_contact": {
      "phone": "ກະລຸນາຢືນ​ຢັນເບີໂທລະສັບ​ຂອງ​ທ່ານໂດຍ​ການຄ​ລິກ: {{confirmation_link}}",
      "email": "{{confirmation_link}}"
    },
    "duplicate_user_continue_registering": "ສືບ​ຕໍ່​ລົງ​ທະ​ບຽນ​ສຳ​ລັບ​ການ​ນັດ​ໝາຍ​ຢູ່​ບ່ອນ​ນີ້: {{url}}",
    "on_demand_confirmation_message": "ທ່ານ​ໄດ້​ລົງທະບຽນ​ສຳ​ລັບ {{name}} ສຳເລັດແລ້ວ.",
    "appointment_reminder": "ກ່ອນ​ການນັດ​ໝາຍ​ຂອງ​ທ່ານ​ໃນ​ເວ​ລາ {{start_time}}, ກະ​ລຸ​ນາ​ຄ​ລິກໃສ່​ລິ້ງ​ເພື່ອເຮັດ​ລາຍ​ການ​ດຳ​ເນີນ​ການ​ທີ່​ຍັງ​ຄ້າງ​ຢູ່​ໃຫ້​ສຳ​ເລັດ ແລະ ເບິ່ງ​ຂໍ້​ມູນ​ຂອງ​ທ່ານ. ກະ​ລຸ​ນາ​ຢ່າ​ມາ​ຮອດ​ກ່ອນ​ເວ​ລາ​ນັດ​ໝາຍ​ຫຼາຍກວ່າ 15 ນາ​ທີ.",
    "appointment_reminder_base": "{{text}} ຄ​ລິກ​ບ່ອນ​ນີ້: {{link}}",
    "test_result_notifier": {
      "text_message": "ຜອດ​ໂທ​ລ​ຄົນ​ເຈັບສຳ​ລັບ {{first_name_with_last_initial}} ໄດ້​ຮັບ​ການ​ອັບ​ເດດ​ແລ້ວ. ໄປ​ທີ່ {{url}} ແລະ ໃຊ້​ລະ​ຫັດ: {{access_code}}",
      "email_subject": "ການ​ອັບ​ເດດ​ໃໝ່​ຢູ່​ໃນ​ຜອດ​ໂທ​ລ​ຄົນ​ເຈັບ​ຂອງ​ທ່ານ",
      "email_intro": "ສະ​ບາຍ​ດີ {{first_name_with_last_initial}}",
      "email_p1": "​ຂໍ້​ຄວາມ​ໃໝ່ ຫຼື ການ​ອັບ​ເດດ​ຄົນ​ເຈັບ​ແມ່​ນ​ມີ​ໃຫ້​ສຳ​ລັບ​ທ່ານ.",
      "email_p2": "ຄ​ລິກ​ປຸ່ມ​ຢູ່​ລຸ່ມ​ນີ້ ຫຼື ໃຊ້​ລິ້ງ​ເພື່ອ​ເບິ່ງ​ຜອດ​ໂທ​ລ​ຄົນ​ເຈັບ​ຂອງ​ທ່ານ ແລະ ໃຊ້​ລະ​ຫັດ",
      "email_p3": "ເບິ່ງ​ຜອດ​ໂທ​ລ​ຄົນ​ເຈັບ​ຢູ່​ທີ່:'",
      "email_button": "ເບິ່ງ​ຜອດ​ໂທ​ລ​"
    },
    "landline_appointment_reminder_message_without_time": "ສະ​ບາຍ​ດີ, {{full_name}}. ນີ້​ເປັນ​ການ​ເຕືອນ​ສຳ​ລັບ​ການ​ນັດ​ໝາຍ​ຂອງ​ທ່ານ​ຢູ່​ທີ່ {{name}}. ການ​ນັດ​ໝາຍ​ຂອງທ່ານໃນ​ວັນ​ທີ {{date}} ຢູ່​ທີ່ {{address}}.",
    "appointment_reminder_on_demand": "ກ່ອນ​ການນັດ​ໝາຍ​ຂອງ​ທ່ານ​ໃນວັນ​ທີ {{date}}, ກະ​ລຸ​ນາ​ຄ​ລິກໃສ່​ລິ້ງ​ເພື່ອເຮັດ​ລາຍ​ການ​ດຳ​ເນີນ​ການ​ທີ່​ຍັງ​ຄ້າງ​ຢູ່​ໃຫ້​ສຳ​ເລັດ ແລະ ເບິ່ງ​ຂໍ້​ມູນ​ຂອງ​ທ່ານ.",
    "new_test_result_notifier": {
      "email_intro": "ເບິ່ງ​ຜອດ​ໂທ​ລ​ຄົນ​ເຈັບ​ຂອງ​ທ່ານ​ໄດ້​ຮັບ​ການ​ອັບ​ເດດ​ແລ້ວ.",
      "email_text_1": "ເບິ່ງ​ຜອດ​ໂທ​ລ​ປະ​ຫວັດ​ທາງ​ການ​ແພດ​ຂອງ​ທ່ານ​ຢູ່​ລຸ່ມ​ນີ້​ເພື່ອ​ເບິ່ງ​ບັນ​ທຶກ​ຄົນ​ເຈັບ​ໃໝ່ ຫຼື ບົດ​ລາຍ​ງາ​ນ​ຫ້ອງ​ແລັບ​ໃດ​ໜຶ່ງ.",
      "email_button": "ປະ​ຫວັດ​ທາງ​ການ​ແພດ",
      "email_text_2": "ທ່ານຍັງສາມາດກັອບ​ປີ້ ແລະ ວາງ URL ນີ້ໃນບຣາວເຊີຂອງທ່ານ."
    },
    "mailers": {
      "email_contact_us": "ມີຄຳ​ຖາມບໍ? ຕິດຕໍ່ພວກເຮົາທີ່"
    }
  },
  "errors": {
    "generic": {
      "message": "ມີ​ບາງ​ຢ່າງ​ຜິດພາດ, ກະ​ລຸ​ນາ​ລອງ​ໃໝ່​ອີກ"
    },
    "messages": {
      "blank": "ບໍ່​ສາ​ມາດປ່ອຍ​ໃຫ້​ຫວ່າງ​ເປົ່າໄດ້"
    },
    "incorrect_credentials": "ຂໍ້ມູນສ່ວນຕົວຂອງທ່ານບໍ່ຖືກຕ້ອງ, ກະ​ລຸ​ນາ​ລອງ​ອີກຄັ້ງ.",
    "error_activating_test_kit": "ມີ​ຄວາມ​ຜິດພາດ​ໃນ​ການເປີດໃຊ້ງານອຸປະກອນທົດສອບ."
  },
  "type": "ປະ​ເພດ",
  "payment": {
    "continue_label": "ສືບ​ຕໍ່​ໄປ​ຫາການ​ຊຳ​ລະເງິນ",
    "policy_text": "ຖ້າ​ທ່ານ​ຍົກ​ເລີກ ຫຼື ບໍ່​ມາ​ຕາມ​ການ​ນັດ​ໝາຍ​ຂອງ​ທ່ານ, ພວກ​ເຮົາ​ຈະ​ສົ່ງ​ເງິນທີ່​ທ່ານ​ຊຳ​ລະ​ຄືນ 80%, 7 ວັນ​ຫຼັງ​ຈາກວັນ​ທີ​ນັດ​ໝາຍ​ຂອງ​ທ່ານ. ທ່ານ​ອາດ​ຈະ​ເບິ່ງ [Terms of Service]({{link}}) ສະ​ບັບ​ເຕັມ​ໄດ້",
    "card_number": "ເລກບັດ",
    "expiry_date": "ວັນ​ທີ​ໝົດກຳ​ນົດ",
    "pay_button": "ຈ່າຍ",
    "no_credit_card": "ຂ້າ​ພະ​ເຈົ້າ​ຈະ​ຊຳ​ລ​ະ​ເງິນ​ໃນ​ເວັບ​ໄຊ",
    "cash_payment_helper": "ກະ​ລຸ​ນາ​ໃຫ້​ແນ່​ໃຈ​ວ່າ​ໄດ້​ເອົາ **{{payment}}** ມາ​ນຳ​ການ​ນັດ​ໝາຍ​ຂອງ​ທ່ານ",
    "invoice": "ໃບ​ຮຽກ​ເກັບ​ເງິນ",
    "pay_by_card": "ຈ່າຍ​ດ້ວຍ​ບັດ",
    "cost": "ມູນຄ່າ",
    "total": "ລວມທັງ​ໝົດ",
    "pay_cash_at_appointment": "ຂ້ອຍ​ຈະ​ຈ່າຍ​ດ້ວຍ​ເງິນ​ສົດ​ໃນ​ລະ​ຫວ່າງ​ການ​ນັດ​ໝາຍ​ຂອງ​ຂ້ອຍ​ຢູ່​ທີ່ {{appointment}}",
    "view_invoice": "ເບິ່ງ/ພິມ​ໃບ​ຮຽກ​ເກັບ​ເງິນ",
    "refund_policy": "ຖ້າ​ຫາກ​ທ່ານ​ຍົກ​ເລີກ​ການ​ນັດ​ໝາ​ຍ​ຂອງ​ທ່ານ, ພວກ​ເຮົາ​ຈະສົ່ງເງິນ​ຄືນ​ຈຳ​ນວນ {{amount}} ຂອງ​ການ​ຊຳ​ລະ​ເງິນ​ຂອງ​ທ່ານ, 7 ວັນຫຼັງ​ຈາກວັນ​ທີ​ການ​ນັດ​ໝາຍ​ຂອງ​ທ່ານ. ທ່ານ​ອາດ​ຈະ​ເບິ່ງ [ເງື່ອນໄຂການໃຫ້ບໍລິການ]({{link}}) ສະ​ບັບ​ເຕັມ​ໄດ້",
    "refund_window_passed": "ການ​ນັດ​ໝາຍ​ຂອງ​ທ່ານ​ແມ່ນບໍ່​ມີ​ສິດໄດ້​ຮັບ​ຕໍ່​ກັບ​ການສົ່ງເງິນ​ຄືນ​ເນື່ອງ​ຈາກວ່າ​ເວ​ລາ​ການ​ນັດ​ໝາຍ​ຜ່ານ​ມາ​ແລ້ວ. ກະ​ລຸ​ນາຕິດ​ຕໍ່​ຫາ​ຜູ້​ປະ​ສານ​ງານ​ເວັບ​ໄຊ​ໂດຍ​ກົງສຳ​ລັບ​ການ​ແກ້​ໄຂ​ບັນ​ຫາ​ໃດ​ໜຶ່ງ.",
    "amount_paid": "ຈຳນວນທີ່ຈ່າຍ",
    "balance": "ຍອດຄົງເຫຼືອ"
  },
  "consent_waiver": {
    "upload_waiver_or_sign": "ອັບ​ໂຫຼດ​ໃບ​ສະ​ຫຼະ​ສິດ​ຄຳ​ຍິນຍອມ ຫຼື ເຊັນ​ໃສ່​ຫ້ອງ​ລາຍ​ເຊັນ​ຢູ່​ລຸ່ມ​ນີ້",
    "stop": "ຄຳ​ຍິນຍອມ​ທີ່​ຕ້ອງ​ການ​ເພື່ອ​ລົງ​ທະ​ບຽນ​ໃຫ້​ສຳ​ເລັດ",
    "parent_or_guardian_required_html": "ເພື່ອ​ເຮັດ​ສຳ​ເລັດ​ການ​ລົງ​ທະ​ບຽນ​ສຸດ​ທ້າຍ​ຂອງ​ທ່ານ, ພໍ່​ແມ່, ຜູ້​ປົກ​ຄອງ ຫຼື ຜູ້​ຕັດ​ສິນ​ທີ່​ໄດ້​ຮັບ​ອະ​ນຸ​ຍາດ​ຂອງ​ທ່ານ​ຕ້ອງ​ໃຫ້​ຄຳ​ຍິນຍອມ​ໃນ​ແບບ​ຟອມ​ຢູ່​ລຸ່ມ​ນີ້ ແລະ ເຊັນ​ຊື່​ຂອງ​ພວກ​ເຂົາ. ທ່ານ<b>ບໍ່​ສາ​ມາດ</b>ຍິນຍອມ​ຕໍ່​ບໍ​ລິ​ການ​ນີ້​ດ້ວຍ​ຕົວ​ທ່ານ​ເອງ.",
    "send_parent_or_guardian": "ທ່ານ​ສາ​ມາດ​ສົ່ງ​ລິ້ງ​ນີ້​ໃຫ້​ພໍ່​ແມ່ ຫຼື ຜູ້​ປົກ​ຄອງ​ຂອງ​ທ່ານ ຫຼື ໃຫ້​ພວກ​ເຂົາ​ເຊັນ​ໃສ່​ລຸ່ມ​ນີ້​ຢູ່​ໃນ​ໜ້າ​ນີ້.",
    "consent_guardian_name_signature": "ຊື່​ແທ້",
    "consent_for_minor": "ເຊັນ​ໃບ​ຍິນຍອມ​ນີ້​ໃນ​ນາມ​ຂອງ​ເດັກ",
    "guardian_signature": "ລາຍ​ເຊັນ​ຂອງ​ພໍ່​ແມ່, ຜູ້ປົກຄອງ ຫຼື ຜູ້​ຕັດ​ສິນ​ທາງ​ການ​ແພດ​ທີ່​ໄດ້​ຮັບສິດ",
    "after_you_schedule": "ຜູ້​ເຂົ້າ​ຮ່ວມອາ​ຍຸ​ຕ່ຳ​ກວ່າ {{age}} ຕ້ອງ​ມີ​ຄຳ​ຍິນຍອມ​ເຊັນ​ໃນ​ນາມ​ຂອງ​ພໍ່​ແມ່ ຫຼື ຜູ້​ປົກ​ຄອງ. ກະລຸນາສືບ​ຕໍ່ໄປ​ຫາຕອນ​ທ້າຍ​ຂອງ​ການ​ລົງ​ທະ​ບຽນ​ເພື່ອ​ສົ່ງ​ໄປ​ຫາຜູ້​ປົກ​ຄອງ​ຂອງ​ທ່ານ ຫຼື ເຊັນ​ສຳ​ລັບ​ຜູ້​ຂຶ້ນ​ກັບ​ຂອງ​ທ່ານ.",
    "consent_guardian_last_name_signature": "ນາມສະກຸນ",
    "consent_first_and_last_name": "ຊື່ ແລະ ນາມ​ສະ​ກຸນ",
    "phone_number": "ອີ​ເມວຂອງຜູ້​ປົກ​ຄອງ",
    "email": "ອີ​ເມວຂອງຜູ້​ປົກ​ຄອງ",
    "consent_registrar_with_guardian_name_signature": "ຊື່ຜູ້​ປົກ​ຄອງ",
    "consent_registrar_with_guardian_last_name_signature": "ນາມ​ສະ​ກຸນຜູ້​ປົກ​ຄອງ",
    "consent_registrar_first_name_signature": "ຊື່ນາຍ​ທະ​ບຽນ",
    "consent_registrar_last_name_signature": "ນາມ​ສະ​ກຸນນາຍ​ທະ​ບຽນ",
    "consent_on_behalf": "ຂ້ອຍ​ກຳ​ລັງ​ເຂົ້າ​ສູ່​ລະ​ບົບ​ໃນ​ນາມ​ຂອງ {{first_name}} {{last_name}}.",
    "consent_on_behalf_registrar": "ຂ້າ​ພະ​ເຈົ້າ​ໄດ້​ຮັບ​ອະ​ນຸ​ຍາດ​ທາງ​ປາກ​ເປົ່າ​ໂດຍ {{first_name}} {{last_name}} ເພື່ອ​ເຂົ້າ​ສູ່​ລະ​ບົບ​ໃນາມ​ຂອງ​ພວກ​ເຂົາ.",
    "consent_on_behalf_registrar_with_guardian": "ຂ້າ​ພະ​ເຈົ້າ​ໄດ້​ຮັບ​ອະ​ນຸ​ຍາດ​ທາງ​ປາກ​ເປົ່າ​ໂດຍຜູ້​ປົກ​ຄອງ​ຂອງ {{first_name}} {{last_name}} ເພື່ອ​ເຂົ້າ​ສູ່​ລະ​ບົບ​ໃນາມ​ຂອງ​ພວກ​ເຂົາ.",
    "registrar_phone": "ເບີໂທລະສັບຂອງນາຍທະບຽນ",
    "registrar_email": "ອີເມວຂອງນາຍທະບຽນ",
    "consented_by_parent_guardian": "ພໍ່ແມ່ / ຜູ້ປົກຄອງ",
    "consented_by_decision_maker": "ຜູ້ມີສິດໃນການຕັດສິນໃຈທາງການແພດ",
    "consented_by_guardian_verbal": "ນາຍທະບຽນກັບຜູ້ປົກຄອງ (ຕ້ອງໄດ້ຮັບການຍິນຍອມປາກເປົ່າ)",
    "consented_by_registrar": "ນາຍທະບຽນ (ຕ້ອງໄດ້ຮັບການຍິນຍອມປາກເປົ່າ)",
    "full_name": "ຊື່​ເຕັມ",
    "print": "ພິມ"
  },
  "registration": {
    "contact_information": "ຊື່ ແລະ ທີ່​ຢູ່",
    "contact_information_additional": "ຂໍ້​ມູນ​ຕິດ​ຕໍ່ພໍ່​ແມ່ ຫຼື ຜູ້​ປົກ​ຄອງ​ສຳ​ລັບ​ການ​ສື່​ສານ​ການ​ນັດ​ໝາຍ ແລະ ຜົນ​ຮັບ",
    "personal_information": "ຂໍ້ມູນປະຊາກອນ",
    "address_placeholder": "ກະ​ລຸ​ນາປ້ອນ​ທີ່​ຢູ່ບ້ານຂອງ​ທ່ານ",
    "first_name_label": "ຊື່",
    "last_name_label": "ນາມສະກຸນ",
    "errors": {
      "state": "ລັດ​ຕ້ອງ​ເປັນ​ລະ​ຫັດ​ລັດ 2 ຕົວ​ອັກ​ສອນ​ທີ່​ໃຊ້​ໄດ້",
      "phone_number_invalid": "ເບີ​ໂທລະສັບໃຊ້​ບໍ່​ໄດ້",
      "required": "ຈຳ​ເປັນ​ຕ້ອງ​ມີ​ຄ່າ​ນີ້",
      "email_domain": "ການ​ປ້ອນ​ຂອງ​ທ່ານ​ໃຊ້​ບໍ່​ໄດ້, ກະ​ລຸ​ນາ​ກວດ​ຄືນ​ໃໝ່​ອີກ. ການ​ລົງ​ທະ​ບຽນ​ຖືກ​ຈຳ​ກັດ​ຕໍ່​ຜູ້​ເຂົ້າ​ຮ່ວມ​ທີ່​ອົງ​ການ​ສະ​ໜັບ​ສະ​ໜູນ​ເລືອກ. ກະ​ລຸ​ນາ​ລອງ​ໃຊ້​ອີ​ເມວ​ວຽກ ຫຼື ຊຸມ​ຊົນ​ຂອງ​ທ່ານ. ຖ້າ​ທ່ານ​ຮູ້​ສຶກວ່າ​ທ່ານ​ມີ​ສິດ​ໄດ້​ຮັບ ແລະ ມີ​ຄວາມ​ຜິດ​ພາດ, ກະ​ລຸ​ນາ​ຕິດ​ຕໍ່​ຝ່າຍ​ໃຫ້​ການ​ຊ່ວຍ​ເຫຼືອ.",
      "age_requirement": "ວັນ​ເດືອນ​ປີ​ເກີດບໍ່​ໄດ້​ຕາມ​ເງື່ອນ​ໄຂ​ກຳ​ນົດ​ອາ​ຍຸ",
      "signature_required": "ຈຳ​ເປັນ​ຕ້ອງ​ມີ​ລາຍ​ເຊັນ",
      "regex_invalid": "ເບິ່ງ​ແລ້ວ​ຄ່າ​ນີ້​ໃຊ້​ບໍ່​ໄດ້.",
      "date_invalid": "ບໍ່​ມີ​ວັນ​ທີ​ນີ້​ຢູ່",
      "invalid_entry": "ກະ​ລຸ​ນາ​ຢ່າ​ສືບ​ຕໍ່​ກັບ​ການ​ລົງ​ທະ​ບຽນ. ປະ​ຕິ​ບັດ​ຕາມ​ຄຳ​ແນະ​ນຳໃນ​ໜ້າ​ຈໍ ຫຼື ຕິດ​ຕໍ່​ຫາ​ຈຸດ​ຕິດ​ຕໍ່​ຂອງ​ທ່ານ.",
      "city": "ຕ້ອງ​ເປັນ​ເມືອງ​ທີ່​ຖືກ​ຕ້ອງ",
      "survey_unanswered": "ກະ​ລຸ​ນາ​ກວດ​ເບິ່ງວ່າ​ທຸກ​ຄຳ​ຖາມ​ທີ່​ຕ້ອງ​ການ​ທີ່ໝາຍ​ດ້ວຍ * ແມ່ນ​ໄດ້​ຕອບ​ແລ້ວ.",
      "postal_code": "ຕ້ອງ​ເປັນ​ລະ​ຫັດໄປ​ສະ​ນີ 5 ຕົວເລກທີ່​ໃຊ້​ໄດ້",
      "field_required": "{{field}} ຕ້ອງ​ມີ​ຕົວ​ເລກ",
      "option_required": "ທ່ານ​ຕ້ອງເລືອກ​ໜຶ່ງ​ໃນຕົວເລືອກ",
      "year_length_invalid": "ປ້ອນ​ວັນເກີດ​ພ້ອມ​ດ້ວຍ​ປີ​ຈຳ​ນວນ​ສີ່​ຕົວ​ເລກ (ປ​ປ​ປ​ປ)",
      "invalid_day": "ວັນທີຕ້ອງຢູ່ລະຫວ່າງ 1-31",
      "reached_max_chars": "ທ່ານ​ຮອດ​ຈຳ​ນວນຕົວ​ອັກ​ຂະ​ລະສູງ​ສຸດແລ້ວ.",
      "chars_max": "ຕົວ​ອັກ​ຂະ​ລະ​ສູງ​ສຸດ",
      "minimum_length": "ຂໍ້ມູນຕ້ອງ​ມີ​ຄວາມ​ຍາວ​ຢ່າງ​ໜ້ອຍ {{length}} ຕົວ​ອັກ​ສອນ."
    },
    "insurance_status": {
      "question": "ທ່ານມີ​ປະ​ກັນ​ໄພ​ສຸ​ຂະ​ພາບບໍ?",
      "have_health_insurance": "ແມ່ນ, ຂ້ອຍ​ມີ​ປະ​ກັນ​ໄພສຸ​ຂະ​ພາບ",
      "do_not_have_health_insurance": "ບໍ່, ຂ້ອຍ​ບໍ່ມີ​ປະ​ກັນ​ໄພ​ສຸ​ຂະ​ພາບ"
    },
    "insurance_policy_holder": {
      "question": "ໃຜ​ເປັນ​ຜູ້​ຖື​ສັນ​ຍາ​ປະ​ກັນ​ໄພ?",
      "i_am": "ຂ້ອຍ​ເອງ",
      "my_spouse": "ຄູ່​ສົມ​ລົດ ຫຼື ຄູ່​ຮັກຂອງ​ຂ້ອຍ",
      "my_parents": "ພໍ່​ແມ່ຂອງ​ຂ້ອຍ",
      "someone_else": "ຄົນ​ອື່ນ",
      "policy_first_name": "ຊື່​ຂອງ​ຜູ້​ຖື​ສັນ​ຍາ​ປະ​ກັນ​ໄພ",
      "policy_last_name": "ນາມ​ສະ​ກຸນຂອງ​ຜູ້​ຖື​ສັນ​ຍາ​ປະ​ກັນ​ໄພ",
      "policy_dob": "ວັນເດືອນປີເກີດຂອງ​ຜູ້​ຖື​ສັນ​ຍາ​ປະ​ກັນ​ໄພ",
      "name": "ຊື່ຂອງ​ຜູ້​ຖື​ສັນ​ຍາ​ປະ​ກັນ​ໄພ"
    },
    "insurance_information": {
      "title": "ຂໍ້​ມູນປະ​ກັນ​ໄພ",
      "company_name": "ຊື່ບໍລິສັດປະ​ກັນ​ໄພ",
      "id_number": "ໝາຍເລກປະ​ຈຳຕົວສະ​ມາ​ຊິກ",
      "group_number": "ໝາຍ​ເລກ​ກຸ່ມ​ສະ​ມາ​ຊິກ",
      "secondary_insurance_label": "ຂ້ອຍ​ມີ​ປະ​ກັນ​ໄພຂັ້ນ​ທີ​ສອງ",
      "take_photo": "ກະ​ລຸ​ນາ​ຖ່າຍຮູບ​ບັດ​ປະກັນໄພ​ຂອງ​ທ່ານ. ການຖ່າຍຮູບຈະຕື່ມບາງຂໍ້ມູນໂດຍອັດຕະໂນມັດ.",
      "front_of_card": "ດ້ານ​ໜ້າ​ຂອງ​ບັດ",
      "card_information": "ຂໍ້ມູນບັດ​ປະ​ກັນ​ໄພ",
      "back_of_card": "ດ້ານ​ຫຼັງ​ຂອງ​ບັດ"
    },
    "employment_information": {
      "label": "ການ​ຈ້າງ​ງານ",
      "address_1": "ທີ່ຢູ່ບໍລິສັດ",
      "address_2": "ຕົວ​ຢ່າງ: Suite 200",
      "phone_number": "ເບີ​ໂທ​ລະ​ສັບບໍ​ລິ​ສັດ",
      "company": "ຊື່ບໍລິສັດ",
      "postal_code": "ລະ​ຫັດໄປ​ສະ​ນີນາຍ​ຈ້າງ",
      "employed": "ມີ​ວຽກ​ເຮັດ",
      "sole_prorietor": "ເຈົ້າ​ຂອງ​ແຕ່​ພຽງ​ຜູ້​ດຽວ",
      "not_employed": "ບໍ່​ໄດ້​ເຮັດ​ວຽກ",
      "reporting_supervisor": "ຫົວ​ໜ້າລາຍ​ງານ",
      "reporting_department": "ພະ​ແນກລາຍ​ງານ",
      "supervisor_name": "ຊື່ຫົວໜ້າຄຸມງານ",
      "supervisor_email": "ອີເມວຂອງຫົວໜ້າຄຸມງານ"
    },
    "location_availability": "ປັດ​ຈຸ​ບັນ​ການ​ກວດ​ຖືກ​ຈັດບູ​ລິ​ມະ​ສິດ​ໃຫ້​ກັບ​ສະ​ມາ​ຊິກ​ຊຸມ​ຊົນ​ອາ​ໄສ​ຢູ່​ໃນ​ພື້ນ​ທີ່​ເຫຼົ່າ​ນີ້",
    "custom_survey": "ແບບ​ສອບ​ຖາມ",
    "confirmation": "ການ​ທົບທວນຄືນ",
    "waitlisted": "ໃນ​ບັນ​ຊີ​ລໍ​ຖ້າ",
    "schedule_your_appointment": "ກຳນົດເວລາການນັດໝາຍ",
    "information": "ຂໍ້​ຄວາມ",
    "consent": "ການຍິນຍອມ",
    "location": "ສະ​ຖານ​ທີ່",
    "symptoms": "ອາ​ການ",
    "address_required": "ກະ​ລຸ​ນາປ້ອນ​ທີ່​ຢູ່ຂອງ​ທ່ານ",
    "consent_required": "ກ​ະ​ລຸ​ນາຍິນຍອມເພື່ອ​ສືບ​ຕໍ່",
    "required_field": "ຊີ້ບອກ​ຫ້ອງ​ທີ່​ຕ້ອງ​ໄດ້​ຕອບ",
    "phone_number": "ເບີໂທລະສັບມື​ຖື",
    "email": "ອີ​ເມວ",
    "date_of_birth": "ວັນເດືອນປີເກີດ",
    "minimum_age": "ອາ​ຍຸ​ຕ່ຳ​ສຸດ​ສຳ​ລັບ​ການ​ເຂົ້າ​ຮ່ວມ​ແມ່ນ {{year}} ປີ.",
    "no_minimum_age": "ບໍ່​ມີ​ອາ​ຍຸ​ຕ່ຳສຸດ​ສຳ​ລັບ​ການກວດ",
    "continue_button": "ສືບ​ຕໍ່",
    "email_required": "ຄ່າ​ນີ້ຄວນ​ເປັນ​ອີ​ເມວ​ທີ່​ນຳ​ໃຊ້​ໄດ້.",
    "done": "ສໍາເລັດແລ້ວ",
    "signature": "ລາຍເຊັນ",
    "clear_button": "ລຶບອອກ",
    "back_button": "ກັບ​ຄືນ",
    "choose_location": "ເລືອກ​ສະຖານ​ທີ່",
    "no_slots": "ບໍ່​ມີ​ບ່ອນ​ໃຫ້​ອີກ​ຢູ່​ທີ່ {{location}}",
    "choose_appointment": "ເລືອກ​ເວ​ລາ​ນັດ​ໝາຍ​ຢູ່​ທີ່ {{location}}",
    "appointment_required": "ກະ​ລຸ​ນາ​ເລືອກເວ​ລາ​ນັດ​ໝາຍ",
    "phone_number_required": "ເບີ​ໂທລະສັບໃຊ້​ບໍ່​ໄດ້",
    "phone_number_label": "ກະ​ລຸ​ນາ​ປ້ອນ​ເບີ​ທີ່​ສາ​ມາດ​ຮັບ​ຂໍ້​ຄວາມ​ສຳ​ລັບ​ຜົນ​ການກວດ​ໄວ​ຂຶ້ນ",
    "symptoms_experiencing": "ກະ​ລຸ​ນາ​ຄ​ລິກໃສ່​ອາ​ການ​ທີ່​ທ່ານ​ກຳ​ລັງ​ຮູ້​ສຶກ​ຢູ່​ໃນ​ປັດ​ຈຸ​ບັນ. ຖ້າ​ທ່ານ​ບໍ່​ມີ​ອາ​ການ, ກະ​ລຸ​ນາ​ສືບ​ຕໍ່.",
    "household": "ສະ​ມາ​ຊິກ​ຄົວ​ເຮືອນ",
    "household_p1": "ອີກ​ທາງ​ເລືອກ​ອື່ນ, ເຮັດ​ການ​ນັດ​ໝາຍ​ສຳ​ລັບ​ສະ​ມາ​ຊິກ​ຄົວ​ເຮືອນ​ຜູ້​ທີ່​ຈຳ​ເປັນ​ຕ້ອງ​ໄດ້​ກວດ​ນຳ.",
    "add_dependent": "ເພີ່ມສະ​ມາ​ຊິກຄົວ​ເຮືອນ",
    "remove_dependent": "ເອົາ​ຜູ້​ຂຶ້ນ​ກັບ​ອອກ​ໄປ",
    "dependent_consents": "ຄຳຍິນຍອມ",
    "submit": "ສຳ​ເລັດ",
    "add_waitlist": "ເພີ່ມ​ໃສ່​ບັນ​ຊີ​ລໍ​ຖ້າ",
    "address": "ທີ່ຢູ່ບ້ານ",
    "address_1": "ທີ່​ຢູ່​ແຖວ​ທີ 1",
    "address_2": "ອາ​ພາດ​ເມັ້ນ/ຫ້ອງ​ຊຸດ​ເບີ",
    "address_city": "ເມືອງ",
    "address_state": "ລັດ",
    "postal_code": "ລະຫັດໄປສະນີ",
    "race_ethnicity": "ເຊື້ອ​ຊາດ",
    "gender": "ລັກ​ສະ​ນະ​ເພດ",
    "self_described_gender": "ເພດ​ລະ​ບຸ​ຕົວ​ເອງ",
    "interpreter": "ຕ້ອງ​ການ​ຜູ້​ແປ​ພາ​ສາບໍ? ຖ້າ​ແມ່ນ, ພາ​ສາ​ໃດ?",
    "consent_to_terms": "ຂ້ອຍ​ຍິນຍອມ​ຕໍ່​ຂໍ້​ກຳ​ນົດ​ເຫຼົ່າ​ນີ້.",
    "reg_not_open": "ການ​ລົງ​ທະ​ບຽນ​ຍັງ​ບໍ່​ເປີດ",
    "no_more_avail_spots": "ຫ້ອງ​ເວ​ລາ​ທີ່​ທ່ານ​ເລືອກ​ບໍ່​ມີ​ໃຫ້​ອີກ​ຕໍ່​ໄປ. ກະ​ລຸ​ນາ​ລອງ​ອີກ​ຄັ້ງ.",
    "consent_helper": "ຄ​ລິກ ແລະ ລາກ​ເມົ້າ ຫຼື ນິ້ວ​ມື​ຂອງ​ທ່ານ​ຂ້າມ​ຫ້ອງ​ຢູ່​ລຸ່ມ​ນີ້​ເພື່ອ​ເຊັນ",
    "phone_number_unreachable_label": "ໂທ​ລະ​ສັບຕັ້ງ​ໂຕະບໍ?",
    "select": "ເລືອກ",
    "test_surveys": "ຄຳ​ຖາມ​ການ​ນັດ​ໝາຍ",
    "edit": "ແກ້ໄຂ",
    "continue_to_registration": "ສືບ​ຕໍ່​ການລົງ​ທະ​ບຽນ",
    "accounts": {
      "already_have_an_account": "ມີ​ບັນ​ຊີ​ຮຽບ​ຮ້ອຍ​ແລ້ວ?",
      "log_in": "ເຂົ້າ​ສູ່​ລະ​ບົບ",
      "sign_up": "ລົງ​ທະ​ບຽນ",
      "sign_up_description": "ກະ​ລຸ​ນາ​ປ້ອນ​ຂໍ້​ມູນ​ຂອງ​ທ່ານ​ເພື່ອ​ສ້າງ​ບັນ​ຊີ​ຂອງ​ທ່ານ ແລະ ປະ​ຢັດ​ເວ​ລາ​ໃນ​ການ​ລົງ​ທະ​ບຽນ​ໃນ​ອະ​ນາ​ຄົດ.",
      "log_in_description": "ກະ​ລຸ​ນາ​ປ້ອນອີ​ເມວ ແລະ ລະ​ຫັດ​ຜ່ານ​ຂອງ​ທ່ານ ຫຼື ເຂົ້າ​ສູ່​ລະ​ບົບ​ຜ່ານ Google ຫຼື Outlook ຢູ່​ລຸ່ມ​ນີ້",
      "sign_in_with_label": "ເຂົ້າ​ສູ່​ລະ​ບົບ​ດ້ວຍ {{provider}}",
      "password_must_match": "ລະ​ຫັດ​ຜ່ານຕ້ອງກົງ​ກັນ",
      "password_uppercase": "ລະ​ຫັດ​ຜ່ານຂອງ​ທ່ານ​ຕ້ອງ​ມີ​ຕົວ​ອັກ​ສອນ​ໃຫຍ່ຢ່າງ​ໜ້ອຍ​(%s) ຕົວ.",
      "password_lowercase": "ລະ​ຫັດ​ຜ່ານຂອງ​ທ່ານ​ຕ້ອງ​ມີ​ຕົວ​ອັກ​ສອນ​ນ້ອຍຢ່າງ​ໜ້ອຍ​(%s) ຕົວ.",
      "password_number": "ລະ​ຫັດ​ຜ່ານຂອງ​ທ່ານ​ຕ້ອງ​ມີ​ຕົວເລກຢ່າງ​ໜ້ອຍ (%s) ຕົວ.",
      "password_special": "ລະ​ຫັດ​ຜ່ານຂອງ​ທ່ານ​ຕ້ອງ​ມີ​ຕົວ​ອັກ​ຂະ​ລະພິ​ເສດ​ຢ່າງ​ໜ້ອຍ (%s) ຕົວ."
    },
    "password": "ລະຫັດ​ຜ່ານ",
    "password_confirmation": "ການ​ຢືນ​ຢັນ​ລະ​ຫັດ​ຜ່ານ",
    "consent_for": "ຄຳ​ຍິນຍອມ​ສຳ​ລັບ {{name}}",
    "book_one_time_appointment": "ຈອງ​ການ​ນັດ​ໝາຍ​ຄັ້ງ​ດຽວ",
    "duplicate_users": {
      "exists": "ທ່ານ​ລົງ​ທະ​ບຽນ​ຮຽບ​ຮ້ອຍ​ແລ້ວ",
      "overlapping_email_and_phone_p1": "ພວກ​ເຮົາ​ໄດ້​ສົ່ງ​ຂໍ້​ຄວາມ​ຫາເບີ​ໂທ​ລະ​ສັບ ແລະ ທີ່​ຢູ່ອີເມວ​ຂອງ​ທ່ານແລ້ວ.",
      "p2": "ການ​ໃຊ້​ຂໍ້​ຄວາມ​ຂອງ​ທ່ານ, ທ່ານ​ສາ​ມາດ​ປ່ຽນ​ການນັດ​ໝາຍ​ຂອງ​ທ່ານ ຫຼື ປ້ອນ​ຜົນ​ກວດ​ໄດ້.",
      "overlapping_phone_and_email_p3": "ກະ​ລຸ​ນາ​ກວດ​ເບິ່ງອີ​ເມວ ຫຼື ໂທ​ລະ​ສັບຂອງ​ທ່ານສຳ​ລັບ​ລາຍ​ລະ​ອຽດ.",
      "overlapping_email_p3": "ກະ​ລຸ​ນາ​ກວດ​ເບິ່ງອີ​ເມວຂອງ​ທ່ານສຳ​ລັບ​ລາຍ​ລະ​ອຽດ.",
      "overlapping_phone_p3": "ກະ​ລຸ​ນາ​ກວດ​ເບິ່ງໂທ​ລະ​ສັບຂອງ​ທ່ານສຳ​ລັບ​ລາຍ​ລະ​ອຽດ.",
      "overlapping_phone_p1": "ພວກ​ເຮົາ​ໄດ້​ສົ່ງ​ຂໍ້​ຄວາມ​ຫາເບີ​ໂທ​ລະ​ສັບຂອງ​ທ່ານແລ້ວ.",
      "overlapping_email_p1": "ພວກ​ເຮົາ​ໄດ້​ສົ່ງ​ຂໍ້​ຄວາມ​ຫາທີ່​ຢູ່ອີເມວ​ຂອງ​ທ່ານແລ້ວ.",
      "p4": "ຖ້າ​ທ່ານ​ຄິດ​ວ່າ​ນີ້​ແມ່ນ​ຄວາມ​ຜິດ​ພາດ, ກະ​ລຸ​ນາ​ຕິດ​ຕໍ່​ພວກ​ເຮົາ​ທີ່ support@primary.health",
      "overlapping_email_and_phone_p3": "ກະ​ລຸ​ນາ​ກວດ​ເບິ່ງອີ​ເມວ ຫຼື ໂທ​ລະ​ສັບຂອງ​ທ່ານສຳ​ລັບ​ລາຍ​ລະ​ອຽດ."
    },
    "duplicate_waitlist": {
      "exists": "ທ່ານ​ຢູ່​ໃນ​ບັນ​ຊີ​ລໍ​ຖ້າ​ຮຽບ​ຮ້ອຍ​ແລ້ວ",
      "overlapping_email_and_phone_p1": "ພວກ​ເຮົາ​ໄດ້​ສົ່ງຄຳ​ຢືນ​ຢັນ​ອື່ນຫາເບີ​ໂທ​ລະ​ສັບ ແລະ ທີ່​ຢູ່ອີເມວ​ຂອງ​ທ່ານແລ້ວ",
      "p2": "ດ້ວຍ​ການ​ນຳ​ໃຊ້​ຄຳ​ຢືນ​ຢັນ​ຂອງ​ທ່ານ, ທ່ານ​ສາ​ມາດ​ໃຫ້​ຂໍ້​ມູນ​ເພີ່ມ​ເຕີມ ຫຼື ເອົາ​ຕົວ​ທ່ານ​ອອກ​ຈາກ​ບັນ​ຊີ​ລໍ​ຖ້າ​ໄດ້.",
      "overlapping_phone_and_email_p3": "ກະ​ລຸ​ນາ​ກວດ​ເບິ່ງອີ​ເມວ ຫຼື ໂທ​ລະ​ສັບຂອງ​ທ່ານສຳ​ລັບ​ລາຍ​ລະ​ອຽດ",
      "overlapping_email_p3": "ກະ​ລຸ​ນາ​ກວດ​ເບິ່ງອີ​ເມວຂອງ​ທ່ານສຳ​ລັບ​ລາຍ​ລະ​ອຽດ",
      "overlapping_phone_p3": "ກະ​ລຸ​ນາ​ກວດ​ເບິ່ງໂທ​ລະ​ສັບຂອງ​ທ່ານສຳ​ລັບ​ລາຍ​ລະ​ອຽດ",
      "overlapping_email_p1": "ພວກ​ເຮົາ​ໄດ້​ສົ່ງຄຳ​ຢືນ​ຢັນ​ອື່ນຫາທີ່​ຢູ່ອີເມວ​ຂອງ​ທ່ານແລ້ວ"
    },
    "insist_guardian_consent": "ຂ້ອຍ​ກຳ​ລັງ​ໃຫ້​ຄຳ​ຍິນຍອມ​ໃນ​ນາມ​ຂອງ",
    "confirmation_section": {
      "title": "ທົບ​ທວນ ແລະ ຢືນ​ຢັນ",
      "p1": "ກະ​ລຸ​ນາ​ທົບ​ທວນ​ເບິ່ງ​ຂໍ້​ມູນ​ຂອງ​ທ່ານ​ກ່ອນສຳ​ເລັດ​ການ​ລົງ​ທະ​ບຽນ.",
      "edit_information": "ກັບ​ໄປ ແລະ ແກ້​ໄຂ"
    },
    "iemodal": {
      "title": "ບ​ຣາວ​ເຊີ​ບໍ່​ຮອງ​ຮັບ",
      "body": "ປັດ​ຈຸ​ບັນ​ການ​ລົງ​ທະ​ບຽນ​ແມ່ນ​ເປັນ​ໄປ​ໄດ້​ໃນ​ບ​ຣາວ​ເຊີ​ເຫຼົ່າ​ນີ້"
    },
    "show_other_locations": "ຄ​ລິກ​ເພື່ອ​ສະ​ແດງ​ສະ​ຖານ​ທີ່​ອື່ນ",
    "non_us_address": "ນີ້​ແມ່ນ​ທີ່​ຢູ່​ສາ​ກົນ",
    "test_group_user_survey": "ການກວດ​ຄັດ​ກອງ​ຜູ້​ເຂົ້າ​ຮ່ວມ",
    "self_consent": "ຂ້ອຍ​ກຳ​ລັງ​ໃຫ້​ຄຳ​ຍິນຍອມ​ສຳ​ລັບ​ຕົວຂ້ອຍ​ເອງ",
    "address_country": "ປະເທດ",
    "appointment": "ການນັດໝາຍ",
    "employee_id": "ລະ​ຫັດ​ພະ​ນັກ​ງານ",
    "appointment_time": "ເວລານັດ​ໝາຍ",
    "appointment_location": "ທີ່​ຕັ້ງ",
    "phone_or_email": "ເບີ​ໂທລະສັບ ຫຼື ອີ​ເມ​ວ",
    "no_self_consent": "ຂ້າ​ພະ​ເຈົ້າ​ປະ​ຕິ​ເສດ​ການ​ຍິນຍອມສຳ​ລັບ​ຂ້າ​ພະ​ເຈົ້າ​ເອງ",
    "no_insist_guardian_consent": "ຂ້າ​ພະ​ເຈົ້າ​ປະ​ຕິ​ເສດ​ການ​ຍິນຍອມ​ໃນ​ນາມ​ຂອງ {{full_name}}",
    "minimum_age_with_months": "ອາຍຸຕໍ່າສຸດທີ່ຈະເຂົ້າຮ່ວມແມ່ນ {{year}} ປີ {{month}} ເດືອນ.",
    "additional_consents_helpertext": "ອາດຈະຂໍ​ຄຳ​ຍິນຍອມ​ເພີ່ມ​ເຕີມ​ໃນ​ພາຍຫຼັງ",
    "assistive_technology": "ເຕັກ​ໂນ​ໂລ​ຊີຊ່ວ​ຍ",
    "covid_vaccine_y_n": "ທ່ານ​ຕ້ອງ​ການຮັບວັກ​ຊີນ​ປ້ອງ​ກັນ​ພະ​ຍາດ​ໂຄວິດ-19 ດ້ວຍ​ການ​ຈອງຄັ້ງ​ນີ້ບໍ?",
    "received_covid_vaccine_y_n": "ທ່ານ​ເຄີຍ​ໄດ້​ຮັບວັກ​ຊີນ​ປ້ອງ​ກັນ​ພະ​ຍາດ​ໂຄວິດ-19 ມາ​ກ່ອນ​ບໍ?",
    "covid_vaccinations": "ການ​ສັກ​ວັກ​ຊີນ​ປ້ອງ​ກັນ​ພະ​ຍາດ​ໂຄວິດ-19",
    "select_vaccine_dose": "ກະ​ລຸ​ນາ​ເລືອກ​ວັກ​ຊີນ​ທີ່​ທ່ານ​ຕ້ອງ​ການ​ສັກ",
    "demographic_info": "ຂໍ້ມູນປະຊາກອນ",
    "additional_info": "ຂໍ້​ມູນ​ເພີ່ມ​ຕື່ມ",
    "self_described_race": "ເຊື້ອ​ຊາດລະ​ບຸ​ຕົວ​ເອງ",
    "verify_contact_information": "ການກວດ​ສອບ",
    "verify_contact": {
      "we_sent_you_a_code": "ກະ​ລຸ​ນາ​ປ້ອນ​ລະ​ຫັດ​ທີ່​ພວກ​ເຮົາ​ສົ່ງ​ຫາ {{contact}}",
      "confirmation_code": "ລະ​ຫັດ​ຢືນ​ຢັນ",
      "code_resent": {
        "email": "ສົ່ງ​ລະ​ຫັດ​ອື່ນ​ໄປ​ຫາ​ອີ​ເມວ​ຂອງ​ທ່ານ​ແລ້ວ",
        "phone_number": "ສົ່ງ​ລະ​ຫັດ​ອື່ນ​ໄປ​ຫາໂທ​ລະ​ສັບ​ຂອງ​ທ່ານ​ແລ້ວ"
      },
      "did_not_receive_code": "ບໍ່​ໄດ້​ຮັບ​ລະ​ຫັດ​ຢືນ​ຢັນ​ບໍ?",
      "verify_by": {
        "email": "ກວດ​ສອບ​ເບິ່ງ​ທາງ​ອີ​ເມວ​ແທນ",
        "phone_number": "ກວດ​ສອບ​ເບິ່ງ​ທາງ​ເບີ​ໂທ​ລະ​ສັບ​ແທນ"
      }
    },
    "preferred_method_of_communication": {
      "question": "ວິ​ທີຕິດ​ຕໍ່ສື່ສານ​ທີ່​ມັກ",
      "helper_text": "ພວກເຮົາຈະຕິດຕໍ່ຫາທ່ານກ່ຽວກັບການອັບເດດການນັດໝາຍ",
      "phone_number": "ໂທລະສັບ (ການສົ່ງຂໍ້ຄວາມ SMS ເທົ່ານັ້ນ ແລະ ອັດຕາຂໍ້ມູນອາດຈະຖືກນຳໃຊ້)",
      "landline": "ພວກເຮົາບໍ່​ສາ​ມາດຮອງຮັບໂທລະສັບພື້ນຖານໄດ້​ໃນ​ເວ​ລາ​ນີ້. ກະລຸນາໃຫ້​ທີ່​ຢູ່ອີເມວສຳລັບການຕິດຕໍ່ທີ່ສຳຄັນ.",
      "verification_method": "ທ່ານ​ຕ້ອງ​ການຮັບເອົາລະຫັດການຢືນຢັນ​ຕົວ​ຕົນ​ຂອງ​ທ່ານແນວ​ໃດ?",
      "verification_helper_text": "ທ່ານຈະໄດ້ຮັບລະຫັດເພື່ອຢືນຢັນຕົວຕົນຂອງທ່ານກ່ອນທີ່ຈະເຂົ້າສູ່ລະບົບ."
    },
    "skip_for_now": "ຂ້າມ​ໄປສຳ​ລັບ​ຕອນ​ນີ້",
    "default_information": {
      "title": "ຕົກ​ລົງ, ພວກເຮົາຈະໃຊ້ທີ່ຢູ່ນີ້",
      "title_v2": "ໂດຍການ, ພວກເຮົາຈະໃຊ້ຂໍ້ມູນການຕິດຕໍ່ນີ້"
    },
    "middle_name_label": "ຊື່ກາງ",
    "confirm_appointment": "ຢືນຢັນການນັດໝາຍ",
    "dob": "ວັນ​ເດືອນ​ປີ​ເກີດ",
    "different_appointment": "ການນັດໝາຍອື່ນ",
    "select_appointment_time": "ເລືອກເວລາການນັດໝາຍ",
    "decline_address": "ຂ້ອຍປະຕິເສດທີ່ຈະໃຫ້ທີ່ຢູ່. ກະລຸນານຳໃຊ້ທີ່ຢູ່ຂອງໂຄງການແທນ.",
    "patient_information": "ຂໍ້ມູນຄົນເຈັບ",
    "scan_license": "ສະແກນ​ໃບ​ຂັບ​ຂີ່ຂອງຄົນຂັບ",
    "how_to_contact": "ພວກເຮົາຄວນຈະຕິດຕໍ່ທ່ານແນວໃດ?",
    "decline_email_or_phone": "ຂ້ອຍຕັດສິນໃຈທີ່ຈະໃຫ້​ທີ່​ຢູ່ອີເມວ ຫຼື ເບີ​ໂທ​ລະ​ສັບ. ຂ້ອຍອະນຸຍາດໃຫ້ຜູ້ບໍລິຫານໂຄງການຮັບເອົາຜົນໄດ້ຮັບຂອງຂ້ອຍ.",
    "medical_screening": "ການກວດຄັດກອງທາງການແພດ",
    "show_service_at_this_location": "ສະແດງບໍລິການໃນສະຖານທີ່ນີ້",
    "verbal_consent_provided": "ອີງຕາມກົດໝາຍຄົນພິການອາເມລິກາ, ຜູ້ເຂົ້າຮ່ວມ ຫຼື ຜູ້ຕັດສິນທີ່ໄດ້ຮັບອະນຸຍາດຕາມກົດໝາຍຂອງເຂົາເຈົ້າໄດ້ໃຫ້ການຍິນຍອມທາງປາກເປົ່າ. ຄຳຍິນຍອມເປັນລາຍລັກອັກສອນຈະ​ໄດ້​ຮັບ​ມາຈາກຜູ້ເຂົ້າຮ່ວມ ຫຼື ຜູ້ດຳເນີນການຕັດສິນໃຈທີ່ໄດ້ຮັບອານຸຍາດຕາມກົດໝາຍພາຍໃນ 24 ຊົ່ວໂມງ.",
    "unexpired_written_consent_present": "ສະຖານທີ່ກວດມີຄຳ​ຍິນຍອມເປັນລາຍລັກອັກສອນທີ່ຍັງບໍ່ໝົດອາຍຸໃນຊຸດເອກະສານ.",
    "select_to_proceed": "ກະລຸນາເລືອກຕົວເລືອກຂ້າງເທິງເພື່ອນຳເດີນການຕໍ່.",
    "guardians_information": "ຂໍ້ມູນຜູ້ປົກຄອງ",
    "registrars_information": "ຂໍ້ມູນຂອງນາຍທະບຽນ",
    "optional": "ເປັນ​ທາງ​ເລືອກ",
    "vfc_eligibility": "ການມີ​ສິດ​ໄດ້​ຮັບວັກຊີນສຳລັບເດັກນ້ອຍ (VFC)",
    "vfc_eligibility_subtext": "ລູກຂອງທ່ານອາດຈະສາມາດໄດ້ຮັບວັກຊີນທີ່ບໍ່ມີຄ່າໃຊ້ຈ່າຍຜ່ານໂຄງການວັກຊີນສໍາລັບເດັກນ້ອຍ (VFC) ຂອງ CDC ຢູ່ຫ້ອງການຜູ້ໃຫ້ບໍລິການດ້ານສຸຂະພາບ, ຮ້ານຂາຍຢາ ແລະ ຄລີນິກສຸຂະພາບທີ່ລົງທະບຽນຢູ່ໃນໂຄງການ VFC."
  },
  "gender_key": {
    "male": "ເພດຊາຍ",
    "female": "ເພດ​ຍິງ",
    "cisgender": "ເພດ​ທີ່​ຖືກ​ກັບ​ທີ່​ລະ​ບຸ​ມາ​ແຕ່​ເກີດ (Cisgender)",
    "non_binary": "ເພດ​ທາງ​ເລືອກ (Genderqueer) ຫຼື ບໍ່​ແມ່ນ​ແບບ​ສອງ​ເພດ",
    "other": "ອື່ນໆ",
    "prefer_to_self_describe": "ເອ​ກະ​ລັກ​ສະ​ເພາະ​ບໍ່​ໄດ້​ເຮັດ​ເປັນ​ລາຍ​ການ​ໄວ້",
    "prefer_not_to_disclose": "ປະ​ຕິ​ເສດ​ທີ່​ຈະ​ຕອບ",
    "transgender_male": "ທອມ ຫຼື Trans Male/ Transman",
    "transgender_female": "ເກ (Trans Female/ Transwoman)",
    "unknown": "ບໍ່ຮູ້​ຈັກ",
    "non_binary_only": "ບໍ່​ແມ່ນ​ທັງ​ສອງ",
    "intersex": "ລະ​ຫວ່າງ​ເພດ",
    "transgender": "ຂ້າມ​ເພດ",
    "gender_fluid": "ເພດໄຫຼ",
    "not_applicable": "ບໍ່ກ່ຽວຂ້ອງ"
  },
  "ethnicity": {
    "american_indian_alaska_native": "ຄົນອະເມຣິກັນອິນເດຍແດງ ຫຼື ຄົນພື້ນເມືອງອາລັສກ້າ",
    "american_indian_central_america": "ອາ​ເມ​ຣິ​ກັນ​ເຊື້ອ​ສາຍ​ອິນ​ເດຍ​ຈາກ​ອາ​ເມ​ຣິ​ກາ​ໃຕ້ ຫຼື ກາງ",
    "asian": "ອາຊີ",
    "black": "ຄົນອາ​ເມ​ຣິ​ກັນຜິວດໍາ ຫຼື ອະເມຣິກັນອາຟຣິກັນ",
    "latinx": "ລາ​ຕິ​ໂນ, ລາ​ຕິນ ຫຼື ເຊື້ອ​ສາຍ​ສະ​ເປນ",
    "middle_eastern_north_african": "ຕາ​ເວັນ​ອອກ​ກາງ ຫຼື ອາ​ຟ​ຣິ​ກາ​ເໜືອ",
    "native_hawaiian_pacific_islander": "ຄົນພື້ນ​ເມືອງ​ຮາ​ວາຍ ຫຼື ຊາວ​ເກາະ​ປາ​ຊີ​ຟິກ",
    "white_european": "ຄົນ​ຂາວ ຫຼື ຊາວ​ຄໍ​ເຄ​ຊຽນ",
    "unknown": "ບໍ່ຮູ້​ຈັກ",
    "prefer_not_to_disclose": "ເລືອກ​ທີ່​ຈະ​ບໍ່ເປີດ​ເຜີຍ",
    "asian_indian": "ຄົນອິນເດຍເຊື້ອ​ສາຍອາຊີ",
    "filipino": "ຄົນຟິລິບ​ປິນ",
    "japanese": "ຄົນຍີປຸ່ນ",
    "korean": "ຄົນເກົາຫຼີ",
    "vietnamese": "ຄົນຫວຽດ​ນາມ",
    "other_asian": "ຄົນ​ອາ​ຊີ​ອື່ນໆ",
    "native_hawaiian": "ຄົນພື້ນເມືອງຮາວາຍ",
    "guamanian_or_chamorro": "ຄົນ​ກວາມ ຫຼື ຊາ​ໂມ​ໂຣ",
    "samoan": "ຄົນຊາມົວ",
    "other_pacific_islander": "ຄົນ​ເກາະ​ປາ​ຊີ​ຟິກ​ອື່ນ",
    "chinese": "ຄົນຈີນ",
    "help": "ເຊື້ອ​ຊາດ​ໝາຍ​ເຖິງ​ຊຸດ​ຂອງ​ຄຸນ​ລັກ​ສະ​ນະ​ທາງ​ກາ​ຍະ​ພາບ​ທີ່​ໃຊ້​ເພື່ອ​ຈຳ​ແນກ​ຄວາມແຕກ​ຕ່າງ​ລະ​ຫວ່າງ​ກຸ່ມ​ຄົນ. ເຊື້ອ​ຊາດ​ອາດ​ຈະ ຫຼື ອາດ​ຈະ​ບໍ່​ກົງ​ກັບ​ສັນ​ຊາດ ຫຼື ປະ​ເທດ​ທີ່​ທ່ານ​ເປັນ​ພົນ​ລະ​ເມືອງ.",
    "subtitle": "ກົດ​ລະ​ບຽບ​ຮຽກ​ຮ້ອງ​ໃຫ້​ພວກ​ເຮົາ​ເກັບ​ເອົາທຸກ​ຂໍ້​ມູນ​ຕໍ່​ໄປ​ນີ້.",
    "laotian": "ພາ​ສາລາວ",
    "cambodian": "ພາ​ສາກໍາປູເຈຍ",
    "other": "ອື່ນໆ",
    "bangladeshi": "ລາວບັງກາລາເດດ",
    "hmong": "ຫມົງ",
    "indonesian": "ອິນໂດເນຊຽນ",
    "malaysian": "ມາເລເຊຍນ",
    "pakistani": "ປາກິສຖານ",
    "sri_lankan": "ສີຣິລັງຄາ",
    "thai": "ທາຍ",
    "taiwanese": "ໄທວານ",
    "fijian": "ຟິຈຽນ",
    "guamanian": "ກວາມ",
    "tongan": "ຕອງກາ",
    "bahamian": "ຄົນບາຮາມາ"
  },
  "languages": {
    "en": "ພາ​ສາອັງກິດ",
    "fr": "ພາ​ສາ​ຝ​ຣັ່ງ",
    "es": "ພາ​ສາ​ແອັດ​ສະ​ປາຍ",
    "so": "ພາ​ສາ​ໂຊ​ມາ​ລີ",
    "hmn": "ພາ​ສາ​ມົ້ງ",
    "asl": "ພາ​ສາ​ມື​ອາ​ເມ​ຣິ​ກັນ (ASL)",
    "kar": "ພາ​ສາ​ກາ​ຫຼ່ຽງ",
    "am": "ພາ​ສາ​ອຳ​ຮາ​ຣິກ",
    "ru": "ພາ​ສາ​ລັດ​ເຊຍ",
    "om": "ພາ​ສາ​ໂອ​ໂມ​ໂຣ"
  },
  "symptoms": {
    "fever": "ໄຂ້ ຫຼື​ໜາວ​ສັ່ນ",
    "cough": "ໄອ",
    "muscle_aches": "ເຈັບ​ກ້າມ​ຊີ້ນ",
    "severe_fatigue": "ອ່ອນເພຍຫຼາຍ (ຫຼາຍກວ່າ​ປົກ​ກະ​ຕິ)",
    "trouble_breathing": "ຫາຍ​ໃຈ​ຝືດ",
    "diarrhea": "ຖອກທ້ອງ",
    "loss_of_smell": "ດັງ​ບໍ່​ກິ່ນ",
    "loss_of_taste": "ປາກ​ບໍ່​ໄດ້​ລົດ​ຊາດ",
    "shortness_of_breath": "ຫາຍ​ໃຈ​ຫອບ ຫຼື ຫາຍ​ໃຈ​ຍາກ",
    "headache": "ເຈັບຫົວ",
    "sore_throat": "ເຈັບ​ຄໍ",
    "congestion": "ຕັນ​ດັງ ຫຼື ນ້ຳ​ມູກ​ໄຫຼ",
    "nausea": "ປຸ້ນ​ທ້ອງ ຫຼື ຮາກ​ອອກ",
    "close_contact": "ມີ​ການ​ສຳ​ຜັດ​ໃກ້​ຊິດ​ກັບ​ຄົນ​ຕິດ​ເຊື້ອ*",
    "helper_text": {
      "close_contact": "*ທ່ານ​ໄດ້​ສຳ​ຜັດ​ໃກ້​ຊິດ (ພາຍ​ໃນ 6 ຟຸດ​ເປັນ​ເວ​ລາ​ຢ່າງ​ໜ້ອຍ 15) ກັບ​ຄົນ​ຜູ້​ທີ່ຖືກ​ຢືນ​ຢັນ​ວ່າ​ຕິດ COVID-19 ບໍ?"
    },
    "suspected_exposure": "ສົງ​ໄສວ່າ​ຢູ່​ໃນ​ບ່ອນ​ມີ​ເຊື້ອ",
    "none": "ບໍ່​ມີອາ​ການ"
  },
  "instructions": {
    "title": "ທ່ານໄດ້​ຮັບ​ການ​ລົງ​ທະ​ບຽນ​ແລ້ວ, ແຕ່​ກ່ອນ​ທີ່​ທ່ານ​ຈະ​ໄປ...",
    "p1": "ໜ້າ​ນີ້​ມີ​ຂໍ້​ມູນ​ກ່ຽວ​ກັບ​ການ​ນັດ​ໝາຍ​ຂອງ​ທ່ານ​ພ້ອມ​ທັງ​ລະ​ຫັດ​ບາ​ໂຄດ​ການ​ນັດ​ໝາຍ​ຂອງ​ທ່ານນຳ.",
    "appointment_scheduled": "ການ​ນັດ​ໝາຍ​ຂອງ​ທ່ານ​ຖືກ​ກຳ​ນົດ​ເວ​ລາ​ແລ້ວ",
    "verify_info": {
      "title": "ກວດ​ສອບຂໍ້​ມູນຂອງ​ທ່ານ",
      "p1": "ມັນ​ສຳ​ຄັນ​ທີ່​ຈະ​ຕ້ອງ​ຮັບ​ຮອງ​ຄວາມ​ຖືກ​ຕ້ອງ​ຂໍ້​ມູນ​ຕິດ​ຕໍ່​ຂອງ​ທ່ານເພື່ອ​ໃຫ້​ທ່ານ​ສາ​ມາດ​ເຂົ້າ​ເຖິງ​ຜອດ​ໂທ​ລ​ຄົນ​ເຈັບ​ຂອງ​ທ່ານ​ໄດ້​ຢ່າງ​ໄວ.",
      "p2": "ສົ່ງຄຳ​ຢືນ​ຢັນ​ໄປຫາ​ອີ​ເມວ​ຂອງ​ທ່ານແລ້ວ. ກະ​ລຸ​ນາ​ຄ​ລິກໃສ່​ລິ້ງ​ທີ່​ຈັດ​ໃຫ້.",
      "p3": "ຖ້າ​ທ່ານ​ຈຳ​ເປັນ​ຕ້ອງ​ປ່ຽນ​ຂໍ້​ມູນ​ຕິດ​ຕໍ່​ຂອງ​ທ່ານ, ກະ​ລຸ​ນາ​ກົດ​ປຸ່ມ​ຊ່ວຍ​ເຫຼືອ."
    },
    "verified": "ກວດສອບແລ້ວ",
    "not_verified": "ບໍ່ໄດ້ກວດ​ສອບ",
    "resend_text": "ບໍ່​ໄດ້​ຮັບ​ລິ້ງ​ບໍ?",
    "resend": "ສົ່ງໃໝ່",
    "skip": "ຂ້າມ​ການ​ຮັບ​ຮອງ​ຄວາມ​ຖືກ​ຕ້ອງ ແລະ ສະ​ແດງ​ຄຳ​ຢືນ​ຢັນ",
    "name": "ຊື່",
    "appointment_details": "ການນັດໝາຍ",
    "date_and_time": "ວັນ​ທີ ແລະ ເວ​ລາ",
    "add_to_calendar": "ເພີ່ມ​ໃສ່​ປະ​ຕິ​ທິນ",
    "instructions": "ຄຳ​ແນະ​ນຳ​ທົ່ວ​ໄປ",
    "successfully_flash": "ສ້າງ​ການ​ນັດ​ໝາຍ​ສຳ​ເລັດ​ແລ້ວ!",
    "success_flash_email": "ກວດ​ສອບ​ອີ​ເມວ​ສຳ​ເລັດ​ແລ້ວ",
    "success_flash_phone": "ກວດ​ສອບ​ເບີ​ໂທ​ລະ​ສັບສຳ​ເລັດ​ແລ້ວ",
    "mail_order": "ທ່ານ​ຈະ​ໄດ້​ຮັບ​ຊຸດ​ເຄື່ອງ​ມື​ກວດ​ຂອງ​ທ່ານ​ທາງ​ໄປ​ສະ​ນີ​ພາຍ​ໃນ​ໜຶ່ງ​ອາ​ທິດ.",
    "at_home": "ຜູ້​ໃຫ້​ບໍ​ລິ​ການ​ທາງ​ການ​ແພດ​ຈະ​ມາ​ທີ່​ບ້ານ​ຂອງ​ທ່ານ​ໃນ​ເວ​ລາ​ປະ​ມານ​ນີ້",
    "at_home_instructions": "ຄຳ​ແນະ​ນຳ​ຢູ່​ທີ່​ບ້ານ",
    "mail_order_instructions": "ຄຳ​ແນະ​ນຳ​ຕາມ​ຄຳ​ສັ່ງ​ທາງ​ໄປ​ສະ​ນີ",
    "request_additional_appointment": "ຈອງ​ການ​ນັດ​ໝາຍ​ຕິດ​ຕາມ​ຄືນ",
    "book_additional_appointment": "ຈອງ​ການ​ນັດ​ໝາຍ​ອື່ນ",
    "confirmation_code": "ລະ​ຫັດ​ຢືນ​ຢັນ",
    "completed": "ສຳ​ເລັດ​ແລ້ວ",
    "appointment_barcode": "ລະ​ຫັດ​ບາ​ໂຄດ​ການ​ນັດ​ໝາຍ",
    "dependent_links": "ລິ້ງ​ການ​ນັດ​ໝາຍ​ຄົວ​ເຮືອນ",
    "on_demand_title": "ທ່ານໄດ້​ຮັບ​ການ​ລົງ​ທະ​ບຽນ​ແລ້ວ, ແຕ່​ກ່ອນ​ທີ່​ທ່ານ​ຈະ​ໄປ...",
    "save_link": "ບັນ​ທຶກລິ້ງນີ້",
    "verify_phone": "ກວດ​ສອບເບີໂທລະສັບຂອງ​ທ່ານ",
    "verify_phone_description": "ມັນ​ສຳ​ຄັນ​ທີ່​ຈະ​ຕ້ອງ​ຮັບ​ຮອງ​ຄວາມ​ຖືກ​ຕ້ອງ​ຂໍ້​ມູນ​ຕິດ​ຕໍ່​ຂອງ​ທ່ານເພື່ອ​ໃຫ້​ທ່ານ​ສາ​ມາດ​ເຂົ້າ​ເຖິງບັນ​ທຶກຂອງ​ທ່ານ​ໄດ້​ຢ່າງ​ໄວ. ສົ່ງຂໍ້​ຄວາມການກວດ​ສອບ​ໄປ​ຫາເບີຂອງ​ທ່ານ​ແລ້ວ.",
    "verify_email_description": "ມັນ​ສຳ​ຄັນ​ທີ່​ຈະ​ຕ້ອງ​ຮັບ​ຮອງ​ຄວາມ​ຖືກ​ຕ້ອງ​ຂໍ້​ມູນ​ຕິດ​ຕໍ່​ຂອງ​ທ່ານເພື່ອ​ໃຫ້​ທ່ານ​ສາ​ມາດ​ເຂົ້າ​ເຖິງບັນ​ທຶກຂອງ​ທ່ານ​ໄດ້​ຢ່າງ​ໄວ. ອີ​ເມວການກວດ​ສອບ​ໄປ​ຫາທີ່​ຢູ່​ອີ​ເມວຂອງ​ທ່ານ​ແລ້ວ.",
    "information": "ຂໍ້​ມູນ​ການ​ລົງ​ທະ​ບຽນ",
    "follow_up_appointment": "ການ​ນັດ​ໝາຍການ​ມາ​ຕິດ​ຕາມ​ຄືນ",
    "get_directions": "ເອົາ​ທິດ​ທາງ",
    "cancel": "ຍົກ​ເລີກ​ການ​ນັດ​ໝາຍ",
    "reschedule_appointment": "ກຳນົດເວລາການນັດໝາຍຄືນ​ໃໝ່",
    "reschedule_linked_appointments": "ກຳນົດເວລາການນັດໝາຍຄືນ​ໃໝ່",
    "no_slots": "ບໍ່​ມີເວ​ລາເພີ່ມ​ເຕີມ​ໃຫ້",
    "check_results": "ກວດ​ເບິ່ງຜອດ​ໂທລຄົນ​ເຈັບ",
    "follow_up_modal_header": "ກະ​ລຸ​ນາ​ກຳ​ນົດ​ເວ​ລາ​ສັກ​ເຂັມ​ທີ 2 ຂອງ​ທ່ານ",
    "are_you_sure_you_want_to_cancel": "ທ່ານແນ່ໃຈບໍວ່າທ່ານຕ້ອງການຍົກ​ເລີກການ​ນັດ​ໝາຍນີ້?",
    "please_choose_cancellation_reason": "ກະ​ລຸ​ນາ​ເລືອກເຫດ​ຜົນ​ການ​ຍົກ​ເລີກ​ຢູ່​ລຸ່ມ​ນີ້",
    "additional_details": "ກະ​ລຸ​ນາ​ເພີ່ມລາຍ​ລະ​ອຽດເພີ່ມ​ເຕີມ​ໃສ່ລຸ່ມ​ນີ້",
    "errors": {
      "missing_cancellation_reason": "ກະ​ລຸ​ນາ​ເລືອກເຫດ​ຜົນ​ການ​ຍົກ​ເລີກ"
    },
    "verify_email": "ກວດ​ສອບອີ​ເມວຂອງ​ທ່ານ",
    "redcap_url": "ຄ​ລິກ​ເພື່ອ​ສຳ​ເລັດ​ການ​ສຳ​ຫຼວດ​ຂອງ​ທ່ານ",
    "verify_contact_information": "ກວດ​ສອບຂໍ້​ມູນ​ຕິດ​ຕໍ່​ຂອງ​ທ່ານ",
    "please_also": "ພ້ອມນັ້ນ, ກະ​ລຸ​ນາ",
    "new_title": "ການຢືນຢັນສຳລັບ {{name}}",
    "contact_method": "ວິທີຕິດ​ຕໍ່",
    "next_steps": {
      "title": "ຂັ້ນ​ຕອນ​ຕໍ່​ໄປ",
      "p1": "ທ່ານມີອຸປະກອນກວດເພື່ອເປີດໃຊ້ງານ. ໃນເວລາທີ່ທ່ານພ້ອມທີ່ຈະດຳເນີນການກວດຂອງທ່ານ, ໃຫ້ປະຕິບັດຕາມລິ້ງທີ່ສົ່ງໄປຫາອີເມວຂອງທ່ານເພື່ອກັບຄືນໄປຫາໜ້ານີ້ ແລະ ເປີດໃຊ້ງານອຸປະກອນຂອງທ່ານ​ຢູ່ລຸ່ມນີ້.",
      "administered": "ພວກເຮົາຈະຕິດຕໍ່ຫາທ່ານໃນເວລາທີ່ອຸປະກອນຂອງທ່ານຮອດຫ້ອງທົດລອງ ແລະ ໃນເວລາທີ່ຜົນກວດຂອງທ່ານພ້ອມແລ້ວ. ປົກກະຕິຜົນກວດຂອງທ່ານໃຊ້ເວລາ 3-5 ວັນຫຼັງຈາກທີ່ພວກມັນຮອດຫ້ອງທົດລອງ. ທ່ານສາມາດເບິ່ງຜົນກວດຈາກໜ້າ [Medical history]({{link}}) ຂອງທ່ານໃນເວລທີ່ພວກມັນພ້ອມແລ້ວ.",
      "resulted": "ຜົນກວດມີໃຫ້ຢູ່ໃນໜ້າ [Medical history]({{link}}) ຂອງທ່ານ.",
      "shipping_information": "ຂໍ້​ມູນ​ການ​ຈັດ​ສົ່ງ"
    },
    "save_this_page": {
      "title": "ບັນທຶກ​ໜ້ານີ້",
      "p1": "ນຳໃຊ້ໜ້ານີ້ເພື່ອລາຍງານການກວດ​ດ້ວຍຕົນເອງ ຫຼື ເບິ່ງຜົນກວດຜ່ານປະຫວັດທາງການແພດ.",
      "p2": "ເພື່ອເຂົ້າເຖິງໜ້ານີ້ໃນອະນາຄົດ, ຄລິກລິ້ງໃນອີເມວຢືນຢັນ ຫຼື ຂໍ້ຄວາມ SMS.",
      "p3": "ທ່ານສາມາດບັນທຶກໜ້ານີ້ໂດຍການບຸກມາກມັນ, ເພີ່ມມັນເຂົ້າໃນໜ້າຈໍຫຼັກ ຫຼື ສຳເນົາລິ້ງໄວ້ໃນສະຖານທີ່ປອດໄພ."
    },
    "show_my_barcode": "ສະແດງບາໂຄດຂອງຂ້ອຍ",
    "my_account": "ບັນຊີຂອງ​ຂ້ອຍ",
    "register_another": "ລົງທະບຽນຜູ້ເຂົ້າຮ່ວມອື່ນ",
    "update_vaccine": "ອັບ​ເດດ​ຂໍ້​ມູນ​ການສັກວັກຊີນ",
    "medical_history": "ປະ​ຫວັດ​ທາງ​ການ​ແພດ",
    "upcoming_appointments": "ການ​ນັດ​ໝາຍ​ທີ່​ກຳ​ລັງ​ຈະ​ມາ​ເຖິງ",
    "reschedule": "ກຳ​ນົດ​ເວ​ລາ​ຄືນ​ໃໝ່",
    "resend_confirmation": "ສົ່ງການຢືນຢັນອີກຄັ້ງ",
    "appointment_details_v2": "ລາຍ​ລະ​ອຽດ​ການ​ນັດ​ໝາຍ",
    "confirm_cancellation": "ຢືນຢັນການຍົກເລີກ",
    "confirm_cancellation_question": "ທ່ານແນ່ໃຈບໍວ່າທ່ານຕ້ອງການຍົກ​ເລີກການ​ນັດ​ໝາຍນີ້?",
    "select_new_appointment_time_below": "ເລືອກເວ​ລາ​ນັດ​ໝາຍໃໝ່ຢູ່ດ້ານລຸ່ມ. ຖ້າຫາກທ່ານຕ້ອງການປ່ຽນບໍລິການ ຫຼື ສະຖານທີ່ນັດໝາຍ, ກະລຸນາຍົກເລີກການນັດໝາຍນີ້ ແລະ ດຳເນີນການນັດໝາຍໃໝ່.",
    "no_take_me_back": "ບໍ່, ນຳຂ້ອຍກັບຄຶນ",
    "yes_cancel_appointment": "ແມ່ນແລ້ວ, ຍົກ​ເລີກ​ການ​ນັດ​ໝາຍ",
    "update_appointment": "ອັບເດດການນັດໝາຍ",
    "select_new_appointment_time": "ເລືອກເວລາການນັດໝາຍໃໝ່",
    "clinic": "ຄ​ລິ​ນິກ",
    "services": "ບໍລິການ",
    "appointment_missed": "ການນັດໝາຍທີ່ພາດໄປ",
    "appointment_canceled": "ການນັດໝາຍທີ່ຖືກຍົກເລີກ",
    "screening_complete_for": "Screening complete for {{name}}"
  },
  "result_page": {
    "errors": {
      "invalid_access_code": "ລະຫັດການ​ເຂົ້າ​ເຖິງໃຊ້​ບໍ່​ໄດ້"
    },
    "landing": {
      "p1": "ເພື່ອ​ເບິ່ງ​ຜົນ​ຂອງ​ທ່ານ, ກະ​ລຸ​ນາ​ປ້ອນ:",
      "access_code_label": "ລະ​ຫັດ​ການ​ເຂົ້າ​ເຖິງ",
      "submit_button": "ສົ່ງ",
      "recent_results": "ຜົນ​ຫຼ້າ​ສຸດ​ຂອງ​ທ່ານ"
    },
    "view_results": {
      "headline": "ຜົນ​ກວດ​ສຳ​ລັບ {{name}}",
      "reregister": "ລົງທະບຽນຄືນ​ໃໝ່",
      "status": {
        "likely_positive": "ຜົນອອກ​ມາ​ບໍ່ແນ່ນອນ",
        "test_not_performed": "ຈຳ​ເປັນ​ຕ້ອງກວດ​ຄືນ​ໃໝ່",
        "results_ready": "ຜົນ​ອອກ​ມາ​ພ້ອມ​ແລ້ວ",
        "processing": "ກຳ​ລັງ​ປະ​ມວນ​ຜົນ",
        "test_status": "ສະ​ຖາ​ນະ​ການກວດ",
        "test_result": "ຜົນກວດ",
        "administered": "ໄດ້ສັກຢາແລ້ວ",
        "results_pending": "ຜົນກວດທີ່ລໍຖ້າດຳເນີນການ",
        "test_results": "ຜົນກວດ"
      },
      "at": "ຢູ່ທີ່ {{address}}"
    },
    "result_label": "ຜົນອອກ​ມາ",
    "result": {
      "flu_a": {
        "result_label": "ຜົນກວດ​ໄຂ້​ຫວັດ A"
      },
      "flu_b": {
        "result_label": "ຜົນ​ກວດໄຂ້​ຫວັດ B"
      },
      "covid19": {
        "result_label": "ຜົນ​ກວດພະ​ຍາ​ດ​ໂຄວິດ-19"
      },
      "covid": {
        "result_label": "ຜົນ​ກວດພະ​ຍາ​ດ​ໂຄວິດ-19"
      },
      "sars": {
        "result_label": "ຜົນການກວດ SARS"
      },
      "control": {
        "result_label": "ຄວບຄຸມ"
      },
      "rsv": {
        "result_label": "ຜົນ​ຮັບ RSV"
      },
      "result": {
        "result_label": "ຜົນໄດ້ຮັບ"
      },
      "hba1c": {
        "normal": "ປົກກະຕິ",
        "warning": "ກ່ອນ​ການ​ເປັນ​ເບົາ​ຫວານ",
        "danger": "ພະ​ຍາດ​ເບົາ​ຫວານ"
      },
      "lead_venous": {
        "danger": "ຜິດ​ປົກ​ກະ​ຕິ",
        "normal": "ປົກກະຕິ"
      },
      "tc": {
        "result_label": "ຜົນກວດ TC"
      },
      "hdl": {
        "result_label": "ຜົນກວດ HDL"
      },
      "trg": {
        "result_label": "ຜົນກວດ TRG"
      },
      "ldl": {
        "result_label": "ຜົນກວດ LDL"
      },
      "non_hdl": {
        "result_label": "ຜົນກວດທີ່ບໍ່ແມ່ນ HDL"
      },
      "tc_hdl_ratio": {
        "result_label": "ອັດຕາສ່ວນ TC/HDL"
      },
      "glu": {
        "result_label": "ຜົນກວດ GLU"
      },
      "alere_cholestech_ldx": {
        "danger": "ມີ​ຄວາມ​ສ່ຽງ",
        "warning": "ຜິດ​ປົກ​ກະ​ຕິ",
        "normal": "ປົກກະຕິ"
      },
      "lead": {
        "result_label": "ຊືນ"
      },
      "zinc": {
        "result_label": "ສັງ​ກະ​ສີ"
      },
      "lead_standard_profile": {
        "danger": "ຜິດ​ປົກ​ກະ​ຕິ",
        "normal": "ປົກກະຕິ"
      },
      "creatinine": {
        "danger": "ຜິດ​ປົກ​ກະ​ຕິ",
        "normal": "ປົກກະຕິ"
      },
      "igg": {
        "result_label": "ຜົນກວດ IgG"
      },
      "igm": {
        "result_label": "ຜົນກວດ IgM"
      },
      "blood_glucose_fasted": {
        "normal": "ປະມານ",
        "warning": "ສູງ",
        "danger": "ສູງ",
        "hypoglycemia": "ນ້ຳ​ຕານ​ໃນ​ເລືອດ​ຕ່ຳ",
        "prediabetes": "ກ່ອນເປັນໂລກເບາະຫວານ",
        "diabetes": "ໂລກເບາະຫວານ"
      },
      "total_cholesterol_fasted": {
        "normal": "ປະມານ",
        "elevated": "ສູງ",
        "high": "ສູງ",
        "low": "ຕ່ຳ",
        "abnormal": "ບໍ່ປົກກະຕິ"
      },
      "total_cholesterol_unfasted": {
        "normal": "ປະມານ",
        "elevated": "ສູງ",
        "high": "ສູງ"
      },
      "a1c_now": {
        "normal": "ປະມານ",
        "warning": "ການກຳມະພັດການສູງ",
        "danger": "ການສູງແຂວງ"
      },
      "blood_glucose": {
        "warning": "ຕ່ຳ",
        "normal": "ປົກກະຕິ",
        "prediabetes": "ກ່ອນບາບເບົາຫວານ",
        "danger": "ບາບເບົາຫວານ",
        "hypoglycemia": "ນ້ຳ​ຕານ​ໃນ​ເລືອດ​ຕ່ຳ",
        "medical_emergency": "ການສຸຂະພາບເຫັນໄດ້",
        "inconclusive_1": "ບໍ່ສົມບູນ 1",
        "inconclusive_2": "ບໍ່ສົມບູນ 2",
        "possible_diabetes": "ເປັນໄດ້ວ່າເປັນໂລກເບາະຫວານ"
      },
      "triglycerides": {
        "result_label": "ໄຕຣກລີເຊີຣາຍ"
      },
      "blood_glucose_ucsf": {
        "low": "ຕ່ຳ",
        "normal": "ປົກກະຕິ",
        "high": "ສູງ"
      },
      "syphilis": {
        "result_label": "ຜົນຜະລິດຂອງຊິຟິລິດ"
      },
      "hepatitis_c": {
        "result_label": "ຜົນຜະລິດຂອງຮີປາຕິດ C"
      },
      "hiv": {
        "result_label": "ຜົນຜະລິດຂອງ HIV"
      },
      "rapid_hiv": {
        "positive": "ຕອບສະຫຼອງ (ບວກເບື້ອງຕົ້ນ)",
        "negative": "ບໍ່ຕອບສະຫຼອງ (ລົບ)",
        "did_not_result": "ບໍ່ຖືກຕ້ອງ (ບໍ່ຊັດເຈນ)"
      },
      "rapid_hcv": {
        "positive": "ຕອບສະຫຼອງ (ບວກເບື້ອງຕົ້ນ)",
        "negative": "ບໍ່ຕອບສະຫຼອງ (ລົບ)",
        "did_not_result": "ບໍ່ຖືກຕ້ອງ (ບໍ່ຊັດເຈນ)"
      },
      "rapid_syphilis": {
        "positive": "ຕອບສະຫຼອງ (ບວກເບື້ອງຕົ້ນ)",
        "negative": "ບໍ່ຕອບສະຫຼອງ (ລົບ)",
        "did_not_result": "ບໍ່ຖືກຕ້ອງ (ບໍ່ຊັດເຈນ)"
      }
    },
    "documents": "ເອກະສານຕ່າງໆ",
    "self_administered": "ການ​ກວດທີ່ເຮັດ​ດ້ວຍ​ຕົນ​ເອງ​ ແມ່ນໄດ້​ຮັບ​​ທີ່ {{location}}",
    "patient": "ຄົນເຈັບ",
    "medical_history": "ປະ​ຫວັດ​ທາງ​ການ​ແພດ",
    "overview_title": "ເລືອກການກວດ ຫຼື ບໍລິການເພື່ອເບິ່ງລາຍລະອຽດເພີ່ມເຕີມ ແລະ ​ລາຍ​ການດຳເນີນການເພີ່ມເຕີມໃດ​ໜຶ່ງທີ່ບໍ່ຈຳເປັນ. ໃຫ້ຮັບຮູ້ວ່າຜົນກວດບາງຢ່າງອາດຍັງຈະບໍ່ພ້ອມໃຫ້ ຫຼື ຈະມີພ້ອມໃຫ້ຫຼັງຈາກທີ່ປຶກສາກັບຜູ້ໃຫ້ບໍລິການ.",
    "insurance_information": "ຕື່ມຂໍ້ມູນປະກັນໄພ",
    "contact_support": "ຖ້າ​ທ່ານ​ຕ້ອງ​ການ​ຄວາມ​ຊ່ວຍ​ເຫຼືອ​, ກະ​ລຸ​ນາ​ຕິດ​ຕໍ່ຜູ້ສະໜັບສະໜູນ.",
    "show": "ສະແດງ",
    "hide": "ເຊື່ອງ",
    "lab_report": "ບົດ​ລາຍ​ງານ​ຈາກ​ຫ້ອງ​ທົດລອງ",
    "contact_provider": {
      "header": "ທ່ານມີຜົນກວດເປັນບວກ",
      "description": "ທ່ານຍັງຕ້ອງການລົມກັບຜູ້ໃຫ້ບໍລິການຂອງທ່ານເພື່ອປຶກສາກ່ຽວກັບຜົນກວດ ຫຼື ການປິ່ນປົວຂອງທ່ານບໍ?",
      "yes_option_text": "ແມ່ນແລ້ວ, ຂ້ອຍຕ້ອງການລົມກັບຜູ້ໃຫ້ບໍລິການ",
      "no_option_text": "ບໍ່ແມ່ນ, ຂ້ອຍໄດ້ອ່ານ ແລະ ເຂົ້າ​ໃຈຜົນກວດຂອງຂ້ອຍ ແລະ ບໍ່ຕ້ອງການລົມກັບຜູ້ໃຫ້ບໍລິການ",
      "confirm_phone_number_header": "ຢືນ​ຢັນເບີ​ໂທ​ລະ​ສັບຂອງທ່ານ",
      "confirm_phone_number_description": "ກະລຸນາຢືນຢັນເບີໂທລະສັບທີ່ດີທີ່ສຸດສຳລັບການໃຫ້ຄຳປຶກສາແກ່ທ່ານ.",
      "confirm_button_text": "ຢືນຢັນ",
      "consultation_confirmed_header": "ການປຶກສາໄດ້ຮັບການຢືນຢັນ",
      "consultation_confirmed_description": "ຜູ້ໃຫ້ບໍລິການຈະໂທຫາທ່ານທີ່ {{phone_number}} ພາຍໃນ 2-3 ວັນທາງລັດຖະການ.",
      "acknowledgement_option_helper_text": "ບາງຢ່າງທີ່ອະທິບາຍຄວາມສຳຄັນຂອງການປິ່ນປົວ ແລະ ໃຫ້ຜູ້ໃຊ້ຮູ້ວິທີກຳນົດເວລາໃນການໂທຫາ, ຖ້າຫາກພວກເຂົາປ່ຽນໃຈ.",
      "acknowledgement_confirmed_header": "ການຮັບຮູ້ໄດ້ຮັບການຢືນຢັນ",
      "acknowledgement_confirmed_description": "ບາງຢ່າງກ່ຽວກັບຄວາມສຳຄັນຂອງການຮັບການປິ່ນປົວດ້ວຍຊັບພະຍາກອນທີ່ເຊື່ອມກັນ. ກະລຸນາຮັບຮູ້ວ່າການປຶກສາແມ່ນບໍ່ເສຍຄ່າໃຊ້ຈ່າຍໃດໆ ແລະ ຜູ້ໃຫ້ບໍລິການສາມາດກຳນົດ ຫຼື ເຮັດສິ່ງອື່ນໆເພື່ອຊ່ວຍພວກເຂົາແກ້ໄຂການຕິດເຊື້ອ.",
      "acknowledgement_confirmed_change_mind_text": "ຖ້າຫາກທ່ານປ່ຽນໃຈ, ພຽງແຕ່ຄລິກ “ຂ້ອຍຕ້ອງການຄຳປຶກສາ” ຢູ່ລຸ່ມນີ້.",
      "request_consultation_button_text": "ຂ້ອຍຕ້ອງການຄຳປຶກສາ"
    },
    "phone": "ໂທລະສັບ",
    "source": "Source"
  },
  "required": {
    "errors": {
      "address_not_in_bounding_box": "ທີ່​ຢູ່​ຂອງ​ທ່ານ​ບໍ່​ກົງ​ກັບເງື່ອນໄຂກໍານົດການມີສິດໄດ້ຮັບສຳ​ລັບ​ບ່ອນ​ຕ່າງໆ​ຢູ່​ໃນ​ໂຄງ​ການ​ນີ້."
    }
  },
  "member": {
    "medical_history": "ປະຫວັດ",
    "view_instructions": "ເບິ່ງ​ຄຳ​ຢືນ​ຢັນ",
    "next_appointment": "ການ​ນັດ​ໝາຍ​ຄັ້ງ​ຕໍ່​ໄປ",
    "over_18": "ຂ້ອຍຢັ້ງ​ຢືນວ່າ​ຂ້ອຍມີອາຍຸເກີນ 18 ປີແລ້ວ",
    "choose_test_configurations_title": "ກະ​ລຸ​ນາ​ເລືອກບໍ​ລິ​ການ​ທີ່​ທ່ານ​ຢາກ​ຈະ​ໄດ້​ສຳ​ລັບ​ການ​ນັດ​ໝາຍ​ນີ້",
    "member_taken_error": "ຜູ້​ໃຊ້​ມີ​ບັນ​ຊີ​ແລ້ວ",
    "choose_test_configurations_subtitle": "ບໍ​ລິ​ການ​ຈະ​ຖືກ​ນຳ​ໃຊ້​ກັບ​ສະ​ມາ​ຊິກ​ຄົວ​ເຮືອນ​ທຸກ​ຄົນ",
    "service": "ການບໍລິການ",
    "group_or_location": "ກຸ່ມ/ສະ​ຖານ​ທີ່",
    "details": "ລາຍລະອຽດ",
    "see_all": "ເບິ່ງ​ທັງ​ໝົດ",
    "no_history": "ບໍ່ມີປະຫວັດໃນຕອນນີ້.",
    "medical_history_title_with_name": "ປະ​ຫວັດ​ຂອງ {{name}}",
    "no_dashboard": "ບໍ່ມີຫຍັງພ້ອມໃຊ້ງານໃນຕອນນີ້",
    "product": "ຜະ​ລິດ​ຕະ​ພັນ",
    "price": "ລາຄາ",
    "quantity": "ຈຳ​ນວນ",
    "total_services_selected": "ການ​ບໍ​ລິ​ການທັງ​ໝົດທີ່​ໄດ້​ເລືອກ",
    "total_price": "ລາ​ຄາທັງ​ໝົດ"
  },
  "or": "ຫຼື",
  "account": {
    "errors": {
      "must_be_13": "ຕ້ອງ​ມີ​ອາ​ຍຸ 13 ປີ​ເພື່ອ​ລົງ​ທະ​ບຽນ​ຂໍ​ບັນ​ຊີ"
    }
  },
  "self_administration": {
    "title": "ການ​ບໍ​ລິ​ຫານຕົວເອງ",
    "self_administer_action": "ບໍ​ລິ​ຫານ​ຕົວ​ເອງ",
    "skip_to_link": "ຂ້າມ​ໄປ​ຫາ​ການ​ບໍ​ລິ​ຫານ​ຕົວ​ເອງ",
    "select_person": "ເລືອກ​ຄົນ​ທີ່​ຈະ​ແຈ້ງ​ອອກ",
    "adult": "ຜູ້​ໃຫຍ່",
    "child": "ເດັກ​ນ້ອຍ",
    "checked_out": "ແຈ້ງອອກ",
    "go_back": "ກັບ​ຄືນ",
    "switch_to_scanner": "ກັບ​ໄປ​ທີ່ເຄື່ອງ​ສະ​ແກນ",
    "enter_barcode": "ປ້ອນບາ​ໂຄດ",
    "scan_barcode": "ສະ​ແກນ​ບາ​ໂຄດ",
    "cancel": "ຍົກເລີກ",
    "barcode_for": "ບາ​ໂຄດ​ສຳ​ລັບ",
    "enter_barcode_manually": "ປ້ອນບາ​ໂຄດດ້ວຍ​ຕົນ​ເອງ",
    "instructions": "ສະ​ແກນ ຫຼື ປ້ອນ​ບາ​ໂຄດ​ທີ່​ຕັ້ງ​ຢູ່​ຫຼອດ​ທົດ​ສອບດ້ານ​ລຸ່ມ.",
    "regex_prefix": "ບາ​ໂຄດ​ຕ້ອງ​ປະ​ກອບ​ດ້ວຍ",
    "all_completed": "ສໍາເລັດແລ້ວ: ສະ​ມາ​ຊິກ​ໃນ​ເຮືອນ​ທຸກ​ຄົນ​ເຮັດ​ສຳ​ເລັດ​ຂັ້ນ​ຕອນນີ້ແລ້ວ",
    "errors": {
      "no_appointment": "ບັນ​ຫາ​ກ່ຽວ​ກັບ​ການ​ຫາ​ທີ່​ຢູ່​ການ​ນັດ​ພົບ",
      "barcode_in_use": "ບາ​ໂຄດນີ້​ຖືກ​ນຳ​ໃຊ້​ກ່ອນ​ໜ້ານີ້​ແລ້ວ. ຖ້າ​ຫາກ​ກ່ອນ​ໜ້ານີ້, ທ່ານບໍ່​ໄດ້​ສັກ​ຢາ​ດ້ວຍ​ຕົນ​ເອງດ້ວຍອຸ​ປະ​ກອນນີ້, ກະ​ລຸ​ນາ​ລົມ​ກັບພະ​ນັກ​ງານ​ທີ່​ສະ​ໜາມ​ທີ່​ເອົາ​ຊຸດ​ອຸ​ປະ​ກອນນີ້​ໃຫ້​ທ່ານ ແລະ ຂໍ​ເອົາຊຸດ​ໃໝ່. ຖ້າບໍ່​ດັ່ງ​ນັ້ນ, ຄ​ລິກ​ປຸ່ມ​ຊ່ວຍ​ເຫຼືອ​ລຸ່ມນີ້.",
      "test_error_general": "ບັນ​ຫາການ​ສ້າງ​ການ​ທົດ​ສອບ",
      "different_barcodes": "ບາ​ໂຄດ​ທີ່​ທ່ານ​ປ້ອນ​ເຂົ້າ​ໄປ​ບໍ່​ກົງ​ກັນ."
    },
    "confirm_barcode_input": "ປ້ອນບາ​ໂຄດອີກ​ຄັ້ງ​ເພື່ອ​ຢືນ​ຢັນ",
    "click_to_self_test": "ຄ​ລິກ​ທີ່ນີ້​ເພື່ອ​ທົດ​ສອບ​ດ້ວ​ຍ​ຕົນ​ເອງ",
    "or_enter_barcode_manually": "Or enter manually with all letters and numbers"
  },
  "location_details": {
    "next_appointment": {
      "waitlist": "ບັນ​ຊີ​ລໍ​ຖ້າ",
      "unavailable": "ບໍ່​ມີ​ການ​ນັດ​ໝາຍ​ໃຫ້"
    },
    "labels": {
      "address": "ທີ່​ຢູ່",
      "next_appointment": "ການ​ນັດ​ໝາຍ​ທີ່​ມີ​ໃຫ້​ຄັ້ງ​ຕໍ່​ໄປ",
      "services": "ບໍ​ລິ​ການທີ່​ມີ​ໃຫ້"
    }
  },
  "waiting_room": {
    "header": "ທ່ານ​ກຳ​ລັງ​ຢູ່​ໃນ​ຫ້ອງ​ລໍ​ຖ້າ; ກະ​ລຸ​ນາ​ລໍ​ຖ້າ​ໃນ​ຂະ​ນະ​ທີ່​ພວກ​ເຮົາ​ເຊື່ອມ​ຕໍ່​ທ່ານ​ກັບ​ລະ​ບົບ​ການ​ກຳ​ນົດ​ເວ​ລາ.",
    "p1": "ກະ​ລຸ​ນາ​ລໍ​ຖ້າ. ພວກ​ເຮົາ​ຈະ​ເຮັດ​ໃຫ້​ການ​ແພ່​ລະ​ບາດ​ນີ້​ສິ້ນ​ສຸດ​ໄປ​ດ້ວຍ​ກັນ.",
    "signature": "ດ້ວຍຄວາມຮັກແພງ,",
    "p2": "ພວກ​ເຮົາ​ຮູ້​ວ່າການ​ພົບ​ກັບ COVID-19 ສາ​ມາດ​ເປັນ​ສິ່ງ​ທີ່​ເຮັດ​ໃຫ້​ເກີດ​ຄວາມ​ທໍ້​ຖອຍ​ໃຈ. ກະ​ລຸ​ນາ​ລໍ​ຖ້າ​ຢູ່​ບ່ອນ​ນີ້​ໃນ​ຂະ​ນະ​ທີ່​ພວກ​ເຮົາ​ເຮັດ​ວຽກ​ໃຫ້​ໄດ້​ໄວ​ທີ່​ສຸດ​ເທົ່າ​ທີ່​ສາ​ມາດ​ເຮັດ​ໄດ້​ເພື່ອ​ເອົາ​ທ່ານ​ເຂົ້າ​ໄປ​ບ່ອນ​ທີ່​ຈະ​ຈອງ​ການ​ນັດ​ໝາຍ​ຂອງ​ທ່ານ."
  },
  "users": {
    "send_appointment_confirmation_message": "ສະ​ບາຍ​ດີ {{full_name}}. ນີ້​ເປັນ​ການ​ເຕືອນ​ສຳ​ລັບ​ການ​ນັດ​ໝາຍ​ຂອງ​ທ່ານ​ຢູ່​ທີ່ {{name}}. ການ​ນັດ​ໝາຍ​ຂອງທ່ານໃນ​ວັນ​ທີ {{date}} ເວ​ລາ {{time}} ຢູ່​ທີ່ {{address}}.",
    "send_registration_link": "ສະ​ບາຍ​ດີ, {{full_name}}. ປະ​ຕິ​ບັດ​ຕາມ​ລິ້ງ​ນີ້​ເພື່ອ​ລົງ​ທະ​ບຽນ​ສຳ​ລັບ {{name}} {{registration_link}} ຂອງ​ທ່ານ"
  },
  "follow_up": {
    "first_dose": "ເຂັມ​ທຳ​ອິດ",
    "previous_appointment": "ການ​ນັດ​ໝາຍ​ຜ່ານ​ມາ",
    "booked_appointment": "ການ​ນັດ​ໝາຍ​ຂອງ​ທ່ານ",
    "second_dose": "ເຂັມ​ທີ​ສອງ",
    "choose_second_location": "ເລືອກ​ສະ​ຖານ​ທີ່​ນັດ​ໝາຍ​ທີ​ສອງ"
  },
  "cancellation_types": {
    "cant_get_to_location": "ຂ້ອຍ​ມີ​ເວ​ລາ​ຕຳ​ກັນ",
    "timing_conflict": "ຂ້ອຍ​ບໍ່​ສະ​ບາຍ​ດ້ວຍ COVID-19",
    "became_ill_with_covid19": "ຂ້ອຍ​ໄດ້​ຮັບ​ບໍ​ລິ​ການ​ຢູ່​ບ່ອນ​ອື່ນ",
    "received_service_elsewhere": "ອື່ນໆ",
    "other": "ພິມ​ຊື່​ແທນ",
    "duplicate_appointment": "ນີ້​ແມ່ນ​ກາ​ນ​ນັດ​ໝາຍຊ້ອນ​ກັນ"
  },
  "translation": {
    "consent": {
      "type_name_instead": "ພິມ​ຊື່",
      "type_name": "ພິມ​ລາຍເຊັນແທນ"
    }
  },
  "email": {
    "confirmation": {
      "your_access_code_is": "ລະ​ຫັດການເຂົ້າ​ເຖິງຂອງ​ທ່ານ​ແມ່ນ",
      "please_confirm_your_email": "ກະ​ລຸ​ນາ​ຢືນ​ຢັນ​ອີ​ເມວ​ຂອງ​ທ່ານ​ໂດຍ​ການ​ຄ​ລິກ​ໃສ່​ປຸ່ມ​ຢູ່​ລຸ່ມ​ນີ້",
      "hello": "ສະບາຍດີ",
      "click_to_reschedule": "ຄ​ລິກ​ໃສ່​ສະ​ແດງ​ຄຳ​ແນະ​ນຳ/ກຳ​ນົດ​ເວ​ລາ​ຄືນ​ໃໝ່",
      "click_to_reschedule_on_demand": "ຄ​ລິກ​ເພື່ອສະ​ແດງຄຳ​ແນະ​ນຳ"
    },
    "cancellation": {
      "your_appointment_has_been_canceled": "ການ​ນັດ​ໝາຍ​ຂອງ​ທ່ານ​ໃນ​ເວລາ {{time}} ຢູ່​ທີ່ {{location}} ໄດ້​ຖືກ​ຍົກ​ເລີກ​ແລ້ວ. ຖ້າ​ນີ້​ແມ່ນ​ຄວາມ​ຜິດ​ພາດ, ກະ​ລຸ​ນາ​ສົ່ງ​ອີ​ເມວ​ຫາ support@primary.health"
    }
  },
  "signature_lines": {
    "name": "ຊື່ຜູ້​ເຂົ້າ​ຮ່ວມ",
    "date": "ວັນທີ",
    "signature": "ລາຍ​ເຊັນຜູ້​ເຂົ້າ​ຮ່ວມ",
    "and_or": "ແລະ/ຫຼື",
    "guardian_name": "ຊື່ພໍ່​ແມ່/ຜູ້​ປົກ​ຄອງ",
    "guardian_signature": "ລາຍ​ເຊັນພໍ່​ແມ່/ຜູ້​ປົກ​ຄອງ"
  },
  "employer_testing": {
    "hello_name": "ສະ​ບາຍ​ດີ {{name}}",
    "results": {
      "negative": "ລົບ",
      "positive": "ບ​ວກ",
      "did_not_result": "ບໍ່ຮູ້​ຈັກ",
      "unknown": "ບໍ່ຮູ້​ຈັກ",
      "invalid": "ໃຊ້ບໍ່ໄດ້",
      "pending": "ກຳລັງລໍຖ້າ"
    },
    "code_reader": {
      "scan_your_code": "ສະ​ແກນລະ​ຫັດໃນບັດ​ກວດຂອງ​ທ່ານ",
      "find_in_top_right_hand_corner": "ລະ​ຫັດ​ສະ​ແກນ​ແມ່ນ​ສະ​ເພາະ​ກັບ​ການກວດ​ຂອງ​ທ່ານ ແລະ ສາ​ມາດເຫັນ​ຢູ່​ມູມ​ຂວາ​ດ້ານ​ເທິງ​ສຸດ​ຂອງ​ໃບ​ກວດ​ຂອງ​ທ່ານ",
      "center_code": "ເອົາ​ລະ​ຫັດ​ໃສ່​ເຄິ່ງ​ກາງ​ບ່ອນ​ນີ້.",
      "already_used_error": "ບາໂຄດການກວດນີ້​ຖືກ​ໃຊ້​ແລ້ວ. ກະ​ລຸ​ນາ​ຕິດ​ຕໍ່​ຜູ້​ໃຫ້​ຊຸດ​ເຄື່ອງ​ມື​ກວດ​ຂອງ​ທ່ານ​ສຳ​ລັບ​ການ​ຊ່ວຍ​ເຫຼືອ.",
      "click_to_scan": "ຄ​ລິກ​ເພື່ອສະ​ແກນລະຫັດ QR",
      "scan_new_test_card": "ຄ​ລິກ​ໃສ່​ບ່ອນ​ນີ້​ເພື່ອ​ສະ​ແກນ​ລະ​ຫັດ​ກວດ​ໃໝ່.",
      "not_working": "ໃຊ້​ບໍ່​ໄດ້​ບໍ?",
      "try_again": "ຄ​ລິກ​ໃສ່ບ່ອນ​ນີ້ເພື່ອລອງໃໝ່ອີກ."
    },
    "continue": "ສືບ​ຕໍ່",
    "verify_date_of_birth": {
      "confirm_date_of_birth": "ກະ​ລຸ​ນາ​ຢືນ​ຢັນ​ວັນ​ເດືອນ​ປີ​ເກີດ​ຂອງ​ຜູ້​ເຂົ້າ​ຮ່ວມ​ເພື່ອ​ສືບ​ຕໍ່",
      "contact_administrator": "ຖ້າຂໍ້​ມູນທີ່​ສະ​ແດງບໍ່​ຖືກ​ຕ້ອງ, ກະ​ລຸ​ນາຕິດ​ຕໍ່​ພະ​ນັກ​ງານບໍ​ລິ​ຫານເພື່ອ​ຂໍ​ຄວາມ​ຊ່ວຍ​ເຫຼືອ",
      "error": "ວັນ​ເດືອນ​ປີ​ເກີດ​ທີ່​ປ້ອນເຂົ້າ​ບໍ່​ຖືກ​ຕ້ອງ — ກະ​ລຸ​ນາ​ລອງ​ອີກ​ຄັ້ງ ຫຼື ຕິດ​ຕໍ່​ພະ​ນັກ​ງານ​ບໍ​ລິ​ຫານ​ໃນ​ບ່ອນ​ຂອງ​ທ່ານ​ເພື່ອ​ໃຫ້​ອັບ​ເດດ​ອັນ​ນີ້."
    },
    "result_entry": {
      "code_is_registered": "ລະ​ຫັດ​ຂອງ​ທ່ານ​ຖືກ​ລົງ​ທະ​ບຽນ​ແລ້ວ.",
      "take_the_test": "ເຮັດ​ການກວດ",
      "follow_the_instructions": "ປະ​ຕິ​ບັດ​ຕາມ​ຄຳ​ແນະ​ນຳທີ່​ມາ​ພ້ອມ​ກັບ​ການກວດ​ຂອງ​ທ່ານ ແລະ ເລີ່ມ​ຕົ້ນ​ຈັບ​ເວ​ລາ 15 ນາ​ທີ​ເມື່ອ​ທ່ານ​ເອົາສຳ​ລີເຍື່ອ​ເມືອກ​ຈາກ​ດັງ​ເຂົ້າ​ໃສ່ຫຼອດ​ແກ້ວ​ໃນ​ແຜ່ນກວດ.",
      "cant_find_instructions": "ບໍ່​ສາ​ມາດ​ຊອກ​ຫາ​ຄຳ​ແນະ​ນຳ​ໄດ້​ບໍ?",
      "view_instructions": "ເບິ່ງ​ຄຳ​ແນະ​ນຳ Binax Now",
      "start_timer": "ເລີ່ມ​ຈັບ​ເວ​ລາ 15 ນາ​ທີ (ເປັນ​ທາງ​ເລືອກ)",
      "submit_within_15": "ເຄັດ​ລັບ: ສົ່ງ​ຜົນ​ຂອງ​ທ່ານ​ພາຍ​ໃນ 15 ນາ​ທີ",
      "enter_test_results": "ປ້ອນຜົນກວດ",
      "choose_a_result": "ເລືອກ​ຕົວ​ເລືອກ​ທີ່​ອະ​ທິ​ບາຍ​ຜົນ​ອອກ​ມາ​ໃນ​ບັດ​ກວດ​ຂອງ​ທ່ານ",
      "submit": "ສົ່ງ",
      "must_log_result_and_photo": "ທ່ານ​ຕ້ອງ​ບັນ​ທຶກ​ຜົນ ແລະ ຖ່າຍ​ຮູບ​ການກວດ​ຂອງ​ທ່ານ​ເພື່ອ​ສືບ​ຕໍ່",
      "submit_within_15_v2": "ເຄັດ​ລັບ: ສົ່ງ​ຜົນ​ກວດ​ຂອງ​ທ່ານ​ຫຼັງ​ຈາກ​ການກວດ​ໄດ້​ຜ່ານ​ໄປ 15 ນາ​ທີ",
      "click_to_take_photo": "ຄ​ລິກເພື່ອ​ຖ່າຍຮູບ",
      "results_may_be_invalid": "ຜົນ​ກວດ​ອາດ​ຈະ​ໃຊ້​ບໍ່​ໄດ້"
    },
    "summary": {
      "title": "ສັງ​ລວມຜົນກວດ",
      "negative_subtitle": "ທ່ານ​ບໍ່ມີ COVID-19",
      "negative_message": "ພວກ​ເຮົາ​ໄດ້​ສົ່ງ​ແຈ້ງ​ການ​ໃຫ້​ນາຍ​ຈ້າງ​ຂອງ​ທ່ານ​ວ່າ​ທ່ານ​ປອດ​ໄພ​ແລ້ວ​ທີ່​ຈະ​ກັບ​ມາ​ເຮັດ​ວຽກ​ຢູ່​ໃນ​ບ່ອນ​ເຮັດ​ວຽກ​ໄດ້.",
      "positive_subtitle": "ຂໍ​ອະ​ໄພ, ແຕ່​ທ່ານມີ COVID-19",
      "positive_message": "ພວກ​ເຮົາ​ໄດ້​ສົ່ງ​ແຈ້ງ​ການ​ໃຫ້​ນາຍຈ້າງ​ຂອງ​ທ່ານ​ວ່າ​ທ່ານ​ມີ COVID-19.",
      "what_should_you_do": "ສິ່ງ​ທີ່​ທ່ານ​ຄວນ​ເຮັດ:",
      "employer_name_resources": "ຊັບ​ພະ​ຍາ​ກອນຊື່ນາຍຈ້າງ:",
      "follow_cdc_guidelines": "ປະ​ຕິ​ບັດ​ຕາມ​ຂໍ້​ແນະ​ນຳ​ທີ່ CDC ແນະ​ນຳ​ໃຫ້"
    },
    "clarifying_results": {
      "title": "ກຳ​ລັງ​ຊີ້​ແຈງ​ຜົນ​ຂອງ​ທ່ານ",
      "which_did_your_result_most_look_like": "ຜົນ​ຂອງ​ທ່ານ​ຄ້າຍ​ຄື​ອັນ​ໃດ​ຫຼາຍ​ທີ່​ສຸດ?",
      "no_lines": "ບໍ່​ເຫັນເສັ້ນ​ໃດ",
      "blue_control_line_only": "ເສັ້ນ​ຄວບ​ຄຸມ​ສີ​ຟ້າ​ເທົ່າ​ນັ້ນ",
      "pink_sample_line_only": "ເສັ້ນ​ຕົວ​ຢ່າງ​ສີ​ບົວເທົ່າ​ນັ້ນ",
      "blue_control_line_and_pink_sample_line": "ເສັ້ນ​ຄວບ​ຄຸມ​ສີ​ຟ້າ ແລະ ເສັ້ນ​ຕົວ​ຢ່າງ​ສີ​ບົວ/ສີ​ມ່ວງ",
      "still_not_sure": "ຍັງ​ບໍ່​ແນ່​ໃຈ",
      "submit_results": "ສົ່ງ​ຜົນ",
      "thank_you": "ຂອບໃຈ!",
      "clarification_received_message": "ໄດ້​ຮັບການ​ຊີ້​ແຈງ​ຜົນ​ຂອງ​ທ່ານ​ແລ້ວ.",
      "only_pink_line": "ເສັ້ນສີ​ບົວ​ເທົ່າ​ນັ້ນ",
      "no_lines_v2": "ບໍ່​ມີເສັ້ນ"
    },
    "return_to_confirmation": "ກັບ​ຄືນ​ຫາ​ໜ້າຢືນ​ຢັນ"
  },
  "preferred_language": {
    "title": "ພາ​ສາ​ທີ່​ມັກ",
    "subtitle": "ທ່ານຕ້ອງການພາສາໃດ?"
  },
  "appointment_type": {
    "initial_vaccine_appointment": "ຊຸດວັກຊີນເບື້ອງຕົ້ນ",
    "one_dose": "ຈອງ​ການ​ນັດ​ໝາຍ​ສຳ​ລັບ​ເຂັມ​ສະ​ເພາະ. ໂດຍ​ສະ​ເພາະ​ນີ້​ແມ່ນ",
    "title": "ການ​ເລືອກ​ເຂັມ​ຂອງ​ຂ້ອຍ",
    "first_dose": "ເຂັມ​ທຳ​ອິດ​ຂອງ​ຂ້ອຍ",
    "second_dose": "ເຂັມ​ທີ​ສອງ​ຂອງ​ຂ້ອຍ",
    "single_dose_title": "ເຂັມ​ດຽວ​ຂອງ​ຂ້ອຍ",
    "additional": "ເພີ່ມ​ເຕີມ",
    "booster": "ເພີ່ມ​ປະ​ສິດ​ທິ​ພາບ",
    "supplemental": "ເສີມ",
    "third_dose": "ເຂັມ​ທີ​ສາມ​ເທົ່ານັ້ນ",
    "no_vaccine": "ບໍ່ມີຢູ່ຂ້າງເທິງ"
  },
  "activemodel": {
    "errors": {
      "models": {
        "importers/results/row": {
          "attributes": {
            "administered_at": {
              "invalid_datetime": "ຄວນ​ຈະ​ມີ​ຮູບ​ແບບ {{datetime_format}}"
            },
            "results_at": {
              "invalid_datetime": "ຄວນ​ຈະ​ມີ​ຮູບ​ແບບ {{datetime_format}}"
            },
            "uid": {
              "unknown_test": "ບໍ່​ພົບ​ການກວດ"
            }
          }
        },
        "importers/participants/row": {
          "attributes": {
            "access_code": {
              "not_allow_update_test_group_user": "ມີ​ຜູ້​ໃຊ້​ຢູ່ ແລະ ບໍ່​ໄດ້​ຮັບ​ອະ​ນຸ​ຍາດ​ໃຫ້​ອັບ​ເດດ​ມັນ"
            },
            "date_of_birth": {
              "invalid_date": "ຄວນ​ຈະ​ມີ​ຮູບ​ແບບ {{date_format}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "ບໍ່​ມີ {{unknown_ethnicities}} ຢູ່, ເລືອກ​ຈາກ​ຄ່າ​ທີ່​ສາ​ມາດ​ຮັບ​ໄດ້​ຕໍ່​ໄປ​ນີ້: {{ethnicities}}",
                "other": "ບໍ່​ມີ {{unknown_ethnicities}} ຢູ່, ເລືອກ​ຈາກ​ຄ່າ​ທີ່​ສາ​ມາດ​ຮັບ​ໄດ້​ຕໍ່​ໄປ​ນີ້: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "ບໍ່​ມີເພດຢູ່, ເລືອກ​ຈາກ​ຄ່າ​ທີ່​ສາ​ມາດ​ຮັບ​ໄດ້​ຕໍ່​ໄປ​ນີ້: {{genders}}"
            },
            "phone_number": {
              "invalid": "ແມ່ນເລກໝາຍໃຊ້ບໍ່ໄດ້"
            },
            "population_name": {
              "unknown_population": "ບໍ່​ມີ {{unknown_population}} ຢູ່, ເລືອກ​ຈາກ​ຄ່າ​ທີ່​ສາ​ມາດ​ຮັບ​ໄດ້​ຕໍ່​ໄປ​ນີ້: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "ບໍ່​ສາ​ມາດ​ຕື່ມ​ໃສ່​ມັນ​ໄດ້, ຖ້າ​ເພດ​ບໍ່​ແມ່ນ \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "ບໍ່ມີເພດຢູ່, ເລືອກ​ຈາກ​ຄ່າ​ທີ່​ສາ​ມາດ​ຮັບ​ໄດ້​ຕໍ່​ໄປ​ນີ້: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "ບໍ່ມີລົດ​ນິ​ຍົມທາງເພດຢູ່, ເລືອກ​ຈາກ​ຄ່າ​ທີ່​ສາ​ມາດ​ຮັບ​ໄດ້​ຕໍ່​ໄປ​ນີ້: {{sexual_orientations}}"
            },
            "status": {
              "unknown_status": "ສະ​ຖາ​ນະ​ໃຊ້​ບໍ່​ໄດ້, ເລືອກ​ຈາກ​ຄ່າ​ທີ່​ສາ​ມາດ​ຮັບ​ໄດ້​ຕໍ່​ໄປ​ນີ້: {{statuses}}"
            },
            "races": {
              "unknown_races": {
                "one": "ບໍ່​ມີ {{unknown_races}} ຢູ່, ເລືອກ​ຈາກ​ຄ່າ​ທີ່​ສາ​ມາດ​ຮັບ​ໄດ້​ຕໍ່​ໄປ​ນີ້: {{races}}",
                "other": "ບໍ່​ມີ {{unknown_races}} ຢູ່, ເລືອກ​ຈາກ​ຄ່າ​ທີ່​ສາ​ມາດ​ຮັບ​ໄດ້​ຕໍ່​ໄປ​ນີ້: {{races}}"
              }
            },
            "vaccination_status": {
              "unknown_vaccination_status": "vaccination status is invalid, pick from the following acceptable values: {{vaccination_statuses}}"
            }
          }
        },
        "importers/organization_user/row": {
          "attributes": {
            "role": {
              "insufficient_privileges": "ສິດ​ທິ​ພິ​ເສດ​ບໍ່​ພຽງ​ພໍ​ກ່ອນ​ທີ່​ຈະ​ກຳ​ນົດ​ບົດ​ບາດ"
            },
            "user_id": {
              "unknown_user": "ບໍ່​ພົບ​ຜູ້​ໃຊ້"
            }
          }
        },
        "importers/additional_information/row": {
          "attributes": {
            "additional_information": {
              "invalid_json": "JSON ໃຊ້​ບໍ່​ໄດ້"
            },
            "user_id": {
              "unknown_user": "ບໍ່​ພົບ​ຜູ້​ໃຊ້"
            }
          }
        },
        "importers/emr/row": {
          "attributes": {
            "appointment_location": {
              "unknown_location": "ບໍ່​ພົບ​ສະ​ຖານ​ທີ່​ນັດ​ໝາຍ"
            },
            "date_of_birth": {
              "invalid_date": "ຄວນ​ຈະ​ມີ​ຮູບ​ແບບ {{date_format}}"
            },
            "email": {
              "duplicated": "ໃຊ້​ແລ້ວ"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "ບໍ່​ມີ {{unknown_ethnicities}} ຢູ່, ເລືອກ​ຈາກ​ຄ່າ​ທີ່​ສາ​ມາດ​ຮັບ​ໄດ້​ຕໍ່​ໄປ​ນີ້: {{ethnicities}}",
                "other": "ບໍ່​ມີ {{unknown_ethnicities}} ຢູ່, ເລືອກ​ຈາກ​ຄ່າ​ທີ່​ສາ​ມາດ​ຮັບ​ໄດ້​ຕໍ່​ໄປ​ນີ້: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "ບໍ່​ມີເພດຢູ່, ເລືອກ​ຈາກ​ຄ່າ​ທີ່​ສາ​ມາດ​ຮັບ​ໄດ້​ຕໍ່​ໄປ​ນີ້: {{genders}}"
            },
            "phone_number": {
              "invalid": "ແມ່ນເລກໝາຍໃຊ້ບໍ່ໄດ້"
            },
            "population": {
              "unknown_population": "ບໍ່​ມີ {{unknown_population}} ຢູ່, ເລືອກ​ຈາກ​ຄ່າ​ທີ່​ສາ​ມາດ​ຮັບ​ໄດ້​ຕໍ່​ໄປ​ນີ້: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "ບໍ່​ສາ​ມາດ​ຕື່ມ​ໃສ່​ມັນ​ໄດ້, ຖ້າ​ເພດ​ບໍ່​ແມ່ນ \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "ບໍ່ມີເພດຢູ່, ເລືອກ​ຈາກ​ຄ່າ​ທີ່​ສາ​ມາດ​ຮັບ​ໄດ້​ຕໍ່​ໄປ​ນີ້: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "ບໍ່ມີລົດ​ນິ​ຍົມທາງເພດຢູ່, ເລືອກ​ຈາກ​ຄ່າ​ທີ່​ສາ​ມາດ​ຮັບ​ໄດ້​ຕໍ່​ໄປ​ນີ້: {{sexual_orientations}}"
            },
            "races": {
              "unknown_races": {
                "one": "ບໍ່​ມີ {{unknown_races}} ຢູ່, ເລືອກ​ຈາກ​ຄ່າ​ທີ່​ສາ​ມາດ​ຮັບ​ໄດ້​ຕໍ່​ໄປ​ນີ້: {{races}}",
                "other": "ບໍ່​ມີ {{unknown_races}} ຢູ່, ເລືອກ​ຈາກ​ຄ່າ​ທີ່​ສາ​ມາດ​ຮັບ​ໄດ້​ຕໍ່​ໄປ​ນີ້: {{races}}"
              }
            }
          }
        },
        "importers/additional_consents/row": {
          "attributes": {
            "consent_form_id": {
              "unknown_consent_form": "consent_form not found"
            },
            "access_code": {
              "unknown_user": "user not found"
            }
          }
        },
        "importers/vaccine/row": {
          "attributes": {
            "date_of_birth": {
              "invalid_date": "should have format {{date_format}}"
            },
            "phone_number": {
              "invalid": "is an invalid number"
            },
            "email": {
              "duplicated": "is already used"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} does not exist, pick from the following acceptable values: {{races}}",
                "other": "{{unknown_races}} do not exist, pick from the following acceptable values: {{races}}"
              }
            },
            "gender": {
              "unknown_gender": "gender does not exist, pick from the following acceptable values: {{genders}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation does not exist, pick from the following acceptable values: {{sexual_orientations}}"
            },
            "self_described_gender": {
              "not_allowed": "cannot fill it if the gender is not \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "sex does not exist, pick from the following acceptable values: {{sexes}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} does not exist, pick from the following acceptable values: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} do not exist, pick from the following acceptable values: {{ethnicities}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} is an invalid value, pick from the following acceptable values: {{tag_actions}}"
            },
            "population": {
              "unknown_population": "{{unknown_population}} does not exist, pick from the following acceptable values: {{population_names}}"
            },
            "appointment_location": {
              "unknown_location": "appointment location not found"
            },
            "appointment_date": {
              "invalid_date": "should have format {{date_format}}"
            },
            "dose_type": {
              "invalid_dose": "{{unknown_dose}} is not a valid dose, pick from the following acceptable values: {{acceptable_doses}}",
              "unavailable_dose": "{{dose_type}} is not available within the vaccine_lot, pick from the following available values: {{available_doses}}"
            },
            "vis_version_date": {
              "invalid_date": "should have format {{date_format}}"
            }
          }
        },
        "importers/base/row": {
          "attributes": {
            "appointment_time": {
              "invalid_time": "should have format {{time_format}}"
            }
          }
        }
      }
    }
  },
  "activerecord": {
    "errors": {
      "models": {
        "test_location": {
          "attributes": {
            "metadata": {
              "wrong_json": "is not a valid JSON"
            },
            "name": {
              "taken": "ແລະ ທີ່​ຢູ່​ພ້ອມ​ຖືກ​ນຳ​ໃຊ້​ຢູ່​ໃນ​ສະ​ຖານ​ທີ່​ມີ​ຢູ່​ບ່ອນ​ອື່ນ"
            }
          }
        }
      }
    },
    "attributes": {
      "organization_user": {
        "organization_user_leads": {
          "one": "User",
          "other": "Users"
        }
      },
      "appointment_slot_group": {
        "organization_user_leads": {
          "one": "Location",
          "other": "Locations"
        }
      }
    }
  },
  "hispanic_questions": {
    "hispanic": "ເຊື້ອ​ສາຍ​ສະ​ເປນ ຫຼື ລາ​ຕິ​ໂນ/a",
    "not_hispanic": "ບໍ່​ແມ່ນເຊື້ອ​ສາຍ​ສະ​ເປນ ຫຼື ລາ​ຕິ​ໂນ/a",
    "hispanic_expanded": {
      "other": "ຕົ້ນ​ກຳ​ເນີດ​ຈາກ​ເຊື້ອ​ສາຍສະ​ເປນ, ລາຕິ​ໂນ ຫຼື ສະ​ເປນອື່ນ",
      "mexican": "ຄົນເມັກ​ຊິກກັນ, ຄົນ​ອາເມ​ຣິ​ກາ​ເຊື້ອ​ສາຍ​ເມັກ​ຊິກ​ກັນ, ຄົນຊິ​ກາ​ໂນ/a",
      "puerto_rican": "ຄົນເປີ​ໂຕຣິ​ກາ",
      "cuban": "ຄົນກູບາ"
    },
    "subtitle": "ກົດ​ລະ​ບຽບ​ຮຽກ​ຮ້ອງ​ໃຫ້​ພວກ​ເຮົາ​ເກັບ​ເອົາຂໍ້​ມູນ​ຕໍ່​ໄປ​ນີ້",
    "help": "ຊົນ​ເຜົ່າ​ໝາຍ​ເຖິງ​ຊຸດ​ຂອງ​ລັກ​ສະ​ນະ​ສະ​ເພາະ​ທົ່ວ​ໄປ​ເຊັ່ນ: ພາ​ສາ, ປະ​ເພ​ນີ​ທາງວັດ​ທະ​ນະ​ທຳ, ສາ​ສະ​ໜາ ແລະ ຄຸນ​ລັກ​ສະ​ນະ​ອື່ນໆ​ທີ່​ໃຊ້​ເພື່ອ​ຈຳ​ແນກ​ຄວາມ​ແຕກ​ຕ່າງ​ຂອງ​ກຸ່ມ​ຄົນ. ມັນ​ອາດ​ຈະ ຫຼື ອາດ​ຈະ​ບໍ່​ກົງ​ກັບ​ການ​ລະ​ບຸ​ເຊື້ອ​ຊາດ​ຄື​ກັນ.",
    "unknown": "ບໍ່ຮູ້​ຈັກ",
    "title": "ຊົນ​ເຜົ່າ"
  },
  "sex_at_birth": {
    "question": "ເພດ",
    "female": "ເພດ​ຍິງ",
    "male": "ເພດຊາຍ",
    "help": "ເພດ​ໝາຍ​ເຖິງ​ສິ່ງ​ທີ່​ຢູ່​ໃນ​ໃບ​ຢັ້ງ​ຢືນ​ການ​ເກີດ​ຂອງ​ທ່ານ.",
    "non_binary": "ບໍ່ລະບຸ",
    "subtitle": "ກົດ​ລະ​ບຽບ​ຮຽກ​ຮ້ອງ​ໃຫ້​ພວກ​ເຮົາ​ເກັບ​ເອົາທຸກ​ຂໍ້​ມູນ​ຕໍ່​ໄປ​ນີ້.",
    "decline": "ປະ​ຕິ​ເສດ​ທີ່​ຈະ​ຕອບ",
    "unknown": "ບໍ່ຮູ້​ຈັກ"
  },
  "gender": {
    "help": "ລັກ​ສະ​ນະ​ເພດໝາຍ​ເຖິງວ່າ​ທ່ານ​ລະ​ບຸ​ສ່ວນ​ຕົວ​ຂອງ​ທ່ານ​ແນວ​ໃດ. ມັນ​ອາດ​ຈະ ຫຼື ອາດ​ຈະ​ບໍ່​ກົງ​ກັບ​ເພດ​ທີ່​ທ່ານ​ຖືກ​ກຳ​ນົດ​ໃຫ້​ຕອນ​ເກີດ.",
    "subtitle": "ຖ້າ​ທ່ານ​ຢາກ​ຈະ​ເພີ່ມ​ຂໍ້​ມູນ​ກ່ຽວ​ກັບ​ລັກ​ສະ​ນະ​ເພດ​ຂອງ​ທ່ານ, ກະ​ລຸ​ນາ​ເຮັດ​ແນວ​ນັ້ນ."
  },
  "sexual_orientation": {
    "title": "ລົດ​ນິ​ຍົມທາງ​ເພດ",
    "subtitle": "ຖ້າ​ທ່ານ​ຢາກ​ຈະ​ເພີ່ມ​ຂໍ້​ມູນ​ກ່ຽວ​ກັບລົດ​ສະ​ນິ​ຍົມ​ທາງເພດ​ຂອງ​ທ່ານ, ກະ​ລຸ​ນາ​ເຮັດ​ແນວ​ນັ້ນ.",
    "gay": "ເກ, ເລັ​ສ​ບ້ຽນ ຫຼື ຜູ້​ທີ່​ມັກ​ເພດ​ດຽວ​ກັນ",
    "heterosexual": "ຄົນ​ທີ່​ມັກ​ເພດ​ກົງ​ກັນ​ຂ້າ ຫຼື ບໍ່​ແມ່ນ​ກະ​ເທີຍ",
    "bisexual": "ເປັນ​ທັງ​ສອງ​ເພດ",
    "questioning": "ສົງ​ໄສ/ບໍ່​ແນ່​ໃຈ/ບໍ່​ຮູ້",
    "prefer_not_to_disclose": "ເລືອກ​ທີ່​ຈະ​ບໍ່ເປີດ​ເຜີຍ",
    "unknown": "ບໍ່ຮູ້​ຈັກ",
    "orientation_not_listed": "ຄວາມ​ມັກ​ບໍ່​ໄດ້​ມີ​ຢູ່​ໃນ​ລາຍ​ການ",
    "not_applicable": "ບໍ່ກ່ຽວຂ້ອງ",
    "pansexual": "ພານເຊັກຊວນ",
    "queer": "ຄວຽ"
  },
  "pronouns": {
    "title": "ຄຳ​ແທນ​ນາມ​ທີ່​ເລືອກ",
    "he": "ລາວ/ລາວ",
    "she": "ນາງ/ນາງ",
    "they": "ພວກ​ເຂົາ/ພວກ​ເຂົາ",
    "choose_not_to_disclose": "ເລືອກ​ທີ່​ຈະ​ບໍ່ເປີດ​ເຜີຍ",
    "prefer_to_self_describe": "ເລືອກ​ທີ່​ຈະ​ອະ​ທິ​ບາຍ​ຕົວ​ເອງ"
  },
  "no_insurance_information": {
    "driver_license_or_state_id": "ໃບ​ອະ​ນຸ​ຍາດ​ຂັບ​ຂີ່ ຫຼື ເລກ​ບັດ​ປະ​ຈຳ​ຕົວ (ID) ຂອງ​ລັດ",
    "driver_license": "ໃບ​ອະ​ນຸ​ຍາດ​ຂັບ​ຂີ່",
    "use_ssn_instead": "ໃຊ້ SSN ແທນ",
    "social_security_number": "ເລກປະກັນສັງຄົມ",
    "state": "ລັດ",
    "upload_front_of_driver_license": "ອັບ​ໂຫຼດ​ດ້ານ​ໜ້າ​ໃບ​ຂັບ​ຂີ່​ຂອງ​ທ່ານ ຫຼື ເລກບັດ​ປະ​ຈຳ​ຕົວ (ID) ລັດຂອງ​ທ່ານ",
    "choose_file": "ເລືອກໄຟລ໌",
    "no_file_chosen": "ບໍ່​ໄດ້​ເລືອກໄຟ​ລ໌​ໃດ",
    "no_identification": "ຂ້ອຍ​ບໍ່​ມີບັດ​ປະ​ຈຳ​ຕົວ",
    "insurance_card_has_a_back": "ບັດ​ປະ​ກັນ​ໄພຂອງ​ຂ້ອຍ​ມີ​ດ້ານຫຼັງ",
    "upload_front_of_insurance_card": "ອັບ​ໂຫຼດ​ດ້ານ​ໜ້າ​ບັດ​ປະ​ກັນ​ໄພ​ຂອງ​ທ່ານ.",
    "front_of_insurance_card_uploaded": "ດ້ານ​ໜ້າ​ບັດ​ປະ​ກັນ​ໄພ​ຂອງ​ທ່ານໄດ້​ອັບ​ໂຫຼດ​ແລ້ວ.",
    "insurance_card": "ບັດ​ປະ​ກັນ​ໄພ",
    "insurance_card_back": "ດ້ານຫຼັງບັດ​ປະ​ກັນ​ໄພ",
    "back_of_insurance_card_uploaded": "ດ້ານຫຼັງ​ບັດ​ປະ​ກັນ​ໄພ​ຂອງ​ທ່ານໄດ້​ອັບ​ໂຫຼດ​ແລ້ວ."
  },
  "quidel": {
    "certify_text": "ຂ້ອຍ​ຢັ້ງ​ຢືນ​ວ່າ​ຂ້ອຍ​ກຳ​ລັງ​ຈະ​ບັນ​ທຶກ​ຜົນ​ກວດ​ນີ້​ຄັ້ງ​ດຽວ​ເທົ່າ​ນັ້ນ.",
    "entering_results": "ການ​ປ້ອນ​ຜົນ​ກວດ​ຂອງ​ທ່ານ",
    "review_instructions_1": "ທົບ​ທວນ​ຄຳ​ແນະ​ນຳ​ຄືນ​ຢ່າງ​ລະ​ມັດ​ລະ​ວັງ",
    "review_instructions_2": "ທ່ານ​ສາ​ມາດ​ທົບ​ທວນ​ເບິ່ງ​ຄຳ​ແນະ​ນຳ​ທີ່​ມາ​ພ້ອມ​ກັບ​ການກວດ​ຂອງ​ທ່ານ, ອ່ານ​ຄຳ​ແນະ​ນຳ​ໄປ​ຕາມ​ແຕ່​ລະ​ຂັ້ນ​ຕອນ ຫຼື ເບິ່ງວິ​ດີ​ໂອ​ແນະ​ນຳ​ຢູ່​ລຸ່ມ​ນີ້:",
    "read_instructions": "ອ່ານ​ຄຳ​ແນະ​ນຳ",
    "watch_video": "ເບິ່ງວິ​ດີ​ໂອ",
    "timer": "ເລີ່ມ​ຈັບ​ເວ​ລາ​ຫຼັງ​ຈາກ​ຄົນ​ສຳ​ລີເຍື່ອ​ເມືອກຢູ່​ໃນ​ຫຼອດ. ໂມງ​ຈັບ​ເວ​ລາ​ຈະ​ຢຸດ​ຫຼັງ​ຈາກ 1 ນາ​ທີ (ສຳລັບ​ໃຫ້​ທ່ານເອົາສຳ​ລີ​ເຍື່ອ​ເມືອກ​ອອກ​ຈາກ​ຫຼອດ). ກະລຸນາຈື່ໄວ້ວ່າ: ທ່ານ​ຕ້ອງ​ເລີ່ມ​ເຄື່ອງ​ຈັບ​ເວ​ລາ​ໃໝ່​ເພື່ອ​ເລີ່ມ​ຈັບ​ເວ​ລາ 10 ນາ​ທີສຳ​ລັບ​ການກວດ​ຂອງ​ທ່ານ​ໃຫ້​ຜົນ.",
    "start_timer": "ເລີ່ມ​ຈັບ​ເວ​ລາ (ເປັນ​ທາງ​ເລືອກ)",
    "restart_timer": "ຢຸດ ແລະ ປິດ​ເປີດໃໝ່",
    "resume": "ເລີ່ມຕໍ່",
    "one_minute_countdown": "1 ນາ​ທີ​ຜ່ານ​ໄປ! ເອົາ​ຕົວ​ຢ່າງ​ສຳ​ລີເຍື່ອ​ເມືອກອອກ​ຈາກ​ຫຼອດ ແລະ ເລີ່ມເຄື່ອງ​ຈັບ​ເວ​ລາ​ໃໝ່.",
    "take_photo": "ຖ່າຍ​ຮູບແຖບກວດຂອງ​ທ່ານ",
    "photo_warning": "ການ​ໃຫ້​ຮູບ​ແມ່ນ​ຈຳ​ເປັນ​ເພື່ອ​ສືບ​ຕໍ່.",
    "enter_results": "ປ້ອນຜົນກວດ",
    "choose_result": "ເລືອກ​ຕົວ​ເລືອກ​ທີ່​ສະ​ທ້ອນ​ໃຫ້​ເຫັນ​ແຖບກວດ​ຂອງ​ທ່ານ​ໄດ້​ດີ​ທີ່​ສຸດ. ຕ້ອງການຄວາມຊ່ອຍເຫຼືອບໍ? ຄ​ລິກ​ໃສ່​ບ່ອນ​ນີ້​ເພື່ອ​ເບິ່ງ​ຜົນກວດ​ຕົວ​ຢ່າງ.",
    "positive": "ບ​ວກ",
    "negative": "ລົບ",
    "unknown": "ບໍ່ຮູ້​ຈັກ",
    "clarify": "ກຳ​ລັງ​ຊີ້​ແຈງ​ຜົນ​ຂອງ​ທ່ານ",
    "strip_question": "ແຖບກວດຂອງ​ທ່ານ​ຄ້າຍ​ຄື​ອັນ​ໃດ​ຫຼາຍ​ທີ່​ສຸດ?",
    "no_lines": "ບໍ່​ມີ​ເສັ້ນ",
    "pink_line": "ເສັ້ນ​ສີ​ບົວ​ເທົ່າ​ນັ້ນ",
    "not_sure": "ຍັງ​ບໍ່​ແນ່​ໃຈ"
  },
  "ihealth": {
    "take_a_photo_of_your_test": "ປະ​ກົດ​ຜົນ​ເປັນ​ຮູບ",
    "retake_photo": "ຖ່າຍຮູບຄືນ​ໃໝ່",
    "capture_photo": "ບັນ​ທຶກຮູບ",
    "confirm_information_correct": "ຂ້ອຍ​ຢືນ​ຢັນ​ວ່າຂໍ້​ມູນຂ້າງ​ເທິງຖືກ​ຕ້ອງ.",
    "submit": "ສົ່ງ",
    "title": "ປ້ອນ​ຜົນໄດ້​ຮັບ​ສຳ​ລັບ {{name}}",
    "subtitle": "ທ່ານ​ບໍ່​ຈຳ​ເປັນ​ຕ້ອງ​ດາວ​ໂຫຼດ ຫຼື ນຳ​ໃຊ້​ແອັບ iHealth COVID-19.",
    "instruction_title": "ເບິ່ງ​ຄຳ​ແນະ​ນຳ iHealth",
    "instruction_guide": "ອ່ານ​ຄຳ​ແນະ​ນຳ",
    "video_guide": "ເບິ່ງວິດີໂອ",
    "add_photo": "ເພີ່ມຮູບ",
    "confirm_result": "ຢືນ​ຢັນ​ຜົນໄດ້ຮັບ",
    "result_warning": "ເລືອກ​ຜົນ​ໄດ້​ຮັບ​ເພື່ອ​ສົ່ງ",
    "confirm_warning": "ຢືນ​ຢັນຜົນ​ໄດ້​ຮັບ​ເພື່ອ​ສົ່ງ",
    "administered_at_label": "ທ່ານ​ໄດ້ເຮັດ​ການກວດ​ນີ້ເມື່ອ​ໃດ?",
    "instruction_subtitle": "ເບິ່ງ​ຄຳ​ແນະ​ນຳ​ທີ່​ມາ​ພ້ອມ​ກັບ​ການກວດ​ຂອງ​ທ່ານ ຫຼື ທົບ​ທວນ​ເບິ່ງ​ຄຳ​ແນະ​ນຳ​ຢູ່​ບ່ອນ​ນີ້."
  },
  "public_test_groups": {
    "title": "ເຄື່ອງກວດໂຄວິດ-19 (COVID-19)",
    "appointment_recoveries_title": "ກຳ​ລັງ​ກັບຄືນ​ມາບໍ?",
    "appointment_recoveries_button_text": "ຊອກຫາລິ້ງຂອງຂ້ອຍ",
    "search_test_group_title": "ເທື່ອ​ທຳ​ອິດ​ບໍ?",
    "search_test_group_button_text": "ລົງ​ທະ​ບຽນ​ດຽວ​ນີ້",
    "title_2": "ການລົງ​ທະ​ບຽນ ແລະ ການລາຍ​ງານ​ງ່າຍ",
    "step_one": "ການ​ລົງ​ທະ​ບຽນ​ບັນ​ຊີ",
    "step_two": "ເກັບ​ຕົວ​ຢ່າງ​ນ້ຳ​ລາຍ​ເພື່ອ​ສົ່ງ​ກັບ​ຄືນ",
    "step_three": "ເບິ່ງ​ຜົນ​ກວດ​ທາງ​ອອນ​ລາຍ",
    "title_3": "ລົງ​ທະ​ບຽນ​ເພື່ອ​ເປີດ​ນຳ​ໃຊ້​ເຄື່ອງກວດ​ຂອງ​ທ່ານ",
    "population_title": "ການລົງທະບຽນ",
    "population_button": "ເປີດນຳໃຊ້",
    "faq_subtitle": "ສຶກ​ສາເພີ່ມເຕີມ",
    "faq_title": "ຄໍາຖາມທີ່ຖາມເລື້ອຍໆ",
    "faq_1": "ຂ້ອຍ​ຈະ​ສົ່ງ​ເຄື່ອງກວດ​ຂອງ​ຂ້ອຍ​ແນວ​ໃດ?",
    "faq_1_footer": "ເບິ່ງ​ຄຳ​ແນະ​ນຳ​ຈວຍ​ຕອງ​ນ້ຳ​ລາຍ​ໂດຍ​ກົງ",
    "faq_2": "ຂ້ອຍ​ລົງ​ທະ​ບຽນ​ແລ້ວ, ແຕ່​ຂ້ອຍ​ລືມ​ບັນ​ຊີ​ຂອງ​ຂ້ອຍ.",
    "faq_3": "ຂ້ອຍ​ຈະ​ເປີດ​ນຳ​ໃຊ້ເຄື່ອງກວດ​ຂອງ​ຂ້ອຍ​ແນວ​ໃດ?",
    "faq_2_subtitle": "ເນື່ອງ​ຈາກ​ເປັນ​ຜູ້​ໃຊ້​ທີ່​ກຳ​ລັງ​ກັບ​ຄືນ​ມາ, ທ່ານ​ສາ​ມາດ​ຢືນ​ຢັນ​ລາຍ​ງານ​ຂອງ​ທ່ານ​ໂດຍ​ການ​ໃຊ້​ປຸ່ມ '**ຊອກຫາລິ້ງຂອງຂ້ອຍ**' ຢູ່​ຂ້າງ​ເທິງ.",
    "faq_3_subtitle": "ກະ​ລຸ​ນາ​ໄປ​ຜ່ານ​ຂະ​ບວນ​ການ​ບັນ​ຊີ Primary.Health (ສຸ​ຂະ​ພາບ​ຂັ້ນ​ຕົ້ນ) ດ້ວຍ​ການ​ໃຊ້​ຕົວ​ເລືອກ​ຢູ່​ລຸ່ມ​ນີ້:",
    "footer_text_1": "ຜະ​ລິດ​ຕະ​ພັນ​ນີ້​ບໍ່​ທັນ​ໄດ້​ຖືກກວດ​ກາ ຫຼື ອະ​ນຸ​ມັດ​ຈາກ FDA, ແຕ່​ໄດ້​ຮັບ​ອະ​ນຸ​ຍາດ​ໃຫ້​ນຳ​ໃຊ້​ສຳ​ລັບ​ການ​ໃຊ້​ສຸກ​ເສີນ​ໂດຍ FDA ພາຍ​ໃຕ້ EUA;",
    "footer_text_2": "ຜະ​ລິດ​ຕະ​ພັນ​ນີ້​ໄດ້​ຮັບ​ອະ​ນຸ​ຍາດ​ໃຫ້​ແຕ່​ສຳ​ລັບ​ການ​ເກັບ​ເອົາ ແລະ ການ​ຮັກ​ສາ​ຕົວ​ຢ່າງ​ນ້ຳ​ລາຍ​ເພື່ອ​ເປັນ​ການ​ຊ່ວຍ​ໃນ​ການກວດ​ອາ​ຊິດ​ນິວ​ຄ​ລິ​ອິກ​ຈາກ SARS-CoV-2, ບໍ່​ໃຊ້​ສຳ​ລັບ​ໄວ​ຣັ​ສ ຫຼື ເຊື້ອ​ພະ​ຍາດ​ອື່ນ;",
    "footer_text_3": "ການ​ນຳ​ໃຊ້​ຜະ​ລິດ​ຕະ​ພັນ​ນີ້​ສຸກ​ເສີນ​ໄດ້​ຮັບ​ອະ​ນຸ​ຍາດ​ໃຫ້​ພຽງ​ແຕ່​ສຳ​ລັບ​ໄລ​ຍະ​ເວ​ລາ​ຂອງ​ການ​ປະ​ກາດ​ວ່າ​ສະ​ພາບ​ການ​ນັ້ນ​ມີ​ຢູ່​ໃນ​ການ​ຕັດ​ສິນ​ໃຫ້​ການ​ອະ​ນຸ​ຍາດ​ການ​ນຳ​ໃຊ້​ອຸ​ປະ​ກອນ​ທາງ​ການ​ແພດ​ສຸກ​ເສີນ​ພາຍ​ໃຕ້​ຂໍ້​ທີ 564(b)(1) ຂອງ​ກົດ​ໝາຍ​ວ່າ​ດ້ວຍ​ອາ​ຫານ, ຢາ ແລະ ເຄື່ອງ​ສຳ​ອາງ​ຂອງ​ລັດ​ຖະ​ບານ​ກາງ (Section 564(b)(1) of the Federal Food, Drug and Cos- metic Act, 21 U.S.C. § 360bbb-3(b)(1)), ນອກ​ຈາກວ່າ​ການ​ປະ​ກາດ​ແຈ້ງ​ສິ້ນ​ສຸດ​ລົງ ຫຼື ການ​ອະ​ນຸ​ຍາດ​ຖືກ​ຍົກ​ເລີກ​ໄວ​ຂຶ້ນ.",
    "description": "ເພື່ອ​ຄວາມ​ສະ​ດວກ​ຂອງ​ທ່ານ, ທ່ານ​ສາ​ມາດ​ເປີດ​ນຳ​ໃຊ້​ເຄື່ອງກວດ​ຂອງ​ທ່ານ​ດ້ວຍ​ການ​ໃຊ້​ປຸ່ມ​ຢູ່​ລຸ່ມ​ນີ້:",
    "subtitle": "ເທື່ອ​ທຳ​ອິດ​ໃນ​ຂັ້ນ​ຕົ້ນ​ບໍ?",
    "subtitle_1": "ກ່ຽວ​ກັບເຄື່ອງກວດໂຄວິດ-19 (COVID-19) ຂອງ​ທ່ານ",
    "faq_1_1": "ເອົາ​ຫຼອດ​ຕົວ​ຢ່າງ​ທີ່​ປິດ​ແລ້ວ​ໃສ່​ເຂົ້າ​ໄປ​ໃນ​ຖົງ​ເກັບ​ຕົວ​ຢ່າງ​ຊີ​ວະ​ພາບ",
    "faq_1_2": "ເອົາ​ຖົງ​ເກັບ​ຕົວ​ຢ່າງ​ຊີ​ວະ​ພາບ​ໃສ່​ເຂົ້າ​ໄປ​ໃນ​ກ່ອງ​ທີ່​ຂຽນ​ໄວ້​ວ່າ SalivaDirect ທີ່​ມັນ​ມາ​ນຳ​ຊຸດ. ເອົາ​ກ່ອງທີ່​ຂຽນ​ວ່າ SalivaDirect ໃສ່​ເຂົ້າ​ໄປ​ໃນກ່ອງ FedEx UN 3373 Pak.",
    "faq_1_3": "ເອົາ​ເສັ້ນ​ຕິດຂອງ FedEx UN 3373 Pak ອອກ ແລະ ກົດ​ລົງ​ຢ່າງ​ໜັກ​ເພື່ອ​ປິດກ່ອງ Pak ໃຫ້​ແໜ້ນ.",
    "faq_1_4": "ກະ​ລຸ​ນາສົ່ງ​ຕົວ​ຢ່າງ​ຂອງ​ທ່ານ​ໃນ​ມື້​ດຽວ​ກັນ​ທີ່​ທ່ານ​ເກັບ​ມັນ ແລະ ກ່ອນ​ເວ​ລາ​ໄປຮັບ​ເອົາ​ເທື່ອ​ສຸດ​ທ້າຍ. ຢ່າ​ສົ່ງ​ຕົວ​ຢ່າງ​ຂອງ​ທ່ານ​ລົງ​ໃນ​ກ່ອງ​ຝາກ​ໃນ​ວັນ​ທ້າຍ​ອາ​ທິດ.",
    "faq_1_5": "ເຂົ້າ​ເບິ່ງ​ເວັບ​ໄຊ​ຂອງ FedEx ທີ່ **[fedex.com/labreturns](https://www.fedex.com/labreturns)** ເພື່ອ​ເບິ່ງ​ສະ​ຖານ​ທີ່​ຕັ້ງ​ກ່ອງ​ຝາກ ແລະ ເວ​ລາ​ໄປ​ຮັບ​ເອົາ.",
    "demo": {
      "title": "COVID-19 at-home test kit additional information & instructions",
      "online_instructions": "Online instructions",
      "download_instructions": "Download instruction sheet",
      "begin": "Begin",
      "instructions": {
        "welcome_title": "Welcome to the at-home COVID-19 testing portal.",
        "welcome_text_1": "Before you start testing, wash you hands or use hand sanitizer.",
        "welcome_text_2": "Make sure your hands are dry before starting.",
        "continue": "Continue",
        "back": "Back",
        "step_one_title": "Step 1: Check your test kit",
        "step_one_text_1": "Locate the kit components:",
        "step_one_text_2": "It is recommended gloves (not provided) also be used during testing.",
        "step_two_title": "Step 2: Place tube in the tube holder",
        "step_two_text_1": "Remove cap from one **TUBE** and place it in the **TUBE HOLDER.**",
        "step_two_text_2": "**NOTE:** Use of gloves is recommended.",
        "step_five_title": "Step 5: Remove swab from the tube",
        "step_five_text_1": "After **ONE MINUTE**, remove the swab from the **TUBE** by rubbing the swab head against the inside wall of the tube to squeeze out as much liquid as possible.",
        "step_five_text_2": "Dispose of swab in the trash.",
        "step_six_title": "Step 6: Open the test strip",
        "step_six_text_1": "Open the **TEST STRIP** pouch carefully at the slit and hold the **TEST STRIP** as indicated.",
        "step_seven_title": "Step 7: Place test strip in the tube",
        "step_seven_text_1": "Place the **TEST STRIP** into the **TUBE** with the arrows pointing down. Leave the strip in the **TUBE** for a **FULL TEN MINUTES**– do not handle or remove.",
        "step_eight_title": "Step 8: Remove test strip from the tube",
        "step_eight_text_1": "At **TEN MINUTES**, remove the **TEST STRIP** from the **TUBE**. Next, place the **TEST STRIP** on the outline in Steps 10-12. Ensure the **TEST STRIP** is on a flat surface in good lighting.",
        "step_eight_text_2": "**Note:** The test is intended to be read at 10 minutes. If the test is read before this or is read more than 5 minutes after the indicated read time, results may be inaccurate (false negative, false positive, or invalid) and the test should be repeated.",
        "step_nine_title": "Step 9: Check your results",
        "step_nine_text_1": "There are three type of results possible.",
        "step_nine_text_2": "Check for a Positive Result",
        "step_nine_text_3": "Check for a Negative Result",
        "step_nine_text_4": "Check for an Invalid Result",
        "step_ten_title": "Step 10: Remove test strip from the tube",
        "step_ten_text_1": "Place the **TEST STRIP** on the test strip outline below and compare with test result examples shown.",
        "step_three_title": "Step 3: Swab the nostrils",
        "step_three_text_1": "Remove the **SWAB** from its wrapper, being careful not to touch the **SWAB** head.",
        "step_three_text_2": "Gently insert the **SWAB** ½ to ¾ of an inch into the nostril, depending on the size of the person’s nose. Firmly rub the **SWAB** in a circular motion around the inside wall of EACH NOSTRIL at least 4 times.",
        "step_three_text_3": "Be sure to rub BOTH nostrils with the SAME SWAB.",
        "step_three_text_5": "**NOTE:** Failure to swab properly may cause false negative results.",
        "step_four_title": "Step 4: Place swab in the tube",
        "step_four_text_1": "Immediately place the **SWAB** into the liquid inside the **TUBE**, and ensure it is touching the bottom. Stir 3-4 times.",
        "step_four_text_2": "Leave the swab in the solution for **ONE MINUTE**.",
        "step_four_text_3": "**NOTE**: If the swab is in the solution for more than 10-minutes it should not be used.",
        "step_three_text_4": "**NOTE:** If you are swabbing others, please wear a face mask. With children, you may not need to insert the swab as far into the nostril. For very young children, you may need another person to steady the child’s head while swabbing."
      },
      "choose_language": "Choose your language"
    }
  },
  "self_resulting": {
    "report_result": "ບົດ​ລາຍ​ງານ​ຜົນ​ໄດ້​ຮັບ",
    "report_result_subtitle": "ຖ້າ​ຫາກ​ອົງ​ກອນ​ຂອງ​ທ່ານ​ສະ​ເໜີ​ການກວດ​ດ້ວຍ​ຕົນ​ເອງ ແລະ ທ່ານ​ພ້​ອມ​ທີ່​ຈະ​ລາຍ​ງານ​ຜົນ​ກວດ, ກະ​ລຸ​ນາ​ຄ​ລິກ​ປຸ່ມ​ດ້ານຫຼັງ.",
    "need_help": "ຕ້ອງການຄວາມຊ່ວຍເຫຼືອບໍ?",
    "assistance": "ຖ້າ​ຫາກ​ທ່ານ​ຕ້ອງ​ການ​ຄວາມ​ຊ່ວຍ​ເຫຼືອ ຫຼື ປ່ຽນ​ຂໍ້​ມູນ​ຕິດ​ຕໍ່​ຂອງ​ທ່ານ, ກ​ະ​ລຸ​ນາ​ຕິດ​ຕໍ່​ຫາ​ສູນ​ໃຫ້​ການ​ຊ່ວຍ​ເຫຼືອ.",
    "contact_support": "ຕິດ​ຕໍ່​ຝ່າຍ​ໃຫ້​ການ​ຊ່ວຍ​ເຫຼືອ",
    "save_link": "ບັນ​ທຶກ​ລິ້ງນີ້​ເພື່ອ​ລາຍ​ງານ​ຜົນ​ກວດ​ໃນ​ພາຍຫຼັງ.",
    "send_via_email": "ສົ່ງລິ້ງ​ຜ່ານອີເມວ",
    "send_via_text": "ສົ່ງລິ້ງ​ຜ່ານຂໍ້​ຄວາມ SMS",
    "copy_link": "ອັດ​ສຳ​ເນົາ​ລິ້ງ​ເຊື່ອມ​ໂຍງ",
    "resend_email": "ສົ່ງ​ອີ​ເມວຢືນ​ຢັນ​ຄືນ​ໃໝ່",
    "phone_verified": "ເບີ​ໂທ​ລະ​ສັບ​ໄດ້​ຮັບ​ການ​ຢືນ​ຢັນ​ແລ້ວ",
    "add_vaccination_status": "ເພີ່ມ/ອັບ​ເດດ​ສະ​ຖາ​ນະ​ການ​ສັກ​ວັກ​ຊິນໂຄວິດ-19",
    "terms_of_service": "ຂໍ້​ກຳ​ນົດການ​ບໍ​ລິ​ການ",
    "verify_contact_information": "ຢຶນ​ຢັນ​ຂໍ້​ມູນ​ຕິດ​ຕໍ່​ຂອງ​ທ່ານ​ເພື່ອ​ທີ່​ທ່ານ​ຈະ​ສາ​ມາດ​ໄດ້​ຮັບ​ຜົນ​ກວດ ແລະ ການ​ຕິດ​ຕໍ່​ພົວ​ພັນ. ຖ້າ​ຫາກ​ທ່ານ​ຕ້ອງ​ການ​ຄວາມ​ຊ່ວຍ​ເຫຼືອ ຫຼື ປ່ຽນ​ຂໍ້​ມູນ​ຕິດ​ຕໍ່​ຂອງ​ທ່ານ, ກ​ະ​ລຸ​ນາ​ຕິດ​ຕໍ່​ຫາ​ສູນ​ໃຫ້​ການ​ຊ່ວຍ​ເຫຼືອຂອງ​ພວກ​ເຮົາ.",
    "resend_text": "ສົ່ງ​ຂໍ້​ຄວາມຢືນ​ຢັນ​ຄືນ​ໃໝ່",
    "loading": "ກຳລັງ​ໂຫຼດ",
    "add_to_account": "ເພີ່ມໃສ່ບັນຊີ",
    "hi": "ສະ​ບາຍ​ດີ",
    "email_verified": "ອີ​ເມວ​ໄດ້​ຮັບ​ການ​ຢືນ​ຢັນ​ແລ້ວ",
    "go_to_my_account": "ໄປ​ຫາ​ບັນ​ຊີ​ຂອງຂ້ອຍ",
    "activate_kit": "ເປີດ​ນຳ​ໃຊ້​ຊຸດ​ເຄື່ອງ​ມືກວດ",
    "report_custom_result": "ລາຍ​ງານຜົນ​ໄດ້​ຮັບ: {{test}}",
    "activate_custom_test": "ເປີດ​ນຳ​ໃຊ້ການກວດ {{test}}",
    "update_vaccination_status": "ອັບເດດສະຖານະການສັກຢາ",
    "order_a_test_kit": "ສັ່ງຊຸດ​ເຄື່ອງ​ມືກວດ"
  },
  "enter_information": {
    "contact_info": "ປ້ອນຂໍ້ມູນຕິດຕໍ່ຂອງທ່ານ",
    "label": "ໂທ​ລະ​ສັບ ຫຼື ອີ​ເມວ",
    "invalid": "ນີ້ບໍ່​ແມ່ນອີ​ເມວ ຫຼື ເບີ​ໂທ​ລະ​ສັບ​ທີ່​ຖືກ​ຕ້ອງ.",
    "send": "ພວກ​ເຮົາ​ຈະ​ສົ່ງ​ລະ​ຫັດ​ໄປ​ທີ່ໂທ​ລະ​ສັບ ຫຼື ອີ​ເມວ​ຂອງ​ທ່ານ",
    "legal": "ໂດຍ​ການ​ປ້ອນ​ເບີ​ໂທ​ລະ​ສັບ ຫຼື ອີ​ເມວ​ຂອງ​ທ່ານ, ທ່ານ​ເຫັນ​ດີ​ຕໍ່​ກັບ **[ຂໍ້​ກຳ​ນົດ​ການ​ບໍ​ລິ​ການ](https://primary.health/terms-of-service/)** ແລະ **[ນະ​ໂຍ​ບາຍ​ຄວາມ​ເປັນ​ສ່ວນ​ຕົວ](https://primary.health/privacy-policy/)** ຂອງ Primary Health.",
    "subtitle": "ພວກເຮົາຈະສົ່ງຂໍ້ຄວາມ ຫຼື ອີເມວເພື່ອຊ່ວຍທ່ານຊອກຫາບັນທຶກຂອງທ່ານ ຫຼື ເຊື່ອມຕໍ່ເພື່ອລາຍງານຜົນກວດ!",
    "find_link": "ລົງທະບຽນແລ້ວບໍ? ຊອກຫາລິ້ງຂອງທ່ານ",
    "not_registered": "ບໍ່ໄດ້ລົງທະບຽນບໍ? ຊອກ​ຫາ​ເວັບ​ໄຊ​ຂອງ​ທ່ານ",
    "search_by_keyword": "ຊອກ​ຫາ​ຕາມ​ຊື່, ຄຳ​ສຳ​ຄັນ, ເມືອງ, ລະ​ຫັດ​ໄປ​ສະ​ນີ ຫຼື ລະ​ຫັດ​ອົງ​ການ​ຈັດ​ຕັ້ງ!",
    "search": "ຊອກ​ຫາ",
    "register_here": "ລົງທະບຽນທີ່ນີ້"
  },
  "contact_support": {
    "invalid": "ຂໍ້​ມູນບໍ່​ຖືກ​ຕ້ອງ",
    "invalid_explanation": "ທ່ານ​ຫາກໍ່​ຂໍ​ໃຫ້​ສົ່ງ​ລິ້ງການເຂົ້າ​ສູ່​ລະ​ບົບ Primary.Health. ເຖິງ​ແນວ​ໃດ​ກໍ່​ຕາມ,",
    "never_registered": "ບໍ່​ເຄີຍ​ລົງ​ທະ​ບຽນ​ມາ​ກ່ອນ​ບໍ? ກະ​ລຸ​ນາ​ຕິດ​ຕໍ່​ຫາ​ຜູ້​ໃຫ້​ການກວດ​ສອບ​ຂອງ​ທ່ານ​ສຳ​ລັບ​ລິ້ງ​ການ​ລົງ​ທະ​ບຽນ ຫຼື ຊອກ​ຫາ​ເວັບ​ໄຊ​ຂອງ​ທ່ານ​ຢູ່​ລຸ່ມ​ນີ້.",
    "get_help": "ທ່ານ​ສາ​ມາດ​ໄດ້​ຮັບ​ຄວາມ​ຊ່ວຍ​ເຫຼືອ​ໃນ​ການ​ຈັບ​ຄູ່​ບັນ​ທຶກ​ຂອງ​ທ່ານ​ກັບ​ຂໍ້​ມູນ​ຕິດ​ຕໍ່​ຂອງ​ທ່ານ​",
    "get_help_calling": "ຫຼື ໂດຍ​ການ​ໂທ",
    "invalid_explanation_bold": "ຂໍ້ມູນທີ່ທ່ານໃຫ້ໄປບໍ່ຖືກຕ້ອງ.",
    "get_help_label": "ຂໍ​ຄວາມ​ຊ່ວຍ​ເຫຼືອ",
    "mobile": {
      "invalid_explanation": "ບໍ່ພົບຜູ້ໃຊ້ທີ່ສາມາດເພີ່ມໃສ່ບັນຊີຂອງທ່ານໄດ້.",
      "get_help": "ຖ້າທ່ານມີຄໍາຖາມເພີ່ມຕື່ມ, ກະລຸນາຕິດຕໍ່",
      "reason": "ບໍ່ແມ່ນຜູ້ໃຊ້ Primary.Health ທຸກຄົນ ຈະມີສິດໄດ້ຮັບຄຸນສົມບັດຂອງບັນຊີໃໝ່ຂອງພວກເຮົາ. ເມື່ອທ່ານມີສິດໄດ້ຮັບ, ທ່ານຈະໄດ້ຮັບຄຳເຊີນຈາກ Primary.Health ຫຼື ຈາກຜູ້ດູແລກຸ່ມຂອງທ່ານ."
    },
    "zen_name": "ຊື່ຜູ້ຕິດຕໍ່",
    "ticket_type": "ສິ່ງນີ້ກ່ຽວກັບກັບຫຍັງ",
    "appointment_access_code": "ລະຫັດຢືນຢັນ (ຖ້າຮູ້)",
    "zen_desc": "ຂໍ້ຄວາມ",
    "contact_us": "ຕິດຕໍ່ພວກເຮົາ",
    "need_assistance": "ຖ້າ​ຫາກ​ທ່ານ​ຕ້ອງ​ການ​ຄວາມ​ຊ່ວຍ​ເຫຼືອ ຫຼື ປ່ຽນ​ຂໍ້​ມູນ​ຕິດ​ຕໍ່​ຂອງ​ທ່ານ, ກ​ະ​ລຸ​ນາ​ຕິດ​ຕໍ່​ຫາ​ຝ່າຍ​ຊ່ວຍ​ເຫຼືອ."
  },
  "show_appointments": {
    "welcome": "ຊອກ​ຫາໂປຣຟາຍຂອງ​ທ່ານ",
    "welcome_subtitle_1": "ສະແດງຜົນກວດຢູ່ລຸ່ມນີ້ສຳລັບ",
    "added_to_account": "ເພີ່ມໃສ່ບັນຊີແລ້ວ",
    "error": "ຜູ້ເຂົ້າຮ່ວມຄົນນີ້ ອາດຈະມີບັນຊີເປັນທີ່ຮຽບຮ້ອຍແລ້ວ",
    "welcome_subtitle_2": "ບໍ່ເຫັນຊື່ຢູ່ລຸ່ມ​ນີ້ບໍ?",
    "welcome_not_logged_in": "ການຊອກຫາລິ້ງໜ້າຫຼັກ",
    "welcome_logged_in": "ເລືອກກຸ່ມຂອງ​ທ່ານ",
    "welcome_subtitle_1_logged_in": "ເພື່ອເຮັດການເພີ່ມສະມາຊິກໃຫ້ສຳເລັດ, ເລືອກຜູ້ໃຊ້ ແລະ ກຸ່ມ. ສະມາຊິກຈະຖືກມອບ​ໝາຍ​ໃຫ້ໃນໜຶ່ງບັນຊີ.",
    "welcome_subtitle_2_logged_in": "ຖ້າຫາກທ່ານບໍ່ເຫັນສະມາຊິກຂອງທ່ານຢູ່​ລາຍ​ຊື່ຂ້າງເທິງ, ທ່ານສາມາດ",
    "welcome_subtitle_1_not_logged_in": "ເລືອກຜູ້ທີ່ຕ້ອງການຊອກຫາເວັບ​ໄຊທີ່ຂອງພວກເຂົາ ແລະ ເພີ່ມ​ສະ​ມາ​ຊິກໃໝ່. ບໍ່​ພົບ​ສະມາຊິກບໍ?",
    "welcome_subtitle_3_logged_in": "ລອງວິ​ທີ​ການຕິດຕໍ່ອື່ນ",
    "welcome_subtitle_4_logged_in": "ຫຼື ຕິດຕໍ່ຫາພວກເຮົາທີ່",
    "welcome_subtitle_2_not_logged_in": "ອັບເດດການຄົ້ນຫາ.",
    "welcome_subtitle_3": "ອັບເດດການຄົ້ນຫາຂອງທ່ານ"
  },
  "enter_code": {
    "code": "ລະ​ຫັດ​ແມ່ນ​ຫຍັງ?",
    "enter_code": "ປ້ອນລະ​ຫັດທີ່​ໄດ້​ສົ່ງ​ຫາ",
    "incorrect_code": "ທ່ານ​ໄດ້ປ້ອນ​ລະ​ຫັດ​ທີ່​ບໍ່​ຖືກ​ຕ້ອງ​ເຂົ້າ​ໄປ.",
    "verify": "ກວດ​ສອບບັນຊີ",
    "verified": "ກວດສອບແລ້ວ",
    "verify_account_information": "ກວດ​ສອບບັນ​ຊີ​ຂອງ​ທ່ານ"
  },
  "general_self_checkout": {
    "choose_test": "ທ່ານ​ໄດ້​ໄປ​ກວດຫຍັງ​ມາ​ແດ່?",
    "photo_required": "ຕ້ອງ​ການຮູບ​ຂອງ​ຜົນກວດ",
    "find_your_test": "ຊອກ​ຫາການກວດຂອງ​ທ່ານ"
  },
  "login": {
    "create_account": "ສ້າງບັນ​ຊີ",
    "create_account_help": "ການ​ສ້າງ​ບັນ​ຊີ​ບໍ່​ຄື​ກັນ​ກັບ​ການ​ລົງ​ທະ​ບຽນ​ສຳ​ລັບ​ການກວດ ຫຼື ການ​ສັກ​ວັກ​ຊີນ. ເມື່ອ​ທ່ານ​ໄດ້​ລົງ​ທະ​ບຽນ​ແລ້ວ",
    "create_account_help_schedule": "ກຳນົດເວລາການນັດໝາຍ",
    "create_account_help_record": "ບັນ​ທຶກ​ການກວດ​ຢູ່​ເຮືອນ",
    "create_account_help_match": "ຈັດ​ການ​ເລື່ອງ​ການ​ສັກ​ຢາ ແລະ ບັນ​ທຶກ​ການກວດ.",
    "continue": "ສືບ​ຕໍ່​ເປັນ​ແຂກ",
    "already": "ມີ​ບັນ​ຊີ​ຮຽບ​ຮ້ອຍ​ແລ້ວບໍ? ຄ​ລິກ",
    "login": "ເພື່ອເຂົ້າ​ສູ່​ລະ​ບົບ.",
    "or_sign_in": "ຫຼື ເຂົ້າ​ສູ່​ລະ​ບົບດ້ວຍ:",
    "no_account": "ບໍ່ມີ​ບັນ​ຊີ​ບໍ? ຄ​ລິກ",
    "signup": "ເພື່ອ​ລົງ​ທະ​ບຽນ.",
    "here": "ຢູ່ບ່ອນ​ນີ້",
    "email_address": "ທີ່​ຢູ່ອີ​ເມວ:",
    "password": "ລະຫັດຜ່ານ:",
    "complete_registration": "ລົງ​ທະ​ບຽນໃຫ້​ສຳ​ເລັດ",
    "last_covid_test": "ຜົນ​ກວດພະ​ຍາ​ດ​ໂຄວິດ-19 ຄັ້ງ​ຫຼ້າ​ສຸດ",
    "no_record": "ບໍ່ມີບັນທຶກ",
    "viewing_information": "ກຳ​ລັງ​ເບິ່ງຂໍ້​ມູນ​ຂອງ {{first_name}}",
    "download": "ດາວ​ໂຫຼດ",
    "not_registered": "ເບິ່ງ​ຄື​ວ່າ​ທ່ານ​ຍັງ​ບໍ່​ໄດ້​ລົງ​ທະ​ບຽນເຂົ້າ​ກຸ່ມ​ໃດໆ",
    "please_follow_link": "ກະ​ລຸ​ນາ​ຕິດ​ຕາມ​ລິ້ງ​ກາ​ນ​ເຊື້ອ​ເຊີນ​ຈາກ​ອີ​ເມວ​ຂອງ​ທ່ານ​ເພື່ອ​ຈັດ​ການ​ນັດ​ໝາຍ ແລະ ເຂົ້າ​ເຖິງ​ຂໍ້​ມູນ​ຂອງ​ທ່ານດ້ວຍ Primary.Health.",
    "log_results": "ບັນ​ທຶກຜົນ​ກວດຂອງ​ຂ້າ​ພະເຈົ້າ:",
    "book_appointment": "ກຳນົດເວລາການນັດໝາຍ",
    "no_new_appointments": "ຕອນນີ້​ຍັງ​ບໍ່​ໄດ້​ຮັບ​ການ​ນັດ​ໝາຍ​ໃໝ໋",
    "upload_vaccinations": "ອັບ​ໂຫຼດ​ການ​ສັກ​ຢາ​ວັກ​ຊິນ",
    "vaccination": "ການ​ສັກຢາ​ວັກ​ຊິນ",
    "new_appointment": "ການນັດໝາຍໃໝ່",
    "log_otc_results": "ບັນ​ທຶກ​ຜົນ​ກວດ OTC",
    "no_household_members": "ທ່ານ​ຍັງ​ບໍ່​ໄດ້​ເປັນ​ສະ​ມາ​ຊິກ​ຄົວ​ເຮືອນ​ເທື່ອ.",
    "no_upcoming": "ບໍ່​ມີການນັດໝາຍ​ທີ່​ຈະ​ເກີດ​ຂຶ້ນ",
    "update_vaccination": "ອັບເດດ​ການ​ສັກ​ຢາ​ວັກ​ຊິນ",
    "upload_vaccination": "ອັບ​ໂຫຼດ​ການ​ສັກ​ຢາ​ວັກ​ຊິນ",
    "title": "ຍິນ​ດີ​ຕ້ອນ​ຮັບ​ສູ່ Primary.Health",
    "sub_title": "Before registering please create an account.",
    "p1": "ການ​ສ້າງ​ບັນ​ຊີ​ອະ​ນຸ​ຍາດ​ໃຫ້​ທ່ານ:",
    "p2": "ກຳນົດເວລາການນັດໝາຍໃນ​ອະ​ນາ​ຄົດ",
    "p3": "ບັນ​ທຶກ​ການກວດພະ​ຍາດ​ໂຄວິດ-19 ຢູ່​ເຮືອນ",
    "p4": "ຈັດ​ການ​ເລື່ອງ​ການ​ສັກ​ຢາວັກ​ຊິນຜູ້​ຂຶ້ນ​ກັບ ແລະ ບັນ​ທຶກ​ການກວດ.",
    "p5": "ຂັ້ນ​ຕອນ​ການ​ເຂົ້າ​ສູ່​ລະ​ບົບ​ທີ່ງ່າຍ​ດາຍຈາກ my.primary.health",
    "no_vaccination_record": "ຍັງບໍ່ໄດ້ເພີ່ມວັກຊີນໃດໆເທື່ອ",
    "vaccination_title_card": "ສະຖານະການສັກວັກຊີນໂຄວິດ-19",
    "account_and_settings": "ບັນຊີ ແລະ ການຕັ້ງຄ່າ",
    "delete_account": "ລຶບບັນຊີ",
    "language": "ພາສາ",
    "language_current": "ພາສາປັດຈຸບັນ: {{language}}",
    "log_out": "ອອກຈາກລະບົບ",
    "delete_account_title": "ທ່ານແນ່ໃຈບໍວ່າທ່ານຕ້ອງການລຶບບັນຊີ?",
    "new_encounter": "ການພົບກັນໃໝ່ຂອງ {{first_name}}",
    "new_encounter_subtitle": "ການ​ພົບ​ປະ​ຄັ້ງ​ໃໝ່​ນີ້​ແມ່ນເໝາະສົມກັບ​ກຸ່ມ​ໃດ?",
    "no_programs": "ມັນເບິ່ງຄືວ່າທ່ານຍັງບໍ່ໄດ້ເຂົ້າຮ່ວມໂປຣແກຣມໃດໆເທື່ອ",
    "edit_members": "ແກ້ໄຂສະມາຊິກ",
    "edit_members_subtitle": "ເລືອກກະດານຂ່າວອັນໃດທີ່ທ່ານຕ້ອງການໄປເບິ່ງ ຫຼື ເພື່ອເພີ່ມສະມາຊິກເຂົ້າໃນບັນຊີຂອງທ່ານ",
    "add_members": "ເພີ່ມສະມາຊິກ",
    "delete_member": "ລຶບບັນຊີຜູ້ໃຊ້ {{full_name}} ບໍ?",
    "delete_member_subtitle": "ການລຶບຜູ້ໃຊ້ອອກຈະ​ລຶບຂໍ້ມູນທັງໝົດທີ່ກ່ຽວຂ້ອງກັບບັນຊີນີ້.",
    "select_member": "ການເລືອກສະມາຊິກ",
    "edit_appointment": "ແກ້ໄຂການນັດໝາຍ",
    "route": {
      "me": "ກະດານຂ່າວ",
      "history": "ປະຫວັດ",
      "programs": "ເພີ່ມການທົດສອບ",
      "contact_us": "ຊ່ວຍເຫຼືອ",
      "choose_member": "ສະມາຊິກ",
      "settings": "ການຕັ້ງຄ່າ",
      "help": "ຊ່ວຍເຫຼືອ"
    },
    "user_dashboard": "ກະດານຂ່າວຂອງ {{first_name}}",
    "view_full_results": "ເບິ່ງໃນຜອດ​ໂທ​ລຜົນໄດ້ຮັບ",
    "required": "ຈຳ​ເປັນ​ຕ້ອງ​ມີ, ຖ້າ​ທ່ານ​ກຳ​ລັງ​ໃຊ້​ແອັບ​ຕົ້ນ​ຕໍ.",
    "no_email": "ຂ້ອຍ​ບໍ່​ມີທີ່​ຢູ່​ອີ​ເມວ"
  },
  "vaccination_status": {
    "not_vaccinated": "ຍັງ​ບໍ່​ໄດ້​ສັກ​ຢາວັກ​ຊິນ.",
    "fully_vaccinated": "ສັກ​ຢາວັກ​ຊິນຄົບ​ແລ້ວ",
    "partially_vaccinated": "ສັກ​ຢາວັກ​ຊິນບາງ​ສ່ວນແລ້ວ.",
    "vaccination_records": "ປະຫວັດການສັກວັກຊີນ",
    "title": "ສະ​ຖາ​ນະ​ການ​ສັກ​ວັກ​ຊີນໂຄວິດ-19 ຂອງ​ທ່ານ​ເປັນ​ແນວ​ໃດ?",
    "definition_title": "ຄຳ​ນິ​ຍາມສະ​ຖາ​ນະ​ການ​ສັກ​ວັກ​ຊີນ",
    "definition_cdc": "ຄຳ​ນິ​ຍາມສະ​ຖາ​ນະ​ການ​ສັກ​ວັກ​ຊີນຂອງ CDC",
    "definition_osha": "ຄຳ​ນິ​ຍາມສະ​ຖາ​ນະ​ການ​ສັກ​ວັກ​ຊີນຂອງ OSHA",
    "definition": {
      "fully_vaccinated": "2 ອາ​ທິດ​ຫຼັງ​ຈາກ​ເຂັມ​ສຸດ​ທ້າຍ​ໃນ​ຊຸດ 2 ເຂັມ​ຂອງ Pfizer-BioNTech ຫຼື Moderna ຫຼື 1 ເຂັມ​ຂອງວັກ​ຊີນ Johnson & Johnson's Janssen.",
      "partially_vaccinated": "ໄດ້​ສັກ​ພຽງ​ແຕ່ 1 ໃນ 2 ເຂັມ​ຢູ່​ໃນ​ຊຸດ​ຕົ້ນ​ຕໍ ຫຼື ໜ້ອຍກວ່າ 2 ອາ​ທິດ​ຫຼັງ​ຈາກ​ໄດ້ສັກ​ເຂັມ​ສຸດ​ທ້າຍ​ໃນ​ຊຸດ 2 ເຂັມ ຫຼື ເຂັມ​ຂອງ Johnson & Johnson's Janssen.",
      "not_vaccinated": "ບໍ່ໄດ້​ສັກວັກ​ຊີນໂຄວິດ-19 (COVID-19).",
      "fully_vaccinated_with_one_booster": "ໄດ້​ຮັບ​ວັກ​ຊີນ​ເຂັມ​ກະ​ຕຸ້ນ​ຂອງ Pfizer-BioNTech ຫຼື Moderna ຫຼັງ​ຈາກ​ຊຸດ​ເຕັມ ຫຼື 1 ເຂັມ​ຂອງວັກ​ຊີນ Johnson & Johnson's Janssen.",
      "fully_vaccinated_with_two_boosters": "ໄດ້​ຮັບ​ວັກ​ຊີນ​ເຂັມ​ກະ​ຕຸ້ນທີ​ສອງຂອງ Pfizer-BioNTech ຫຼື Moderna ຫຼັງ​ຈາກ​ຊຸດ​ເຕັມ ຫຼື ເຂັມ​ຂອງວັກ​ຊີນ Johnson & Johnson's Janssen ແລະ ເຂັມ​ກະ​ຕຸ້ນ."
    },
    "label": {
      "fully_vaccinated": "ສັກ​ຢາວັກ​ຊິນຄົບ​ແລ້ວ",
      "partially_vaccinated": "ສັກ​ຢາວັກ​ຊິນບາງ​ສ່ວນແລ້ວ.",
      "not_vaccinated": "ຍັງ​ບໍ່​ໄດ້​ສັກ​ຢາວັກ​ຊິນ.",
      "fully_vaccinated_with_one_booster": "ສັກ​ວັກ​ຊິນ​ຄົບ + ເຂັມ​ກະ​ຕຸ້ນ",
      "fully_vaccinated_with_two_boosters": "ສັກ​ວັກ​ຊິນ​ຄົບ + 2 ເຂັມ​ກະ​ຕຸ້ນ",
      "prefer_not_to_answer": "ເລືອກ​ທີ່​ຈະ​ບໍ່​ຕອບ"
    },
    "progress_bar_title": "ປະ​ຫວັດ​ການສັກວັກ​ຊີນ COVID-19",
    "upload_record_card": "ອັບ​ໂຫຼດ​ບັດ​ບັນ​ທຶກ​ການ​ສັກ​ວັກ​ຊີນ COVID-19",
    "show_example": "ສະ​ແດງ​ຕົວ​ຢ່າງ",
    "hide_example": "ເຊື່ອງ​ຕົວ​ຢ່າງ",
    "take_photo": "ຄ​ລິກເພື່ອ​ຖ່າຍຮູບ",
    "or": "ຫຼື",
    "upload_image_or_pdf_instead": "ອັບ​ໂຫຼດ​ຮູບ/PDF ແທນ",
    "select_doses_received": "ເລືອກ​ຈຳ​ນວນ​ເຂັມ​ວັກ​ຊີນ COVID-19 ທີ່​ທ່ານ​ໄດ້​ສັກ​ທຸກ​ເຂັມ.",
    "first_dose": "ເຂັມ​ທຳ​ອິດ",
    "second_dose": "ເຂັມ​ທີ​ສອງ",
    "first_booster_dose": "ເຂັມ​ກະ​ຕຸ້ນ​ທີ​ໜຶ່ງ",
    "second_booster_dose": "ເຂັມ​ກະ​ຕຸ້ນ​ທີສອງ",
    "additional_dose": "ເຂັມ​ເພີ່ມ​ເຕີມ",
    "first_dose_manufacturer": "ໃຜ​ເປັນ​ຜູ້​ຜະ​ລິດ​ວັກ​ຊີນ COVID-19 ສຳ​ລັບ​ເຂັມ​ທຳ​ອິດ​ຂອງ​ທ່ານ?",
    "first_dose_date": "ທ່ານ​ສັກ​ເຂັມ​ທຳ​ອິດ​ວັນ​ທີ​ໃດ?",
    "second_dose_manufacturer": "ໃຜ​ເປັນ​ຜູ້​ຜະ​ລິດ​ວັກ​ຊີນ COVID-19 ສຳ​ລັບ​ເຂັມທີ​ສອງ​ຂອງ​ທ່ານ?",
    "second_dose_date": "ທ່ານ​ສັກ​ເຂັມ​ທີ​ສອງ​ວັນ​ທີ​ໃດ?",
    "first_booster_dose_manufacturer": "ໃຜ​ເປັນ​ຜູ້​ຜະ​ລິດ​ວັກ​ຊີນ COVID-19 ສຳ​ລັບ​ເຂັມ​ທີ​ສອງ​ຂອງ​ທ່ານ?",
    "first_booster_dose_date": "ທ່ານ​ສັກ​ເຂັມກະ​ຕຸ້ນ​ວັນ​ທີ​ໃດ?",
    "second_booster_dose_manufacturer": "ໃຜ​ເປັນ​ຜູ້​ຜະ​ລິດ​ວັກ​ຊີນ COVID-19 ສຳ​ລັບ​ເຂັມກະ​ຕຸ້ນທີ​ສອງ​ຂອງ​ທ່ານ?",
    "second_booster_dose_date": "ທ່ານ​ສັກ​ເຂັມກະ​ຕຸ້ນ​ທີ​ສອງ​ວັນ​ທີ​ໃດ?",
    "additional_dose_manufacturer": "ໃຜ​ເປັນ​ຜູ້​ຜະ​ລິດ​ວັກ​ຊີນ COVID-19 ສຳ​ລັບ​ເຂັມເພີ່ມ​ເຕີມ​ຂອງ​ທ່ານ?",
    "additional_dose_date": "ທ່ານ​ສັກ​ເຂັມເພີ່ມ​ເຕີມ​ວັນ​ທີ​ໃດ?",
    "completed_one": "ສຳ​ເລັດ​ແລ້ວ",
    "completed_two": "ຂໍ​ຂອບ​ໃຈ​ສຳ​ລັບ​ການ​ອັບ​ເດດ​ສະ​ຖາ​ນະ​ການ​ສັກ​ວັກ​ຊີນ​ຂອງ​ທ່ານ!",
    "progress_bar_complete": "ສຳ​ເລັດ",
    "upload_image": "ອັບ​ໂຫຼດ​ຮູບພາບ",
    "retake_photo": "ຖ່າຍ​ຮູບຄືນໃໝ່​",
    "other": "ອື່ນໆ",
    "remove_first_dose": "ລຶບ​ປະ​ຫວັດ​ເຂັມ​ທຳ​ອິດ​ອອກ",
    "remove_second_dose": "ລຶບ​ປະ​ຫວັດ​ເຂັມ​ທີ​ສອງ​ອອກ",
    "remove_first_booster_dose": "ລຶບ​ປະ​ຫວັດ​ເຂັມ​ກະ​ຕຸ້ນທຳ​ອິດ​ອອກ",
    "remove_second_booster_dose": "ລຶບ​ປະ​ຫວັດ​ເຂັມກະ​ຕຸ້ນ​ທີ​ສອງ​ອອກ",
    "remove_additional_dose": "ລຶບ​ປະ​ຫວັດ​ເຂັມເພີ່ມ​ເຕີມ​ອອກ",
    "exemption": "ທ່ານ​ມີການ​ຍົກ​ເວັ້ນ​ບໍ?",
    "exempt": "ຂ້ອຍ​ໄດ້​ຮັບ​ການ​ຍົກ​ເວັ້ນ",
    "not_exempt": "ຂ້ອຍ​ບໍ່​ໄດ້​ຮັບ​ການ​ຍົກ​ເວັ້ນ",
    "enter_exemption": "ປ້ອນ​ການ​ຍົກ​ເວັ້ນ",
    "upload_exemption_documentation": "ອັບ​ໂຫຼດ​ເອ​ກະ​ສານ​ສຳ​ລັບ​ການ​ຍົກ​ເວັ້ນ​ຂອງ​ທ່ານ",
    "remove_dose": "ເອົາ​ເຂັມ​ທີ່​ສັກ​ອອກ",
    "continue": "ສືບ​ຕໍ່",
    "enter_credentials": "ກະ​ລຸ​ນາປ້ອນການ​ຢັ້ງ​ຢືນຂອງ​ທ່ານ",
    "incorrect_credentials": "ການຢັ້ງຢືນບໍ່ຖືກຕ້ອງ, ກະ​ລຸ​ນາ​ລອງ​ໃໝ່​ອີກ.",
    "add_photo": "ເພີ່ມຮູບ"
  },
  "mailers": {
    "appointment_mailer": {
      "payment_pending": "ສະ​ບາຍ​ດີ, {{name}}, ພວກ​ເຮົາ​ສັງ​ເກດເຫັນ​ວ່າ​ທ່ານ​ຍັງ​ບໍ່​ໄດ້​ເຮັດການ​ກຳ​ນົດ​ການນັດ​ໝາຍ {{group_name}} ຂອງ​ທ່ານ​ກັບ {{org_name}} ສຳ​ເລັດ​ເທື່ອ. ເພື່ອ​ສືບ​ຕໍ່​ການ​ນັດ​ໝາຍ​ຂອງ​ທ່ານ, ທ່ານ​ຕ້ອ​ງ​ເຮັດຂັ້ນ​ຕອນ​ການ​ຊຳ​ລະ​ເງິນໃຫ້​ສຳ​ເລັດ. ກະ​ລຸ​ນາ​ຄ​ລິກ​ລິ້ງນີ້​ເພື່ອ​ເຮັດຂະ​ບວນ​ການ {{complete_url}} ໃຫ້​ສຳ​ເລັດ. ຖ້າ​ຫາກ​ທ່ານ​ຕ້ອງ​ການຍົກ​ເລີກ​ການ​ນັດ​ໝາຍ​ຂອງທ່ານ, ກະ​ລຸ​ນາ​ຄ​ລິກ​ໃສ່​ລິ້ງນີ້ {{cancel_url}}. ຂໍ​ຂອບ​ໃຈ"
    }
  },
  "otc": {
    "record_results_for_abbott": "ກວດ ແລະ ບັນ​ທຶກ​ຜົນ​ໄດ້​ຮັບ​ຂອງ​ທ່ານສຳ​ລັບ​ການກວດ BinaxNOW",
    "record_results": "ກວດ ແລະ ບັນ​ທຶກ​ຜົນ​ໄດ້​ຮັບ​ຂອງ​ທ່ານ",
    "activate_a_kit": "ເປີດ​ນຳ​ໃຊ້​ຊຸດ​ເຄື່ອງ​ມື",
    "activate_a_kit_for_test": "ເປີດ​ນຳ​ໃຊ້​ຊຸດ​ເຄື່ອງ​ມື​ສຳ​ລັບ {{test}}",
    "todays_test_process": "ຂະ​ບວນ​ການກວດ​ໃນ​ມື້ນີ້:",
    "date_of_birth_question": "ວັນ​ເດືອນ​ປີ​ເກີດ​ຂອງ {{first_name}} ແມ່ນ​ຫຍັງ?",
    "confirm_dob_to_continue": "ກະ​ລຸ​ນາ​ຢືນ​ຢັນ​ວັນ​ເດືອນ​ປີ​ເກີດ​ຂອງ​ຜູ້​ເຂົ້າ​ຮ່ວມ​ເພື່ອ​ສືບ​ຕໍ່:",
    "incorrect_dob": "ວັນເດືອນ​ປີ​ເກີດບໍ່​ຖືກ​ຕ້ອງ",
    "file_too_large": "ກະ​ລຸ​ນາ​ເບິ່ງ​ໃຫ້​ແນ່​ໃຈວ່າ​ໄຟ​ລ໌​ທີ່​ອັບ​ໂຫຼດ​ແມ່ນນ້ອຍກວ່າ {{size}}",
    "enter_valid_date_in_last_x_days": "ປ້ອນ​ວັນ​ທີ​ທີ່​ຖືກ​ຕ້ອງ​ໃນ {{n}} ວັນຜ່ານ​ມາ",
    "barcode_format_not_valid": "ຮູບ​ແບບບາໂຄດບໍ່​ຖືກ​ຕ້ອງ",
    "complete_button": "ສຳ​ເລັດ",
    "next_button": "ຕໍ່ໄປ",
    "back_button": "ກັບ​ຄືນ",
    "report_a_test": "ລາຍ​ງານການກວດ:",
    "x_%_completed": "ສຳ​ເລັດ {{n}}%",
    "answer_survey": "ຕອບຄຳ​ຖາມບົດ​ສຳຫຼວດ",
    "steps": {
      "answer_survey": "ຕອບ​ບົດ​ສຳຫຼວດ",
      "review_instructions": "ທົບ​ທວນ​ຄຳ​ແນະ​ນຳ",
      "scan_code": "ສະ​ແກນລະຫັດ",
      "enter_time": "ປ້ອນເວ​ລາເຂົ້າ​ໄປ",
      "enter_result": "ປ້ອນຜົນ​ໄດ້​ຮັບ",
      "take_photo": "ຖ່າຍ​ຮູບ",
      "confirm_result": "ຢືນ​ຢັນຜົນ​ໄດ້ຮັບ",
      "selest_test_kit": "ເລືອກປະ​ເພດການກວດ",
      "enter_date": "ປ້ອນ​ວັນ​ທີ",
      "enter_date_and_time": "ປ້ອນ​ວັນ​ທີ ແລະ ເວ​ລາ"
    },
    "review_instructions": "ທົບ​ທວ​ນ​ຄຳ​ແນະ​ນຳ​ການກວດ",
    "follow_instructions_or_below": "ປະ​ຕິ​ບັດ​ຕາມ​ຄຳ​ແນະ​ນຳ​ທີ່​ມາ​ພ້ອມ​ກັບການກວດຂອງ​ທ່ານ ຫຼື ທົບ​ທວນ​ຄຳ​ແນະ​ນຳ​ລຸ່ມນີ້.",
    "watch_video": "ເບິ່ງວິດີໂອ",
    "view_instructions": "ເບິ່ງ​ຄຳ​ແນະ​ນຳ",
    "follow_instructions": "ປະ​ຕິ​ບັດ​ຕາມ​ຄຳ​ແນະ​ນຳ​ທີ່​ມາ​ພ້ອມ​ກັບການກວດ​ຂອງ​ທ່ານ.",
    "scan_code": "ສະ​ແກນ​ລະ​ຫັດ QR ການກວດ",
    "qr_code_description": "ລະຫັດ QR ແມ່ນ​ມີ​ລັກ​ສະ​ນະ​ສະ​ເພາະ​ຕໍ່​ກັບ​ການກວດ​ຂອງ​ທ່ານ ແລະ ສາ​ມາດ​ພົບ​ໄດ້​ໃນແຈເບື້ອງ​ຂວາ​ມື​ດ້ານ​ເທິງ​ຂອງ​ການກວດ​ຂອງ​ທ່ານ",
    "show_example": "ສະ​ແດງ​ຕົວ​ຢ່າງ",
    "hide_example": "ເຊື່ອງ​ຕົວ​ຢ່າງ",
    "barcode_scanned": "ສະ​ແກນ​ບາໂຄດ​ສຳ​ເລັດ​ແລ້ວ",
    "enter_barcode": "ປ້ອນບາ​ໂຄດ",
    "scan_qr_code": "ສະ​ແກນລະຫັດ QR",
    "which_test_did_you_take": "ທ່ານ​ໄດ້​ໄປ​ກວດອັນ​ໃດ​ແດ່?",
    "when_did_you_take_test": "ທ່ານ​ໄດ້​ເຮັດ​ການກວດນີ້​ເມື່ອໃດ?",
    "what_day_did_you_take_test": "ທ່ານ​ໄດ້​ເຮັດ​ການກວດນີ້​ມື້​ໃດ?",
    "what_time_did_you_take_test": "ທ່ານ​ໄດ້​ເຮັດ​ການກວດນີ້​ເວລາ​ໃດ?",
    "time": {
      "today": "ມື້​ນີ້",
      "yesterday": "ມື້ວານນີ້",
      "two_days_ago": "2 ວັນ​ກ່ອນ​ນີ້"
    },
    "enter_current_time": "ປ້ອນ​ເວ​ລາ​ປັດ​ຈຸ​ບັນ",
    "enter_result": "ປ້ອນຜົນ​ໄດ້​ຮັບການກວດ",
    "choose_result_option": "ເລືອກຕົວ​ເລືອກ​ທີ່​ອະ​ທິ​ບາຍ​ຜົນ​ໄດ້​ຮັບ​ບັດກວດຂອງ​ທ່ານ:",
    "clarify_results": "ຊີ້​ແຈງຜົນ​ໄດ້​ຮັບ​ຂອງ​ທ່ານ",
    "take_result_photo": "ຖ່າຍ​ຮູບ​ຜົນ​ໄດ້​ຮັບ",
    "take_result_photo_description": "ບັນ​ທຶກຮູບພາບ​ຂອງ​ບັດ​ຜົນ​ໄດ້​ຮັບ​ທີ່​ສຳ​ເລັດ​ແລ້ວເພື່ອ​ບັນ​ທຶກ​ຜົນໄດ້​ຮັບ​ຂອງ​ທ່ານ.",
    "my_result": "ຜົນ​ໄດ້​ຮັບຂອງ​ຂ້ອຍ",
    "switch_camera": "ສະ​ຫຼັບ​ປ່ຽນ​​ກ້ອງ​ຖ່າຍ​ຮູບ",
    "confirm_result": "ຢືນ​ຢັນຜົນ​ໄດ້ຮັບ",
    "name": "ຊື່:",
    "date": "ວັນ​ທີ:",
    "result": "ຜົນໄດ້ຮັບ:",
    "results": "ຜົນໄດ້ຮັບ",
    "test_submitted": "ຂໍ​ຂອບ​ໃຈ, {{first_name}}! {{test}} ຂອງ​ທ່ານ​ໄດ້ຖືກສົ່ງໄປຮຽບຮ້ອຍແລ້ວ.",
    "dob": "ວັນເດືອນປີເກີດ:",
    "administered": "ບໍ​ລິ​ຫານ​ແລ້ວ:",
    "or": "ຫຼື",
    "upload": "ບໍ່​ສາ​ມາດ​ສະ​ແກນລະ​ຫັດໄດ້ບໍ? ຄ​ລິກ​ຕໍ່​ໄປ​ເພື່ອ​ລາຍ​ງານ​ຜົນ​ການກວດ",
    "change_uploaded_file": "ປ່ຽນ​ໄຟລ໌​ທີ່ອັບໂຫຼດແລ້ວ",
    "take_photo": "ຖ່າຍ​ຮູບ",
    "confirm_information": "ຢືນ​ຢັນ​ຂໍ້​ມູນ",
    "barcode": "ບາໂຄດ:",
    "scan_test_barcode": "ສະ​ແກນ​ບາໂຄດ​ການກວດ",
    "barcode_description": "ບາ​ໂຄດມີ​ລັກ​ສະ​ນະ​ສະ​ເພາະ​ຕໍ່​ກັບການກວດ​ຂອງ​ທ່ານ ແລະ ສາ​ມາດ​ພົບ​ໄດ້​ໃນການກວດ​ຂອງ​ທ່ານ",
    "enter_date_in_last_30_days": "ປ້ອນ​ວັນ​ທີໃນ 30 ວັນ​ທີ່​ຜ່ານ​ມາ ແລະ ເວ​ລາ​ໂດຍ​ປະ​ມານ​ທີ່​ໄດ້​ເຮັດການກວດ",
    "add_image": "ເພີ່ມ​ຮູບ",
    "change_image": "ປ່ຽນ​ຮູບ",
    "skip_barcode_reader": "ບໍ່​ສາ​ມາດ​ສະ​ແກນລະ​ຫັດໄດ້ບໍ? ຄ​ລິກ​ຕໍ່​ໄປ​ເພື່ອ​ລາຍ​ງານ​ຜົນ​ການກວດ",
    "enter_date_and_time": "ປ້ອນ​ວັນ​ທີ ແລະ ເວ​ລາຂອງການກວດ",
    "enter_date": "ປ້ອນ​ວັນ​ທີ​ຂອງການກວດ",
    "did_you_take_test_today": "ທ່ານໄດ້ກວດໃນມື້ນີ້ແລ້ວບໍ?",
    "record_results_for_generic": "ກວດ ແລະ ບັນ​ທຶກ​ຜົນ​ກວດຂອງ​ທ່ານສຳລັບ {{name}}",
    "choose_result_option_custom": "ເລືອກຕົວ​ເລືອກ​ທີ່​ອະ​ທິ​ບາຍ​ຜົນ​ກວດ {{name}} ຂອງ​ທ່ານ:"
  },
  "yes": "ແມ່ນ​ແລ້ວ",
  "no": "ບໍ່",
  "event_token": {
    "title": "ກະ​ລຸ​ນາ​ປ້ອນ​ລະ​ຫັດ​ຢູ່ລຸ່ມ​ເຂົ້າ​ໃນ​ຕູ້​ກົດ​ອັດ​ຕະ​ໂນ​ມັດ​ເພື່ອ​ຮັບ​ເອົາ​ເຄື່ອງ​ມືການກວດ​ຂອ​ງ​ທ່ານ",
    "loading_text": "ກຳ​ລັງ​ໂຫຼດ, ກະ​ລຸ​ນາ​ກວດ​ເບິ່ງ​ອີກ​ຄັ້ງ​ໃນໜຶ່ງ​ນາ​ທີ"
  },
  "appointment_recovery": {
    "complete_title": "ທ່ານກຽມພ້ອມແລ້ວ!",
    "subtitle_1": "ຖ້າຫາກວ່າທ່ານໃຊ້ໄອໂຟນ, ກະລຸນນາແຕະ  **Done** ຢູ່ແຈເບື້ອງຊ້າຍດ້ານເທິງຂອງຈໍນີ້ເພື່ອກັບໄປສູ່ກະດານຂ່າວຂອງທ່ານ.",
    "subtitle_2": "ຖ້າຫາກວ່າທ່ານໃຊ້ແອນດຣອຍ, ກະລຸນນາແຕະ  **X** ຢູ່ແຈເບື້ອງຊ້າຍດ້ານເທິງຂອງຈໍນີ້.",
    "subtitle_0": "ກະລຸນາອອກບຣາວເຊີເພື່ອດຳເນີນການຕໍ່."
  },
  "primary": {
    "support_email": "support@primary.health"
  },
  "consented_relationship": "ຄວາມ​ສຳ​ພັນ​ທີ່​ໄດ້​ຮັບ​ການ​ຍິນຍອມ",
  "finish_registration": {
    "title": "ສຳ​ເລັດການ​ລົງ​ທະ​ບຽນຂອງ​ທ່ານ",
    "subtitle": "ກະ​ລຸ​ນາປະ​ກອບຂໍ້​ມູນ​ຕໍ່​ໄປ​ນີ້ໃຫ້​ຄົບ​ຖ້ວນ​ກ່ອນ​ການ​ນັດ​ໝາຍຂອງ​ທ່ານ:",
    "button": "ສຳເລັດການລົງທະບຽນ"
  },
  "arab_ethnicity": {
    "arab": "ຄົນອາ​ຣັບ",
    "non_arab": "ບໍ່​ແມ່ນ​ຄົນ​ອາ​ຣັບ",
    "prefer_not_to_answer": "ເລືອກ​ທີ່​ຈະ​ບໍ່​ຕອບ",
    "question": "ຊົນ​ເຜົ່າ​ອາ​ຣັບ",
    "subtitle": "ກົດ​ລະ​ບຽບ​ຮຽກ​ຮ້ອງ​ໃຫ້​ພວກ​ເຮົາ​ເກັບ​ເອົາຂໍ້​ມູນ​ຕໍ່​ໄປ​ນີ້.",
    "help": "ຊົນ​ເຜົ່າ​ໝາຍ​ເຖິງ​ຊຸດ​ຂອງ​ລັກ​ສະ​ນະ​ສະ​ເພາະ​ທົ່ວ​ໄປ​ເຊັ່ນ: ພາ​ສາ, ປະ​ເພ​ນີ​ທາງວັດ​ທະ​ນະ​ທຳ, ສາ​ສະ​ໜາ ແລະ ຄຸນ​ລັກ​ສະ​ນະ​ອື່ນໆ​ທີ່​ໃຊ້​ເພື່ອ​ຈຳ​ແນກ​ຄວາມ​ແຕກ​ຕ່າງ​ຂອງ​ກຸ່ມ​ຄົນ. ມັນ​ອາດ​ຈະ ຫຼື ອາດ​ຈະ​ບໍ່​ກົງ​ກັບ​ການ​ລະ​ບຸ​ເຊື້ອ​ຊາດ​ຄື​ກັນ."
  },
  "custom_comms_quick_add": {
    "mobile_app_links": {
      "email_text": "ຂຶ້ນກັບອຸປະກອນຂອງທ່ານ, ຄລິກລິ້ງຢູ່ລຸ່ມນີ້ເພື່ອດາວໂຫຼດ Primary Health Pass."
    }
  },
  "stepper": {
    "next": "ຕໍ່ໄປ",
    "back": "ກັບຄືນ",
    "complete": "ສຳ​ເລັດ"
  },
  "registration_direcory": {
    "keyword_search_label": "ຄຳສຳຄັນ, ເມືອງ, ລະຫັດໄປສະນີ, ຊື່ອົງການ",
    "keyword_search_label_mobile": "ເມືອງ, ລະຫັດໄປສະນີ, ຊື່ອົງການ",
    "zero_results": {
      "title": "ພວກເຮົາບໍ່ພົບຜູ້ໃຫ້ບໍລິການດ້ວຍຄຳຄົ້ນຫານັ້ນ.",
      "sub_title": "ລອງຄົ້ນຫາຜູ້ໃຫ້ບໍລິການຂອງທ່ານໂດຍ:",
      "p1": "ລະຫັດໄປສະນີ",
      "p2": "ເມືອງ ຫຼື ຊື່ເມືອງ",
      "p3": "ຊື່ເຂດ",
      "p4": "ຊື່ຜູ້ໃຫ້ບໍລິການ ຫຼື ອົງການ"
    }
  },
  "registration_flyer": {
    "open_camera": "ເປີດ​ແອັບ​ກ້ອງ​ຖ່າຍ​ຮູບ​ໃນ​ອຸ​ປະ​ກອນ​ຂອງ​ທ່ານ",
    "point_to_qr_code": "ຊີ້​ໄປ​ໃສ່​ລະ​ຫັດ QR ແລະ ແຕະ​ລິ້ງ",
    "complete_registration": "ສຳ​ເລັດ​ການ​ລົງ​ທະ​ບຽນ",
    "need_help": "ຕ້ອງ​ການ​ຄວາມ​ຊ່ວຍ​ເຫຼືອ​ບໍ? ຕິດ​ຕໍ່​ພວກ​ເຮົາ​ທີ່"
  },
  "vaccine_info_sheets": {
    "vaccine_info_attestation": "ການຮັບຮອງຂໍ້ມູນການສັກວັກຊີນ",
    "select_checkbox": "ກາລຸນາທົບທວນສິ່ງຕໍ່ໄປນີ້ ແລະ ເລືອກຫ້ອງຕິກເຄື່ອງໝາຍທີ່ເໝາະສົມກ່ອນດຳເນີນການ.",
    "opportunity_to_read": "ຂ້ອຍມີ ຫຼື ໄດ້ຮັບໂອກາດໃຫ້ອ່ານ ຫຼື ໄດ້​ຮັບຟັງການ​ອ່ານຖະແຫຼງການຂໍ້ຄວາມວັກຊີນ (“VIS”) ຫຼື ເອກະສານຂໍ້ເທັດຈິງ​ຂອງໜ່ວຍງານອະນຸຍາດນຳໃຊ້ສຸກເສີນ (“EUA”) ທີ່ມີໃຫ້ສຳລັບວັກຊີນທີ່ຈະສັກ:",
    "vaccine_info_sheet": "ເອກະສານຂໍ້ມູນການສັກວັກຊີນ",
    "vaccine_fact_sheet": "ເອກະສານຂໍ້ເທັດຈິງການສັກວັກຊີນ",
    "vaccine_info_statement": "ຄຳ​ຖະ​ແຫຼງ​ຂໍ້ມູນການສັກວັກຊີນ"
  },
  "exemption": {
    "dtap_tdap": "DTaP/TDap",
    "mmr": "MMR",
    "ipv_opv": "ພະ​ຍາດ​ໂປ​ລິ​ໂອ (Polio)",
    "hib": "HIB",
    "hep_b": "HEPB",
    "var": "ໝາກສຸກໝາກໃສ",
    "hep_a": "HEPA",
    "pcv": "PCV",
    "mcv": "MCV"
  },
  "test_strip": {
    "A": "this kit includes tests for chlamydia and gonorrhea.",
    "B": "this kit includes tests for chlamydia and gonorrhea.",
    "C": "this kit includes tests for syphilis.",
    "D": "this kit includes tests for HIV, syphilis, chlamydia and gonorrhea.",
    "E": "this kit includes tests for HIV and syphilis.",
    "box_type": "Box {{type}}",
    "administered_text": "We will contact you when your kit reaches the lab and when your results are ready.",
    "activate_kit": "Activate my test kit",
    "register_another_test": "Register another test",
    "credentials": {
      "title": "Let's get started",
      "label": "Contact",
      "p1": "We'll start with some info about you.",
      "location_step": {
        "p1": "Who provided your test kit?",
        "load": "Load more"
      }
    },
    "checklist": {
      "not_urinated": "You have not urinated in the last hour.",
      "bathroom": "You have access to a bathroom for urine collection.",
      "anal_swab": "You have access to a private place to perform an anal swab.",
      "blood_extraction": "You have access to a clean environment for blood extraction.",
      "washed_hands": "You have washed your hands with soap and warm water for 30 seconds.",
      "title": "Are you ready to begin sample collection?",
      "no_alcohol": "Do not drink alcohol on the day of collection.",
      "menstruation": "Do not collect vaginal swab specimen during menstruation or within 24 hours of intercourse."
    },
    "confirm": {
      "title": "Confirm your information",
      "p1": "Is this information correct?"
    },
    "display_name": {
      "A": "Chlamydia & Gonorrhea (Single Site)",
      "B": "Chlamydia & Gonorrhea (3 Site)",
      "C": "Syphilis",
      "shortened": {
        "A": "Chlamydia & Gonorrhea",
        "B": "Chlamydia & Gonorrhea",
        "C": "Syphilis"
      },
      "CC": "COVID-19",
      "CE": "Celiac Disease Screening",
      "CR": "Creatinine Test",
      "D": "4 Panel Test - HIV, Syphilis, Chlamydia & Gonorrhea (Single Site)",
      "DD": "Diabetes Screening (HbA1c)",
      "E": "HIV & Syphilis",
      "H": "HPV",
      "P": "Hepatitis C",
      "HS": "Herpes Simplex",
      "I": "HIV",
      "TT": "Testosterone Level",
      "VD": "Vitamin D",
      "VS": "Viral Surveillance",
      "AP": "Chlamydia & Gonorrhea",
      "NN": "COVID-19",
      "HH": "COVID-19, Flu A/B, & RSV",
      "FFFWW": "COVID-19 & Flu A/B",
      "YYYWW": "COVID-19, Flu A/B & RSV",
      "HA": "COVID-19, Flu A/B, & RSV"
    },
    "rescan": "Rescan",
    "scanned": "You've scanned Box {{type}}",
    "not_yet": "Not Yet",
    "success_registration": "Registration success!",
    "no_box": "Don't have a box?",
    "faq_link": "Visit FAQs",
    "welcome": "Welcome!",
    "scan": {
      "title": "You're one step closer to a healthier you.",
      "p1": "Scan or enter the **kit bar code** on the side of your box."
    },
    "appointments": {
      "title": "Select the patient & provider",
      "p1": "We found more than one profile associated with your contact information. Select the correct profile:",
      "new": "New patient or provider"
    },
    "verify": {
      "title": "Confirm your identity",
      "p1": "We found your patient record."
    },
    "section_1": "Confirm kit contents",
    "section_2": "Test collection",
    "section_3": "Packaging",
    "section_4": "Shipping",
    "box_a": "Box A",
    "box_b": "Box B",
    "box_c": "Box C",
    "kit_flow": {
      "button_1": "Continue to packaging",
      "button_2": "I'm ready",
      "button_3": "My kit is complete and packed",
      "button_4": "I'm finished",
      "button_5": "Next test",
      "button_6": "Skip instructions"
    },
    "contents": {
      "header": "Great! Let's make sure your kit is complete",
      "text_1": "Does your test kit box contain the following?",
      "blood_collection_card": "Blood collection card",
      "blood_collection_card_subtitle": "(1 or 2 depending on test kit)",
      "alchohol_pads": "2 alcohol pads",
      "lancets": "Single use lancets",
      "up_to_other": "(Up to 4 depending on test kit)",
      "adhesive_bandages": "Adhesive bandages",
      "pipette": "Pipette",
      "urine_tube": "Urine collection tube",
      "urine_cup": "Urine collection cup",
      "oral_swab": "Oral swab",
      "oral_tube": "Oral collection tube",
      "anal_swab": "Anal swab",
      "anal_tube": "Anal collection tube",
      "vaginal_tube": "1 vaginal collection tube",
      "vaginal_swab": "1 vaginal swab",
      "biodegradable_paper": "Biodegradable collection paper",
      "bottle_and_probe": "Sampling bottle and probe",
      "prepaid_envelope": "Pre-paid shipping envelope",
      "biohazard_subtitle": "(with absorbent pad)",
      "biohazard_bag": "1 specimen bag",
      "biohazard_bag_plural": "({{count}}) Biohazard bags",
      "alcohol_pads_plural": "{{count}} alcohol pads",
      "sterile_gauze_pad": "1 sterile gauze pad",
      "collection_tube": "1 collection tube",
      "collection_swabs": "2 collection swabs"
    },
    "packaging": {
      "title": "Packaging checklist",
      "subtitle": "Before you ship or drop your kit at a kiosk, make sure that:"
    },
    "packaging_box_a": {
      "step_1": "You have packed all test kit contents",
      "step_2": "You have used all collection materials contained in the kit to collect the required specimens",
      "step_3": "Your **date-of-birth** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_4": "The **collection date** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_5": "The specimen collection tube is sealed in its biohazard bag",
      "step_6": "All specimens are placed into the box",
      "step_7": "The box is placed into the pre-paid shipping envelope",
      "step_8": "The return shipping envelope is completely sealed"
    },
    "packaging_box_b": {
      "step_1": "You have packed all test kit contents, even if you skipped the test(s)",
      "step_2": "You have used all collection materials contained in the kit to collect the required specimens",
      "step_3": "Your **date-of-birth** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_4": "The **collection date** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_5": "Each specimen collection tube is sealed in its own biohazard bag (only **one** specimen per bag)",
      "step_6": "All specimens are placed into the box",
      "step_7": "The box is placed into the pre-paid shipping envelope",
      "step_8": "The return shipping envelope is completely sealed"
    },
    "packaging_box_c": {
      "step_1": "You have packed all test kit contents, including used lancets.",
      "step_2": "Your **date-of-birth** is written on the blood collection card using MM/DD/YYYY format",
      "step_3": "The **collection date** is written on the blood collection card using MM/DD/YYYY format",
      "step_4": "The blood collection card and all used lancets are placed in the biohazard bag",
      "step_5": "The biohazard bag is placed into the box",
      "step_6": "The box is placed into the pre-paid shipping envelope",
      "step_7": "The return shipping envelope is completely sealed"
    },
    "success": {
      "header_1": "Great!",
      "text_1": "You're ready to collect your first sample.",
      "header_2": "You did it!",
      "text_2": "**Next up:** package your kit to send back.",
      "header_3": "Great job!",
      "text_3": "You’re one step closer to better health.",
      "header_4": "Nice job!",
      "text_4": "Just one more and you’re done!",
      "text_5": "You're ready to collect your sample."
    },
    "instructions": {
      "title": "Select a test to view instructions:",
      "subtitle": "Test",
      "button": "Instructions",
      "pdf": "PDF instructions"
    },
    "instructions_box_a": {
      "title": "Urine collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Do not urinate for at least 1 hour prior to collection. Using first morning urine is best.",
      "step_2": "Urinate directly into the PeeCanter or collection cup, filling between 1/3 and 1/2.",
      "step_3": "Pour from the PeeCanter or use the pipette to transfer urine from the collection cup into the collection tube(s) marked with an orange <span style=\"color: #FF5000\">**\"URINE\"**</span> label.",
      "step_4": "Fill the collection tube(s) until the combined liquid is between the designated fill lines.",
      "step_4_sublabel": "Do not fill tube past the maximum fill line or specimen will be rejected.",
      "step_5": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_6": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
      "step_7": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
    },
    "instructions_box_b": {
      "test_1": {
        "title": "Urine collection",
        "subtitle": "For this test you will need the following materials:",
        "step_1": "Do not urinate for at least 1 hour prior to collection. Using first morning urine is best.",
        "step_2": "Urinate directly into the PeeCanter or collection cup, filling between 1/3 and 1/2.",
        "step_3": "Pour from the PeeCanter or use the pipette to transfer urine from the collection cup into the collection tube(s) marked with an orange <span style=\"color: #FF5000\">**\"URINE\"**</span> label.",
        "step_4": "Fill the collection tube(s) until the combined liquid is between the designated fill lines.",
        "step_4_subtitle": "Do not fill tube past the maximum fill line or specimen will be rejected.",
        "step_5": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
        "step_6": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
        "step_7": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
      },
      "test_2": {
        "title": "Oral swab",
        "subtitle": "For this test you will need the following materials:",
        "step_1": "Hold the swab between the faint score line (if present) and the cotton-topped portion of the swab.",
        "step_2": "Insert the swab into your mouth and rub the swab tip against the back of your throat for 10 seconds.",
        "step_3": "Carefully withdraw the swab and place into the collection tube marked with a blue <span style=\"color: #001F70\">**\"ORAL\"**</span> label. Break the swab at the score line by bending against the collection tube.",
        "step_4": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
        "step_5": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
        "step_6": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
      },
      "test_3": {
        "title": "Anal swab",
        "subtitle": "For this test you will need the following materials:",
        "step_1": "Hold the swab between the faint score line (if present) and the cotton-topped portion of the swab.",
        "step_2": "Insert the swab 3 - 5 cm (1 - 2”) into the anal canal. Gently turn the swab for 5 - 10 seconds to collect any potential organisms.",
        "step_3": "Carefully withdraw the swab and place into the collection tube marked with a green <span style=\"color: #00C08C\">**“ANAL”**</span> label. Break the swab at the score line by bending against the collection tube.",
        "step_4": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
        "step_5": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
        "step_6": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
      }
    },
    "instructions_box_c": {
      "title": "Blood collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Write your name, date of birth, and the date of collection in the designated fields. Use MM/DD/YYYY format.",
      "step_2": "Open blood card flap to expose the circles on the blood collection paper. Do not touch the blood collection paper.",
      "step_3": "Wash hands with warm water for at least 30 seconds, then shake hands vigorously for 15 seconds to encourage blood flow to your fingers.",
      "step_4": "Clean fingertip with alcohol pad. It is best to use the middle or ring finger of your non-dominant hand.",
      "step_5": "Take the lancet and twist off the cap. Press the small tip firmly into the tip of your finger, until the needle ejects with a click. Keeping your hand below your heart during collection, massage finger from base to tip to encourage blood flow.",
      "step_5_subtitle": "Lancets are single use. All lancets need to be returned with your sample to the laboratory for disposal.",
      "step_6": "Starting at the center, apply 3-6 drops to fill the circle and soak through the collection paper. Do not touch the paper with your finger as this will restrict blood flow. Once circle is full, move on to next circle. It is okay for blood to extend beyond lines, but do not let blood spots spread into each other.",
      "step_6_subtitle": "Do not add additional blood to a circle once completed or dry. The “layering” of blood will invalidate the collection.",
      "step_7": "Without closing the blood card, lay it on a flat surface and allow the blood collection paper to air dry at room temperature, for at least 30 minutes. Do not heat, blow dry, or expose the blood collection paper to direct sunlight. Heat will damage the specimen.",
      "step_7_subtitle": "Check the back side of blood collection paper. Blood should saturate all the way through and fill each circle of the collection paper.",
      "step_8": "When blood collection paper is dry, close blood card by tucking flap. Place the blood card and lancets into biohazard bag with the desiccant pack. Ensure biohazard bag is properly sealed."
    },
    "test_tips": {
      "title": "Tips for Proper Blood Collection",
      "subtitle": "For best results:",
      "step_1": "Be sure you are not dehydrated while performing collection. Hydration promotes blood flow.",
      "step_2": "Do not perform collection immediately after smoking.",
      "step_3": "Washing and warming your hands under warm water will help promote blood flow in your hands.",
      "step_4": "Shake hands vigorously towards the floor to encourage blood flow to your fingers.",
      "step_5": "Keep collection device and hands below your heart during collection for best blood flow.",
      "step_6": "You may need more than one finger prick. Repeat these tips between each finger prick."
    },
    "shipping": {
      "header": "Your kit is ready to ship!",
      "text": "**Congrats!** You finished your health screening."
    },
    "pick_up": "Pick up your recommended test kit from the site staff and register below",
    "short_display_name": {
      "A": "Chlamydia & Gonorrhea",
      "B": "Chlamydia & Gonorrhea",
      "C": "Syphilis",
      "CC": "Colorectal Cancer",
      "CE": "Celiac",
      "CR": "Creatinine",
      "D": "HIV, Syphilis, Chlamydia & Gonorrhea",
      "DD": "Diabetes",
      "E": "HIV & Syphilis",
      "H": "HPV",
      "P": "Hepatitis C",
      "HS": "Herpes Simplex",
      "I": "HIV",
      "TT": "Testosterone",
      "VD": "Vitamin D",
      "VS": "Viral Surveillance"
    },
    "test_kit": "Test Kit",
    "view_results": "View results",
    "recommended_kit_title": "Recommended Test Kits",
    "instructions_box_h": {
      "title": "Vaginal Swab Collection",
      "subtitle": "Collection tubes may contain a liquid preservative. <span class=\"text-danger\">**Do not empty liquid preservative from the collection tubes if present.**</span>",
      "step_1": "Hold the swab between the faint score line (if present) and the cotton-tipped portion of the swab in one hand and separate the labia (folds of skin around the vaginal opening).",
      "step_2": "Insert the swab 5cm (2in) into the vaginal opening. Gently turn the swab for 30 seconds while rubbing the swab against the walls of the vagina.",
      "step_3": "Carefully withdraw the swab and place into the collection tube marked with a <span class=\"text-danger\">**RED “VAGINAL” LABEL**</div>. Break the swab by bending against the collection tube.",
      "step_4": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_5": "Write your date of birth in MM/DD/YYYY format onto the tube in the designated area (DOB).",
      "step_6": "Write the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area (Coll. Date).",
      "step_7": "Place specimen into the empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
    },
    "packaging_box_h": {
      "step_3": "Your **date-of-birth** is written on the specimen collection tube using MM/DD/YYYY format",
      "step_4": "Your **collection date** is written on the specimen collection tube using MM/DD/YYYY format",
      "step_6": "The biohazard bag is placed into the box"
    },
    "instructions_box_cc": {
      "title": "Stool collection",
      "step_1": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area. Open green cap by twisting and lifting.",
      "step_2": "Place supplied collection paper into toilet bowl on top of water.",
      "step_3": "Deposit stool sample on top of collection paper.",
      "step_4": "Collect sample from stool before paper sinks and stool sample touches water.",
      "step_5": "Scrape the surface of the fecal sample with the sample probe.",
      "step_6": "Cover the grooved portion of the sample probe completely with stool sample.",
      "step_7": "Close sampling bottle by inserting sample probe and snapping green cap on tightly. Do not reopen.",
      "step_8": "Flush. Collection paper is biodegradable and will not harm septic systems.",
      "step_9": "Wrap sampling bottle in absorbent pad and insert in to  the biohazard bag."
    },
    "contact_support": {
      "title": "Let's fix this",
      "text_1": "We are sorry to hear that something is wrong!",
      "text_2": "Please contact us to let us know what’s wrong and we will help to replace your kit."
    },
    "contact_support_success": {
      "title": "We received your message",
      "text_1": "Thanks for contacting us.",
      "text_2": "A member of our support team will be in touch soon.",
      "button_text": "Return home"
    },
    "kit_in_transit_to_patient": "Your order is on its way! Most orders are delivered within 2-5 business days. Once you receive your kit, we will send you more information on how to activate it and send it back.\n\nTracking Link: https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{tracking_number}}",
    "kit_received_at_lab": "The lab received your kit! You'll receive a notification to check your patient portal when results are ready",
    "registration_confirmation": "Your registration is now complete! Visit your registration confirmation page to activate your test kit:",
    "kit_ordered_online": "We've received your kit order and we will send an update once it ships! \n\nOrder #: {{order_number}} \nOrder date: {{order_date}}",
    "kit_ordered_online_email": {
      "header": "We received your order!",
      "p1": "We've received your order! Once your order ships, we'll send you another update.",
      "order_number": "Order #",
      "order_date": "Order date"
    },
    "completed": "Completed",
    "I": "this kit includes tests for HIV.",
    "CC": "this kit includes tests for COVID-19.",
    "CE": "this kit includes tests for celiac disease.",
    "CR": "this kit includes tests for creatinine.",
    "DD": "this kit includes tests for diabetes.",
    "H": "this kit includes tests for HPV.",
    "P": "this kit includes tests for hepatitis C.",
    "HH": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "HS": "this kit includes tests for HSV-2.",
    "TT": "this kit includes tests for testosterone.",
    "VD": "this kit includes tests for vitamin D.",
    "nasal_collection": {
      "title": "Nasal collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Write your Date of Birth and the Collection Date on your collection tube. Use MM/DD/YYYY format for the dates (i.e., 08/29/2023).",
      "step_2": "Wash your hands with soap and water thoroughly before proceeding and after completeing all steps.",
      "step_3": "Remove the swab from the wrapper with your clean hands. Hold the swab with the white tip up. Do not touch the tip of the swab to anything.",
      "step_4": "Holding the swab with one hand, gently insert the white tip of the swab about 1/2 to 3/4 inces (1 to 2 cm) into the opening of your nose. Rotate the swab for 15 to 30 seconds. Make sure the swab touches the insides of your nose. Remove the swab from your nose being careful not to touch your skin. Do not set the swab down. Repeat in the other nostril.",
      "step_5": "While still holding the swab, unscrew and remove the cap from the transport tube without setting the cap down. Place the swab into the tube with the white tip down, taking great care not to spill or splash the liquid in the collection tube.",
      "step_6": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_7": "Place specimen into the empty biohazard bag with the absobent pad and ensure the seal is closed completely. Return the biohazard bag to the kiosk drop box."
    },
    "NN": "this kit includes tests for COVID-19.",
    "YYYWW": "this kit includes tests for COVID-19, Flu A/B, and RSV.",
    "FFFWW": "this kit includes tests for COVID-19 and Flu A/B.",
    "HA": "this kit includes tests for COVID-19, Flu A/B, and RSV."
  },
  "copy_link": "ກັອບ​ປີ້ລິ້ງໜ້າ",
  "copied_link": "ກັອບ​ປີ້ລິ້ງແລ້ວ!",
  "vaccines": {
    "descriptions": {
      "vaccine_flu": "ການປ້ອງກັນໄວຣັສໄຂ້ຫວັດທົ່ວໄປ",
      "vaccine_covid": "ການປ້ອງກັນໄວຣັສ COVID-19",
      "tdap": "ວັກຊີນ Tdap ສາມາດປ້ອງກັນບາດທະຍັກ",
      "polio": "ການປ້ອງກັນໄວຣັສໂປລີໂອ. ຈຳເປັນສຳລັບເດັກນ້ອຍກ່ອນເລີ່ມເຂົ້າຮຽນ.",
      "varicella": "ວັກຊີນທີ່ປ້ອງກັນໝາກສຸກໝາກໃສ",
      "shingles": "ວັກຊີນທີ່ປ້ອງກັນພະຍາດງູສະຫວັດ. ອາຍຸຂັ້ນຕ່ຳ 18 ປີ.",
      "human_papillomavirus": "ວັກຊີນທີ່ປ້ອງກັນພະຍາດ HPV. ແນະນຳສຳລັບເດັກທີ່ອາຍຸ 11 ປີ.",
      "meningococcal": "ວັກຊີນປ້ອງກັນແບັກທີເຣຍເມນິງກໍຄັອກຄໍ (meningococcal) ສີ່ປະເພດ."
    }
  },
  "viral_surveillance": {
    "shipping": {
      "title": "Prepare to ship your sample:",
      "step_1": {
        "label": "Put the biohazard bag into box.",
        "substep_1": "Remove the return shipping mailer from your test kit.",
        "substep_2": "Place the biohazard bag into the original collection kit box and close the box."
      },
      "step_2": {
        "label": "Put box into return shipping mailer.",
        "substep_1": "Place the box into the return shipping mailer and seal the mailer.",
        "substep_2": "Do not fold or cover the UN3373 diamond symbol."
      },
      "step_3": {
        "label": "Return to Fedex location.",
        "substep_1": "Return kit on the same day of collection (before the last pickup) to a Fedex location.",
        "substep_2": "Call for a FedEx pickup at 1.800.GoFedEx."
      }
    },
    "overview": {
      "title": "Welcome to the Viral Sequencing Program!",
      "subtitle": "Your participation helps provide valuable information on which respiratory pathogen variants are circulating in your area. Please follow the instructions that came with your kit to get started."
    },
    "instructions": {
      "title": "Collect your sample:",
      "step_1": "Wash your hands.",
      "step_2": "Remove the cap of the collection tube and open the swab package.",
      "step_2a": "Remove the swab from the package. Do not touch the soft end with your hands or anything else.",
      "step_3": "Collect a nasal swab.",
      "step_3a": "Insert the entire soft end of the swab into your nostril no more than 3/4 of an inch (1/5cm) into your nose.",
      "step_3b": "Slowly rotate the swab",
      "step_3c": "Gently remove the swab.",
      "step_3d": "Using the same swab, repeat in your other nostril with the same end of the swab.",
      "step_4": "Put swab into the collection tube and cap the tube securely.",
      "step_5": "Put the collection tube into the biohazard bag and seal.",
      "step_6": "Apply even pressure to the ziplock to seal the bag shut."
    },
    "click_to_get_started_button": "Click to get started",
    "get_started_button": "Get started",
    "thank_you": {
      "title": "Thank you for using Primary.Health!",
      "p_3": "You may now close the browser.",
      "p_2": "Please make sure to drop off the viral surveillance kit at your closest FedEx location.",
      "p_1": "Your participation actively helps Public Health officials fight the spread of disease. If you would like to see the impact you are making on Public Health, please take a moment to explore more via [this link]({{link}}). You will not be contacted about your results, as they are anonymous."
    },
    "verify_dob_title": "First, let's confirm your details",
    "verify_dob_subtitle": "This helps us ensure the correct person is using the test kit.",
    "activate_kit": "Activate a kit:",
    "steps_complete": "Steps complete",
    "opt_in": {
      "section_1_title": "How it works",
      "section_1_text_1": "Sign up below and receive a viral sequencing test kit in 1-3 business days.",
      "section_1_text_2": "Upon receiving the test kit",
      "section_1_text_3": "Send the sample back per the instructions in the kit using our prepaid label.",
      "sign_up": "Sign me up!",
      "no_to_participate": "No",
      "section_2_title": "What is the impact of your participation?",
      "section_2": {
        "card_1_title": "Rapid response to new variants",
        "card_1_text": "Genetic sequencing helps track the spread and emergence of new respiratory pathogen variants, allowing public health systems to quickly ramp up preparedness efforts.",
        "card_2_title": "Track how Respiratory Pathogens are changing",
        "card_2_text": "Respiratory pathogen sequencing analyzes the “genetic blueprint” of the virus. This helps scientists trace how the virus changes, enabling it to spread more easily, make people more sick",
        "card_3_title": "You have complete privacy",
        "card_3_text": "The sequencing will be processed anonymously. The lab will never contact you about your results"
      },
      "title": "You’re eligible for California’s Free Respiratory Pathogen Viral Sequencing Project!",
      "subtitle": "You can help public health officials protect communities against respiratory pathogens! If you opt in to participate you will receive a free viral sequencing kit that will test what respiratory pathogen strain you are infected with. Results will help officials track the spread of respiratory pathogen variants. See below for more information and to sign up.",
      "continue_to_result": "Continue to my result",
      "create_page_title": "Thank you!",
      "create_page_text_1": "Your kit should arrive in the next 1-3 business days.",
      "create_page_text_2": "Your participation actively helps Public Health officials fight the spread of diseases. You will be notified when your test kit order has been approved and when it has shipped."
    }
  },
  "family_registrations": {
    "find_clinic": "ຊອກຫາຄລີນິກ",
    "select_pin": "ເລືອກໝຸດເພື່ອເບິ່ງລາຍລະອຽດ",
    "miles_shorten": "{{miles}} ໄມ",
    "next_available": "ທີ່​ມີ​ໃຫ້​ຄັ້ງ​ຕໍ່​ໄປ",
    "appointment_type": "ປະເພດການນັດໝາຍ",
    "individual_appointment": "ການນັດໝາຍສ່ວນບຸກຄົນ",
    "family_appointment": "ການນັດໝາຍຄອບຄົວ",
    "vaccines": "ວັກ​ຊິນ",
    "what_kind_appointment": "ທ່ານຕ້ອງ​ການການນັດໝາຍປະເພດໃດ?",
    "select_family_or_individual": "ເລືອກການນັດໝາຍຄອບຄົວ, ຖ້າຫາກທ່ານນັດສະມາຊິກຄອບຄົວຫຼາຍຄົນພ້ອມກັນ.",
    "vaccines_selected": "ວັກຊີນທີ່ຖືກເລືອກ",
    "where_care": "ທ່ານ​ຕ້ອງ​ການຮັບເອົາການເບິ່ງແຍງດູແລ​ຂອງ​ທ່ານຢູ່ໃສ?",
    "select_vaccines_for_individual": "ເລືອກວັກຊີນສຳລັບການນັດໝາຍຂອງທ່ານ (ທາງເລືອກ).",
    "select_vaccines_for_family": "ເລືອກວັກຊີນສຳລັບການນັດໝາຍຂອງຄອບຄົວທ່ານ (ທາງເລືອກ).",
    "schedule_services": "ບໍລິການຈັດຕາຕະລາງ",
    "add_family": "ເພີ່ມສະ​ມາ​ຊິກ​ຄອບ​ຄົວ ແລະ ເລືອກບໍລິການຢູ່ລຸ່ມນີ້.",
    "family_member": "ສະ​ມາ​ຊິກຄອບຄົວ {{number}}",
    "morning": "ຕອນ​ເຊົ້າ",
    "afternoon": "ຕອນ​ສວາຍ",
    "slot_available": "{{number}} ທີ່ມີຢູ່",
    "within_x_miles": "ພາຍໃນ {{miles}} ໄມຈາກ",
    "any_distance": "ໄລຍະທາງຈາກ",
    "partial_results": "ຜົນກວດບາງສ່ວນ",
    "partial_matches": "ຄລີກນິກຕໍ່ໄປນີ້ໃຫ້ບໍລິການວັກຊີນບາງສ່ວນ, ແຕ່ບໍ່ແມ່ນທັງໝົດທີ່ທ່ານກຳລັງຊອກຫາ",
    "no_matches": "ການຄົ້ນຫານັ້ນບໍ່ພົບສິ່ງທີ່ກົງກັນໃດໆ. ລອງປ່ຽນຕົວຄັດກອງຂອງທ່ານເພື່ອໃຫ້ໄດ້ຜົນຮັບທີ່ດີຂຶ້ນ.",
    "no_clinics_found": "ບໍ່ມີຄລີນິກໃດທີ່ກົງກັບການຄົ້ນຫາຂອງທ່ານ.",
    "broaden_filters": "ລອງຂະຫຍາຍຕົວຄັດກອງຂອງທ່ານເພື່ອໃຫ້ໄດ້ຜົນຮັບທີ່ດີຂຶ້ນ.",
    "unavailable_vaccines": "ວັກຊີນທີ່ບໍ່ມີໃຫ້:",
    "available_vaccines": "ວັກຊີນທີ່ມີໃຫ້:",
    "select_date": "ເລືອກວັນ​ທີ",
    "available_appointments": "ການນັດໝາຍທີ່ມີພ້ອມໃຫ້",
    "appointment_scheduling_info": "ທ່ານຈະບໍ່ສາມາດກຳນົດເວລາການນັດໝາຍໃຫ້ແກ່ສະມາຊິກຄອບຄົວສະເພາະໃນຂັ້ນຕອນຕໍ່ໄປໄດ້. ທ່ານສາມາດຈັດເວລາການນັດໝາຍໃນມື້ຕ່າງໆໄດ້, ຖ້າຫາກທ່ານເລືອກ.",
    "hold_selected_appointments": "ພວກເຮົາຈະຮັກສາການນັດໝາຍທີ່ທ່ານເລືອກເປັນເວລາ 15 ນາທີ.",
    "appointments_selected": "ການນັດໝາຍທີ່ໄດ້ເລືອກ",
    "no_appointments_selected": "ບໍ່ມີການນັດໝາຍທີ່ໄດ້ເລືອກ",
    "vaccines_needed": "ວັກຊີນທີ່ຈຳເປັນ",
    "select_x_appointments": "ເລືອກ {{number}} ການນັດໝາຍ.",
    "more": "ເພີ່ມ​ເຕີມ",
    "less": "ໜ້ອຍລົງ",
    "register_next_family_member": "ລົງທະບຽນສະມາຊິກໃນຄອບຄົວຕໍ່ໄປ",
    "successfully_registered_x_of_y_family_members": "ທ່ານໄດ້ລົງທະບຽນໃຫ້ສະມາຊິກຄອບຄົວ {{x}} ຈາກ {{y}} ຄົນສຳເລັດແລ້ວ",
    "next_family_member": "ສະມາຊິກໃນຄອບຄົວຕໍ່ໄປ",
    "appointments_abbreviated": "ການນັດໝາຍ",
    "register_for_clinic": "ລົງທະບຽນສຳລັບຄລີນິກ",
    "select_services": "ເລືອກບໍລິການ",
    "person": "ຄົນ {{number}}",
    "add_person": "ເພີ່ມຄົນອື່ນອີກ",
    "registration_confirmation": "ການຢືນຢັນການລົງທະບຽນ"
  },
  "user_mailer": {
    "verify_email": "ມາກວດສອບອີເມວຂອງທ່ານ",
    "hi_full_name": "ສະບາຍດີ {{full_name}},",
    "verify_email_button": "ກວດ​ສອບອີເມວ",
    "please_verify_email": "ກະລຸນານຳໃຊ້ປຸ່ມຢູ່ລຸ່ມນີ້ເພື່ອກວດສອບອີເມວຂອງທ່ານ."
  },
  "services": {
    "dptap_dt": "DTaP/Tdap/Td",
    "hepatitis_a": "ຕັບ​ອັກ​ເສບ A",
    "hepatitis_b": "ຕັບ​ອັກ​ເສບ B",
    "hepatitis_a_b": "ຕັບ​ອັກ​ເສບ A ແລະ B",
    "hib": "Hib",
    "mmr": "ໝາກ​ແດງ, ໝາກ​ເບີດ, ໝາກ​ແດງ​ນ້ອຍ",
    "meningococcal": "ພະຍາດເຍື່ອຫຸ້ມສະໝອງອັກເສບ",
    "mpox": "Mpox",
    "pneumococcal": "ພະຍາດປອດບວມຈາກນິວໂມຄັອກຄັສ",
    "polio": "ພະ​ຍາດ​ໂປ​ລິ​ໂອ (Polio)",
    "rsv": "ໄວຣັສການຕິດເຊື້ອທາງເດີນຫາຍໃຈ",
    "rotovirus": "ໄວຣັສໂຣຕ້າ",
    "zoster": "ງູສະຫວັດ",
    "tetanus_diptheria": "ບາດທະຍັກ ແລະ ຄໍຕີບ",
    "tdap": "Tdap",
    "typhoid": "ໄທ​ຟອຍ",
    "varicella": "ພະຍາດໝາກສຸກໝາກໃສ",
    "covid-19": "COVID-19",
    "covid-19_adult": "COVID-19 (ຜູ້​ໃຫຍ່)",
    "covid-19_adult_description": "ສຳລັບຄົນ 12 ປີຂຶ້ນໄປ.",
    "covid-19_children": "COVID-19 (ເດັກ​ນ້ອຍ)",
    "covid-19_children_description": "ສຳ​ລັບ​ເດັກ​ທີ່​ມີອາ​ຍຸ 4 - 11 ປີ.",
    "covid-19_infants": "COVID-19 (ເດັກ​ອ່ອນ)",
    "covid-19_infants_description": "ສຳ​ລັບ​ເດັກ​ທີ່​ມີອາ​ຍຸ 6 ເດືອນ - 3 ປີ.",
    "influenza": "ໄຂ້ຫວັດໃຫຍ່",
    "mmrv": "ໝາກ​ແດງ, ໝາກ​ເບີດ, ໝາກ​ແດງ​ນ້ອຍ ແລະ ໝາກສຸກໝາກໃສ"
  },
  "deep_archived": {
    "admin": "ເພື່ອຈຸດປະສົງ​ຄວາມ​ປອດ​ໄພ​ຂໍ້​ມູນ, ຂໍ້ມູນໃນກຸ່ມ {{test_group_name}} ({{slug}}) ແມ່ນຖືກເກັບໄວ້ ແລະ ບໍ່ສາມາດເບິ່ງໄດ້ ຫຼື ປ່ຽນຈາກໜ້ານີ້ໄດ້. ກະລຸນາຕິດຕໍ່ຫາຜູ້ຈັດການບັນຊີຂອງທ່ານ ຫຼື support@primary.health, ຖ້າຫາກທ່ານຕ້ອງການຄວາມຊ່ວຍເຫຼືອ.",
    "participant": "ໜ້ານີ້​ບໍ່​ມີ​ໃຫ້​ໃຊ້​ອີກຕໍ່​ໄປ. ກະລຸນາຕິດຕໍ່ຫາຜູ້ຕິດຕໍ່ຂອງທ່ານຈາກການ​ໂຄ​ສະ​ນາ​ສົ່ງ​ເສີມສຸຂະພາບ: {{test_group_name}}. ຖ້າ​ທ່ານ​ມີ​ບັນ​ຫາ​ໃນ​ການ​ເບິ່ງບັນທຶກເກົ່າ, ກະ​ລຸ​ນາ​ຕິດ​ຕໍ່ support@primary.health ເພື່ອຂໍ​ຄວາມ​ຊ່ວຍ​ເຫຼືອ​."
  }
}