import React, { useState } from 'react';
import {
  faCalendar,
  faUser,
  faNotesMedical,
  faClipboardMedical,
  faClipboardList,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StatusDot } from './ServicesSection';

const SIDEBAR_DATA = {
  guardian_information: { label: "Guardian" },
  test_lane: { label: "Test lane" },
  consent: { label: "Consent" },
  survey: {
    label: "Questionnaires",
    isAccordion: true,
    subtabs: {
      appointment_survey: { label: "Appointment", icon: faCalendar },
      checkout_survey: { label: "Checkout", icon: faClipboardList },
      insurance_survey: { label: "Insurance", icon: faNotesMedical },
      medical_screening_survey: { label: "Medical", icon: faClipboardMedical },
      test_group_user_survey: { label: "Participant", icon: faUser },
    },
  },
  dependents: { label: "Dependents" },
  symptoms: { label: "Symptoms" },
  tags: { label: "Tags" },
  insurance: { label: "Insurance" },
  show_immunization_history: {
    label: "Vaccine history",
    hideStatus: true,
  },
  services: { label: "Services" },
};

const SidebarLabel = ({
  icon,
  isActive,
  label
}) => (
  <div className="d-flex">
    {icon && <FontAwesomeIcon className="me-2 my-auto" icon={icon} />}
    <div
      className="sidebar-label"
      style={{
        color: isActive ? "#2862FA" : "",
        fontWeight: isActive ? "500" : "",
      }}
    >
      {label}
    </div>
  </div>
);

const SidebarItem = ({
  keyName,
  data,
  isActive,
  accordionState,
  toggleAccordion,
  scrollToSection,
  sidebarKeys,
  activeSection,
  setActiveSection,
}) => (
  <div className="d-flex flex-column">
    <div className="d-flex">
      {isActive && <div className="active-indicator" />}
      <a
        className="dropdown-item pointer"
        onClick={() => (data.isAccordion ? toggleAccordion(keyName) : scrollToSection(keyName))}
      >
        <SidebarLabel
          icon={data.icon}
          isActive={isActive}
          label={data.label}
        />
        {data.isAccordion && (
          <FontAwesomeIcon
            className="my-auto"
            icon={accordionState[keyName] ? faChevronUp : faChevronDown}
          />
        )}
      </a>
      {!data.isAccordion && !data.hideStatus && (
        <StatusDot className="me-4 my-auto" id={`${keyName}_sidebar_status_indicator`} status="pending" />
      )}
    </div>
    {data.isAccordion && accordionState[keyName] && (
      <div className="ms-1">
        {Object.keys(data.subtabs)
          .filter((subKey) => sidebarKeys.includes(subKey))
          .map((subKey) => (
            <SidebarSubItem
              key={subKey}
              keyName={subKey}
              data={data.subtabs[subKey]}
              isActive={activeSection === subKey}
              scrollToSection={scrollToSection}
              setActiveSection={setActiveSection}
            />
          ))}
      </div>
    )}
  </div>
);

const SidebarSubItem = ({
  data,
  isActive,
  keyName,
  scrollToSection,
  setActiveSection
}) => (
  <div className="d-flex">
    {isActive && <div className="active-indicator" />}
    <a
      className="dropdown-item pointer"
      onClick={() => {
        scrollToSection(keyName);
        setActiveSection(keyName);
      }}
    >
      <SidebarLabel
        icon={data.icon}
        isActive={isActive}
        label={data.label}
      />
    </a>
    {!data.hideStatus && (
      <StatusDot
        className="me-4 my-auto"
        id={`${keyName}_sidebar_status_indicator`}
        status="pending"
      />
    )}
  </div>
);

const CheckoutSideBar = ({ activeSection, setActiveSection, sidebarKeys }) => {
  const [accordionState, setAccordionState] = useState({ survey: false });

  const toggleAccordion = (key) => {
    setAccordionState((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const scrollToSection = (key) => {
    const headerHeight = document.getElementById('header-container').offsetHeight;
    const section = document.getElementById(`${key}_section_container`);
    const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset;
    const offsetPosition = sectionPosition - headerHeight - 180;

    window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
  };

  return (
    <div
      className="d-lg-block d-none sidebar-nav"
      id="checkout-sidebar"
      style={{ zIndex: 1 }}
    >
      {Object.keys(SIDEBAR_DATA)
        .filter((key) => sidebarKeys.includes(key))
        .map((key) => (
          <SidebarItem
            key={key}
            keyName={key}
            data={SIDEBAR_DATA[key]}
            isActive={activeSection === key}
            accordionState={accordionState}
            toggleAccordion={toggleAccordion}
            scrollToSection={scrollToSection}
            sidebarKeys={sidebarKeys}
            activeSection={activeSection}
            setActiveSection={setActiveSection}
          />
        ))}
    </div>
  );
};

export default CheckoutSideBar;