export const CONTENTS = 'CONTENTS';
export const INSTRUCTIONS = 'INSTRUCTIONS';
export const PACKAGING = 'PACKAGING';
export const SHIPPING = 'SHIPPING';

// Contents
const driedBloodSwabItems = [
    {
        image: '/images/sti-programs/contents-s-1.svg',
        contents: [
            {
                label: 'test_strip.contents.blood_collection_card',
            },
            {
                label: 'test_strip.contents.blood_collection_card_subtitle',
            }
        ],
    },
    {
        image: '/images/sti-programs/contents-s-2.svg',
        contents: [
            {
                label: 'test_strip.contents.alcohol_pads',
                count: 2
            },
        ],
    },
    {
        image: '/images/sti-programs/contents-s-3.svg',
        contents: [
            {
                label: 'test_strip.contents.sterile_gauze_pad',
            },
        ],
    },
    {
        image: '/images/sti-programs/contents-s-4.svg',
        contents: [
            {
                label: 'test_strip.contents.lancets',
            },
            {
                label: "test_strip.contents.up_to_other",
                count: 4,
            }
        ],
    },
    {
        image: '/images/sti-programs/contents-s-5.svg',
        contents: [
            {
                label: 'test_strip.contents.adhesive_bandages',
            },
            {
                label: 'test_strip.contents.up_to_other',
                count: 4,
            },
        ],
    },
];

const bioHazardBag = (count) => ({
  image:
    count === 3
      ? '/images/sti-programs/biohazard-bags.svg'
      : '/images/sti-programs/biohazard-bag.svg',
  contents: [
    {
      label: 'test_strip.contents.biohazard_bag',
      count: count,
    },
  ],
});

const biohazardBagWithAbsorbent = {
    image: '/images/sti-programs/biohazard-bag.svg',
    contents: [
        {
            label: 'test_strip.contents.biohazard_bag',
        },
        {
            label: 'test_strip.contents.biohazard_subtitle',
        },
    ],
};

const urineSupplies = {
    image: '/images/sti-programs/supplies-urine.png',
    contents: [
        {
            label: 'test_strip.contents.pipette',
        },
        {
            label: 'test_strip.contents.urine_tube',
        },
        {
            label: 'test_strip.contents.urine_cup',
        },
    ],
};

const oralSupplies = {
    image: '/images/sti-programs/supplies-oral.png',
    contents: [
        {
            label: 'test_strip.contents.oral_swab',
        },
        {
            label: 'test_strip.contents.oral_tube',
        },
    ],
};

const analSupplies = {
    image: '/images/sti-programs/supplies-anal.png',
    contents: [
        {
            label: 'test_strip.contents.anal_swab',
        },
        {
            label: 'test_strip.contents.anal_tube',
        },
    ],
};

const vaginalItems = [
    {
        image: '/images/sti-programs/vaginal-collection-tube.png',
        contents: [
            {
                label: 'test_strip.contents.vaginal_tube',
            },
        ],
    },
    {
        image: '/images/sti-programs/vaginal-swab.png',
        contents: [
            {
                label: 'test_strip.contents.vaginal_swab',
            },
        ],
    }
];

const nasalSupplies = [
    {
        image: '/images/sti-programs/collection-tube.png',
        contents: [
            {
                label: 'test_strip.contents.collection_tube',
            },
        ],
    },
    {
        image: '/images/sti-programs/collection-swabs.png',
        contents: [
            {
                label: 'test_strip.contents.collection_swabs',
            },
        ],
    }
]

const stoolItems = [
    {
        image: '/images/sti-programs/biodegradable-paper.png',
        contents: [
            {
                label: 'test_strip.contents.biodegradable_paper',
            },
        ],
    },
    {
        image: '/images/sti-programs/sample-probe.png',
        contents: [
            {
                label: 'test_strip.contents.bottle_and_probe',
            },
        ],
    }
]

const shippingEnvelope = {
    image: '/images/sti-programs/shipping-envelope.png',
    contents: [
        {
            label: 'test_strip.contents.prepaid_envelope',
        },
    ],
};

// Instructions
const driedBloodSwabInstructions = {
    "title": "test_strip.instructions_box_c.title",
    "link": "https://primarybio-public.s3.us-east-2.amazonaws.com/V.1.0-DBS-Collection-Instruction-English.pdf",
    "subtitle": "test_strip.instructions_box_c.subtitle",
    "test_steps": [
        {
            "label": "test_strip.instructions_box_c.step_1",
            "image": "/images/sti-programs/blood-1.svg"
        },
        {
            "label": "test_strip.instructions_box_c.step_2",
            "image": "/images/sti-programs/blood-2.svg"
        },
        {
            "label": "test_strip.instructions_box_c.step_3",
            "image": "/images/sti-programs/blood-3.svg"
        },
        {
            "label": "test_strip.instructions_box_c.step_4",
            "image": "/images/sti-programs/blood-4.svg"
        },
        {
            "label": "test_strip.instructions_box_c.step_5",
            "sublabel": "test_strip.instructions_box_c.step_5_subtitle",
            "image": "/images/sti-programs/blood-5.svg"
        },
        {
            "label": "test_strip.instructions_box_c.step_6",
            "sublabel": "test_strip.instructions_box_c.step_6_subtitle",
            "image": "/images/sti-programs/blood-6.svg"
        },
        {
            "label": "test_strip.instructions_box_c.step_7",
            "sublabel": "test_strip.instructions_box_c.step_7_subtitle",
            "image": "/images/sti-programs/blood-7.svg"
        },
        {
            "label": "test_strip.instructions_box_c.step_8",
            "image": "/images/sti-programs/biohazard-bag-large.svg"
        }
    ]
};

const urineCollectionInstructions = {
    "title": "test_strip.instructions_box_b.test_1.title",
    "subtitle": "test_strip.instructions_box_b.test_1.subtitle",
    "link": "https://primarybio-public.s3.us-east-2.amazonaws.com/Primary-IFU-B-20221220-v1.pdf",
    "test_steps": [
        {
            "label": "test_strip.instructions_box_b.test_1.step_1",
            "image": "/images/sti-programs/urine-1.svg"
        },
        {
            "label": "test_strip.instructions_box_b.test_1.step_2",
            "image": "/images/sti-programs/urine-2.svg"
        },
        {
            "label": 'test_strip.instructions_box_b.test_1.step_3',
            "image": "/images/sti-programs/urine-3.svg"
        },
        {
            "label": "test_strip.instructions_box_b.test_1.step_4",
            "sublabel": "test_strip.instructions_box_b.test_1.step_4_subtitle",
            "image": "/images/sti-programs/urine-4.svg"
        },
        {
            "label": "test_strip.instructions_box_b.test_1.step_5",
            "image": "/images/sti-programs/urine-5.svg"
        },
        {
            "label": "test_strip.instructions_box_b.test_1.step_6",
            "image": "/images/sti-programs/urine-6.svg"
        },
        {
            "label": "test_strip.instructions_box_b.test_1.step_7",
            "image": "/images/sti-programs/biohazard-bag-large.svg"
        }
    ],
}

const vaginalSwabInstructions = {
    "title": "test_strip.instructions_box_h.title",
    "link": "https://primarybio-public.s3.us-east-2.amazonaws.com/V.1.0-Vaginal-Swab-Collection-English.pdf",
    "subtitle": "test_strip.instructions_box_h.subtitle",
    "test_steps": [
        {
            "label": "test_strip.instructions_box_h.step_1",
            "image": "/images/sti-programs/vaginal-1.png"
        },
        {
            "label": "test_strip.instructions_box_h.step_2",
            "image": "/images/sti-programs/vaginal-2.png"
        },
        {
            "label": 'test_strip.instructions_box_h.step_3',
            "image": "/images/sti-programs/vaginal-3.png"
        },
        {
            "label": "test_strip.instructions_box_h.step_4",
            "image": "/images/sti-programs/vaginal-4.png"
        },
        {
            "label": "test_strip.instructions_box_h.step_5",
            "image": "/images/sti-programs/vaginal-5.png"
        },
        {
            "label": "test_strip.instructions_box_h.step_6",
            "image": "/images/sti-programs/vaginal-6.png"
        },
        {
            "label": "test_strip.instructions_box_h.step_7",
            "image": "/images/sti-programs/vaginal-7.png"
        },
    ],
}

const oralCollectionInstructions = {
    "title": "test_strip.instructions_box_b.test_2.title",
    "subtitle": "test_strip.instructions_box_b.test_2.subtitle",
    "link": "https://primarybio-public.s3.us-east-2.amazonaws.com/Primary-IFU-B-20221220-v1.pdf",
    "test_steps": [
        {
            "label": "test_strip.instructions_box_b.test_2.step_1",
            "image": "/images/sti-programs/oral-1.svg"
        },
        {
            "label": "test_strip.instructions_box_b.test_2.step_2",
            "image": "/images/sti-programs/oral-2.svg"
        },
        {
            "label": 'test_strip.instructions_box_b.test_2.step_3',
            "image": "/images/sti-programs/oral-3.svg"
        },
        {
            "label": "test_strip.instructions_box_b.test_2.step_4",
            "image": "/images/sti-programs/oral-4.svg"
        },
        {
            "label": "test_strip.instructions_box_b.test_2.step_5",
            "image": "/images/sti-programs/oral-5.svg"
        },
        {
            "label": "test_strip.instructions_box_b.test_2.step_6",
            "image": "/images/sti-programs/biohazard-bag-large.svg"
        }
    ],
}

const analCollectionInstructions = {
    "title": "test_strip.instructions_box_b.test_3.title",
    "subtitle": "test_strip.instructions_box_b.test_3.subtitle",
    "link": "https://primarybio-public.s3.us-east-2.amazonaws.com/Primary-IFU-B-20221220-v1.pdf",
    "test_steps": [
        {
            "label": "test_strip.instructions_box_b.test_3.step_1",
            "image": "/images/sti-programs/anal-1.svg"
        },
        {
            "label": "test_strip.instructions_box_b.test_3.step_2",
            "image": "/images/sti-programs/anal-2.svg"
        },
        {
            "label": 'test_strip.instructions_box_b.test_3.step_3',
            "image": "/images/sti-programs/anal-3.svg"
        },
        {
            "label": "test_strip.instructions_box_b.test_3.step_4",
            "image": "/images/sti-programs/anal-4.svg"
        },
        {
            "label": "test_strip.instructions_box_b.test_3.step_5",
            "image": "/images/sti-programs/anal-5.svg"
        },
        {
            "label": "test_strip.instructions_box_b.test_3.step_6",
            "image": "/images/sti-programs/biohazard-bag-large.svg"
        }
    ],
}

const fitCollectionInstructions = {
    "title": "test_strip.instructions_box_cc.title",
    "link": "https://primarybio-public.s3.us-east-2.amazonaws.com/(V.1.2)+FIT+Collection+Instructions+English+Spanish.pdf",
    "subtitle": "test_strip.instructions_box_b.test_1.subtitle",
    "test_steps": [
        {
            "label": "test_strip.instructions_box_cc.step_1",
            "image": "/images/sti-programs/fit-1.png"
        },
        {
            "label": "test_strip.instructions_box_cc.step_2",
            "image": "/images/sti-programs/fit-2.png"
        },
        {
            "label": 'test_strip.instructions_box_cc.step_3',
            "image": "/images/sti-programs/fit-3.png"
        },
        {
            "label": "test_strip.instructions_box_cc.step_4",
            "image": "/images/sti-programs/fit-4.png"
        },
        {
            "label": "test_strip.instructions_box_cc.step_5",
            "image": "/images/sti-programs/fit-5.png"
        },
        {
            "label": "test_strip.instructions_box_cc.step_6",
            "image": "/images/sti-programs/fit-6.png"
        },
        {
            "label": "test_strip.instructions_box_cc.step_7",
            "image": "/images/sti-programs/fit-7.png"
        },
        {
            "label": "test_strip.instructions_box_cc.step_8",
            "image": "/images/sti-programs/fit-8.png"
        },
        {
            "label": "test_strip.instructions_box_cc.step_9",
            "image": "/images/sti-programs/fit-9.png"
        },
    ]
}

const nasalCollectionInstructions = {
    "title": "test_strip.nasal_collection.title",
    "link": "https://primarybio-public.s3.us-east-2.amazonaws.com/FINAL+-+Proof+162046+MTL+LTS+Nasal+Swab+Collect+Kit+2.pdf",
    "subtitle": "test_strip.nasal_collection.subtitle",
    "test_steps": [
        {
            "label": "test_strip.nasal_collection.step_1",
            "image": "/images/sti-programs/nasal-1.png"
        },
        {
            "label": "test_strip.nasal_collection.step_2",
            "image": "/images/sti-programs/nasal-2.png"
        },
        {
            "label": 'test_strip.nasal_collection.step_3',
            "image": "/images/sti-programs/nasal-3.png"
        },
        {
            "label": "test_strip.nasal_collection.step_4",
            "image": "/images/sti-programs/nasal-4.png"
        },
        {
            "label": "test_strip.nasal_collection.step_5",
            "image": "/images/sti-programs/nasal-5.png"
        },
        {
            "label": "test_strip.nasal_collection.step_6",
            "image": "/images/sti-programs/nasal-6.png"
        },
        {
            "label": "test_strip.nasal_collection.step_7",
            "image": "/images/sti-programs/nasal-7.png"
        },
    ],
}

// Test tips
const testTips = {
    "title": "test_strip.test_tips.title",
    "subtitle": "test_strip.test_tips.subtitle",
    "steps": [
        {
            "step": "test_strip.test_tips.step_1"
        },
        {
            "step": "test_strip.test_tips.step_2"
        },
        {
            "step": "test_strip.test_tips.step_3"
        },
        {
            "step": "test_strip.test_tips.step_4"
        },
        {
            "step": "test_strip.test_tips.step_5"
        },
        {
            "step": "test_strip.test_tips.step_6"
        }
    ]
}

// Packaging
const driedBloodSwabPackaging = {
    "title": "test_strip.packaging.title",
    "subtitle": "test_strip.packaging.subtitle",
    "steps": [
        {
            "step": "test_strip.packaging_box_c.step_1"
        },
        {
            "step": "test_strip.packaging_box_c.step_2"
        },
        {
            "step": "test_strip.packaging_box_c.step_3"
        },
        {
            "step": "test_strip.packaging_box_c.step_4"
        },
        {
            "step": "test_strip.packaging_box_c.step_5"
        },
        {
            "step": "test_strip.packaging_box_c.step_6"
        },
        {
            "step": "test_strip.packaging_box_c.step_7"
        }
    ]
};

const urineCollectionPackaging = {
    "title": "test_strip.packaging.title",
    "subtitle": "test_strip.packaging.subtitle",
    "steps": [
        {
            "step": "test_strip.packaging_box_a.step_1"
        },
        {
            "step": "test_strip.packaging_box_a.step_2"
        },
        {
            "step": "test_strip.packaging_box_a.step_3"
        },
        {
            "step": "test_strip.packaging_box_a.step_4"
        },
        {
            "step": "test_strip.packaging_box_a.step_5"
        },
        {
            "step": "test_strip.packaging_box_a.step_6"
        },
        {
            "step": "test_strip.packaging_box_a.step_7"
        },
        {
            "step": "test_strip.packaging_box_a.step_8"
        }
    ]
}

const vaginalAndFitCollectionPackaging = {
    "title": "test_strip.packaging.title",
    "subtitle": "test_strip.packaging.subtitle",
    "steps": [
        {
            "step": "test_strip.packaging_box_a.step_1"
        },
        {
            "step": "test_strip.packaging_box_a.step_2"
        },
        {
            "step": "test_strip.packaging_box_h.step_3"
        },
        {
            "step": "test_strip.packaging_box_h.step_4"
        },
        {
            "step": "test_strip.packaging_box_a.step_5"
        },
        {
            "step": "test_strip.packaging_box_h.step_6"
        },
        {
            "step": "test_strip.packaging_box_a.step_7"
        },
        {
            "step": "test_strip.packaging_box_a.step_8"
        }
    ]
}

const multiCollectionPackaging = {
    "title": "test_strip.packaging.title",
    "subtitle": "test_strip.packaging.subtitle",
    "steps": [
        {
            "step": "test_strip.packaging_box_b.step_1"
        },
        {
            "step": "test_strip.packaging_box_b.step_2"
        },
        {
            "step": "test_strip.packaging_box_b.step_3"
        },
        {
            "step": "test_strip.packaging_box_b.step_4"
        },
        {
            "step": "test_strip.packaging_box_b.step_5"
        },
        {
            "step": "test_strip.packaging_box_b.step_6"
        },
        {
            "step": "test_strip.packaging_box_b.step_7"
        },
        {
            "step": "test_strip.packaging_box_b.step_8"
        }
    ]
}

const multiWithBloodSwabPackaging = {
    "title": "test_strip.packaging.title",
    "subtitle": "test_strip.packaging.subtitle",
    "steps": [
        {
            "step": "test_strip.packaging_box_c.step_1"
        },
        {
            "step": "test_strip.packaging_box_d.step_2"
        },
        {
            "step": "test_strip.packaging_box_d.step_3"
        },
        {
            "step": "test_strip.packaging_box_c.step_4"
        },
        {
            "step": "test_strip.packaging_box_b.step_5"
        },
        {
            "step": "test_strip.packaging_box_b.step_6"
        },
        {
            "step": "test_strip.packaging_box_c.step_6"
        },
        {
            "step": "test_strip.packaging_box_c.step_7"
        }
    ]
}

const nasalCollectionPackaging = {
  title: 'test_strip.packaging.title',
  subtitle: 'test_strip.packaging.subtitle',
  steps: [
    {
      step: 'test_strip.packaging_box_a.step_1',
    },
    {
      step: 'test_strip.packaging_box_a.step_2',
    },
    {
      step: 'test_strip.packaging_box_a.step_3',
    },
    {
      step: 'test_strip.packaging_box_a.step_4',
    },
    {
      step: 'test_strip.packaging_box_a.step_5',
    },
  ],
};

// Kit information
const kitInformation = {
    A: {
        contents: {
            items: [
                urineSupplies,
                bioHazardBag(1),
                shippingEnvelope,
            ],
        },
        instructions: [
          {
            ...urineCollectionInstructions,
            "items": [
                urineSupplies,
                bioHazardBag(1),
            ]
          },
        ],
        packaging: urineCollectionPackaging,
    },
    B: {
        contents: {
            items: [
                urineSupplies,
                oralSupplies,
                analSupplies,
                bioHazardBag(3),
                shippingEnvelope,
            ],
        },
        instructions: [
            {
                ...urineCollectionInstructions,
                "items": [
                    urineSupplies,
                    bioHazardBag(1),
                ]
            },
            {
                ...oralCollectionInstructions,
                "items": [
                    oralSupplies,
                    bioHazardBag(1),
                ]
            },
            {
                ...analCollectionInstructions,
                "items": [
                    analSupplies,
                    bioHazardBag(1),
                ]
            }
        ],
        packaging: multiCollectionPackaging,
    },
    C: {
        contents: { items: [...driedBloodSwabItems, bioHazardBag(1)] },
        testTips: testTips,
        instructions: [
            {
              ...driedBloodSwabInstructions,
              "items": [
                ...driedBloodSwabItems,
                  bioHazardBag(1),
              ]
            },
          ],
        packaging: driedBloodSwabPackaging,
      },
    CC: {
        contents: {
            items: [
                ...nasalSupplies,
                biohazardBagWithAbsorbent,
            ],
        },
        instructions: [
            {
              ...nasalCollectionInstructions,
              items: [
                ...nasalSupplies,
                biohazardBagWithAbsorbent,
              ],
            },
          ],
        packaging: nasalCollectionPackaging,
    },
    CE: {
        contents: { items: [...driedBloodSwabItems, bioHazardBag(1)] },
        testTips: testTips,
        instructions: [
            {
              ...driedBloodSwabInstructions,
              "items": [
                ...driedBloodSwabItems,
                  bioHazardBag(1),
              ]
            },
          ],
        packaging: driedBloodSwabPackaging,
    },
    CR: {
        contents: {
            items: [
                ...stoolItems,
                biohazardBagWithAbsorbent,
                shippingEnvelope,
            ],
        },
        instructions: [
            {
              ...fitCollectionInstructions,
              items: [
                ...stoolItems,
                biohazardBagWithAbsorbent,
              ],
            },
          ],
        packaging: vaginalAndFitCollectionPackaging,
    },
    D: {
        contents: { items: [urineSupplies, ...driedBloodSwabItems, bioHazardBag(2)] },
        testTips: testTips,
        instructions: [
            {
                ...driedBloodSwabInstructions,
                items: [
                    ...driedBloodSwabItems,
                    bioHazardBag(1),
                ],
            },
            {
                ...urineCollectionInstructions,
                items: [
                    urineSupplies,
                    bioHazardBag(1),
                ],
            }
        ],
        packaging: multiWithBloodSwabPackaging,
    },
    DD: {
        contents: { items: [...driedBloodSwabItems, bioHazardBag(1)] },
        testTips: testTips,
        instructions: [
            {
              ...driedBloodSwabInstructions,
              "items": [
                ...driedBloodSwabItems,
                  bioHazardBag(1),
              ]
            },
          ],
        packaging: driedBloodSwabPackaging,
    },
    E: {
        contents: { items: [...driedBloodSwabItems, bioHazardBag(1)] },
        testTips: testTips,
        instructions: [
            {
              ...driedBloodSwabInstructions,
              "items": [
                ...driedBloodSwabItems,
                  bioHazardBag(1),
              ]
            },
          ],
        packaging: driedBloodSwabPackaging,
    },
    H: {
        contents: {
            items: [
                ...vaginalItems,
                biohazardBagWithAbsorbent,
                shippingEnvelope,
            ],
        },
        instructions: [
            {
                ...vaginalSwabInstructions,
                items: [
                    ...vaginalItems,
                    biohazardBagWithAbsorbent,
                ]
            }
        ],
        packaging: vaginalAndFitCollectionPackaging,
    },
    P: {
        contents: { items: [...driedBloodSwabItems, bioHazardBag(1)] },
        testTips: testTips,
        instructions: [
            {
              ...driedBloodSwabInstructions,
              "items": [
                ...driedBloodSwabItems,
                  bioHazardBag(1),
              ]
            },
          ],
        packaging: driedBloodSwabPackaging,
    },
    HA: {
        contents: {
            items: [
                ...nasalSupplies,
                biohazardBagWithAbsorbent,
            ],
        },
        instructions: [
            {
              ...nasalCollectionInstructions,
              items: [
                ...nasalSupplies,
                biohazardBagWithAbsorbent,
              ],
            },
          ],
        packaging: nasalCollectionPackaging,
    },
    HH: {
        contents: {
            items: [
                ...nasalSupplies,
                biohazardBagWithAbsorbent,
            ],
        },
        instructions: [
            {
              ...nasalCollectionInstructions,
              items: [
                ...nasalSupplies,
                biohazardBagWithAbsorbent,
              ],
            },
          ],
        packaging: nasalCollectionPackaging,
    },
    HS: {
        contents: {
            items: [
                ...vaginalItems,
                biohazardBagWithAbsorbent,
                shippingEnvelope,
            ],
        },
        instructions: [
            {
                ...vaginalSwabInstructions,
                items: [
                    ...vaginalItems,
                    biohazardBagWithAbsorbent,
                ]
            }
        ],
        packaging: vaginalAndFitCollectionPackaging,
    },
    I: {
        contents: { items: [...driedBloodSwabItems, bioHazardBag(1)] },
        testTips: testTips,
        instructions: [
            {
              ...driedBloodSwabInstructions,
              "items": [
                ...driedBloodSwabItems,
                  bioHazardBag(1),
              ]
            },
          ],
        packaging: driedBloodSwabPackaging,
    },
    TT: {
        contents: { items: [...driedBloodSwabItems, bioHazardBag(1)] },
        testTips: testTips,
        instructions: [
            {
              ...driedBloodSwabInstructions,
              "items": [
                ...driedBloodSwabItems,
                  bioHazardBag(1),
              ]
            },
          ],
        packaging: driedBloodSwabPackaging,
    },
    VD: {
        contents: { items: [...driedBloodSwabItems, bioHazardBag(1)] },
        testTips: testTips,
        instructions: [
            {
              ...driedBloodSwabInstructions,
              "items": [
                ...driedBloodSwabItems,
                  bioHazardBag(1),
              ]
            },
          ],
        packaging: driedBloodSwabPackaging,
    },
    AP: {
        contents: {
            items: [
                urineSupplies,
                bioHazardBag(1),
                shippingEnvelope,
            ],
        },
        instructions: [
          {
            ...urineCollectionInstructions,
            "items": [
                urineSupplies,
                bioHazardBag(1),
            ]
          },
        ],
        packaging: urineCollectionPackaging,
    },
    NN: {
        contents: {
            items: [
                ...nasalSupplies,
                biohazardBagWithAbsorbent,
            ],
        },
        instructions: [
            {
              ...nasalCollectionInstructions,
              items: [
                ...nasalSupplies,
                biohazardBagWithAbsorbent,
              ],
            },
          ],
        packaging: nasalCollectionPackaging,
    },
    FFFWW: {
        contents: {
            items: [
                ...nasalSupplies,
                biohazardBagWithAbsorbent,
            ],
        },
        instructions: [
            {
              ...nasalCollectionInstructions,
              items: [
                ...nasalSupplies,
                biohazardBagWithAbsorbent,
              ],
            },
          ],
        packaging: nasalCollectionPackaging,
    },
    YYYWW: {
        contents: {
            items: [
                ...nasalSupplies,
                biohazardBagWithAbsorbent,
            ],
        },
        instructions: [
            {
              ...nasalCollectionInstructions,
              items: [
                ...nasalSupplies,
                biohazardBagWithAbsorbent,
              ],
            },
          ],
        packaging: nasalCollectionPackaging,
    }
}

// Success
const packageKit = {
    header: "test_strip.success.header_2",
    image: "/images/sti-programs/success-4.svg",
    text: "test_strip.success.text_2",
};

const successVariants = [
    {
      header: "test_strip.success.header_1",
      image: "/images/sti-programs/success-1.svg",
      text: "test_strip.success.text_5",
    },
    {
        header: "test_strip.success.header_1",
        image: "/images/sti-programs/success-1.svg",
        text: "test_strip.success.text_1",
    },
    {
        header: "test_strip.success.header_3",
        image: "/images/sti-programs/success-2.svg",
        text: "test_strip.success.text_3",
    },
    {
        header: "test_strip.success.header_4",
        image: "/images/sti-programs/success-3.svg",
        text: "test_strip.success.text_4",
    },
];

export const successSteps = (kitType) => {
  let successSteps = [];
  const instructions = kitInformation[kitType].instructions;

  if (instructions.length <= 1) {
    successSteps.push(successVariants[0]);
  } else {
    for (var i = 0; i < instructions.length; i++) {
      successSteps.push(successVariants[i + 1]);
    }
  }

  successSteps.push(packageKit);
  return successSteps;
};

// Kit checklist
export const kitCheckList = {
    A: ["not_urinated", "bathroom"],
    B: ["not_urinated", "bathroom", "anal_swab"],
    C: ["blood_extraction", "washed_hands"],
    CC: ["washed_hands"],
    CE: ["blood_extraction", "washed_hands"],
    CR: ["blood_extraction", "washed_hands"],
    D: ['blood_extraction', "washed_hands", "not_urinated", "bathroom"],
    DD: ["blood_extraction", "washed_hands"],
    E: ["not_urinated", "bathroom"],
    H: ["no_alcohol", "menstruation"],
    HA: ["washed_hands"],
    HH: ["washed_hands"],
    HS: ["blood_extraction", "washed_hands"],
    I: ["blood_extraction", "washed_hands"],
    TT: ["blood_extraction", "washed_hands"],
    VD: ["blood_extraction", "washed_hands"],
    NN: ["washed_hands"],
    WW: ["washed_hands"]
};

export const mobileProgressBarSteps = (kitType) => {
    const totalInstructions = kitInformation[kitType].instructions.length;
  
    const instructionsSteps = Array.from(
      { length: totalInstructions },
      (_, i) => `INSTRUCTIONS.${i}`,
    );
  
    const steps = [CONTENTS, ...instructionsSteps, PACKAGING, SHIPPING];
  
    return { steps, totalSteps: steps.length - 1 };
  };

export default kitInformation;
