import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button, Card } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';

const AlertHeader = ({
  icon,
  title,
  message,
  btnLabel,
  btnAction,
  type = 'danger',
  disabledBtn = false,
  className,
  dataTestHook,
  style,
}) => {
  let primaryColor = '#FFEFE8';
  let secondaryColor = 'danger';
  let btnVariant = 'outline-danger'

  if (type === 'info') {
    primaryColor = "#E9F1FB";
    secondaryColor = "primary";
    btnVariant = "outline-primary"
  }

  if (type === 'warning') {
    primaryColor = "#FFEFE8";
    secondaryColor = "danger";
    btnVariant = "outline-danger"
  }

  if (type === 'danger') {
    primaryColor = "#FFDDE4";
    secondaryColor = "danger";
    btnVariant = "outline-danger"
  }

  return (
    <Card
      className={className + ' border-0 shadow-none mb-3 p-2 px-3'}
      style={{ backgroundColor: primaryColor, ...style }}
    >
      <Row>
        {icon && (
          <Col xs="auto">
            <FontAwesomeIcon
              icon={icon}
              color={`var(--bs-${secondaryColor})`}
              className="my-2"
              style={{ height: '24px', width: '24px' }}
            />
          </Col>
        )}
        <Col
          md={btnLabel && 8}
          xs={btnLabel && 10}
          className="d-flex flex-column justify-content-center"
        >
          <div className="my-2">
            {title && <h6>{title}</h6>}
            <div className="body-large-16-regular">
              <ReactMarkdown source={message} />
            </div>
          </div>
        </Col>
        {btnLabel && (
          <Col
            md={3}
            sm={12}
            className="d-flex justify-content-md-end justify-content-center ms-md-auto"
          >
            <div className="my-auto">
              <Button
                variant={btnVariant}
                onClick={btnAction}
                disabled={disabledBtn}
                data-test-hook={dataTestHook}
              >
                {btnLabel}
              </Button>
            </div>
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default AlertHeader;
