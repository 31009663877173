import React from 'react';
import StatusBadge from '../../common/components/StatusBadge';
import { Badge, BadgeWithTooltip } from '../../common/components/Badge';

const ParticipantRow = ({ appointment }) => {
  const {
    appointment_slot,
    appointment_slot_group,
    user,
    links,
    global_search,
  } = appointment;

  const notCheckoutEligible =
    !user.is_checkout_eligible && !user.primary_user_id;

  var householdButtonLink = appointment.is_global_search
    ? global_search.global_search_participant
    : links.primary_user_id;

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('include_archived') === 'true') {
    const householdButtonLinkParams = new URLSearchParams(
      householdButtonLink.split('?')[1],
    );
    householdButtonLinkParams.set('include_archived', true);
    householdButtonLink =
      householdButtonLink.split('?')[0] + '?' + householdButtonLinkParams;
  }

  const archivedStatus = appointment.archived_status;

  return (
    <React.Fragment>
      <td>
        {!appointment_slot.on_demand ? (
          <div>
            <div>
              {appointment_slot.appointment_date}
            </div>
            <div>
              {appointment_slot.localized_starts_at_time}
            </div>
          </div>
        ) : (
          <div>On demand</div>
        )}
      </td>
      <td>
        <div className="fw-bold">
          <a href={user.links.participant_path}>
            {user.first_name} {user.last_name}
          </a>
        </div>
        <div className="flex">
          {user.date_of_birth} (age {user.age})
          {user.age < 18 && <span class="badge bg-light text-secondary ms-1">Minor</span>}
        </div>
      </td>
      <td>
        {user.phone_number && (
          <div>{user.phone_formatted}</div>
        )}
        {user.email && (
          <div>{user.email}</div>
        )}
      </td>
      <td>
        <div>
          {appointment_slot_group.title}
        </div>
      </td>
      <td>
        {!!archivedStatus && (
          <a className="text-decoration-none" href={ appointment.links.appointment }>
            <StatusBadge status={archivedStatus} className="me-1" />
          </a>
        )}
        {appointment['has_symptoms?'] && (
          <BadgeWithTooltip
            title="Has symptoms"
            color="#FB4E03"
            backgroundColor="#FFEFE8"
            icon="fa-face-thermometer"
          />
        )}
        {!appointment['consents_signed?'] && (
          <BadgeWithTooltip
            title="Missing consent"
            color="#F70235"
            backgroundColor="#F3E2E3"
            icon="fa-circle-exclamation"
          />
        )}
        {appointment.check_in_at && (
          <StatusBadge status={"checked_in"} className="me-1" />
        )}
      </td>
    </React.Fragment>
  );
};

export default ParticipantRow;
